<template>
  <div class="v-overlay" :class="{'v-overlay--dark': dark}">
    <slot />
  </div>
</template>

<script>
export default {
  name: "VOverlay",
  props: {
    dark: Boolean,
  }
};
</script>

<style lang="scss" scoped>
.v-overlay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, .7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  &--dark {
    background-color: rgba(0, 0, 0, 0.25);
  }
}
</style>