<template>
  <div class="chart">
    <LineChart
        v-if="$store.state.dashboard.chartType === 'line'"
        class="random-chart__line-chart"
        :chart-data="_dynamicDatasets"
        :options="_dynamicOptions"
        :width="1000"
        :height="300"
    />
    <BarChart
        v-if="$store.state.dashboard.chartType === 'bar'"
        class="random-chart__line-chart"
        :chart-data="_dynamicDatasets"
        :options="_dynamicOptions"
        :width="1000"
        :height="300"
    />
  </div>
</template>

<script>
import moment from 'moment';
import LineChart from './LineChart.js';
import BarChart from './BarChart';

export default {
  name: 'Chart',
  components: {
    LineChart,
    BarChart,
  },
  data() {
    return {
      options: {
        responsive: true,
        // aspectRatio: 1,
        scales: {
          xAxes: [],
          yAxes: [
            {
              id: 'revenue',
              type: 'linear',
              position: 'left',
            },
          ],
        },
        tooltips: {
          mode: 'label',
          intersect: false
        },
        hover:{
          mode: 'label',
          intersect: false
        },
        elements: {
          point: {
            radius: 0
          }
        },
      },
      labelsFormat: 'HH:mm',
    };
  },
  computed: {
    _typeFilter() {
      return this.$store.state.dashboard.typeFilter;
    },
    _dateFilter() {
      return this.$store.state.dashboard.dateFilter;
    },
    _chartData() {
      return this.$store.state.dashboard.chartData;
    },
    _labels() {
      return Array.from(new Set(this._chartData?.left_range.map(item => this.renderLabels(item.unix))));
    },
    _dynamicOptions() {
      const availablePositions = ['left', 'right'];

      const yAxes = [...this._typeFilter.map((item, index) => ({
        id: item.value,
        type: 'linear',
        position: availablePositions[index],
        ticks: {
          max: this.getBiggestNumber(item.value) || 1,
          min: 0
        },
        scaleLabel: {
          display: true,
          labelString: item.title,
          fontColor: "black",
        },
      }))];

      return {
        ...this.options,
        scales: {
          xAxes: this.options.scales.xAxes,
          yAxes
        }
      };
    },
    _dynamicDatasets() {
      if (!this._dynamicOptions) return {};

      const colors = ['#FFCA8B', '#FF7D34', '#A8DEFC', '#1E70BB'];
      const dataSets = [];
      const [leftPeriod, rightPeriod] = this._dateFilter?.title.split('vs');

      this._typeFilter?.forEach(item => {
        const results = this.renderData(item.value);
        const labelLeft = item.title + ' ' + leftPeriod.trim();
        const labelRight = item.title + ' ' + rightPeriod.trim();

        dataSets.push({
          label: labelLeft,
          borderColor: colors[dataSets.length],
          backgroundColor: colors[dataSets.length] + '44',
          data: results[item.value].lastPeriod,
          borderWidth: 1.5,
          fill: true,
          tension: 0.1,
          yAxisID: item.value,
        });

        dataSets.push({
          label: labelRight,
          borderColor: colors[dataSets.length],
          backgroundColor: colors[dataSets.length] + '44',
          data: results[item.value].currentPeriod,
          borderWidth: 1.5,
          fill: true,
          tension: 0.1,
          yAxisID: item.value,
        });
      });

      return {
        labels: this._labels,
        datasets: dataSets,
      };
    },
  },
  methods: {
    getBiggestNumber(value) {
      const renderData = this.renderData(value);
      return Math.max(...renderData[value].lastPeriod, ...renderData[value].currentPeriod);
    },
    renderLabels(unix) {
      switch (this._dateFilter.value) {
        case 'today_yesterday':
        case 'today_last_week':
          this.labelsFormat = 'HH:mm'; // 00:00
          return moment(unix * 1000).utc().format(this.labelsFormat);
        case 'this_week_last_week':
          this.labelsFormat = 'ddd'; // Mon
          return moment(unix * 1000).utc().format(this.labelsFormat);
        case 'this_month_last_month':
          this.labelsFormat = 'DD.MM'; // 24.02 - day.month
          return moment(unix * 1000).utc().format('DD');
      }
    },
    formattedChartData(data) {
      const formattedTimeCreated = (date) => {
        return moment(date * 1000).utc().format(this.labelsFormat);
      };

      const formattedData = data.reduce((acc, el) => {
        acc[formattedTimeCreated(el.unix)] = [];
        return acc;
      }, {});

      data.forEach((el) => {
        formattedData[formattedTimeCreated(el.unix)].push({
          date: formattedTimeCreated(el.unix),
          reporting: el.reporting,
        });
      });

      return formattedData;
    },
    renderData(param) {
      this._chartData?.left_range.map(item => this.renderLabels(item.unix))

      const formattedLeftRange = this.formattedChartData(this._chartData?.left_range);
      const formattedRightRange = this.formattedChartData(this._chartData?.right_range);

      const element = (item) => Math.max(...item.map(el => el.reporting[param]));

      return {
        [param]: {
          lastPeriod: Object.values(formattedLeftRange).map(element),
          currentPeriod: Object.values(formattedRightRange).map(element),
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>