<template>
  <div class="v-mask">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VMask"
}
</script>

<style lang="scss" scoped>
.v-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 200;
}
</style>