const storage = window.localStorage;
const key = 'app_session_settings';

const appSessionSettingsService = {
  get() {
    const userData = storage.getItem(key);
    return userData ? JSON.parse(userData) : null;
  },
  set(data) {
    const stringifyData = JSON.stringify(data);
    storage.setItem(key, stringifyData);
  },
  update(data) {
    let parseData = this.get();

    if (parseData) {
      parseData = { ...parseData, ...data };
      this.set(parseData);
      return parseData;
    }

    return { ...data };
  },
  remove() {
    storage.removeItem(key);
  },
};

export { appSessionSettingsService };
