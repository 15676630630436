<template>
  <div v-if="layout" class="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>
<script>

import MainLayout from "@/js/components/layouts/MainLayout";
import AuthLayout from "@/js/components/layouts/AuthLayout";

export default {
  name: 'App',
  metaInfo: {
    title: 'Adstellar'
  },
  components: { MainLayout, AuthLayout},
  computed: {
    layout() {
      if (this.$route.meta.layout) return this.$route.meta.layout + '-layout';
      return null;
    }
  }
};
</script>