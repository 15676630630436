<template>
  <div class="header">
    <div class="header__container" :class="{'container-lg': containerLg}">
       <span class="header__icon-menu" @click="toggleSidebar">
        <template v-if="menuIsOpen">
           <icon-menu-close />
        </template>
        <template v-else>
          <icon-menu-open />
        </template>
      </span>
      <div class="header__right-col">
        <!--        <div class="header__balance">Account Balance: <span>$896.58</span></div>-->
        <template v-if="checkCurrentRouteName">
          <router-link to="/settings/notifications" class="header__notifications">
          <span class="icon">
             <icon-notifications />
          </span>
          </router-link>
        </template>
        <template v-else>
          <div class="header__notifications" @click="$store.commit('modalNotifications/TOGGLE_NOTIFICATION', true)">
          <span class="icon">
             <icon-notifications />
          </span>
          </div>
        </template>
        <div class="header__user" @click="toggleUserPopup = !toggleUserPopup" ref="user" v-click-outside="hide">
          <div class="header__user-img">
            <img src="@/assets/img/avatar-default.png" alt="Avatar" />
          </div>
          <div class="header__user-name">
            {{ _userProfile.first_name + ' ' + _userProfile.last_name }}
          </div>

          <div class="header__user-popup" v-if="toggleUserPopup">
            <div class="header__user-popup-top">
              <div class="text-semibold mb-1">
                {{ _userProfile.first_name + ' ' + _userProfile.last_name }}
              </div>
              <div class="color-grey-main">
                {{ _userProfile.email }}
              </div>
            </div>
            <div class="header__user-popup-bottom">
              <router-link
                to="/settings/profile"
                class="d-flex align-items-center mb-1 color-black-main text-underline-none"
              >
                <v-icon icon="account" class="mr-1" />
                My account
              </router-link>
              <div @click="logout" class="d-flex align-items-center">
                <v-icon icon="logout" class="mr-1" />
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconMenuOpen from "@/js/components/icons/IconMenuOpen";
import IconMenuClose from "@/js/components/icons/IconMenuClose";
import IconNotifications from "@/js/components/icons/IconNotifications";

export default {
  name: "AppHeader",
  components: {
    IconNotifications,
    IconMenuOpen,
    IconMenuClose
  },
  props: {
    menuIsOpen: Boolean,
  },
  data() {
    return {
      containerLg: false,
      toggleUserPopup: false
    };
  },
  computed: {
    checkCurrentRouteName() {
      return ['Dashboard', 'Settings'].includes(this.$route.name);
    },
    _userProfile() {
      return this.$store.getters['auth/userProfile'];
    },
  },
  methods: {
    toggleSidebar() {
      this.containerLg = !this.containerLg;
      this.$emit('drawer');
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    hide(e) {
      if (this.$refs.user.contains(e.target)) return;
      this.toggleUserPopup = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  color: var(--black-main);
  background-color: var(--white-main);
  box-shadow: 0 2px 6px 2px rgba(0, 42, 81, 0.1);
  z-index: 99;

  &__container {
    width: 100%;
    height: 100%;
    padding: 0 30px 0 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon-menu {
    cursor: pointer;
  }

  &__right-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__user {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black-main);
    text-decoration: none;
    position: relative;
    cursor: pointer;
  }

  &__user-popup {
    width: 240px;
    height: 170px;
    position: absolute;
    top: 40px;
    right: 100px;
    padding: 16px;
    background: var(--white-main);
    box-shadow: 0px 2px 6px 2px rgba(0, 42, 81, 0.1);
    border-radius: 8px;
  }

  &__user-popup-top {
    border-bottom: 1px solid var(--grey-light-1);
    padding-bottom: 8px;
  }

  &__user-popup-bottom {
    padding: 16px 0;
  }

  &__balance {
    margin-right: 16px;

    span {
      color: var(--blue-main);
      font-weight: 700;
    }
  }

  &__notifications {
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      position: relative;
      cursor: pointer;
      width: 24px;
      height: 24px;

      //&:before {
      //  content: '';
      //  width: 8px;
      //  height: 8px;
      //  display: block;
      //  position: absolute;
      //  background-color: var(--green-main);
      //  top: 0;
      //  right: 0;
      //  border-radius: 50%;
      //}
    }
  }

  &__user-img {
    width: 40px;
    height: 40px;
    position: relative;
    margin-right: 8px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .container-lg {
    padding: 0 30px 0 330px;
    transition: all 0.3s ease-out;
  }
}

</style>