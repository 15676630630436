<template>
  <div>
    <AuthHeader />
    <router-view />
    <AuthFooter />
  </div>
</template>

<script>
import AuthFooter from '@/js/components/layouts/Auth/AuthFooter';
import AuthHeader from '@/js/components/layouts/Auth/AuthHeader';

export default {
  name: "AuthLayout",
  components: { AuthFooter, AuthHeader }
}
</script>