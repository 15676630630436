<template>
  <component :is="getIcon"></component>
</template>

<script>
const getName = (name) => `icon-${name}`;
export default {
  name: 'VIcon',
  props: {
    icon:{
      type: String,
      default: 'dashboard',
    },
  },
  components: {
    //NewPassword
    [getName('password')]: () => import('../icons/IconPassword.vue'),
    [getName('password-show')]: () => import('../icons/IconPasswordShow.vue'),
    // Dashboard
    [getName('logo')]: () => import('../icons/IconLogo.vue'),
    [getName('menu-open')]: () => import('../icons/IconMenuOpen.vue'),
    [getName('menu-close')]: () => import('../icons/IconMenuClose.vue'),
    [getName('close')]: () => import('../icons/IconClose.vue'),
    [getName('notifications')]: () => import('../icons/IconNotifications.vue'),
    [getName('dashboard')]: () => import('../icons/IconDashboard.vue'),
    [getName('offers')]: () => import('../icons/IconOffers.vue'),
    [getName('payments')]: () => import('../icons/IconPayments.vue'),
    [getName('reports')]: () => import('../icons/IconReports.vue'),
    [getName('settings')]: () => import('../icons/IconSettings.vue'),
    [getName('update')]: () => import('../icons/IconUpdate.vue'),
    [getName('arrow-right')]: () => import('../icons/IconArrowRight.vue'),
    [getName('arrow-select')]: () => import('../icons/IconArrowSelect.vue'),
    [getName('delete-chip')]: () => import('../icons/IconDeleteChip.vue'),
    // Offers
    [getName('arrow-up')]: () => import('../icons/IconArrowUp.vue'),
    [getName('search')]: () => import('../icons/IconSearch.vue'),
    [getName('add')]: () => import('../icons/IconAdd.vue'),
    [getName('remove')]: () => import('../icons/IconRemove.vue'),
    [getName('sort-default')]: () => import('../icons/IconSortDefault.vue'),
    [getName('sort-desc')]: () => import('../icons/IconSortDesc.vue'),
    [getName('sort-asc')]: () => import('../icons/IconSortAsc.vue'),
    [getName('generate')]: () => import('../icons/IconGenerate.vue'),
    [getName('arrow')]: () => import('../icons/IconArrow.vue'),
    [getName('id-card')]: () => import('../icons/IconIdCard.vue'),
    [getName('csv')]: () => import('../icons/IconCsv.vue'),
    [getName('actions')]: () => import('../icons/IconActions.vue'),
    [getName('account')]: () => import('../icons/IconAccount.vue'),
    [getName('logout')]: () => import('../icons/IconLogout.vue'),
    [getName('metrics')]: () => import('../icons/IconMetrics.vue'),
    //Reports
    [getName('play')]: () => import('../icons/IconPlay.vue'),
    [getName('columns')]: () => import('../icons/IconColumns.vue'),
    [getName('filter')]: () => import('../icons/IconFilter.vue'),
    //Settings
    [getName('edit')]: () => import('../icons/IconEdit.vue'),
    //Payment
    [getName('edit-row-table')]: () => import('../icons/IconEditRowTable.vue'),
    [getName('delete')]: () => import('../icons/IconDelete.vue'),

  },
  computed: {
    getIcon() {
      return getName(this.icon);
    },
  },
};
</script>