<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_261_25245" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" transform="matrix(-1 0 0 1 24 0)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_261_25245)">
      <path
          d="M23.0001 8.76052C22.9934 8.59028 22.9233 8.39348 22.8066 8.26941L18.997 4.22168C18.7389 3.98967 18.2438 3.894 17.9553 4.16222C17.6712 4.42646 17.6796 4.93867 17.9626 5.20389L20.6412 8.04622L8.00009 8.04622C7.60565 8.04622 7.28577 8.36609 7.28577 8.76054C7.28577 9.155 7.60564 9.47487 8.00009 9.47487L20.6412 9.47487L17.9626 12.3172C17.7188 12.5613 17.6738 13.0918 17.9553 13.3589C18.2367 13.6261 18.7494 13.5407 18.997 13.2992L22.8066 9.25151C22.9369 9.113 23.0004 8.95041 23.0001 8.7604L23.0001 8.76052Z"
          fill="#1D1E24"/>
      <rect width="15.7143" height="1.57143" rx="0.785714" transform="matrix(-1 0 0 1 16.7142 13.4285)" fill="#1D1E24"/>
      <rect width="15.7143" height="1.57143" rx="0.785714" transform="matrix(-1 0 0 1 16.7142 17.6191)" fill="#1D1E24"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconMenuOpen"
}
</script>

<style scoped>

</style>