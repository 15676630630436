import dashboard from "@/js/api/actions/dashboard/dashboard";
import auth from "@/js/api/actions/auth/auth"
import notifications from "@/js/api/actions/notifications/notifications";
import meta from "@/js/api/actions/meta/meta";
import offers from "@/js/api/actions/offers/offers";
import reports from "@/js/api/actions/reports/reports";
import payments from '@/js/api/actions/payments/payments';
import settings from "@/js/api/actions/settings/settings";
import appSessionSettings from '@/js/api/actions/user/appSessionSettings';

export default {
  dashboard,
  notifications,
  meta,
  auth,
  offers,
  reports,
  payments,
  settings,
  appSessionSettings,
}