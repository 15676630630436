<template>
  <button
    class="v-button"
    :class="[
      [type],
      {'disabled': disabled}
    ]"
    :type="typeBtn"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <v-icon
      v-if="(icon && iconPosition === 'left')"
      :icon="icon"
      class="v-button__icon"
      :spin="spin"
    />
    <span class="v-button__text">
        <slot />
    </span>
    <v-icon
      v-if="(icon && iconPosition === 'right')"
      :icon="icon"
      class="v-button__icon v-button__icon--right"
      :spin="spin"
    />
  </button>
</template>

<script>
export default {
  name: "VButton",
  props: {
    typeBtn: {
      type: String,
      default: 'button'
    },
    type: {
      validator(value) {
        return [
          'primary',
          'orange',
          'blue',
          'orange-outline',
          'small-blue',
          'small-blue-outline',
          'blue-filled',
          'disabled'
        ].indexOf(value) !== -1;
      },
      default: "primary",
    },
    icon: {
      type: String,
    },
    spin: {
      type: Boolean,
      default: false
    },
    iconPosition: {
      type: String,
      default: 'left'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss">
.v-button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s ease;
  border-radius: 8px;
  height: 40px;
  padding: 0px 10px;
  cursor: pointer;
  width: 100%;

  &.orange {
    background-color: var(--orange-main);
    border: none;
    outline: none;
    font-weight: 600;
    color: var(--white-main);
  }

  &.blue {
    background-color: transparent;
    border: 1px solid var(--blue-main);
    color: var(--blue-main);
    font-weight: 600;

    &:hover {
      background-color: rgba(30, 112, 187, 0.15);
    }

    &:active {
      background-color: rgba(30, 112, 187, 0.25);
    }
  }

  &.orange-outline {
    background-color: transparent;
    border: 1px solid var(--orange-main);
    color: var(--orange-main);
    font-weight: 600;

    &:hover {
      background-color: rgba(255, 125, 52, 0.15);;
    }

    &:active {
      background-color: rgba(255, 125, 52, 0.25);;
    }
  }

  &.small-blue-outline {
    background-color: transparent;
    border: 1px solid var(--blue-main);
    color: var(--blue-main);
    font-weight: 600;
    height: 28px;

    &:hover {
      background-color: rgba(30, 112, 187, 0.15);
    }

    &:active {
      background-color: rgba(30, 112, 187, 0.25);
    }
  }

  &.small-blue {
    background-color: #EAF5FF;
    border: none;
    outline: none;
    color: var(--blue-main);
    font-weight: 400;
    height: 28px;

    &:hover {
      background-color: rgba(30, 112, 187, 0.15);
    }

    &:active {
      background-color: rgba(30, 112, 187, 0.25);
    }
  }

  &.blue-filled {
    background-color: var(--blue-main);
    border: none;
    outline: none;
    height: 28px;
    color: #FFFFFF;

    &:hover {
      background-color: rgba(#1E70BB, 0.90);
    }

    &:active {
      background-color: rgba(#1E70BB, 0.80);
    }
  }

  &.disabled {
    color: var(--grey-main);
    border-color: var(--grey-main);
    background: #E4E4E8;
    cursor: not-allowed;

    svg path {
      fill: var(--grey-main);
    }

    &:hover {
      background: #E4E4E8;
    }

    &:active {
      background: #E4E4E8;
    }
  }

  &.btn-xs {
    max-width: 94px;
  }

  &.btn-xl {
    max-width: 100px;
  }

  &.btn-ml {
    max-width: 173px;
  }

  &:hover {
    background-color: var(--orange-dark);
  }

  &:active {
    background-color: var(--orange-dark-1);
  }

  &__icon {
    margin-right: 10px;

    &--right {
      margin-left: auto;
      margin-right: 5px;
    }
  }

}
</style>