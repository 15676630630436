import api from '@/js/api';
import router from "@/js/router";
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: {
    offerDetails: {},
    offerStats: {},
    fetchingOfferDetailsStatus: requestStatus.IDLE,
    fetchingOfferStatsStatus: requestStatus.IDLE,
    fetchingDownloadAllImagesStatus: requestStatus.IDLE,
  },
  mutations: {
    SET_MAIN_DATA: (s, data) => {
      s.offerDetails = { ...s.offerDetails, ...data};
    },
    SET_OFFER_STATS: (s, data) => {
      s.offerStats = data;
    },
    RESET_STATUS: (s, status) => {
      s.fetchingOfferDetailsStatus = status;
    },
  },
  actions: {
    async getOfferDetails({ state, commit }, offerId) {
      state.fetchingOfferDetailsStatus = requestStatus.PENDING;

      try {
        const res = await api.offers.getDetails(offerId);
        commit('SET_MAIN_DATA', res.data);
        state.fetchingOfferDetailsStatus = requestStatus.SUCCESS;
      } catch (err) {
        console.log(err.response.data);
        state.fetchingOfferDetailsStatus = requestStatus.FAIL;
        alert(err.response.data.error);
        router.push('/offers');
      }
    },
    async getOfferStats({ state, commit }, formData) {
      state.fetchingOfferStatsStatus = requestStatus.PENDING;

      try {
        const { data } = await api.offers.getStats(formData);
        commit('SET_OFFER_STATS', data);
        state.fetchingOfferStatsStatus = requestStatus.SUCCESS;
      } catch (err) {
        console.log(err.response.data);
        state.fetchingOfferStatsStatus = requestStatus.FAIL;
      }
    },
    async getPermissionOnViewReport({ state }, offerId) {
      state.fetchingOfferStatsStatus = requestStatus.PENDING;

      try {
        const { data } = await api.offers.getPermissionOnViewReport(offerId);
        state.fetchingOfferStatsStatus = requestStatus.SUCCESS;
        return data;
      } catch (err) {
        state.fetchingOfferStatsStatus = requestStatus.FAIL;
      }
    },
    async downloadAllImages({ state }, offerId) {
      state.fetchingDownloadAllImagesStatus = requestStatus.PENDING;

      try {
        const { data } = await api.offers.downloadAllImages(offerId);
        state.fetchingDownloadAllImagesStatus = requestStatus.SUCCESS;
        return data;
      } catch (err) {
        state.fetchingDownloadAllImagesStatus = requestStatus.FAIL;
        throw err;
      }
    },
  },
  getters: {
    mainData: s => s.offerDetails,
    offerName: s => s.offerDetails.name,
    description: s => ({
      value: s.offerDetails.html_description,
      is_description_plain_text: s.offerDetails.is_description_plain_text,
    }),
  }
};
