import routes from '@/js/api/api-routes';
import { accessBackend } from "@/js/api/fetchData";

export default {

  async getOffers(data) {
    return await accessBackend.post(routes.proxy, {
      method: 'POST',
      url: routes.offers + `?page_size=999&order_field=${data.params.order_field}&order_direction=${data.params.order_direction}&relationship=application&relationship=application_questionnaire&relationship=questionnaire_answers`,
      data: {
        filters: data.formData,
        // search_terms: []
        search_terms: [{ search_type: "name", value: data.formData.name }]
      }
    });

    // return await accessAdstellar.post(routes.offers, {
    //   filters: {...formData},
    // search_terms: []
    // search_terms: [{search_type: "name", value: formData.name}]
    // }, {params})
  },

  async getCategoryList() {
    return await accessBackend.post(routes.proxy, {
      method: 'GET',
      url: routes.offersCategories,
    });
    // return await accessAdstellar.get(routes.offersCategories)
  },

  async getDetails(offerId) {
    return await accessBackend.post(routes.proxy, {
      method: 'GET',
      url: `${routes.offerDetails}/${offerId}?relationship=all`,
    });
    // return await accessAdstellar.get(`${routes.offerDetails}/${offerId}?relationship=all`)
  },

  async getStats(data) {
    return await accessBackend.post(routes.proxy, {
      method: 'POST',
      url: routes.stats,
      data: data
    });
    // return await accessAdstellar.post(routes.stats, data)
  },

  async getPermissionOnViewReport(offerId) {
    return await accessBackend.post(routes.proxy, {
      method: 'GET',
      url: `search/offers/${offerId}`,
    });
  },

  async getTrackingList(data) {
    return await accessBackend.post(routes.proxy, {
      method: 'POST',
      url: `${routes.trackingList}?relationship=offer&relationship=creative_bundle&relationship=targeting`,
      data: data
    });
    // return await accessAdstellar.post(`${routes.trackingList}?relationship=offer&relationship=creative_bundle&relationship=targeting`,data)
  },

  async getOffersRunnable() {
    return await accessBackend.post(routes.proxy, {
      method: 'GET',
      url: routes.offersRunnable,
    });
    // return await accessAdstellar.get(routes.offersRunnable)
  },

  async getApplicationQuestionnaire(offerId) {
    return await accessBackend.post(routes.proxy, {
      method: 'POST',
      // url: `${routes.offerDetails}/${offerId}?relationship=application&relationship=application_questionnaire&relationship=questionnaire_answers`,
      url: `${routes.offerDetails}/${offerId}/application`,
      data: { questionnaire_answers: [] },
    });

    // return await accessAdstellar.get(`${routes.offerDetails}/${offerId}?relationship=application&relationship=application_questionnaire&relationship=questionnaire_answers`)
  },

  downloadAllImages(offerId) {
    return accessBackend.post(routes.proxy, {
      method: 'GET',
      url: `${routes.offerDetails}/${offerId}/creatives/download`,
    });
  },

};
