<template>
  <div class="v-select" ref="select">
    <slot name="label">
      <div v-if="label" class="mb-1">
        {{ label }}
      </div>
    </slot>
    <div
      class="v-select-container"
      v-click-outside="hide"
      @click="toggleShowOptions = !toggleShowOptions"
    >
      <div
        class="v-select__select"
        :class="[
          size ? `v-select--${size}` : '',
          fullWidth ? 'v-select--full-width' : '',
          {'v-select--error': error},
        ]"
      >
        <span class="v-select__select--name">
          {{ _value }}
        </span>
        <icon-arrow-select class="s-arrow" :class="{'s-arrow-up': toggleShowOptions}" />
      </div>
    </div>
    <ul
      v-if="toggleShowOptions"
      class="v-select__select-options"
      :class="[
        dropdownPosition ? `v-select__select-options--${dropdownPosition}` : '',
      ]"
      ref="dropdown"
    >
      <li
        v-for="option in options"
        :key="option.id"
        class="v-select__option"
        :class="{
          'v-select__option--active': valueIsActive(option.id),
          'v-select__option--non-check': withoutIconCheck
        }"
        @click="selectOption(option)"
      >
        <icon-check v-if="!withoutIconCheck && valueIsActive(option.id)" class="v-select__option-check" />
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script>
import IconArrowSelect from "@/js/components/icons/IconArrowSelect";
import IconCheck from '@/js/components/icons/IconCheck';

export default {
  name: "VSelect",
  components: { IconCheck, IconArrowSelect },
  props: {
    label: String,
    options: Array, // { id: Number, label: String }
    value: [String, Number],
    size: String,
    getType: {
      type: String,
      default: 'label'
    },
    fullWidth: Boolean,
    error: Boolean,
    withoutIconCheck: Boolean,
  },
  data() {
    return {
      itemValue: this.value,
      toggleShowOptions: false,
      dropdownPosition: 'bottom', // 'top'
    };
  },
  computed: {
    _value() {
      if (this.getType === 'object') {
        if (this.value || Number.isInteger(this.value)) {
          const option = this.options.find(item => item.id === this.value);
          return option.label;
        }
        return '';
      }

      return this.options[this.itemValue] || this.itemValue;
    }
  },
  methods: {
    valueIsActive(optionId) {
      return this.value === optionId;
    },
    selectOption(option) {
      this.itemValue = option.label;
      this.$emit('input', this.getType === 'object' ? option : option.label);
      this.toggleShowOptions = false;
    },
    hide(e) {
      if (this.$refs.select.contains(e.target)) return;
      this.toggleShowOptions = false;
    }
  },
  watch: {
    async toggleShowOptions(next) {
      if (!next) return;
      await this.$nextTick();
      const { top, height } = this.$refs.dropdown.getBoundingClientRect();
      const diff = window.innerHeight - top - height;
      if (diff < 0) this.dropdownPosition = 'top';
    }
  },
};
</script>

<style scoped lang="scss">
.v-select {
  position: relative;

  &__select {
    border: 1px solid var(--grey-light-1);
    border-radius: 8px;
    outline: none;
    width: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
  }

  &--mini {
    height: 30px;
  }

  &--sm {
    max-width: 260px;
    height: 40px;
  }

  &--full-width {
    max-width: 100%;
  }

  &--error {
    border-color: var(--red-main);
  }

  .v-select__select--name {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .s-arrow {
    margin-left: auto;
    transform: rotate(0deg);
  }

  .s-arrow-up {
    transform: rotate(180deg);
  }

  .v-select__select-options {
    position: absolute;
    left: 0;
    width: 100%;
    max-height: 160px;
    margin: 0;
    padding: 0;
    background: #FFFFFF;
    border-radius: 8px 8px 10px 10px;
    border: 1px solid var(--grey-light-1);
    box-shadow: 0 2px 6px 2px rgba(0, 42, 81, 0.1);
    overflow-y: auto;
    cursor: pointer;
    z-index: 500;

    &--bottom {
      top: 100%;
    }

    &--top {
      bottom: 100%;
    }
  }

  &__option {
    height: 30px;
    display: flex;
    align-items: center;
    padding-right: 8px;
    padding-left: 36px;

    &:hover {
      background-color: var(--blue-light);
    }

    &:last-child {
      border-bottom: none;
    }

    &--non-check {
      padding-left: 8px;
    }

    &--active {
      padding-left: 8px;
      background-color: var(--blue-light);
    }

    &-check {
      margin-right: 4px;
    }
  }
}

</style>
