import api from "@/js/api";
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: () => ({
    paymentMethodId: null,
    details: {},
    fetchingStatus: requestStatus.IDLE,
    updatingStatus: requestStatus.IDLE,
  }),
  mutations: {
    SET_PAYMENT_METHOD_ID: (s, methodId) => {
      s.paymentMethodId = methodId;
    },
    SET_DATA: (state, payload) => {
      state.details = payload.data;
    },
  },
  actions: {
    async getPaymentMethodDetails({ state, commit }, methodId) {
      state.fetchingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.payments.getPaymentMethodDetails(methodId);
        commit('SET_DATA', data);
        state.fetchingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.fetchingStatus = requestStatus.FAIL;
        throw e;
      }
    },
    async createPaymentMethod({ state }, formData) {
      state.updatingStatus = requestStatus.PENDING;

      try {
        await api.payments.createPaymentMethod(formData);
        state.updatingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.updatingStatus = requestStatus.FAIL;
        throw e;
      }
    },
    async editPaymentMethod({ state }, formData) {
      state.updatingStatus = requestStatus.PENDING;

      try {
        await api.payments.editPaymentMethod({
          methodId: state.paymentMethodId,
          formData
        });
        state.updatingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.updatingStatus = requestStatus.FAIL;
        throw e;
      }
    },
  },
};
