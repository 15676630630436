import api from "@/js/api";
import { requestStatus } from '@/js/utils/constants';
import { bodyOverflow, cancelOverflow } from '@/js/utils/helpers';
import notificationsTransformer from "@/js/utils/transformers/notificationsTransformer";

export default {
  namespaced: true,
  state: {
    toggleNotification: false,
    notifications: [],
    notificationsStatic: {},
    fetchingStatus: requestStatus.IDLE,
  },
  mutations: {
    TOGGLE_NOTIFICATION: (s, payload) => {
      s.toggleNotification = payload;
      if (payload) bodyOverflow();
      else cancelOverflow();
    },
    SET_NOTIFICATIONS: (s, payload) => {
      s.notifications = payload;
    },
    SET_NOTIFICATIONS_STATIC: (s, payload) => {
      s.notificationsStatic = payload;
    },
  },
  actions: {
    async getNotifications({ state, commit }) {
      state.fetchingStatus = requestStatus.PENDING;

      try {
        const res = await api.dashboard.getNotifications();
        console.log('=> RES NOTIFICATIONS', res?.data);
        commit('SET_NOTIFICATIONS', notificationsTransformer(res?.data?.notifications));
        commit('SET_NOTIFICATIONS_STATIC', notificationsTransformer(res?.data?.notifications, 'static'));
        state.fetchingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('err=>', e);
        state.fetchingStatus = requestStatus.FAIL;
      }
    },
  }
};