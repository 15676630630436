import api from '@/js/api';
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: {
    offers: {},
    daily: {},
    conversion: {},
    click: {},
    filterOffers: null,
    fetchingOffersStatus: requestStatus.IDLE,
    fetchingClicksStatus: requestStatus.IDLE,
    fetchingDailiesStatus: requestStatus.IDLE,
    fetchingConversionsStatus: requestStatus.IDLE,
  },
  mutations: {
    SET_OFFERS: (s, data) => {
      s.offers = { ...data };
    },
    SET_DAILIES: (s, data) => {
      s.daily = { ...data };
    },
    SET_CONVERSIONS: (s, data) => {
      s.conversion = { ...data };
    },
    SET_CLICKS: (s, data) => {
      s.click = { ...data };
    },
    SET_FILTER_OFFERS: (s, data) => {
      s.filterOffers = data.results;
    }
  },
  actions: {
    async getOffers({ state, commit }, data) {
      state.fetchingOffersStatus = requestStatus.PENDING;

      try {
        const res = await api.reports.getOffers(data);
        commit(`SET_OFFERS`, res.data);
        state.fetchingOffersStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('err=>', e);
        state.fetchingOffersStatus = requestStatus.FAIL;
      }
    },
    async getDailies({ state, commit }, params) {
      state.fetchingDailiesStatus = requestStatus.PENDING;

      try {
        const res = await api.reports.getDailies(params);
        commit(`SET_DAILIES`, res.data);
        state.fetchingDailiesStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('err=>', e);
        state.fetchingDailiesStatus = requestStatus.FAIL;
      }
    },
    async getConversions({ state, commit }, params) {
      state.fetchingConversionsStatus = requestStatus.PENDING;

      try {
        const res = await api.reports.getConversions(params);
        commit(`SET_CONVERSIONS`, res.data);
        state.fetchingConversionsStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('err=>', e);
        state.fetchingConversionsStatus = requestStatus.FAIL;
      }
    },
    async getClicks({ state, commit }, params) {
      state.fetchingClicksStatus = requestStatus.PENDING;

      try {
        const res = await api.reports.getClicks(params);
        commit(`SET_CLICKS`, res.data);
        state.fetchingClicksStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('err=>', e);
        state.fetchingClicksStatus = requestStatus.FAIL;
      }
    },
    async getFilterOffers({ commit }) {
      try {
        const res = await api.reports.getFilterOffers();
        // console.log(res.data)
        commit(`SET_FILTER_OFFERS`, res.data);
      } catch (e) {
        console.log('err=>', e);
        return;
      }
    }
  }
};
