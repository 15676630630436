<template>
  <div class="dashboard-card">
    <div class="dashboard-card__container">
      <div class="dashboard-card__row">
        <div class="dashboard-card__item-row">
          <div class="dashboard-card__title">{{ _coolTitle }}</div>
          <div class="dashboard-card__percent" :class="{'dashboard-card__percent-minus': +_summary.trending_percentage <= 0}"> {{ _summary.trending_percentage }}%
          </div>
        </div>
        <div class="dashboard-card__item-row dashboard-card__today">Today</div>
        <div class="dashboard-card__item-row dashboard-card__price">{{ _currencySign }}{{ convertInt(_summary.today) }}</div>
      </div>
      <div class="dashboard-card__row">
        <div class="dashboard-card__item-row">
          <div class="dashboard-card__item-text">Yesterday</div>
          <div class="dashboard-card__item-price">{{ _currencySign }}{{ convertInt(_summary.yesterday) }}</div>
        </div>
        <div class="dashboard-card__item-row">
          <div class="dashboard-card__item-text">Current month</div>
          <div class="dashboard-card__item-price">{{ _currencySign }}{{ convertInt(_summary.current_month) }}</div>
        </div>
        <div class="dashboard-card__item-row">
          <div class="dashboard-card__item-text">Last month</div>
          <div class="dashboard-card__item-price">{{ _currencySign }}{{ convertInt(_summary.last_month) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardCard",
  props: {
    title: String
  },
  data() {
    return {}
  },
  computed: {
    _coolTitle() {
      return this.title.split('_').map( word => {
        word = word.replace('click', 'clicks');
        return word[0].toUpperCase() + word.slice(1);
      }).join(' ');
    },
    _summary() {
      return this.$store.state.dashboard.dashboardSummary && this.$store.state.dashboard.dashboardSummary[this.title] || {};
    },
    _currencySign() {
      if(this.title === 'revenue'){
        return '€'
        }
      return '';
    }
  },
  methods: {
    convertInt(val) {
      if (this.title !== 'revenue') {
        return val
      }
      if(! val) {
        return 0.00;
      }
      return val.toFixed(2);
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-card {
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: var(--main-box-shadow);
  border-radius: 8px;

  @include media("max", "xl") {
    padding: 16px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__row {
    &:first-child {
      border-bottom: 1px dashed #DDE4EB;
      padding-bottom: 14px;
    }

    &:last-child {
      padding-top: 14px;
    }
  }

  &__item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__today {
    text-transform: uppercase;
    color: var(--grey-main);
  }

  &__price {
    text-align: center;
    display: block;
    color: var(--blue-main);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
  }

  &__percent {
    background: #6DDD78;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 5px 6px;
  }

  &__percent-minus {
    background-color: var(--red-main);
  }

  &__item-text {
    color: var(--grey-main);
  }

  &__item-price {
    font-weight: 600;
  }
}
</style>