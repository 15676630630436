<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 10L12 15L17 10L7 10Z" fill="#1F1F1F"/>
  </svg>
</template>

<script>
export default {
  name: "IconArrowSelect"
}
</script>

<style scoped>

</style>