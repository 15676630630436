import {accessBackend} from '@/js/api/fetchData'
import routes from '@/js/api/api-routes'

export default {
  async setPassword(formData) {
    return await accessBackend.post(routes.auth.setPassword, formData)
  },
  async forgotPassword(email) {
    return await accessBackend.post(routes.auth.forgotPassword, {email}, {
      maxRedirects: 0
    })
  },
   async resetPassword(formData) {
    return await accessBackend.post(routes.auth.resetPassword, formData)
  },
   async login(email, password) {
    return await accessBackend.post(routes.auth.login, {email, password, remember: true}, {
      maxRedirects: 0
    })
  },
  async signup(formData) {
    return await accessBackend.post(routes.auth.signup, formData)
  },
  async logout() {
    return await accessBackend.post(routes.auth.logout, {
      maxRedirects: 0
    })
  },
}
