import api from '@/js/api';
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: {
    trackingList: [],
    offersRunnable: [],
    formParamsOffer: null,
    fetchingTrackingListStatus: requestStatus.IDLE,
  },
  mutations: {
    SET_TRACKING_LIST: (s, data) => {
      s.trackingList = data;
    },
    SET_OFFERS: (s, data) => {
      s.offersRunnable = data.map(item => ({
        ...data,
        id: item.network_offer_id,
        name: item.name
      }));
    },
    SET_FORM_PARAMS_OFFER: (s, payload) => {
      s.formParamsOffer = payload;
      localStorage.setItem('formParamsOffer', JSON.stringify(payload));
    }
  },
  actions: {
    async getTrackingList({ state, commit }, data) {
      state.fetchingTrackingListStatus = requestStatus.PENDING;

      try {
        const res = await api.offers.getTrackingList(data);
        console.log('res=>', res);
        commit('SET_TRACKING_LIST', res.data.tracking_urls);
        state.fetchingTrackingListStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('err=>', e);
        state.fetchingTrackingListStatus = requestStatus.FAIL;
      }
    },
    async getOffersRunnable({ commit }) {
      try {
        const res = await api.offers.getOffersRunnable();
        console.log('res=>', res);
        commit('SET_OFFERS', res.data.offers);

      } catch (e) {
        console.log('err=>', e);
      }
    }
  }
};
