var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"select",staticClass:"v-select"},[_vm._t("label",function(){return [(_vm.label)?_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],staticClass:"v-select-container",on:{"click":function($event){_vm.toggleShowOptions = !_vm.toggleShowOptions}}},[_c('div',{staticClass:"v-select__select",class:[
        _vm.size ? ("v-select--" + _vm.size) : '',
        _vm.fullWidth ? 'v-select--full-width' : '',
        {'v-select--error': _vm.error} ]},[_c('span',{staticClass:"v-select__select--name"},[_vm._v(" "+_vm._s(_vm._value)+" ")]),_c('icon-arrow-select',{staticClass:"s-arrow",class:{'s-arrow-up': _vm.toggleShowOptions}})],1)]),(_vm.toggleShowOptions)?_c('ul',{ref:"dropdown",staticClass:"v-select__select-options",class:[
      _vm.dropdownPosition ? ("v-select__select-options--" + _vm.dropdownPosition) : '' ]},_vm._l((_vm.options),function(option){return _c('li',{key:option.id,staticClass:"v-select__option",class:{
        'v-select__option--active': _vm.valueIsActive(option.id),
        'v-select__option--non-check': _vm.withoutIconCheck
      },on:{"click":function($event){return _vm.selectOption(option)}}},[(!_vm.withoutIconCheck && _vm.valueIsActive(option.id))?_c('icon-check',{staticClass:"v-select__option-check"}):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")],1)}),0):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }