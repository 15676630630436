import api from '@/js/api';
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: {
    isEditing: null,
    updatingStatus: requestStatus.IDLE,
  },
  mutations: {
    SET_IS_EDITING: (s, data) => {
      s.isEditing = data;
    },
  },
  actions: {
    async updateProfile({ state, commit }, formData) {
      state.updatingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.settings.updateProfile(formData);
        const { instant_messaging_id, instant_messaging_identifier } = data.profile;
        commit('auth/UPDATE_DATA', {
          instant_messaging_id,
          instant_messaging_identifier
        }, { root: true });
        state.updatingStatus = requestStatus.SUCCESS;
        return data;
      } catch (err) {
        console.log('=> ERR', err);
        state.updatingStatus = requestStatus.FAIL;
        throw err;
      }
    },
  }
};
