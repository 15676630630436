export default {
  proxy: 'proxy',
  products: 'products',
  dashboardSummary: 'dashboard/summary',
  chart: 'dashboard/performance',
  auth: {
    login: 'auth/login',
    signup: 'auth/signup',
    logout: 'auth/logout',
    forgotPassword: 'auth/forgot-password',
    resetPassword: 'auth/reset-password',
    setPassword: 'auth/set-password',
  },
  getCookie: 'csrf-cookie',
  offers: 'offerstable',
  offersCategories: 'categories',
  offerDetails: 'offers',
  trackingList:'trackingurls',
  offersRunnable:'offersrunnable',
  stats: 'reporting/entity',
  notifications: 'notifications', //users/8/notifications?page_size=5
  // meta: 'i18n/translations/1/meta',

  reports:{
    offer:'reports/offer',
    daily:'reports/daily',
    conversion: 'reports/conversion',
    clicks: 'reports/click',
    filterOffers: 'search/offers?status=active'
  },
  settings: {
    updateProfile: 'settings/update-profile',
    resetPassword: 'settings/reset-password',
    postback: 'postback'
  },
  user: {
    appSessionSettings: 'customization',
  },
  payments: {
    getPaymentMethods: 'payment',
    getPaymentMethodDetails: 'payment/{methodId}',
    createPaymentMethod: 'payment',
    setDefaultPaymentMethod: 'payment',
    deletePaymentMethod: 'payment',
    editPaymentMethod: 'payment/{methodId}',
    getInvoiceSummary: 'invoice/summary',
    getInvoices: 'invoice',
    deleteInvoice: 'invoice/{id}',
    addInvoice: 'invoice',
    exportInvoice: 'invoice/{id}/export',
    updateFrequency: 'payment/frequency',
    getInvoiceDetails: 'invoice/{id}',
  }
}