import api from "@/js/api";
import { requestStatus } from '@/js/utils/constants';
import moment from 'moment';

const getFormattedDate = (date) => moment(new Date(date)).format('DD.MM.yyyy');

export default {
  namespaced: true,
  state: () => ({
    details: {},
    summary: {
      billed_amount: 0,
      paid_amount: 0,
    },
    invoices: [],
    billingFrequencyIsManual: false,
    fetchingStatusSummary: requestStatus.IDLE,
    fetchingStatus: requestStatus.IDLE,
    deletingStatus: requestStatus.IDLE,
    exportingInvoiceStatus: requestStatus.IDLE,
    updatingStatus: requestStatus.IDLE,
    updatingStatusFrequency: requestStatus.IDLE,
  }),
  mutations: {
    SET_SUMMARY_LIST: (state, data) => {
      state.details = data;
    },
    SET_LIST: (state, list) => {
      state.invoices = list.map((invoice) => {
        state.summary.billed_amount += parseFloat(invoice.billed) || 0;
        state.summary.paid_amount += parseFloat(invoice.paid) || 0;

        return {
          ...invoice,
          created_at: getFormattedDate(invoice.created_at),
          start_date: getFormattedDate(invoice.start_date),
          end_date: getFormattedDate(invoice.end_date),
        };
      });
    },
    DELETE_INVOICE: (state, id) => {
      state.invoices = state.invoices.filter(item => item.id !== id);
    },
    ADD_INVOICE: (state, invoice) => {
      state.invoices.push({
        ...invoice,
        created_at: getFormattedDate(invoice.created_at),
        start_date: getFormattedDate(invoice.start_date),
        end_date: getFormattedDate(invoice.end_date),
      });
    },
    SET_BILLING_FREQUENCY: (s, payload) => {
      s.billingFrequencyIsManual = payload;
    },
    RESET_SUMMARY: (state) => {
      state.summary = {
        billed_amount: 0,
        paid_amount: 0,
      };
    },
  },
  actions: {
    async getInvoiceSummary({ state, commit }) {
      state.fetchingStatusSummary = requestStatus.PENDING;

      try {
        const { data } = await api.payments.getInvoiceSummary();
        commit('SET_SUMMARY_LIST', data);
        state.fetchingStatusSummary = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.fetchingStatusSummary = requestStatus.FAIL;
      }
    },
    async getInvoices({ state, commit }, params) {
      state.fetchingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.payments.getInvoices(params);
        commit('RESET_SUMMARY');
        commit('SET_LIST', data.data);
        state.fetchingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.fetchingStatus = requestStatus.FAIL;
      }
    },
    async deleteInvoice({ state, commit }, id) {
      state.deletingStatus = requestStatus.PENDING;

      try {
        await api.payments.deleteInvoice(id);
        commit('DELETE_INVOICE', id);
        state.deletingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.deletingStatus = requestStatus.FAIL;
        throw e;
      }
    },
    async addInvoice({ state, commit }, formData) {
      state.updatingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.payments.addInvoice(formData);
        commit('ADD_INVOICE', data.data);
        state.updatingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.updatingStatus = requestStatus.FAIL;
        throw e;
      }
    },
    async exportInvoice({ state }, id) {
      state.updatingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.payments.exportInvoice(id);
        state.updatingStatus = requestStatus.SUCCESS;
        return data;
      } catch (e) {
        console.log('=> ERR', e);
        state.updatingStatus = requestStatus.FAIL;
        throw e;
      }
    },
    async updateFrequency({ state }, formData) {
      state.updatingStatusFrequency = requestStatus.PENDING;

      try {
        await api.payments.updateFrequency(formData);
        state.updatingStatusFrequency = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.updatingStatusFrequency = requestStatus.FAIL;
        throw e;
      }
    },
  },
};
