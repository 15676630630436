<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_67_5063" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_67_5063)">
      <path
          d="M0.999949 8.76052C1.00659 8.59028 1.07668 8.39348 1.19343 8.26941L5.00302 4.22168C5.26111 3.98967 5.75621 3.894 6.04468 4.16222C6.32885 4.42646 6.32038 4.93867 6.03738 5.20389L3.35882 8.04622L15.9999 8.04622C16.3943 8.04622 16.7142 8.36609 16.7142 8.76054C16.7142 9.155 16.3944 9.47487 15.9999 9.47487L3.35882 9.47487L6.03738 12.3172C6.28118 12.5613 6.32619 13.0918 6.04468 13.3589C5.76334 13.6261 5.25064 13.5407 5.00302 13.2992L1.19343 9.25151C1.06306 9.113 0.999617 8.95041 0.999949 8.7604L0.999949 8.76052Z"
          fill="#1D1E24"/>
      <rect x="7.28577" y="13.4285" width="15.7143" height="1.57143" rx="0.785714" fill="#1D1E24"/>
      <rect x="7.28577" y="17.6191" width="15.7143" height="1.57143" rx="0.785714" fill="#1D1E24"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconMenuClose"
}
</script>

<style scoped>

</style>