import routes from '@/js/api/api-routes';
import { accessBackend } from "@/js/api/fetchData";

export default {
  getPaymentMethods() {
    return accessBackend.get(routes.payments.getPaymentMethods);
  },
  deletePaymentMethod(id) {
    return accessBackend.delete(`${routes.payments.deletePaymentMethod}/${id}`);
  },
  setDefaultPaymentMethod(paymentMethodId) {
    return accessBackend.put(`${routes.payments.setDefaultPaymentMethod}/${paymentMethodId}/set-default`);
  },
  getPaymentMethodDetails(methodId) {
    return accessBackend.get(routes.payments.getPaymentMethodDetails.replace('{methodId}', methodId));
  },
  createPaymentMethod(formData) {
    return accessBackend.post(routes.payments.createPaymentMethod, formData);
  },
  editPaymentMethod({ methodId, formData }) {
    return accessBackend.put(routes.payments.editPaymentMethod.replace('{methodId}', methodId), formData);
  },
  getInvoiceSummary() {
    return accessBackend.get(routes.payments.getInvoiceSummary);
  },
  getInvoices(params) {
    return accessBackend.get(routes.payments.getInvoices, { params });
  },
  deleteInvoice(id) {
    return accessBackend.delete(routes.payments.deleteInvoice.replace('{id}', id));
  },
  addInvoice(formData) {
    return accessBackend.post(routes.payments.addInvoice, formData);
  },
  exportInvoice(id) {
    return accessBackend.get(routes.payments.exportInvoice.replace('{id}', id), { responseType: 'blob' });
  },
  updateFrequency(formData) {
    return accessBackend.put(routes.payments.updateFrequency, formData);
  },
  getInvoiceDetails(invoiceId) {
    return accessBackend.get(routes.payments.getInvoiceDetails.replace('{id}', invoiceId));
  },
};
