import api from "@/js/api";

export default {
  namespaced: true,
  state: () => ({
    translations: {
      "brand": {
        "acer": "Acer",
        "alcatel": "ALCATEL",
        "alcatel_one_touch": "ALCATEL ONE TOUCH",
        "amazon": "Amazon",
        "apple": "Apple",
        "asus": "Asus",
        "blackview": "Blackview",
        "blu": "BLU",
        "bq": "bq",
        "casper": "Casper",
        "condor": "CONDOR",
        "coolpad": "Coolpad",
        "crosscall": "Crosscall",
        "docomo": "DoCoMo",
        "general_mobile": "General Mobile",
        "google": "Google",
        "hisense": "Hisense",
        "hmd_global": "HMD Global",
        "htc": "HTC",
        "huawei": "Huawei",
        "infinix": "Infinix",
        "itel": "iTel",
        "kddi": "KDDI",
        "kyocera": "Kyocera",
        "lava": "LAVA",
        "lenovo": "Lenovo",
        "lg": "LG",
        "meizu": "Meizu",
        "microsoft": "Microsoft",
        "motorola": "Motorola",
        "neffos": "neffos",
        "oneplus": "Oneplus",
        "oppo": "OPPO",
        "realme": "Realme",
        "samsung": "Samsung",
        "sharp": "Sharp",
        "softbank": "SoftBank",
        "sony": "Sony",
        "symphony": "Symphony",
        "tecno": "Tecno",
        "tmobile": "T-Mobile",
        "ulefone": "Ulefone",
        "vivo": "Vivo",
        "vodafone": "Vodafone",
        "vsmart": "Vsmart",
        "walton": "Walton",
        "wiko": "Wiko",
        "xiaomi": "Xiaomi",
        "zte": "ZTE"
      },
      "browser": {
        "chrome": "Chrome",
        "edge": "Edge",
        "internet_explorer": "Internet Explorer",
        "mozilla_firefox": "Mozilla Firefox",
        "opera": "Opera",
        "other": "Other",
        "safari": "Safari"
      },
      "browser_language": {
        "afrikaans": "Afrikaans",
        "albanian": "Albanian",
        "arabic": "Arabic",
        "basque": "Basque",
        "belarusian": "Belarusian",
        "bulgarian": "Bulgarian",
        "catalan": "Catalan",
        "chinese": "Chinese",
        "croatian": "Croatian",
        "czech": "Czech",
        "danish": "Danish",
        "dutch": "Dutch",
        "english": "English",
        "estonian": "Estonian",
        "faeroese": "Faeroese",
        "farsi": "Farsi",
        "finnish": "Finnish",
        "french": "French",
        "gaelic": "Gaelic",
        "german": "German",
        "greek": "Greek",
        "hebrew": "Hebrew",
        "hindi": "Hindi",
        "hungarian": "Hungarian",
        "icelandic": "Icelandic",
        "indonesian": "Indonesian",
        "irish": "Irish",
        "italian": "Italian",
        "japanese": "Japanese",
        "korean": "Korean",
        "latvian": "Latvian",
        "lithuanian": "Lithuanian",
        "macedonian": "Macedonian",
        "malaysian": "Malaysian",
        "maltese": "Maltese",
        "norwegian": "Norwegian",
        "polish": "Polish",
        "portuguese": "Portuguese",
        "rhaetoromanic": "Rhaeto-Romanic",
        "romanian": "Romanian",
        "russian": "Russian",
        "sami": "Sami",
        "serbian": "Serbian",
        "slovak": "Slovak",
        "slovenian": "Slovenian",
        "sorbian": "Sorbian",
        "spanish": "Spanish",
        "sutu": "Sutu",
        "swedish": "Swedish",
        "thai": "Thai",
        "tsonga": "Tsonga",
        "tswana": "Tswana",
        "turkish": "Turkish",
        "ukrainian": "Ukrainian",
        "urdu": "Urdu",
        "venda": "Venda",
        "vietnamese": "Vietnamese",
        "xhosa": "Xhosa",
        "yiddish": "Yiddish",
        "zulu": "Zulu"
      },
      "category": {
        "auto": "Auto ",
        "betting": "Betting ",
        "ecommerce": "E-commerce ",
        "education": "Education ",
        "fashion": "Fashion ",
        "finance": "Finance ",
        "games": "Games ",
        "health_fitness": "Health \u0026 Fitness ",
        "incentive": "Incentive ",
        "news": "News",
        "retail": "Retail ",
        "sports": "Sports ",
        "travel": "Travel ",
        "utility": "Utility "
      },
      "city": {
        "aachen_nw_de": "Aachen (NW, DE)",
        "aalborg_81_dk": "Aalborg (81, DK)",
        "aarhus_82_dk": "Aarhus (82, DK)",
        "aarlerixtel_nb_nl": "Aarle-Rixtel (NB, NL)",
        "abakan_kk_ru": "Abakan (KK, RU)",
        "abbotsford_bc_ca": "Abbotsford (BC, CA)",
        "abilene_tx_us": "Abilene (TX, US)",
        "abreu_e_lima_pe_br": "Abreu e Lima (PE, BR)",
        "abu_dhabi_az_ae": "Abu Dhabi (AZ, AE)",
        "acton_ca_us": "Acton (CA, US)",
        "addison_tx_us": "Addison (TX, US)",
        "adelaide_sa_au": "Adelaide (SA, AU)",
        "aerdenhout_nh_nl": "Aerdenhout (NH, NL)",
        "agoura_hills_ca_us": "Agoura Hills (CA, US)",
        "agra_up_in": "Agra (UP, IN)",
        "aguascalientes_agu_mx": "Aguascalientes (AGU, MX)",
        "ahmedabad_gj_in": "Ahmedabad (GJ, IN)",
        "ajmer_rj_in": "Ajmer (RJ, IN)",
        "akron_oh_us": "Akron (OH, US)",
        "al_hufuf_04_sa": "Al Hufuf (04, SA)",
        "albacete_ab_es": "Albacete (AB, ES)",
        "albany_ny_us": "Albany (NY, US)",
        "albuquerque_nm_us": "Albuquerque (NM, US)",
        "alcalá_de_henares_m_es": "Alcalá de Henares (M, ES)",
        "alcobendas_y_la_moraleja_m_es": "Alcobendas y la Moraleja (M, ES)",
        "alcorcón_m_es": "Alcorcón (M, ES)",
        "aledo_tx_us": "Aledo (TX, US)",
        "alexandria_alx_eg": "Alexandria (alx, EG)",
        "alexandria_va_us": "Alexandria (VA, US)",
        "alfenas_mg_br": "Alfenas (MG, BR)",
        "alfortville_idf_fr": "Alfortville (IDF, FR)",
        "algeciras_ca_es": "Algeciras (CA, ES)",
        "alhambra_ca_us": "Alhambra (CA, US)",
        "alicante_a_es": "Alicante (A, ES)",
        "aligarh_up_in": "Aligarh (UP, IN)",
        "aliso_viejo_ca_us": "Aliso Viejo (CA, US)",
        "aljubayl_04_sa": "Al-Jubayl (04, SA)",
        "alkmaar_nh_nl": "Alkmaar (NH, NL)",
        "allahabad_up_in": "Allahabad (UP, IN)",
        "allen_tx_us": "Allen (TX, US)",
        "allentown_nj_us": "Allentown (NJ, US)",
        "allentown_pa_us": "Allentown (PA, US)",
        "almadinah_03_sa": "Al-Madinah (03, SA)",
        "almere_fl_nl": "Almere (FL, NL)",
        "almería_al_es": "Almería (AL, ES)",
        "almetyevsk_ta_ru": "Almetyevsk (TA, RU)",
        "altoona_pa_us": "Altoona (PA, US)",
        "alvarado_tx_us": "Alvarado (TX, US)",
        "alvorada_rs_br": "Alvorada (RS, BR)",
        "alvord_tx_us": "Alvord (TX, US)",
        "alwar_rj_in": "Alwar (RJ, IN)",
        "amarillo_tx_us": "Amarillo (TX, US)",
        "amersfoort_ut_nl": "Amersfoort (UT, NL)",
        "ames_ia_us": "Ames (IA, US)",
        "amherst_ny_us": "Amherst (NY, US)",
        "amiens_hdf_fr": "Amiens (HDF, FR)",
        "amparo_sp_br": "Amparo (SP, BR)",
        "amritsar_pb_in": "Amritsar (PB, IN)",
        "amstenrade_li_nl": "Amstenrade (LI, NL)",
        "amsterdam_nh_nl": "Amsterdam (NH, NL)",
        "amsterdamduivendrecht_nh_nl": "Amsterdam-Duivendrecht (NH, NL)",
        "anaheim_ca_us": "Anaheim (CA, US)",
        "anantapur_ap_in": "Anantapur (AP, IN)",
        "anapolis_go_br": "Anapolis (GO, BR)",
        "anchorage_ak_us": "Anchorage (AK, US)",
        "anderson_sc_us": "Anderson (SC, US)",
        "andreevka_mos_ru": "Andreevka (MOS, RU)",
        "ann_arbor_mi_us": "Ann Arbor (MI, US)",
        "anna_tx_us": "Anna (TX, US)",
        "annapolis_md_us": "Annapolis (MD, US)",
        "antioch_ca_us": "Antioch (CA, US)",
        "antipolo_riz_ph": "Antipolo (RIZ, PH)",
        "antofagasta_an_cl": "Antofagasta (AN, CL)",
        "apartado_ant_co": "Apartado (ANT, CO)",
        "apeldoorn_ge_nl": "Apeldoorn (GE, NL)",
        "appleton_wi_us": "Appleton (WI, US)",
        "aquiraz_ce_br": "Aquiraz (CE, BR)",
        "aracaju_se_br": "Aracaju (SE, BR)",
        "araucaria_pr_br": "Araucaria (PR, BR)",
        "arcadia_ca_us": "Arcadia (CA, US)",
        "arcueil_idf_fr": "Arcueil (IDF, FR)",
        "arequipa_are_pe": "Arequipa (ARE, PE)",
        "argyle_tx_us": "Argyle (TX, US)",
        "arica_ap_cl": "Arica (AP, CL)",
        "ariquemes_ro_br": "Ariquemes (RO, BR)",
        "arlington_tx_us": "Arlington (TX, US)",
        "arlington_va_us": "Arlington (VA, US)",
        "armenia_qui_co": "Armenia (QUI, CO)",
        "arnhem_ge_nl": "Arnhem (GE, NL)",
        "artesia_ca_us": "Artesia (CA, US)",
        "arvada_co_us": "Arvada (CO, US)",
        "asansol_wb_in": "Asansol (WB, IN)",
        "asheville_nc_us": "Asheville (NC, US)",
        "ashland_or_us": "Ashland (OR, US)",
        "aspen_co_us": "Aspen (CO, US)",
        "astrakhan_ast_ru": "Astrakhan (AST, RU)",
        "athens_ga_us": "Athens (GA, US)",
        "atlanta_ga_us": "Atlanta (GA, US)",
        "atta_if_02_sa": "At-Ta if (02, SA)",
        "aubervilliers_idf_fr": "Aubervilliers (IDF, FR)",
        "aubrey_tx_us": "Aubrey (TX, US)",
        "auburn_al_us": "Auburn (AL, US)",
        "auburn_mi_us": "Auburn (MI, US)",
        "auburn_wa_us": "Auburn (WA, US)",
        "auckland_auk_nz": "Auckland (AUK, NZ)",
        "augsburg_by_de": "Augsburg (BY, DE)",
        "augusta_ga_us": "Augusta (GA, US)",
        "aurangabad_br_in": "Aurangabad (BR, IN)",
        "aurora_co_us": "Aurora (CO, US)",
        "aurora_il_us": "Aurora (IL, US)",
        "austin_tx_us": "Austin (TX, US)",
        "avalon_ca_us": "Avalon (CA, US)",
        "azle_tx_us": "Azle (TX, US)",
        "azusa_ca_us": "Azusa (CA, US)",
        "bacoor_cav_ph": "Bacoor (CAV, PH)",
        "badajoz_ba_es": "Badajoz (BA, ES)",
        "badalona_b_es": "Badalona (B, ES)",
        "badhoevedorp_nh_nl": "Badhoevedorp (NH, NL)",
        "bagnolet_idf_fr": "Bagnolet (IDF, FR)",
        "baguio_que_ph": "Baguio (QUE, PH)",
        "bakersfield_ca_us": "Bakersfield (CA, US)",
        "balanga_ban_ph": "Balanga (BAN, PH)",
        "balashikha_mos_ru": "Balashikha (MOS, RU)",
        "balch_springs_tx_us": "Balch Springs (TX, US)",
        "baldwin_park_ca_us": "Baldwin Park (CA, US)",
        "balikpapan_ki_id": "Balikpapan (KI, ID)",
        "balneario_picarras_sc_br": "Balneario Picarras (SC, BR)",
        "baltimore_md_us": "Baltimore (MD, US)",
        "banda_aceh_ac_id": "Banda Aceh (AC, ID)",
        "bandar_baru_ac_id": "Bandar Baru (AC, ID)",
        "bandar_ji_id": "Bandar (JI, ID)",
        "bandar_jt_id": "Bandar (JT, ID)",
        "bandar_su_id": "Bandar (SU, ID)",
        "bandung_jb_id": "Bandung (JB, ID)",
        "bandung_kanan_ss_id": "Bandung Kanan (SS, ID)",
        "bandung_kidul_jt_id": "Bandung Kidul (JT, ID)",
        "bandungrejo_jt_id": "Bandungrejo (JT, ID)",
        "bangalore_ka_in": "Bangalore (KA, IN)",
        "bangkok_10_th": "Bangkok (10, TH)",
        "banjarmasin_ks_id": "Banjarmasin (KS, ID)",
        "banyuwangi_ji_id": "Banyuwangi (JI, ID)",
        "barcelona_b_es": "Barcelona (B, ES)",
        "bardwell_tx_us": "Bardwell (TX, US)",
        "bareilly_up_in": "Bareilly (UP, IN)",
        "bari_ba_it": "Bari (BA, IT)",
        "barra_do_garcas_mt_br": "Barra do Garcas (MT, BR)",
        "barra_velha_sc_br": "Barra Velha (SC, BR)",
        "barranquilla_atl_co": "Barranquilla (ATL, CO)",
        "barrie_on_ca": "Barrie (ON, CA)",
        "barstow_ca_us": "Barstow (CA, US)",
        "barueri_sp_br": "Barueri (SP, BR)",
        "basel_bs_ch": "Basel (BS, CH)",
        "baton_rouge_la_us": "Baton Rouge (LA, US)",
        "bavel_nb_nl": "Bavel (NB, NL)",
        "beaufort_sc_us": "Beaufort (SC, US)",
        "beaumont_tx_us": "Beaumont (TX, US)",
        "bedford_tx_us": "Bedford (TX, US)",
        "beek_en_donk_nb_nl": "Beek en Donk (NB, NL)",
        "beinsdorp_nh_nl": "Beinsdorp (NH, NL)",
        "bekasi_jaya_jb_id": "Bekasi Jaya (JB, ID)",
        "bekasi_jb_id": "Bekasi (JB, ID)",
        "belem_pa_br": "Belem (PA, BR)",
        "belfeld_li_nl": "Belfeld (LI, NL)",
        "bell_ca_us": "Bell (CA, US)",
        "bell_gardens_ca_us": "Bell Gardens (CA, US)",
        "belleville_il_us": "Belleville (IL, US)",
        "bellevue_wa_us": "Bellevue (WA, US)",
        "bellflower_ca_us": "Bellflower (CA, US)",
        "bellingham_wa_us": "Bellingham (WA, US)",
        "belmont_ca_us": "Belmont (CA, US)",
        "belo_horizonte_mg_br": "Belo Horizonte (MG, BR)",
        "bend_or_us": "Bend (OR, US)",
        "bennekom_ge_nl": "Bennekom (GE, NL)",
        "benthuizen_zh_nl": "Benthuizen (ZH, NL)",
        "berezniki_per_ru": "Berezniki (PER, RU)",
        "bergamo_bg_it": "Bergamo (BG, IT)",
        "bergen_12_no": "Bergen (12, NO)",
        "bergen_46_no": "Bergen (46, NO)",
        "bergisch_gladbach_nw_de": "Bergisch Gladbach (NW, DE)",
        "berkeley_ca_us": "Berkeley (CA, US)",
        "berkley_ma_us": "Berkley (MA, US)",
        "berkley_mi_us": "Berkley (MI, US)",
        "berlin_be_de": "Berlin (BE, DE)",
        "bern_be_ch": "Bern (BE, CH)",
        "bertioga_sp_br": "Bertioga (SP, BR)",
        "besançon_bfc_fr": "Besançon (BFC, FR)",
        "betim_mg_br": "Betim (MG, BR)",
        "beuningen_ge_nl": "Beuningen (GE, NL)",
        "beverly_hills_ca_us": "Beverly Hills (CA, US)",
        "bhagalpur_br_in": "Bhagalpur (BR, IN)",
        "bhilai_ct_in": "Bhilai (CT, IN)",
        "bhopal_mp_in": "Bhopal (MP, IN)",
        "bhubaneswar_or_in": "Bhubaneswar (OR, IN)",
        "bielefeld_nw_de": "Bielefeld (NW, DE)",
        "biguacu_sc_br": "Biguacu (SC, BR)",
        "bikaner_rj_in": "Bikaner (RJ, IN)",
        "bilaspur_ct_in": "Bilaspur (CT, IN)",
        "bilbao_bi_es": "Bilbao (BI, ES)",
        "billings_mt_us": "Billings (MT, US)",
        "biloxi_ms_us": "Biloxi (MS, US)",
        "bilthoven_ut_nl": "Bilthoven (UT, NL)",
        "binan_lag_ph": "Binan (LAG, PH)",
        "birmingham_al_us": "Birmingham (AL, US)",
        "birmingham_bir_uk": "Birmingham (BIR, UK)",
        "bismarck_nd_us": "Bismarck (ND, US)",
        "blacksburg_va_us": "Blacksburg (VA, US)",
        "blagoveshchensk_amu_ru": "Blagoveshchensk (AMU, RU)",
        "bloemendaal_nh_nl": "Bloemendaal (NH, NL)",
        "bloomington_in_us": "Bloomington (IN, US)",
        "blue_ridge_tx_us": "Blue Ridge (TX, US)",
        "bluefield_wv_us": "Bluefield (WV, US)",
        "blumenau_sc_br": "Blumenau (SC, BR)",
        "boa_vista_rr_br": "Boa Vista (RR, BR)",
        "bochum_nw_de": "Bochum (NW, DE)",
        "bogor_barat_jb_id": "Bogor Barat (JB, ID)",
        "bogor_jb_id": "Bogor (JB, ID)",
        "bogorejo_jt_id": "Bogorejo (JT, ID)",
        "bogota_dc_co": "Bogota (DC, CO)",
        "boise_id_us": "Boise (ID, US)",
        "boituva_sp_br": "Boituva (SP, BR)",
        "bokaro_steel_jh_in": "Bokaro Steel (JH, IN)",
        "bologna_bo_it": "Bologna (BO, IT)",
        "bom_despacho_mg_br": "Bom Despacho (MG, BR)",
        "bombinhas_sc_br": "Bombinhas (SC, BR)",
        "bonita_ca_us": "Bonita (CA, US)",
        "bonn_nw_de": "Bonn (NW, DE)",
        "boone_nc_us": "Boone (NC, US)",
        "bordeaux_naq_fr": "Bordeaux (NAQ, FR)",
        "bosch_en_duin_ut_nl": "Bosch en Duin (UT, NL)",
        "boskoop_zh_nl": "Boskoop (ZH, NL)",
        "boston_ma_us": "Boston (MA, US)",
        "bottrop_nw_de": "Bottrop (NW, DE)",
        "boulder_co_us": "Boulder (CO, US)",
        "boulognebillancourt_idf_fr": "Boulogne-Billancourt (IDF, FR)",
        "bowling_green_ky_us": "Bowling Green (KY, US)",
        "boyd_tx_us": "Boyd (TX, US)",
        "bozeman_mt_us": "Bozeman (MT, US)",
        "brampton_on_ca": "Brampton (ON, CA)",
        "brandon_fl_us": "Brandon (FL, US)",
        "branson_mo_us": "Branson (MO, US)",
        "brasilia_df_br": "Brasilia (DF, BR)",
        "brea_ca_us": "Brea (CA, US)",
        "breda_nb_nl": "Breda (NB, NL)",
        "bremen_hb_de": "Bremen (HB, DE)",
        "bremerhaven_hb_de": "Bremerhaven (HB, DE)",
        "bremerton_wa_us": "Bremerton (WA, US)",
        "brescia_bs_it": "Brescia (BS, IT)",
        "bridgeport_ct_us": "Bridgeport (CT, US)",
        "brighton_bnh_uk": "Brighton (BNH, UK)",
        "brisbane_qld_au": "Brisbane (QLD, AU)",
        "bristol_bst_uk": "Bristol (BST, UK)",
        "broek_op_langedijk_nh_nl": "Broek op Langedijk (NH, NL)",
        "bronx_ny_us": "Bronx (NY, US)",
        "brooklyn_ny_us": "Brooklyn (NY, US)",
        "brownsville_tx_us": "Brownsville (TX, US)",
        "brumadinho_mg_br": "Brumadinho (MG, BR)",
        "brunssum_li_nl": "Brunssum (LI, NL)",
        "brunswick_ni_de": "Brunswick (NI, DE)",
        "bucaramanga_san_co": "Bucaramanga (SAN, CO)",
        "buena_park_ca_us": "Buena Park (CA, US)",
        "buenos_aires_c_ar": "Buenos Aires (C, AR)",
        "buffalo_ny_us": "Buffalo (NY, US)",
        "bullhead_city_az_us": "Bullhead City (AZ, US)",
        "bunnik_ut_nl": "Bunnik (UT, NL)",
        "burbank_ca_us": "Burbank (CA, US)",
        "burgos_bu_es": "Burgos (BU, ES)",
        "burleson_tx_us": "Burleson (TX, US)",
        "burlington_on_ca": "Burlington (ON, CA)",
        "burnaby_bc_ca": "Burnaby (BC, CA)",
        "cabo_frio_rj_br": "Cabo Frio (RJ, BR)",
        "cabuyao_lag_ph": "Cabuyao (LAG, PH)",
        "cachoeirinha_rs_br": "Cachoeirinha (RS, BR)",
        "caddo_mills_tx_us": "Caddo Mills (TX, US)",
        "cadiz_ca_es": "Cadiz (CA, ES)",
        "caen_nor_fr": "Caen (NOR, FR)",
        "cagayan_de_oro_msr_ph": "Cagayan De Oro (MSR, PH)",
        "cagliari_ca_it": "Cagliari (CA, IT)",
        "cairns_qld_au": "Cairns (QLD, AU)",
        "cairo_c_eg": "Cairo (c, EG)",
        "cajamar_sp_br": "Cajamar (SP, BR)",
        "cajica_cun_co": "Cajica (CUN, CO)",
        "calabasas_ca_us": "Calabasas (CA, US)",
        "calama_an_cl": "Calama (AN, CL)",
        "calcutta_wb_in": "Calcutta (WB, IN)",
        "calgary_ab_ca": "Calgary (AB, CA)",
        "cali_vac_co": "Cali (VAC, CO)",
        "california_md_us": "California (MD, US)",
        "calistoga_ca_us": "Calistoga (CA, US)",
        "camaqua_rs_br": "Camaqua (RS, BR)",
        "camaragibe_pe_br": "Camaragibe (PE, BR)",
        "cambridge_ma_us": "Cambridge (MA, US)",
        "cambridge_on_ca": "Cambridge (ON, CA)",
        "camden_nj_us": "Camden (NJ, US)",
        "campbell_tx_us": "Campbell (TX, US)",
        "campina_grande_do_sul_pr_br": "Campina Grande do Sul (PR, BR)",
        "campina_grande_pb_br": "Campina Grande (PB, BR)",
        "campinas_sp_br": "Campinas (SP, BR)",
        "campo_belo_mg_br": "Campo Belo (MG, BR)",
        "campo_bom_rs_br": "Campo Bom (RS, BR)",
        "campo_grande_ms_br": "Campo Grande (MS, BR)",
        "campo_limpo_paulista_sp_br": "Campo Limpo Paulista (SP, BR)",
        "campos_dos_goytacazes_rj_br": "Campos dos Goytacazes (RJ, BR)",
        "canberra_act_au": "Canberra (ACT, AU)",
        "cancun_roo_mx": "Cancun (ROO, MX)",
        "canoas_rs_br": "Canoas (RS, BR)",
        "canton_oh_us": "Canton (OH, US)",
        "cape_coral_fl_us": "Cape Coral (FL, US)",
        "capelle_aan_den_ijssel_zh_nl": "Capelle aan den IJssel (ZH, NL)",
        "carapicuiba_sp_br": "Carapicuiba (SP, BR)",
        "carbondale_il_us": "Carbondale (IL, US)",
        "cardiff_crf_uk": "Cardiff (CRF, UK)",
        "carlsbad_ca_us": "Carlsbad (CA, US)",
        "carrollton_tx_us": "Carrollton (TX, US)",
        "carson_ca_us": "Carson (CA, US)",
        "cartagena_bol_co": "Cartagena (BOL, CO)",
        "cartagena_mu_es": "Cartagena (MU, ES)",
        "caruaru_pe_br": "Caruaru (PE, BR)",
        "cary_nc_us": "Cary (NC, US)",
        "casa_grande_az_us": "Casa Grande (AZ, US)",
        "cascavel_pr_br": "Cascavel (PR, BR)",
        "castellon_de_la_plana_cs_es": "Castellon de la Plana (CS, ES)",
        "cataguases_mg_br": "Cataguases (MG, BR)",
        "catania_ct_it": "Catania (CT, IT)",
        "caxias_do_sul_rs_br": "Caxias do Sul (RS, BR)",
        "cebu_ceb_ph": "Cebu (CEB, PH)",
        "cedar_hill_tx_us": "Cedar Hill (TX, US)",
        "cedar_rapids_ia_us": "Cedar Rapids (IA, US)",
        "celaya_gua_mx": "Celaya (GUA, MX)",
        "celeste_tx_us": "Celeste (TX, US)",
        "celina_tx_us": "Celina (TX, US)",
        "cerritos_ca_us": "Cerritos (CA, US)",
        "chambersburg_pa_us": "Chambersburg (PA, US)",
        "chandigarh_ch_in": "Chandigarh (CH, IN)",
        "chandler_az_us": "Chandler (AZ, US)",
        "chapel_hill_nc_us": "Chapel Hill (NC, US)",
        "charleston_sc_us": "Charleston (SC, US)",
        "charlotte_nc_us": "Charlotte (NC, US)",
        "charlottesville_va_us": "Charlottesville (VA, US)",
        "chatsworth_ca_us": "Chatsworth (CA, US)",
        "chattanooga_tn_us": "Chattanooga (TN, US)",
        "cheboksary_cu_ru": "Cheboksary (CU, RU)",
        "chekhov_mos_ru": "Chekhov (MOS, RU)",
        "chelyabinsk_che_ru": "Chelyabinsk (CHE, RU)",
        "chemnitz_sn_de": "Chemnitz (SN, DE)",
        "cheney_wa_us": "Cheney (WA, US)",
        "chennai_tn_in": "Chennai (TN, IN)",
        "cherepovets_vlg_ru": "Cherepovets (VLG, RU)",
        "cherkessk_kc_ru": "Cherkessk (KC, RU)",
        "chesapeake_va_us": "Chesapeake (VA, US)",
        "cheyenne_wy_us": "Cheyenne (WY, US)",
        "chia_cun_co": "Chia (CUN, CO)",
        "chiang_mai_50_th": "Chiang Mai (50, TH)",
        "chiang_rai_57_th": "Chiang Rai (57, TH)",
        "chicago_il_us": "Chicago (IL, US)",
        "chiclayo_lam_pe": "Chiclayo (LAM, PE)",
        "chico_ca_us": "Chico (CA, US)",
        "chicoutimi_qc_ca": "Chicoutimi (QC, CA)",
        "chihuahua_chh_mx": "Chihuahua (CHH, MX)",
        "chilliwack_bc_ca": "Chilliwack (BC, CA)",
        "christchurch_can_nz": "Christchurch (CAN, NZ)",
        "chula_vista_ca_us": "Chula Vista (CA, US)",
        "cilacap_jt_id": "Cilacap (JT, ID)",
        "cilacap_selatan_jt_id": "Cilacap Selatan (JT, ID)",
        "cincinnati_oh_us": "Cincinnati (OH, US)",
        "cirebon_jb_id": "Cirebon (JB, ID)",
        "cirebon_selatan_jb_id": "Cirebon Selatan (JB, ID)",
        "ciudad_obregon_son_mx": "Ciudad Obregon (SON, MX)",
        "ciudad_victoria_tam_mx": "Ciudad Victoria (TAM, MX)",
        "claremont_ca_us": "Claremont (CA, US)",
        "clarkesville_tn_us": "Clarkesville (TN, US)",
        "clarksburg_wv_us": "Clarksburg (WV, US)",
        "clarksville_tn_us": "Clarksville (TN, US)",
        "clearwater_fl_us": "Clearwater (FL, US)",
        "cleburne_tx_us": "Cleburne (TX, US)",
        "clemson_sc_us": "Clemson (SC, US)",
        "clermontferrand_ara_fr": "Clermont-Ferrand (ARA, FR)",
        "cleveland_oh_us": "Cleveland (OH, US)",
        "clichy_idf_fr": "Clichy (IDF, FR)",
        "coeur_d_alene_id_us": "Coeur d Alene (ID, US)",
        "coimbatore_tn_in": "Coimbatore (TN, IN)",
        "colatina_es_br": "Colatina (ES, BR)",
        "colima_col_mx": "Colima (COL, MX)",
        "college_station_tx_us": "College Station (TX, US)",
        "colleyville_tx_us": "Colleyville (TX, US)",
        "collingwood_on_ca": "Collingwood (ON, CA)",
        "colmschate_ov_nl": "Colmschate (OV, NL)",
        "cologne_nw_de": "Cologne (NW, DE)",
        "colombo_pr_br": "Colombo (PR, BR)",
        "colorado_springs_co_us": "Colorado Springs (CO, US)",
        "columbia_mo_us": "Columbia (MO, US)",
        "columbia_sc_us": "Columbia (SC, US)",
        "columbus_ga_us": "Columbus (GA, US)",
        "columbus_oh_us": "Columbus (OH, US)",
        "commerce_tx_us": "Commerce (TX, US)",
        "compton_ca_us": "Compton (CA, US)",
        "concepcion_bi_cl": "Concepcion (BI, CL)",
        "concord_ca_us": "Concord (CA, US)",
        "concord_pa_us": "Concord (PA, US)",
        "contagem_mg_br": "Contagem (MG, BR)",
        "conway_ar_us": "Conway (AR, US)",
        "cookeville_tn_us": "Cookeville (TN, US)",
        "copenhagen_84_dk": "Copenhagen (84, DK)",
        "copeville_tx_us": "Copeville (TX, US)",
        "copiapo_at_cl": "Copiapo (AT, CL)",
        "coppell_tx_us": "Coppell (TX, US)",
        "coral_gables_fl_us": "Coral Gables (FL, US)",
        "cordoba_co_es": "Cordoba (CO, ES)",
        "cornelio_procopio_pr_br": "Cornelio Procopio (PR, BR)",
        "corona_ca_us": "Corona (CA, US)",
        "coronel_fabriciano_mg_br": "Coronel Fabriciano (MG, BR)",
        "corpus_christi_tx_us": "Corpus Christi (TX, US)",
        "cosmopolis_sp_br": "Cosmopolis (SP, BR)",
        "costa_mesa_ca_us": "Costa Mesa (CA, US)",
        "cotia_sp_br": "Cotia (SP, BR)",
        "coventry_cov_uk": "Coventry (COV, UK)",
        "covina_ca_us": "Covina (CA, US)",
        "crandall_tx_us": "Crandall (TX, US)",
        "cresson_tx_us": "Cresson (TX, US)",
        "criciuma_sc_br": "Criciuma (SC, BR)",
        "crowley_tx_us": "Crowley (TX, US)",
        "cruz_das_almas_ba_br": "Cruz das Almas (BA, BR)",
        "cruzeiro_sp_br": "Cruzeiro (SP, BR)",
        "cuautitlan_mex_mx": "Cuautitlan (MEX, MX)",
        "cucuta_nsa_co": "Cucuta (NSA, CO)",
        "cuddalore_tn_in": "Cuddalore (TN, IN)",
        "cuddapah_ap_in": "Cuddapah (AP, IN)",
        "cuernavaca_mor_mx": "Cuernavaca (MOR, MX)",
        "cuiaba_mt_br": "Cuiaba (MT, BR)",
        "culpeper_va_us": "Culpeper (VA, US)",
        "culver_city_ca_us": "Culver City (CA, US)",
        "curitiba_pr_br": "Curitiba (PR, BR)",
        "cusco_cus_pe": "Cusco (CUS, PE)",
        "cypress_ca_us": "Cypress (CA, US)",
        "dallas_tx_us": "Dallas (TX, US)",
        "daly_city_ca_us": "Daly City (CA, US)",
        "dana_point_ca_us": "Dana Point (CA, US)",
        "danville_va_us": "Danville (VA, US)",
        "darmstadt_he_de": "Darmstadt (HE, DE)",
        "dartmouth_ns_ca": "Dartmouth (NS, CA)",
        "davao_city_das_ph": "Davao City (DAS, PH)",
        "davenport_ia_us": "Davenport (IA, US)",
        "dayton_oh_us": "Dayton (OH, US)",
        "daytona_beach_fl_us": "Daytona Beach (FL, US)",
        "de_bilt_ut_nl": "De Bilt (UT, NL)",
        "de_meern_ut_nl": "De Meern (UT, NL)",
        "decatur_tx_us": "Decatur (TX, US)",
        "delfgauw_zh_nl": "Delfgauw (ZH, NL)",
        "delft_zh_nl": "Delft (ZH, NL)",
        "delhi_dl_in": "Delhi (DL, IN)",
        "delta_bc_ca": "Delta (BC, CA)",
        "den_dolder_ut_nl": "Den Dolder (UT, NL)",
        "den_hoorn_nh_nl": "Den Hoorn (NH, NL)",
        "den_hoorn_zh_nl": "Den Hoorn (ZH, NL)",
        "dennis_tx_us": "Dennis (TX, US)",
        "denton_tx_us": "Denton (TX, US)",
        "denver_co_us": "Denver (CO, US)",
        "depok_jaya_jb_id": "Depok Jaya (JB, ID)",
        "depok_jb_id": "Depok (JB, ID)",
        "derbent_da_ru": "Derbent (DA, RU)",
        "derby_ks_us": "Derby (KS, US)",
        "des_moines_ia_us": "Des Moines (IA, US)",
        "desoto_tx_us": "Desoto (TX, US)",
        "detroit_mi_us": "Detroit (MI, US)",
        "deurne_nb_nl": "Deurne (NB, NL)",
        "deventer_ov_nl": "Deventer (OV, NL)",
        "dhanbad_jh_in": "Dhanbad (JH, IN)",
        "diadema_sp_br": "Diadema (SP, BR)",
        "diamond_bar_ca_us": "Diamond Bar (CA, US)",
        "dibrugarh_as_in": "Dibrugarh (AS, IN)",
        "dickinson_nd_us": "Dickinson (ND, US)",
        "diemen_nh_nl": "Diemen (NH, NL)",
        "diepenveen_ov_nl": "Diepenveen (OV, NL)",
        "dieppe_nb_ca": "Dieppe (NB, CA)",
        "dimitrovgrad_uly_ru": "Dimitrovgrad (ULY, RU)",
        "dolgoprudny_mos_ru": "Dolgoprudny (MOS, RU)",
        "domodedovo_mos_ru": "Domodedovo (MOS, RU)",
        "dordrecht_zh_nl": "Dordrecht (ZH, NL)",
        "dortmund_nw_de": "Dortmund (NW, DE)",
        "dos_hermanas_ma_es": "Dos Hermanas (MA, ES)",
        "dosquebradas_ris_co": "Dosquebradas (RIS, CO)",
        "dothan_al_us": "Dothan (AL, US)",
        "dourados_ms_br": "Dourados (MS, BR)",
        "dover_de_us": "Dover (DE, US)",
        "downey_ca_us": "Downey (CA, US)",
        "dresden_sn_de": "Dresden (SN, DE)",
        "driebergenrijsenburg_ut_nl": "Driebergen-Rijsenburg (UT, NL)",
        "driehuis_nh_nl": "Driehuis (NH, NL)",
        "duarte_ca_us": "Duarte (CA, US)",
        "dubai_du_ae": "Dubai (DU, AE)",
        "dubuque_ia_us": "Dubuque (IA, US)",
        "duisburg_nw_de": "Duisburg (NW, DE)",
        "duiven_ge_nl": "Duiven (GE, NL)",
        "duivendrecht_nh_nl": "Duivendrecht (NH, NL)",
        "duncanville_tx_us": "Duncanville (TX, US)",
        "durgapur_br_in": "Durgapur (BR, IN)",
        "durham_nc_us": "Durham (NC, US)",
        "dusseldorf_nw_de": "Dusseldorf (NW, DE)",
        "dzerzhinsk_niz_ru": "Dzerzhinsk (NIZ, RU)",
        "dzerzhinsky_mos_ru": "Dzerzhinsky (MOS, RU)",
        "eagle_pass_tx_us": "Eagle Pass (TX, US)",
        "east_stroudsburg_pa_us": "East Stroudsburg (PA, US)",
        "eau_claire_wi_us": "Eau Claire (WI, US)",
        "edinburgh_edh_uk": "Edinburgh (EDH, UK)",
        "edison_nj_us": "Edison (NJ, US)",
        "edmonton_ab_ca": "Edmonton (AB, CA)",
        "eede_ze_nl": "Eede (ZE, NL)",
        "eindhoven_nb_nl": "Eindhoven (NB, NL)",
        "ekaterinburg_sve_ru": "Ekaterinburg (SVE, RU)",
        "el_centro_ca_us": "El Centro (CA, US)",
        "el_monte_ca_us": "El Monte (CA, US)",
        "el_paso_tx_us": "El Paso (TX, US)",
        "el_segundo_ca_us": "El Segundo (CA, US)",
        "elgin_il_us": "Elgin (IL, US)",
        "elista_kl_ru": "Elista (KL, RU)",
        "elizabeth_nj_us": "Elizabeth (NJ, US)",
        "elizabethtown_ky_us": "Elizabethtown (KY, US)",
        "elk_grove_ca_us": "Elk Grove (CA, US)",
        "elkhart_in_us": "Elkhart (IN, US)",
        "elmo_tx_us": "Elmo (TX, US)",
        "elx_elche_a_es": "Elx/Elche (A, ES)",
        "embu_das_artes_sp_br": "Embu das Artes (SP, BR)",
        "embuguacu_sp_br": "Embu-Guacu (SP, BR)",
        "encino_ca_us": "Encino (CA, US)",
        "ennis_tx_us": "Ennis (TX, US)",
        "enschede_ov_nl": "Enschede (OV, NL)",
        "erfurt_th_de": "Erfurt (TH, DE)",
        "erie_pa_us": "Erie (PA, US)",
        "erlangen_by_de": "Erlangen (BY, DE)",
        "escondido_ca_us": "Escondido (CA, US)",
        "esmeraldas_mg_br": "Esmeraldas (MG, BR)",
        "essen_ni_de": "Essen (NI, DE)",
        "estancia_velha_rs_br": "Estancia Velha (RS, BR)",
        "esteio_rs_br": "Esteio (RS, BR)",
        "etobicoke_on_ca": "Etobicoke (ON, CA)",
        "eugene_or_us": "Eugene (OR, US)",
        "euless_tx_us": "Euless (TX, US)",
        "eureka_ca_us": "Eureka (CA, US)",
        "evanston_il_us": "Evanston (IL, US)",
        "evansville_in_us": "Evansville (IN, US)",
        "everett_wa_us": "Everett (WA, US)",
        "ewijk_ge_nl": "Ewijk (GE, NL)",
        "fairbanks_ak_us": "Fairbanks (AK, US)",
        "fairfield_ca_us": "Fairfield (CA, US)",
        "fargo_nd_us": "Fargo (ND, US)",
        "faridabad_city_hr_in": "Faridabad City (HR, IN)",
        "farmersville_tx_us": "Farmersville (TX, US)",
        "fate_tx_us": "Fate (TX, US)",
        "fayetteville_nc_us": "Fayetteville (NC, US)",
        "federal_way_wa_us": "Federal Way (WA, US)",
        "feira_de_santana_ba_br": "Feira de Santana (BA, BR)",
        "fenton_mi_us": "Fenton (MI, US)",
        "ferraz_de_vasconcelos_sp_br": "Ferraz de Vasconcelos (SP, BR)",
        "ferris_tx_us": "Ferris (TX, US)",
        "flagstaff_az_us": "Flagstaff (AZ, US)",
        "flint_mi_us": "Flint (MI, US)",
        "florence_fi_it": "Florence (FI, IT)",
        "florianopolis_sc_br": "Florianopolis (SC, BR)",
        "flower_mound_tx_us": "Flower Mound (TX, US)",
        "foggia_fg_it": "Foggia (FG, IT)",
        "fontana_ca_us": "Fontana (CA, US)",
        "forest_park_il_us": "Forest Park (IL, US)",
        "formiga_mg_br": "Formiga (MG, BR)",
        "forney_tx_us": "Forney (TX, US)",
        "forreston_tx_us": "Forreston (TX, US)",
        "fort_mcmurray_ab_ca": "Fort McMurray (AB, CA)",
        "fort_saskatchewan_ab_ca": "Fort Saskatchewan (AB, CA)",
        "fort_smith_ar_us": "Fort Smith (AR, US)",
        "fortaleza_ce_br": "Fortaleza (CE, BR)",
        "fountain_valley_ca_us": "Fountain Valley (CA, US)",
        "francisco_morato_sp_br": "Francisco Morato (SP, BR)",
        "franco_da_rocha_sp_br": "Franco da Rocha (SP, BR)",
        "frankfurt_am_main_he_de": "Frankfurt am Main (HE, DE)",
        "frederick_md_us": "Frederick (MD, US)",
        "freiburg_ni_de": "Freiburg (NI, DE)",
        "fremont_ca_us": "Fremont (CA, US)",
        "fresno_ca_us": "Fresno (CA, US)",
        "frisco_tx_us": "Frisco (TX, US)",
        "ft_collins_co_us": "Ft Collins (CO, US)",
        "ft_lauderdale_fl_us": "Ft Lauderdale (FL, US)",
        "ft_myers_fl_us": "Ft Myers (FL, US)",
        "ft_walton_beach_fl_us": "Ft Walton Beach (FL, US)",
        "ft_wayne_in_us": "Ft Wayne (IN, US)",
        "ft_worth_tx_us": "Ft Worth (TX, US)",
        "fuenlabrada_m_es": "Fuenlabrada (M, ES)",
        "fullerton_ca_us": "Fullerton (CA, US)",
        "funza_cun_co": "Funza (CUN, CO)",
        "fürth_by_de": "Fürth (BY, DE)",
        "gainesville_fl_us": "Gainesville (FL, US)",
        "gallup_nm_us": "Gallup (NM, US)",
        "galveston_tx_us": "Galveston (TX, US)",
        "garches_idf_fr": "Garches (IDF, FR)",
        "garden_grove_ca_us": "Garden Grove (CA, US)",
        "gardena_ca_us": "Gardena (CA, US)",
        "gardner_ma_us": "Gardner (MA, US)",
        "garland_tx_us": "Garland (TX, US)",
        "garopaba_sc_br": "Garopaba (SC, BR)",
        "garut_jb_id": "Garut (JB, ID)",
        "gary_in_us": "Gary (IN, US)",
        "gatchina_len_ru": "Gatchina (LEN, RU)",
        "gatineau_qc_ca": "Gatineau (QC, CA)",
        "gaya_br_in": "Gaya (BR, IN)",
        "geldrop_nb_nl": "Geldrop (NB, NL)",
        "gelsenkirchen_nw_de": "Gelsenkirchen (NW, DE)",
        "geneve_ge_ch": "Geneve (GE, CH)",
        "genova_ge_it": "Genova (GE, IT)",
        "george_town_07_my": "George Town (07, MY)",
        "gera_th_de": "Gera (TH, DE)",
        "getafe_m_es": "Getafe (M, ES)",
        "ghaziabad_up_in": "Ghaziabad (UP, IN)",
        "gig_harbor_wa_us": "Gig Harbor (WA, US)",
        "gijón_o_es": "Gijón (O, ES)",
        "gilbert_az_us": "Gilbert (AZ, US)",
        "glasgow_glg_uk": "Glasgow (GLG, UK)",
        "glazov_ud_ru": "Glazov (UD, RU)",
        "glen_rose_tx_us": "Glen Rose (TX, US)",
        "glendale_az_us": "Glendale (AZ, US)",
        "glendale_ca_us": "Glendale (CA, US)",
        "glendora_ca_us": "Glendora (CA, US)",
        "glens_falls_ny_us": "Glens Falls (NY, US)",
        "godley_tx_us": "Godley (TX, US)",
        "goianesia_go_br": "Goianesia (GO, BR)",
        "goiania_go_br": "Goiania (GO, BR)",
        "goias_go_br": "Goias (GO, BR)",
        "goirle_nb_nl": "Goirle (NB, NL)",
        "gold_coast_qld_au": "Gold Coast (QLD, AU)",
        "goldsboro_nc_us": "Goldsboro (NC, US)",
        "gorakhpur_up_in": "Gorakhpur (UP, IN)",
        "gouda_zh_nl": "Gouda (ZH, NL)",
        "granada_gr_es": "Granada (GR, ES)",
        "granbury_tx_us": "Granbury (TX, US)",
        "grand_forks_bc_ca": "Grand Forks (BC, CA)",
        "grand_prairie_tx_us": "Grand Prairie (TX, US)",
        "grand_rapids_mi_us": "Grand Rapids (MI, US)",
        "grandview_tx_us": "Grandview (TX, US)",
        "grapevine_tx_us": "Grapevine (TX, US)",
        "gravatai_rs_br": "Gravatai (RS, BR)",
        "greeley_co_us": "Greeley (CO, US)",
        "green_bay_wi_us": "Green Bay (WI, US)",
        "greensboro_nc_us": "Greensboro (NC, US)",
        "greenville_nc_us": "Greenville (NC, US)",
        "greenwood_tx_us": "Greenwood (TX, US)",
        "grenoble_ara_fr": "Grenoble (ARA, FR)",
        "gresham_or_us": "Gresham (OR, US)",
        "gresik_ji_id": "Gresik (JI, ID)",
        "grozny_ce_ru": "Grozny (CE, RU)",
        "grubbenvorst_li_nl": "Grubbenvorst (LI, NL)",
        "guadalajara_jal_mx": "Guadalajara (JAL, MX)",
        "guadalupe_nle_mx": "Guadalupe (NLE, MX)",
        "guaratuba_pr_br": "Guaratuba (PR, BR)",
        "guariba_sp_br": "Guariba (SP, BR)",
        "guaruja_sp_br": "Guaruja (SP, BR)",
        "guarulhos_sp_br": "Guarulhos (SP, BR)",
        "guatemala_city_gu_gt": "Guatemala City (GU, GT)",
        "guayaquil_g_ec": "Guayaquil (G, EC)",
        "guelph_on_ca": "Guelph (ON, CA)",
        "gulfport_ms_us": "Gulfport (MS, US)",
        "guntur_ap_in": "Guntur (AP, IN)",
        "gurgaon_hr_in": "Gurgaon (HR, IN)",
        "guwahati_as_in": "Guwahati (AS, IN)",
        "göttingen_ni_de": "Göttingen (NI, DE)",
        "haarlem_nh_nl": "Haarlem (NH, NL)",
        "hael_06_sa": "Hael (06, SA)",
        "hagen_ni_de": "Hagen (NI, DE)",
        "halifax_ns_ca": "Halifax (NS, CA)",
        "halle_ni_de": "Halle (NI, DE)",
        "haltom_city_tx_us": "Haltom City (TX, US)",
        "hamburg_hh_de": "Hamburg (HH, DE)",
        "hamilton_on_ca": "Hamilton (ON, CA)",
        "hamm_nw_de": "Hamm (NW, DE)",
        "hammond_la_us": "Hammond (LA, US)",
        "hampton_va_us": "Hampton (VA, US)",
        "hanford_ca_us": "Hanford (CA, US)",
        "hannover_ni_de": "Hannover (NI, DE)",
        "hanoi_hn_vn": "Hanoi (HN, VN)",
        "hanover_pa_us": "Hanover (PA, US)",
        "harlingen_tx_us": "Harlingen (TX, US)",
        "harrisburg_nc_us": "Harrisburg (NC, US)",
        "hartford_ct_us": "Hartford (CT, US)",
        "haslet_tx_us": "Haslet (TX, US)",
        "hattiesburg_ms_us": "Hattiesburg (MS, US)",
        "hawaiian_gardens_ca_us": "Hawaiian Gardens (CA, US)",
        "hawthorne_ca_us": "Hawthorne (CA, US)",
        "hayward_ca_us": "Hayward (CA, US)",
        "healdsburg_ca_us": "Healdsburg (CA, US)",
        "heemstede_nh_nl": "Heemstede (NH, NL)",
        "heerhugowaard_nh_nl": "Heerhugowaard (NH, NL)",
        "heerlen_li_nl": "Heerlen (LI, NL)",
        "heidelberg_bw_de": "Heidelberg (BW, DE)",
        "heilbronn_bw_de": "Heilbronn (BW, DE)",
        "heilig_landstichting_ge_nl": "Heilig Landstichting (GE, NL)",
        "hekelingen_zh_nl": "Hekelingen (ZH, NL)",
        "helmond_nb_nl": "Helmond (NB, NL)",
        "henderson_nv_us": "Henderson (NV, US)",
        "hendrikidoambacht_zh_nl": "Hendrik-Ido-Ambacht (ZH, NL)",
        "hengelo_ov_nl": "Hengelo (OV, NL)",
        "hermosa_beach_ca_us": "Hermosa Beach (CA, US)",
        "hermosillo_son_mx": "Hermosillo (SON, MX)",
        "herne_nw_de": "Herne (NW, DE)",
        "hialeah_fl_us": "Hialeah (FL, US)",
        "hickory_nc_us": "Hickory (NC, US)",
        "high_point_nc_us": "High Point (NC, US)",
        "hildesheim_ni_de": "Hildesheim (NI, DE)",
        "hillegom_zh_nl": "Hillegom (ZH, NL)",
        "hinesville_ga_us": "Hinesville (GA, US)",
        "hoensbroek_li_nl": "Hoensbroek (LI, NL)",
        "holland_mi_us": "Holland (MI, US)",
        "hollywood_fl_us": "Hollywood (FL, US)",
        "hong_kong_1_hk": "Hong Kong (-1, HK)",
        "honokaa_hi_us": "Honokaa (HI, US)",
        "honolulu_hi_us": "Honolulu (HI, US)",
        "hoofddorp_nh_nl": "Hoofddorp (NH, NL)",
        "hoogland_ut_nl": "Hoogland (UT, NL)",
        "hooglanderveen_ut_nl": "Hooglanderveen (UT, NL)",
        "hoogvliet_rotterdam_zh_nl": "Hoogvliet Rotterdam (ZH, NL)",
        "horizonte_ce_br": "Horizonte (CE, BR)",
        "houston_tx_us": "Houston (TX, US)",
        "houten_ut_nl": "Houten (UT, NL)",
        "hove_bnh_uk": "Hove (BNH, UK)",
        "howrah_wb_in": "Howrah (WB, IN)",
        "huelva_h_es": "Huelva (H, ES)",
        "huis_ter_heide_ut_nl": "Huis ter Heide (UT, NL)",
        "hulsberg_li_nl": "Hulsberg (LI, NL)",
        "huntington_beach_ca_us": "Huntington Beach (CA, US)",
        "huntington_park_ca_us": "Huntington Park (CA, US)",
        "huntington_wv_us": "Huntington (WV, US)",
        "huntsville_al_us": "Huntsville (AL, US)",
        "hurst_tx_us": "Hurst (TX, US)",
        "hutchins_tx_us": "Hutchins (TX, US)",
        "hyderabad_tg_in": "Hyderabad (TG, IN)",
        "ibague_tol_co": "Ibague (TOL, CO)",
        "ibirite_mg_br": "Ibirite (MG, BR)",
        "idaho_falls_id_us": "Idaho Falls (ID, US)",
        "igarape_mg_br": "Igarape (MG, BR)",
        "ijmuiden_nh_nl": "IJmuiden (NH, NL)",
        "ijsselstein_ut_nl": "IJsselstein (UT, NL)",
        "ijui_rs_br": "Ijui (RS, BR)",
        "ilhabela_sp_br": "Ilhabela (SP, BR)",
        "iloilo_ili_ph": "Iloilo (ILI, PH)",
        "independence_mo_us": "Independence (MO, US)",
        "indianapolis_in_us": "Indianapolis (IN, US)",
        "indore_mp_in": "Indore (MP, IN)",
        "inglewood_ca_us": "Inglewood (CA, US)",
        "ingolstadt_by_de": "Ingolstadt (BY, DE)",
        "inland_ne_us": "Inland (NE, US)",
        "iowa_city_ia_us": "Iowa City (IA, US)",
        "ipoh_08_my": "Ipoh (08, MY)",
        "irati_pr_br": "Irati (PR, BR)",
        "irvine_ca_us": "Irvine (CA, US)",
        "irving_tx_us": "Irving (TX, US)",
        "issylesmoulineaux_idf_fr": "Issy-les-Moulineaux (IDF, FR)",
        "istanbul_34_tr": "Istanbul (34, TR)",
        "itagui_ant_co": "Itagui (ANT, CO)",
        "itajai_sc_br": "Itajai (SC, BR)",
        "italy_tx_us": "Italy (TX, US)",
        "itanhaem_sp_br": "Itanhaem (SP, BR)",
        "itapecerica_da_serra_sp_br": "Itapecerica da Serra (SP, BR)",
        "itapevi_sp_br": "Itapevi (SP, BR)",
        "itaquaquecetuba_sp_br": "Itaquaquecetuba (SP, BR)",
        "itauna_mg_br": "Itauna (MG, BR)",
        "ivanovo_iva_ru": "Ivanovo (IVA, RU)",
        "ivanteevka_mos_ru": "Ivanteevka (MOS, RU)",
        "ivrysurseine_idf_fr": "Ivry-sur-Seine (IDF, FR)",
        "izhevsk_ud_ru": "Izhevsk (UD, RU)",
        "jabalpur_mp_in": "Jabalpur (MP, IN)",
        "jaboatao_dos_guararapes_pe_br": "Jaboatao dos Guararapes (PE, BR)",
        "jackson_ms_us": "Jackson (MS, US)",
        "jackson_tn_us": "Jackson (TN, US)",
        "jacksonville_fl_us": "Jacksonville (FL, US)",
        "jaipur_rj_in": "Jaipur (RJ, IN)",
        "jakarta_jk_id": "Jakarta (JK, ID)",
        "jalandhar_city_pb_in": "Jalandhar City (PB, IN)",
        "jamaica_ny_us": "Jamaica (NY, US)",
        "jamshedpur_jk_in": "Jamshedpur (JK, IN)",
        "jandira_sp_br": "Jandira (SP, BR)",
        "janesville_wi_us": "Janesville (WI, US)",
        "jaén_j_es": "Jaén (J, ES)",
        "jember_ji_id": "Jember (JI, ID)",
        "jemberkidul_ji_id": "Jemberkidul (JI, ID)",
        "jena_th_de": "Jena (TH, DE)",
        "jerez_de_la_frontera_ca_es": "Jerez de la Frontera (CA, ES)",
        "jersey_city_nj_us": "Jersey City (NJ, US)",
        "jhansi_up_in": "Jhansi (UP, IN)",
        "jiddah_02_sa": "Jiddah (02, SA)",
        "joao_monlevade_mg_br": "Joao Monlevade (MG, BR)",
        "joao_pessoa_pb_br": "Joao Pessoa (PB, BR)",
        "jodhpur_rj_in": "Jodhpur (RJ, IN)",
        "johnson_city_tn_us": "Johnson City (TN, US)",
        "johnstown_pa_us": "Johnstown (PA, US)",
        "johor_bahru_01_my": "Johor Bahru (01, MY)",
        "joinville_sc_br": "Joinville (SC, BR)",
        "joinvillelepont_idf_fr": "Joinville-le-Pont (IDF, FR)",
        "joliet_il_us": "Joliet (IL, US)",
        "jonesboro_ar_us": "Jonesboro (AR, US)",
        "jonesboro_ga_us": "Jonesboro (GA, US)",
        "jonquière_qc_ca": "Jonquière (QC, CA)",
        "joplin_mo_us": "Joplin (MO, US)",
        "jorhat_as_in": "Jorhat (AS, IN)",
        "josephine_tx_us": "Josephine (TX, US)",
        "joshua_tx_us": "Joshua (TX, US)",
        "juarez_chh_mx": "Juarez (CHH, MX)",
        "juazeiro_do_norte_ce_br": "Juazeiro do Norte (CE, BR)",
        "juiz_de_fora_mg_br": "Juiz de Fora (MG, BR)",
        "juneau_ak_us": "Juneau (AK, US)",
        "justin_tx_us": "Justin (TX, US)",
        "kahului_hi_us": "Kahului (HI, US)",
        "kakinada_ap_in": "Kakinada (AP, IN)",
        "kalamazoo_mi_us": "Kalamazoo (MI, US)",
        "kaliningrad_kgd_ru": "Kaliningrad (KGD, RU)",
        "kaluga_klu_ru": "Kaluga (KLU, RU)",
        "kalyan_city_mh_in": "Kalyan City (MH, IN)",
        "kamloops_bc_ca": "Kamloops (BC, CA)",
        "kanata_on_ca": "Kanata (ON, CA)",
        "kanpur_up_in": "Kanpur (UP, IN)",
        "kansas_city_ks_us": "Kansas City (KS, US)",
        "kansas_city_mo_us": "Kansas City (MO, US)",
        "kaohsiung_khh_tw": "Kaohsiung (KHH, TW)",
        "karawang_jb_id": "Karawang (JB, ID)",
        "karawang_timur_jb_id": "Karawang Timur (JB, ID)",
        "karawang_wetan_jb_id": "Karawang Wetan (JB, ID)",
        "karawangpawitan_jb_id": "Karawangpawitan (JB, ID)",
        "karimnagar_tg_in": "Karimnagar (TG, IN)",
        "karlsruhe_bw_de": "Karlsruhe (BW, DE)",
        "karnal_hr_in": "Karnal (HR, IN)",
        "kassel_he_de": "Kassel (HE, DE)",
        "kaufman_tx_us": "Kaufman (TX, US)",
        "kazan_ta_ru": "Kazan (TA, RU)",
        "kediri_ba_id": "Kediri (BA, ID)",
        "kediri_ji_id": "Kediri (JI, ID)",
        "kediri_nb_id": "Kediri (NB, ID)",
        "keene_tx_us": "Keene (TX, US)",
        "keller_tx_us": "Keller (TX, US)",
        "kelowna_bc_ca": "Kelowna (BC, CA)",
        "kemp_tx_us": "Kemp (TX, US)",
        "kennedale_tx_us": "Kennedale (TX, US)",
        "kennewick_wa_us": "Kennewick (WA, US)",
        "kent_wa_us": "Kent (WA, US)",
        "khabarovsk_kha_ru": "Khabarovsk (KHA, RU)",
        "khasavyurt_da_ru": "Khasavyurt (DA, RU)",
        "khimki_mos_ru": "Khimki (MOS, RU)",
        "khobar_04_sa": "Khobar (04, SA)",
        "kiel_sh_de": "Kiel (SH, DE)",
        "kihei_hi_us": "Kihei (HI, US)",
        "killeen_tx_us": "Killeen (TX, US)",
        "kingman_az_us": "Kingman (AZ, US)",
        "kingston_on_ca": "Kingston (ON, CA)",
        "kirkland_qc_ca": "Kirkland (QC, CA)",
        "kissimmee_fl_us": "Kissimmee (FL, US)",
        "kitchener_on_ca": "Kitchener (ON, CA)",
        "knoxville_tn_us": "Knoxville (TN, US)",
        "koblenz_rp_de": "Koblenz (RP, DE)",
        "kochi_kl_in": "Kochi (KL, IN)",
        "koedijk_nh_nl": "Koedijk (NH, NL)",
        "kolhapur_mh_in": "Kolhapur (MH, IN)",
        "kolomna_mos_ru": "Kolomna (MOS, RU)",
        "kommunarka_mos_ru": "Kommunarka (MOS, RU)",
        "koog_aan_de_zaan_nh_nl": "Koog aan de Zaan (NH, NL)",
        "korolev_mos_ru": "Korolev (MOS, RU)",
        "kostroma_kos_ru": "Kostroma (KOS, RU)",
        "kota_kinabalu_12_my": "Kota Kinabalu (12, MY)",
        "kota_rj_in": "Kota (RJ, IN)",
        "kotelniki_mos_ru": "Kotelniki (MOS, RU)",
        "krasnodar_kda_ru": "Krasnodar (KDA, RU)",
        "krasnogorsk_mos_ru": "Krasnogorsk (MOS, RU)",
        "krasnoyarsk_kya_ru": "Krasnoyarsk (KYA, RU)",
        "krefeld_nw_de": "Krefeld (NW, DE)",
        "krum_tx_us": "Krum (TX, US)",
        "kuala_lumpur_14_my": "Kuala Lumpur (14, MY)",
        "kuching_13_my": "Kuching (13, MY)",
        "kurnool_ap_in": "Kurnool, (AP, IN)",
        "kyzyl_ty_ru": "Kyzyl (TY, RU)",
        "la_baie_qc_ca": "La Baie (QC, CA)",
        "la_canada_flintridge_ca_us": "La Canada Flintridge (CA, US)",
        "la_coruna_c_es": "La Coruna (C, ES)",
        "la_crosse_wi_us": "La Crosse (WI, US)",
        "la_habra_ca_us": "La Habra (CA, US)",
        "la_mirada_ca_us": "La Mirada (CA, US)",
        "la_palma_ca_us": "La Palma (CA, US)",
        "la_paz_l_bo": "La Paz (L, BO)",
        "la_puente_ca_us": "La Puente (CA, US)",
        "la_serena_co_cl": "La Serena (CO, CL)",
        "la_verne_ca_us": "La Verne (CA, US)",
        "lafayette_la_us": "Lafayette (LA, US)",
        "laguna_beach_ca_us": "Laguna Beach (CA, US)",
        "laguna_hills_ca_us": "Laguna Hills (CA, US)",
        "laguna_niguel_ca_us": "Laguna Niguel (CA, US)",
        "laguna_woods_ca_us": "Laguna Woods (CA, US)",
        "lajeado_rs_br": "Lajeado (RS, BR)",
        "lake_dallas_tx_us": "Lake Dallas (TX, US)",
        "lake_forest_ca_us": "Lake Forest (CA, US)",
        "lake_havasu_city_az_us": "Lake Havasu City (AZ, US)",
        "lake_jackson_tx_us": "Lake Jackson (TX, US)",
        "lakewood_co_us": "Lakewood (CO, US)",
        "lancaster_ca_us": "Lancaster (CA, US)",
        "lancaster_pa_us": "Lancaster (PA, US)",
        "landsmeer_nh_nl": "Landsmeer (NH, NL)",
        "lansing_mi_us": "Lansing (MI, US)",
        "laredo_tx_us": "Laredo (TX, US)",
        "las_cruces_nm_us": "Las Cruces (NM, US)",
        "las_palmas_de_gran_canaria_gc_es": "Las Palmas de Gran Canaria (GC, ES)",
        "las_vegas_nv_us": "Las Vegas (NV, US)",
        "lauderdale_ms_us": "Lauderdale (MS, US)",
        "lausanne_vd_ch": "Lausanne (VD, CH)",
        "laval_qc_ca": "Laval (QC, CA)",
        "lavon_tx_us": "Lavon (TX, US)",
        "lawndale_ca_us": "Lawndale (CA, US)",
        "lawrence_ks_us": "Lawrence (KS, US)",
        "lawton_ok_us": "Lawton (OK, US)",
        "le_havre_nor_fr": "Le Havre (NOR, FR)",
        "leduc_ab_ca": "Leduc (AB, CA)",
        "leeds_lds_uk": "Leeds (LDS, UK)",
        "leganés_m_es": "Leganés (M, ES)",
        "leicester_lce_uk": "Leicester (LCE, UK)",
        "leiden_zh_nl": "Leiden (ZH, NL)",
        "leiderdorp_zh_nl": "Leiderdorp (ZH, NL)",
        "leidschendam_zh_nl": "Leidschendam (ZH, NL)",
        "leipzig_sn_de": "Leipzig (SN, DE)",
        "lent_ge_nl": "Lent (GE, NL)",
        "leon_gua_mx": "Leon (GUA, MX)",
        "lethbridge_ab_ca": "Lethbridge (AB, CA)",
        "leusden_ut_nl": "Leusden (UT, NL)",
        "levalloisperret_idf_fr": "Levallois-Perret (IDF, FR)",
        "leverkusen_nw_de": "Leverkusen (NW, DE)",
        "levis_qc_ca": "Levis (QC, CA)",
        "lewisville_tx_us": "Lewisville (TX, US)",
        "lexington_ky_us": "Lexington (KY, US)",
        "león_le_es": "León (LE, ES)",
        "lhospitalet_de_llobregat_b_es": "L'Hospitalet de Llobregat (B, ES)",
        "lille_hdf_fr": "Lille (HDF, FR)",
        "lillian_tx_us": "Lillian (TX, US)",
        "lima_lim_pe": "Lima (LIM, PE)",
        "limoges_naq_fr": "Limoges (NAQ, FR)",
        "lincoln_ne_us": "Lincoln (NE, US)",
        "lipa_btg_ph": "Lipa (BTG, PH)",
        "lipan_tx_us": "Lipan (TX, US)",
        "lipetsk_lip_ru": "Lipetsk (LIP, RU)",
        "little_elm_tx_us": "Little Elm (TX, US)",
        "little_rock_ar_us": "Little Rock (AR, US)",
        "liverpool_liv_uk": "Liverpool (LIV, UK)",
        "livorno_li_it": "Livorno (LI, IT)",
        "lleida_l_es": "Lleida (L, ES)",
        "lobnya_mos_ru": "Lobnya (MOS, RU)",
        "logan_ut_us": "Logan (UT, US)",
        "logroño_lo_es": "Logroño (LO, ES)",
        "lomita_ca_us": "Lomita (CA, US)",
        "london_lnd_uk": "London (LND, UK)",
        "london_on_ca": "London (ON, CA)",
        "londrina_pr_br": "Londrina (PR, BR)",
        "lone_oak_tx_us": "Lone Oak (TX, US)",
        "long_beach_ca_us": "Long Beach (CA, US)",
        "long_island_city_ny_us": "Long Island City (NY, US)",
        "longueuil_qc_ca": "Longueuil (QC, CA)",
        "longview_wa_us": "Longview (WA, US)",
        "los_alamitos_ca_us": "Los Alamitos (CA, US)",
        "los_angeles_ca_us": "Los Angeles (CA, US)",
        "louisville_ky_us": "Louisville (KY, US)",
        "lowell_ma_us": "Lowell (MA, US)",
        "lubbock_tx_us": "Lubbock (TX, US)",
        "lucena_que_ph": "Lucena (QUE, PH)",
        "lucknow_up_in": "Lucknow (UP, IN)",
        "ludhiana_pb_in": "Ludhiana (PB, IN)",
        "ludwigshafen_rp_de": "Ludwigshafen (RP, DE)",
        "lynchburg_va_us": "Lynchburg (VA, US)",
        "lynwood_ca_us": "Lynwood (CA, US)",
        "lyon_ara_fr": "Lyon (ARA, FR)",
        "lyubertsy_mos_ru": "Lyubertsy (MOS, RU)",
        "lübeck_sh_de": "Lübeck (SH, DE)",
        "maarssen_ut_nl": "Maarssen (UT, NL)",
        "mabank_tx_us": "Mabank (TX, US)",
        "macapa_ap_br": "Macapa (AP, BR)",
        "maceio_al_br": "Maceio (AL, BR)",
        "macon_ga_us": "Macon (GA, US)",
        "madera_ca_us": "Madera (CA, US)",
        "madison_wi_us": "Madison (WI, US)",
        "madiun_ji_id": "Madiun (JI, ID)",
        "madiun_lor_ji_id": "Madiun Lor (JI, ID)",
        "madrid_cun_co": "Madrid (CUN, CO)",
        "madrid_m_es": "Madrid (M, ES)",
        "madurai_tn_in": "Madurai (TN, IN)",
        "magdeburg_st_de": "Magdeburg (ST, DE)",
        "magelang_jt_id": "Magelang (JT, ID)",
        "magnitogorsk_che_ru": "Magnitogorsk (CHE, RU)",
        "magog_qc_ca": "Magog (QC, CA)",
        "mainz_rp_de": "Mainz (RP, DE)",
        "mairipora_sp_br": "Mairipora (SP, BR)",
        "makassar_sn_id": "Makassar (SN, ID)",
        "makhachkala_da_ru": "Makhachkala (DA, RU)",
        "malakoff_idf_fr": "Malakoff (IDF, FR)",
        "malang_ji_id": "Malang (JI, ID)",
        "malangbong_jb_id": "Malangbong (JB, ID)",
        "malangjiwan_jt_id": "Malangjiwan (JT, ID)",
        "malibu_ca_us": "Malibu (CA, US)",
        "manado_sa_id": "Manado (SA, ID)",
        "manado_tua_dua_sa_id": "Manado Tua Dua (SA, ID)",
        "manado_tua_satu_sa_id": "Manado Tua Satu (SA, ID)",
        "manaus_am_br": "Manaus (AM, BR)",
        "manchester_man_uk": "Manchester (MAN, UK)",
        "manchester_nh_us": "Manchester (NH, US)",
        "mandalay_04_mm": "Mandalay (04, MM)",
        "mandeville_la_us": "Mandeville (LA, US)",
        "mangalore_ka_in": "Mangalore (KA, IN)",
        "mangaratiba_rj_br": "Mangaratiba (RJ, BR)",
        "manhattan_beach_ca_us": "Manhattan Beach (CA, US)",
        "manhattan_ny_us": "Manhattan (NY, US)",
        "manila_0_ph": "Manila (0, PH)",
        "manizales_cal_co": "Manizales (CAL, CO)",
        "mankato_mn_us": "Mankato (MN, US)",
        "mannheim_bw_de": "Mannheim (BW, DE)",
        "mansfield_tx_us": "Mansfield (TX, US)",
        "manteca_ca_us": "Manteca (CA, US)",
        "marbella_co_es": "Marbella (CO, ES)",
        "mariana_mg_br": "Mariana (MG, BR)",
        "maringa_pr_br": "Maringa (PR, BR)",
        "markham_on_ca": "Markham (ON, CA)",
        "marnelavallee_idf_fr": "Marne-La-Vallee (IDF, FR)",
        "marneslacoquette_idf_fr": "Marnes-la-Coquette (IDF, FR)",
        "marseille_pac_fr": "Marseille (PAC, FR)",
        "martinsburg_wv_us": "Martinsburg (WV, US)",
        "marysville_wa_us": "Marysville (WA, US)",
        "matao_sp_br": "Matao (SP, BR)",
        "mataram_barat_nb_id": "Mataram Barat (NB, ID)",
        "mataram_baru_la_id": "Mataram Baru (LA, ID)",
        "mataram_nb_id": "Mataram (NB, ID)",
        "mataró_b_es": "Mataró (B, ES)",
        "mathura_up_in": "Mathura (UP, IN)",
        "maua_sp_br": "Maua (SP, BR)",
        "maypearl_tx_us": "Maypearl (TX, US)",
        "maywood_ca_us": "Maywood (CA, US)",
        "mazatlan_sin_mx": "Mazatlan (SIN, MX)",
        "mcallen_tx_us": "McAllen (TX, US)",
        "mckinney_tx_us": "McKinney (TX, US)",
        "mecca_02_sa": "Mecca (02, SA)",
        "medan_labuhan_su_id": "Medan Labuhan (SU, ID)",
        "medan_satria_jb_id": "Medan Satria (JB, ID)",
        "medan_su_id": "Medan (SU, ID)",
        "medang_bt_id": "Medang (BT, ID)",
        "medang_deras_su_id": "Medang Deras (SU, ID)",
        "medellin_ant_co": "Medellin (ANT, CO)",
        "medford_or_us": "Medford (OR, US)",
        "meerut_city_up_in": "Meerut City (UP, IN)",
        "melaka_4_my": "Melaka (4, MY)",
        "melbourne_vic_au": "Melbourne (VIC, AU)",
        "melissa_tx_us": "Melissa (TX, US)",
        "memphis_tn_us": "Memphis (TN, US)",
        "merida_yuc_mx": "Merida (YUC, MX)",
        "meridian_id_us": "Meridian (ID, US)",
        "merit_tx_us": "Merit (TX, US)",
        "merkelbeek_li_nl": "Merkelbeek (LI, NL)",
        "mesa_az_us": "Mesa (AZ, US)",
        "mesquite_tx_us": "Mesquite (TX, US)",
        "messina_me_it": "Messina (ME, IT)",
        "mestre_ve_it": "Mestre (VE, IT)",
        "metairie_la_us": "Metairie (LA, US)",
        "metz_ges_fr": "Metz (GES, FR)",
        "mexicali_bcn_mx": "Mexicali (BCN, MX)",
        "mexico_city_cmx_mx": "Mexico City (CMX, MX)",
        "miami_fl_us": "Miami (FL, US)",
        "miass_che_ru": "Miass (CHE, RU)",
        "midland_tx_us": "Midland (TX, US)",
        "midlothian_tx_us": "Midlothian (TX, US)",
        "mierlo_nb_nl": "Mierlo (NB, NL)",
        "milan_mi_it": "Milan (MI, IT)",
        "milford_nh_us": "Milford (NH, US)",
        "millsap_tx_us": "Millsap (TX, US)",
        "milton_on_ca": "Milton (ON, CA)",
        "milwaukee_wi_us": "Milwaukee (WI, US)",
        "mineiros_go_br": "Mineiros (GO, BR)",
        "minneapolis_mn_us": "Minneapolis (MN, US)",
        "mirassol_sp_br": "Mirassol (SP, BR)",
        "mission_hills_ca_us": "Mission Hills (CA, US)",
        "mission_viejo_ca_us": "Mission Viejo (CA, US)",
        "mississauga_on_ca": "Mississauga (ON, CA)",
        "missoula_mt_us": "Missoula (MT, US)",
        "mobile_al_us": "Mobile (AL, US)",
        "modena_mo_it": "Modena (MO, IT)",
        "modesto_ca_us": "Modesto (CA, US)",
        "moers_nw_de": "Moers (NW, DE)",
        "mogi_das_cruzes_sp_br": "Mogi das Cruzes (SP, BR)",
        "moncton_nb_ca": "Moncton (NB, CA)",
        "mongagua_sp_br": "Mongagua (SP, BR)",
        "monroe_la_us": "Monroe (LA, US)",
        "monrovia_ca_us": "Monrovia (CA, US)",
        "monte_mor_sp_br": "Monte Mor (SP, BR)",
        "montebello_ca_us": "Montebello (CA, US)",
        "monterey_ca_us": "Monterey (CA, US)",
        "monterey_park_ca_us": "Monterey Park (CA, US)",
        "monteria_cor_co": "Monteria (COR, CO)",
        "monterrey_nle_mx": "Monterrey (NLE, MX)",
        "montes_claros_mg_br": "Montes Claros (MG, BR)",
        "montevideo_mo_uy": "Montevideo (MO, UY)",
        "montgomery_al_us": "Montgomery (AL, US)",
        "montpellier_occ_fr": "Montpellier (OCC, FR)",
        "montreal_qc_ca": "Montreal (QC, CA)",
        "montreuil_pdl_fr": "Montreuil (PDL, FR)",
        "montrouge_idf_fr": "Montrouge (IDF, FR)",
        "monza_mi_it": "Monza (MI, IT)",
        "moradabad_up_in": "Moradabad (UP, IN)",
        "moreno_valley_ca_us": "Moreno Valley (CA, US)",
        "morgantown_wv_us": "Morgantown (WV, US)",
        "moscow_mow_ru": "Moscow (MOW, RU)",
        "mossoro_rn_br": "Mossoro (RN, BR)",
        "mostoles_m_es": "Mostoles (M, ES)",
        "mount_vernon_wa_us": "Mount Vernon (WA, US)",
        "mountain_view_ca_us": "Mountain View (CA, US)",
        "mulhouse_ges_fr": "Mulhouse (GES, FR)",
        "mumbai_mh_in": "Mumbai (MH, IN)",
        "munich_by_de": "Munich (BY, DE)",
        "murcia_mu_es": "Murcia (MU, ES)",
        "murfreesboro_tn_us": "Murfreesboro (TN, US)",
        "muriae_mg_br": "Muriae (MG, BR)",
        "murmansk_mur_ru": "Murmansk (MUR, RU)",
        "murrieta_ca_us": "Murrieta (CA, US)",
        "muzaffarpur_br_in": "Muzaffarpur (BR, IN)",
        "myrtle_beach_sc_us": "Myrtle Beach (SC, US)",
        "mysore_ka_in": "Mysore (KA, IN)",
        "mytishchi_mos_ru": "Mytishchi (MOS, RU)",
        "málaga_ma_es": "Málaga (MA, ES)",
        "mönchengladbach_nw_de": "Mönchengladbach (NW, DE)",
        "mülheim_rp_de": "Mülheim (RP, DE)",
        "münster_by_de": "Münster (BY, DE)",
        "naberezhnye_chelny_ta_ru": "Naberezhnye Chelny (TA, RU)",
        "nacogdoches_tx_us": "Nacogdoches (TX, US)",
        "naga_ceb_ph": "Naga (CEB, PH)",
        "naga_city_cas_ph": "Naga City (CAS, PH)",
        "nagpur_city_mh_in": "Nagpur City (MH, IN)",
        "nalchik_kb_ru": "Nalchik (KB, RU)",
        "nanaimo_bc_ca": "Nanaimo (BC, CA)",
        "nancy_ges_fr": "Nancy (GES, FR)",
        "napa_ca_us": "Napa (CA, US)",
        "naperville_il_us": "Naperville (IL, US)",
        "naples_fl_us": "Naples (FL, US)",
        "naples_na_it": "Naples (NA, IT)",
        "nashik_mh_in": "Nashik (MH, IN)",
        "nashville_tn_us": "Nashville (TN, US)",
        "natal_rn_br": "Natal (RN, BR)",
        "naucalpan_de_juarez_mex_mx": "Naucalpan de Juarez (MEX, MX)",
        "naval_air_station_jrb_tx_us": "Naval Air Station Jrb (TX, US)",
        "nazran_in_ru": "Nazran (IN, RU)",
        "neftekamsk_ba_ru": "Neftekamsk (BA, RU)",
        "neiva_hui_co": "Neiva (HUI, CO)",
        "nellore_ap_in": "Nellore (AP, IN)",
        "nemo_tx_us": "Nemo (TX, US)",
        "neuillysurseine_idf_fr": "Neuilly-sur-Seine (IDF, FR)",
        "neuss_nw_de": "Neuss (NW, DE)",
        "nevada_tx_us": "Nevada (TX, US)",
        "new_haven_ct_us": "New Haven (CT, US)",
        "new_orleans_la_us": "New Orleans (LA, US)",
        "new_york_ny_us": "New York (NY, US)",
        "newark_ca_us": "Newark (CA, US)",
        "newark_nj_us": "Newark (NJ, US)",
        "newcastle_nsw_au": "Newcastle (NSW, AU)",
        "newport_beach_ca_us": "Newport Beach (CA, US)",
        "newport_news_va_us": "Newport News (VA, US)",
        "newton_ma_us": "Newton (MA, US)",
        "niagara_falls_on_ca": "Niagara Falls (ON, CA)",
        "nieuwegein_ut_nl": "Nieuwegein (UT, NL)",
        "nieuwerkerk_aan_den_ijssel_zh_nl": "Nieuwerkerk aan den IJssel (ZH, NL)",
        "nieuwvennep_nh_nl": "Nieuw-Vennep (NH, NL)",
        "nijmegen_ge_nl": "Nijmegen (GE, NL)",
        "niteroi_rj_br": "Niteroi (RJ, BR)",
        "nizhnekamsk_ta_ru": "Nizhnekamsk (TA, RU)",
        "nizhny_novgorod_niz_ru": "Nizhny Novgorod (NIZ, RU)",
        "noginsk_mos_ru": "Noginsk (MOS, RU)",
        "noida_up_in": "Noida (UP, IN)",
        "noordscharwoude_nh_nl": "Noord-Scharwoude (NH, NL)",
        "nootdorp_zh_nl": "Nootdorp (ZH, NL)",
        "norfolk_va_us": "Norfolk (VA, US)",
        "norman_ok_us": "Norman (OK, US)",
        "north_bay_ny_us": "North Bay (NY, US)",
        "north_las_vegas_nv_us": "North Las Vegas (NV, US)",
        "north_richland_hills_tx_us": "North Richland Hills (TX, US)",
        "north_vancouver_bc_ca": "North Vancouver (BC, CA)",
        "north_york_on_ca": "North York (ON, CA)",
        "northampton_ma_us": "Northampton (MA, US)",
        "northridge_ca_us": "Northridge (CA, US)",
        "norwalk_ca_us": "Norwalk (CA, US)",
        "norwich_ct_us": "Norwich (CT, US)",
        "nottingham_ngm_uk": "Nottingham (NGM, UK)",
        "novo_hamburgo_rs_br": "Novo Hamburgo (RS, BR)",
        "novocheboksarsk_cu_ru": "Novocheboksarsk (CU, RU)",
        "novocherkassk_ros_ru": "Novocherkassk (ROS, RU)",
        "novokuybyshevsk_sam_ru": "Novokuybyshevsk (SAM, RU)",
        "novokuznetsk_kem_ru": "Novokuznetsk (KEM, RU)",
        "novomoskovsk_tul_ru": "Novomoskovsk (TUL, RU)",
        "novorossysk_kda_ru": "Novorossysk (KDA, RU)",
        "novosibirsk_nvs_ru": "Novosibirsk (NVS, RU)",
        "nuenen_nb_nl": "Nuenen (NB, NL)",
        "nuremberg_by_de": "Nuremberg (BY, DE)",
        "nuth_li_nl": "Nuth (LI, NL)",
        "nîmes_occ_fr": "Nîmes (OCC, FR)",
        "oakland_ca_us": "Oakland (CA, US)",
        "oakville_on_ca": "Oakville (ON, CA)",
        "oberhausen_by_de": "Oberhausen (BY, DE)",
        "obninsk_klu_ru": "Obninsk (KLU, RU)",
        "ocala_fl_us": "Ocala (FL, US)",
        "oceanside_ca_us": "Oceanside (CA, US)",
        "odense_83_dk": "Odense (83, DK)",
        "odessa_tx_us": "Odessa (TX, US)",
        "odintsovo_mos_ru": "Odintsovo (MOS, RU)",
        "oegstgeest_zh_nl": "Oegstgeest (ZH, NL)",
        "offenbach_am_main_he_de": "Offenbach am Main (HE, DE)",
        "ogden_ut_us": "Ogden (UT, US)",
        "oirsbeek_li_nl": "Oirsbeek (LI, NL)",
        "oklahoma_city_ok_us": "Oklahoma City (OK, US)",
        "oktyabrsky_ba_ru": "Oktyabrsky (BA, RU)",
        "olathe_ks_us": "Olathe (KS, US)",
        "oldenburg_ni_de": "Oldenburg (NI, DE)",
        "olinda_pe_br": "Olinda (PE, BR)",
        "olympia_wa_us": "Olympia (WA, US)",
        "omaha_ne_us": "Omaha (NE, US)",
        "omsk_oms_ru": "Omsk (OMS, RU)",
        "ontario_ca_us": "Ontario (CA, US)",
        "oosterbeek_ge_nl": "Oosterbeek (GE, NL)",
        "oostzaan_nh_nl": "Oostzaan (NH, NL)",
        "orange_ca_us": "Orange (CA, US)",
        "orangeville_on_ca": "Orangeville (ON, CA)",
        "orenburg_ore_ru": "Orenburg (ORE, RU)",
        "orillia_on_ca": "Orillia (ON, CA)",
        "orlando_fl_us": "Orlando (FL, US)",
        "orsk_ore_ru": "Orsk (ORE, RU)",
        "osasco_sp_br": "Osasco (SP, BR)",
        "oshawa_on_ca": "Oshawa (ON, CA)",
        "oslo_03_no": "Oslo (03, NO)",
        "osnabrück_ni_de": "Osnabrück (NI, DE)",
        "osorno_ll_cl": "Osorno (LL, CL)",
        "ottawa_on_ca": "Ottawa (ON, CA)",
        "oudorp_nh_nl": "Oudorp (NH, NL)",
        "ourense_or_es": "Ourense (OR, ES)",
        "ovalle_co_cl": "Ovalle (CO, CL)",
        "overland_park_ks_us": "Overland Park (KS, US)",
        "overveen_nh_nl": "Overveen (NH, NL)",
        "oviedo_o_es": "Oviedo (O, ES)",
        "owasso_ok_us": "Owasso (OK, US)",
        "oxnard_ca_us": "Oxnard (CA, US)",
        "pachuca_hid_mx": "Pachuca (HID, MX)",
        "padang_ac_id": "Padang (AC, ID)",
        "padang_alai_sb_id": "Padang Alai (SB, ID)",
        "padang_barat_sb_id": "Padang Barat (SB, ID)",
        "padang_bb_id": "Padang (BB, ID)",
        "padang_bolak_su_id": "Padang Bolak (SU, ID)",
        "padang_bulan_su_id": "Padang Bulan (SU, ID)",
        "padang_datar_sb_id": "Padang Datar (SB, ID)",
        "padang_ganting_sb_id": "Padang Ganting (SB, ID)",
        "padang_harapan_be_id": "Padang Harapan (BE, ID)",
        "padang_jaya_be_id": "Padang Jaya (BE, ID)",
        "padang_kerta_ba_id": "Padang Kerta (BA, ID)",
        "padang_pasir_selatan_sb_id": "Padang Pasir Selatan (SB, ID)",
        "padang_sambian_kaja_ba_id": "Padang Sambian Kaja (BA, ID)",
        "padang_tiji_ac_id": "Padang Tiji (AC, ID)",
        "padang_tualang_su_id": "Padang Tualang (SU, ID)",
        "paderborn_nw_de": "Paderborn (NW, DE)",
        "padova_pd_it": "Padova (PD, IT)",
        "palembang_ss_id": "Palembang (SS, ID)",
        "palermo_pa_it": "Palermo (PA, IT)",
        "palhoca_sc_br": "Palhoca (SC, BR)",
        "palm_bay_fl_us": "Palm Bay (FL, US)",
        "palm_beach_fl_us": "Palm Beach (FL, US)",
        "palm_coast_fl_us": "Palm Coast (FL, US)",
        "palm_springs_ca_us": "Palm Springs (CA, US)",
        "palma_de_mallorca_pm_es": "Palma de Mallorca (PM, ES)",
        "palmas_to_br": "Palmas (TO, BR)",
        "palmdale_ca_us": "Palmdale (CA, US)",
        "palmer_tx_us": "Palmer (TX, US)",
        "palmira_vac_co": "Palmira (VAC, CO)",
        "palo_alto_ca_us": "Palo Alto (CA, US)",
        "palos_verdes_peninsula_ca_us": "Palos Verdes Peninsula (CA, US)",
        "paluxy_tx_us": "Paluxy (TX, US)",
        "pamplona_na_es": "Pamplona (NA, ES)",
        "panama_city_08_pa": "Panama City (08, PA)",
        "panama_city_beach_fl_us": "Panama City Beach (FL, US)",
        "panama_city_fl_us": "Panama City (FL, US)",
        "pantin_idf_fr": "Pantin (IDF, FR)",
        "paracatu_mg_br": "Paracatu (MG, BR)",
        "paradise_tx_us": "Paradise (TX, US)",
        "paramount_ca_us": "Paramount (CA, US)",
        "parana_rn_br": "Parana (RN, BR)",
        "parana_to_br": "Parana (TO, BR)",
        "paris_idf_fr": "Paris (IDF, FR)",
        "park_city_ut_us": "Park City (UT, US)",
        "parkersburg_wv_us": "Parkersburg (WV, US)",
        "parla_m_es": "Parla (M, ES)",
        "parma_pr_it": "Parma (PR, IT)",
        "pasadena_ca_us": "Pasadena (CA, US)",
        "pasadena_tx_us": "Pasadena (TX, US)",
        "pasay_0_ph": "Pasay (0, PH)",
        "paso_robles_ca_us": "Paso Robles (CA, US)",
        "pasto_nar_co": "Pasto (NAR, CO)",
        "pasuruan_ji_id": "Pasuruan (JI, ID)",
        "paterson_nj_us": "Paterson (NJ, US)",
        "patiala_pb_in": "Patiala (PB, IN)",
        "patna_br_in": "Patna (BR, IN)",
        "pattaya_20_th": "Pattaya (20, TH)",
        "peaster_tx_us": "Peaster (TX, US)",
        "pedro_leopoldo_mg_br": "Pedro Leopoldo (MG, BR)",
        "pekalongan_la_id": "Pekalongan (LA, ID)",
        "pekanbaru_ri_id": "Pekanbaru (RI, ID)",
        "pelotas_rs_br": "Pelotas (RS, BR)",
        "pematangsiantar_su_id": "Pematangsiantar (SU, ID)",
        "pembroke_pines_fl_us": "Pembroke Pines (FL, US)",
        "penedo_al_br": "Penedo (AL, BR)",
        "peninsula_oh_us": "Peninsula (OH, US)",
        "pensacola_fl_us": "Pensacola (FL, US)",
        "penza_pnz_ru": "Penza (PNZ, RU)",
        "peoria_az_us": "Peoria (AZ, US)",
        "peoria_il_us": "Peoria (IL, US)",
        "pereira_ris_co": "Pereira (RIS, CO)",
        "perm_per_ru": "Perm (PER, RU)",
        "perpignan_occ_fr": "Perpignan (OCC, FR)",
        "perth_tas_au": "Perth (TAS, AU)",
        "perugia_pg_it": "Perugia (PG, IT)",
        "peruibe_sp_br": "Peruibe (SP, BR)",
        "pescara_pe_it": "Pescara (PE, IT)",
        "petaluma_ca_us": "Petaluma (CA, US)",
        "petrolina_pe_br": "Petrolina (PE, BR)",
        "petrozavodsk_kr_ru": "Petrozavodsk (KR, RU)",
        "pforzheim_bw_de": "Pforzheim (BW, DE)",
        "philadelphia_pa_us": "Philadelphia (PA, US)",
        "phnum_penh_327_kh": "Phnum Penh (327, KH)",
        "phoenix_az_us": "Phoenix (AZ, US)",
        "phuket_83_th": "Phuket (83, TH)",
        "pico_rivera_ca_us": "Pico Rivera (CA, US)",
        "picos_pi_br": "Picos (PI, BR)",
        "pilot_point_tx_us": "Pilot Point (TX, US)",
        "pinhais_pr_br": "Pinhais (PR, BR)",
        "piracicaba_sp_br": "Piracicaba (SP, BR)",
        "pittsboro_nc_us": "Pittsboro (NC, US)",
        "pittsburgh_pa_us": "Pittsburgh (PA, US)",
        "piura_piu_pe": "Piura (PIU, PE)",
        "placentia_ca_us": "Placentia (CA, US)",
        "placerville_ca_us": "Placerville (CA, US)",
        "plano_tx_us": "Plano (TX, US)",
        "pleasantville_nj_us": "Pleasantville (NJ, US)",
        "poa_sp_br": "Poa (SP, BR)",
        "podolsk_mos_ru": "Podolsk (MOS, RU)",
        "pomona_ca_us": "Pomona (CA, US)",
        "ponder_tx_us": "Ponder (TX, US)",
        "ponta_grossa_pr_br": "Ponta Grossa (PR, BR)",
        "ponte_nova_mg_br": "Ponte Nova (MG, BR)",
        "pontianak_kb_id": "Pontianak (KB, ID)",
        "poolville_tx_us": "Poolville (TX, US)",
        "poortugaal_zh_nl": "Poortugaal (ZH, NL)",
        "popayan_cau_co": "Popayan (CAU, CO)",
        "port_of_spain_pos_tt": "Port Of Spain (POS, TT)",
        "port_saint_lucie_fl_us": "Port Saint Lucie (FL, US)",
        "porterville_ca_us": "Porterville (CA, US)",
        "portland_or_us": "Portland (OR, US)",
        "porto_alegre_rs_br": "Porto Alegre (RS, BR)",
        "porto_belo_sc_br": "Porto Belo (SC, BR)",
        "porto_ferreira_sp_br": "Porto Ferreira (SP, BR)",
        "porto_uniao_sc_br": "Porto Uniao (SC, BR)",
        "porto_velho_ro_br": "Porto Velho (RO, BR)",
        "praia_grande_sp_br": "Praia Grande (SP, BR)",
        "prato_po_it": "Prato (PO, IT)",
        "prescott_az_us": "Prescott (AZ, US)",
        "primavera_do_leste_mt_br": "Primavera do Leste (MT, BR)",
        "prince_frederick_md_us": "Prince Frederick (MD, US)",
        "prince_george_bc_ca": "Prince George (BC, CA)",
        "princeton_tx_us": "Princeton (TX, US)",
        "prinsenbeek_nb_nl": "Prinsenbeek (NB, NL)",
        "probolinggo_ji_id": "Probolinggo (JI, ID)",
        "prosper_tx_us": "Prosper (TX, US)",
        "providence_ri_us": "Providence (RI, US)",
        "provo_ut_us": "Provo (UT, US)",
        "pskov_psk_ru": "Pskov (PSK, RU)",
        "puebla_pue_mx": "Puebla (PUE, MX)",
        "pueblo_co_us": "Pueblo (CO, US)",
        "puerto_montt_ll_cl": "Puerto Montt (LL, CL)",
        "puerto_vallarta_jal_mx": "Puerto Vallarta (JAL, MX)",
        "pune_mh_in": "Pune (MH, IN)",
        "punta_arenas_ma_cl": "Punta Arenas (MA, CL)",
        "purwakarta_bt_id": "Purwakarta (BT, ID)",
        "purwakarta_jb_id": "Purwakarta (JB, ID)",
        "purwokerto_jt_id": "Purwokerto (JT, ID)",
        "purwokerto_kidul_jt_id": "Purwokerto Kidul (JT, ID)",
        "purwokerto_wetan_jt_id": "Purwokerto Wetan (JT, ID)",
        "pushkino_mos_ru": "Pushkino (MOS, RU)",
        "puteaux_idf_fr": "Puteaux (IDF, FR)",
        "putilkovo_mos_ru": "Putilkovo (MOS, RU)",
        "queens_ny_us": "Queens (NY, US)",
        "queens_village_ny_us": "Queens Village (NY, US)",
        "queretaro_que_mx": "Queretaro (QUE, MX)",
        "quinlan_tx_us": "Quinlan (TX, US)",
        "quito_p_ec": "Quito (P, EC)",
        "québec_qc_ca": "Québec (QC, CA)",
        "rainbow_tx_us": "Rainbow (TX, US)",
        "raipur_ct_in": "Raipur (CT, IN)",
        "rajahmundry_ap_in": "Rajahmundry (AP, IN)",
        "rajkot_gj_in": "Rajkot (GJ, IN)",
        "raleigh_nc_us": "Raleigh (NC, US)",
        "ramenskoe_mos_ru": "Ramenskoe (MOS, RU)",
        "rancagua_li_cl": "Rancagua (LI, CL)",
        "ranchi_jh_in": "Ranchi (JH, IN)",
        "rancho_cucamonga_ca_us": "Rancho Cucamonga (CA, US)",
        "rancho_palos_verdes_ca_us": "Rancho Palos Verdes (CA, US)",
        "rancho_santa_margarita_ca_us": "Rancho Santa Margarita (CA, US)",
        "rapid_city_sd_us": "Rapid City (SD, US)",
        "reading_pa_us": "Reading (PA, US)",
        "reading_rdg_uk": "Reading (RDG, UK)",
        "recife_pe_br": "Recife (PE, BR)",
        "recklinghausen_nw_de": "Recklinghausen (NW, DE)",
        "red_oak_tx_us": "Red Oak (TX, US)",
        "redding_ca_us": "Redding (CA, US)",
        "redondo_beach_ca_us": "Redondo Beach (CA, US)",
        "redwood_city_ca_us": "Redwood City (CA, US)",
        "reeuwijk_zh_nl": "Reeuwijk (ZH, NL)",
        "regensburg_by_de": "Regensburg (BY, DE)",
        "reggio_calabria_rc_it": "Reggio Calabria (RC, IT)",
        "reggio_emilia_re_it": "Reggio Emilia (RE, IT)",
        "regina_sk_ca": "Regina (SK, CA)",
        "rehoboth_beach_de_us": "Rehoboth Beach (DE, US)",
        "reims_ges_fr": "Reims (GES, FR)",
        "remscheid_nw_de": "Remscheid (NW, DE)",
        "reno_nv_us": "Reno (NV, US)",
        "renton_wa_us": "Renton (WA, US)",
        "reston_va_us": "Reston (VA, US)",
        "reus_t_es": "Reus (T, ES)",
        "reutlingen_bw_de": "Reutlingen (BW, DE)",
        "reutov_mos_ru": "Reutov (MOS, RU)",
        "rhome_tx_us": "Rhome (TX, US)",
        "rhoon_zh_nl": "Rhoon (ZH, NL)",
        "ribeirao_das_neves_mg_br": "Ribeirao das Neves (MG, BR)",
        "ribeirao_pires_sp_br": "Ribeirao Pires (SP, BR)",
        "ribeirao_preto_sp_br": "Ribeirao Preto (SP, BR)",
        "richardson_tx_us": "Richardson (TX, US)",
        "richmond_bc_ca": "Richmond (BC, CA)",
        "richmond_ca_us": "Richmond (CA, US)",
        "richmond_hill_on_ca": "Richmond Hill (ON, CA)",
        "richmond_va_us": "Richmond (VA, US)",
        "rijen_nb_nl": "Rijen (NB, NL)",
        "rijnsburg_zh_nl": "Rijnsburg (ZH, NL)",
        "rijswijk_zh_nl": "Rijswijk (ZH, NL)",
        "rimini_rn_it": "Rimini (RN, IT)",
        "rio_bonito_rj_br": "Rio Bonito (RJ, BR)",
        "rio_branco_ac_br": "Rio Branco (AC, BR)",
        "rio_de_janeiro_rj_br": "Rio de Janeiro (RJ, BR)",
        "rio_grande_da_serra_sp_br": "Rio Grande da Serra (SP, BR)",
        "rio_verde_go_br": "Rio Verde (GO, BR)",
        "rio_vista_tx_us": "Rio Vista (TX, US)",
        "riverside_ca_us": "Riverside (CA, US)",
        "riyadh_01_sa": "Riyadh (01, SA)",
        "roanoke_va_us": "Roanoke (VA, US)",
        "rochester_mn_us": "Rochester (MN, US)",
        "rochester_ny_us": "Rochester (NY, US)",
        "rockford_il_us": "Rockford (IL, US)",
        "rockville_md_us": "Rockville (MD, US)",
        "rockwall_tx_us": "Rockwall (TX, US)",
        "rocky_mount_nc_us": "Rocky Mount (NC, US)",
        "rohtak_hr_in": "Rohtak (HR, IN)",
        "romainville_idf_fr": "Romainville (IDF, FR)",
        "rome_rm_it": "Rome (RM, IT)",
        "rondonopolis_mt_br": "Rondonopolis (MT, BR)",
        "rosario_g_ar": "Rosario (G, AR)",
        "rosemead_ca_us": "Rosemead (CA, US)",
        "roseville_ca_us": "Roseville (CA, US)",
        "rosmalen_nb_nl": "Rosmalen (NB, NL)",
        "rosser_tx_us": "Rosser (TX, US)",
        "rostock_mv_de": "Rostock (MV, DE)",
        "rostov_yar_ru": "Rostov (YAR, RU)",
        "rostovnadonu_ros_ru": "Rostov-na-Donu (ROS, RU)",
        "rotterdam_zh_nl": "Rotterdam (ZH, NL)",
        "rouen_nor_fr": "Rouen (NOR, FR)",
        "round_rock_tx_us": "Round Rock (TX, US)",
        "rourkela_or_in": "Rourkela (OR, IN)",
        "rowlett_tx_us": "Rowlett (TX, US)",
        "royse_city_tx_us": "Royse City (TX, US)",
        "rozendaal_ge_nl": "Rozendaal (GE, NL)",
        "ryazan_rya_ru": "Ryazan (RYA, RU)",
        "s_hertogenbosch_nb_nl": "S Hertogenbosch (NB, NL)",
        "saarbrücken_sl_de": "Saarbrücken (SL, DE)",
        "sabadell_b_es": "Sabadell (B, ES)",
        "sachse_tx_us": "Sachse (TX, US)",
        "sacramento_ca_us": "Sacramento (CA, US)",
        "sagar_city_mp_in": "Sagar City (MP, IN)",
        "saginaw_mi_us": "Saginaw (MI, US)",
        "saharanpur_up_in": "Saharanpur (UP, IN)",
        "saintcloud_idf_fr": "Saint-Cloud (IDF, FR)",
        "saintdenis_idf_fr": "Saint-Denis (IDF, FR)",
        "saintetienne_ara_fr": "Saint-Etienne (ARA, FR)",
        "saintjeansurrichelieu_qc_ca": "Saint-Jean-sur-Richelieu (QC, CA)",
        "saintjerome_qc_ca": "Saint-Jerome (QC, CA)",
        "saintmande_idf_fr": "Saint-Mande (IDF, FR)",
        "salaberrydevalleyfield_qc_ca": "Salaberry-de-Valleyfield (QC, CA)",
        "salamanca_sa_es": "Salamanca (SA, ES)",
        "salatiga_jt_id": "Salatiga (JT, ID)",
        "salavat_ba_ru": "Salavat (BA, RU)",
        "salem_or_us": "Salem (OR, US)",
        "salem_tn_in": "Salem (TN, IN)",
        "salerno_sa_it": "Salerno (SA, IT)",
        "salinas_ca_us": "Salinas (CA, US)",
        "salisbury_nc_us": "Salisbury (NC, US)",
        "salt_lake_city_ut_us": "Salt Lake City (UT, US)",
        "saltillo_coa_mx": "Saltillo (COA, MX)",
        "salvador_ba_br": "Salvador (BA, BR)",
        "samara_sam_ru": "Samara (SAM, RU)",
        "samarinda_ki_id": "Samarinda (KI, ID)",
        "sambalpur_or_in": "Sambalpur (OR, IN)",
        "sammamish_wa_us": "Sammamish (WA, US)",
        "san_angelo_tx_us": "San Angelo (TX, US)",
        "san_antonio_tx_us": "San Antonio (TX, US)",
        "san_bernardino_ca_us": "San Bernardino (CA, US)",
        "san_clemente_ca_us": "San Clemente (CA, US)",
        "san_cristóbal_de_la_laguna_tf_es": "San Cristóbal de La Laguna (TF, ES)",
        "san_diego_ca_us": "San Diego (CA, US)",
        "san_dimas_ca_us": "San Dimas (CA, US)",
        "san_fernando_ca_us": "San Fernando (CA, US)",
        "san_francisco_ca_us": "San Francisco (CA, US)",
        "san_gabriel_ca_us": "San Gabriel (CA, US)",
        "san_jose_ca_us": "San Jose (CA, US)",
        "san_jose_sj_cr": "San Jose (SJ, CR)",
        "san_jose_sur_sj_cr": "San Jose Sur (SJ, CR)",
        "san_juan_1_pr": "San Juan (-1, PR)",
        "san_juan_capistrano_ca_us": "San Juan Capistrano (CA, US)",
        "san_luis_obispo_ca_us": "San Luis Obispo (CA, US)",
        "san_luis_potosi_slp_mx": "San Luis Potosi (SLP, MX)",
        "san_marcos_tx_us": "San Marcos (TX, US)",
        "san_marino_ca_us": "San Marino (CA, US)",
        "san_mateo_ca_us": "San Mateo (CA, US)",
        "san_nicolas_de_los_garza_nle_mx": "San Nicolas de los Garza (NLE, MX)",
        "san_nicolas_mex_mx": "San Nicolas (MEX, MX)",
        "san_pablo_lag_ph": "San Pablo (LAG, PH)",
        "san_pedro_lag_ph": "San Pedro (LAG, PH)",
        "san_rafael_ca_us": "San Rafael (CA, US)",
        "san_salvador_ss_sv": "San Salvador (SS, SV)",
        "san_sebastian_za_es": "San Sebastian (ZA, ES)",
        "sanford_fl_us": "Sanford (FL, US)",
        "sanger_tx_us": "Sanger (TX, US)",
        "santa_ana_ca_us": "Santa Ana (CA, US)",
        "santa_barbara_ca_us": "Santa Barbara (CA, US)",
        "santa_clara_ca_us": "Santa Clara (CA, US)",
        "santa_clarita_ca_us": "Santa Clarita (CA, US)",
        "santa_coloma_de_gramenet_b_es": "Santa Coloma de Gramenet (B, ES)",
        "santa_cruz_de_tenerife_tf_es": "Santa Cruz de Tenerife (TF, ES)",
        "santa_cruz_s_bo": "Santa Cruz (S, BO)",
        "santa_fe_nm_us": "Santa Fe (NM, US)",
        "santa_fe_springs_ca_us": "Santa Fe Springs (CA, US)",
        "santa_luzia_mg_br": "Santa Luzia (MG, BR)",
        "santa_maria_ca_us": "Santa Maria (CA, US)",
        "santa_marta_mag_co": "Santa Marta (MAG, CO)",
        "santa_monica_ca_us": "Santa Monica (CA, US)",
        "santa_rosa_ca_us": "Santa Rosa (CA, US)",
        "santa_rosa_rs_br": "Santa Rosa (RS, BR)",
        "santana_de_parnaiba_sp_br": "Santana de Parnaiba (SP, BR)",
        "santander_s_es": "Santander (S, ES)",
        "santiago_25_do": "Santiago (25, DO)",
        "santiago_de_cali_vac_co": "Santiago de Cali (VAC, CO)",
        "santiago_rm_cl": "Santiago (RM, CL)",
        "santo_amaro_da_imperatriz_sc_br": "Santo Amaro da Imperatriz (SC, BR)",
        "santo_andre_sp_br": "Santo Andre (SP, BR)",
        "santo_domingo_01_do": "Santo Domingo (01, DO)",
        "santos_sp_br": "Santos (SP, BR)",
        "santpoortnoord_nh_nl": "Santpoort-Noord (NH, NL)",
        "santpoortzuid_nh_nl": "Santpoort-Zuid (NH, NL)",
        "sao_bernardo_do_campo_sp_br": "Sao Bernardo do Campo (SP, BR)",
        "sao_caetano_do_sul_sp_br": "Sao Caetano do Sul (SP, BR)",
        "sao_joao_del_rei_mg_br": "Sao Joao Del Rei (MG, BR)",
        "sao_joaquim_de_bicas_mg_br": "Sao Joaquim de Bicas (MG, BR)",
        "sao_jose_do_rio_preto_sp_br": "Sao Jose do Rio Preto (SP, BR)",
        "sao_jose_dos_campos_sp_br": "Sao Jose dos Campos (SP, BR)",
        "sao_jose_dos_pinhais_pr_br": "Sao Jose dos Pinhais (PR, BR)",
        "sao_jose_sc_br": "Sao Jose (SC, BR)",
        "sao_lourenco_da_mata_pe_br": "Sao Lourenco da Mata (PE, BR)",
        "sao_lourenco_mg_br": "Sao Lourenco (MG, BR)",
        "sao_luis_ma_br": "Sao Luis (MA, BR)",
        "sao_paulo_sp_br": "Sao Paulo (SP, BR)",
        "sao_roque_sp_br": "Sao Roque (SP, BR)",
        "sao_vicente_sp_br": "Sao Vicente (SP, BR)",
        "sapronovo_amu_ru": "Sapronovo (AMU, RU)",
        "sapucaia_do_sul_rs_br": "Sapucaia do Sul (RS, BR)",
        "saransk_mo_ru": "Saransk (MO, RU)",
        "sarasota_fl_us": "Sarasota (FL, US)",
        "saratov_sar_ru": "Saratov (SAR, RU)",
        "sarzedo_mg_br": "Sarzedo (MG, BR)",
        "saskatoon_sk_ca": "Saskatoon (SK, CA)",
        "sault_ste_marie_on_ca": "Sault Ste. Marie (ON, CA)",
        "savannah_ga_us": "Savannah (GA, US)",
        "sayanogorsk_kk_ru": "Sayanogorsk (KK, RU)",
        "scarborough_on_ca": "Scarborough (ON, CA)",
        "schalkhaar_ov_nl": "Schalkhaar (OV, NL)",
        "schiedam_zh_nl": "Schiedam (ZH, NL)",
        "schimmert_li_nl": "Schimmert (LI, NL)",
        "schinnen_li_nl": "Schinnen (LI, NL)",
        "scottsdale_az_us": "Scottsdale (AZ, US)",
        "scranton_pa_us": "Scranton (PA, US)",
        "scurry_tx_us": "Scurry (TX, US)",
        "seagoville_tx_us": "Seagoville (TX, US)",
        "seal_beach_ca_us": "Seal Beach (CA, US)",
        "seattle_wa_us": "Seattle (WA, US)",
        "semarang_jt_id": "Semarang (JT, ID)",
        "senador_canedo_go_br": "Senador Canedo (GO, BR)",
        "seoul_11_kr": "Seoul (11, KR)",
        "serang_bt_id": "Serang (BT, ID)",
        "serangan_ba_id": "Serangan (BA, ID)",
        "sergiev_posad_mos_ru": "Sergiev Posad (MOS, RU)",
        "serpukhov_mos_ru": "Serpukhov (MOS, RU)",
        "serrana_sp_br": "Serrana (SP, BR)",
        "sete_lagoas_mg_br": "Sete Lagoas (MG, BR)",
        "sevierville_tn_us": "Sevierville (TN, US)",
        "sevilla_se_es": "Sevilla (SE, ES)",
        "sevres_idf_fr": "Sevres (IDF, FR)",
        "sharjah_sh_ae": "Sharjah (SH, AE)",
        "sharon_pa_us": "Sharon (PA, US)",
        "shawnee_ok_us": "Shawnee (OK, US)",
        "shchelkovo_mos_ru": "Shchelkovo (MOS, RU)",
        "sheboygan_wi_us": "Sheboygan (WI, US)",
        "sheffield_rot_uk": "Sheffield (ROT, UK)",
        "sherbrooke_qc_ca": "Sherbrooke (QC, CA)",
        "shillong_ml_in": "Shillong (ML, IN)",
        "shreveport_la_us": "Shreveport (LA, US)",
        "sidoarjo_ji_id": "Sidoarjo (JI, ID)",
        "sidoarjo_jt_id": "Sidoarjo (JT, ID)",
        "sidoarjo_lainnya_ji_id": "Sidoarjo Lainnya (JI, ID)",
        "sidoarjo_st_id": "Sidoarjo (ST, ID)",
        "siegen_nw_de": "Siegen (NW, DE)",
        "sierra_madre_ca_us": "Sierra Madre (CA, US)",
        "sierra_vista_az_us": "Sierra Vista (AZ, US)",
        "signal_hill_ca_us": "Signal Hill (CA, US)",
        "siliguri_wb_in": "Siliguri (WB, IN)",
        "simi_valley_ca_us": "Simi Valley (CA, US)",
        "sincelejo_suc_co": "Sincelejo (SUC, CO)",
        "singapore_01_sg": "Singapore (01, SG)",
        "sint_pancras_nh_nl": "Sint Pancras (NH, NL)",
        "sioux_city_ia_us": "Sioux City (IA, US)",
        "sioux_falls_sd_us": "Sioux Falls (SD, US)",
        "slidell_la_us": "Slidell (LA, US)",
        "smithfield_va_us": "Smithfield (VA, US)",
        "smolensk_smo_ru": "Smolensk (SMO, RU)",
        "sochi_kda_ru": "Sochi (KDA, RU)",
        "socorro_sp_br": "Socorro (SP, BR)",
        "soest_ut_nl": "Soest (UT, NL)",
        "soesterberg_ut_nl": "Soesterberg (UT, NL)",
        "solingen_nw_de": "Solingen (NW, DE)",
        "solo_baru_jt_id": "Solo Baru (JT, ID)",
        "solvang_ca_us": "Solvang (CA, US)",
        "sopo_cun_co": "Sopo (CUN, CO)",
        "sorocaba_sp_br": "Sorocaba (SP, BR)",
        "sorriso_mt_br": "Sorriso (MT, BR)",
        "south_bay_fl_us": "South Bay (FL, US)",
        "south_bend_in_us": "South Bend (IN, US)",
        "south_el_monte_ca_us": "South El Monte (CA, US)",
        "south_gate_ca_us": "South Gate (CA, US)",
        "south_pasadena_ca_us": "South Pasadena (CA, US)",
        "south_san_francisco_ca_us": "South San Francisco (CA, US)",
        "south_yarmouth_ma_us": "South Yarmouth (MA, US)",
        "southern_pines_nc_us": "Southern Pines (NC, US)",
        "southlake_tx_us": "Southlake (TX, US)",
        "spaubeek_li_nl": "Spaubeek (LI, NL)",
        "spijkenisse_zh_nl": "Spijkenisse (ZH, NL)",
        "spokane_wa_us": "Spokane (WA, US)",
        "springfield_il_us": "Springfield (IL, US)",
        "springfield_ma_us": "Springfield (MA, US)",
        "springfield_mo_us": "Springfield (MO, US)",
        "springtown_tx_us": "Springtown (TX, US)",
        "spruce_grove_ab_ca": "Spruce Grove (AB, CA)",
        "st_catharines_on_ca": "St Catharines (ON, CA)",
        "st_cloud_mn_us": "St Cloud (MN, US)",
        "st_george_ut_us": "St George (UT, US)",
        "st_johns_nl_ca": "St John's (NL, CA)",
        "st_louis_mo_us": "St Louis (MO, US)",
        "st_paul_mn_us": "St Paul (MN, US)",
        "st_petersburg_fl_us": "St Petersburg (FL, US)",
        "st_petersburg_spe_ru": "St Petersburg (SPE, RU)",
        "st_thomas_on_ca": "St Thomas (ON, CA)",
        "stamford_ct_us": "Stamford (CT, US)",
        "stanton_ca_us": "Stanton (CA, US)",
        "state_college_pa_us": "State College (PA, US)",
        "staten_island_ny_us": "Staten Island (NY, US)",
        "statesboro_ga_us": "Statesboro (GA, US)",
        "statesville_nc_us": "Statesville (NC, US)",
        "stavanger_11_no": "Stavanger (11, NO)",
        "steenenkamer_ge_nl": "Steenenkamer (GE, NL)",
        "sterling_heights_mi_us": "Sterling Heights (MI, US)",
        "sterlitamak_ba_ru": "Sterlitamak (BA, RU)",
        "steyl_li_nl": "Steyl (LI, NL)",
        "stillwater_ok_us": "Stillwater (OK, US)",
        "stockton_ca_us": "Stockton (CA, US)",
        "stokeontrent_ste_uk": "Stoke-on-Trent (STE, UK)",
        "storrs_mansfield_ct_us": "Storrs Mansfield (CT, US)",
        "strasbourg_ges_fr": "Strasbourg (GES, FR)",
        "stuttgart_bw_de": "Stuttgart (BW, DE)",
        "sudbury_on_ca": "Sudbury (ON, CA)",
        "sukabumi_jb_id": "Sukabumi (JB, ID)",
        "sukabumi_ji_id": "Sukabumi (JI, ID)",
        "sukabumi_selatan_jk_id": "Sukabumi Selatan (JK, ID)",
        "sukabumi_utara_jk_id": "Sukabumi Utara (JK, ID)",
        "sumedang_jb_id": "Sumedang (JB, ID)",
        "sumedang_selatan_jb_id": "Sumedang Selatan (JB, ID)",
        "sumedang_utara_jb_id": "Sumedang Utara (JB, ID)",
        "summerville_sc_us": "Summerville (SC, US)",
        "sunderland_snd_uk": "Sunderland (SND, UK)",
        "sunnyvale_ca_us": "Sunnyvale (CA, US)",
        "sunshine_vic_au": "Sunshine (VIC, AU)",
        "surabaya_ji_id": "Surabaya (JI, ID)",
        "surakarta_jt_id": "Surakarta (JT, ID)",
        "surat_gj_in": "Surat (GJ, IN)",
        "suresnes_idf_fr": "Suresnes (IDF, FR)",
        "surfside_ca_us": "Surfside (CA, US)",
        "surprise_az_us": "Surprise (AZ, US)",
        "surrey_bc_ca": "Surrey (BC, CA)",
        "sutton_qc_ca": "Sutton (QC, CA)",
        "suzano_sp_br": "Suzano (SP, BR)",
        "sydney_ns_ca": "Sydney (NS, CA)",
        "sydney_nsw_au": "Sydney (NSW, AU)",
        "syktyvkar_ko_ru": "Syktyvkar (KO, RU)",
        "sylmar_ca_us": "Sylmar (CA, US)",
        "syracuse_ny_us": "Syracuse (NY, US)",
        "syzran_sam_ru": "Syzran (SAM, RU)",
        "taboao_da_serra_sp_br": "Taboao da Serra (SP, BR)",
        "tabuk_07_sa": "Tabuk (07, SA)",
        "tacoma_wa_us": "Tacoma (WA, US)",
        "taganrog_ros_ru": "Taganrog (ROS, RU)",
        "talca_ml_cl": "Talca (ML, CL)",
        "tallahassee_fl_us": "Tallahassee (FL, US)",
        "tampa_fl_us": "Tampa (FL, US)",
        "tangerang_jb_id": "Tangerang (JB, ID)",
        "taranto_ta_it": "Taranto (TA, IT)",
        "tarragona_t_es": "Tarragona (T, ES)",
        "tegal_alur_jk_id": "Tegal Alur (JK, ID)",
        "tegal_gundil_jb_id": "Tegal Gundil (JB, ID)",
        "tegal_jt_id": "Tegal (JT, ID)",
        "tegal_panggung_yo_id": "Tegal Panggung (YO, ID)",
        "tegal_parang_jk_id": "Tegal Parang (JK, ID)",
        "tegal_ratu_bt_id": "Tegal Ratu (BT, ID)",
        "tegal_tirto_yo_id": "Tegal Tirto (YO, ID)",
        "tegalallang_ba_id": "Tegalallang (BA, ID)",
        "tegalampel_ji_id": "Tegalampel (JI, ID)",
        "tegalbesar_ji_id": "Tegalbesar (JI, ID)",
        "tegelen_li_nl": "Tegelen (LI, NL)",
        "telde_gc_es": "Telde (GC, ES)",
        "temecula_ca_us": "Temecula (CA, US)",
        "tempe_az_us": "Tempe (AZ, US)",
        "temple_city_ca_us": "Temple City (CA, US)",
        "temple_tx_us": "Temple (TX, US)",
        "temuco_ar_cl": "Temuco (AR, CL)",
        "teresina_pi_br": "Teresina (PI, BR)",
        "terrassa_b_es": "Terrassa (B, ES)",
        "terre_haute_in_us": "Terre Haute (IN, US)",
        "terrell_tx_us": "Terrell (TX, US)",
        "teteringen_nb_nl": "Teteringen (NB, NL)",
        "texarkana_ar_us": "Texarkana (AR, US)",
        "texarkana_tx_us": "Texarkana (TX, US)",
        "thane_mh_in": "Thane (MH, IN)",
        "thanh_pho_ho_chi_minh_sg_vn": "Thanh Pho Ho Chi Minh (SG, VN)",
        "the_colony_tx_us": "The Colony (TX, US)",
        "theni_tn_in": "Theni (TN, IN)",
        "thiruvananthapuram_kl_in": "Thiruvananthapuram (KL, IN)",
        "thousand_oaks_ca_us": "Thousand Oaks (CA, US)",
        "thunder_bay_on_ca": "Thunder Bay (ON, CA)",
        "tijuana_bcn_mx": "Tijuana (BCN, MX)",
        "tilburg_nb_nl": "Tilburg (NB, NL)",
        "timoteo_mg_br": "Timoteo (MG, BR)",
        "tiruchirappalli_tn_in": "Tiruchirappalli (TN, IN)",
        "tirupati_ap_in": "Tirupati (AP, IN)",
        "tlajomulco_de_zuniga_jal_mx": "Tlajomulco de Zuniga (JAL, MX)",
        "tolar_tx_us": "Tolar (TX, US)",
        "toledo_oh_us": "Toledo (OH, US)",
        "toluca_cmx_mx": "Toluca (CMX, MX)",
        "tolyatti_sam_ru": "Tolyatti (SAM, RU)",
        "tomsk_tom_ru": "Tomsk (TOM, RU)",
        "tooele_ut_us": "Tooele (UT, US)",
        "topeka_ks_us": "Topeka (KS, US)",
        "torino_to_it": "Torino (TO, IT)",
        "toronto_on_ca": "Toronto (ON, CA)",
        "torrance_ca_us": "Torrance (CA, US)",
        "torrejón_de_ardoz_m_es": "Torrejón de Ardoz (M, ES)",
        "torreon_coa_mx": "Torreon (COA, MX)",
        "torrevieja_a_es": "Torrevieja (A, ES)",
        "toulouse_occ_fr": "Toulouse (OCC, FR)",
        "townsville_qld_au": "Townsville (QLD, AU)",
        "towson_md_us": "Towson (MD, US)",
        "tracy_ca_us": "Tracy (CA, US)",
        "traverse_city_mi_us": "Traverse City (MI, US)",
        "trenton_nj_us": "Trenton (NJ, US)",
        "trier_rp_de": "Trier (RP, DE)",
        "trieste_ts_it": "Trieste (TS, IT)",
        "troisrivières_qc_ca": "Trois-Rivières (QC, CA)",
        "troitsk_che_ru": "Troitsk (CHE, RU)",
        "trondheim_23_no": "Trondheim (23, NO)",
        "tucson_az_us": "Tucson (AZ, US)",
        "tulsa_ok_us": "Tulsa (OK, US)",
        "tunja_boy_co": "Tunja (BOY, CO)",
        "tuscaloosa_al_us": "Tuscaloosa (AL, US)",
        "tustin_ca_us": "Tustin (CA, US)",
        "tuxtla_chico_chp_mx": "Tuxtla Chico (CHP, MX)",
        "tver_tve_ru": "Tver (TVE, RU)",
        "twello_ge_nl": "Twello (GE, NL)",
        "tyler_tx_us": "Tyler (TX, US)",
        "tyumen_tyu_ru": "Tyumen (TYU, RU)",
        "uba_mg_br": "Uba (MG, BR)",
        "uberaba_mg_br": "Uberaba (MG, BR)",
        "uberlandia_mg_br": "Uberlandia (MG, BR)",
        "udaipur_rj_in": "Udaipur (RJ, IN)",
        "ufa_ba_ru": "Ufa (BA, RU)",
        "ugchelen_ge_nl": "Ugchelen (GE, NL)",
        "ujjain_mp_in": "Ujjain (MP, IN)",
        "ukhta_ko_ru": "Ukhta (KO, RU)",
        "ulm_bw_de": "Ulm (BW, DE)",
        "ulvenhout_nb_nl": "Ulvenhout (NB, NL)",
        "ulyanovsk_uly_ru": "Ulyanovsk (ULY, RU)",
        "uniao_da_vitoria_pr_br": "Uniao da Vitoria (PR, BR)",
        "union_city_ca_us": "Union City (CA, US)",
        "universal_city_ca_us": "Universal City (CA, US)",
        "upper_marlboro_md_us": "Upper Marlboro (MD, US)",
        "utrecht_ut_nl": "Utrecht (UT, NL)",
        "uxbridge_on_ca": "Uxbridge (ON, CA)",
        "vadodara_gj_in": "Vadodara (GJ, IN)",
        "valdivia_lr_cl": "Valdivia (LR, CL)",
        "valencia_ca_us": "Valencia (CA, US)",
        "valencia_v_es": "Valencia (V, ES)",
        "valinhos_sp_br": "Valinhos (SP, BR)",
        "valladolid_va_es": "Valladolid (VA, ES)",
        "valledupar_ces_co": "Valledupar (CES, CO)",
        "vallejo_ca_us": "Vallejo (CA, US)",
        "valparaíso_vs_cl": "Valparaíso (VS, CL)",
        "van_nuys_ca_us": "Van Nuys (CA, US)",
        "vancouver_bc_ca": "Vancouver (BC, CA)",
        "vancouver_wa_us": "Vancouver (WA, US)",
        "vanves_idf_fr": "Vanves (IDF, FR)",
        "varanasi_up_in": "Varanasi (UP, IN)",
        "vargem_grande_paulista_sp_br": "Vargem Grande Paulista (SP, BR)",
        "vaughan_on_ca": "Vaughan (ON, CA)",
        "veenendaal_ut_nl": "Veenendaal (UT, NL)",
        "velden_li_nl": "Velden (LI, NL)",
        "veldhoven_nb_nl": "Veldhoven (NB, NL)",
        "veliky_novgorod_ngr_ru": "Veliky Novgorod (NGR, RU)",
        "velp_ge_nl": "Velp (GE, NL)",
        "velp_nb_nl": "Velp (NB, NL)",
        "velserbroek_nh_nl": "Velserbroek (NH, NL)",
        "venlo_li_nl": "Venlo (LI, NL)",
        "ventura_ca_us": "Ventura (CA, US)",
        "venus_tx_us": "Venus (TX, US)",
        "vernon_bc_ca": "Vernon (BC, CA)",
        "verona_vr_it": "Verona (VR, IT)",
        "versailles_idf_fr": "Versailles (IDF, FR)",
        "vespasiano_mg_br": "Vespasiano (MG, BR)",
        "viamao_rs_br": "Viamao (RS, BR)",
        "vicenza_vi_it": "Vicenza (VI, IT)",
        "victoria_bc_ca": "Victoria (BC, CA)",
        "victoria_tx_us": "Victoria (TX, US)",
        "victorville_ca_us": "Victorville (CA, US)",
        "vidnoe_mos_ru": "Vidnoe (MOS, RU)",
        "vigo_c_es": "Vigo (C, ES)",
        "vijayawada_ap_in": "Vijayawada (AP, IN)",
        "villa_park_ca_us": "Villa Park (CA, US)",
        "villahermosa_tab_mx": "Villahermosa (TAB, MX)",
        "villavicencio_met_co": "Villavicencio (MET, CO)",
        "villejuif_idf_fr": "Villejuif (IDF, FR)",
        "villeurbanne_ara_fr": "Villeurbanne (ARA, FR)",
        "vincennes_idf_fr": "Vincennes (IDF, FR)",
        "virginia_beach_va_us": "Virginia Beach (VA, US)",
        "visakhapatnam_ap_in": "Visakhapatnam (AP, IN)",
        "visalia_ca_us": "Visalia (CA, US)",
        "vitoria_es_br": "Vitoria (ES, BR)",
        "vitoriagasteiz_vi_es": "Vitoria-Gasteiz (VI, ES)",
        "vlaardingen_zh_nl": "Vlaardingen (ZH, NL)",
        "vladikavkaz_se_ru": "Vladikavkaz (SE, RU)",
        "vladivostok_pri_ru": "Vladivostok (PRI, RU)",
        "vleuten_ut_nl": "Vleuten (UT, NL)",
        "voerendaal_li_nl": "Voerendaal (LI, NL)",
        "volgograd_vgg_ru": "Volgograd (VGG, RU)",
        "vologda_vlg_ru": "Vologda (VLG, RU)",
        "volta_redonda_rj_br": "Volta Redonda (RJ, BR)",
        "voorburg_zh_nl": "Voorburg (ZH, NL)",
        "voorschoten_zh_nl": "Voorschoten (ZH, NL)",
        "voronezh_vor_ru": "Voronezh (VOR, RU)",
        "vught_nb_nl": "Vught (NB, NL)",
        "waalre_nb_nl": "Waalre (NB, NL)",
        "waco_tx_us": "Waco (TX, US)",
        "waddinxveen_zh_nl": "Waddinxveen (ZH, NL)",
        "walnut_ca_us": "Walnut (CA, US)",
        "warangal_tg_in": "Warangal (TG, IN)",
        "warmond_zh_nl": "Warmond (ZH, NL)",
        "warner_robins_ga_us": "Warner Robins (GA, US)",
        "warren_mi_us": "Warren (MI, US)",
        "wasaga_beach_on_ca": "Wasaga Beach (ON, CA)",
        "washington_dc_us": "Washington (DC, US)",
        "washington_depot_ct_us": "Washington Depot (CT, US)",
        "waterbury_ct_us": "Waterbury (CT, US)",
        "waterloo_on_ca": "Waterloo (ON, CA)",
        "wausau_wi_us": "Wausau (WI, US)",
        "waxahachie_tx_us": "Waxahachie (TX, US)",
        "weatherford_tx_us": "Weatherford (TX, US)",
        "wellington_wgn_nz": "Wellington (WGN, NZ)",
        "wenatchee_wa_us": "Wenatchee (WA, US)",
        "west_covina_ca_us": "West Covina (CA, US)",
        "west_hartford_ct_us": "West Hartford (CT, US)",
        "west_hollywood_ca_us": "West Hollywood (CA, US)",
        "west_jordan_ut_us": "West Jordan (UT, US)",
        "west_lafayette_in_us": "West Lafayette (IN, US)",
        "west_palm_beach_fl_us": "West Palm Beach (FL, US)",
        "west_valley_city_ut_us": "West Valley City (UT, US)",
        "westbury_ny_us": "Westbury (NY, US)",
        "westervoort_ge_nl": "Westervoort (GE, NL)",
        "westlake_village_ca_us": "Westlake Village (CA, US)",
        "westminster_co_us": "Westminster (CO, US)",
        "weston_tx_us": "Weston (TX, US)",
        "weurt_ge_nl": "Weurt (GE, NL)",
        "wheeling_wv_us": "Wheeling (WV, US)",
        "white_rock_bc_ca": "White Rock (BC, CA)",
        "whitt_tx_us": "Whitt (TX, US)",
        "whittier_ca_us": "Whittier (CA, US)",
        "wichita_falls_tx_us": "Wichita Falls (TX, US)",
        "wichita_ks_us": "Wichita (KS, US)",
        "wiesbaden_he_de": "Wiesbaden (HE, DE)",
        "wijchen_ge_nl": "Wijchen (GE, NL)",
        "wijnandsrade_li_nl": "Wijnandsrade (LI, NL)",
        "wilkes_barre_pa_us": "Wilkes Barre (PA, US)",
        "willimantic_ct_us": "Willimantic (CT, US)",
        "wilmer_tx_us": "Wilmer (TX, US)",
        "wilmington_de_us": "Wilmington (DE, US)",
        "wilmington_nc_us": "Wilmington (NC, US)",
        "winchester_va_us": "Winchester (VA, US)",
        "windsor_on_ca": "Windsor (ON, CA)",
        "winnipeg_mb_ca": "Winnipeg (MB, CA)",
        "winston_salem_nc_us": "Winston Salem (NC, US)",
        "witten_nw_de": "Witten (NW, DE)",
        "wolfe_city_tx_us": "Wolfe City (TX, US)",
        "wolfsburg_ni_de": "Wolfsburg (NI, DE)",
        "wollongong_nsw_au": "Wollongong (NSW, AU)",
        "woodbridge_va_us": "Woodbridge (VA, US)",
        "woodland_hills_ca_us": "Woodland Hills (CA, US)",
        "worcester_ma_us": "Worcester (MA, US)",
        "wrightsville_ga_us": "Wrightsville (GA, US)",
        "wuppertal_nw_de": "Wuppertal (NW, DE)",
        "wurzburg_by_de": "Wurzburg (BY, DE)",
        "wylie_tx_us": "Wylie (TX, US)",
        "yakima_wa_us": "Yakima (WA, US)",
        "yakutsk_sa_ru": "Yakutsk (SA, RU)",
        "yogyakarta_yo_id": "Yogyakarta (YO, ID)",
        "yonkers_ny_us": "Yonkers (NY, US)",
        "yorba_linda_ca_us": "Yorba Linda (CA, US)",
        "york_pa_us": "York (PA, US)",
        "yoshkarola_me_ru": "Yoshkar-Ola (ME, RU)",
        "youngstown_oh_us": "Youngstown (OH, US)",
        "yuba_city_ca_us": "Yuba City (CA, US)",
        "yucca_valley_ca_us": "Yucca Valley (CA, US)",
        "yuma_az_us": "Yuma (AZ, US)",
        "zaandam_nh_nl": "Zaandam (NH, NL)",
        "zaandijk_nh_nl": "Zaandijk (NH, NL)",
        "zacatecas_zac_mx": "Zacatecas (ZAC, MX)",
        "zamboanga_zas_ph": "Zamboanga (ZAS, PH)",
        "zapopan_jal_mx": "Zapopan (JAL, MX)",
        "zaragoza_z_es": "Zaragoza (Z, ES)",
        "zeist_ut_nl": "Zeist (UT, NL)",
        "zelenogradsk_kgd_ru": "Zelenogradsk (KGD, RU)",
        "zheleznodorozhny_mos_ru": "Zheleznodorozhny (MOS, RU)",
        "zhukovsky_mos_ru": "Zhukovsky (MOS, RU)",
        "zipaquira_cun_co": "Zipaquira (CUN, CO)",
        "zlatoust_che_ru": "Zlatoust (CHE, RU)",
        "zoetermeer_zh_nl": "Zoetermeer (ZH, NL)",
        "zuidscharwoude_nh_nl": "Zuid-Scharwoude (NH, NL)",
        "zurich_zh_ch": "Zurich (ZH, CH)",
        "zwijndrecht_zh_nl": "Zwijndrecht (ZH, NL)"
      },
      "country": {
        "afghanistan": "Afghanistan",
        "albania": "Albania",
        "algeria": "Algeria",
        "american_samoa": "American Samoa",
        "andorra": "Andorra",
        "angola": "Angola",
        "anguilla": "Anguilla",
        "antarctica": "Antarctica",
        "antigua_and_barbuda": "Antigua and Barbuda",
        "argentina": "Argentina",
        "armenia": "Armenia",
        "aruba": "Aruba",
        "australia": "Australia",
        "austria": "Austria",
        "azerbaijan": "Azerbaijan",
        "bahamas": "Bahamas",
        "bahrain": "Bahrain",
        "bangladesh": "Bangladesh",
        "barbados": "Barbados",
        "belarus": "Belarus",
        "belgium": "Belgium",
        "belize": "Belize",
        "benin": "Benin",
        "bermuda": "Bermuda",
        "bhutan": "Bhutan",
        "bolivia": "Bolivia",
        "bosnia_and_herzegovina": "Bosnia and Herzegovina",
        "botswana": "Botswana",
        "brazil": "Brazil",
        "british_indian_ocean_territory": "British Indian Ocean Territory",
        "british_virgin_islands": "British Virgin Islands",
        "brunei": "Brunei",
        "bulgaria": "Bulgaria",
        "burkina_faso": "Burkina Faso",
        "burundi": "Burundi",
        "cambodia": "Cambodia",
        "cameroon": "Cameroon",
        "canada": "Canada",
        "cape_verde": "Cape Verde",
        "cayman_islands": "Cayman Islands",
        "central_african_republic": "Central African Republic",
        "chad": "Chad",
        "chile": "Chile",
        "china": "China",
        "christmas_island": "Christmas Island",
        "cocos_[keeling]_islands": "Cocos [Keeling] Islands",
        "colombia": "Colombia",
        "comoros": "Comoros",
        "congo": "Congo",
        "cook_islands": "Cook Islands",
        "costa_rica": "Costa Rica",
        "croatia": "Croatia",
        "cuba": "Cuba",
        "curacao": "Curacao",
        "cyprus": "Cyprus",
        "czech_republic": "Czech Republic",
        "denmark": "Denmark",
        "djibouti": "Djibouti",
        "dominica": "Dominica",
        "dominican_republic": "Dominican Republic",
        "east_timor": "East Timor",
        "ecuador": "Ecuador",
        "egypt": "Egypt",
        "el_salvador": "El Salvador",
        "equatorial_guinea": "Equatorial Guinea",
        "eritrea": "Eritrea",
        "estonia": "Estonia",
        "ethiopia": "Ethiopia",
        "falkland_islands": "Falkland Islands",
        "faroe_islands": "Faroe Islands",
        "federated_states_of_micronesia": "Federated States of Micronesia",
        "fiji": "Fiji",
        "finland": "Finland",
        "france": "France",
        "french_guiana": "French Guiana",
        "french_polynesia": "French Polynesia",
        "french_southern_territories": "French Southern Territories",
        "gabon": "Gabon",
        "gambia": "Gambia",
        "georgia": "Georgia",
        "germany": "Germany",
        "ghana": "Ghana",
        "gibraltar": "Gibraltar",
        "greece": "Greece",
        "greenland": "Greenland",
        "grenada": "Grenada",
        "guadeloupe": "Guadeloupe",
        "guam": "Guam",
        "guatemala": "Guatemala",
        "guernsey": "Guernsey",
        "guinea": "Guinea",
        "guineabissau": "Guinea-Bissau",
        "guyana": "Guyana",
        "haiti": "Haiti",
        "hashemite_kingdom_of_jordan": "Hashemite Kingdom of Jordan",
        "honduras": "Honduras",
        "hong_kong": "Hong Kong",
        "hungary": "Hungary",
        "iceland": "Iceland",
        "india": "India",
        "indonesia": "Indonesia",
        "iran": "Iran",
        "iraq": "Iraq",
        "ireland": "Ireland",
        "isle_of_man": "Isle of Man",
        "israel": "Israel",
        "italy": "Italy",
        "ivory_coast": "Ivory Coast",
        "jamaica": "Jamaica",
        "japan": "Japan",
        "jersey": "Jersey",
        "kazakhstan": "Kazakhstan",
        "kenya": "Kenya",
        "kiribati": "Kiribati",
        "kuwait": "Kuwait",
        "kyrgyzstan": "Kyrgyzstan",
        "laos": "Laos",
        "latvia": "Latvia",
        "lebanon": "Lebanon",
        "lesotho": "Lesotho",
        "liberia": "Liberia",
        "libya": "Libya",
        "liechtenstein": "Liechtenstein",
        "luxembourg": "Luxembourg",
        "macao": "Macao",
        "macedonia": "Macedonia",
        "madagascar": "Madagascar",
        "malawi": "Malawi",
        "malaysia": "Malaysia",
        "maldives": "Maldives",
        "mali": "Mali",
        "malta": "Malta",
        "marshall_islands": "Marshall Islands",
        "martinique": "Martinique",
        "mauritania": "Mauritania",
        "mauritius": "Mauritius",
        "mayotte": "Mayotte",
        "mexico": "Mexico",
        "monaco": "Monaco",
        "mongolia": "Mongolia",
        "montenegro": "Montenegro",
        "montserrat": "Montserrat",
        "morocco": "Morocco",
        "mozambique": "Mozambique",
        "myanmar_[burma]": "Myanmar [Burma]",
        "namibia": "Namibia",
        "nauru": "Nauru",
        "nepal": "Nepal",
        "netherlands": "Netherlands",
        "netherlands_antilles": "Netherlands Antilles",
        "new_caledonia": "New Caledonia",
        "new_zealand": "New Zealand",
        "nicaragua": "Nicaragua",
        "niger": "Niger",
        "nigeria": "Nigeria",
        "niue": "Niue",
        "norfolk_island": "Norfolk Island",
        "north_korea": "North Korea",
        "northern_mariana_islands": "Northern Mariana Islands",
        "norway": "Norway",
        "oman": "Oman",
        "pakistan": "Pakistan",
        "palau": "Palau",
        "palestine": "Palestinian Territories",
        "panama": "Panama",
        "papua_new_guinea": "Papua New Guinea",
        "paraguay": "Paraguay",
        "peru": "Peru",
        "philippines": "Philippines",
        "pitcairn_islands": "Pitcairn Islands",
        "poland": "Poland",
        "portugal": "Portugal",
        "puerto_rico": "Puerto Rico",
        "qatar": "Qatar",
        "republic_of_korea": "Republic of Korea",
        "republic_of_lithuania": "Republic of Lithuania",
        "republic_of_moldova": "Republic of Moldova",
        "republic_of_the_congo": "Republic of the Congo",
        "romania": "Romania",
        "russia": "Russia",
        "rwanda": "Rwanda",
        "réunion": "Réunion",
        "saint_barthélemy": "Saint Barthélemy",
        "saint_helena": "Saint Helena",
        "saint_kitts_and_nevis": "Saint Kitts and Nevis",
        "saint_lucia": "Saint Lucia",
        "saint_martin": "Saint Martin",
        "saint_pierre_and_miquelon": "Saint Pierre and Miquelon",
        "saint_vincent_and_the_grenadines": "Saint Vincent and the Grenadines",
        "samoa": "Samoa",
        "san_marino": "San Marino",
        "sao_tome_and_principe": "Sao Tome and Principe",
        "saudi_arabia": "Saudi Arabia",
        "senegal": "Senegal",
        "serbia": "Serbia",
        "seychelles": "Seychelles",
        "sierra_leone": "Sierra Leone",
        "singapore": "Singapore",
        "slovak_republic": "Slovak Republic",
        "slovenia": "Slovenia",
        "solomon_islands": "Solomon Islands",
        "somalia": "Somalia",
        "south_africa": "South Africa",
        "south_georgia_and_the_south_sandwich_islands": "South Georgia and the South Sandwich Islands",
        "south_sudan": "South Sudan",
        "spain": "Spain",
        "sri_lanka": "Sri Lanka",
        "sudan": "Sudan",
        "suriname": "Suriname",
        "svalbard_and_jan_mayen": "Svalbard and Jan Mayen",
        "swaziland": "Swaziland",
        "sweden": "Sweden",
        "switzerland": "Switzerland",
        "syria": "Syria",
        "taiwan": "Taiwan",
        "tajikistan": "Tajikistan",
        "tanzania": "Tanzania",
        "thailand": "Thailand",
        "togo": "Togo",
        "tokelau": "Tokelau",
        "tonga": "Tonga",
        "trinidad_and_tobago": "Trinidad and Tobago",
        "tunisia": "Tunisia",
        "turkey": "Turkey",
        "turkmenistan": "Turkmenistan",
        "turks_and_caicos_islands": "Turks and Caicos Islands",
        "tuvalu": "Tuvalu",
        "u_s_minor_outlying_islands": "U.S. Minor Outlying Islands",
        "u_s_virgin_islands": "U.S. Virgin Islands",
        "uganda": "Uganda",
        "ukraine": "Ukraine",
        "united_arab_emirates": "United Arab Emirates",
        "united_kingdom": "United Kingdom",
        "united_states": "United States",
        "uruguay": "Uruguay",
        "uzbekistan": "Uzbekistan",
        "vanuatu": "Vanuatu",
        "vatican_city": "Vatican City",
        "venezuela": "Venezuela",
        "vietnam": "Vietnam",
        "wallis_and_futuna": "Wallis and Futuna",
        "western_sahara": "Western Sahara",
        "yemen": "Yemen",
        "zambia": "Zambia",
        "zimbabwe": "Zimbabwe",
        "åland_islands": "Åland Islands"
      },
      "currency": {
        "afghan_afghani": "؋ Afghan Afghani",
        "albanian_lek": "Lek Albanian Lek",
        "argentine_peso": "$ Argentine Peso",
        "aruban_florin": "ƒ Aruban Florin",
        "australian_dollar": "$ Australian Dollar",
        "azerbaijani_manat": "ман Azerbaijani Manat",
        "bahamian_dollar": "$ Bahamian Dollar",
        "barbadian_dollar": "$ Barbadian Dollar",
        "belarusian_ruble": "p. Belarusian Ruble",
        "belize_dollar": "BZ$ Belize Dollar",
        "bermudan_dollar": "$ Bermudan Dollar",
        "bolivian_boliviano": "$b Bolivian Boliviano",
        "bosniaherzegovina_convertible_mark": "KM Bosnia-Herzegovina Convertible Mark",
        "botswanan_pula": "P Botswanan Pula",
        "brazilian_real": "R$ Brazilian Real",
        "british_pound_sterling": "£ British Pound Sterling",
        "brunei_dollar": "$ Brunei Dollar",
        "bulgarian_lev": "лв Bulgarian Lev",
        "cambodian_riel": "៛ Cambodian Riel",
        "canadian_dollar": "$ Canadian Dollar",
        "cayman_islands_dollar": "$ Cayman Islands Dollar",
        "chilean_peso": "$ Chilean Peso",
        "chinese_yuan": "¥ Chinese Yuan",
        "colombian_peso": "$ Colombian Peso",
        "costa_rican_colon": "₡ Costa Rican Colon",
        "croatian_kuna": "kn Croatian Kuna",
        "cuban_peso": "₱ Cuban Peso",
        "czech_republic_koruna": "Kč Czech Republic Koruna",
        "danish_krone": "kr Danish Krone",
        "dominican_peso": "RD$ Dominican Peso",
        "east_caribbean_dollar": "$ East Caribbean Dollar",
        "egyptian_pound": "£ Egyptian Pound",
        "euro": "€ Euro",
        "falkland_islands_pound": "£ Falkland Islands Pound",
        "fijian_dollar": "$ Fijian Dollar",
        "gibraltar_pound": "£ Gibraltar Pound",
        "guatemalan_quetzal": "Q Guatemalan Quetzal",
        "guyanaese_dollar": "$ Guyanaese Dollar",
        "honduran_lempira": "L Honduran Lempira",
        "hong_kong_dollar": "$ Hong Kong Dollar",
        "hungarian_forint": "Ft Hungarian Forint",
        "icelandic_krona": "kr Icelandic Krona",
        "indian_rupee": "Rp Indian Rupee",
        "indonesian_rupiah": "Rp Indonesian Rupiah",
        "iranian_rial": "﷼ Iranian Rial",
        "israeli_new_sheqel": "₪ Israeli New Sheqel",
        "jamaican_dollar": "J$ Jamaican Dollar",
        "japanese_yen": "¥ Japanese Yen",
        "kazakhstani_tenge": "лв Kazakhstani Tenge",
        "kyrgystani_som": "лв Kyrgystani Som",
        "laotian_kip": "₭ Laotian Kip",
        "lebanese_pound": "£ Lebanese Pound",
        "liberian_dollar": "$ Liberian Dollar",
        "macedonian_denar": "ден Macedonian Denar",
        "malaysian_ringgit": "RM Malaysian Ringgit",
        "mauritian_rupee": "₨ Mauritian Rupee",
        "mexican_peso": "$ Mexican Peso",
        "mongolian_tugrik": "₮ Mongolian Tugrik",
        "mozambican_metical": "MT Mozambican Metical",
        "namibian_dollar": "$ Namibian Dollar",
        "nepalese_rupee": "₨ Nepalese Rupee",
        "netherlands_antillean_guilder": "ƒ Netherlands Antillean Guilder",
        "new_taiwan_dollar": "NT$ New Taiwan Dollar",
        "new_zealand_dollar": "$ New Zealand Dollar",
        "nicaraguan_cordoba": "C$ Nicaraguan Cordoba",
        "nigerian_naira": "₦ Nigerian Naira",
        "north_korean_won": "₩ North Korean Won",
        "norwegian_krone": "kr Norwegian Krone",
        "omani_rial": "﷼ Omani Rial",
        "pakistani_rupee": "₨ Pakistani Rupee",
        "panamanian_balboa": "B/. Panamanian Balboa",
        "paraguayan_guarani": "Gs Paraguayan Guarani",
        "peruvian_nuevo_sol": "S/. Peruvian Nuevo Sol",
        "philippine_peso": "Php Philippine Peso",
        "polish_zloty": "zł Polish Zloty",
        "qatari_rial": "﷼ Qatari Rial",
        "romanian_leu": "lei Romanian Leu",
        "russian_ruble": "руб Russian Ruble",
        "salvadoran_colón": "$ Salvadoran Colón",
        "saudi_riyal": "﷼ Saudi Riyal",
        "serbian_dinar": "Дин. Serbian Dinar",
        "seychellois_rupee": "₨ Seychellois Rupee",
        "singapore_dollar": "$ Singapore Dollar",
        "solomon_islands_dollar": "$ Solomon Islands Dollar",
        "somali_shilling": "S Somali Shilling",
        "south_african_rand": "R South African Rand",
        "south_korean_won": "₩ South Korean Won",
        "sri_lankan_rupee": "₨ Sri Lankan Rupee",
        "st_helena_pound": "£ St. Helena Pound",
        "surinamese_dollar": "$ Surinamese Dollar",
        "swedish_krona": "kr Swedish Krona",
        "swiss_franc": "CHF Swiss Franc",
        "syrian_pound": "£ Syrian Pound",
        "thai_baht": "฿ Thai Baht",
        "trinidad_tobago_dollar": "TT$ Trinidad \u0026 Tobago Dollar",
        "turkish_lira": "TL Turkish Lira",
        "ukrainian_hryvnia": "₴ Ukrainian Hryvnia",
        "uruguayan_peso": "$U Uruguayan Peso",
        "us_dollar": "$ US Dollar",
        "uzbekistan_som": "лв Uzbekistan Som",
        "venezuelan_bolívar": "Bs Venezuelan Bolívar",
        "vietnamese_dong": "₫ Vietnamese Dong",
        "yemeni_rial": "﷼ Yemeni Rial",
        "united_arab_emirates_dirham": "د.إ United Arab Emirates Dirham",
        "bahraini_dinar": ".د.ب Bahraini Dinar",
        "kuwaiti_dinar": "د.ك Kuwaiti Dinar"
      },
      "device_make": {
        "acer": "Acer",
        "advan": "Advan",
        "airis": "Airis",
        "alcatel": "Alcatel",
        "allview": "Allview",
        "allwinner": "Allwinner",
        "alps": "Alps",
        "amazon": "Amazon",
        "amoi": "Amoi",
        "aoc": "AOC",
        "archos": "Archos",
        "arnova": "Arnova",
        "asus": "Asus",
        "audiovox": "Audiovox",
        "avvio": "Avvio",
        "azumi": "Azumi",
        "benq": "BenQ",
        "benqsiemens": "BenQ-Siemens",
        "bird": "Bird",
        "blu": "Blu",
        "bmobile": "B-Mobile",
        "bq": "BQ",
        "celkon": "Celkon",
        "cherry_mobile": "Cherry Mobile",
        "coby": "Coby",
        "compal": "Compal",
        "coolpad": "Coolpad",
        "cube": "Cube",
        "cubot": "Cubot",
        "dbtel": "DBTEL",
        "dell": "Dell",
        "dns": "DNS",
        "docomo": "DoCoMo",
        "doogee": "DOOGEE",
        "doov": "DOOV",
        "dopod": "Dopod",
        "evercoss": "Evercoss",
        "ezze": "Ezze",
        "feiteng": "Feiteng",
        "fly": "Fly",
        "fujitsu": "Fujitsu",
        "generic": "Generic",
        "gionee": "Gionee",
        "goclever": "GoClever",
        "google": "Google",
        "grundig": "Grundig",
        "haier": "Haier",
        "hisense": "Hisense",
        "hp": "HP",
        "htc": "HTC",
        "huawei": "Huawei",
        "iball": "iBall",
        "imate": "i-mate",
        "imobile": "i-mobile",
        "innostream": "Innostream",
        "intex": "Intex",
        "jiayu": "Jiayu",
        "karbonn": "Karbonn",
        "kddi": "KDDI",
        "konka": "Konka",
        "ktouch": "K-Touch",
        "kyocera": "Kyocera",
        "lanix": "Lanix",
        "lava": "Lava",
        "lenovo": "Lenovo",
        "lg": "LG",
        "majestic": "Majestic",
        "maxx": "Maxx",
        "mediacom": "Mediacom",
        "medion": "Medion",
        "micromax": "Micromax",
        "microsoft": "Microsoft",
        "mitsubishi": "Mitsubishi",
        "mobiistar": "Mobiistar",
        "motorola": "Motorola",
        "myphone": "MyPhone",
        "nec": "NEC",
        "nexian": "Nexian",
        "nextbook": "Nextbook",
        "ngm": "NGM",
        "noblex": "Noblex",
        "nokia": "Nokia",
        "o2": "O2",
        "onda": "Onda",
        "oppo": "OPPO",
        "orange": "Orange",
        "palm": "Palm",
        "panasonic": "Panasonic",
        "pantech": "Pantech",
        "pcd": "PCD",
        "philips": "Philips",
        "phoneone": "PhoneOne",
        "pipo": "PiPO",
        "polaroid": "Polaroid",
        "prestigio": "Prestigio",
        "qmobile": "QMobile",
        "rim": "RIM",
        "robot": "Robot",
        "sagem": "Sagem",
        "samsung": "Samsung",
        "sanyo": "Sanyo",
        "sendo": "Sendo",
        "sharp": "Sharp",
        "siemens": "Siemens",
        "softbank": "Softbank",
        "sony": "Sony",
        "sonyericsson": "SonyEricsson",
        "spice": "Spice",
        "sprint": "Sprint",
        "star": "Star",
        "symphony": "Symphony",
        "tcl": "TCL",
        "tecno": "Tecno",
        "telit": "Telit",
        "thl": "ThL",
        "tianyu": "Tianyu",
        "tmobile": "T-Mobile",
        "toshiba": "Toshiba",
        "utstarcom": "UTStarcom",
        "videocon": "Videocon",
        "viewsonic": "ViewSonic",
        "vitelcom": "Vitelcom",
        "vivo": "Vivo",
        "vk_mobile": "VK Mobile",
        "vodafone": "Vodafone",
        "voxtel": "Voxtel",
        "walton": "Walton",
        "wellcom": "WellcoM",
        "wiko": "Wiko",
        "woxter": "Woxter",
        "xiaomi": "Xiaomi",
        "xion": "Xion",
        "xolo": "Xolo",
        "zen": "Zen",
        "zonda": "Zonda",
        "zopo": "ZOPO",
        "zte": "ZTE"
      },
      "device_type": {
        "gaming": "Gaming",
        "mobile": "Mobile",
        "pc": "PC",
        "tablet": "Tablet",
        "tv": "TV",
        "watch": "Watch"
      },
      "dma": {
        "abilenesweetwater_tx_662": "Abilene-Sweetwater, TX (662)",
        "albany_ga_525": "Albany, GA (525)",
        "albanyschenectadytroy_ny_532": "Albany-Schenectady-Troy, NY (532)",
        "albuquerquesanta_fe_nm_790": "Albuquerque-Santa Fe, NM (790)",
        "alexandria_la_644": "Alexandria, LA (644)",
        "alpena_mi_583": "Alpena, MI (583)",
        "amarillo_tx_634": "Amarillo, TX (634)",
        "anchorage_ak_743": "Anchorage, AK (743)",
        "atlanta_ga_524": "Atlanta, GA (524)",
        "augusta_ga_520": "Augusta, GA (520)",
        "austin_tx_635": "Austin, TX (635)",
        "bakersfield_ca_800": "Bakersfield, CA (800)",
        "baltimore_md_512": "Baltimore, MD (512)",
        "bangor_me_537": "Bangor, ME (537)",
        "baton_rouge_la_716": "Baton Rouge, LA (716)",
        "beaumontport_arthur_tx_692": "Beaumont-Port Arthur, TX (692)",
        "bend_or_821": "Bend, OR (821)",
        "billings_mt_756": "Billings, MT (756)",
        "biloxigulfport_ms_746": "Biloxi-Gulfport, MS (746)",
        "binghamton_ny_502": "Binghamton, NY (502)",
        "birmingham_al_630": "Birmingham, AL (630)",
        "bluefieldbeckleyoak_hill_wv_559": "Bluefield-Beckley-Oak Hill, WV (559)",
        "boise_id_757": "Boise, ID (757)",
        "boston_mamanchester_nh_506": "Boston, MA-Manchester, NH (506)",
        "bowling_green_ky_736": "Bowling Green, KY (736)",
        "buffalo_ny_514": "Buffalo, NY (514)",
        "burlington_vtplattsburgh_ny_523": "Burlington, VT-Plattsburgh, NY (523)",
        "buttebozeman_mt_754": "Butte-Bozeman, MT (754)",
        "casperriverton_wy_767": "Casper-Riverton, WY (767)",
        "cedar_rapidswaterlooiowa_city_dubuque_ia_637": "Cedar Rapids-Waterloo-Iowa City \u0026 Dubuque, IA (637)",
        "champaign_springfielddecaturil_648": "Champaign \u0026 Springfield-Decatur,IL (648)",
        "charleston_sc_519": "Charleston, SC (519)",
        "charlestonhuntington_wv_564": "Charleston-Huntington, WV (564)",
        "charlotte_nc_517": "Charlotte, NC (517)",
        "charlottesville_va_584": "Charlottesville, VA (584)",
        "chattanooga_tn_575": "Chattanooga, TN (575)",
        "cheyenne_wyscottsbluff_ne_759": "Cheyenne, WY-Scottsbluff, NE (759)",
        "chicago_il_602": "Chicago, IL (602)",
        "chicoredding_ca_868": "Chico-Redding, CA (868)",
        "cincinnati_oh_515": "Cincinnati, OH (515)",
        "clarksburgweston_wv_598": "Clarksburg-Weston, WV (598)",
        "clevelandakron_canton_oh_510": "Cleveland-Akron (Canton), OH (510)",
        "colorado_springspueblo_co_752": "Colorado Springs-Pueblo, CO (752)",
        "columbia_sc_546": "Columbia, SC (546)",
        "columbiajefferson_city_mo_604": "Columbia-Jefferson City, MO (604)",
        "columbus_ga_522": "Columbus, GA (522)",
        "columbus_oh_535": "Columbus, OH (535)",
        "columbustupelowest_point_ms_673": "Columbus-Tupelo-West Point, MS (673)",
        "corpus_christi_tx_600": "Corpus Christi, TX (600)",
        "dallasft_worth_tx_623": "Dallas-Ft. Worth, TX (623)",
        "davenportiarock_islandmolineil_682": "Davenport,IA-Rock Island-Moline,IL (682)",
        "dayton_oh_542": "Dayton, OH (542)",
        "denver_co_751": "Denver, CO (751)",
        "des_moinesames_ia_679": "Des Moines-Ames, IA (679)",
        "detroit_mi_505": "Detroit, MI (505)",
        "dothan_al_606": "Dothan, AL (606)",
        "duluth_mnsuperior_wi_676": "Duluth, MN-Superior, WI (676)",
        "el_paso_tx_765": "El Paso, TX (765)",
        "elmira_ny_565": "Elmira, NY (565)",
        "erie_pa_516": "Erie, PA (516)",
        "eugene_or_801": "Eugene, OR (801)",
        "eureka_ca_802": "Eureka, CA (802)",
        "evansville_in_649": "Evansville, IN (649)",
        "fairbanks_ak_745": "Fairbanks, AK (745)",
        "fargovalley_city_nd_724": "Fargo-Valley City, ND (724)",
        "flintsaginawbay_city_mi_513": "Flint-Saginaw-Bay City, MI (513)",
        "florencemyrtle_beach_sc_570": "Florence-Myrtle Beach, SC (570)",
        "fresnovisalia_ca_866": "Fresno-Visalia, CA (866)",
        "ft_myersnaples_fl_571": "Ft. Myers-Naples, FL (571)",
        "ft_smithfayettevillespringdalerogers_ar_670": "Ft. Smith-Fayetteville-Springdale-Rogers, AR (670)",
        "ft_wayne_in_509": "Ft. Wayne, IN (509)",
        "gainesville_fl_592": "Gainesville, FL (592)",
        "glendive_mt_798": "Glendive, MT (798)",
        "grand_junctionmontrose_co_773": "Grand Junction-Montrose, CO (773)",
        "grand_rapidskalamazoobattle_creek_mi_563": "Grand Rapids-Kalamazoo-Battle Creek, MI (563)",
        "great_falls_mt_755": "Great Falls, MT (755)",
        "green_bayappleton_wi_658": "Green Bay-Appleton, WI (658)",
        "greensborohigh_pointwinston_salem_nc_518": "Greensboro-High Point-Winston Salem, NC (518)",
        "greenvillenew_bernwashington_nc_545": "Greenville-New Bern-Washington, NC (545)",
        "greenvillespartanburg_scasheville_ncanderson_sc_567": "Greenville-Spartanburg, SC-Asheville, NC-Anderson, SC (567)",
        "greenwoodgreenville_ms_647": "Greenwood-Greenville, MS (647)",
        "harlingenweslacobrownsvillemcallen_tx_636": "Harlingen-Weslaco-Brownsville-McAllen, TX (636)",
        "harrisburglancasterlebanonyork_pa_566": "Harrisburg-Lancaster-Lebanon-York, PA (566)",
        "harrisonburg_va_569": "Harrisonburg, VA (569)",
        "hartford_new_haven_ct_533": "Hartford \u0026 New Haven, CT (533)",
        "hattiesburglaurel_ms_710": "Hattiesburg-Laurel, MS (710)",
        "helena_mt_766": "Helena, MT (766)",
        "honolulu_hi_744": "Honolulu, HI (744)",
        "houston_tx_618": "Houston, TX (618)",
        "huntsvilledecatur_florence_al_691": "Huntsville-Decatur (Florence), AL (691)",
        "idaho_fallspocatello_id_758": "Idaho Falls-Pocatello, ID (758)",
        "indianapolis_in_527": "Indianapolis, IN (527)",
        "jackson_ms_718": "Jackson, MS (718)",
        "jackson_tn_639": "Jackson, TN (639)",
        "jacksonville_fl_561": "Jacksonville, FL (561)",
        "johnstownaltoona_pa_574": "Johnstown-Altoona, PA (574)",
        "jonesboro_ar_734": "Jonesboro, AR (734)",
        "joplin_mopittsburg_ks_603": "Joplin, MO-Pittsburg, KS (603)",
        "juneau_ak_747": "Juneau, AK (747)",
        "kansas_city_mo_616": "Kansas City, MO (616)",
        "knoxville_tn_557": "Knoxville, TN (557)",
        "la_crosseeau_claire_wi_702": "La Crosse-Eau Claire, WI (702)",
        "lafayette_in_582": "Lafayette, IN (582)",
        "lafayette_la_642": "Lafayette, LA (642)",
        "lake_charles_la_643": "Lake Charles, LA (643)",
        "lansing_mi_551": "Lansing, MI (551)",
        "laredo_tx_749": "Laredo, TX (749)",
        "las_vegas_nv_839": "Las Vegas, NV (839)",
        "lexington_ky_541": "Lexington, KY (541)",
        "lima_oh_558": "Lima, OH (558)",
        "lincoln_hastingskearney_ne_722": "Lincoln \u0026 Hastings-Kearney, NE (722)",
        "little_rockpine_bluff_ar_693": "Little Rock-Pine Bluff, AR (693)",
        "los_angeles_ca_803": "Los Angeles, CA (803)",
        "louisville_ky_529": "Louisville, KY (529)",
        "lubbock_tx_651": "Lubbock, TX (651)",
        "macon_ga_503": "Macon, GA (503)",
        "madison_wi_669": "Madison, WI (669)",
        "mankato_mn_737": "Mankato, MN (737)",
        "marquette_mi_553": "Marquette, MI (553)",
        "medfordklamath_falls_or_813": "Medford-Klamath Falls, OR (813)",
        "memphis_tn_640": "Memphis, TN (640)",
        "meridian_ms_711": "Meridian, MS (711)",
        "miamift_lauderdale_fl_528": "Miami-Ft. Lauderdale, FL (528)",
        "milwaukee_wi_617": "Milwaukee, WI (617)",
        "minneapolisst_paul_mn_613": "Minneapolis-St. Paul, MN (613)",
        "minotbismarckdickinson_williston_nd_687": "Minot-Bismarck-Dickinson(Williston), ND (687)",
        "missoula_mt_762": "Missoula, MT (762)",
        "mobile_alpensacola_ft_walton_beach_fl_686": "Mobile, AL-Pensacola (Ft. Walton Beach), FL (686)",
        "monroe_lael_dorado_ar_628": "Monroe, LA-El Dorado, AR (628)",
        "montereysalinas_ca_828": "Monterey-Salinas, CA (828)",
        "montgomery_selma_al_698": "Montgomery (Selma), AL (698)",
        "nashville_tn_659": "Nashville, TN (659)",
        "new_orleans_la_622": "New Orleans, LA (622)",
        "new_york_ny_501": "New York, NY (501)",
        "norfolkportsmouthnewport_newsva_544": "Norfolk-Portsmouth-Newport News,VA (544)",
        "north_platte_ne_740": "North Platte, NE (740)",
        "odessamidland_tx_633": "Odessa-Midland, TX (633)",
        "oklahoma_city_ok_650": "Oklahoma City, OK (650)",
        "omaha_ne_652": "Omaha, NE (652)",
        "orlandodaytona_beachmelbourne_fl_534": "Orlando-Daytona Beach-Melbourne, FL (534)",
        "ottumwa_iakirksville_mo_631": "Ottumwa, IA-Kirksville, MO (631)",
        "paducah_kycape_girardeau_moharrisburgmount_vernon_il_632": "Paducah, KY-Cape Girardeau, MO-Harrisburg-Mount Vernon, IL (632)",
        "palm_springs_ca_804": "Palm Springs, CA (804)",
        "panama_city_fl_656": "Panama City, FL (656)",
        "parkersburg_wv_597": "Parkersburg, WV (597)",
        "peoriabloomington_il_675": "Peoria-Bloomington, IL (675)",
        "philadelphia_pa_504": "Philadelphia, PA (504)",
        "phoenix_az_753": "Phoenix, AZ (753)",
        "pittsburgh_pa_508": "Pittsburgh, PA (508)",
        "portland_or_820": "Portland, OR (820)",
        "portlandauburn_me_500": "Portland-Auburn, ME (500)",
        "presque_isle_me_552": "Presque Isle, ME (552)",
        "providence_rinew_bedford_ma_521": "Providence, RI-New Bedford, MA (521)",
        "quincy_ilhannibal_mokeokuk_ia_717": "Quincy, IL-Hannibal, MO-Keokuk, IA (717)",
        "raleighdurham_fayetteville_nc_560": "Raleigh-Durham (Fayetteville), NC (560)",
        "rapid_city_sd_764": "Rapid City, SD (764)",
        "reno_nv_811": "Reno, NV (811)",
        "richmondpetersburg_va_556": "Richmond-Petersburg, VA (556)",
        "roanokelynchburg_va_573": "Roanoke-Lynchburg, VA (573)",
        "rochester_mnmason_city_iaaustin_mn_611": "Rochester, MN-Mason City, IA-Austin, MN (611)",
        "rochester_ny_538": "Rochester, NY (538)",
        "rockford_il_610": "Rockford, IL (610)",
        "sacramentostocktonmodesto_ca_862": "Sacramento-Stockton-Modesto, CA (862)",
        "salisbury_md_576": "Salisbury, MD (576)",
        "salt_lake_city_ut_770": "Salt Lake City, UT (770)",
        "san_angelo_tx_661": "San Angelo, TX (661)",
        "san_antonio_tx_641": "San Antonio, TX (641)",
        "san_diego_ca_825": "San Diego, CA (825)",
        "san_franciscooaklandsan_jose_ca_807": "San Francisco-Oakland-San Jose, CA (807)",
        "santa_barbarasanta_mariasan_luis_obispo_ca_855": "Santa Barbara-Santa Maria-San Luis Obispo, CA (855)",
        "savannah_ga_507": "Savannah, GA (507)",
        "seattletacoma_wa_819": "Seattle-Tacoma, WA (819)",
        "sherman_txada_ok_657": "Sherman, TX-Ada, OK (657)",
        "shreveport_la_612": "Shreveport, LA (612)",
        "sioux_city_ia_624": "Sioux City, IA (624)",
        "sioux_falls_mitchell_sd_725": "Sioux Falls(Mitchell), SD (725)",
        "south_bendelkhart_in_588": "South Bend-Elkhart, IN (588)",
        "spokane_wa_881": "Spokane, WA (881)",
        "springfield_mo_619": "Springfield, MO (619)",
        "springfieldholyoke_ma_543": "Springfield-Holyoke, MA (543)",
        "st_joseph_mo_638": "St. Joseph, MO (638)",
        "st_louis_mo_609": "St. Louis, MO (609)",
        "syracuse_ny_555": "Syracuse, NY (555)",
        "tallahassee_flthomasville_ga_530": "Tallahassee, FL-Thomasville, GA (530)",
        "tampast_petersburg_sarasota_fl_539": "Tampa-St Petersburg (Sarasota), FL (539)",
        "terre_haute_in_581": "Terre Haute, IN (581)",
        "toledo_oh_547": "Toledo, OH (547)",
        "topeka_ks_605": "Topeka, KS (605)",
        "traverse_citycadillac_mi_540": "Traverse City-Cadillac, MI (540)",
        "tricities_tnva_531": "Tri-Cities, TN-VA (531)",
        "tucson_sierra_vista_az_789": "Tucson (Sierra Vista), AZ (789)",
        "tulsa_ok_671": "Tulsa, OK (671)",
        "twin_falls_id_760": "Twin Falls, ID (760)",
        "tylerlongview_lufkin_nacogdoches_tx_709": "Tyler-Longview(Lufkin \u0026 Nacogdoches), TX (709)",
        "utica_ny_526": "Utica, NY (526)",
        "victoria_tx_626": "Victoria, TX (626)",
        "wacotemplebryan_tx_625": "Waco-Temple-Bryan, TX (625)",
        "washington_dc_hagerstown_md_511": "Washington, DC (Hagerstown, MD) (511)",
        "watertown_ny_549": "Watertown, NY (549)",
        "wausaurhinelander_wi_705": "Wausau-Rhinelander, WI (705)",
        "west_palm_beachft_pierce_fl_548": "West Palm Beach-Ft. Pierce, FL (548)",
        "wheeling_wvsteubenville_oh_554": "Wheeling, WV-Steubenville, OH (554)",
        "wichita_falls_tx_lawton_ok_627": "Wichita Falls, TX \u0026 Lawton, OK (627)",
        "wichitahutchinson_ks_678": "Wichita-Hutchinson, KS (678)",
        "wilkes_barrescranton_pa_577": "Wilkes Barre-Scranton, PA (577)",
        "wilmington_nc_550": "Wilmington, NC (550)",
        "yakimapascorichlandkennewick_wa_810": "Yakima-Pasco-Richland-Kennewick, WA (810)",
        "youngstown_oh_536": "Youngstown, OH (536)",
        "yuma_azel_centro_ca_771": "Yuma, AZ-El Centro, CA (771)",
        "zanesville_oh_596": "Zanesville, OH (596)"
      },
      "everxchange_channel": {
        "affiliate": "Affiliate",
        "co_reg": "Co Reg",
        "connected_tv": "Connected TV",
        "content": "Content",
        "direct_display": "Direct Display",
        "email": "Email",
        "incent": "Incent",
        "influencers": "Influencers",
        "lead_gen": "Lead Gen",
        "media_buying": "Media Buying",
        "native_ads": "Native Ads",
        "o_o": "O\u0026O",
        "paypercall": "Pay-Per-Call",
        "pop": "Pop",
        "programmatic": "Programmatic",
        "push": "Push",
        "sem": "SEM",
        "sms": "SMS",
        "social": "Social",
        "survey": "Survey",
        "video": "Video"
      },
      "everxchange_geo_vertical": {"apac": "APAC", "emea": "EMEA", "global": "Global", "latam": "LATAM", "nam": "NAM"},
      "everxchange_partners": {
        "ad_attraction": "Ad Attraction",
        "ad_synthetic": "AdSynthetic",
        "adcolony": "AdColony",
        "adeals": "Adeals",
        "adforus": "Adforus",
        "adgatemedia": "AdGateMedia",
        "adgem": "AdGem",
        "adkomo": "Adkomo",
        "admitad": "Admitad",
        "adperio": "Adperio",
        "adstack_trackier": "Adstack (Trackier)",
        "adstacks": "AdStacks",
        "advibe": "AdVibe",
        "adx_pro": "ADX PRO",
        "affle": "Affle",
        "all_out_ads": "All Out Ads",
        "apetab": "ApeTab",
        "appalgo": "Appalgo",
        "appier": "Appier",
        "applift": "AppLift",
        "applift_incent_network": "AppLift Incent network",
        "appnext": "AppNext",
        "apptap": "AppTap",
        "appxigo": "Appxigo",
        "aragonadvertising": "AragonAdvertising",
        "aragonpremium": "AragonPremium",
        "art_of_click": "Art Of Click",
        "avant_link": "AvantLink",
        "avazu_native_ad": "Avazu Native Ad",
        "avow": "Avow",
        "awin": "Awin",
        "ayetstudios": "Ayetstudios",
        "baidu": "Baidu",
        "batmobi": "BatMobi",
        "beintoo": "Beintoo",
        "blind_ferret": "Blind Ferret",
        "bold_screen_media": "OPN",
        "brusmedia": "BrusMedia",
        "cauly": "Cauly",
        "cj_affiliate": "CJ Affiliate",
        "clearpier_performance": "Clearpier Performance",
        "clicksmob": "Clicksmob",
        "clinkad": "ClinkAd",
        "collectcent": "CollectCent",
        "commission_factory": "Commission Factory",
        "covalent_media_group": "Covalent Media Group",
        "cpxi": "CPXI",
        "creativeclicks": "CreativeClicks",
        "cryptoads": "CryptoAds",
        "cubepile": "Cubepile",
        "curatemobile": "CurateMobile",
        "dfo": "DFO",
        "discipline_digital": "Discipline Digital",
        "displayio": "DisplayIO",
        "do_fun": "Do Fun",
        "echo226": "Echo226",
        "edge360": "Edge360",
        "emx": "EMX",
        "expand_mobile": "Expand Mobile",
        "fmg": "FMG",
        "gazet": "Gazet",
        "glispa": "Glispa",
        "gridwise": "Gridwise",
        "hangmyads": "HangMyAds",
        "harvest_media": "Harvest Media",
        "impact_radius": "Impact Radius",
        "inflecto": "Inflecto",
        "instal": "Instal",
        "instar": "instar",
        "intellect_lite": "Intellect lite",
        "koneo": "Koneo",
        "kvell": "Kvell",
        "lead_bolt": "Lead Bolt",
        "leadid": "LeadId",
        "lemmonet": "Lemmonet",
        "libertap": "Libertap",
        "linkbux": "Linkbux",
        "macan_native": "Macan Native",
        "maggomedia": "MAGGOMEDIA",
        "marsfeeds": "MarsFeeds",
        "mazooka": "Mazooka",
        "minimob": "Minimob",
        "mobisummer": "Mobisummer",
        "moblin_previously_zoomd": "Moblin (previously Zoomd)",
        "mobobeat": "Mobobeat",
        "mobpower": "MobPower",
        "mobrain": "Mobrain",
        "mobusi": "Mobusi",
        "mobvault": "Mobvault",
        "mobvista": "Mobvista",
        "moca_tech": "Moca Tech",
        "mokumads": "MokumAds",
        "monsterads": "MonsterAds",
        "mooko": "Mooko",
        "motive": "Motive",
        "msales": "msales",
        "myappfree": "MyAppFree",
        "offerslook": "OffersLook",
        "papaya": "Papaya",
        "partnerize": "Partnerize",
        "pepperjam": "Pepperjam",
        "perform_cb": "Perform(cb)",
        "personaly": "Personaly",
        "playdigo": "Playdigo",
        "pwn_games": "PWN Games",
        "qverse": "Qverse",
        "qverse_v3": "Qverse(v3)",
        "rakuten": "Rakuten",
        "resultsmedia": "ResultsMedia",
        "revboost": "RevBoost",
        "revmob": "RevMob",
        "secco": "Secco",
        "share_a_sale": "ShareASale",
        "startapp": "StartApp",
        "superads": "SuperADS",
        "swaarm": "Swaarm",
        "tab": "Tab",
        "taptica": "Taptica",
        "thing_or_two": "Thing or Two",
        "united": "United",
        "uppway": "UppWay",
        "vene": "Vene",
        "weq": "WeQ",
        "weq_hasoffers": "WeQ (HasOffers)",
        "wewe": "Wewe",
        "yeahmobi": "YeahMobi",
        "yellow_hammer": "Yellow Hammer",
        "youappi": "Youappi",
        "zoomd_v3": "Zoomd(v3)"
      },
      "everxchange_platforms": {
        "ad_synthetic": "AdSynthetic",
        "addictive_ads": "Addictive Ads",
        "adeals": "Adeals",
        "adforus": "Adforus",
        "adgate": "Adgate",
        "adgem": "AdGem",
        "adkomo": "Adkomo",
        "admitad": "Admitad",
        "adperio": "Adperio",
        "adstacks": "AdStacks",
        "affise": "Affise",
        "affle": "Affle",
        "apetab": "ApeTab",
        "appalgo": "Appalgo",
        "appier": "Appier",
        "applift": "AppLift",
        "applift_incent_network": "AppLift Incent network",
        "appnext": "AppNext",
        "apptap": "AppTap",
        "appxigo": "Appxigo",
        "art_of_click": "Art Of Click",
        "avant_link": "AvantLink",
        "avazu_native_ad": "Avazu Native Ad",
        "awin": "Awin",
        "axonite": "Axonite",
        "ayetstudios": "Ayetstudios",
        "baidu": "Baidu",
        "batmobi": "BatMobi",
        "blind_ferret": "Blind Ferret",
        "blue_track_media_proprietary_platform": "Blue Track Media Proprietary Platform",
        "brus": "Brus",
        "cake": "Cake",
        "cauly": "Cauly",
        "cj_affiliate": "CJ Affiliate",
        "clearpier_performance": "Clearpier Performance",
        "clicksmob": "Clicksmob",
        "clink_ad": "Clink Ad",
        "commission_factory": "Commission Factory",
        "creativeclicks": "CreativeClicks",
        "curatemobile": "CurateMobile",
        "displayio": "DisplayIo",
        "dofun": "DoFun",
        "echo226": "Echo226",
        "everflow": "Everflow",
        "gazet": "Gazet",
        "glispa": "Glispa",
        "hangmyads": "HangMyAds",
        "has_offers": "Has Offers",
        "hubscale": "Hubscale",
        "impact_radius": "Impact Radius",
        "inmobi": "InMobi",
        "instal": "Instal",
        "intellect_lite": "Intellect lite",
        "khing": "Khing",
        "koneo": "Koneo",
        "lead_bolt": "Lead Bolt",
        "lemmonet": "Lemmonet",
        "libertap": "Libertap",
        "linkbux": "Linkbux",
        "macan_native": "Macan Native",
        "marsfeeds": "MarsFeeds",
        "minimob": "Minimob",
        "mobisummer": "Mobisummer",
        "mobobeat": "Mobobeat",
        "mobpower": "MobPower",
        "mobrain": "Mobrain",
        "mobusi": "Mobusi",
        "mobvault": "Mobvault",
        "mobvista": "Mobvista",
        "moca_tech": "Moca Tech",
        "msales": "msales",
        "mundomedia": "MundoMedia",
        "myappfree": "MyAppFree",
        "offerslook": "OffersLook",
        "orangear": "Orangear",
        "orangear_v2": "Orangear V2",
        "papaya": "Papaya",
        "partnerize": "Partnerize",
        "pepperjam": "Pepperjam",
        "perform_cb": "Perform(cb)",
        "personaly": "Personaly",
        "qverse": "Qverse",
        "qverse_v3": "Qverse(v3)",
        "rakuten": "Rakuten",
        "resultmedia": "ResultMedia",
        "revmob": "RevMob",
        "share_a_sale": "ShareASale",
        "startapp": "StartApp",
        "superads": "SuperADS",
        "swaarm": "Swaarm",
        "taptica": "Taptica",
        "trackier": "Trackier",
        "united": "United",
        "vene": "Vene",
        "weq": "WeQ",
        "yeahmobi": "YeahMobi",
        "youappi": "Youappi",
        "zoomd_deprecated": "Zoomd(deprecated)",
        "zoomd_v3": "Zoomd(v3)"
      },
      "everxchange_vertical": {
        "accessibility": "Accessibility",
        "adult": "Adult",
        "adult_gaming": "Adult Gaming",
        "apps": "Apps",
        "astrology": "Astrology",
        "auto": "Auto",
        "biz_op": "Biz Op",
        "brand": "Brand",
        "business_management": "Business Management",
        "casino": "Casino",
        "cbd": "CBD",
        "cc_submits": "CC Submits",
        "chat_traffic": "Chat Traffic",
        "consumer_loans": "Consumer Loans",
        "cpa": "CPA",
        "cpi": "CPI",
        "credit": "Credit",
        "crypto": "Crypto",
        "cyber_security": "Cyber Security",
        "dating": "Dating",
        "diet": "Diet",
        "ecommerce": "eCommerce",
        "education_students": "Education / Students",
        "electronics": "Electronics",
        "email": "Email",
        "entertainment": "Entertainment",
        "family": "Family",
        "fashion": "Fashion",
        "finance": "Finance",
        "fitness": "Fitness",
        "food": "Food",
        "gambling": "Gambling",
        "gaming": "Gaming",
        "health_beauty": "Health / Beauty",
        "home": "Home",
        "identity_protection": "Identity Protection",
        "insurance": "Insurance",
        "lead_gen": "Lead Gen",
        "legal": "Legal",
        "lifestyle": "Lifestyle",
        "live_entertainment": "Live Entertainment",
        "medical_consulting": "Medical Consulting",
        "mindfulness": "Mindfulness",
        "monthy_subscriptions": "Monthy Subscriptions",
        "nonapp": "Non-App",
        "nutra": "Nutra",
        "payperview": "Pay-per-view",
        "pet": "Pet",
        "ppcall": "PPCall",
        "samples": "Samples",
        "social_casino": "Social Casino",
        "software": "Software",
        "sports_betting": "Sports Betting",
        "stocks": "Stocks",
        "streaming": "Streaming",
        "subprime": "Subprime",
        "subscription_box": "Subscription Box",
        "survival_outdoor": "Survival/Outdoor",
        "sweepstakes": "Sweepstakes",
        "tech": "Tech",
        "travel": "Travel",
        "utility": "Utility",
        "venue_tickets": "Venue Tickets",
        "water": "Water",
        "weight_loss": "Weight Loss",
        "wellness": "Wellness"
      },
      "integration": {
        "24metrics": "24metrics",
        "24metrics_description": "24metrics provides you with mobile fraud detection and prevention. Enabling the Fraudshield integration activates an additional layer of detection for IP Duplication, VPNs, Proxies, Emulators, Bots, Click Spam, and more.",
        "anura": "Anura",
        "anura_description": "Anura is an ad fraud protection solution that monitors your traffic to identify real users versus bots, malware, and human fraud.",
        "appsflyer": "Appsflyer",
        "bigcommerce": "BigCommerce",
        "bigcommerce_description": "Instantly connect your BigCommerce store accounts to your Everflow platform account. This installs your Direct Linking Click and Conversion Tracking across the BigCommerce store, so you're ready to launch your Partner program right away. ",
        "clickbank": "Clickbank",
        "clickbank_description": "Whether you’re new to performance marketing or an established online business, ClickBank provides the ability to scale, grow sales, and make money online.",
        "clickfunnels": "ClickFunnels",
        "clickfunnels_description": "Instantly connect your ClickFunnels accounts to your Everflow platform account. Provides your custom script and webhook URLs, so you're ready to launch your Partner program right away.",
        "docusign": "DocuSign",
        "docusign_description": "Distribute your electronic documents to advertisers, affiliates etc. and authenticate them as signers from a central document repository, all integrated with the Everflow platform.",
        "ezepo": "Ezepo",
        "ezepo_description": "Praised for its superior design, Ezepo manages email opt-out compliance with ease and confidence.",
        "forensiq": "Forensiq",
        "forensiq_description": "Forensiq's ad fraud solution automates the detection, prevention, and suppression of many of the significant fraud types involved in performance marketing.",
        "fraudscore": "FraudScore",
        "fraudscore_description": "Fraudscore is an anti-fraud solution that helps you fight mobile fraud. FraudScore provides you with an analysis of your mobile advertising campaigns and provides feedback for rejecting fraudulent installs and conversions.",
        "hubspot": "Hubspot",
        "hubspot_description": "Connect your HubSpot lead data to your Everflow platform account. This allows you to track the lead data as offer events inside of Everflow.",
        "ipqualityscore": "IPQualityScore",
        "ipqualityscore_description": "IPQualityScore provides real-time fraud scoring to identify high-risk users and transactions. IPQualityScore identifies bots, fake accounts, fraudsters, suspicious transactions, and malicious users.",
        "mandrill_mailchimp": "Mandrill (Mailchimp)",
        "mandrill_mailchimp_description": "Instantly connect your Mandrill by Mailchimp account for sending out in-platform messages. Once enabled, you will be able to send out all notifications and dynamic Direct Messages through your Mandrill email accounts.",
        "masspay": "MassPay",
        "masspay_description": "Use MassPay to process your Partner’s payments for Nutra, Cannabis and other industries via cash pickup, bank, virtual and physical prepaid cards, and Mobile Wallets.",
        "optizmo": "Optizmo",
        "optizmo_description": "Optizmo is the recognized leader for email suppression list management, SMS compliance, data management, and risk mitigation services relative to email compliance. ",
        "paypal": "Paypal",
        "paypal_description": "PayPal lets you pay your Partners immediately. Your recipients can quickly receive their funds with a PayPal account, as they likely are one of the 210 million active PayPal accounts worldwide.",
        "recharge": "ReCharge",
        "recharge_description": "Enhance your Shopify integration by connecting ReCharge. Track and attribute rebills precisely.",
        "salesforce": "Salesforce",
        "salesforce_description": "Connect your Salesforce lead or opportunity data to your Everflow platform account. This allows you to track the data as offer events inside of Everflow.",
        "scrubkit": "Scrubkit",
        "scrubkit_description": "Scrubkit provides you with an automated analytics system that assists with the detection and management of affiliate fraud and risk. Algorithms process your data, then tuned thresholds alert you upon finding risk.",
        "sendgrid": "Sendgrid",
        "sendgrid_description": "Instantly connect your SendGrid account for sending out in-platform messages. Once enabled, you will be able to send out all notifications and dynamic Direct Messages through your Sendgrid email accounts.",
        "shopify": "Shopify",
        "shopify_description": "Instantly connect your Shopify store accounts to your Everflow platform account. This installs your Direct Linking Click and Conversion Tracking across the Shopify store, so you're ready to launch your Partner program right away. ",
        "stripe": "Stripe",
        "stripe_description": "Allows Everflow to interface with Stripe orders",
        "stripe_webhooks": "Stripe Webhooks",
        "stripe_webhooks_description": "Allows Everflow to interface with Stripe orders",
        "tipalti": "Tipalti",
        "tipalti_description": "Tipalti allows you to automate your global payments. Enable Tipalti today to handle payments to Partners across 190 countries with full regulatory compliance.",
        "unsubcentral": "UnsubCentral",
        "unsubcentral_description": " UnsubCentral provides enterprise-level suppression list management software for brands and affiliates.",
        "veem": "Veem",
        "veem_description": "Veem is the subscription-free solution for processing payments globally. Enable Veem today to manage, track, and automate all of your payments to Partners without leaving the Everflow platform.",
        "woocommerce": "WooCommerce",
        "woocommerce_description": "Allows Everflow to automatically install webhooks on your WooCommerce store in order to enrich conversion data with order details and support refunds."
      },
      "isp": {
        "013_netvision_ltd": "013 Netvision Ltd",
        "116_madhav_darshan": "116 Madhav Darshan",
        "11_internet_inc": "11 Internet Inc.",
        "11_telecom_gmbh": "11 Telecom Gmbh",
        "123_net_inc": "123.net Inc.",
        "1651884_ontario_inc": "1651884 Ontario Inc.",
        "18_science_park_drive": "18 Science Park Drive",
        "1_northfields_av": "1 Northfields Av",
        "1telecom_servicos_de_tecnologia_em_internet_ltda": "1telecom Servicos De Tecnologia Em Internet Ltda",
        "2020_communications_llc": "2020 Communications Llc",
        "20_dover_drive_singapore_138682": "20 Dover Drive Singapore 138682",
        "21vianet_inc": "21vianet Inc.",
        "22_stirling_st": "22 Stirling St",
        "23media_gmbh": "23media Gmbh",
        "24_shells": "24 Shells",
        "2com_co_ltd": "2com Co Ltd.",
        "2degrees_mobile_limited": "2degrees Mobile Limited",
        "2nd_floor_vamanashram_building": "2nd Floor Vamanashram Building",
        "360_communications_llc": "360 Communications Llc",
        "3_rivers_telephone_cooperative_inc": "3 Rivers Telephone Cooperative Inc.",
        "3db_wireless_inc": "3db Wireless Inc",
        "3menwork_integrated_networks_inc": "3menwork Integrated Networks Inc.",
        "4_siwi_llc": "4 Siwi Llc",
        "4dvision_llc": "4dvision Llc",
        "4inet_sorocaba_ltda_epp": "4inet Sorocaba Ltda Epp",
        "51a_rachna_midas_gokulpeth": "51a Rachna Midas Gokulpeth",
        "5nines_data_llc": "5nines Data Llc",
        "702_communications": "702 Communications",
        "76_telecomunicacao_ltda": "76 Telecomunicacao Ltda",
        "7eleven_inc": "7-eleven Inc.",
        "8f_kitec": "8f Kitec",
        "901_a1_902_a2_tidel_park": "901 A1 902 A2 Tidel Park",
        "?": "?",
        "a1_bulgaria_ead": "A1 Bulgaria Ead",
        "a1_slovenija_telekomunikacijske_storitve_d_d": "A1 Slovenija Telekomunikacijske Storitve D.d.",
        "a1_telekom_austria_ag": "A1 Telekom Austria Ag",
        "a2_telecom_eireli_me": "A2 Telecom Eireli - Me",
        "a2z_telecomunicaciones_s_l": "A2z Telecomunicaciones S.l.",
        "a_a_informatica_e_manutencao_ltda": "A. A. Informatica E Manutencao Ltda",
        "a_communications_south_mountain_llc": "A Communications South Mountain Llc",
        "a_f_vianna_de_oliveira_provedores_me": "A. F. Vianna De Oliveira Provedores - Me",
        "a_g_servicos_em_ti": "A G Servicos Em Ti",
        "a_j_da_silva": "A.j Da Silva",
        "a_l_n_lopes": "A. L. N. Lopes",
        "a_r_g3_telecom_associados_ltda": "A R G3 Telecom Associados Ltda",
        "a_s_vera_cruz_me": "A.s. Vera Cruz - Me",
        "a_z_de_araujo_neto_me": "A Z De Araujo Neto Me",
        "aaa_of_michigan": "Aaa Of Michigan",
        "aalok_it_limited": "Aalok It Limited",
        "aapt_limited": "Aapt Limited",
        "abbott_laboratories": "Abbott Laboratories",
        "abc_telecom": "Abc Telecom",
        "abcrede_ltda_me": "Abcrede Ltda Me",
        "abilene_independent_school_district": "Abilene Independent School District",
        "abn": "Abn",
        "abovenet_communications_taiwan": "Abovenet Communications Taiwan",
        "abzorb_data_limited": "Abzorb Data Limited",
        "academic_newtown_community_cable_service": "Academic Newtown Community Cable Service",
        "academy_school_district_20": "Academy School District 20",
        "acantho_s_p_a": "Acantho S.p.a",
        "accelerated_connections_inc": "Accelerated Connections Inc.",
        "accenture_llp": "Accenture Llp",
        "access": "Access",
        "access_cable_television_inc": "Access Cable Television Inc.",
        "access_communications_cooperative_limited": "Access Communications Co-operative Limited",
        "access_one_inc": "Access One Inc.",
        "access_smart_solutions_india_pvt_ltd": "Access Smart Solutions India Pvt Ltd",
        "accessplus_communications_inc": "Accessplus Communications Inc.",
        "acd_net": "Acd.net",
        "ace_comercio_e_servicos_de_informatica_ltda_me": "Ace Comercio E Servicos De Informatica Ltda - Me",
        "ace_data_centers_inc": "Ace Data Centers Inc.",
        "acentek": "Acentek",
        "acer_telecomunicacoes_ltda": "Acer Telecomunicacoes Ltda",
        "acesse_comunicacao_ltda": "Acesse Comunicacao Ltda",
        "acesso_net_servicos": "Acesso Net Servicos",
        "acesso_point_eireli_me": "Acesso Point Eireli - Me",
        "acessy_telecomunicacoes": "Acessy Telecomunicacoes",
        "aci_worldwide_inc": "Aci Worldwide Inc.",
        "acn": "Acn",
        "aco_computerservice_gmbh": "Aco Computerservice Gmbh",
        "aconet": "Aconet",
        "act_government_intact_group": "Act Government Intact Group",
        "action_communications_inc": "Action Communications Inc.",
        "active_network_s_p_a": "Active Network S.p.a.",
        "actrix_networks_ltd": "Actrix Networks Ltd",
        "ad_telecom": "Ad Telecom",
        "adamo_telecom_iberia_s_a": "Adamo Telecom Iberia S.a",
        "adams_catv_inc": "Adams Catv Inc",
        "adams_networks_inc": "Adams Networks Inc.",
        "adamswells_internet": "Adamswells Internet",
        "adapt_services_ltd": "Adapt Services Ltd",
        "adaptive_systems_as": "Adaptive Systems As",
        "adecco_it_services_sas": "Adecco It Services Sas",
        "adelphi_net1_ltd": "Adelphi Net1 Ltd",
        "adelphi_university": "Adelphi University",
        "adenilton_da_paixao_ribeiro_me": "Adenilton Da Paixao Ribeiro - Me",
        "adista_sas": "Adista Sas",
        "adjenet_networks_sl": "Adjenet Networks Sl",
        "adllink_provedor_de_internet_via_radio_ltda": "Adllink Provedor De Internet Via Radio Ltda",
        "administracio_auutonomica": "Administracio Auutonomica",
        "administracion_nacional_de_telecomunicaciones": "Administracion Nacional De Telecomunicaciones",
        "adp_inc": "Adp Inc.",
        "advanced_cable_communications_llc": "Advanced Cable Communications Llc",
        "advanced_communications_technology": "Advanced Communications Technology",
        "advanced_wireless_network_company_limited": "Advanced Wireless Network Company Limited",
        "adventist_health": "Adventist Health",
        "adventist_health_systems_ssci": "Adventist Health Systems Ssci",
        "adygei_telephone_company_cjsc": "Adygei Telephone Company Cjsc",
        "adylnet_telecom": "Adylnet Telecom",
        "aegis_group_plc": "Aegis Group Plc",
        "aehn": "Aehn",
        "aei_internet_inc": "Aei Internet Inc.",
        "aeneas_internet_services": "Aeneas Internet Services",
        "aerioconnect": "Aerioconnect",
        "aerux_llp": "Aerux Llp",
        "aethernet_llc": "Aethernet Llc",
        "af_networks_b_v": "Af Networks B.v.",
        "affiniti_llc": "Affiniti Llc",
        "affinity_technology_solutions_llc": "Affinity Technology Solutions Llc",
        "afghan_wireless_communication_company": "Afghan Wireless Communication Company",
        "afone": "Afone",
        "afribone_mali_sa": "Afribone Mali Sa",
        "african_network_information_center": "African Network Information Center",
        "afrihost_pty_ltd": "Afrihost Pty Ltd",
        "ag_telecom_ltd": "Ag Telecom Ltd.",
        "agatangelo_telecom_e_informatica_ltda": "Agatangelo Telecom E Informatica Ltda",
        "agence_tunisienne_internet_ati": "Agence Tunisienne Internet - Ati",
        "agencia_estadual_de_tecnologia_da_informacao_ati": "Agencia Estadual De Tecnologia Da Informacao - Ati",
        "agilent_technologies": "Agilent Technologies",
        "agily_telecom": "Agily Telecom",
        "agis": "Agis",
        "ago_telecom_sl": "Ago Telecom Sl",
        "agreste_telecomunicacoes_eirelime": "Agreste Telecomunicacoes Eireli-me",
        "agrestenet_com_e_serv_ltda_me": "Agrestenet Com E Serv Ltda - Me",
        "agrivalley_services_inc": "Agri-valley Services Inc.",
        "aguas_del_colorado_sapem": "Aguas Del Colorado Sapem",
        "ai_networks_limited": "Ai Networks Limited",
        "ainet_telekommunikationsnetzwerk_betriebs_gmbh": "Ainet Telekommunikations-netzwerk Betriebs Gmbh",
        "air_advantage_llc": "Air Advantage Llc",
        "air_connect_internet_services_pvt_ltd": "Air Connect Internet Services Pvt Ltd",
        "air_force_systems_networking": "Air Force Systems Networking",
        "air_link_rural_broadband_llc": "Air Link Rural Broadband Llc",
        "air_speed_internet": "Air Speed Internet",
        "airband_communications_inc": "Airband Communications Inc",
        "aircel_ltd": "Aircel Ltd.",
        "airconect_comunicacoes_ltdame": "Airconect_comunicacoes Ltda-me",
        "airebullas_cb": "Airebullas Cb",
        "airgenie_communications_private_limited": "Airgenie Communications Private Limited",
        "airit_services_ag": "Airit Services Ag",
        "airnet_cable_and_datacom_pvt_ltd": "Airnet Cable And Datacom Pvt Ltd",
        "airnet_wireless_inc": "Airnet Wireless Inc.",
        "airstream_communications_llc": "Airstream Communications Llc",
        "airtel_burkina_faso": "Airtel Burkina Faso",
        "airtel_congo_s_a": "Airtel Congo S.a",
        "airtel_gabon_s_a": "Airtel Gabon S.a",
        "airtel_kenya": "Airtel Kenya",
        "airtel_madagascar": "Airtel Madagascar",
        "airtel_malawi": "Airtel Malawi",
        "airtel_networks_limited": "Airtel Networks Limited",
        "airtel_tanzania": "Airtel Tanzania",
        "airtel_uganda_limited": "Airtel Uganda Limited",
        "airwave_networks_incorporated": "Airwave Networks Incorporated",
        "airwaynet_a_s": "Airwaynet A.s.",
        "aitainet": "Aitai-net",
        "aju_net": "Aju - Net",
        "akamai_international_b_v": "Akamai International B.v.",
        "akamai_technologies_inc": "Akamai Technologies Inc.",
        "al_jawal": "Al Jawal",
        "al_zaytona_company_for_communication_ltd": "Al Zaytona Company For Communication Ltd.",
        "alabama_supercomputer_network": "Alabama Supercomputer Network",
        "alabanza_inc": "Alabanza Inc.",
        "alameda_county": "Alameda County",
        "alameda_county_office_of_education": "Alameda County Office Of Education",
        "alami_internet_services_ltd": "Alami Internet Services Ltd",
        "alaska_airline_inc": "Alaska Airline Inc.",
        "alaska_communications_systems_group_inc": "Alaska Communications Systems Group Inc.",
        "alaska_fiberstar": "Alaska Fiberstar",
        "albacete_sistemas_y_servicios_sl": "Albacete Sistemas Y Servicios Sl",
        "albanian_satellite_communications_sh_p_k": "Albanian Satellite Communications Sh.p.k.",
        "albany_schoharie_schenectady_boces": "Albany Schoharie Schenectady Boces",
        "alberta_health_services": "Alberta Health Services",
        "albertludwigsuniversitaet_freiburg": "Albert-ludwigs-universitaet Freiburg",
        "alberto_oscar_genovese": "Alberto Oscar Genovese",
        "albertsons_inc": "Albertson's Inc.",
        "albright_college": "Albright College",
        "albtelecom_sh_a": "Albtelecom Sh.a.",
        "alcatellucent": "Alcatel-lucent",
        "alentus_corporation": "Alentus Corporation",
        "alestra_s_de_r_l_de_c_v": "Alestra S. De R.l. De C.v.",
        "alfa_telecom_s_r_o": "Alfa Telecom S.r.o.",
        "alfatel_plus_ltd": "Alfatel Plus Ltd",
        "alfred_university": "Alfred University",
        "algar_telecom_sa": "Algar Telecom Sa",
        "algerie_telecom": "Algerie Telecom",
        "alibaba_china_technology_co_ltd": "Alibaba China Technology Co. Ltd.",
        "alibaba_com_llc": "Alibaba.com Llc",
        "alida_srl": "Alida Srl",
        "aligia_gmbh": "Aligia Gmbh",
        "alimam_muhammad_ibn_saud_islamic_university": "Al-imam Muhammad Ibn Saud Islamic University",
        "aliyun_computing_co_ltd": "Aliyun Computing Co. Ltd",
        "aljeel_aljadeed_for_technology": "Aljeel Aljadeed For Technology",
        "all_points_broadband": "All Points Broadband",
        "all_west_communications_inc": "All West Communications Inc.",
        "all_your_base_are_belong_to_us": "All Your Base Are Belong To Us",
        "allband_communications_cooperative": "Allband Communications Cooperative",
        "allegiance_communications_llc": "Allegiance Communications Llc",
        "allens_communications": "Allens Communications",
        "allfiber_telecom_servicos_de_telecomunicacoes": "Allfiber Telecom Servicos De Telecomunicacoes",
        "alliance_broadband_services_pvt_ltd": "Alliance Broadband Services Pvt. Ltd.",
        "allied_telecom_group_llc": "Allied Telecom Group Llc",
        "allied_wireless_communications_corporation": "Allied Wireless Communications Corporation",
        "allina_health_system_inc": "Allina Health System Inc.",
        "allnet_broadband_network": "Allnet Broadband Network",
        "allo_communications_llc": "Allo Communications Llc",
        "allstate_insurance_company": "Allstate Insurance Company",
        "allstream_corp": "Allstream Corp.",
        "alltele_allmanna_svenska_telefonaktiebolaget": "Alltele Allmanna Svenska Telefonaktiebolaget",
        "alltele_foretag_sverige_ab": "Alltele Foretag Sverige Ab",
        "allyson_diniz_melo_me": "Allyson Diniz Melo - Me",
        "alma_college": "Alma College",
        "alma_telephone": "Alma Telephone",
        "almeida_moura_ltda": "Almeida Moura Ltda",
        "alpha_enterprises_limited_inc": "Alpha Enterprises Limited Inc.",
        "alpha_net_telecom_ltd": "Alpha Net Telecom Ltd",
        "alpha_tel_s_a": "Alpha Tel S.a.",
        "alphalink": "Alphalink",
        "alphanett_servicos_de_telecomunicacoes": "Alphanett Servicos De Telecomunicacoes",
        "alpheus_data_services_l_l_c": "Alpheus Data Services L.l.c.",
        "alpinedc_sa": "Alpinedc Sa",
        "alta_rede_corporate_network_telecom_ltda_epp": "Alta Rede Corporate Network Telecom Ltda - Epp",
        "alta_tecnologia_en_comunicacions_s_l": "Alta Tecnologia En Comunicacions S.l",
        "alternatyva_s_r_l": "Alternatyva S.r.l.",
        "alteva_inc": "Alteva Inc",
        "altibox_as": "Altibox As",
        "altice_dominicana_s_a": "Altice Dominicana S.a.",
        "altima_telecom": "Altima Telecom",
        "altius_broadband_llc": "Altius Broadband Llc",
        "altushost_b_v": "Altushost B.v.",
        "alvernia_university": "Alvernia University",
        "alwayson_limited": "Alwayson Limited",
        "alyrica_networks_inc": "Alyrica Networks Inc.",
        "ama_communications_llc": "Ama Communications Llc",
        "amalgamated_holdings_limited": "Amalgamated Holdings Limited",
        "amanah_tech_inc": "Amanah Tech Inc.",
        "amarillo_wireless": "Amarillo Wireless",
        "amateur_radio_digital_communications": "Amateur Radio Digital Communications",
        "amazon_com_inc": "Amazon.com Inc.",
        "amazon_technologies_inc": "Amazon Technologies Inc.",
        "amazonet_servicos_de_informatica_ltda_me": "Amazonet Servicos De Informatica Ltda - Me",
        "amazonia_publicidade_ltda": "Amazonia Publicidade Ltda",
        "ambrogio_s_r_l": "Ambrogio S.r.l.",
        "ambrosio_de_araujo_barros_me": "Ambrosio De Araujo Barros - Me",
        "america_internet_communications": "America Internet Communications",
        "american_express_company": "American Express Company",
        "american_express_travel_related_services_company_inc": "American Express Travel Related Services Company Inc",
        "american_fiber_inc": "American Fiber Inc.",
        "american_university": "American University",
        "americanet_ltda": "America-net Ltda.",
        "americatel_peru_s_a": "Americatel Peru S.a.",
        "amnet_us": "Amnet Us",
        "ampernet_telecomunicacoes_ltda": "Ampernet Telecomunicacoes Ltda",
        "amplex_electric_inc": "Amplex Electric Inc.",
        "amwireless_uruguay_sa": "Amwireless Uruguay Sa",
        "anadarko_petroleum_corporation": "Anadarko Petroleum Corporation",
        "analog_devices_inc": "Analog Devices Inc.",
        "anderson_gustavo_neves_gomes_me": "Anderson Gustavo Neves Gomes - Me",
        "andrew_corp": "Andrew Corp.",
        "andrews_arnold_ltd": "Andrews Arnold Ltd",
        "andrews_university": "Andrews University",
        "andries_cia_ltda": "Andries Cia Ltda",
        "anexia_internetdienstleistungs_gmbh": "Anexia Internetdienstleistungs Gmbh",
        "angel_drops_ltd": "Angel Drops Ltd.",
        "angelo_state_university": "Angelo State University",
        "anheuserbusch_companies_inc": "Anheuser-busch Companies Inc.",
        "ani_network_pvt_ltd": "Ani Network Pvt Ltd",
        "aniruddha_skyline_web_service": "Aniruddha Skyline Web Service",
        "anjani_broadband_solutions_pvt_ltd": "Anjani Broadband Solutions Pvt.ltd.",
        "ankhnet_informations_pvt_ltd": "Ankhnet Informations Pvt. Ltd.",
        "ankit_wifi_solution_private_limited": "Ankit Wi-fi Solution Private Limited",
        "ans_communications_inc": "Ans Communications Inc",
        "antelecom_inc": "Antelecom Inc.",
        "anthelio_healthcare_solutions_inc": "Anthelio Healthcare Solutions Inc",
        "anticlockwise_pty_ltd": "Anticlockwise Pty Ltd",
        "antietam_broadband": "Antietam Broadband",
        "antonio_j_de_albuquerque_me": "Antonio J De Albuquerque Me",
        "aol_inc": "Aol Inc.",
        "aol_transit_data_network": "Aol Transit Data Network",
        "aonet_telecom_eireli_me": "Aonet Telecom Eireli - Me",
        "aoyama_gakuin_university": "Aoyama Gakuin University",
        "apfutura_internacional_soluciones_sl": "Apfutura Internacional Soluciones Sl",
        "api_digital_communications_group_llc": "Api Digital Communications Group Llc",
        "apnatelelink_pvt_ltd": "Apnatelelink Pvt. Ltd.",
        "apogee_telecom_inc": "Apogee Telecom Inc.",
        "apollo_online_services_pvt_ltd": "Apollo Online Services Pvt Ltd",
        "appalachian_state_university": "Appalachian State University",
        "apple_inc": "Apple Inc.",
        "appnexus_inc": "Appnexus Inc",
        "appserv_limited": "Appserv Limited",
        "apua": "Apua",
        "aq_networks_limited": "Aq Networks Limited",
        "aqua_ray_sas": "Aqua Ray Sas",
        "arabian_internet_communications_services_co_ltd": "Arabian Internet Communications Services Co.ltd",
        "aramco_as_number": "Aramco As Number",
        "araujosat_comercio_de_antenas_ltda_me": "Araujosat Comercio De Antenas Ltda Me",
        "arbuckle_communications_llc": "Arbuckle Communications Llc",
        "arcade_solutions_ag": "Arcade Solutions Ag",
        "architect_of_the_capitol": "Architect Of The Capitol",
        "arconic_inc": "Arconic Inc.",
        "arctic_slope_telephone_association_cooperative_inc": "Arctic Slope Telephone Association Cooperative Inc.",
        "ardmore_telephone_company_inc": "Ardmore Telephone Company Inc.",
        "areti_internet_ltd": "Areti Internet Ltd.",
        "argent_communications_llc": "Argent Communications Llc",
        "argon_technologies_inc": "Argon Technologies Inc.",
        "arin_as19419": "Arin As19419",
        "arinc_inc": "Arinc Inc.",
        "aristotle_net_inc": "Aristotle.net Inc.",
        "arizona_state_university": "Arizona State University",
        "arjun_telecom_pvt_ltd": "Arjun Telecom Pvt. Ltd.",
        "arkansas_colleges_of_health_education": "Arkansas Colleges Of Health Education",
        "arkansas_research_and_education_optical_network": "Arkansas Research And Education Optical Network",
        "arkansas_state_university": "Arkansas State University",
        "arkansas_tech_university": "Arkansas Tech University",
        "arkhangelsk_television_company": "Arkhangelsk Television Company",
        "arkwest_communications_inc": "Arkwest Communications Inc",
        "arlington_county_government": "Arlington County Government",
        "arlink_s_a": "Arlink S.a.",
        "armor_connectic_sas": "Armor Connectic Sas",
        "armstrong_cable_services": "Armstrong Cable Services",
        "aronete_de_carvalho_silva_me": "Aronete De Carvalho Silva - Me",
        "arp_telecom_comunicacoes_ltda_me": "Arp Telecom Comunicacoes Ltda Me",
        "arrobanet_bandalarga": "Arrobanet Bandalarga",
        "arrowhead_as": "Arrowhead As",
        "arrownet_inc": "Arrownet Inc.",
        "arsys_internet_s_l": "Arsys Internet S.l.",
        "artec_telecomunicaciones_limitada": "Artec Telecomunicaciones Limitada",
        "artelco": "Artelco",
        "arteria_networks_corporation": "Arteria Networks Corporation",
        "aruba_business_s_r_l": "Aruba Business S.r.l.",
        "aruba_s_p_a": "Aruba S.p.a.",
        "arvig_enterprises_inc": "Arvig Enterprises Inc.",
        "as_for_datahotel_which_is_one_of_idc_in_japan": "As For Datahotel Which Is One Of Idc In Japan",
        "as_number_for_interdomain_routing": "As Number For Interdomain Routing",
        "as_number_for_zolotaya_linia_ltd": "As Number For Zolotaya Linia Ltd.",
        "as_number_of_in2cable_com_india_ltd": "As Number Of In2cable.com India Ltd.",
        "asahi_net": "Asahi Net",
        "asahi_net_inc": "Asahi Net Inc.",
        "asarta_llc": "Asarta Llc",
        "ashland_fiber_network": "Ashland Fiber Network",
        "ashland_university": "Ashland University",
        "asia_pacific_network_information_centre": "Asia Pacific Network Information Centre",
        "asia_pacific_online_service_inc": "Asia Pacific On-line Service Inc.",
        "asia_pacific_online_services_inc": "Asia Pacific On-line Services Inc.",
        "asia_pacific_operations": "Asia Pacific Operations",
        "asiacell_communications_llc": "Asiacell Communications Llc",
        "asiana_airlines": "Asiana Airlines",
        "asianet": "Asianet",
        "asiti_limited_liability_company": "A-siti Limited Liability Company",
        "ask4_limited": "Ask4 Limited",
        "asn_ntt_singapore_pte_ltd": "Asn - Ntt Singapore Pte Ltd",
        "aspire_technology_solutions_ltd": "Aspire Technology Solutions Ltd",
        "aspwifi_s_l": "Aspwifi S.l.",
        "associacao_rede_nacional_de_ensino_e_pesquisa": "Associacao Rede Nacional De Ensino E Pesquisa",
        "astsystems_ltd": "Ast-systems Ltd.",
        "astute_hosting_inc": "Astute Hosting Inc.",
        "asurion_insurance_services_inc": "Asurion Insurance Services Inc.",
        "atc_communications": "Atc Communications",
        "atendnet_com_e_serv_de_equip_informatica_ltda": "Atendnet Com. E Serv. De Equip. Informatica Ltda",
        "ativa_network": "Ativa Network",
        "atjeu_publishing_llc": "Atjeu Publishing Llc",
        "atlantech_online_inc": "Atlantech Online Inc.",
        "atlantic_broadband_finance_llc": "Atlantic Broadband Finance Llc",
        "atlantic_metro_communications_llc": "Atlantic Metro Communications Llc",
        "atlantic_net_inc": "Atlantic.net Inc.",
        "atlantic_telephone_membership_corp": "Atlantic Telephone Membership Corp.",
        "atlantique_telecom_cote_divoire": "Atlantique Telecom Cote D'ivoire",
        "atlantique_telecom_niger": "Atlantique Telecom - Niger",
        "atlantique_telecom_togo": "Atlantique Telecom Togo",
        "atlas_networks_corporation": "Atlas Networks Corporation",
        "atlas_telecom_ltd": "Atlas Telecom Ltd.",
        "atlink_services_llc": "Atlink Services Llc",
        "atos_it_solutions_and_services_inc": "Atos It Solutions And Services Inc",
        "atos_nederland_b_v": "Atos Nederland B.v.",
        "atplus_telecom": "Atplus Telecom",
        "atria_convergence_technologies_pvt_ltd": "Atria Convergence Technologies Pvt. Ltd.",
        "atria_convergence_technologies_pvt_ltd_broadband_internet_service_provider_india": "Atria Convergence Technologies Pvt. Ltd. Broadband Internet Service Provider India",
        "att_comunicaciones_digitales_s_de_rl_de_cv": "Att Comunicaciones Digitales S De Rl De Cv",
        "att_corp": "Att Corp.",
        "att_data_communications_services": "Att Data Communications Services",
        "att_global_network_services_llc": "Att Global Network Services Llc",
        "att_global_network_services_nederland_b_v": "Att Global Network Services Nederland B.v.",
        "att_internet_services": "Att Internet Services",
        "att_japan_kk": "Att Japan Kk",
        "att_mobility_llc": "Att Mobility Llc",
        "att_services_inc": "Att Services Inc.",
        "atualnet_provedor_de_internet_ltda": "Atualnet Provedor De Internet Ltda",
        "atx_telecommunications_services": "Atx Telecommunications Services",
        "auburn_essential_services": "Auburn Essential Services",
        "auburn_university": "Auburn University",
        "auckland_university_of_technology": "Auckland University Of Technology",
        "augustana_college": "Augustana College",
        "aura_fiber_oe_as": "Aura Fiber Oe As",
        "aureon_network_services": "Aureon Network Services",
        "aurora_health_care": "Aurora Health Care",
        "austevoll_kraftlag_ba": "Austevoll Kraftlag Ba",
        "austin_college": "Austin College",
        "austin_independent_school_district": "Austin Independent School District",
        "austin_peay_state_university": "Austin Peay State University",
        "australian_academic_and_reasearch_network_aarnet": "Australian Academic And Reasearch Network Aarnet",
        "australian_academic_and_research_network": "Australian Academic And Research Network",
        "australian_private_networks_pty_ltd": "Australian Private Networks Pty Ltd",
        "automatic_data_processing_inc": "Automatic Data Processing Inc.",
        "autonomous_as_of_miramo": "Autonomous As Of Miramo",
        "autonomous_system_of_big_telecom_jsc": "Autonomous System Of Big Telecom Jsc",
        "avago_technologies_u_s_inc": "Avago Technologies U.s. Inc.",
        "avantel_close_joint_stock_company": "Avantel Close Joint Stock Company",
        "avantel_sas": "Avantel Sas",
        "avast_software_s_r_o": "Avast Software S.r.o.",
        "avato_tecnologia": "Avato Tecnologia",
        "avea_iletisim_hizmetleri_a_s": "Avea Iletisim Hizmetleri A.s.",
        "avelacom_ltd": "Avelacom Ltd.",
        "aventice_llc": "Aventice Llc",
        "avkcomputer_ltd": "Avk-computer Ltd",
        "awanti_ltd": "Awanti Ltd.",
        "awareness_software_limited": "Awareness Software Limited",
        "awaser_oman_llc": "Awaser Oman Llc",
        "awi_networks": "Awi Networks",
        "axartel_comunicaciones_s_l": "Axartel Comunicaciones S.l.",
        "axia_connect_limited": "Axia Connect Limited",
        "axiata_bangladesh": "Axiata Bangladesh",
        "axiom_technologies_llc": "Axiom Technologies Llc",
        "axtel_s_a_b_de_c_v": "Axtel S.a.b. De C.v.",
        "ayera_technologies_inc": "Ayera Technologies Inc.",
        "azci_net_llc": "Azci.net Llc",
        "azerbaijan_telecomunication_isp": "Azerbaijan Telecomunication Isp",
        "azeronline_information_services": "Azeronline Information Services",
        "azimutr_ltd": "Azimut-r Ltd.",
        "azusa_pacific_university": "Azusa Pacific University",
        "b2_bredband_ab": "B2 Bredband Ab",
        "b2_net_solutions_inc": "B2 Net Solutions Inc.",
        "b2b2c_inc": "B2b2c Inc",
        "b2x_online_inc": "B2x Online Inc",
        "b_b_bell_spa": "B.b.bell Spa",
        "b_communications_ltd": "B Communications Ltd",
        "b_smart_net_s_a_r_l": "B_smart.net S.a.r.l",
        "babilont": "Babilon-t",
        "babson_college": "Babson College",
        "backbone_connect_limited": "Backbone Connect Limited",
        "badger_internet_inc": "Badger Internet Inc.",
        "bahialink_technology_ltda": "Bahialink - Technology Ltda",
        "bahianet_ltda": "Bahianet Ltda.",
        "bahnhof_internet_ab": "Bahnhof Internet Ab",
        "baikal_teleport_cjsc": "Baikal Teleport Cjsc",
        "bakcell_llc": "Bakcell Llc",
        "baker_college": "Baker College",
        "ball_state_university": "Ball State University",
        "ballard_rural_telephone_cooperative_corporation_inc": "Ballard Rural Telephone Cooperative Corporation Inc.",
        "balsamwest_fibernet_llc": "Balsamwest Fibernet Llc",
        "banco_bilbao_vizcaya_argentaria_s_a": "Banco Bilbao Vizcaya Argentaria S.a.",
        "bandcon": "Bandcon",
        "bangalinkgsm_ltd": "Bangalinkgsm Ltd.",
        "bangalore_broadband_network_pvt_ltd": "Bangalore Broadband Network Pvt Ltd",
        "banglalink_gsm": "Banglalink Gsm",
        "bank_of_america": "Bank Of America",
        "banner_health": "Banner Health",
        "baptist_health": "Baptist Health",
        "baptist_health_care_corporation": "Baptist Health Care Corporation",
        "baptist_health_system": "Baptist Health System",
        "baraga_telephone_company": "Baraga Telephone Company",
        "barbourville_utility_commission": "Barbourville Utility Commission",
        "bard_college_at_simons_rock": "Bard College At Simon's Rock",
        "barracuda_networks_inc": "Barracuda Networks Inc.",
        "base_company_nvsa": "Base Company Nvsa",
        "basf_corporation": "Basf Corporation",
        "basin_networking": "Basin Networking",
        "batelco": "Batelco",
        "bates_college": "Bates College",
        "bausch_lomb": "Bausch Lomb",
        "bay_country_communications_inc": "Bay Country Communications Inc.",
        "bayan_telecommunications_inc": "Bayan Telecommunications Inc.",
        "bayan_telecommunications_incorporated": "Bayan Telecommunications Incorporated",
        "bayanat_aloula_for_network_services": "Bayanat Al-oula For Network Services",
        "bayer_business_services_gmbh": "Bayer Business Services Gmbh",
        "bayer_corporation": "Bayer Corporation",
        "baylor_health_care_systems": "Baylor Health Care Systems",
        "baylor_university": "Baylor University",
        "bba_aviation_usa_inc": "Bba Aviation Usa Inc.",
        "bbanda_srl": "Bbanda Srl",
        "bbg_telecom": "Bbg Telecom",
        "bbn_communications": "Bbn Communications",
        "bci_mississippi_broadband_llc": "Bci Mississippi Broadband Llc",
        "bci_telecommunication_advanced_technology_company": "Bci Telecommunication Advanced Technology Company",
        "bcnet": "Bcnet",
        "beam_telecom_pvt_ltd": "Beam Telecom Pvt Ltd",
        "beam_telecom_pvt_ltd_hyderabad": "Beam Telecom Pvt Ltd Hyderabad",
        "beamspeed_llc": "Beamspeed Llc",
        "beanfield_technologies_inc": "Beanfield Technologies Inc.",
        "beaver_creek_telephone_company": "Beaver Creek Telephone Company",
        "bec_fiber": "Bec Fiber",
        "bee_line_cable": "Bee Line Cable",
        "beehive_telephone_company_inc": "Beehive Telephone Company Inc.",
        "beggs_telephone_company_inc": "Beggs Telephone Company Inc.",
        "beijing_baidu_netcom_science_and_technology_co_ltd": "Beijing Baidu Netcom Science And Technology Co. Ltd.",
        "beijing_cnisp_technology_co_ltd": "Beijing Cnisp Technology Co. Ltd.",
        "beijing_guoxin_bilin_telecom_technology_co_ltd": "Beijing Guoxin Bilin Telecom Technology Co. Ltd",
        "beijing_kuancom_network_technology_co_ltd": "Beijing Kuancom Network Technology Co. Ltd.",
        "beijing_kuandaitong_telecom_technology_co_ltd": "Beijing Kuandaitong Telecom Technology Co. Ltd",
        "beijing_teletron_telecom_engineering_co_ltd": "Beijing Teletron Telecom Engineering Co. Ltd.",
        "beijing_weishichuangjie_technical_development_co_ltd": "Beijing Weishichuangjie Technical Development Co. Ltd",
        "beijing_xirang_media_cultural_co_ltd": "Beijing Xirang Media Cultural Co. Ltd.",
        "beirel_telecom_llc": "Beirel Telecom Llc",
        "bek_communications_cooperative": "Bek Communications Cooperative",
        "bekkoame_internet_inc": "Bekkoame Internet Inc.",
        "bel_air_internet_llc": "Bel Air Internet Llc",
        "belize_telemedia_limited": "Belize Telemedia Limited",
        "bell_canada": "Bell Canada",
        "bell_teleservices_india_pvt_ltd": "Bell Teleservices India Pvt Ltd",
        "bellsouth_net_inc": "Bellsouth.net Inc.",
        "belwave_communications": "Belwave Communications",
        "belzoni_cable_llc": "Belzoni Cable Llc",
        "bemobie_ltd": "Bemobie Ltd",
        "ben_lomand_rural_telephone_cooperative_inc": "Ben Lomand Rural Telephone Cooperative Inc.",
        "benton_rea_powernet": "Benton Rea Powernet",
        "berklee_college_of_music": "Berklee College Of Music",
        "berks_county_intermediate_unit": "Berks County Intermediate Unit",
        "berry_college": "Berry College",
        "bertram_communications_llc": "Bertram Communications Llc",
        "beta_systems": "Beta Systems",
        "bethany_lutheran_college": "Bethany Lutheran College",
        "bethel_university": "Bethel University",
        "bevcomm": "Bevcomm",
        "bezeq_internationalltd": "Bezeq International-ltd",
        "bharat_sanchar_nigam_limited": "Bharat Sanchar Nigam Limited",
        "bharti_airtel_ghana_limited": "Bharti Airtel Ghana Limited",
        "bharti_airtel_lanka_pvt_limited": "Bharti Airtel Lanka Pvt. Limited",
        "bharti_airtel_limited": "Bharti Airtel Limited",
        "bharti_broadband_networks_limited": "Bharti Broadband Networks Limited",
        "bharti_infotel_ltd": "Bharti Infotel Ltd.",
        "bhomika": "Bhomika",
        "biddeford_internet_corp": "Biddeford Internet Corp",
        "big_bend_telephone_inc": "Big Bend Telephone Inc.",
        "big_river_telephone": "Big River Telephone",
        "big_sandy_broadband_inc": "Big Sandy Broadband Inc",
        "big_tlc_isp": "Big Tlc Isp",
        "bigleaf_networks_inc": "Bigleaf Networks Inc.",
        "biglobe_inc": "Biglobe Inc.",
        "binariang_berhad": "Binariang Berhad",
        "binary_net_llc": "Binary Net Llc",
        "bindnet_rj": "Bindnet Rj",
        "binghamton_university": "Binghamton University",
        "biola_university_inc": "Biola University Inc.",
        "birch_telecom_inc": "Birch Telecom Inc.",
        "birmingham_grid_for_learning": "Birmingham Grid For Learning",
        "bishagni_networks_private_limited": "Bishagni Networks Private Limited",
        "bital_telecom": "Bital Telecom",
        "bitcom_provedor_de_servicos_de_internet_ltda": "Bitcom Provedor De Servicos De Internet Ltda",
        "bitred_group_spa": "Bitred Group Spa",
        "biz_net_technologies": "Biz Net Technologies",
        "biznet_isp": "Biznet Isp",
        "bj_net_provedor_de_internet_ltda_me": "Bj Net Provedor De Internet Ltda. - Me",
        "bjc_health_system": "Bjc Health System",
        "black_mesa_wireless_llc": "Black Mesa Wireless Llc.",
        "blackberry_limited": "Blackberry Limited",
        "blackfoot_telephone_cooperative_inc": "Blackfoot Telephone Cooperative Inc.",
        "blast_communications_inc": "Blast Communications Inc.",
        "blazenet_pvt_ltd": "Blazenet Pvt Ltd",
        "blazenets_network": "Blazenet's Network",
        "bledsoe_telephone_cooperative_corporation": "Bledsoe Telephone Cooperative Corporation",
        "blinn_college": "Blinn College",
        "block_line_systems_llc": "Block Line Systems Llc",
        "bloomberg_financial_market": "Bloomberg Financial Market",
        "bloomingdale_communications_inc": "Bloomingdale Communications Inc.",
        "bloomsburg_university": "Bloomsburg University",
        "blossom_telephone_company": "Blossom Telephone Company",
        "blue_chip_customer_engineering_ltd": "Blue Chip Customer Engineering Ltd",
        "blue_lotus_support_services_pvt_ltd": "Blue Lotus Support Services Pvt Ltd",
        "blue_ridge_websoft_llc": "Blue Ridge Websoft Llc",
        "blue_sky_communications": "Blue Sky Communications",
        "blue_valley_telecommunications_inc": "Blue Valley Tele-communications Inc.",
        "bluebird_network": "Bluebird Network",
        "bluebird_wireless_broadband_services_l_l_c": "Bluebird Wireless Broadband Services L.l.c.",
        "bluegrass_cellular_inc": "Bluegrass Cellular Inc.",
        "bluegrass_network_llc": "Bluegrass Network Llc",
        "blueprint_america_inc": "Blueprint America Inc.",
        "blueriver_networking_services_inc": "Blueriver Networking Services Inc.",
        "bluezone_internet_inc": "Bluezone Internet Inc",
        "bmjnet_telecom": "Bmjnet Telecom",
        "boat_ltd": "Boat Ltd.",
        "bob_jones_university": "Bob Jones University",
        "boces_southern_westchester_lower_hudson_regional_information_center": "Boces Southern Westchester Lower Hudson Regional Information Center",
        "boise_state_university": "Boise State University",
        "bolignetaarhus": "Bolignet-aarhus",
        "bolt_fiber_optic_services": "Bolt Fiber Optic Services",
        "booz_allen_hamilton_inc": "Booz Allen Hamilton Inc.",
        "boris_ltd": "Boris Ltd.",
        "bose_corporation": "Bose Corporation",
        "boston_college": "Boston College",
        "boston_public_library": "Boston Public Library",
        "boston_university": "Boston University",
        "botik_technologies_ltd": "Botik Technologies Ltd.",
        "botswana_telecommunications_corporation": "Botswana Telecommunications Corporation",
        "bouygues_telecom_s_a": "Bouygues Telecom S.a.",
        "bowling_green_state_university": "Bowling Green State University",
        "boycom_cablevision": "Boycom Cablevision",
        "bp_america_inc": "Bp America Inc.",
        "bps_networks": "Bps Networks",
        "br_simples_multimidia_ltda": "Br Simples Multimidia Ltda",
        "bradley_university": "Bradley University",
        "brainbox_s_r_l": "Brainbox S.r.l.",
        "braintree_electric_light_dept": "Braintree Electric Light Dept.",
        "brandeis_university": "Brandeis University",
        "brandenburg_telephone_company": "Brandenburg Telephone Company",
        "brantley_telephone_company_inc": "Brantley Telephone Company Inc.",
        "brascom_solucoes_e_tecnologia_ltda_me": "Brascom Solucoes E Tecnologia Ltda Me",
        "brasil_net_empreendimentos_ltda_me": "Brasil Net Empreendimentos Ltda - Me",
        "brasil_starlink_telecomunicacoes_ltda_epp": "Brasil Starlink Telecomunicacoes Ltda - Epp",
        "brasil_telecom_sa_filial_distrito_federal": "Brasil Telecom Sa - Filial Distrito Federal",
        "brasilink_telecomunicacoes_eireli_me": "Brasilink Telecomunicacoes Eireli - Me",
        "brasilip_telecomunicacoes_ltda_me": "Brasil-ip Telecomunicacoes Ltda - Me",
        "brasilnet_telecomunicacoes_do_parana_ltda": "Brasilnet Telecomunicacoes Do Parana Ltda",
        "brasrede_telecomunicacoes_ltda": "Brasrede Telecomunicacoes Ltda",
        "brava_telecomunicacoes_brasilia_ltda": "Brava Telecomunicacoes Brasilia Ltda.",
        "brava_telecomunicacoes_rondonopolis_ltda_me": "Brava Telecomunicacoes Rondonopolis Ltda - Me",
        "braveway_llc": "Braveway Llc",
        "bravo_telecom": "Bravo Telecom",
        "brazoria_inet": "Brazoria Inet",
        "brazos_internet": "Brazos Internet",
        "brazos_wifi": "Brazos Wifi",
        "bredband2_ab": "Bredband2 Ab",
        "breiband_no_as": "Breiband.no As",
        "brennercom_s_p_a": "Brennercom S.p.a.",
        "bresco_broadband": "Bresco Broadband",
        "bretagne_telecom_sas": "Bretagne Telecom Sas",
        "bridge_fibre_limited": "Bridge Fibre Limited",
        "bridgestone_americas_inc": "Bridgestone Americas Inc.",
        "bridgewater_college": "Bridgewater College",
        "bridgewater_state_college": "Bridgewater State College",
        "brigham_young_university": "Brigham Young University",
        "brigham_young_university_hawaii": "Brigham Young University Hawaii",
        "brigham_young_university_idaho": "Brigham Young University - Idaho",
        "bright_house_networks_llc": "Bright House Networks Llc",
        "bright_net_of_indiana": "Bright Net Of Indiana",
        "brightnet_oklahoma": "Brightnet Oklahoma",
        "brip_multimidia_ltda": "Brip Multimidia Ltda",
        "brisanet_servicos_de_telecomunicacoes_ltda": "Brisanet Servicos De Telecomunicacoes Ltda",
        "bristol_tennessee_essential_services": "Bristol Tennessee Essential Services",
        "bristol_virginia_utilities": "Bristol Virginia Utilities",
        "britis_telecom_ltda": "Britis Telecom Ltda",
        "british_airways_plc": "British Airways Plc",
        "british_columbia_institute_of_technology": "British Columbia Institute Of Technology",
        "british_sky_broadcasting_limited": "British Sky Broadcasting Limited",
        "british_telecom": "British Telecom",
        "british_telecommunications_plc": "British Telecommunications Plc",
        "brnet_informatica": "Brnet Informatica",
        "broadaspect": "Broadaspect",
        "broadband_for_the_rural_north_ltd": "Broadband For The Rural North Ltd",
        "broadband_pacenet_pvt_ltd": "Broadband Pacenet Pvt. Ltd",
        "broadband_service_provider": "Broadband Service Provider",
        "broadband_solutions_pty_ltd": "Broadband Solutions Pty Ltd",
        "broadbandone_inc": "Broadbandone Inc.",
        "broadmax": "Broadmax",
        "broadnet_as": "Broadnet As",
        "broadstripe": "Broadstripe",
        "broadview_networks_inc": "Broadview Networks Inc.",
        "brookhaven_national_laboratory": "Brookhaven National Laboratory",
        "broome_tioga_boces": "Broome - Tioga Boces",
        "broseghini_ltda_epp": "Broseghini Ltda Epp",
        "broward_county_public_schools": "Broward County Public Schools",
        "brown_university": "Brown University",
        "brsulnet_telecom_ltda": "Brsulnet Telecom Ltda",
        "bruce_telecom": "Bruce Telecom",
        "brutele_sc": "Brutele Sc",
        "bryant_university": "Bryant University",
        "bspeedy_wireless_inc": "Bspeedy Wireless Inc.",
        "bst_ltd": "Bst Ltd.",
        "bt_communications_ireland_limited": "Bt Communications Ireland Limited",
        "bt_espana_compania_de_servicios_globales_de_telecomunicaciones_sa": "Bt Espana Compania De Servicios Globales De Telecomunicaciones Sa",
        "bt_italia_s_p_a": "Bt Italia S.p.a.",
        "bt_public_internet_service": "Bt Public Internet Service",
        "btc_broadband_inc": "Btc Broadband Inc.",
        "buckeye_cablevision_inc": "Buckeye Cablevision Inc.",
        "buckland_telephone_company": "Buckland Telephone Company",
        "bucknell_university": "Bucknell University",
        "bucks_county_intermediate_unit_#22": "Bucks County Intermediate Unit #22",
        "bug_tussel_wireless": "Bug Tussel Wireless",
        "buggs_island_telephone_cooperative": "Buggs Island Telephone Cooperative",
        "bulgarian_telecommunications_company_plc": "Bulgarian Telecommunications Company Plc.",
        "bulloch_county_rural_telephone_cooperative_inc": "Bulloch County Rural Telephone Cooperative Inc.",
        "bureau_of_indian_affairs_office_of_indianeducation": "Bureau Of Indian Affairs Office Of Indianeducation",
        "bureau_of_land_management": "Bureau Of Land Management",
        "burlington_telecom": "Burlington Telecom",
        "busan_education_research_information_center": "Busan Education Research Information Center",
        "business_only_broadband": "Business Only Broadband",
        "businesssvyaz_ltd": "Business-svyaz Ltd",
        "bwtelcom": "Bwtelcom",
        "byteplex_ltd": "Byteplex Ltd.",
        "c_c_s_leeds_ltd": "C.c.s. Leeds Ltd",
        "c_s_tavaresme": "C.s Tavares-me",
        "c_spire_fiber": "C Spire Fiber",
        "ca_inc": "Ca Inc.",
        "cable_and_wireless_jamaica": "Cable And Wireless Jamaica",
        "cable_and_wireless_worldwide_plc": "Cable And Wireless Worldwide Plc",
        "cable_axion_digitel_inc": "Cable Axion Digitel Inc.",
        "cable_bahamas": "Cable Bahamas",
        "cable_com_telecomunicacoes_ltda": "Cable.com Telecomunicacoes Ltda",
        "cable_coop": "Cable Co-op",
        "cable_isp_in_india": "Cable Isp In India",
        "cable_onda": "Cable Onda",
        "cable_one_inc": "Cable One Inc.",
        "cable_services_inc": "Cable Services Inc.",
        "cable_tv_corporation": "Cable Tv Corporation",
        "cable_tv_elpos_ltd": "Cable Tv Elpos Ltd.",
        "cable_wireless_access_limited": "Cable Wireless Access Limited",
        "cable_wireless_antigua_and_barbuda_ltd": "Cable Wireless Antigua And Barbuda Ltd",
        "cable_wireless_panama": "Cable Wireless Panama",
        "cableamerica_corporation": "Cableamerica Corporation",
        "cablecolor_s_a": "Cablecolor S.a.",
        "cablecom_networking_limited": "Cablecom Networking Limited",
        "cablelink_holdings_corp_transit_as_internet_service_provider_philippines": "Cablelink Holdings Corp. Transit As Internet Service Provider Philippines",
        "cablelynx": "Cablelynx",
        "cablemas_telecomunicaciones_sa_de_cv": "Cablemas Telecomunicaciones Sa De Cv",
        "cablemel_s_l": "Cablemel S.l.",
        "cablemurcia_slu": "Cablemurcia Slu",
        "cablesouth_media_iii_llc": "Cablesouth Media Iii Llc",
        "cablesur_comunicaciones_s_a": "Cablesur Comunicaciones S.a.",
        "cablevision_of_marion_county_llc": "Cablevision Of Marion County Llc",
        "cablevision_red_s_a_de_c_v": "Cablevision Red S.a De C.v.",
        "cablevision_s_a_de_c_v": "Cablevision S.a. De C.v.",
        "cablevision_systems_corp": "Cablevision Systems Corp.",
        "cableworld_sl": "Cableworld Sl",
        "cabo_servicos_de_telecomunicacoes_ltda": "Cabo Servicos De Telecomunicacoes Ltda",
        "cabonnet_telecomunicacoes_ltda": "Cabonnet Telecomunicacoes Ltda",
        "cabral_net_telecomunicacao_ltda_me": "Cabral Net Telecomunicacao Ltda - Me",
        "cactus_ltd": "Cactus Ltd.",
        "cadence_design_systems_inc": "Cadence Design Systems Inc.",
        "caezar_provedor_de_internet_ltda": "Caezar Provedor De Internet Ltda",
        "caioba_fibra_comunicacoes_ltda": "Caioba Fibra Comunicacoes Ltda",
        "caisse_federale_de_credit_mutuel_sa": "Caisse Federale De Credit Mutuel Sa",
        "caiw_diensten_b_v": "Caiw Diensten B.v.",
        "cal_net_inc": "Cal.net Inc.",
        "cal_state_fullerton": "Cal State Fullerton",
        "calaveras_internet_company": "Calaveras Internet Company",
        "caldsl": "Caldsl",
        "calhoun_isd": "Calhoun Isd",
        "california_broadband_services": "California Broadband Services",
        "california_institute_of_technology": "California Institute Of Technology",
        "california_state_polytechnic_university_pomona": "California State Polytechnic University - Pomona",
        "california_state_university": "California State University",
        "california_state_university_office_of_the_chancellor": "California State University Office Of The Chancellor",
        "california_state_university_sacramento": "California State University Sacramento",
        "california_technology_agency": "California Technology Agency",
        "california_telecom": "California Telecom",
        "call_flow_solutions_ltd": "Call Flow Solutions Ltd",
        "call_one_inc": "Call One Inc.",
        "callnet_telecom": "Callnet Telecom",
        "callplus_services_limited": "Callplus Services Limited",
        "calore_telephone_co": "Cal-ore Telephone Co.",
        "calvin_college": "Calvin College",
        "camgsm": "Camgsm",
        "campina_digital_s_l": "Campina Digital S.l",
        "camtel": "Camtel",
        "canal_2000_la_solana_s_l": "Canal 2000 La Solana S. L.",
        "canal_don_benito_s_l": "Canal Don Benito S.l.",
        "canal_saturne_s_a": "Canal Saturne S.a.",
        "canby_telephone_association": "Canby Telephone Association",
        "cancer_treatment_centers_of_america_inc": "Cancer Treatment Centers Of America Inc.",
        "candor_infosolution_pvt_ltd": "Candor Infosolution Pvt Ltd",
        "cantor_fitzgerald_co": "Cantor Fitzgerald Co.",
        "cantv_servicios_venezuela": "Cantv Servicios Venezuela",
        "capital_one_financial_corporation": "Capital One Financial Corporation",
        "caprock_as": "Caprock As",
        "capture_network_systems_pvt_ltd": "Capture Network Systems Pvt Ltd",
        "carangola_telecomunicacoes_ltdaepp": "Carangola Telecomunicacoes Ltda-epp",
        "carbon_lehigh_intermediate_unit_21": "Carbon Lehigh Intermediate Unit 21",
        "caresource_management_group_co": "Caresource Management Group Co.",
        "caribbean_internet_service": "Caribbean Internet Service",
        "carleton_college": "Carleton College",
        "carleton_university": "Carleton University",
        "carlos_a_de_l_santos_filho_me": "Carlos A De L Santos Filho Me",
        "carnegie_mellon_university": "Carnegie Mellon University",
        "carnival_corporation": "Carnival Corporation",
        "carolina_internet_ltd": "Carolina Internet Ltd.",
        "carolina_west_wireless": "Carolina West Wireless",
        "carolinas_healthcare_system": "Carolinas Healthcare System",
        "carpathia_hosting": "Carpathia Hosting",
        "carpenter_technology_corporation": "Carpenter Technology Corporation",
        "carrier1_bv": "Carrier1 Bv",
        "carrierenabler_s_l": "Carrierenabler S.l.",
        "carrolltonfarmers_branch_independent_school_dist": "Carrollton-farmers Branch Independent School Dist.",
        "carrytel": "Carrytel",
        "carson_communications_llc": "Carson Communications Llc",
        "carthage_college": "Carthage College",
        "caruaru_online_ltda": "Caruaru_online Ltda",
        "casavision_s_a": "Casavision S.a.",
        "cascade_access_llc": "Cascade Access Llc",
        "cascadelink_inc": "Cascadelink Inc",
        "case_western_reserve_university": "Case Western Reserve University",
        "cass_cable_tv_inc": "Cass Cable Tv Inc.",
        "cat_telecom_public_company_ltd": "Cat Telecom Public Company Ltd",
        "cat_telecom_public_company_ltd_cat": "Cat Telecom Public Company Ltd Cat",
        "catawba_college": "Catawba College",
        "caterpillar_inc": "Caterpillar Inc.",
        "catholic_network_australia_limited_cevn_victoria": "Catholic Network Australia Limited Cevn Victoria",
        "cbc": "Cbc",
        "cbeyond_communications_llc": "Cbeyond Communications Llc",
        "cbs_corporation": "Cbs Corporation",
        "cc_communications": "Cc Communications",
        "ccnst_christof_englmeier_e_k": "Ccnst Christof Englmeier E.k.",
        "ccs": "Ccs",
        "cct_llc": "Cct Llc",
        "cdlan_s_r_l": "Cdlan S.r.l.",
        "cdtelematika_a_s": "Cd-telematika A.s.",
        "ceara_networks_telecomunicacoes_ltda_me": "Ceara Networks Telecomunicacoes Ltda - Me",
        "cebridge_connections": "Cebridge Connections",
        "cedar_networks": "Cedar Networks",
        "cedarssinai_health_systems": "Cedars-sinai Health Systems",
        "cedarville_university": "Cedarville University",
        "cednet_provedor_internet": "Cednet Provedor Internet",
        "celanese_international_corporation": "Celanese International Corporation",
        "celcom_internet_service_provider": "Celcom Internet Service Provider",
        "celcom_timur_sabah_sdn_bhd": "Celcom Timur Sabah Sdn Bhd",
        "celito_communications_inc": "Celito Communications Inc.",
        "cell_c_ltd": "Cell C Ltd",
        "cellcom": "Cellcom",
        "celltex_networks_llc": "Celltex Networks Llc",
        "celtel_drc": "Celtel Drc",
        "cencom_inc": "Cencom Inc",
        "centenary_college": "Centenary College",
        "centennial_cayman_corp_chile_s_a": "Centennial Cayman Corp Chile S.a",
        "centennial_college_of_applied_arts_and_technology": "Centennial College Of Applied Arts And Technology",
        "center_for_educational_leadership_and_technology": "Center For Educational Leadership And Technology",
        "centra_health_inc": "Centra Health Inc.",
        "centracomm_communications": "Centracomm Communications",
        "central_arizona_college": "Central Arizona College",
        "central_community_college": "Central Community College",
        "central_michigan_university": "Central Michigan University",
        "central_piedmont_community_college": "Central Piedmont Community College",
        "central_telegraph_public_jointstock_company": "Central Telegraph Public Joint-stock Company",
        "central_utah_telephone_inc": "Central Utah Telephone Inc.",
        "centralnet_telecomunicacoes_ltda_me": "Centralnet Telecomunicacoes Ltda - Me",
        "centre_de_recherche_informatique_de_montreal": "Centre De Recherche Informatique De Montreal",
        "centre_de_telecomuncaciones_i_tecnologies_de_la_informacias_de_la_generalitat_de_catalunya": "Centre De Telecomuncaciones I Tecnologies De La Informacias De La Generalitat De Catalunya",
        "centre_telecos_i_ti_generalitat_de_catalunya": "Centre Telecos I Ti Generalitat De Catalunya",
        "centrilogic_inc": "Centrilogic Inc.",
        "centro_educacional_nossa_senhora_auxiliadora": "Centro Educacional Nossa Senhora Auxiliadora",
        "centro_informatico_cientifico_de_andalucia": "Centro Informatico Cientifico De Andalucia",
        "centurylink_communications_uk_limited": "Centurylink Communications Uk Limited",
        "centurytel_internet_holdings_inc": "Centurytel Internet Holdings Inc.",
        "centurytel_solutions_llc": "Centurytel Solutions Llc",
        "cerberus_networks_ltd": "Cerberus Networks Ltd",
        "cerfnet": "Cerfnet",
        "cern_european_organization_for_nuclear_research": "Cern - European Organization For Nuclear Research",
        "cerner_corporation": "Cerner Corporation",
        "ceska_telekomunikacni_infrastruktura_a_s": "Ceska Telekomunikacni Infrastruktura A.s.",
        "cesnet_z_s_p_o": "Cesnet Z.s.p.o.",
        "cetel_gmbh": "Cetel Gmbh",
        "cetera_financial_group_inc": "Cetera Financial Group Inc.",
        "ch3_data_llc": "Ch3 Data Llc",
        "ch_sistemas_videla_s_r_l": "Ch Sistemas Videla S.r.l.",
        "champaign_telephone_company_inc": "Champaign Telephone Company Inc.",
        "chandranet_pvt_ltd": "Chandranet Pvt.ltd.",
        "chapman_university": "Chapman University",
        "chariton_valley_telephone_corporation": "Chariton Valley Telephone Corporation",
        "charles_river_laboratories_inc": "Charles River Laboratories Inc.",
        "charles_sturt_university": "Charles Sturt University",
        "charlotte_colocation_center_llc": "Charlotte Colocation Center Llc",
        "charter_communications": "Charter Communications",
        "chawich_group_ltd": "Chawich Group Ltd",
        "checs": "Checs",
        "cheongju_cable_tv_systems": "Cheongju Cable Tv Systems",
        "cherry_creek_school_district_#5": "Cherry Creek School District #5",
        "cherryland_services_inc": "Cherryland Services Inc",
        "chester_county_intermediate_unit": "Chester County Intermediate Unit",
        "chevron_corporation": "Chevron Corporation",
        "chickamauga_telephone_corporation": "Chickamauga Telephone Corporation",
        "childrens_hospital": "Childrens Hospital",
        "childrens_hospital_colorado": "Children's Hospital Colorado",
        "childrens_hospital_medical_center_of_akron": "Children's Hospital Medical Center Of Akron",
        "china_cable_television_network_co_ltd": "China Cable Television Network Co. Ltd",
        "china_education_and_research_network": "China Education And Research Network",
        "china_egovnet_information_center": "China Egovnet Information Center",
        "china_great_wall_internet_server_provider": "China Great Wall Internet Server Provider",
        "china_internet_information_center": "China Internet Information Center",
        "china_mobile_communications_corporation": "China Mobile Communications Corporation",
        "china_mobile_peoples_telephone_company_limited": "China Mobile Peoples Telephone Company Limited",
        "china_telecom_group": "China Telecom Group",
        "china_tietong_telecommunications_corporation": "China Tietong Telecommunications Corporation",
        "china_unicom_anhui_province_network": "China Unicom Anhui Province Network",
        "china_unicom_beijing_province_network": "China Unicom Beijing Province Network",
        "china_unicom_chongqing_province_network": "China Unicom Chongqing Province Network",
        "china_unicom_fujian_province_network": "China Unicom Fujian Province Network",
        "china_unicom_guangdong_province_network": "China Unicom Guangdong Province Network",
        "china_unicom_guangxi_province_network": "China Unicom Guangxi Province Network",
        "china_unicom_guangzhou_network": "China Unicom Guangzhou Network",
        "china_unicom_hainan_province_network": "China Unicom Hainan Province Network",
        "china_unicom_hebei_province_network": "China Unicom Hebei Province Network",
        "china_unicom_heibei_province_network": "China Unicom Heibei Province Network",
        "china_unicom_heilongjiang_province_network": "China Unicom Heilongjiang Province Network",
        "china_unicom_henan_province_network": "China Unicom Henan Province Network",
        "china_unicom_hubei_province_network": "China Unicom Hubei Province Network",
        "china_unicom_hunan_province_network": "China Unicom Hunan Province Network",
        "china_unicom_ip_network": "China Unicom Ip Network",
        "china_unicom_jiangsu_province_network": "China Unicom Jiangsu Province Network",
        "china_unicom_jilin_province_network": "China Unicom Jilin Province Network",
        "china_unicom_liaoning_province_network": "China Unicom Liaoning Province Network",
        "china_unicom_neimenggu_province_network": "China Unicom Neimenggu Province Network",
        "china_unicom_shaanxi_province_network": "China Unicom Shaanxi Province Network",
        "china_unicom_shandong_province_network": "China Unicom Shandong Province Network",
        "china_unicom_shanghai_city_network": "China Unicom Shanghai City Network",
        "china_unicom_shanghai_network": "China Unicom Shanghai Network",
        "china_unicom_shanxi_province_network": "China Unicom Shanxi Province Network",
        "china_unicom_sichuan_province_network": "China Unicom Sichuan Province Network",
        "china_unicom_tianjin_province_network": "China Unicom Tianjin Province Network",
        "china_unicom_xinjiang_province_network": "China Unicom Xinjiang Province Network",
        "china_unicom_yunnan_province_network": "China Unicom Yunnan Province Network",
        "china_unicom_zhejiang_province_network": "China Unicom Zhejiang Province Network",
        "chinanet_anhui_province_network": "Chinanet Anhui Province Network",
        "chinanet_backbone_network": "Chinanet Backbone Network",
        "chinanet_chongqing_province_network": "Chinanet Chongqing Province Network",
        "chinanet_fujian_province_network": "Chinanet Fujian Province Network",
        "chinanet_gansu_province_network": "Chinanet Gansu Province Network",
        "chinanet_guangdong_province_network": "Chinanet Guangdong Province Network",
        "chinanet_guangxi_province_network": "Chinanet Guangxi Province Network",
        "chinanet_guizhou_province_network": "Chinanet Guizhou Province Network",
        "chinanet_hainan_province_network": "Chinanet Hainan Province Network",
        "chinanet_hebei_province_network": "Chinanet Hebei Province Network",
        "chinanet_heilongjiang_province_network": "Chinanet Heilongjiang Province Network",
        "chinanet_henan_province_network": "Chinanet Henan Province Network",
        "chinanet_hubei_province_network": "Chinanet Hubei Province Network",
        "chinanet_hunan_province_network": "Chinanet Hunan Province Network",
        "chinanet_jiangsu_province_network": "Chinanet Jiangsu Province Network",
        "chinanet_jiangxi_province_network": "Chinanet Jiangxi Province Network",
        "chinanet_jilin_province_network": "Chinanet Jilin Province Network",
        "chinanet_liaoning_province_network": "Chinanet Liaoning Province Network",
        "chinanet_neimenggu_province_network": "Chinanet Neimenggu Province Network",
        "chinanet_ningxia_province_network": "Chinanet Ningxia Province Network",
        "chinanet_shaanxi_province_network": "Chinanet Shaanxi Province Network",
        "chinanet_shandong_province_network": "Chinanet Shandong Province Network",
        "chinanet_shanghai_province_network": "Chinanet Shanghai Province Network",
        "chinanet_sichuan_province_network": "Chinanet Sichuan Province Network",
        "chinanet_tianjin_province_network": "Chinanet Tianjin Province Network",
        "chinanet_xinjiang_province_network": "Chinanet Xinjiang Province Network",
        "chinanet_xizang_province_network": "Chinanet Xizang Province Network",
        "chinanet_yunnan_province_network": "Chinanet Yunnan Province Network",
        "chinanet_zhejiang_province_network": "Chinanet Zhejiang Province Network",
        "chinaunicom_lasa_city_3g_pool": "Chinaunicom Lasa City 3g Pool",
        "choate_rosemary_hall": "Choate Rosemary Hall",
        "chojnet_maciej_szypryt": "Chojnet Maciej Szypryt",
        "choopa_llc": "Choopa Llc",
        "christchurch_polytechnic_institute": "Christchurch Polytechnic Institute",
        "christopher_newport_university": "Christopher Newport University",
        "christus_health": "Christus Health",
        "cht_com_twchunghwa_telecom_data_communication_business_group": "Cht.com.twchunghwa Telecom Data Communication Business Group",
        "chtd_chunghwa_telecom_co_ltd": "Chtd Chunghwa Telecom Co. Ltd.",
        "chubu_telecommunications_co_inc": "Chubu Telecommunications Co. Inc.",
        "chubu_telecommunications_company_inc": "Chubu Telecommunications Company Inc.",
        "chubu_university": "Chubu University",
        "chukai_television_co_ltd": "Chukai Television Co .ltd.",
        "chuncheon_national_university_of_education": "Chuncheon National University Of Education",
        "chungnam_national_university": "Chungnam National University",
        "chunkychips_net_limited": "Chunkychips.net Limited",
        "chupicom_fureai_inc": "Chupicom Fureai Inc.",
        "cik_telecom_inc": "Cik Telecom Inc",
        "cilnet_comunicacao_e_informatica_ltda": "Cilnet Comunicacao E Informatica Ltda.",
        "cincinnati_bell_telephone_company_llc": "Cincinnati Bell Telephone Company Llc",
        "cinergy_communications": "Cinergy Communications",
        "cinte_telecom_comercio_e_servicos_ltda": "Cinte Telecom Comercio E Servicos Ltda.",
        "cipherkey_exchange_corp": "Cipherkey Exchange Corp.",
        "ciputranet": "Ciputranet",
        "cirbn_llc": "Cirbn Llc",
        "cirrus_communications_pty_ltd": "Cirrus Communications Pty Ltd",
        "cisco_systems_inc": "Cisco Systems Inc.",
        "cisp": "Cisp",
        "citadelgroup": "Citadelgroup",
        "citic_telecom_international_cpc_limited": "Citic Telecom International Cpc Limited",
        "citicorp": "Citicorp",
        "citigroup": "Citigroup",
        "citizens_internet_service": "Citizens Internet Service",
        "citizens_telephone_cooperative": "Citizens Telephone Cooperative",
        "citta_studi_s_p_a": "Citta' Studi S.p.a.",
        "city_and_county_of_denver": "City And County Of Denver",
        "city_of_baltimore_mayors_office_of_information_technology": "City Of Baltimore Mayor's Office Of Information Technology",
        "city_of_bardstown_kentucky": "City Of Bardstown Kentucky",
        "city_of_boston": "City Of Boston",
        "city_of_burbank": "City Of Burbank",
        "city_of_charlotte": "City Of Charlotte",
        "city_of_jacksonville_florida": "City Of Jacksonville Florida",
        "city_of_lagrange_georgia": "City Of Lagrange Georgia",
        "city_of_lebanon_lebanon_utilities": "City Of Lebanon - Lebanon Utilities",
        "city_of_los_angeles": "City Of Los Angeles",
        "city_of_monroe": "City Of Monroe",
        "city_of_mont_belvieu": "City Of Mont Belvieu",
        "city_of_montgomery": "City Of Montgomery",
        "city_of_montreal": "City Of Montreal",
        "city_of_morganton": "City Of Morganton",
        "city_of_north_kansas_city_mo": "City Of North Kansas City Mo",
        "city_of_opelika_al": "City Of Opelika Al",
        "city_of_san_jose": "City Of San Jose",
        "city_of_sandy": "City Of Sandy",
        "city_of_tacoma": "City Of Tacoma",
        "city_of_thomasville_utilities": "City Of Thomasville Utilities",
        "city_of_vineland_board_of_education": "City Of Vineland Board Of Education",
        "city_of_westfield_gas_electric_light_department": "City Of Westfield Gas Electric Light Department",
        "city_of_williamstown": "City Of Williamstown",
        "city_of_windom": "City Of Windom",
        "city_of_winstonsalem": "City Of Winston-salem",
        "city_of_wyandotte": "City Of Wyandotte",
        "city_shop_net_ltda": "City Shop Net Ltda",
        "city_university_of_hong_kong": "City University Of Hong Kong",
        "city_university_of_new_york": "City University Of New York",
        "city_west_cable_telephone_corp": "City West Cable Telephone Corp.",
        "city_wide_communications_inc": "City Wide Communications Inc.",
        "citycable": "Citycable",
        "citycable_shunan_corporation": "City-cable Shunan Corporation",
        "citycom_networks_pvt_ltd": "Citycom Networks Pvt Ltd",
        "cityline_ltd": "Cityline Ltd",
        "citylink_fiber_holdings_inc": "Citylink Fiber Holdings Inc.",
        "citylink_ltd": "Citylink Ltd",
        "citynet": "Citynet",
        "cityonline_services_ltd": "Cityonline Services Ltd",
        "cityonline_services_pvt_ltd": "Cityonline Services Pvt Ltd",
        "cjcablenet": "Cj-cablenet",
        "cjhellovision": "Cj-hellovision",
        "cjsc_aist": "Cjsc Aist",
        "cjsc_enginet": "Cjsc Enginet",
        "cjsc_ertelecom_holding": "Cjsc Er-telecom Holding",
        "cjsc_futures_telecom": "Cjsc Futures Telecom",
        "cjsc_macomnet": "Cjsc Macomnet",
        "cjsc_mastertel": "Cjsc Mastertel",
        "cjsc_progressive_technologies": "Cjsc Progressive Technologies",
        "cjsc_radiosvyaz": "Cjsc Radiosvyaz",
        "cjsc_radiotelephone_as": "Cjsc Radiotelephone As",
        "cjsc_rascom": "Cjsc Rascom",
        "cjsc_severentelecom": "Cjsc Severen-telecom",
        "cjsc_ural_wes": "Cjsc Ural Wes",
        "cjsc_vainah_telecom": "Cjsc Vainah Telecom",
        "clackamas_education_service_district": "Clackamas Education Service District",
        "clara_luz_internet_ltda_me": "Clara Luz Internet Ltda Me",
        "claranet_ltd": "Claranet Ltd",
        "claremont_university_consortium": "Claremont University Consortium",
        "clarendon_house": "Clarendon House",
        "clarion_university_of_pennsylvania": "Clarion University Of Pennsylvania",
        "clarity_connect_inc": "Clarity Connect Inc",
        "clarity_telecom_llc": "Clarity Telecom Llc",
        "clark_county_nevada": "Clark County Nevada",
        "clark_county_school_district": "Clark County School District",
        "clarkson_university": "Clarkson University",
        "clarkstown_central_school_district": "Clarkstown Central School District",
        "clarksville_department_of_electricity": "Clarksville Department Of Electricity",
        "claro_argentina": "Claro Argentina",
        "claro_brazil": "Claro Brazil",
        "claro_chile_s_a": "Claro Chile S.a.",
        "claro_cr_telecomunicaciones_s_a": "Claro Cr Telecomunicaciones S.a.",
        "claro_dominican_republic": "Claro Dominican Republic",
        "claro_ecuador": "Claro Ecuador",
        "claro_el_salvador": "Claro El Salvador",
        "claro_guatemala": "Claro Guatemala",
        "claro_nicaragua": "Claro Nicaragua",
        "claro_panama_s_a": "Claro Panama S.a.",
        "claro_peru": "Claro Peru",
        "claro_s_a": "Claro S.a.",
        "classic_south_communications_l_l_c": "Classic South Communications L.l.c.",
        "classicnet_broadband_network": "Classicnet Broadband Network",
        "clayton_county_public_schools": "Clayton County Public Schools",
        "clear_creek_isd": "Clear Creek Isd",
        "clear_path_networks_inc": "Clear Path Networks Inc",
        "clear_rate_communications_inc": "Clear Rate Communications Inc.",
        "clear_wireless_llc": "Clear Wireless Llc",
        "clearnetworx": "Clearnetworx",
        "clearwave_communications": "Clearwave Communications",
        "clemson_university": "Clemson University",
        "clesat_comunicacao_e_manutencao_em_eletro_ldta_me": "Clesat Comunicacao E Manutencao Em Eletro.ldta Me",
        "cleveland_clinic_foundation": "Cleveland Clinic Foundation",
        "cleveland_state_university_computer_services": "Cleveland State University Computer Services",
        "clicfacil_computadores_servicos_e_telecomunicacoe": "Clicfacil Computadores Servicos E Telecomunicacoe",
        "click_broadband": "Click Broadband",
        "click_com_telecomunicacoes_ltdame": "Click.com Telecomunicacoes Ltda-me",
        "click_enter_ltda_me": "Click Enter Ltda - Me",
        "click_tecnologia_e_telecomunicacao_ltda": "Click Tecnologia E Telecomunicacao Ltda",
        "clicknet_brasil_informatica_e_telecomunicacoes_lt": "Clicknet Brasil Informatica E Telecomunicacoes Lt",
        "clio_s_p_a": "Clio S.p.a.",
        "close_joint_stock_company_transtelecom": "Close Joint Stock Company Transtelecom",
        "closed_joint_stock_company_kuzbassenergosviaz": "Closed Joint Stock Company Kuzbassenergosviaz",
        "closed_joint_stock_company_russian_company": "Closed Joint Stock Company Russian Company",
        "closed_jointstock_company_avantel": "Closed Joint-stock Company Avantel",
        "closed_jointstock_company_orienttelecom": "Closed Joint-stock Company Orient-telecom",
        "closed_jointstock_company_telecommunication_company_tel": "Closed Joint-stock Company Telecommunication Company Tel",
        "closeness_s_l": "Closeness S.l.",
        "cloud_innovation_ltd": "Cloud Innovation Ltd",
        "cloud_plus_pty_ltd": "Cloud Plus Pty Ltd",
        "cloud_sigma": "Cloud Sigma",
        "cloud_south": "Cloud South",
        "cloudflare_inc": "Cloudflare Inc.",
        "cloudie_limited": "Cloudie Limited",
        "cloudip_llc": "Cloudip Llc",
        "clouditalia_telecomunicazioni_s_p_a": "Clouditalia Telecomunicazioni S.p.a.",
        "cloudradium_l_l_c": "Cloudradium L.l.c",
        "cloudroute_llc": "Cloudroute Llc",
        "clouvider_limited": "Clouvider Limited",
        "clovis_unified_school_district": "Clovis Unified School District",
        "club_21": "Club 21",
        "cmb": "Cmb",
        "cmb_daejeon_broadcasting_co_ltd": "Cmb Daejeon Broadcasting Co .ltd",
        "cmb_kwnagju_easten_broadcasting": "Cmb Kwnagju Easten Broadcasting",
        "cmb_taegu_dongbu_broadcast": "Cmb Taegu Dongbu Broadcast",
        "cmet_saci": "Cmet Saci",
        "cmpak_limited": "Cmpak Limited",
        "cmsinter_net_llc": "Cmsinter.net Llc",
        "cnc_az_mmc": "Cnc.az Mmc",
        "cncgroup_china169_backbone": "Cncgroup China169 Backbone",
        "cnispunion_technology_beijing_co_ltd": "Cnisp-union Technology Beijing Co. Ltd",
        "cnsp": "Cnsp",
        "coast_communications_company": "Coast Communications Company",
        "coast_community_college_district": "Coast Community College District",
        "coastal_telco_services_inc": "Coastal Telco Services Inc.",
        "cobridge_communications_llc": "Cobridge Communications Llc",
        "codgrec_s_a": "Codgrec S.a.",
        "coditel": "Coditel",
        "cogeco_cable": "Cogeco Cable",
        "cogeco_communications_holdings_inc": "Cogeco Communications Holdings Inc.",
        "cogeco_peer_1_uk_ltd": "Cogeco Peer 1 Uk Ltd",
        "cogecodata": "Cogecodata",
        "cogent_communications": "Cogent Communications",
        "cogentco_comcogent_communications_group_inc": "Cogentco.comcogent Communications Group Inc.",
        "cogentpsi": "Cogentpsi",
        "colba_net_inc": "Colba Net Inc.",
        "colby_college": "Colby College",
        "cold_spring_harbor_laboratory": "Cold Spring Harbor Laboratory",
        "coldwater_board_of_public_utilities": "Coldwater Board Of Public Utilities",
        "colgate_university": "Colgate University",
        "college_of_dupage": "College Of Dupage",
        "college_of_mount_saint_vincent": "College Of Mount Saint Vincent",
        "college_of_st_elizabeth": "College Of St. Elizabeth",
        "college_of_st_scholastica": "College Of St. Scholastica",
        "college_of_william_and_mary": "College Of William And Mary",
        "college_of_wooster": "College Of Wooster",
        "colleges_of_the_fenway_inc": "Colleges Of The Fenway Inc.",
        "collier_county_public_schools": "Collier County Public Schools",
        "collin_county_community_college_district": "Collin County Community College District",
        "colocrossing": "Colocrossing",
        "cologlobal": "Cologlobal",
        "colombia_movil_s_a": "Colombia Movil S.a.",
        "colorado_central_telecom": "Colorado Central Telecom",
        "colorado_college": "Colorado College",
        "colorado_internet_cooperative_association": "Colorado Internet Cooperative Association",
        "colorado_state_university": "Colorado State University",
        "colorado_telehealth_network": "Colorado Telehealth Network",
        "colorado_valley_communications_inc": "Colorado Valley Communications Inc.",
        "colostore_com": "Colostore.com",
        "coloup": "Coloup",
        "colsecor_cooperativa_limitada": "Colsecor Cooperativa Limitada",
        "colt_technology_services_group_limited": "Colt Technology Services Group Limited",
        "columbia_college": "Columbia College",
        "columbia_power_and_water_systems": "Columbia Power And Water Systems",
        "columbia_sussex_corporation": "Columbia Sussex Corporation",
        "columbia_university": "Columbia University",
        "columbus_communications_trinidad_limited": "Columbus Communications Trinidad Limited.",
        "columbus_networks_de_colombia_limitada": "Columbus Networks De Colombia Limitada",
        "columbus_networks_usa_inc": "Columbus Networks Usa Inc.",
        "columbus_public_schools": "Columbus Public Schools",
        "com_hem_ab": "Com Hem Ab",
        "com_net_inc": "Com Net Inc.",
        "comcast_cable_communications_inc": "Comcast Cable Communications Inc.",
        "comcast_cable_communications_llc": "Comcast Cable Communications Llc",
        "comcast_ip_services_l_l_c": "Comcast Ip Services L.l.c.",
        "comcel_guatemala_s_a": "Comcel Guatemala S.a.",
        "comcel_s_a": "Comcel S.a.",
        "comcell_inc": "Comcell Inc.",
        "comclark_cable_internet": "Comclark Cable Internet",
        "comclark_network_technology_corp": "Comclark Network Technology Corp",
        "comercio_de_produtos_eletronicos_videosat_ltda": "Comercio De Produtos Eletronicos Videosat Ltda",
        "comeser_srl": "Comeser Srl",
        "comfortel_ltd": "Comfortel Ltd.",
        "comin_telekommunikations_gmbh": "Com-in Telekommunikations Gmbh",
        "comisso_dante_anibal": "Comisso Dante Anibal",
        "comlink_ltd": "Comlink Ltd",
        "commcorp_comunicacoes_ltda": "Commcorp Comunicacoes Ltda",
        "commission_scolaire_de_portneuf": "Commission Scolaire De Portneuf",
        "commission_scolaire_des_patriotes": "Commission Scolaire Des Patriotes",
        "commission_scolaire_du_cheminduroy": "Commission Scolaire Du Chemin-du-roy",
        "commnet_wireless_llc": "Commnet Wireless Llc",
        "commstream_communications_inc": "Commstream Communications Inc",
        "communication_and_international_trading_company_s_a_r_l": "Communication And International Trading Company S.a.r.l",
        "communication_construction_services_inc": "Communication Construction Services Inc.",
        "communications_design_acquisition_corporation": "Communications Design Acquisition Corporation",
        "community_antenna_service_inc": "Community Antenna Service Inc.",
        "community_cable_broadband_inc": "Community Cable Broadband Inc.",
        "community_college_of_allegheny_county": "Community College Of Allegheny County",
        "community_communications_company": "Community Communications Company",
        "community_fibre_limited": "Community Fibre Limited",
        "community_health_systems_professional_services_corporation": "Community Health Systems Professional Services Corporation",
        "community_hospitals_indianapolis": "Community Hospitals Indianapolis",
        "community_network_center_inc": "Community Network Center Inc.",
        "community_network_center_incorporated": "Community Network Center Incorporated.",
        "commzoom_llc": "Commzoom Llc",
        "comnet_bulgaria_holding_ltd": "Comnet Bulgaria Holding Ltd.",
        "como_comm_inc": "Co-mo Comm Inc",
        "companhia_de_telecomunicacoes_de_macau": "Companhia De Telecomunicacoes De Macau",
        "companhia_energetica_de_minas_gerais": "Companhia Energetica De Minas Gerais",
        "companhia_itabirana_telecomunicacoes_ltda": "Companhia Itabirana Telecomunicacoes Ltda",
        "company_relant_ltd": "Company Relant Ltd",
        "company_skala_llc": "Company Skala Llc",
        "compass_communications_ltd": "Compass Communications Ltd",
        "completel_ltd": "Completel Ltd",
        "completel_sas_france": "Completel Sas France",
        "comporium_inc": "Comporium Inc",
        "compudyne_inc": "Compudyne Inc.",
        "compunet_inc": "Compunet Inc.",
        "compuservice_empreendimentos_ltda": "Compuservice Empreendimentos Ltda",
        "computer_center": "Computer Center",
        "computer_centre": "Computer Centre",
        "computer_information_technology_ltd": "Computer Information Technology Ltd.",
        "computer_science": "Computer Science",
        "computer_sciences_corporation": "Computer Sciences Corporation",
        "computer_services_inc": "Computer Services Inc",
        "computer_techniques_inc": "Computer Techniques Inc.",
        "comsave_bv": "Comsave Bv",
        "comsite_ltd": "Com-site Ltd",
        "comsouth_teledata_inc": "Comsouth Teledata Inc.",
        "comspan_bandon_network_llc": "Comspan Bandon Network Llc",
        "comteco_ltda": "Comteco Ltda",
        "comteh_ru_ltd": "Comteh.ru Ltd.",
        "comunicaciones_enersol_s_l": "Comunicaciones Enersol S.l.",
        "comunicaciones_ronda_s_l": "Comunicaciones Ronda S.l.",
        "comvive_servidores_s_l": "Comvive Servidores S.l.",
        "comwave_telecom_inc": "Comwave Telecom Inc.",
        "comx_networks_as": "Comx Networks As",
        "conchalnet_telecomunicacoes_ltda_me": "Conchalnet Telecomunicacoes Ltda - Me",
        "concisa_comunicacion_marketing_y_servicios_s_l": "Concisa Comunicacion Marketing Y Servicios S.l.",
        "concorde_inc": "Concorde Inc.",
        "concordia_university_wisconsin": "Concordia University Wisconsin",
        "condenast_publications": "Condenast Publications",
        "condointernet_net": "Condointernet.net",
        "conduent_business_services_llc": "Conduent Business Services Llc",
        "conecel": "Conecel",
        "conect_provedor_de_acesso_a_internet_ltda_me": "Conect Provedor De Acesso A Internet Ltda Me",
        "conect_telecom": "Conect Telecom",
        "conect_telecomunicacoes_comunicacoes_e_multimidia": "Conect Telecomunicacoes Comunicacoes E Multimidia",
        "conect_telecomunicacoes_eireli_me": "Conect Telecomunicacoes Eireli - Me",
        "conecta_3_telecom_s_l": "Conecta 3 Telecom S.l.",
        "conecta_eletricidade_e_servicos_ltda": "Conecta Eletricidade E Servicos Ltda",
        "conecta_ltda": "Conecta Ltda.",
        "conecta_minas_telecom_ltda": "Conecta Minas Telecom Ltda",
        "conecta_net": "Conecta Net",
        "conecta_net_informatica_ltda": "Conecta Net Informatica Ltda",
        "conecta_provedor_de_internet": "Conecta Provedor De Internet",
        "conecta_tecnologia_ltda": "Conecta Tecnologia Ltda",
        "conecta_telecom_ltda": "Conecta Telecom Ltda",
        "conectja_telecomunicacoes_ltda_me": "Conectja Telecomunicacoes Ltda - Me",
        "conexao_telecom_e_internet_ltda": "Conexao - Telecom. E Internet Ltda",
        "conexao_vip_telecom": "Conexao Vip Telecom",
        "congo_telecom": "Congo Telecom",
        "connect_communications": "Connect Communications",
        "connect_llc": "Connect Llc",
        "connect_wireless_ltda": "Connect Wireless Ltda",
        "connecti_servicos_de_comunicacao_ltda": "Connecti Servicos De Comunicacao Ltda",
        "connecticut_education_network": "Connecticut Education Network",
        "connecticut_state_university_system": "Connecticut State University System",
        "connectto_communications_inc": "Connectto Communications Inc",
        "connew_informatica_e_telecominicaoes_ltdame": "Connew Informatica E Telecominicaoes Ltda-me",
        "connexio_ca": "Connexio.ca",
        "conopco_inc": "Conopco Inc.",
        "conquersoft_communication_pvt_ltd": "Conquersoft Communication Pvt. Ltd.",
        "conquest_telecomunicacoes_ltda": "Conquest Telecomunicacoes Ltda",
        "consolidated_communications_inc": "Consolidated Communications Inc.",
        "consolidated_smart_systems_llc": "Consolidated Smart Systems Llc",
        "consolidated_telcom": "Consolidated Telcom",
        "consolidated_telephone_co": "Consolidated Telephone Co",
        "consolidated_telephone_company": "Consolidated Telephone Company",
        "consorci_de_serveis_universitaris_de_catalunya": "Consorci De Serveis Universitaris De Catalunya",
        "consortium_garr": "Consortium Garr",
        "consumers_energy_company": "Consumers Energy Company",
        "contabo_gmbh": "Contabo Gmbh",
        "contact_tv_inc": "Contact Tv Inc.",
        "conterra": "Conterra",
        "contilnet_staff_computer_ltda_me": "Contilnet - Staff Computer Ltda - Me",
        "contina": "Contina",
        "continental_broadband_pennsylvania_inc": "Continental Broadband Pennsylvania Inc.",
        "contingency_networks_ltd": "Contingency Networks Ltd",
        "continuum": "Continuum",
        "contra_costa_county_office_of_education": "Contra Costa County Office Of Education",
        "convergence_group_limited": "Convergence Group Limited",
        "convergenze_s_p_a": "Convergenze S.p.a.",
        "conway_corporation": "Conway Corporation",
        "cook_group_inc": "Cook Group Inc",
        "coolhousing_autonomous_system": "Coolhousing Autonomous System",
        "cooolbox_ad": "Cooolbox Ad",
        "coop_de_gral_viamonte": "Coop De Gral Viamonte",
        "coop_limitada_de_consumo_de_electricidad_de_salto": "Coop. Limitada De Consumo De Electricidad De Salto",
        "coop_popular_de_elec_obras_y_servicios_pub_de_santa_rosa_ltda": "Coop. Popular De Elec. Obras Y Servicios Pub. De Santa Rosa Ltda",
        "cooperativa_de_electricidad_y_servicios_publicos_de_arroyito_ltda": "Cooperativa De Electricidad Y Servicios Publicos De Arroyito Ltda",
        "cooperativa_de_obras_serv_publ_y_sociales_de_hernando_ltda": "Cooperativa De Obras Serv. Publ. Y Sociales De Hernando Ltda.",
        "cooperativa_electrica_antonio_carboni_ltda": "Cooperativa Electrica Antonio Carboni Ltda",
        "cooperativa_electrica_de_colon_ba_ltda": "Cooperativa Electrica De Colon Ba Ltda",
        "cooperativa_electrica_de_saladillo": "Cooperativa Electrica De Saladillo",
        "cooperative_group_cws_limited": "Co-operative Group Cws Limited",
        "copel_telecomunicacoes_s_a": "Copel Telecomunicacoes S.a.",
        "coppernet_systems_inc": "Coppernet Systems Inc.",
        "coprel_telecom_ltda": "Coprel Telecom Ltda",
        "coprosys_a_s": "Coprosys A.s.",
        "cordova_telephone_cooperative_inc": "Cordova Telephone Cooperative Inc.",
        "core_telecom_it_ltda": "Core Telecom It Ltda",
        "corecomm_internet_services_inc": "Corecomm Internet Services Inc",
        "coriolis_telecom_sas": "Coriolis Telecom Sas",
        "cornell_university": "Cornell University",
        "cornerstone_communications_llc": "Cornerstone Communications Llc",
        "corning_incorporated": "Corning Incorporated",
        "corpico_ltda": "Corpico Ltda",
        "corporacio_megatel_s_l": "Corporacio Megatel S.l.",
        "corporacion_telemic_c_a": "Corporacion Telemic C.a.",
        "corridor_communications_inc": "Corridor Communications Inc.",
        "cortez_on_line_provedor_de_internet_ltda_me": "Cortez On Line Provedor De Internet Ltda - Me",
        "cosmonline_informatica_ltda": "Cosmonline Informatica Ltda",
        "cosmote_mobile_telecommunications_s_a": "Cosmote Mobile Telecommunications S.a.",
        "costa_esmeralda_net_ltda_me": "Costa Esmeralda Net Ltda Me",
        "costra_s_a": "Costra S.a.",
        "cotc_connections": "Cotc Connections",
        "cote_d_ivoire_telecom": "Cote D Ivoire Telecom",
        "cotesma": "Cotesma",
        "country_cablevision_inc": "Country Cablevision Inc.",
        "country_wireless": "Country Wireless",
        "county_of_chester": "County Of Chester",
        "county_of_los_angeles": "County Of Los Angeles",
        "county_of_morris_oit": "County Of Morris Oit",
        "county_of_orangeburg": "County Of Orangeburg",
        "county_of_riverside": "County Of Riverside",
        "county_of_sacramento": "County Of Sacramento",
        "courier_plus_ltd": "Courier Plus Ltd.",
        "covenant_health": "Covenant Health",
        "cox_communications_inc": "Cox Communications Inc.",
        "cp_internet": "Cp Internet",
        "cpgnet_solucoes_em_internet_e_rede_ltda_me": "Cpgnet Solucoes Em Internet E Rede Ltda - Me",
        "cpnet_com_e_serv_telecomunicacoes_ltda": "Cpnet Com. E Serv. Telecomunicacoes Ltda.",
        "cpnet_seu_provedor_banda_larga": "Cpnet Seu Provedor Banda Larga",
        "cptel": "Cp-tel",
        "cr_net_telecomunicacoes_ltdame": "Cr Net Telecomunicacoes Ltda-me",
        "crapac_servicos_de_telecomunicacoes_ltda_me": "Crapac Servicos De Telecomunicacoes Ltda - Me",
        "crawkan_telephone_cooperative_inc": "Craw-kan Telephone Cooperative Inc.",
        "creighton_university": "Creighton University",
        "creral_comercio_e_servicos_de_telecomunicacoes_ltd": "Creral Comercio E Servicos De Telecomunicacoes Ltd",
        "critical_path_inc": "Critical Path Inc.",
        "crocker_communications": "Crocker Communications",
        "cronomagic_canada_inc": "Cronomagic Canada Inc.",
        "cronyx_plus_ltd": "Cronyx Plus Ltd.",
        "crrstv": "Crrs-tv",
        "cruzio": "Cruzio",
        "crystal_automation_systems_inc": "Crystal Automation Systems Inc",
        "crystalbet_ltd": "Crystalbet Ltd",
        "cs_breitband_gmbh": "Cs Breitband Gmbh",
        "cs_loxinfo_public_company_limited": "Cs Loxinfo Public Company Limited",
        "cs_technologies": "Cs Technologies",
        "cs_wholesale_grocers_inc": "Cs Wholesale Grocers Inc.",
        "csc_tieteen_tietotekniikan_keskus_oy": "Csc - Tieteen Tietotekniikan Keskus Oy",
        "csg_systems_inc": "Csg Systems Inc.",
        "csi_piemonte": "Csi Piemonte",
        "csiro_it_services": "Csiro It Services",
        "csl_limited": "Csl Limited",
        "csra_llc": "Csra Llc",
        "css_integration_inc": "Css Integration Inc.",
        "ct_communications_network": "Ct Communications Network",
        "ctb_media_inc": "Ctb Media Inc.",
        "ctba_telecom": "Ctba Telecom",
        "ctc_corp_s_a_telefonica_empresas": "Ctc. Corp S.a. Telefonica Empresas",
        "ctc_transmisiones_regionales_s_a": "Ctc Transmisiones Regionales S.a.",
        "ctrls_datacenters_ltd": "Ctrls Datacenters Ltd.",
        "cts_communications_corp": "Cts Communications Corp",
        "cuba_city_telephone_exchange_company": "Cuba City Telephone Exchange Company",
        "culr_llc": "Culr Llc",
        "cumberland_technologies_international": "Cumberland Technologies International",
        "curtin_university": "Curtin University",
        "custodiandc_limited": "Custodiandc Limited",
        "cuyahoga_community_college": "Cuyahoga Community College",
        "cvin_llc": "Cvin Llc",
        "cwmc_telecom_ltda_me": "Cwmc Telecom Ltda Me",
        "cyber_cloud_shield_broadband_services_private_limited": "Cyber Cloud Shield Broadband Services Private Limited",
        "cyber_info_provedor_de_acesso_ltda_me": "Cyber Info Provedor De Acesso Ltda Me",
        "cyber_internet_services_pvt_ltd": "Cyber Internet Services Pvt Ltd.",
        "cyber_mesa_computer_systems_incorporated": "Cyber Mesa Computer Systems Incorporated",
        "cybera_inc": "Cybera Inc",
        "cyberlink_ag": "Cyberlink Ag",
        "cybernet1": "Cybernet1",
        "cybernet_provedor": "Cybernet Provedor",
        "cybernetrs_ltda_me": "Cybernetrs Ltda - Me",
        "cybersmart": "Cybersmart",
        "cygnet_internet_services_inc": "Cygnet Internet Services Inc",
        "cynex_communications_corporation": "Cynex Communications Corporation",
        "cypressfairbanks_isd": "Cypress-fairbanks Isd",
        "cyprus_telecommunications_authority": "Cyprus Telecommunications Authority",
        "cyrusone_llc": "Cyrusone Llc",
        "d_p_s_giroconsulting_s_l": "D.p.s. Giro-consulting S.l.",
        "dada_broadband_ltd": "Dada Broadband Ltd.",
        "daegu_university": "Daegu University",
        "dagupan_urban_satellite_vision_inc": "Dagupan Urban Satellite Vision Inc.",
        "daimler_ag": "Daimler Ag",
        "daisy_communications_ltd": "Daisy Communications Ltd",
        "dakota_carrier_network": "Dakota Carrier Network",
        "dakota_state_university": "Dakota State University",
        "dakotapro_biz": "Dakotapro.biz",
        "dalhousie_university": "Dalhousie University",
        "dallas_county_community_college_district": "Dallas County Community College District",
        "dallas_independent_school_district": "Dallas Independent School District",
        "dalton_utilities": "Dalton Utilities",
        "dana_limited": "Dana Limited",
        "dancer_llc": "Dancer Llc",
        "daou_technology": "Daou Technology",
        "dartmouth_college": "Dartmouth College",
        "data_communication_business_group": "Data Communication Business Group",
        "data_connect_pvt_ltd": "Data Connect Pvt.ltd.",
        "data_info_comercio_e_servico_ltda": "Data Info Comercio E Servico Ltda.",
        "data_telecom_service": "Data Telecom Service",
        "data_wing_limited": "Data Wing Limited",
        "database_by_design_llc": "Database By Design Llc",
        "datacamp_limited": "Datacamp Limited",
        "datacamp_s_r_o": "Datacamp S.r.o.",
        "dataforce_network_private_limited": "Dataforce Network Private Limited",
        "dataline_llc": "Data-line Llc",
        "datapark_ag": "Datapark Ag",
        "datapeer_s_r_o": "Datapeer S.r.o.",
        "datapipe_inc": "Datapipe Inc.",
        "datasafeit_solucoes_em_tecnologia": "Datasafeit Solucoes Em Tecnologia",
        "datashack_lc": "Datashack Lc",
        "datasix_rechenzentrumsbetriebs_gmbh": "Datasix Rechenzentrumsbetriebs Gmbh",
        "datawagon_llc": "Datawagon Llc",
        "dataweb_global_group_b_v": "Dataweb Global Group B.v.",
        "datayard": "Datayard",
        "dattatec_com": "Dattatec.com",
        "davis_community_network": "Davis Community Network",
        "davis_school_district": "Davis School District",
        "daystarr_communications": "Daystarr Communications",
        "db_systel_gmbh": "Db Systel Gmbh",
        "dcnet": "Dc-net",
        "dcs_internet": "Dcs Internet",
        "dct_telecom_group_inc": "Dct Telecom Group Inc.",
        "dctech_micro_services_inc": "Dctech Micro Services Inc.",
        "dctv_cable_network_broadband_services_inc": "Dctv Cable Network Broadband Services Inc",
        "de_la_sallecollege_of_saint_benilde": "De La Salle-college Of Saint Benilde",
        "deakin_university": "Deakin University",
        "debian_signal_comunicacao_multimidia_ltda_me": "Debian Signal Comunicacao Multimidia Ltda Me",
        "dechert_llp": "Dechert Llp",
        "dedfiberco": "Dedfiberco",
        "deere_company": "Deere Company",
        "deerfield_academy": "Deerfield Academy",
        "defaultroute_limited": "Defaultroute Limited",
        "defense_net_inc": "Defense.net Inc",
        "dehradun_enet_solutions_private_ltd": "Dehradun Enet Solutions Private Ltd",
        "deil_ltd": "Deil Ltd.",
        "delaware_county_community_college": "Delaware County Community College",
        "delaware_state_university": "Delaware State University",
        "delcom_inc": "Delcom Inc.",
        "deldsl_internet_pvt_ltd": "Deldsl Internet Pvt. Ltd.",
        "delgado_community_college": "Delgado Community College",
        "delta_air_lines": "Delta Air Lines",
        "delta_cable_communications_ltd": "Delta Cable Communications Ltd.",
        "delta_college": "Delta College",
        "delta_telecom_internet_anjos_informatica_ltda": "Delta Telecom Internet Anjos Informatica Ltda",
        "delta_telecom_ltd": "Delta Telecom Ltd.",
        "deltanet_ltd": "Delta-net Ltd.",
        "deluxe_corporation": "Deluxe Corporation",
        "den_danske_bank_as": "Den Danske Bank As",
        "den_digital_entertainment_pvt_ltd_as_isp_india": "Den Digital Entertainment Pvt. Ltd. As Isp India",
        "denver_public_schools": "Denver Public Schools",
        "department_of_administrative_services": "Department Of Administrative Services",
        "department_of_finance_western_australia": "Department Of Finance - Western Australia",
        "department_of_interior": "Department Of Interior",
        "department_of_science_and_technology": "Department Of Science And Technology",
        "depaul_university": "Depaul University",
        "dery_telecom_inc": "Dery Telecom Inc.",
        "desales_university": "Desales University",
        "desert_inet": "Desert Inet",
        "desert_winds_wireless_inc": "Desert Winds Wireless Inc",
        "desktop_sigmanet_comunicacao_multimidia_ltda": "Desktop Sigmanet Comunicacao Multimidia Ltda",
        "detroit_medical_center": "Detroit Medical Center",
        "deutsche_boerse_ag": "Deutsche Boerse Ag",
        "deutsche_telekom_ag": "Deutsche Telekom Ag",
        "development_authority_of_the_north_country": "Development Authority Of The North Country",
        "development_group_inc": "Development Group Inc",
        "dexterity_networks_limited": "Dexterity Networks Limited",
        "dfn_systems": "Dfn Systems",
        "dfw_datacenter": "Dfw Datacenter",
        "dhaka_fiber_net_ltd": "Dhaka Fiber Net Ltd",
        "dhivehi_raajjeyge_gulhun_private_limited": "Dhivehi Raajjeyge Gulhun Private Limited",
        "dhms_ltd": "Dhms Ltd",
        "dhosting_die_rackspace_connectivity_gmbh": "D-hosting Die Rackspace Connectivity Gmbh",
        "dialnet_de_colombia_s_a_e_s_p": "Dialnet De Colombia S.a. E.s.p.",
        "dialog_axiata_plc": "Dialog Axiata Plc.",
        "dialogix_telecom": "Dialogix Telecom",
        "dianet_ltd": "Dianet Ltd.",
        "dickey_rural_networks": "Dickey Rural Networks",
        "dicks_sporting_goods_inc": "Dick's Sporting Goods Inc",
        "diebold_incorporated": "Diebold Incorporated",
        "digi_desert_llc": "Digi Desert Llc",
        "digi_italy_s_r_l": "Digi Italy S.r.l.",
        "digi_spain_telecom_sl": "Digi Spain Telecom Sl",
        "digi_telecommunications_sdn_bhd": "Digi Telecommunications Sdn Bhd",
        "digi_win_infotainment_mumbai_pvt_ltd": "Digi Win Infotainment Mumbai Pvt Ltd",
        "digicel_afg": "Digicel Afg",
        "digicel_jamaica": "Digicel Jamaica",
        "digicel_pacific_ltd": "Digicel Pacific Ltd",
        "digicel_png_ltd": "Digicel Png Ltd",
        "digicel_trinidad_and_tobago_ltd": "Digicel Trinidad And Tobago Ltd.",
        "digicelgroup_el_salvador": "Digicelgroup El Salvador",
        "digicon_corporation": "Digicon Corporation",
        "digicube_sas": "Digicube Sas",
        "diginet": "Diginet",
        "digit_one_llc": "Digit One Llc",
        "digital_energy_technologies_chile_spa": "Digital Energy Technologies Chile Spa",
        "digital_landscape_inc": "Digital Landscape Inc.",
        "digital_net_internet_service_provider": "Digital Net Internet Service Provider",
        "digital_network_associates_private_limited": "Digital Network Associates Private Limited",
        "digital_network_associates_pvt_ltd": "Digital Network Associates Pvt Ltd",
        "digital_ocean_inc": "Digital Ocean Inc.",
        "digital_passage": "Digital Passage",
        "digital_path": "Digital Path",
        "digital_solutions_provider": "Digital Solutions Provider",
        "digital_telecommunication_services": "Digital Telecommunication Services",
        "digital_tv_cable_de_edmund_s_r_l": "Digital Tv Cable De Edmund S.r.l.",
        "digital_united_inc": "Digital United Inc.",
        "digitalfyre_internet_solutions_llc": "Digitalfyre Internet Solutions Llc.",
        "dignity_health": "Dignity Health",
        "dinamica_telecomunicacoes_ltda": "Dinamica Telecomunicacoes Ltda",
        "diode_cable_company": "Diode Cable Company",
        "diplomatic_telecommunications_services_program_office_dtspo": "Diplomatic Telecommunications Services - Program Office Dts-po",
        "direct_communications_cable_llc": "Direct Communications Cable Llc",
        "direct_communications_cedar_valley_llc": "Direct Communications Cedar Valley Llc",
        "direct_plus": "Direct Plus",
        "directspace_networks_llc": "Directspace Networks Llc.",
        "directv_colombia_ltda": "Directv Colombia Ltda.",
        "disney_worldwide_services_inc": "Disney Worldwide Services Inc.",
        "distributed_systems_services_inc": "Distributed Systems Services Inc.",
        "distributel_communications_ltd": "Distributel Communications Ltd.",
        "district_school_board_of_niagara": "District School Board Of Niagara",
        "dix_co_ltd": "Dix Co. Ltd.",
        "dlive": "Dlive",
        "dm_ventures_inc": "Dm Ventures Inc",
        "dmci_broadband_llc": "Dmci Broadband Llc.",
        "dmg_media_limited": "Dmg Media Limited",
        "dmr_communications_inc": "Dmr Communications Inc.",
        "dna_oy": "Dna Oy",
        "dnet_tecnologia": "Dnet Tecnologia",
        "dns_net_internet_service_gmbh": "Dns Net Internet Service Gmbh",
        "dns_telecomunicacoes_e_solucoes_em_informatica_eir": "Dns Telecomunicacoes E Solucoes Em Informatica Eir",
        "dobson_technologies_transport_and_telecom_solutions_llc": "Dobson Technologies - Transport And Telecom Solutions Llc",
        "dod_network_information_center": "Dod Network Information Center",
        "dogan_tv_digital_platform_isletmeciligi_a_s": "Dogan Tv Digital Platform Isletmeciligi A.s",
        "domain_names_registrar_reg_ru_ltd": "Domain Names Registrar Reg.ru Ltd",
        "domolan_ltd": "Domolan Ltd.",
        "donbass_electronic_communications_ltd": "Donbass Electronic Communications Ltd.",
        "donga_universirty": "Dong-a Universirty",
        "donna_live_s_coop": "Donna Live S.coop.",
        "door_county_broadband_llc": "Door County Broadband Llc",
        "double_dog_communications": "Double Dog Communications",
        "douglas_fastnet": "Douglas Fastnet",
        "doylestown_communications_inc": "Doylestown Communications Inc",
        "dp_communications_inc": "Dp Communications Inc.",
        "dqe_communications_network_services_llc": "Dqe Communications Network Services Llc",
        "dragonet_comunicaciones": "Dragonet Comunicaciones",
        "drake_university": "Drake University",
        "dream_net_ltd": "Dream Net Ltd",
        "dreamline_co": "Dreamline Co.",
        "drexel_university": "Drexel University",
        "drury_inns": "Drury Inns",
        "drustvo_za_telekomunikacije_mtel_doo": "Drustvo Za Telekomunikacije Mtel Doo",
        "dsci_corporation": "Dsci Corporation",
        "dsconnection_ltd": "Ds-connection Ltd",
        "dsl_extreme": "Dsl Extreme",
        "dtc_cable": "Dtc Cable",
        "dtc_communications_inc": "Dtc Communications Inc.",
        "dte_energy_company": "Dte Energy Company",
        "dtpnet_nap": "Dtpnet Nap",
        "duke_energy_corporation": "Duke Energy Corporation",
        "duke_university": "Duke University",
        "dun_bradstreet": "Dun Bradstreet",
        "duncan_telecommunications_llc": "Duncan Telecommunications Llc",
        "dunkirk_fredonia_telephone_company": "Dunkirk Fredonia Telephone Company",
        "duo_county_telecom": "Duo County Telecom",
        "duquesne_university": "Duquesne University",
        "duraes_e_clementino_tecnologia_em_redes_ltda": "Duraes E Clementino Tecnologia Em Redes Ltda.",
        "dutchess_county_boces": "Dutchess County Boces",
        "dvblab_communication_sl": "Dvblab Communication Sl",
        "dvois_broadband_private_limited": "D-vois Broadband Private Limited",
        "dvois_broadband_pvt_ltd": "D-vois Broadband Pvt Ltd",
        "dwanirinn": "Dwanirinn",
        "dxc_technology_danmark_as": "Dxc Technology Danmark As",
        "e4a_s_r_l": "E4a S.r.l.",
        "e_b_de_melo_informatica_e_consultoria_m": "E. B. De Melo Informatica E Consultoria -m",
        "e_crom_s_r_l": "E.crom S.r.l.",
        "e_d_servicos_de_comunicacoes_ltda": "E. D. Servicos De Comunicacoes Ltda",
        "e_discom_telekommunikation_gmbh": "E.discom Telekommunikation Gmbh",
        "e_serv_informatica_e_tecnologia_ltda": "E.serv Informatica E Tecnologia Ltda.",
        "e_silva_azedo_eireli_me": "E. Silva Azedo Eireli - Me",
        "eagle_communications_inc": "Eagle Communications Inc.",
        "eagle_telephone_system_inc": "Eagle Telephone System Inc",
        "eaglenet_alliance": "Eagle-net Alliance",
        "eaglenet_s_a_r_l": "Eaglenet S.a.r.l.",
        "earth_telecommunication_pvt_limited": "Earth Telecommunication Pvt Limited",
        "earth_telecommunication_pvt_ltd": "Earth Telecommunication Pvt Ltd.",
        "earthlink_inc": "Earthlink Inc.",
        "earthlink_ltd_communicationsinternet_services": "Earthlink Ltd. Communicationsinternet Services",
        "east_carolina_university": "East Carolina University",
        "east_kentucky_network_llc": "East Kentucky Network Llc.",
        "east_tennessee_state_university": "East Tennessee State University",
        "eastern_kentucky_university": "Eastern Kentucky University",
        "eastern_new_mexico_university": "Eastern New Mexico University",
        "eastern_oregon_net_inc": "Eastern Oregon Net Inc.",
        "eastern_oregon_telecom": "Eastern Oregon Telecom",
        "eastern_suffolk_boces": "Eastern Suffolk Boces",
        "eastern_telecom_philippines_inc": "Eastern Telecom Philippines Inc.",
        "eastern_washington_university": "Eastern Washington University",
        "eastex_telephone_cooperative_inc": "Eastex Telephone Cooperative Inc.",
        "eastland_internet": "Eastland Internet",
        "eastlink": "Eastlink",
        "eastman_chemical_company": "Eastman Chemical Company",
        "eastman_kodak_company": "Eastman Kodak Company",
        "easton_utilities_commission": "Easton Utilities Commission",
        "easy_net": "Easy Net",
        "eatel": "Eatel",
        "eaton_corporation": "Eaton Corporation",
        "ebay_inc": "Ebay Inc",
        "eber_dejani_pereira_de_albernaz_cia_ltda": "Eber Dejani Pereira De Albernaz Cia Ltda",
        "ebox": "Ebox",
        "ebretic_enginyeria_sl": "Ebretic Enginyeria Sl",
        "ebsco_industries_inc": "Ebsco Industries Inc.",
        "echo_broadband": "Echo Broadband",
        "eclipse_telecom": "Eclipse Telecom",
        "ecn": "Ecn",
        "economic_computer_systems_inc_dba_mid_atlantic_broadband": "Economic Computer Systems Inc. Dba Mid Atlantic Broadband",
        "ecpi_colleges_inc": "Ecpi Colleges Inc.",
        "ectisp_ellis_county_texas_internet_service_provider_inc": "Ectisp Ellis County Texas Internet Service Provider Inc.",
        "ecuanet_corporacion_ecuatoriana_de_informacion": "Ecuanet - Corporacion Ecuatoriana De Informacion",
        "edaptivity_com_inc": "Edaptivity.com Inc",
        "edatel_s_a_e_s_p": "Edatel S.a. E.s.p",
        "edgar_rodrigues_romao_filho_me": "Edgar Rodrigues Romao Filho Me",
        "edge_broadband": "Edge Broadband",
        "edifice_communications_sa": "Edifice Communications Sa",
        "edilson_santos_silva_sobrinho_me": "Edilson Santos Silva Sobrinho - Me",
        "edion_corporation": "Edion Corporation",
        "edis_gmbh": "Edis Gmbh",
        "edpnet": "Edpnet",
        "education_managment_information_system_emis": "Education Managment Information System Emis",
        "education_networks_of_america": "Education Networks Of America",
        "education_service_center": "Education Service Center",
        "education_service_center_region_xi": "Education Service Center Region Xi",
        "education_service_center_region_xv": "Education Service Center Region Xv",
        "educational_network_ltd": "Educational Network Ltd.",
        "ee_limited": "Ee Limited",
        "ef_tecnologia_ltda": "Ef Tecnologia Ltda",
        "efar_ltd": "Efar Ltd",
        "efibra_telecom_ltda_epp": "Efibra Telecom Ltda - Epp",
        "egate_networks": "E-gate Networks",
        "egihosting": "Egihosting",
        "egleston_childrens_hospital": "Egleston Children's Hospital",
        "egtech_telecom_ltda": "Egtech Telecom Ltda",
        "egyptian_telephone": "Egyptian Telephone",
        "eharmony_com": "Eharmony.com",
        "ehealth_ontario": "Ehealth Ontario",
        "ehime_catv_co_ltd": "Ehime Catv Co. Ltd.",
        "eidsiva_bredband_as": "Eidsiva Bredband As",
        "einfrastructure_and_entertainment_india_pvt_ltd": "E-infrastructure And Entertainment India Pvt. Ltd",
        "eircom_limited": "Eircom Limited",
        "eison_ltd": "Eison Ltd",
        "ekaterinburg2000_llc": "Ekaterinburg-2000 Llc",
        "elastichosts_ltd": "Elastichosts Ltd",
        "elbertonnet": "Elbertonnet",
        "elc_management_llc_elc_security_operations": "Elc Management Llc - Elc Security Operations",
        "elecom_trade_sl": "Elecom Trade Sl",
        "elecon_information_technology_ltd": "Elecon Information Technology Ltd",
        "electric_plant_board_of_the_city_of_glasgow_kentucky": "Electric Plant Board Of The City Of Glasgow Kentucky",
        "electrica_sollerense_sau": "Electrica Sollerense Sau",
        "electrics_ltd": "Electrics Ltd.",
        "electrolux_home_products_inc": "Electrolux Home Products Inc.",
        "electronic_arts_inc": "Electronic Arts Inc.",
        "electronic_corporate_pages_inc": "Electronic Corporate Pages Inc",
        "electronica_martinez_de_cartagena_s_l": "Electronica Martinez De Cartagena S.l.",
        "electronniy_gorod_ltd": "Electronniy Gorod Ltd.",
        "electrovideo_utrera_s_a": "Electrovideo Utrera S.a.",
        "elektrizitaets_und_wasserwerk_der_stadt_buchs_sg": "Elektrizitaets- Und Wasserwerk Der Stadt Buchs Sg",
        "elephant_talk_europe_holding_bv": "Elephant Talk Europe Holding Bv",
        "eletronica_bk_2009_ltda_me": "Eletronica Bk 2009 Ltda - Me",
        "elevalink_telecomunicacoes_ltda_me": "Elevalink Telecomunicacoes Ltda - Me",
        "elevate": "Elevate",
        "eli_lilly_and_company": "Eli Lilly And Company",
        "elighttelecom": "E-light-telecom",
        "elisa_oyj": "Elisa Oyj",
        "elite_broadband_llc": "Elite Broadband Llc",
        "elite_systems_llc": "Elite Systems Llc",
        "elitetele_com_plc": "Elitetele.com Plc",
        "elk_island_public_schools_regional_division_#14": "Elk Island Public Schools Regional Division #14",
        "ellijay_telephone_company": "Ellijay Telephone Company",
        "elsynet_s_r_l": "Elsynet S.r.l.",
        "eltrona_interdiffusion_s_a": "Eltrona Interdiffusion S.a.",
        "elxer_communications_private_limited": "Elxer Communications Private Limited",
        "elxire_data_services_pvt_ltd": "Elxire Data Services Pvt. Ltd.",
        "elyzium_technologies_pvt_ltd": "Elyzium Technologies Pvt. Ltd.",
        "embarq_corporation": "Embarq Corporation",
        "embou_nuevas_tecnologias": "Embou Nuevas Tecnologias",
        "embryriddle_university": "Embry-riddle University",
        "emc_corporation": "Emc Corporation",
        "emerald_onion": "Emerald Onion",
        "emerging_markets_communications": "Emerging Markets Communications",
        "emerson_electric_co": "Emerson Electric Co.",
        "emery_telcom": "Emery Telcom",
        "emirates_integrated_telecommunications_company_pjsc_eitcdu": "Emirates Integrated Telecommunications Company Pjsc Eitc-du",
        "emirates_telecommunications_corporation": "Emirates Telecommunications Corporation",
        "emory_university": "Emory University",
        "empire_access": "Empire Access",
        "empire_district_industries": "Empire District Industries",
        "empresa_de_recursos_tecnologicos_s_a_e_s_p": "Empresa De Recursos Tecnologicos S.a E.s.p",
        "empresa_de_telecomunicaciones_de_cuba_s_a": "Empresa De Telecomunicaciones De Cuba S.a.",
        "empresa_de_telecomunicaciones_y_servicios_audiovisuales_soc_del_estado": "Empresa De Telecomunicaciones Y Servicios Audiovisuales Soc. Del Estado",
        "empresarial_net_banda_larga_ltda": "Empresarial Net Banda Larga Ltda",
        "empresas_municipales_de_cali_e_i_c_e_e_s_p": "Empresas Municipales De Cali E.i.c.e. E.s.p.",
        "emtel_is_gsm_3g_and_isp_in_mauritius": "Emtel Is Gsm 3g And Isp In Mauritius",
        "emtel_s_a_e_s_p": "Emtel S.a. E.s.p.",
        "emts_limited_etisalat_nigeria": "Emts Limited Etisalat Nigeria",
        "end_of_reality_llc": "End Of Reality Llc",
        "endeavor_communications": "Endeavor Communications",
        "endicott_college": "Endicott College",
        "energente_s_r_l": "Energente S.r.l.",
        "energia_communications_inc": "Energia Communications Inc.",
        "energimidt_fiberbredband_as": "Energimidt Fiberbredband As",
        "enet_inc": "Enet Inc.",
        "enetworks_cc": "Enetworks Cc",
        "enhanced_telecommunications_corp": "Enhanced Telecommunications Corp.",
        "enlace_telecom": "Enlace Telecom",
        "enmax_envision_inc": "Enmax Envision Inc.",
        "ennit_server_gmbh": "Ennit Server Gmbh",
        "enochpratt_free_library": "Enoch-pratt Free Library",
        "enredes_s_a": "Enredes S.a.",
        "entanet_international_limited": "Entanet International Limited",
        "entel_chile_s_a": "Entel Chile S.a.",
        "entel_pcs_telecomunicaciones_s_a": "Entel Pcs Telecomunicaciones S.a.",
        "entel_peru_s_a": "Entel Peru S.a.",
        "enteracloud_solutions": "Enteracloud Solutions",
        "entergy_corporation": "Entergy Corporation",
        "enterprise_networks": "Enterprise Networks",
        "enterprise_of_telecommunications_lao": "Enterprise Of Telecommunications Lao",
        "entidad_publica_empresarial_red_es": "Entidad Publica Empresarial Red.es",
        "enventis_telecom_inc": "Enventis Telecom Inc.",
        "envia_tel_gmbh": "Envia Tel Gmbh",
        "environmental_systems_research_institute": "Environmental Systems Research Institute",
        "enzu_inc": "Enzu Inc",
        "eonix_corporation": "Eonix Corporation",
        "epb_fiber_optics": "Epb Fiber Optics",
        "ephos_digital_slu": "E-phos Digital Slu",
        "epic_systems_corporation": "Epic Systems Corporation",
        "epm_telecomunicaciones_s_a_e_s_p": "Epm Telecomunicaciones S.a. E.s.p.",
        "epresa_energia_s_a_u": "Epresa Energia S.a.u.",
        "equant_inc": "Equant Inc.",
        "equinix_brasil_sp": "Equinix Brasil Sp",
        "equinix_inc": "Equinix Inc.",
        "erezuma_martin_twireless": "Erezuma Martin T-wireless",
        "ericsson_inc": "Ericsson Inc.",
        "ericsson_network_systems_inc": "Ericsson Network Systems Inc.",
        "erie_1_boces": "Erie 1 Boces",
        "ernst_young_llp": "Ernst Young Llp",
        "escape_net": "Escape.net",
        "escom_ltd": "Escom Ltd.",
        "esnet": "Esnet",
        "espaco_digital": "Espaco Digital",
        "espn": "Espn",
        "essensys_inc": "Essensys Inc.",
        "essensys_ltd": "Essensys Ltd",
        "estrelar_web_servicos_de_internet_ltda": "Estrelar Web Servicos De Internet Ltda",
        "esycor_s_a": "Esycor S.a.",
        "esystel_servicios_multimedia_sl": "Esystel Servicios Multimedia Sl",
        "etb_colombia": "Etb - Colombia",
        "eternal_video_locadora_ltda": "Eternal Video Locadora Ltda",
        "etex_communications_lp": "Etex Communications Lp",
        "etheric_networks_inc": "Etheric Networks Inc.",
        "ethernet_xpress_pvt_ltd": "Ethernet Xpress Pvt. Ltd.",
        "ethio_telecom": "Ethio Telecom",
        "ethiopian_telecommuncation_corporation": "Ethiopian Telecommuncation Corporation",
        "ethoplex_llc": "Ethoplex Llc",
        "etihad_atheeb_telecom_company": "Etihad Atheeb Telecom Company",
        "etihad_etisalat_a_joint_stock_company": "Etihad Etisalat A Joint Stock Company",
        "etihad_etisalat_company_mobily": "Etihad Etisalat Company Mobily",
        "etisalat_afghanistan": "Etisalat Afghanistan",
        "etisalat_benin_sa": "Etisalat Benin Sa",
        "etisalat_lanka_ltd": "Etisalat Lanka Ltd",
        "etisalat_misr": "Etisalat Misr",
        "etn_emslandtel_net_gmbh_co_kg": "Etn Emslandtel.net Gmbh Co Kg",
        "ets_telephone_company_inc": "Ets Telephone Company Inc.",
        "eunetworks_managed_services_gmbh": "Eunetworks Managed Services Gmbh",
        "euroinformationeuropeenne_de_traitement_de_linformation_sas": "Euro-information-europeenne De Traitement De L'information Sas",
        "eurolink_telecom_ltd": "Eurolink Telecom Ltd",
        "eurona_wireless_telecom_s_a": "Eurona Wireless Telecom S.a.",
        "euronet_communications_b_v": "Euronet Communications B.v.",
        "eurosat_s_a": "Eurosat S.a.",
        "euroweb_romania_sa": "Euroweb Romania Sa",
        "euskaltel_s_a": "Euskaltel S.a.",
        "eutv_ltda": "Eutv Ltda",
        "everest_broadband_networks": "Everest Broadband Networks",
        "everex_comunicaciones_s_a": "Everex Comunicaciones S.a.",
        "evertek_inc": "Evertek Inc",
        "everywhere_wireless_llc": "Everywhere Wireless Llc",
        "evolveip_llc": "Evolveip Llc",
        "evox_telecom_ltda_me": "Evox Telecom Ltda - Me",
        "evsl": "Evsl",
        "ewe_tel_gmbh": "Ewe Tel Gmbh",
        "eweka_internet_services_b_v": "Eweka Internet Services B.v.",
        "exa_networks_limited": "Exa Networks Limited",
        "exatel_s_a": "Exatel S.a.",
        "excell_group_plc": "Excell Group Plc",
        "excell_media_pvt_ltd": "Excell Media Pvt Ltd",
        "excellmedia": "Excellmedia",
        "excitel_broadband_private_limited": "Excitel Broadband Private Limited",
        "execulink_telecom_inc": "Execulink Telecom Inc.",
        "exetel_pty_ltd": "Exetel Pty Ltd",
        "exobit_networks_inc": "Exobit Networks Inc.",
        "experian": "Experian",
        "experior_networks_llc": "Experior Networks Llc",
        "explorernet_infolink_tecnologia_e_telecomunicacoes": "Explorernet Infolink Tecnologia E Telecomunicacoes",
        "exponentiale_ltd": "Exponential-e Ltd.",
        "express_scripts_incorporated": "Express Scripts Incorporated",
        "express_web_systems_inc": "Express Web Systems Inc.",
        "extralan_technologies_co_ltd": "Extra-lan Technologies Co. Ltd",
        "extreme_ltd": "Extreme Ltd",
        "extremena_de_comunicaciones_por_cable_sl": "Extremena De Comunicaciones Por Cable Sl",
        "exxon_mobil_corporation": "Exxon Mobil Corporation",
        "f_a_vieira_de_souza_servicos_me": "F A Vieira De Souza Servicos Me",
        "fabio_da_silva_garcia_computadores_me": "Fabio Da Silva Garcia Computadores - Me",
        "facebook_inc": "Facebook Inc.",
        "fachhochschule_worms": "Fachhochschule Worms",
        "fairfax_county_dept_of_information_technology": "Fairfax County Dept Of Information Technology",
        "fairfax_county_public_schools": "Fairfax County Public Schools",
        "fairfield_university": "Fairfield University",
        "fairleigh_dickinson_university": "Fairleigh Dickinson University",
        "fairpoint_communications_inc": "Fairpoint Communications Inc.",
        "fairview_health_services": "Fairview Health Services",
        "fairway_network_inc": "Fairway Network Inc.",
        "faith_online_network": "Faith Online Network",
        "far_eastone_telecommunication_co_ltd": "Far Eastone Telecommunication Co. Ltd.",
        "farahnet": "Farahnet",
        "farlepinvest": "Farlep-invest",
        "farm_to_market_broadband_lp": "Farm To Market Broadband Lp",
        "farmers_group_inc": "Farmers Group Inc.",
        "farmers_telephone_cooperative_inc": "Farmers Telephone Cooperative Inc.",
        "farmingdale_state_college": "Farmingdale State College",
        "fashion_institute_of_technology": "Fashion Institute Of Technology",
        "fast_serv_networks_llc": "Fast Serv Networks Llc",
        "fast_telecommunications_company_w_l_l": "Fast Telecommunications Company W.l.l.",
        "fasthosts_internet_limited": "Fasthosts Internet Limited",
        "fastmetrics": "Fastmetrics",
        "fastnet_international_ltd": "Fastnet International Ltd",
        "fastnet_telecom": "Fastnet Telecom",
        "fasttrack_communications_inc": "Fasttrack Communications Inc.",
        "fastweb_spa": "Fastweb Spa",
        "fat_shark_ltd": "Fat Shark Ltd",
        "fatbeam_llc": "Fatbeam Llc",
        "fayetteville_public_utilities": "Fayetteville Public Utilities",
        "fbi_criminal_justice_information_systems": "Fbi Criminal Justice Information Systems",
        "fc_telecomunicacoes": "Fc Telecomunicacoes",
        "fd_cavalcante_de_lima_me": "Fd Cavalcante De Lima Me",
        "fdcservers_net": "Fdcservers.net",
        "federal_aviation_administration": "Federal Aviation Administration",
        "federal_aviation_agency": "Federal Aviation Agency",
        "federal_state_educational_institution_of_higher_professional_education_m_v_lomonosov_moscow_state_university": "Federal State Educational Institution Of Higher Professional Education M.v.lomonosov Moscow State University",
        "federal_state_unitary_enterprise_elektrosvyaz": "Federal State Unitary Enterprise Elektrosvyaz",
        "felipe_mauricio_de_queiroz": "Felipe Mauricio De Queiroz",
        "ferris_state_university": "Ferris State University",
        "fgtech_informatica_ltda": "Fgtech Informatica Ltda",
        "fhp_telecomunicacao_e_com_varejista_de_produtos_de": "Fhp Telecomunicacao E Com Varejista De Produtos De",
        "fiber_ca_inc": "Fiber.ca Inc.",
        "fiber_conectividade_ltda_me": "Fiber Conectividade Ltda Me",
        "fiber_galaxy": "Fiber Galaxy",
        "fiber_grid_inc": "Fiber Grid Inc",
        "fiber_internet_center": "Fiber Internet Center",
        "fiber_telecom_telecomunicacao_de_dados": "Fiber Telecom - Telecomunicacao De Dados",
        "fibercomm_lc": "Fibercomm Lc",
        "fiberlight_llc": "Fiberlight Llc",
        "fiberlink_provedores_de_voz_sobre_protocolo_intern": "Fiberlink Provedores De Voz Sobre Protocolo Intern",
        "fibernet_corporation": "Fibernet Corporation",
        "fibernet_direct": "Fibernet Direct",
        "fibernetics_corporation": "Fibernetics Corporation",
        "fiberspark_inc": "Fiberspark Inc.",
        "fibersphere_communications_inc": "Fibersphere Communications Inc.",
        "fibertech_networks_llc": "Fibertech Networks Llc",
        "fibia_ps": "Fibia Ps",
        "fibre_options_limited": "Fibre Options Limited",
        "fibrenoire_inc": "Fibrenoire Inc.",
        "fibrestream_inc": "Fibrestream Inc.",
        "fidelity_access_networks_llc": "Fidelity Access Networks Llc",
        "fidelity_communication_international_inc": "Fidelity Communication International Inc.",
        "fidelity_investments": "Fidelity Investments",
        "fidelity_national_information_services_inc": "Fidelity National Information Services Inc.",
        "fiducia_gad_it_ag": "Fiducia Gad It Ag",
        "field_solutions_group_pty_ltd": "Field Solutions Group Pty Ltd",
        "finanz_informatik_gmbh_co_kg": "Finanz Informatik Gmbh Co. Kg",
        "finger_lakes_technologies_group_inc": "Finger Lakes Technologies Group Inc.",
        "fire2wire": "Fire2wire",
        "fireline_network_solutions_inc": "Fireline Network Solutions Inc.",
        "fireserve_llc": "Fireserve Llc",
        "first_data_corporation": "First Data Corporation",
        "first_step_internet_llc": "First Step Internet Llc",
        "firstdigital_communications_llc": "Firstdigital Communications Llc",
        "fit_telecom_eireli": "Fit Telecom Eireli",
        "five_area_systems_llc": "Five Area Systems Llc",
        "five_college_net_llc": "Five College Net Llc",
        "five_network_broadband_solution_pvt_ltd": "Five Network Broadband Solution Pvt Ltd",
        "fivenetwork_solution_india_pvt_ltd_internet": "Fivenetwork Solution India Pvt Ltd Internet",
        "fivenetwork_solutions_india_pvt_ltd": "Fivenetwork Solutions India Pvt Ltd",
        "fl_nk_gmbh": "Fl Nk Gmbh",
        "flacknet_telecomunicacoes_ltda_me": "Flacknet Telecomunicacoes Ltda Me",
        "flash_net_telecomunicacoes_ltda_me": "Flash Net Telecomunicacoes Ltda - Me",
        "flex_ltd": "Flex Ltd.",
        "flextel_network_telecomunicacoes_eireli_me": "Flextel Network Telecomunicacoes Eireli - Me",
        "flip_tv_pty_limited": "Flip Tv Pty Limited",
        "flix_telecom": "Flix Telecom",
        "florida_a_m_university": "Florida A M University",
        "florida_atlantic_university": "Florida Atlantic University",
        "florida_cable_inc": "Florida Cable Inc.",
        "florida_department_of_management_services_division_of_telecommunications": "Florida Department Of Management Services - Division Of Telecommunications",
        "florida_gulf_coast_university": "Florida Gulf Coast University",
        "florida_high_speed_internet": "Florida High Speed Internet",
        "florida_institute_of_technology": "Florida Institute Of Technology",
        "florida_international_university": "Florida International University",
        "florida_lambdarail_llc": "Florida Lambdarail Llc",
        "florida_state_university": "Florida State University",
        "flow": "Flow",
        "fluency_communications_limited": "Fluency Communications Limited",
        "fluidata_ltd": "Fluidata Ltd",
        "flybyte_comunicacao_multimida_ltda": "Flybyte Comunicacao Multimida Ltda.",
        "fofrnet_spol_s_r_o": "Fofrnet Spol. S R.o.",
        "fogo_data_centers": "Fogo Data Centers",
        "fone_net_llc": "Fone Net Llc",
        "fonelight_telecomunicacoes_sa": "Fonelight Telecomunicacoes Sa",
        "fontainebleau_miami_beach": "Fontainebleau Miami Beach",
        "fonte_informatica_ltda": "Fonte Informatica Ltda",
        "foothills_rural_telephone_cooperative_corporation_inc": "Foothills Rural Telephone Cooperative Corporation Inc.",
        "forcepoint_cloud_ltd": "Forcepoint Cloud Ltd",
        "ford_motor_company": "Ford Motor Company",
        "fordham_university": "Fordham University",
        "forethought_net": "Forethought.net",
        "forsvarets_logistikkorganisasjon_ikt": "Forsvarets Logistikkorganisasjon Ikt",
        "fortescue_metals_group_ltd": "Fortescue Metals Group Ltd",
        "fortex_cjsc": "Fortex Cjsc",
        "forthnet_sa": "Forthnet Sa",
        "fournetwork_servicos_de_informatica_ltda": "Fournetwork Servicos De Informatica Ltda",
        "fouru_technologies_llc": "Four-u Technologies Llc",
        "fourway_computer_products_inc": "Fourway Computer Products Inc.",
        "fox_entertainment_group_inc": "Fox Entertainment Group Inc.",
        "fox_telecomunicacao_e_internet_ltda": "Fox Telecomunicacao E Internet Ltda.",
        "fox_valley_internet": "Fox Valley Internet",
        "foxtel_management_pty_ltd": "Foxtel Management Pty Ltd",
        "foxtel_telecommunications_pvt_ltd": "Foxtel Telecommunications Pvt. Ltd.",
        "foxx_provider_telecom": "Foxx Provider Telecom",
        "fp_telecomiunicacoes_ltda": "Fp Telecomiunicacoes Ltda",
        "fpl_fibernet_llc": "Fpl Fibernet Llc",
        "fpt_telecom": "Fpt Telecom",
        "fpt_telecom_company": "Fpt Telecom Company",
        "francis_de_lasalle_trading_as_heymman_servers": "Francis De Lasalle Trading As Heymman Servers",
        "francis_marion_university": "Francis Marion University",
        "frankfort_plant_board": "Frankfort Plant Board",
        "franklin_and_marshall_college": "Franklin And Marshall College",
        "frantech_solutions": "Frantech Solutions",
        "free_mobile_sas": "Free Mobile Sas",
        "free_sas": "Free Sas",
        "free_technologies_excom_s_l": "Free Technologies Excom S.l.",
        "freebit_co_ltd": "Freebit Co. Ltd.",
        "freedomnet": "Freedomnet",
        "freenet_datenkommunikations_gmbh": "Freenet Datenkommunikations Gmbh",
        "freenet_ltd": "Freenet Ltd.",
        "freewire_broadband_llc": "Freewire Broadband Llc",
        "froedtert_memorial_lutheran_hospital_inc": "Froedtert Memorial Lutheran Hospital Inc.",
        "front_range_internet_inc": "Front Range Internet Inc.",
        "frontier_communications_of_america_inc": "Frontier Communications Of America Inc.",
        "frontier_networks": "Frontier Networks",
        "frontiir_myanmar_group": "Frontiir Myanmar Group",
        "fsa_tecnologia_em_comunicacao_ltda_me": "Fsa Tecnologia Em Comunicacao Ltda - Me",
        "fsm_telecommunications_corporation": "Fsm Telecommunications Corporation",
        "ftip003256947": "Ftip003256947",
        "fujitsu_limited": "Fujitsu Limited",
        "fukuoka_university": "Fukuoka University",
        "fukushima_medical_university": "Fukushima Medical University",
        "full_channel": "Full Channel",
        "fundacao_para_a_ciencia_e_a_tecnologia_i_p": "Fundacao Para A Ciencia E A Tecnologia I.p.",
        "fundacio_guifi_net": "Fundacio Guifi.net",
        "fundacion_integra": "Fundacion Integra",
        "furman_university": "Furman University",
        "fuse_internet_access": "Fuse Internet Access",
        "fusion_media": "Fusion Media",
        "fusion_voice_and_data_corp": "Fusion Voice And Data Corp.",
        "fusionnet_web_services_private_limited": "Fusionnet Web Services Private Limited",
        "future_wireless_technologies_of_nebraska_inc": "Future Wireless Technologies Of Nebraska Inc.",
        "futurnet_s_a": "Futurnet S.a.",
        "fybercom_llc": "Fybercom Llc",
        "g1telecom_provedor_de_internet_ltda_me": "G1telecom Provedor De Internet Ltda Me",
        "g20_telecomunicacoes_ltda": "G20 Telecomunicacoes Ltda",
        "g2link_solucoes_em_internet": "G2link Solucoes Em Internet",
        "g3_comms_limited": "G3 Comms Limited",
        "g6_internet": "G6 Internet",
        "g8_networks_ltda": "G8 Networks Ltda",
        "g_de_sousa_araujo": "G. De Sousa Araujo",
        "g_g_net_telecomunicacoes_ltda_epp": "G G Net - Telecomunicacoes Ltda Epp",
        "g_l_servicos_e_com_de_informatica_ltda": "G L Servicos E Com. De Informatica Ltda.",
        "g_s_infornet_ltda": "G S Infornet Ltda",
        "g_s_ttelecom": "G S T-telecom",
        "gabon_telecom": "Gabon Telecom",
        "gabon_telecom_internet_network_autonomous_system": "Gabon Telecom Internet Network Autonomous System",
        "gainesville_regional_utilities": "Gainesville Regional Utilities",
        "galaxyvisions_inc": "Galaxyvisions Inc",
        "gallagher_bassett_services_inc": "Gallagher Bassett Services Inc.",
        "gallaudet_university": "Gallaudet University",
        "gallo_vicente": "Gallo Vicente",
        "galop_telecom": "Galop Telecom",
        "gamewood_technology_group_inc": "Gamewood Technology Group Inc.",
        "gamma_network_solution_limited": "Gamma Network Solution Limited",
        "gamma_telecom_holdings_ltd": "Gamma Telecom Holdings Ltd",
        "gamma_telecom_limited": "Gamma Telecom Limited",
        "garden_sat_provedor_de_internet_ltda_me": "Garden Sat Provedor De Internet Ltda Me",
        "garden_valley_telephone_company": "Garden Valley Telephone Company",
        "garra_telecom_ltda": "Garra Telecom Ltda",
        "garrison_network_solutions_llc": "Garrison Network Solutions Llc",
        "gars_telecom_telecommunications_management_llc": "Gars Telecom - Telecommunications Management Llc",
        "gatik_business_solutions": "Gatik Business Solutions",
        "gazon_communications_india_limited": "Gazon Communications India Limited",
        "gb_informatica_ltda": "Gb Informatica Ltda",
        "gcec_technologies": "Gcec Technologies",
        "gcn_broadband_pvt_ltd": "Gcn Broadband Pvt Ltd",
        "gcndcn_networks": "Gcndcn Networks",
        "gcnet_reach_range_inc": "Gcnet Reach Range Inc.",
        "gcom_networks_ltda_me": "Gcom Networks Ltda Me",
        "gconnect_technology_ltd": "Gconnect Technology Ltd",
        "gcore_labs_s_a": "G-core Labs S.a.",
        "gcr_companygcr_online": "Gcr Companygcr Online",
        "gdsys": "Gdsys",
        "ge_drive_systems": "Ge Drive Systems",
        "geetingsville_telephone_co_inc": "Geetingsville Telephone Co. Inc.",
        "geisinger_system_services": "Geisinger System Services",
        "geliconapple_limited_liability_company": "Gelicon-apple Limited Liability Company",
        "gelsennet_kommunikationsgesellschaft_mbh": "Gelsen-net Kommunikationsgesellschaft Mbh",
        "genentech_inc": "Genentech Inc.",
        "general_communication_inc": "General Communication Inc.",
        "general_dynamics_information_technology_inc": "General Dynamics Information Technology Inc.",
        "general_electric_company": "General Electric Company",
        "general_mills": "General Mills",
        "general_motors_corporation": "General Motors Corporation",
        "general_services_administration": "General Services Administration",
        "geneseo_telephone_company": "Geneseo Telephone Company",
        "genesis_technology_communication_llc": "Genesis Technology Communication Llc",
        "geneva_online_inc": "Geneva On-line Inc.",
        "genossenschaft_gga_maur": "Genossenschaft Gga Maur",
        "genstar_network_solutions_pvt_ltd": "Genstar Network Solutions Pvt Ltd.",
        "geocell_ltd": "Geocell Ltd.",
        "geocity_network_solutions_pvt_ltd": "Geocity Network Solutions Pvt Ltd",
        "geolinks": "Geolinks",
        "geonet_ltd": "Geonet Ltd.",
        "george_mason_university": "George Mason University",
        "georgetown_university": "Georgetown University",
        "georgia_institute_of_technology": "Georgia Institute Of Technology",
        "georgia_public_web_inc": "Georgia Public Web Inc.",
        "georgia_state_university": "Georgia State University",
        "georgia_technology_authority": "Georgia Technology Authority",
        "georgian_technical_university": "Georgian Technical University",
        "geotel_bangladesh_it_ltd": "Geotel Bangladesh It Ltd.",
        "geracao_real_banda_larga_eireli": "Geracao Real Banda Larga Eireli",
        "get_as": "Get As",
        "gettysburg_college": "Gettysburg College",
        "getwireless_net": "Getwireless.net",
        "ggew_net_gmbh": "Ggew Net Gmbh",
        "ghostnet_gmbh": "Ghostnet Gmbh",
        "gibsolutions_ag": "Gib-solutions Ag",
        "gie_axa_technology_services_france": "Gie Axa Technology Services France",
        "gigaclear_plc": "Gigaclear Plc",
        "gigacom_systems_ltd": "Gigacom Systems Ltd.",
        "gigaline_internet_servicos_de_informatica_ltda": "Gigaline Internet Servicos De Informatica Ltda",
        "gigalink_de_nova_friburgo_solucoes_em_rede_multimi": "Gigalink De Nova Friburgo Solucoes Em Rede Multimi",
        "gigalink_ltd": "Gigalink Ltd.",
        "gigamonster": "Gigamonster",
        "giganet_comunicacoes_multimidia_ltda": "Giganet Comunicacoes Multimidia Ltda",
        "giganet_servicos_de_internet_ltda_me": "Giganet Servicos De Internet Ltda Me",
        "giganet_telecom": "Giganet Telecom",
        "giganetlink_telecomunicacoes_ltda_me_me": "Giganetlink Telecomunicacoes Ltda Me - Me",
        "giganetsul_me": "Giganetsul - Me",
        "gigantic_infotel_pvt_ltd": "Gigantic Infotel Pvt Ltd",
        "gigared_s_a": "Gigared S.a.",
        "gigastream_plc": "Gigastream Plc",
        "gigavirt_technologies": "Gigavirt Technologies",
        "gigawire_informatica_ltda": "Gigawire Informatica Ltda",
        "gigenet": "Gigenet",
        "giggle_fiber": "Giggle Fiber",
        "gila_river_telecommunications_inc": "Gila River Telecommunications Inc.",
        "gilat_satcom": "Gilat Satcom",
        "gilead_sciences": "Gilead Sciences",
        "glassbridge_enterprises_inc": "Glassbridge Enterprises Inc.",
        "glbb_japan_kk": "Glbb Japan Kk",
        "glenbard_hsd_#87": "Glenbard Hsd #87",
        "glenwood_springs_community_broadband_network": "Glenwood Springs Community Broadband Network",
        "glenwood_telephone_membership_corporation": "Glenwood Telephone Membership Corporation",
        "glesys_internet_services_ab": "Glesys Internet Services Ab",
        "gli": "Gli",
        "globacom_limited": "Globacom Limited",
        "globacom_ltd": "Globacom Ltd",
        "global_crossing_ltd_s_50th_st_phoenix_az_85034_800_414_5028_nccgblx_net": "Global Crossing Ltd. S 50th St Phoenix Az 85034 800.414.5028 Nccgblx.net",
        "global_foundries_pte_ltd": "Global Foundries Pte Ltd",
        "global_frag_networks": "Global Frag Networks",
        "global_network_core_co_ltd": "Global Network Core Co. Ltd.",
        "global_network_telecomunicacoes_do_brasil_ltda": "Global Network Telecomunicacoes Do Brasil Ltda.",
        "global_networks_infocomm_pvt_ltd": "Global Networks Infocomm Pvt Ltd",
        "global_web_solutions_inc": "Global Web Solutions Inc.",
        "globalconnect_as": "Globalconnect As",
        "globalfoundries_u_s_inc": "Globalfoundries U.s. Inc.",
        "globalive_wireless_management_corp": "Globalive Wireless Management Corp.",
        "globalnet_servicos_e_informatica_ltdame": "Globalnet Servicos E Informatica Ltda-me",
        "globalreach_ebusiness_networks_inc": "Globalreach Ebusiness Networks Inc.",
        "globalvision": "Globalvision",
        "globalways_ag": "Globalways Ag",
        "globe_telecom": "Globe Telecom",
        "globecomm_network_services_corporation": "Globecomm Network Services Corporation",
        "globecomm_services_maryland_llc": "Globecomm Services Maryland Llc",
        "globo_net_informatica": "Globo Net Informatica",
        "gmo_internet_inc": "Gmo Internet Inc.",
        "gmobile": "G-mobile",
        "gms_net_telecomunicacoes": "Gms Net Telecomunicacoes",
        "gncalfa_cjsc": "Gnc-alfa Cjsc",
        "gnet_provedor": "Gnet Provedor",
        "go_fiber": "Go Fiber",
        "godaddy_com_llc": "Godaddy.com Llc",
        "golan_telecom_ltd": "Golan Telecom Ltd.",
        "golden_belt_telephone": "Golden Belt Telephone",
        "golden_link": "Golden Link",
        "golden_west_telecommunications_coop_inc": "Golden West Telecommunications Coop. Inc.",
        "goldnet_servicos_de_internet_ltda": "Goldnet Servicos De Internet Ltda",
        "gonet": "Gonet",
        "gonzaga_university": "Gonzaga University",
        "good_net_provedor_de_internet_ltda_epp": "Good Net Provedor De Internet Ltda - Epp",
        "goodnet_llc": "Goodnet Llc",
        "google_fiber_inc": "Google Fiber Inc.",
        "google_inc": "Google Inc.",
        "gorakhpur_net_services_pvt_ltd": "Gorakhpur Net Services Pvt. Ltd.",
        "gorge_networks_inc": "Gorge Networks Inc.",
        "gorillaservers_inc": "Gorillaservers Inc.",
        "gorset_ltd": "Gorset Ltd.",
        "gosfield_north_communications_cooperative_limited": "Gosfield North Communications Co-operative Limited",
        "gostaresheertebatate_mabna_company_private_joint_stock": "Gostaresh-e-ertebatat-e Mabna Company Private Joint Stock",
        "goteborgs_stad": "Goteborgs Stad",
        "government_of_the_district_of_columbia": "Government Of The District Of Columbia",
        "governors_state_university": "Governors State University",
        "graceland_university": "Graceland University",
        "grady_memorial_hospital": "Grady Memorial Hospital",
        "grafton_technologies_inc": "Grafton Technologies Inc.",
        "grajaunet_telecomunicacoes": "Grajaunet Telecomunicacoes",
        "grameenphone_ltd": "Grameenphone Ltd.",
        "granacable_s_l": "Granacable S.l",
        "grand_canyon_university": "Grand Canyon University",
        "grand_river_mutual_telephone_corporation": "Grand River Mutual Telephone Corporation",
        "grand_telephone_company_inc": "Grand Telephone Company Inc.",
        "grand_valley_state_university": "Grand Valley State University",
        "grande_communications_networks_llc": "Grande Communications Networks Llc",
        "granite_state_communications": "Granite State Communications",
        "granite_telecommunications_llc": "Granite Telecommunications Llc",
        "grant_county_powernet_inc": "Grant County Powernet Inc.",
        "grant_county_public_utility_district": "Grant County Public Utility District",
        "graybar_electric_co_inc": "Graybar Electric Co. Inc",
        "great_lakes_communication_corp": "Great Lakes Communication Corp.",
        "great_lakes_comnet_inc": "Great Lakes Comnet Inc.",
        "great_plains_communications_inc": "Great Plains Communications Inc",
        "great_river_energy": "Great River Energy",
        "greater_vision_microwave_networks_llc": "Greater Vision Microwave Networks Llc",
        "greek_research_and_technology_network_s_a": "Greek Research And Technology Network S.a",
        "green_cable_television_station": "Green Cable Television Station",
        "green_ch_ag": "Green.ch Ag",
        "green_cloud_technologies_llc": "Green Cloud Technologies Llc",
        "greenhost_bv": "Greenhost Bv",
        "greenlight": "Greenlight",
        "greenlight_networks_llc": "Greenlight Networks Llc",
        "grid4_communications_inc": "Grid4 Communications Inc",
        "griffith_university": "Griffith University",
        "grinnell_college": "Grinnell College",
        "grinnell_mutual_reinsurance_company": "Grinnell Mutual Reinsurance Company",
        "groupe_maskatel": "Groupe Maskatel",
        "grupo_empresarial_peluche_s_l_u": "Grupo Empresarial Peluche S.l.u.",
        "grupo_mega_flash_servicos_e_com_ltda_epp": "Grupo Mega Flash Servicos E Com Ltda - Epp",
        "grupo_wifimax_s_l": "Grupo Wifimax S.l.",
        "grupohost_comunicacao_multimidia_ltda": "Grupohost Comunicacao Multimidia Ltda",
        "gsg_telecom_servicos_ltda_me": "Gsg Telecom Servicos Ltda - Me",
        "gsn_taiwan_government_service_network": "Gsn Taiwan Government Service Network.",
        "gtd_internet_s_a": "Gtd Internet S.a.",
        "gtek_computers_llc": "Gtek Computers Llc",
        "guadalupe_valley_telephone_cooperative_inc": "Guadalupe Valley Telephone Cooperative Inc.",
        "guaiba_telecom": "Guaiba Telecom",
        "guam_cablevision_llc": "Guam Cablevision Llc.",
        "guidant_corporation": "Guidant Corporation",
        "gujarat_telelik_pvt_ltd": "Gujarat Telelik Pvt Ltd",
        "gulfnet_ksa": "Gulfnet Ksa",
        "gundersen_lutheran_medical_center_inc": "Gundersen Lutheran Medical Center Inc.",
        "gustavus_adolphus_college": "Gustavus Adolphus College",
        "gvec_net_inc": "Gvec.net Inc.",
        "gwynedd_mercy_college": "Gwynedd Mercy College",
        "gyeongsang_national_university": "Gyeongsang National University",
        "h5_data_centers_chandler_llc": "H5 Data Centers - Chandler Llc",
        "h_bauer_publishing_ltd": "H Bauer Publishing Ltd",
        "h_neris_souza": "H Neris Souza",
        "haefele_connect": "Haefele Connect",
        "hala_al_rafidain_company_for_communications_and_internet_ltd": "Hala Al Rafidain Company For Communications And Internet Ltd.",
        "halley_telecom_comercio_servico_ltda_me": "Halley Telecom Comercio Servico Ltda Me",
        "halliburton_company": "Halliburton Company",
        "halstad_telephone_company": "Halstad Telephone Company",
        "halton_catholic_district_school_board": "Halton Catholic District School Board",
        "hamilton_county_communications_inc": "Hamilton County Communications Inc",
        "hamilton_telecommunications": "Hamilton Telecommunications",
        "hamiltonclermont_cooperative_assn": "Hamilton-clermont Cooperative Assn.",
        "hamline_university": "Hamline University",
        "hampdensydney_college": "Hampden-sydney College",
        "hanaro_telecom_inc": "Hanaro Telecom Inc.",
        "hancock_rural_telephone_corp": "Hancock Rural Telephone Corp.",
        "hanson_communications_inc": "Hanson Communications Inc.",
        "hanyang_university": "Hanyang University",
        "harbor_communications_llc": "Harbor Communications Llc",
        "harbour_isp_pty_ltd": "Harbour Isp Pty Ltd",
        "harding_university": "Harding University",
        "hardonline_ltda": "Hardonline Ltda",
        "hardy_telecommunications": "Hardy Telecommunications",
        "harewaves_wireless_inc": "Harewaves Wireless Inc",
        "hargray_communications_group_inc": "Hargray Communications Group Inc.",
        "harisree_cable_net_pvt_ltd": "Harisree Cable Net Pvt Ltd",
        "harlan_community_television_inc": "Harlan Community Television Inc.",
        "harlan_municipal_utilities": "Harlan Municipal Utilities",
        "harris_broadband_l_p": "Harris Broadband L.p.",
        "harris_corporation": "Harris Corporation",
        "harris_county_hospital_district": "Harris County Hospital District",
        "harrisburg_area_community_college": "Harrisburg Area Community College",
        "harrisonville_telephone": "Harrisonville Telephone",
        "hart_communications_inc": "Hart Communications Inc.",
        "hartford_hospital": "Hartford Hospital",
        "hathway_cable_and_datacom_pvt_ltd": "Hathway Cable And Datacom Pvt Ltd",
        "hauni_maschinenbau_ag": "Hauni Maschinenbau Ag",
        "haverford_college": "Haverford College",
        "haviland_telephone_company_inc": "Haviland Telephone Company Inc.",
        "hawaii_pacific_university": "Hawaii Pacific University",
        "hawaiian_telcom_services_company_inc": "Hawaiian Telcom Services Company Inc.",
        "hay_communications_cooperative_limited": "Hay Communications Co-operative Limited",
        "hayneville_telephone_company": "Hayneville Telephone Company",
        "hb_communications_inc": "Hb Communications Inc.",
        "hbu_edu": "Hbu.edu",
        "hc_cable_opco_llc": "Hc Cable Opco Llc",
        "hd_net_nz": "Hd.net.nz",
        "headquarters_usaisc": "Headquarters Usaisc",
        "health_and_hospital_corporation_of_marion_county": "Health And Hospital Corporation Of Marion County",
        "heanet_limited": "Heanet Limited",
        "heartland_council_of_governments": "Heartland Council Of Governments",
        "hector_communications_corp": "Hector Communications Corp.",
        "heg_us_inc": "Heg Us Inc.",
        "heidelberg_it_management_gmbh_co_kg": "Heidelberg It Management Gmbh Co.kg",
        "heli_net_telekommunication_gmbh_co": "Heli Net Telekommunication Gmbh Co.",
        "help_net_telecom_e_informatica_ltda_me": "Help Net Telecom E Informatica Ltda - Me",
        "hempfield_school_district": "Hempfield School District",
        "henderson_municipal_power_light": "Henderson Municipal Power Light",
        "henderson_state_university": "Henderson State University",
        "hennepin_county_medical_center": "Hennepin County Medical Center",
        "henry_ford_health_system": "Henry Ford Health System",
        "herault_telecom": "Herault Telecom",
        "hermes_datacommunications_international_limited": "Hermes Datacommunications International Limited",
        "hershey_chocolate_confectionery_corporation": "Hershey Chocolate Confectionery Corporation",
        "hessenkom_gmbh_co_kg": "Hessenkom Gmbh Co Kg",
        "hetzner_online_ag": "Hetzner Online Ag",
        "hewlett_packard_enterprise_company": "Hewlett Packard Enterprise Company",
        "hewlettpackard_company": "Hewlett-packard Company",
        "heymman_servers": "Heymman Servers",
        "hgc_global_communications_limited": "Hgc Global Communications Limited",
        "hi3g_access_ab": "Hi3g Access Ab",
        "hiawatha_broadband_communications_inc": "Hiawatha Broadband Communications Inc",
        "hickorytech_corporation": "Hickorytech Corporation",
        "high_desert_education_service_district_high_desert_esd": "High Desert Education Service District - High Desert Esd",
        "high_desert_internet_services": "High Desert Internet Services",
        "high_mountain_farm_llc": "High Mountain Farm Llc",
        "high_point_university": "High Point University",
        "high_speed_utah_llc": "High Speed Utah Llc",
        "high_speed_webgenesis_2_networks": "High Speed Webgenesis 2 Networks",
        "high_tech_informatica_ltda": "High Tech Informatica Ltda",
        "highland_communication_services": "Highland Communication Services",
        "highspeed_office_limited": "Highspeed Office Limited",
        "highspeed_universal_broadband_services_c_i_c": "High-speed Universal Broadband Services C.i.c.",
        "highwinds_network_group_inc": "Highwinds Network Group Inc.",
        "hildemar_de_jesus_santos": "Hildemar De Jesus Santos",
        "hill_country_telephone_cooperative_inc": "Hill Country Telephone Cooperative Inc.",
        "hillbilly_wireless_inc": "Hillbilly Wireless Inc.",
        "hillsborough_community_college": "Hillsborough Community College",
        "himeji_cable_television_corporation": "Himeji Cable Television Corporation",
        "hiq_data_corporation": "Hiq Data Corporation",
        "hireach_broadband_private_ltd": "Hireach Broadband Private Ltd",
        "hiroshima_shudo_university": "Hiroshima Shudo University",
        "hk_cable_tv_ltd": "Hk Cable Tv Ltd",
        "hl_komm_telekommunikations_gmbh": "Hl Komm Telekommunikations Gmbh",
        "hoag_memorial_hospital": "Hoag Memorial Hospital",
        "hofstra_university": "Hofstra University",
        "hokuden_information_system_service_co_ltd": "Hokuden Information System Service Co. Ltd.",
        "hokuriku_telecommunication_network_co": "Hokuriku Telecommunication Network Co.",
        "holistica_provedor_internet_ltda": "Holistica Provedor Internet Ltda",
        "holy_family_university": "Holy Family University",
        "holyoke_gas_electric_department": "Holyoke Gas Electric Department",
        "holzer_health_system": "Holzer Health System",
        "home_cable_entertainment_india_pvt_ltd": "Home Cable Entertainment India Pvt Ltd",
        "home_systems_pvt_ltd": "Home Systems Pvt.ltd",
        "home_telephone_co": "Home Telephone Co.",
        "home_telephone_company_inc": "Home Telephone Company Inc.",
        "home_town_telephone_llc": "Home Town Telephone Llc",
        "hondutel": "Hondutel",
        "honesty_net_solutions_india_pvt_ltd": "Honesty Net Solutions India Pvt. Ltd.",
        "hong_kong_broadband_network_ltd": "Hong Kong Broadband Network Ltd",
        "hongkong_commercial_internet_exchange": "Hongkong Commercial Internet Exchange",
        "hood_canal_communications": "Hood Canal Communications",
        "hood_college": "Hood College",
        "hope_college": "Hope College",
        "hopkinsville_electric_system": "Hopkinsville Electric System",
        "hopone_internet_corporation": "Hopone Internet Corporation",
        "horizon_telcom_inc": "Horizon Telcom Inc.",
        "horizons_telecomunicacoes_e_tecnologia_ltda": "Horizons Telecomunicacoes E Tecnologia Ltda",
        "hormuud_telecom_somalia_inc": "Hormuud Telecom Somalia Inc",
        "horry_telephone_cooperative_inc": "Horry Telephone Cooperative Inc.",
        "hoshin_multimedia_center_inc": "Hoshin Multimedia Center Inc",
        "hospital_for_special_surgery": "Hospital For Special Surgery",
        "hospital_sisters_health_systems": "Hospital Sisters Health Systems",
        "host_europe_gmbh": "Host Europe Gmbh",
        "hosted_data_solutions_llc": "Hosted Data Solutions Llc",
        "hosting_services_inc": "Hosting Services Inc.",
        "hostinger_international_limited": "Hostinger International Limited",
        "hostkey": "Hostkey",
        "hostlocation_ltda": "Hostlocation Ltda",
        "hostus": "Hostus",
        "hostvenom_llc": "Hostvenom Llc",
        "hostway_deutschland_gmbh": "Hostway Deutschland Gmbh",
        "hostwinds_llc": "Hostwinds Llc.",
        "hot_mobile_ltd": "Hot Mobile Ltd.",
        "hotwire_communications": "Hotwire Communications",
        "houston_independent_school_district": "Houston Independent School District",
        "howard_community_college": "Howard Community College",
        "hp_hood": "Hp Hood",
        "hr_transportes_verticais": "Hr Transportes Verticais",
        "hringdu_ehf": "Hringdu Ehf",
        "hrvatski_telekom_d_d": "Hrvatski Telekom D.d.",
        "hsbc_bank_plc": "Hsbc Bank Plc",
        "hse_medianet_gmbh": "Hse Medianet Gmbh",
        "ht": "Ht",
        "ht_d_o_o_mostar": "Ht D.o.o. Mostar",
        "htc_communications": "Htc Communications",
        "htec_telecomunicacoes_eireli": "Htec - Telecomunicacoes Eireli",
        "htp_gmbh": "Htp Gmbh",
        "htsnet_isp": "Htsnet - Isp",
        "hudson_valley_community_college": "Hudson Valley Community College",
        "hudson_valley_datanet_llc": "Hudson Valley Datanet Llc",
        "hugeserver_networks_llc": "Hugeserver Networks Llc",
        "hughes_network_systems": "Hughes Network Systems",
        "hughes_telecomunicacoes_do_brasil_ltda": "Hughes Telecomunicacoes Do Brasil Ltda.",
        "huntel_net": "Huntel.net",
        "hunter_communications": "Hunter Communications",
        "hunttel": "Hunttel",
        "hurricane_electric_inc": "Hurricane Electric Inc.",
        "husson_university": "Husson University",
        "hutchinson_community_college": "Hutchinson Community College",
        "hutchison_3g_ireland_limited": "Hutchison 3g Ireland Limited",
        "hutchison_3g_uk_limited": "Hutchison 3g Uk Limited",
        "hutchison_drei_austria_gmbh": "Hutchison Drei Austria Gmbh",
        "hutchison_telecommunication_limited": "Hutchison Telecommunication Limited",
        "hutchison_telephone_company_limited": "Hutchison Telephone Company Limited",
        "hutterian_broadband_network_inc": "Hutterian Broadband Network Inc",
        "hv_television_s_a_s": "Hv Television S.a.s",
        "hydra_communications_ltd": "Hydra Communications Ltd",
        "hydraulic_supply_company": "Hydraulic Supply Company",
        "hyperoptic_ltd": "Hyperoptic Ltd",
        "hyundai_communications_network": "Hyundai Communications Network",
        "hyundai_motor_company": "Hyundai Motor Company",
        "i2000_inc": "I-2000 Inc.",
        "i_b_c_shpk": "I.b.c Shpk",
        "iam": "Iam",
        "ibara_broadcasting_company": "Ibara Broadcasting Company",
        "ibh_itservice_gmbh": "Ibh It-service Gmbh",
        "ibl_banda_larga_internet_informatica_ltda": "Ibl Banda Larga Internet Informatica Ltda",
        "ibm": "Ibm",
        "iboss_inc": "Iboss Inc",
        "ica_canada_online_inc": "Ica Canada On-line Inc.",
        "icc_communication": "Icc Communication",
        "icom_telecom_servicos_de_comunicacao_ltda_me": "Icom Telecom Servicos De Comunicacao Ltda - Me",
        "icone_telecom": "Icone Telecom",
        "iconecta_redes_de_telecomunicacao_ltda_epp": "I-conecta Redes De Telecomunicacao Ltda Epp",
        "iconnectsa_pty_ltd": "Iconnectsa Pty Ltd",
        "iconzwebvisions_pte_ltd": "Iconz-webvisions Pte. Ltd.",
        "ics_advanced_technologies": "Ics Advanced Technologies",
        "icuk_computing_services_limited": "Icuk Computing Services Limited",
        "icvoronezh": "Ic-voronezh",
        "idaho_state_university": "Idaho State University",
        "idc_china_telecommunications_corporation": "Idc China Telecommunications Corporation",
        "idc_telecom_ltda_epp": "Idc Telecom Ltda Epp",
        "idea_cellular_limited": "Idea Cellular Limited",
        "ideal_integrations_inc": "Ideal Integrations Inc.",
        "idealnet_fibra": "Idealnet Fibra",
        "ideatek_telcom_llc": "Ideatek Telcom Llc",
        "ifiber_communications_corp": "Ifiber Communications Corp.",
        "iftnet_telecomunicacoes_ltda": "Iftnet Telecomunicacoes Ltda",
        "ifx_networks_argentina_s_r_l": "Ifx Networks Argentina S.r.l.",
        "ifx_networks_colombia": "Ifx Networks Colombia",
        "igaueno_cable_television_co_ltd": "Igaueno Cable Television Co. Ltd",
        "ignet_linhas_telecomunicacoes_me": "Ignet Linhas Telecomunicacoes - Me",
        "igraservice_llc": "Igra-service Llc",
        "iguana_comunicacions_s_l": "Iguana Comunicacions S.l.",
        "iheartcommunications_inc": "Iheartcommunications Inc.",
        "ihnovecom_telecomunicacoes_e_servicos_ltda": "Ihnovecom Telecomunicacoes E Servicos Ltda",
        "iiac": "Iiac",
        "iinet_limited": "Iinet Limited",
        "ikatel_sa_service_provider_in_mali": "Ikatel Sa. Service Provider In Mali",
        "ikf_technologies_limited": "Ikf Technologies Limited",
        "ikoula_net_sas": "Ikoula Net Sas",
        "ilan": "Ilan",
        "iland_internet_solutions_corporation": "Iland Internet Solutions Corporation",
        "ilim_telecom_ltd": "Ilim Telecom Ltd.",
        "illinois_century_network": "Illinois Century Network",
        "illinois_rural_telecommunication_co": "Illinois Rural Telecommunication Co.",
        "illinois_state_university": "Illinois State University",
        "illinois_wesleyan_university": "Illinois Wesleyan University",
        "ilmprovider_ug_haftungsbeschraenkt": "Ilm-provider Ug Haftungsbeschraenkt",
        "ilorci_tv_s_l": "Ilorci Tv S.l.",
        "imagination_inc": "Imagination Inc.",
        "imagine_networks": "Imagine Networks",
        "img_brasil_telecomunicacoes_ltda": "Img Brasil Telecomunicacoes Ltda",
        "imizu_cable_network_co_ltd": "Imizu Cable Network Co. Ltd.",
        "imos_gmbh": "Imos Gmbh",
        "impacto_telecomunicacoes_eireli_me": "Impacto Telecomunicacoes Eireli - Me",
        "imperial_college_london": "Imperial College London",
        "improware_ag": "Improware Ag",
        "impsat_fiber_networks_inc": "Impsat Fiber Networks Inc",
        "impulstv_cjsc": "Impuls-tv Cjsc",
        "ims_corporation": "Ims Corporation",
        "inb_telecom_eireli_me": "Inb Telecom Eireli - Me",
        "incero_llc": "Incero Llc",
        "incito_limited": "Incito Limited",
        "inconetdata_management_s_a_l": "Inconet-data Management S.a.l.",
        "incontact_inc": "Incontact Inc.",
        "ind_co_cable_tv_inc": "Ind. Co. Cable Tv Inc.",
        "independence_telecommunications_utility": "Independence Telecommunications Utility",
        "independent_fibre_networks_limited": "Independent Fibre Networks Limited",
        "indian_institute_of_technology_roorkee": "Indian Institute Of Technology Roorkee",
        "indiana_department_of_education": "Indiana Department Of Education",
        "indiana_fiber_network_llc": "Indiana Fiber Network Llc",
        "indiana_higher_education_telecommunication_system": "Indiana Higher Education Telecommunication System",
        "indiana_university": "Indiana University",
        "indointernet_network": "Indointernet Network",
        "indosat": "Indosat",
        "indosatm2": "Indosatm2",
        "industry_inet_inc": "Industry I-net Inc.",
        "inea_s_a": "Inea S.a.",
        "inetvip_telecom_ltda_epp": "Inetvip Telecom Ltda Epp",
        "inetvr_internet_service_provider_ltda": "Inetvr Internet Service Provider Ltda.",
        "inexio_informationstechnologie_und_telekommunikationkgaa": "Inexio Informationstechnologie Und Telekommunikationkgaa",
        "inf_telecom": "Inf Telecom",
        "infinity_developments_limited": "Infinity Developments Limited",
        "infinity_internet_inc": "Infinity Internet Inc.",
        "infirmary_health_system_inc": "Infirmary Health System Inc.",
        "info_telecomunicacoes_ltda_me": "Info Telecomunicacoes Ltda - Me",
        "infoartes_telecom": "Infoartes Telecom",
        "infobahn_outfitters_inc": "Infobahn Outfitters Inc.",
        "infolic_comercial_de_informatica_ltda": "Infolic Comercial De Informatica Ltda.",
        "infoline_banda_larga_ltda": "Infoline Banda Larga Ltda",
        "infoline_ltd": "Infoline Ltd.",
        "infolink_telecom": "Infolink Telecom",
        "infomac_telecomunicacoes_ltda": "Infomac Telecomunicacoes Ltda",
        "infonet_comm_enterprises": "Infonet Comm Enterprises",
        "infonet_services_corporation": "Infonet Services Corporation",
        "infonet_telecom_ldta": "Infonet Telecom Ldta",
        "inforbarra_telecom": "Inforbarra Telecom",
        "informatica_fuentealbilla_s_l": "Informatica Fuentealbilla S.l.",
        "information_and_communication_technologies_llc": "Information And Communication Technologies Llc",
        "information_network_llc": "Information Network Llc",
        "information_technologies_ltd": "Information Technologies Ltd",
        "information_technology_company": "Information Technology Company",
        "information_technology_un_limited": "Information Technology Un Limited",
        "informationalmeasuring_systems_ltd": "Informational-measuring Systems Ltd",
        "informatsionniye_systemy_i_technologii_cjsc": "Informatsionniye Systemy I Technologii Cjsc.",
        "informatsionnye_tekhnologii_llc": "Informatsionnye Tekhnologii Llc",
        "infornet_consultoria_e_assessoria_ltda": "Infornet Consultoria E Assessoria Ltda",
        "infornet_servico_de_comunicacao_multimidia_eireli": "Infornet Servico De Comunicacao Multimidia Eireli",
        "inforoom_ltd": "Inforoom Ltd.",
        "infortel_telecomunicacoes_e_servicos_eireli_me": "Infortel Telecomunicacoes E Servicos Eireli - Me",
        "inforway_informatica_e_telefonia_ltda_me": "Inforway Informatica E Telefonia Ltda - Me",
        "infosport_sa": "Infosport Sa",
        "infostructure": "Infostructure",
        "infostructure_cable_and_internet": "Infostructure Cable And Internet",
        "infotec_servicos_de_provedor_da_internet_ltda": "Infotec- Servicos De Provedor Da Internet Ltda",
        "infotech_edvsysteme_gmbh": "Infotech Edv-systeme Gmbh",
        "infotech_ltd": "Infotech Ltd",
        "infotechnet_informatica_e_assistencia_tecnica_ltda": "Infotechnet Informatica E Assistencia Tecnica Ltda",
        "infotelecom_networks_sl": "Infotelecom Networks Sl",
        "infousa": "Infousa",
        "infovaletelecom_ltdaepp": "Infovale-telecom Ltda-epp",
        "infowest_inc": "Infowest Inc.",
        "infracom_italia_s_p_a": "Infracom Italia S.p.a.",
        "infranet_internet_ltda": "Infranet Internet Ltda.",
        "inger_tv_s_l": "Inger Tv S.l.",
        "ingersoll_rand_company": "Ingersoll Rand Company",
        "init7_switzerland_ltd": "Init7 Switzerland Ltd.",
        "inkotelecom_llc": "Inko-telecom Llc",
        "inland_cellular": "Inland Cellular",
        "inland_internet": "Inland Internet",
        "inline_connections": "Inline Connections",
        "innovaciones_tecnlogicas_del_sur_s_l": "Innovaciones Tecnlogicas Del Sur S.l.",
        "innovative_solutions_ltd": "Innovative Solutions Ltd",
        "innove_communications": "Innove Communications",
        "innsys_incorporated": "Innsys Incorporated",
        "inoc_llc": "Inoc Llc",
        "inove_telecomunicacoes_ltda_me": "Inove Telecomunicacoes Ltda Me",
        "inpasupri_com_de_suprimentos_pinf_ltda": "Inpasupri - Com. De Suprimentos Pinf. Ltda",
        "inside_connect_cable_llc": "Inside Connect Cable Llc",
        "instalaciones_y_servicios_mowitel_s_l": "Instalaciones Y Servicios Mowitel S.l.",
        "instanet_technologies_pvt_ltd": "Instanet Technologies Pvt. Ltd",
        "instavps": "Instavps",
        "institute_of_technical_education": "Institute Of Technical Education",
        "instituto_costarricense_de_electricidad_y_telecom": "Instituto Costarricense De Electricidad Y Telecom.",
        "institutul_national_de_cercetaredezvoltare_in_informatica_ici_bucuresti": "Institutul National De Cercetare-dezvoltare In Informatica - Ici Bucuresti",
        "intech_online_private_limited": "Intech Online Private Limited",
        "integra_telecom_inc": "Integra Telecom Inc.",
        "integrated_telecom_co_ltd": "Integrated Telecom Co. Ltd",
        "integrirovannyie_seti_svyazi_ltd": "Integrirovannyie Seti Svyazi Ltd.",
        "integrity_networks": "Integrity Networks",
        "integrys_it_di_stefania_peragna_impresa_individuale": "Integrys.it Di Stefania Peragna Impresa Individuale",
        "intel_corporation": "Intel Corporation",
        "intelig_telecomunicacoes_ltda": "Intelig Telecomunicacoes Ltda.",
        "intelix_tecnologia_ltdame": "Intelix Tecnologia Ltda-me",
        "intelletrace": "Intelletrace",
        "intelliwave_llc": "Intelliwave Llc",
        "intelsat": "Intelsat",
        "intelsat_global_bgp_routing_policy": "Intelsat Global Bgp Routing Policy",
        "inter_com_ltd": "Inter.com Ltd",
        "inter_connects_inc": "Inter Connects Inc",
        "interbel_telephone_cooperative_inc": "Interbel Telephone Cooperative Inc.",
        "intercommunity_telephone_co": "Inter-community Telephone Co.",
        "intercomtel_limited_company": "Intercomtel Limited Company",
        "intercon_jsc": "Intercon Jsc",
        "interface": "Interface",
        "interfacil_internet_provider": "Interfacil - Internet Provider",
        "interfacing_company_of_texas_inc": "Interfacing Company Of Texas Inc.",
        "interfibra_telecomunicaciones_s_l": "Interfibra Telecomunicaciones S.l.",
        "interhop_network_services_inc": "Interhop Network Services Inc.",
        "interles_comunicacoes_ltda": "Interles Comunicacoes Ltda",
        "interlink_co_ltd": "Interlink Co. Ltd",
        "interlink_s_r_l": "Interlink S.r.l.",
        "intermax_networks": "Intermax Networks",
        "intermicro_ltda": "Intermicro Ltda",
        "internal_revenue_service": "Internal Revenue Service",
        "internap_network_services_corporation": "Internap Network Services Corporation",
        "international_communications_company": "International Communications Company",
        "internet_5_8_ltdame": "Internet 5.8 Ltda-me",
        "internet_access_solutions_ltd": "Internet Access Solutions Ltd.",
        "internet_connections_ltd": "Internet Connections Ltd",
        "internet_da_bahia_ltda": "Internet Da Bahia Ltda",
        "internet_de_nuevo_laredo_s_a_de_c_v": "Internet De Nuevo Laredo S.a De C.v.",
        "internet_directo_lineless_s_l": "Internet Directo Lineless S.l.",
        "internet_information_group": "Internet Information Group",
        "internet_initiative_japan_inc": "Internet Initiative Japan Inc.",
        "internet_multifeed_co": "Internet Multifeed Co.",
        "internet_para_todos_gobierno_de_la_rioja": "Internet Para Todos - Gobierno De La Rioja",
        "internet_service_solution_corp": "Internet Service Solution Corp.",
        "internet_servicos_ltda": "Internet Servicos Ltda.",
        "internet_solutions": "Internet Solutions",
        "internet_systems_consortium_inc": "Internet Systems Consortium Inc.",
        "internet_thailand_public_company_limited": "Internet Thailand Public Company Limited",
        "internetia_sp_z_o_o": "Internetia Sp.z O.o.",
        "internexa_global_network": "Internexa Global Network",
        "internexa_s_a_e_s_p": "Internexa S.a. E.s.p",
        "internext_2000_s_r_o": "Internext 2000 S.r.o.",
        "internode": "Internode",
        "interop_show_network": "Interop Show Network",
        "interoute_communications_limited": "Interoute Communications Limited",
        "interoute_managed_services_uk_limited": "Interoute Managed Services Uk Limited",
        "interphase_communications_inc": "Interphase Communications Inc.",
        "interra_telecommunications_group_ltd": "Interra Telecommunications Group Ltd.",
        "interregional_social_foundation_institute_of_engineering_physics": "Interregional Social Foundation Institute Of Engineering Physics",
        "interserver_inc": "Interserver Inc",
        "intersul_telecom_ltda": "Intersul Telecom Ltda",
        "intersvyaz": "Intersvyaz",
        "intertech_corp": "Intertech Corp",
        "intertelecom_ltd": "Intertelecom Ltd",
        "intinform_ltd": "Intinform Ltd.",
        "intred_s_p_a": "Intred S.p.a.",
        "invest_mobile_llc": "Invest Mobile Llc",
        "invisalink_wireless": "Invisalink Wireless",
        "invitel_tavkozlesi_zrt": "Invitel Tavkozlesi Zrt.",
        "involta": "Involta",
        "inyo_networks": "Inyo Networks",
        "io_capital_princess_llc": "Io Capital Princess Llc",
        "iomart": "Iomart",
        "iomart_hosting_limited": "Iomart Hosting Limited",
        "iowa_communications_network": "Iowa Communications Network",
        "iowa_state_university": "Iowa State University",
        "ip4isp_z_s_p_o": "Ip4isp Z.s.p.o",
        "ip_agabalov_rafael_grigoryevich": "Ip Agabalov Rafael Grigoryevich",
        "ip_matrix_s_a_de_c_v": "Ip Matrix S.a. De C.v.",
        "ip_nikolay_yankovskiy": "Ip Nikolay Yankovskiy",
        "ipanet_telecomunicacoes_ltda": "Ipanet Telecomunicacoes Ltda",
        "ipax_og": "Ipax Og",
        "ipe_informatica_ltda": "Ipe Informatica Ltda",
        "ipi9": "Ipi9",
        "ipko_telecommunications_llc": "Ipko Telecommunications Llc",
        "iponly_networks_ab": "Ip-only Networks Ab",
        "iproute_srl": "Iproute Srl",
        "ipunet_telecom": "Ipunet Telecom",
        "ipv4_management_srl": "Ipv4 Management Srl",
        "ipv6_informatica_s_l": "Ipv6 Informatica S.l.",
        "iq_networks": "Iq Networks",
        "iquest_internet": "Iquest Internet",
        "iran_cell_service_and_communication_company": "Iran Cell Service And Communication Company",
        "iren_energia_s_p_a": "Iren Energia S.p.a",
        "iriisnet_communication_pvt_ltd": "Iriisnet Communication Pvt Ltd",
        "iris_networks": "Iris Networks",
        "irkutsk_business_net": "Irkutsk Business Net",
        "irkutskenergosvyaz_ltd": "Irkutskenergosvyaz Ltd.",
        "iron_mountain_inc": "Iron Mountain Inc",
        "ironnet_ltd": "Ironnet Ltd.",
        "irpinia_netcom_srl": "Irpinia Net-com Srl",
        "iruma_catv_co": "Iruma Catv Co.",
        "irvine_community_television": "Irvine Community Television",
        "is": "Is",
        "is_net_elektonik_bilgi_uretim_dagitim_ticaret_ve_iletisim_hizmetleri_a_s": "Is Net Elektonik Bilgi Uretim Dagitim Ticaret Ve Iletisim Hizmetleri A.s.",
        "isahaya_cable_television_co_inc": "Isahaya Cable Television Co. Inc.",
        "isapre_banmedica": "Isapre Banmedica",
        "isaque_oliveira_de_santana": "Isaque Oliveira De Santana",
        "isc_group": "Isc Group",
        "iseek_communications": "Iseek Communications",
        "iseek_communications_pty_ltd": "Iseek Communications Pty Ltd",
        "ishan_netsol_pvt_ltd": "Ishan Netsol Pvt Ltd",
        "ishans_network": "Ishan's Network",
        "ishikawa_computer_center_co_ltd": "Ishikawa Computer Center Co. Ltd.",
        "isi_line_srl": "Isi Line Srl",
        "iskratelecom_cjsc": "Iskratelecom Cjsc",
        "isomedia_inc": "Isomedia Inc.",
        "isp_fregat_ltd": "Isp Fregat Ltd.",
        "isp_provernet_informatica_ltda_me": "Isp Provernet Informatica Ltda - Me",
        "ispcompany_complat": "Isp-company Complat",
        "ispixp_in_cambodia_with_the_best_vervice_in_there": "Ispixp In Cambodia With The Best Vervice In There.",
        "ispnet_inc": "Ispnet Inc.",
        "it7_networks_inc": "It7 Networks Inc",
        "it_concept_srl": "It Concept Srl",
        "it_region_ltd": "It Region Ltd",
        "it_service_ltd": "It Service Ltd.",
        "itaconnect_provedores_ltda_me": "Itaconnect Provedores Ltda - Me",
        "itc": "Itc",
        "itc_electron_ltd": "Itc Electron Ltd",
        "itech_telecom": "Itech Telecom",
        "itel_networks_inc": "Itel Networks Inc",
        "itelkom": "Itelkom",
        "ithaca_college": "Ithaca College",
        "itility_limited": "Itility Limited",
        "its_telecomunicacoes_ltda": "Its Telecomunicacoes Ltda",
        "itt_closed_stock_co": "Itt Closed Stock Co.",
        "itv3": "Itv-3",
        "iveloz_telecom_serv_em_telecomunicacoes_ltda": "Iveloz Telecom Serv. Em Telecomunicacoes Ltda",
        "ivi_tecnologia_e_comunicacao_ltda": "Ivi Tecnologia E Comunicacao Ltda",
        "ivy_tech_community_college_of_indiana": "Ivy Tech Community College Of Indiana",
        "iway_ag": "Iway Ag",
        "iweb_technologies_inc": "Iweb Technologies Inc.",
        "ix_networks_brasil": "Ix Networks Brasil",
        "j_a_b_net_provedor_de_servicos_de_internet_eireli": "J.a.b. Net Provedor De Servicos De Internet Eireli",
        "j_de_lima_fernandes_e_silva_me": "J. De Lima Fernandes E Silva - Me",
        "jab_wireless_inc": "Jab Wireless Inc.",
        "jackson_energy_authority": "Jackson Energy Authority",
        "jackson_state_university": "Jackson State University",
        "jacobi_international_inc": "Jacobi International Inc.",
        "jacobs_levy_equity_management_inc": "Jacobs Levy Equity Management Inc.",
        "jaguar_communications": "Jaguar Communications",
        "jaguar_network_sas": "Jaguar Network Sas",
        "jalan_broga": "Jalan Broga",
        "jamalkontinent_ltd": "Jamal-kontinent Ltd",
        "james_cable_llc": "James Cable Llc",
        "james_cook_university": "James Cook University",
        "james_madison_university": "James Madison University",
        "james_tower_media_design": "James Tower Media Design",
        "japan_communication_inc": "Japan Communication Inc.",
        "japan_nationwide_network_of_softbank_corp": "Japan Nation-wide Network Of Softbank Corp.",
        "jasa_telematika_terpadu_jasatel": "Jasa Telematika Terpadu Jasatel",
        "jasper_technologies_inc": "Jasper Technologies Inc",
        "jatimnet_telecom": "Jatimnet Telecom",
        "jb_informatica_ltda_me": "Jb Informatica Ltda Me",
        "jbn_telephone_co_inc": "Jbn Telephone Co Inc.",
        "jcwifi_com": "Jcwifi.com",
        "jdc_ca_inc": "Jdc.ca Inc.",
        "jdm_broadband_services_pvt_ltd": "Jdm Broadband Services Pvt Ltd",
        "jeffco_public_schools": "Jeffco Public Schools",
        "jefferson_co_cable_inc": "Jefferson Co. Cable Inc.",
        "jefferson_parish_school_board": "Jefferson Parish School Board",
        "jefferson_smurfit_corporation": "Jefferson Smurfit Corporation",
        "jeonju_university": "Jeonju University",
        "jersey_telecom_limited": "Jersey Telecom Limited",
        "jethulio_rodrigues_arcovede_me": "Jethulio Rodrigues Arcovede Me",
        "jetspotnetworks_pvt_ltd": "Jetspotnetworks Pvt Ltd",
        "jhcomp_s_r_o": "Jhcomp S.r.o.",
        "jisc_services_limited": "Jisc Services Limited",
        "jk_telecomunicacoes_ltda": "Jk Telecomunicacoes Ltda",
        "jl_panglima_polim_127": "Jl. Panglima Polim 127",
        "jlconect_pb": "Jlconect Pb",
        "jlink_india": "Jlink India",
        "jm_family_enterprises_inc": "Jm Family Enterprises Inc.",
        "jmdata_gmbh": "Jm-data Gmbh",
        "jmf_investment_technology_pty_ltd": "Jmf Investment Technology Pty Ltd",
        "jmf_solutions_inc": "Jmf Solutions Inc",
        "jn_informatica_ltda": "Jn Informatica Ltda",
        "jobson_luis_melo_de_negreiros_me": "Jobson Luis Melo De Negreiros Me",
        "jocarroll_energy_inc_nfp": "Jo-carroll Energy Inc. Nfp",
        "joes_datacenter_llc": "Joes Datacenter Llc",
        "johannes_gutenberguniversitaet_mainz": "Johannes Gutenberg-universitaet Mainz",
        "john_carroll_university": "John Carroll University",
        "johns_hopkins_university": "Johns Hopkins University",
        "johns_hopkins_university_applied_physics_laboratory": "Johns Hopkins University Applied Physics Laboratory",
        "johnson_controls": "Johnson Controls",
        "johnson_johnson": "Johnson Johnson",
        "johnson_wales_university": "Johnson Wales University",
        "joink_llc": "Joink Llc",
        "joint_stock_company_mtu_kristall": "Joint Stock Company Mtu Kristall",
        "joint_universities_computer_centre_limited": "Joint Universities Computer Centre Limited",
        "jointstock_company_investpribor": "Joint-stock Company Investpribor",
        "jordan_telecommunications_company": "Jordan Telecommunications Company",
        "jose_carlos_roque_oliveira_cia_ltda": "Jose Carlos Roque Oliveira Cia Ltda",
        "jose_luis_zurakouski_mix_servicios_comunicaciones": "Jose Luis Zurakouski Mix Servicios Comunicaciones",
        "jose_nilton_de_freitas_e_cia_ltda": "Jose Nilton De Freitas E Cia Ltda",
        "joshua_peter_mcquistan": "Joshua Peter Mcquistan",
        "joyent_inc": "Joyent Inc.",
        "joytel": "Joytel",
        "jp_providers_eirellime": "Jp Providers Eirelli-me",
        "jpmorgan_chase_co": "Jpmorgan Chase Co.",
        "jpr_digital_pvt_ltd": "Jpr Digital Pvt. Ltd.",
        "jr_intercom_s_r_l": "Jr Intercom S.r.l",
        "jsc_altel": "Jsc Altel",
        "jsc_artemovskoye_interaktivnoe_televidenie": "Jsc Artemovskoye Interaktivnoe Televidenie",
        "jsc_internetcosmos": "Jsc Internet-cosmos",
        "jsc_kazakhtelecom": "Jsc Kazakhtelecom",
        "jsc_kyrgyztelecom": "Jsc Kyrgyztelecom",
        "jsc_lantek": "Jsc Lantek",
        "jsc_ltd_balttelecom": "Jsc Ltd. Balttelecom",
        "jsc_national_multiservice_networks": "Jsc National Multiservice Networks",
        "jsc_relax": "Jsc Relax",
        "jsc_silknet": "Jsc Silknet",
        "jsc_united_telecom_autonomous_system": "Jsc United Telecom Autonomous System",
        "jsc_volgatranstelecom": "Jsc Volgatranstelecom",
        "jsc_zapsib_transtelecom_novosibirsk": "Jsc Zap-sib Transtelecom Novosibirsk",
        "jscc_interdnestrcom": "Jscc Interdnestrcom",
        "jt_international_sa": "Jt International Sa",
        "julio_cesar_das_neves_me": "Julio Cesar Das Neves - Me",
        "juniper_networks_inc": "Juniper Networks Inc.",
        "jupiter_telecommunication_co_ltd": "Jupiter Telecommunication Co. Ltd",
        "jupiter_telecommunications_co_ltd": "Jupiter Telecommunications Co. Ltd.",
        "jupiter_telecomunicacoes_e_informatica_ltda": "Jupiter Telecomunicacoes E Informatica Ltda",
        "justweb_telecomunicacoes_ltda": "Justweb Telecomunicacoes Ltda",
        "jway_co_ltd": "Jway Co. Ltd.",
        "k2_telecom_e_multimidia_ltda_me": "K2 Telecom E Multimidia Ltda Me",
        "k_m_telephone_company_inc": "K M Telephone Company Inc",
        "k_telecom_ltd": "K Telecom Ltd.",
        "kabel_bw_gmbh": "Kabel Bw Gmbh",
        "kabelplus_gmbh": "Kabelplus Gmbh",
        "kaiser_foundation_health_plan": "Kaiser Foundation Health Plan",
        "kaizen_systems": "Kaizen Systems",
        "kamatera_inc": "Kamatera Inc.",
        "kamo_electric_cooperative_inc": "Kamo Electric Cooperative Inc.",
        "kanokla_communications_llc": "Kanokla Communications Llc",
        "kansai_university": "Kansai University",
        "kansas_fiber_network_llc": "Kansas Fiber Network Llc",
        "kansas_research_and_education_network": "Kansas Research And Education Network",
        "kansas_state_university": "Kansas State University",
        "kanuma_cable_television_corporation": "Kanuma Cable Television Corporation",
        "kaplan_telephone_company_inc": "Kaplan Telephone Company Inc.",
        "kappa_internet_services_private_limited": "Kappa Internet Services Private Limited",
        "kartel_llc": "Kar-tel Llc",
        "kasaoka_cable_vision_co_ltd": "Kasaoka Cable Vision Co Ltd.",
        "kaspiytelekom_ltd": "Kaspiytelekom Ltd.",
        "katch_network_inc": "Katch Network Inc.",
        "katy_independent_school_district": "Katy Independent School District",
        "kayse_wireless": "Kayse Wireless",
        "kb_rubin_ltd": "Kb Rubin Ltd.",
        "kbro_co_ltd": "Kbro Co. Ltd.",
        "kc_web_inc": "Kc Web Inc.",
        "kcell_jsc": "Kcell Jsc",
        "kcnnet": "Kcn-net",
        "kcom_group_plc": "Kcom Group Plc",
        "kcom_group_public_limited_company": "Kcom Group Public Limited Company",
        "kctv_jeju_broadcasting": "Kctv Jeju Broadcasting",
        "kddi_corporation": "Kddi Corporation",
        "kddi_web_communications_inc": "Kddi Web Communications Inc.",
        "keene_state_college": "Keene State College",
        "keewaytinook_okimakanak": "Keewaytinook Okimakanak",
        "kellin_communications": "Kellin Communications",
        "kelly_supply_company": "Kelly Supply Company",
        "kennesaw_state_university": "Kennesaw State University",
        "kent_intermediate_school_district": "Kent Intermediate School District",
        "kent_state_university": "Kent State University",
        "kentec_communications_inc": "Kentec Communications Inc.",
        "kentenn_wireless_llc": "Ken-tenn Wireless Llc",
        "kentucky_community_and_technical_college_system": "Kentucky Community And Technical College System",
        "kentucky_department_of_education": "Kentucky Department Of Education",
        "kentucky_educational_computing_network": "Kentucky Educational Computing Network",
        "kentucky_wimax": "Kentucky Wimax",
        "kenya_data_networks": "Kenya Data Networks",
        "kenya_education_network": "Kenya Education Network",
        "kenyan_post_telecommunications_company_telkom_kenya_ltd": "Kenyan Post Telecommunications Company Telkom Kenya Ltd",
        "kerala_communicators_cable_limited": "Kerala Communicators Cable Limited",
        "kern_high_school_district": "Kern High School District",
        "kettering_medical_center": "Kettering Medical Center",
        "kettering_university": "Kettering University",
        "keumgang_cable_network": "Keumgang Cable Network",
        "kevag_telekom_gmbh": "Kevag Telekom Gmbh",
        "key_west_broadband_llc": "Key West Broadband Llc",
        "keycom_plc": "Keycom Plc",
        "khabarovskaya_set_ltd": "Khabarovskaya Set Ltd.",
        "kibris_mobile_telekomunikasyon_ltd": "Kibris Mobile Telekomunikasyon Ltd.",
        "kiewit_corporation": "Kiewit Corporation",
        "killeen_isd": "Killeen Isd",
        "kinber": "Kinber",
        "kinetic_it_solutions_llc": "Kinetic It Solutions Llc",
        "king_abdul_aziz_city_for_science_and_technology": "King Abdul Aziz City For Science And Technology",
        "king_abdullah_university_of_science_and_technology": "King Abdullah University Of Science And Technology",
        "king_county_gov": "King County Gov",
        "king_saud_university": "King Saud University",
        "kingonline_ltd": "King-online Ltd.",
        "kings_bay_communications_inc": "Kings Bay Communications Inc.",
        "kings_broadband_pvt_ltd": "Kings Broadband Pvt Ltd",
        "kings_county_office_of_education": "Kings County Office Of Education",
        "kintetsu_cable_network_co_ltd": "Kintetsu Cable Network Co. Ltd.",
        "kit_carson_electric_cooperative_inc": "Kit Carson Electric Cooperative Inc",
        "kmtelecom": "Kmtelecom",
        "knet_comunicaciones_s_l": "Knet Comunicaciones S.l.",
        "knet_sarl": "K-net Sarl",
        "kngd_infosys_pvt_ltd": "Kngd Infosys Pvt Ltd",
        "knology_inc": "Knology Inc.",
        "koba_sp_z_o_o": "Koba Sp. Z O.o.",
        "koch_industries_inc": "Koch Industries Inc.",
        "komp_ruko_mall_klender_blok_b_212": "Komp Ruko Mall Klender Blok B 212",
        "konnet_informatica_eireli_epp": "Konnet Informatica Eireli - Epp",
        "kontact_ooo": "Kontact Ooo",
        "kopticom_corporation": "K-opticom Corporation",
        "korea_advanced_institute_of_science_and_technology": "Korea Advanced Institute Of Science And Technology",
        "korea_cable_tv_namincheon_broadcasting_co_ltd": "Korea Cable Tv Namincheon Broadcasting.co.ltd",
        "korea_telecom": "Korea Telecom",
        "korean_education_network": "Korean Education Network",
        "kovrovtelecom_ltd": "Kovrovtelecom Ltd.",
        "kozitskiy_a_m_pi": "Kozitskiy A.m. Pi",
        "kpn_b_v": "Kpn B.v.",
        "kpu_telecommunications": "Kpu Telecommunications",
        "kroll_ldn_as": "Kroll_ldn_as",
        "krypt_technologies": "Krypt Technologies",
        "ktelecom_cjsc": "K-telecom Cjsc",
        "ktk_telecom_llc": "Ktk Telecom Llc",
        "kuentos_communications_inc": "Kuentos Communications Inc.",
        "kuhn_communications_com_inc": "Kuhn.communications.com Inc.",
        "kurashiki_cable_tv": "Kurashiki Cable Tv",
        "kuwait_telecommunication_company": "Kuwait Telecommunication Company",
        "kvantel_as": "Kvantel As",
        "kvanttelekom_closed_joint_stock_company": "Kvant-telekom Closed Joint Stock Company",
        "kvchosting_com_llc": "Kvchosting.com Llc",
        "kvh_industries_inc": "Kvh Industries Inc.",
        "kviknet_dk_aps": "Kviknet.dk Aps",
        "kw_datacenter": "Kw Datacenter",
        "kwangju_cable_television_corp": "Kwangju Cable Television Corp",
        "kyivski_telekomunikatsiyni_merezhi_llc": "Kyivski Telekomunikatsiyni Merezhi Llc",
        "kyivstar": "Kyivstar",
        "l_d_telis_de_oliveira": "L D Telis De Oliveira",
        "l_e_m_informatica_e_internet_ltda": "L E M Informatica E Internet Ltda",
        "l_e_m_telecomunicacoes_ltda_me": "L E M Telecomunicacoes Ltda -me",
        "l_r_communications_inc": "L.r. Communications Inc.",
        "labelgrup_networks_s_l": "Label-grup Networks S.l.",
        "lackawanna_college": "Lackawanna College",
        "lafayette_consolidated_government": "Lafayette Consolidated Government",
        "lahey_clinic_inc": "Lahey Clinic Inc.",
        "lake_connections": "Lake Connections",
        "lake_geauga_computer_association": "Lake Geauga Computer Association",
        "lake_region_electric_cooperative_inc": "Lake Region Electric Cooperative Inc",
        "lake_travis_isd": "Lake Travis Isd",
        "lakehead_university": "Lakehead University",
        "lakenet_llc": "Lakenet Llc",
        "lakes_pc_help_llc": "Lakes Pc Help Llc",
        "lakeshore_northeast_ohio_computer_association_lnoca": "Lakeshore Northeast Ohio Computer Association Lnoca",
        "lamar_university": "Lamar University",
        "lamont_doherty_geological_observatory": "Lamont Doherty Geological Observatory",
        "lancashire_county_council": "Lancashire County Council",
        "lancaster_lebanon_intermediate_unit_13": "Lancaster Lebanon Intermediate Unit 13",
        "lancaster_university_network_services_limited": "Lancaster University Network Services Limited",
        "lancefibernet_pvt_ltd": "Lancefibernet Pvt Ltd",
        "lancom_ltd": "Lancom Ltd.",
        "landmark_college_inc": "Landmark College Inc",
        "lanet_network_ltd": "Lanet Network Ltd",
        "lanka_bells_as": "Lanka Bell's As",
        "lanminds_inc": "Lanminds Inc.",
        "lanta_ltd": "Lanta Ltd",
        "lao_telecommunications": "Lao Telecommunications",
        "laredo_isd": "Laredo Isd",
        "largnet": "Largnet",
        "lasalle_university": "Lasalle University",
        "lasvegas_net_llc": "Lasvegas.net Llc",
        "latin_american_and_caribbean_ip_address_regional_registry": "Latin American And Caribbean Ip Address Regional Registry",
        "latisysdenver_llc": "Latisys-denver Llc",
        "latisysirvine_llc": "Latisys-irvine Llc",
        "laurel_highland_telephone_company": "Laurel Highland Telephone Company",
        "lawrence_university": "Lawrence University",
        "laxmi_internet_services_pvt_ltd": "Laxmi Internet Services Pvt Ltd",
        "lays_valeria_costa_almeida_franca_epp": "Lays Valeria Costa Almeida Franca - Epp",
        "lazernet": "Lazernet",
        "lci_equipamentos_de_informatica_ltda_lci_telecom": "Lci Equipamentos De Informatica Ltda - Lci Telecom",
        "lcv_corporation": "Lcv Corporation",
        "le_moyne_college": "Le Moyne College",
        "leaco_rural_telephone": "Leaco Rural Telephone",
        "leadertelecom_b_v": "Leadertelecom B.v.",
        "lealta_ltd": "Lealta Ltd",
        "leapswitch_networks_pvt_ltd": "Leapswitch Networks Pvt Ltd",
        "leaseweb_deutschland_gmbh": "Leaseweb Deutschland Gmbh",
        "leaseweb_netherlands_b_v": "Leaseweb Netherlands B.v.",
        "leaseweb_usa_inc": "Leaseweb Usa Inc.",
        "least_cost_routing_telecom_sl": "Least Cost Routing Telecom Sl",
        "lebrija_television": "Lebrija Television",
        "lee_county_clerk_of_courts": "Lee County Clerk Of Courts",
        "lee_university": "Lee University",
        "legaco_networks_b_v": "Legaco Networks B.v.",
        "legaco_networks_bv": "Legaco Networks Bv",
        "legacy_health_system": "Legacy Health System",
        "legal_entity_of_public_law_financial_analytical_service": "Legal Entity Of Public Law Financial Analytical Service",
        "lehigh_university": "Lehigh University",
        "leibnizrechenzentrum": "Leibniz-rechenzentrum",
        "lemos_e_morais_ltda": "Lemos E Morais Ltda.",
        "leni_gomes_oberleander_me": "Leni Gomes Oberleander Me",
        "leonardo_rodrigo_ferro_me": "Leonardo Rodrigo Ferro - Me",
        "leonix_telecom_eurl": "Leonix Telecom Eurl",
        "lepida_s_p_a": "Lepida S.p.a.",
        "level3_comunicacoes_do_brasil_ltda": "Level3 Comunicacoes Do Brasil Ltda.",
        "level_3_communications": "Level 3 Communications",
        "level_3_communications_ltd": "Level 3 Communications Ltd",
        "level_3_parent_llc": "Level 3 Parent Llc",
        "lewis_clark_college": "Lewis Clark College",
        "lg_cns": "Lg Cns",
        "lg_cns_america_inc": "Lg Cns America Inc.",
        "lg_dacom_corporation": "Lg Dacom Corporation",
        "lg_powercomm": "Lg Powercomm",
        "lg_telecom_kidc": "Lg Telecom Kidc",
        "lga_international": "Lga International",
        "lgtelecom": "Lgtelecom",
        "lhp_hospital_group_inc": "Lhp Hospital Group Inc.",
        "liazo_sarl": "Liazo Sarl",
        "libantelecom": "Libantelecom",
        "liberty_cablevision_of_puerto_rico_ltd": "Liberty Cablevision Of Puerto Rico Ltd",
        "liberty_communications": "Liberty Communications",
        "liberty_global_operations_b_v": "Liberty Global Operations B.v.",
        "liberty_mutual_group": "Liberty Mutual Group",
        "liberty_university": "Liberty University",
        "libertyizone_ltd": "Liberty-izone Ltd",
        "libnet_comunicacao_interativa_ltda": "Libnet Comunicacao Interativa Ltda",
        "licking_area_computer_association": "Licking Area Computer Association",
        "liden_data_internetwork_ab": "Liden Data Internetwork Ab",
        "life_technologies_corp": "Life Technologies Corp.",
        "light_speed_technologies_llc": "Light Speed Technologies Llc",
        "lightbound": "Lightbound",
        "lighthouse_net": "Lighthouse.net",
        "lightower_fiber_networks_i_llc": "Lightower Fiber Networks I Llc",
        "lightower_fiber_networks_light141": "Lightower Fiber Networks Light-141",
        "lightspeed_communications_llc": "Lightspeed Communications Llc",
        "lightwire_ltd": "Lightwire Ltd",
        "ligo_telecomunicacoes_ltda_me": "Ligo Telecomunicacoes Ltda - Me",
        "lima_video_cable_s_a_cabletel": "Lima Video Cable S.a. Cabletel",
        "limestone_cable_vision_inc": "Limestone Cable Vision Inc.",
        "limestone_networks_inc": "Limestone Networks Inc.",
        "limited_company_sint": "Limited Company Sint",
        "limited_liability_company_irtelcom": "Limited Liability Company Irtelcom",
        "limited_liability_company_konnectika": "Limited Liability Company Konnectika",
        "limited_liability_company_kursktelecom": "Limited Liability Company Kursktelecom",
        "limited_liability_company_kvant": "Limited Liability Company Kvant",
        "limited_liability_company_megamax": "Limited Liability Company Megamax",
        "limited_liability_company_mvm_technology": "Limited Liability Company Mvm Technology",
        "limited_liability_company_vladinfo": "Limited Liability Company Vladinfo",
        "limited_liability_company_volgogaztelecom": "Limited Liability Company Volgogaztelecom",
        "lincoln_county_telephone_system_inc": "Lincoln County Telephone System Inc.",
        "lincoln_intermediate_unit_12": "Lincoln Intermediate Unit 12",
        "lincoln_university": "Lincoln University",
        "linea_com_s_r_l": "Linea Com S R L",
        "linejet_servios_de_telecomunicaes_ltd": "Linejet Servios De Telecomunicaes Ltd",
        "linfield_college": "Linfield College",
        "link10_cataguases_net_ltda": "Link10 - Cataguases Net Ltda",
        "link3_technologies_ltd": "Link3 Technologies Ltd.",
        "link_brasil_comunicacao_multimidia_ltda_me": "Link Brasil Comunicacao Multimidia Ltda - Me",
        "link_egypt": "Link Egypt",
        "link_egypt_link_net": "Link Egypt Link.net",
        "link_ltd": "Link Ltd.",
        "link_solution_telecom_ltda": "Link Solution Telecom Ltda",
        "link_telecom_nz_limited": "Link Telecom Nz Limited",
        "link_telecomunicazioni_s_r_l": "Link Telecomunicazioni S.r.l.",
        "link_wap_telecomunicacoes_e_informatica_ltda": "Link Wap Telecomunicacoes E Informatica Ltda",
        "linkce_telecom": "Linkce Telecom",
        "linkdotnetjordan": "Linkdotnet-jordan",
        "linkem_spa": "Linkem Spa",
        "linkfort_telecom_ltda_me": "Linkfort Telecom Ltda Me",
        "linkmais_provedor_de_acesso_as_redes_de_com_ltda": "Linkmais Provedor De Acesso As Redes De Com. Ltda",
        "linktel_telecomunicacoes_do_brasil_ltda": "Linktel Telecomunicacoes Do Brasil Ltda",
        "linode_llc": "Linode Llc",
        "lintas_data_prima_pt": "Lintas Data Prima Pt",
        "lion_resources_inc": "Lion Resources Inc.",
        "lipetskie_kabelnie_seti_llc": "Lipetskie Kabelnie Seti Llc",
        "lipscomb_university": "Lipscomb University",
        "liquid_telecommunications_south_africa_pty_ltd": "Liquid Telecommunications South Africa Pty Ltd",
        "liquid_web_l_l_c": "Liquid Web L.l.c",
        "lisco": "Lisco",
        "litestream_holdings_llc": "Litestream Holdings Llc.",
        "litewire_internet_services_inc": "Litewire Internet Services Inc.",
        "little_apple_technologies": "Little Apple Technologies",
        "live_telecom": "Live Telecom",
        "livecom_serv_e_com_de_equipamentos_de_inf": "Livecom Serv E Com De Equipamentos De Inf",
        "liwest_kabelfernsehen_errichtungs_und_betriebs_ges_m_b_h": "Liwest Kabelfernsehen Errichtungs- Und Betriebs Ges.m.b.h.",
        "llc_citytelekom": "Llc City-telekom",
        "llc_dagsvyaz_telecom": "Llc Dagsvyaz Telecom",
        "llc_gazprom_telecom": "Llc Gazprom Telecom",
        "llc_globaltelecomstroy": "Llc Globaltelecomstroy",
        "llc_komtehcentr": "Llc Komtehcentr",
        "llc_management_company_svyaz": "Llc Management Company Svyaz",
        "llc_mclautinvest": "Llc Mclaut-invest",
        "llc_multiscan": "Llc Multiscan",
        "llc_multiservice": "Llc Multiservice",
        "llc_murmansk_multiservice_networks": "Llc Murmansk Multiservice Networks",
        "llc_naukasvyaz": "Llc Nauka-svyaz",
        "llc_nfs_telecom_stupino_branch": "Llc Nfs Telecom - Stupino Branch",
        "llc_perepelka": "Llc Perepelka",
        "llc_realnet": "Llc Real-net",
        "llc_sip_nis": "Llc Sip Nis",
        "llc_skynet": "Llc Skynet",
        "llc_sp_abaza_telecom": "Llc Sp Abaza Telecom",
        "llc_telecom_mpk": "Llc Telecom Mpk",
        "llc_telematika": "Llc Telematika",
        "llc_tomtel": "Llc Tomtel",
        "llc_trc_fiord": "Llc Trc Fiord",
        "lleida_wifi_networks_sl": "Lleida Wifi Networks Sl",
        "lnp_servicos_de_telecomunicacoes_e_informatica_ltd": "Lnp Servicos De Telecomunicacoes E Informatica Ltd",
        "local_tv_and_electronics_inc": "Local Tv And Electronics Inc.",
        "locallink_telecomunicacoes_ltda_me": "Locallink Telecomunicacoes Ltda - Me",
        "localnet_network": "Localnet Network",
        "localtel_communications": "Localtel Communications",
        "locaweb_servicos_de_internet_sa": "Locaweb Servicos De Internet Sa",
        "lockheed_martin_corporation": "Lockheed Martin Corporation",
        "loclnet_inc": "Locl-net Inc",
        "loews_hotels_at_universal_orlando": "Loews Hotels At Universal Orlando",
        "logicalis_uk_limited": "Logicalis Uk Limited",
        "logicweb_inc": "Logicweb Inc.",
        "loginnet_provedores_ltda_me": "Loginnet Provedores Ltda Me",
        "logitel_ltd": "Logitel Ltd.",
        "logix": "Logix",
        "lognet": "Lognet",
        "logon_broadband": "Logon Broadband",
        "loma_linda_university_medical_center": "Loma Linda University Medical Center",
        "london_borough_of_hackney": "London Borough Of Hackney",
        "london_grid_for_learning_trust": "London Grid For Learning Trust",
        "london_south_bank_university": "London South Bank University",
        "lone_star_college_system_district": "Lone Star College System District",
        "lonestar_communications_corporation": "Lonestar Communications Corporation",
        "long_island_university": "Long Island University",
        "long_lines_internet": "Long Lines Internet",
        "longmont_power_communications": "Longmont Power Communications",
        "longwood_medical_and_academic_area_lma": "Longwood Medical And Academic Area Lma",
        "lonsdale_telephone_company_inc": "Lonsdale Telephone Company Inc",
        "look_communications_inc": "Look Communications Inc.",
        "loop_internet": "Loop Internet",
        "lorca_tv_sol_s_l": "Lorca Tv Sol S.l.",
        "los_angeles_county_office_of_education": "Los Angeles County Office Of Education",
        "los_angeles_sheriffs_department": "Los Angeles Sheriff's Department",
        "los_angeles_unified_school_district": "Los Angeles Unified School District",
        "los_nettos": "Los Nettos",
        "lothian_broadband_limited": "Lothian Broadband Limited",
        "louisiana_board_of_regentslouisiana_optical_network_initiative_loni": "Louisiana Board Of Regentslouisiana Optical Network Initiative Loni",
        "louisiana_state_university": "Louisiana State University",
        "lousiana_tech_university": "Lousiana Tech University",
        "louzeiro_morais_ltda": "Louzeiro Morais Ltda",
        "lowe_s_companies_inc": "Lowe S Companies Inc.",
        "loyola_marymount_university": "Loyola Marymount University",
        "loyola_university_chicago": "Loyola University Chicago",
        "loyola_university_new_orleans": "Loyola University New Orleans",
        "ls_fibernet_private_limited": "Ls Fibernet Private Limited",
        "ls_networks": "Ls Networks",
        "ltd_atelrybinsk": "Ltd Atelrybinsk",
        "ltd_broadband_llc": "Ltd Broadband Llc",
        "ltd_cgc_co": "Ltd Cgc Co",
        "ltd_elektrosvyaz": "Ltd Elektrosvyaz",
        "ltd_novorossiysk_telecom_company": "Ltd Novorossiysk Telecom Company",
        "lubbock_independent_school_district": "Lubbock Independent School District",
        "lucile_salter_packard_childrens_hospital_at_stanford": "Lucile Salter Packard Children's Hospital At Stanford",
        "ludwigmaximiliansuniversitaet_muenchen": "Ludwig-maximilians-universitaet Muenchen",
        "lukjanova_lydia_andreevna_pe": "Lukjanova Lydia Andreevna Pe",
        "luminet_data_limited": "Luminet Data Limited",
        "lumos_networks_inc": "Lumos Networks Inc.",
        "lunar_pages": "Lunar Pages",
        "luther_college": "Luther College",
        "luxembourg_online_s_a": "Luxembourg Online S.a.",
        "lvt_tecnologia": "Lvt Tecnologia",
        "lvt_telecom": "Lvt Telecom",
        "lycamobile_pty_ltd": "Lycamobile Pty Ltd",
        "lycatel_distribution_uk_limited": "Lycatel Distribution Uk Limited",
        "lynet_internett_as": "Lynet Internett As",
        "lyttonnet": "Lyttonnet",
        "m1_connect_pte_ltd": "M1 Connect Pte Ltd",
        "m1_limited": "M1 Limited",
        "m247_ltd": "M247 Ltd",
        "m3_wireless_inc": "M3 Wireless Inc.",
        "m9com_ltd": "M9com Ltd.",
        "m_h_sat_servicos_em_telecomunicacoes": "M H Sat Servicos Em Telecomunicacoes",
        "m_j_ventura_ltda": "M.j. Ventura Ltda",
        "m_n_redes_de_comunicacoes_ltda": "M. N. Redes De Comunicacoes Ltda.",
        "macomb_intermediate_school_district": "Macomb Intermediate School District",
        "mad_city_broadband_inc": "Mad City Broadband Inc.",
        "madeit_inc": "Madeit Inc.",
        "madgenius_com": "Madgenius.com",
        "madison_county_telephone_company": "Madison County Telephone Company",
        "madison_telephone_company": "Madison Telephone Company",
        "madisononeida_boces": "Madison-oneida Boces",
        "mads_provedor_de_acesso_de_telecomunicacoes": "Mads Provedor De Acesso De Telecomunicacoes",
        "maginfo_cjsc": "Maginfo Cjsc",
        "magix_broadband_network": "Magix Broadband Network",
        "magna_international_inc": "Magna International Inc.",
        "magyar_telekom_plc": "Magyar Telekom Plc.",
        "maha_mediacom_llp": "Maha Mediacom Llp",
        "mahanagar_telephone_nigam_ltd": "Mahanagar Telephone Nigam Ltd.",
        "mahataa_information_india_private_limited": "Mahataa Information India Private Limited",
        "main_line_health_inc": "Main Line Health Inc.",
        "maine_librariesdept_of_education": "Maine Librariesdept. Of Education",
        "mais_net_tecnologia_integrada": "Mais Net Tecnologia Integrada",
        "maks_communications_pvt_ltd": "Maks Communications Pvt. Ltd.",
        "maksim_co_ltd": "Maksim Co. Ltd.",
        "malnet_ltd": "Malnet Ltd.",
        "man_net_ltd": "Man Net Ltd.",
        "managed_network_solutions_inc": "Managed Network Solutions Inc.",
        "managed_network_systems_inc": "Managed Network Systems Inc.",
        "managedway": "Managedway",
        "manda_de_gmbh": "Man-da.de Gmbh",
        "mandarin_wimax_sicilia_spa": "Mandarin Wimax Sicilia Spa",
        "manhattan_college": "Manhattan College",
        "manifold_services_inc": "Manifold Services Inc",
        "manquehuenet": "Manquehuenet",
        "maquoketa_valley_electric_cooperative": "Maquoketa Valley Electric Cooperative",
        "mar_provedor_de_internet_ltda": "Mar Provedor De Internet Ltda",
        "marcatti_network_eireli": "Marcatti Network Eireli",
        "maria_lucia_pereira_de_souza_eirele_me": "Maria Lucia Pereira De Souza Eirele Me",
        "maria_luciana_machado_e_cia_ltdame": "Maria Luciana Machado E Cia Ltda-me",
        "maricopa_county_government": "Maricopa County Government",
        "marinter_telecom_ltda": "Marinter Telecom Ltda.",
        "marist_college": "Marist College",
        "mark_twain_rural_telephone_co": "Mark Twain Rural Telephone Co.",
        "markley_boston_llc": "Markley Boston Llc",
        "maroc_telecom": "Maroc Telecom",
        "marp_solution_provider": "Marp Solution Provider",
        "marquette_university": "Marquette University",
        "marquetteadams_telephone_cooperative_inc": "Marquette-adams Telephone Cooperative Inc.",
        "marshall_university": "Marshall University",
        "marshfield_clinic_inc": "Marshfield Clinic Inc.",
        "marston_s_telecoms_limited": "Marston S Telecoms Limited",
        "martin_county_board_of_county_commissioners": "Martin County Board Of County Commissioners",
        "mary_washington_hospital_inc": "Mary Washington Hospital Inc.",
        "maryland_department_of_transportation": "Maryland Department Of Transportation",
        "masco_group": "Masco Group",
        "mascon_cable_systems": "Mascon Cable Systems",
        "masergy_communications": "Masergy Communications",
        "massachusetts_convention_center_authority": "Massachusetts Convention Center Authority",
        "massachusetts_higher_education_computer_network": "Massachusetts Higher Education Computer Network",
        "massachusetts_institute_of_technology": "Massachusetts Institute Of Technology",
        "massachusetts_maritime_academy": "Massachusetts Maritime Academy",
        "massillon_cable_communications": "Massillon Cable Communications",
        "massive_networks": "Massive Networks",
        "master_net_wireless_informatica_ltda": "Master Net Wireless Informatica Ltda",
        "mastercard_technologies_llc": "Mastercard Technologies Llc",
        "mattel": "Mattel",
        "mauritius_telecom": "Mauritius Telecom",
        "mawingu_networks_ltd": "Mawingu Networks Ltd",
        "max_telecomunicacoes_ltda_me": "Max Telecomunicacoes Ltda - Me",
        "maxcom_telecomunicaciones_s_a_b_de_c_v": "Maxcom Telecomunicaciones S.a.b. De C.v.",
        "maxen_technologies_s_l": "Maxen Technologies S.l.",
        "maxfon_campania_srl": "Maxfon Campania Srl",
        "maximatelecom_jsc": "Maximatelecom Jsc",
        "maxis_broadband_sdn_bhd": "Maxis Broadband Sdn Bhd",
        "maxnet_systems_ltd": "Maxnet Systems Ltd.",
        "maxtel_s_r_o": "Maxtel S.r.o.",
        "maxwire_inc": "Maxwire Inc.",
        "mbo_video_llc": "Mbo Video Llc",
        "mcafee_inc": "Mcafee Inc.",
        "mccarran_international_airport": "Mccarran International Airport",
        "mcclatchy_management_services_inc": "Mcclatchy Management Services Inc.",
        "mccooknet_internet_service": "Mccooknet Internet Service",
        "mcdaniel_college": "Mcdaniel College",
        "mcgill_university": "Mcgill University",
        "mcgrawhill_education": "Mcgraw-hill Education",
        "mci_communications_services_inc_dba_verizon_business": "Mci Communications Services Inc. Dba Verizon Business",
        "mcisae": "Mcisae",
        "mckesson_corp": "Mckesson Corp.",
        "mckinney_isd": "Mckinney Isd",
        "mckinsey_company_inc": "Mckinsey Company Inc.",
        "mclink_spa": "Mc-link Spa",
        "mcm_systems_llc": "Mcm Systems Llc",
        "mcnc": "Mcnc",
        "mcsnet": "Mcsnet",
        "md_brasil_tecnologia_da_informacao_ltda": "Md Brasil - Tecnologia Da Informacao Ltda",
        "mdc_datacenter_s_l": "Mdc Datacenter S.l.",
        "mdeca": "Mdeca",
        "mdlink_online_service_center_gmbh": "Mdlink Online Service Center Gmbh",
        "mdnx": "Mdnx",
        "media_antar_nusa_pt": "Media Antar Nusa Pt.",
        "media_commerce_partners_s_a": "Media Commerce Partners S.a",
        "media_ltd": "Media Ltd",
        "media_temple_inc": "Media Temple Inc.",
        "mediacom_communications_corp": "Mediacom Communications Corp",
        "mediaproduccion_sl": "Mediaproduccion Sl",
        "mediaring_communications_pte_ltd": "Mediaring Communications Pte Ltd",
        "mediaserv": "Mediaserv",
        "mediastream": "Mediastream",
        "mediateknik_i_varberg_ab": "Mediateknik I Varberg Ab",
        "medical_college_of_wisconsin": "Medical College Of Wisconsin",
        "medical_university_of_south_carolina": "Medical University Of South Carolina",
        "medicine_park_telephone_company": "Medicine Park Telephone Company",
        "meditelecom": "Meditelecom",
        "medstar_health": "Medstar Health",
        "medtronic_incorporated": "Medtronic Incorporated",
        "mega_cable_s_a_de_c_v": "Mega Cable S.a. De C.v.",
        "megalink_diana_a_de_oliveira_me": "Megalink - Diana A. De Oliveira Me",
        "megalink_ltd": "Megalink Ltd.",
        "megalink_servicos_ltda": "Megalink Servicos Ltda",
        "meganet_digital": "Meganet Digital",
        "meganet_provedor": "Meganet Provedor",
        "meganet_rj_informatica_e_telecomunicacoes_ltda": "Meganet Rj Informatica E Telecomunicacoes Ltda",
        "meganet_telecomunicacoes_e_internet_ltda": "Meganet Telecomunicacoes E Internet Ltda",
        "meganet_telecon_ltda": "Meganet Telecon Ltda",
        "megapath_corporation": "Megapath Corporation",
        "megapath_networks_inc": "Megapath Networks Inc.",
        "megatel": "Megatel",
        "megatelecom_telecomunicacoes_ltda": "Megatelecom Telecomunicacoes Ltda",
        "megavista_online_s_l": "Megavista Online S.l.",
        "megha_communications": "Megha Communications",
        "meghbela_broadband": "Meghbela Broadband",
        "meghbela_cable_broadband_services_p_ltd": "Meghbela Cable Broadband Services P Ltd",
        "meghbela_infitel_cable_and_broadband_private_limited": "Meghbela Infitel Cable And Broadband Private Limited",
        "meigsr_holdings_llc": "Mei-gsr Holdings Llc",
        "melita_limited": "Melita Limited",
        "melo_telecomunicacoes_ltda": "Melo Telecomunicacoes Ltda",
        "melsainet_as": "Melsa-i-net As",
        "memorial_hermann_health_system": "Memorial Hermann Health System",
        "memorial_medical_center": "Memorial Medical Center",
        "memorial_university_of_newfoundland": "Memorial University Of Newfoundland",
        "mentor_graphics_corporation": "Mentor Graphics Corporation",
        "meo": "Meo",
        "meramec_interactive_services_network": "Meramec Interactive Services Network",
        "merck_and_co_inc": "Merck And Co. Inc.",
        "merck_kgaa": "Merck Kgaa",
        "mercury_network_corporation": "Mercury Network Corporation",
        "mercury_wireless_llc": "Mercury Wireless Llc",
        "mercy_college": "Mercy College",
        "mercy_health_system": "Mercy Health System",
        "meridian_business_centers": "Meridian Business Centers",
        "meriplex_communications_ltd": "Meriplex Communications Ltd.",
        "merit_network_inc": "Merit Network Inc.",
        "mesh_net": "Mesh.net",
        "message_express_internet": "Message Express Internet",
        "messagelabs_inc": "Messagelabs Inc.",
        "metalink_technologies_inc": "Metalink Technologies Inc.",
        "methodist_hospital_of_indiana": "Methodist Hospital Of Indiana",
        "metranet_communications_ltd": "Metranet Communications Ltd",
        "metro_communications_company_inc": "Metro Communications Company Inc.",
        "metrofibrenetworx": "Metrofibre-networx",
        "metronet": "Metronet",
        "metronet_limited": "Metronet Limited",
        "metropolitan_educational_council": "Metropolitan Educational Council",
        "metropolitan_telecomm": "Metropolitan Telecomm",
        "metropolitan_washington_airports_authority": "Metropolitan Washington Airports Authority",
        "metroset_ltd": "Metroset Ltd.",
        "metrotel_sa_esp": "Metrotel Sa Esp",
        "mettlertoledo_inc": "Mettler-toledo Inc.",
        "mexico_red_de_telecomunicaciones_s_de_r_l_de_c_v": "Mexico Red De Telecomunicaciones S. De R.l. De C.v.",
        "meyer_telecom": "Meyer Telecom",
        "mezhdurechensk_factory_of_radio_equipment_repair_ltd": "Mezhdurechensk Factory Of Radio Equipment Repair Ltd",
        "mft_info_services_private_limited": "Mft Info Services Private Limited",
        "mg_telecom_ltda_me": "Mg Telecom Ltda Me",
        "mgm_resorts_international_operations_inc": "Mgm Resorts International Operations Inc.",
        "mgp_telecom": "Mgp Telecom",
        "mgw_telephone_company_inc": "Mgw Telephone Company Inc.",
        "mhnet_telecom": "Mhnet Telecom",
        "mho_networks": "Mho Networks",
        "mi_internet_spa": "Mi Internet Spa",
        "miami_dade_college": "Miami Dade College",
        "miami_university": "Miami University",
        "miami_valley_educational_computer_association": "Miami Valley Educational Computer Association",
        "mic_tanzania_ltd": "Mic Tanzania Ltd",
        "micfo_llc": "Micfo Llc.",
        "michigan_comnet_inc": "Michigan Comnet Inc",
        "michigan_technological_university": "Michigan Technological University",
        "michwave_technologies_inc": "Michwave Technologies Inc.",
        "micks_telecom_eireli": "Micks Telecom Eireli",
        "micron_line_servicos_de_informatica_ltda_me": "Micron Line Servicos De Informatica Ltda - Me",
        "micronet_ltd": "Micronet Ltd",
        "micronova_network_solutions_pvt_ltd": "Micronova Network Solutions Pvt. Ltd",
        "micropic_ltda": "Micropic Ltda",
        "microrcim_pro_net_do_brasil_informatica_ltda": "Microrcim Pro Net Do Brasil Informatica Ltda",
        "microscan_computers_private_limited": "Microscan Computers Private Limited",
        "microsense_private_limited": "Microsense Private Limited",
        "microsoft_corp": "Microsoft Corp",
        "microsoft_deutschland_mcio_gmbh": "Microsoft Deutschland Mcio Gmbh",
        "microsoft_limited": "Microsoft Limited",
        "micso_s_r_l": "Micso S.r.l.",
        "mid_century_communications": "Mid Century Communications",
        "midcontinent_communications": "Midcontinent Communications",
        "middle_east_internet_company_limited": "Middle East Internet Company Limited",
        "middle_michigan_network_for_educational_telecommunications": "Middle Michigan Network For Educational Telecommunications",
        "middle_tennessee_state_university": "Middle Tennessee State University",
        "middlebury_college": "Middlebury College",
        "midhudson_cablevision_inc": "Mid-hudson Cablevision Inc.",
        "midland_college": "Midland College",
        "midmaine_communications_inc": "Mid-maine Communications Inc.",
        "midrivers_telephone_cooperative": "Mid-rivers Telephone Cooperative",
        "midvale_telephone_exchange": "Midvale Telephone Exchange",
        "midwest_communications_inc": "Midwest Communications Inc.",
        "midwest_energy_communications": "Midwest Energy Communications",
        "midwest_fiber_networks_llc": "Midwest Fiber Networks Llc",
        "midwest_telecom_of_america_inc": "Midwest Telecom Of America Inc",
        "midwestern_litho_inc": "Midwestern Litho Inc.",
        "mie_university": "Mie University",
        "mikrotec_internet_services": "Mikrotec Internet Services",
        "milanin_net": "Milanin Net",
        "mile_high_networks_llc": "Mile High Networks Llc",
        "milecom_ltd": "Milecom Ltd",
        "milleni_com": "Milleni.com",
        "millennium_pharmaceuticals_inc": "Millennium Pharmaceuticals Inc.",
        "millennium_telcom_llc": "Millennium Telcom Llc",
        "millicom_chad_sa": "Millicom Chad Sa",
        "millicom_ghana_limited": "Millicom Ghana Limited",
        "millington_catv_inc": "Millington Catv Inc",
        "millington_telephone_company_inc": "Millington Telephone Company Inc.",
        "milliway_srl": "Milliway Srl",
        "millry_telephone_co_inc": "Millry Telephone Co Inc.",
        "mills_college": "Mills College",
        "milwaukee_area_technical_college": "Milwaukee Area Technical College",
        "milwaukee_county_government": "Milwaukee County Government",
        "minas_info_ltdame": "Minas Info Ltda-me",
        "minas_net_ltda_me": "Minas Net Ltda Me",
        "minasnet_servicos_de_provedor_de_internet_ltda": "Minasnet Servicos De Provedor De Internet Ltda",
        "mindshift_technologies": "Mindshift Technologies",
        "ministry_of_education_computer_center": "Ministry Of Education Computer Center",
        "ministry_of_internal_affairs": "Ministry Of Internal Affairs",
        "minnesota_state_colleges_and_universities": "Minnesota State Colleges And Universities",
        "minnesota_wifi": "Minnesota Wifi",
        "mirai_communication_network_inc": "Mirai Communication Network Inc.",
        "mirandamedia_ltd": "Miranda-media Ltd",
        "mismenet_telecomunicaciones_s_l": "Mismenet Telecomunicaciones S.l.",
        "mississippi_department_of_information_technology_services_its": "Mississippi Department Of Information Technology Services Its",
        "mississippi_state_university": "Mississippi State University",
        "missouri_network_alliance_llc": "Missouri Network Alliance Llc",
        "missouri_university_of_science_and_technology": "Missouri University Of Science And Technology",
        "mitchell_seaforth_cable_tv": "Mitchell Seaforth Cable Tv",
        "mixed_signal_solutions": "Mixed Signal Solutions",
        "mk_netzdienste_gmbh_co_kg": "Mk Netzdienste Gmbh Co. Kg",
        "mma_acessorios_e_servicos_de_informatica_ltda": "Mma Acessorios E Servicos De Informatica Ltda.",
        "mmer_provedor_de_internet_ltda_me": "Mmer Provedor De Internet Ltda - Me",
        "mnc_playmedia": "Mnc Playmedia",
        "mnet_s_r_l": "Mnet S.r.l",
        "mnet_telekommunikations_gmbh": "M-net Telekommunikations Gmbh",
        "mnr_broadband_services_pvt_ltd": "Mnr Broadband Services Pvt. Ltd.",
        "mob_servicos_de_telecomunicacoes_ltda": "Mob Servicos De Telecomunicacoes Ltda",
        "mobicom_corporation": "Mobicom Corporation",
        "mobile_communication_company_of_iran_plc": "Mobile Communication Company Of Iran Plc",
        "mobile_telecommunications_company": "Mobile Telecommunications Company",
        "mobilink": "Mobilink",
        "mobinil": "Mobinil",
        "mobitel_pvt_ltd": "Mobitel Pvt Ltd",
        "mobius_communications_co": "Mobius Communications Co.",
        "mobiwalkers": "Mobiwalkers",
        "modus_llc": "Modus Llc",
        "molalla_communications_systems_inc": "Molalla Communications Systems Inc.",
        "moldcell_s_a": "Moldcell S.a.",
        "molina_healthcare_inc": "Molina Healthcare Inc.",
        "monad_digitnamic_corp": "Monad Digitnamic Corp.",
        "monash_university": "Monash University",
        "monash_university_sunway_campus_malaysia": "Monash University Sunway Campus Malaysia",
        "moncre_telephone_cooperative_inc": "Mon-cre Telephone Cooperative Inc.",
        "monkey_brains": "Monkey Brains",
        "monroe_#1_boces": "Monroe #1 Boces",
        "monroe_community_college": "Monroe Community College",
        "monsanto": "Monsanto",
        "monster_broadband_inc": "Monster Broadband Inc.",
        "montana_digital_llc": "Montana Digital Llc.",
        "montana_internet_corporation": "Montana Internet Corporation",
        "montana_sky_networks_inc": "Montana Sky Networks Inc.",
        "montana_state_university": "Montana State University",
        "montana_west_l_l_c": "Montana West L.l.c.",
        "montclair_state_university": "Montclair State University",
        "montgomery_county_community_college": "Montgomery County Community College",
        "montgomery_county_government_maryland": "Montgomery County Government Maryland",
        "montgomery_county_intermediate_unit": "Montgomery County Intermediate Unit",
        "montgomery_county_public_schools": "Montgomery County Public Schools",
        "monzoon_networks_ag": "Monzoon Networks Ag",
        "moodys_investors_service": "Moody's Investors Service",
        "morgan_state_university": "Morgan State University",
        "morris_broadband_llc": "Morris Broadband Llc",
        "morristown_utility_systems": "Morristown Utility Systems",
        "mosaic_telecom": "Mosaic Telecom",
        "mosinter_ltd": "Mosinter Ltd.",
        "motorola_inc": "Motorola Inc.",
        "moundridge_telephone_co": "Moundridge Telephone Co.",
        "mount_allison_university": "Mount Allison University",
        "mount_horeb_telephone_co": "Mount Horeb Telephone Co",
        "mount_saint_mary_college": "Mount Saint Mary College",
        "mount_sinai_school_of_medicine": "Mount Sinai School Of Medicine",
        "mountain_telephone": "Mountain Telephone",
        "mountain_west_technologies_corporation": "Mountain West Technologies Corporation",
        "mountainet": "Mountainet",
        "movicel_telecomunicacoes_s_a": "Movicel Telecomunicacoes S.a.",
        "movistar_chile": "Movistar Chile",
        "movistar_colombia": "Movistar Colombia",
        "movistar_ecuador": "Movistar Ecuador",
        "movistar_el_salvador": "Movistar El Salvador",
        "movistar_mexico": "Movistar Mexico",
        "movistar_venezuela": "Movistar Venezuela",
        "movitel_s_a": "Movitel S.a.",
        "mowna_optical_fiber_network": "Mowna Optical Fiber Network",
        "mpinet": "Mpinet",
        "mpower_communications_corp": "Mpower Communications Corp.",
        "mq_telecom_ltda_me": "Mq Telecom Ltda - Me",
        "mr_net_services_gmbh_co_kg": "Mr. Net Services Gmbh Co. Kg",
        "mst_acquisition_group_llc": "Mst Acquisition Group Llc.",
        "mt_internet_comercio_servico_ltda": "Mt Internet Comercio Servico Ltda",
        "mt_net_servicos_de_internet_ltdame": "Mt.net - Servicos De Internet Ltda-me",
        "mta_solutions": "Mta Solutions",
        "mtc_broadband_inc": "Mtc Broadband Inc.",
        "mtc_ksa": "Mtc Ksa",
        "mtc_namibia": "Mtc Namibia",
        "mtco_communications": "Mtco Communications",
        "mtd_products_inc": "Mtd Products Inc",
        "mtn_congo": "Mtn Congo",
        "mtn_cote_divoire_sa": "Mtn Cote Divoire Sa",
        "mtn_nigeria": "Mtn Nigeria",
        "mtn_ns_cameroon": "Mtn Ns Cameroon",
        "mtn_rwandacell": "Mtn Rwandacell",
        "mtn_south_africa": "Mtn South Africa",
        "mtn_south_sudan": "Mtn South Sudan",
        "mtn_uganda": "Mtn Uganda",
        "mtn_zambia": "Mtn Zambia",
        "mtpcs_llc": "Mtpcs Llc",
        "mts_inc": "Mts Inc.",
        "mts_ojsc": "Mts Ojsc",
        "mtsac": "Mtsac",
        "mtt_connect_ltd": "Mtt Connect Ltd.",
        "muenet_gmbh": "Muenet Gmbh",
        "muhlenberg_college": "Muhlenberg College",
        "mulberry_telecommunications": "Mulberry Telecommunications",
        "multacom_corporation": "Multacom Corporation",
        "multicanal_del_cable_tvm_sl": "Multicanal Del Cable Tvm Sl",
        "multimedia_polska_s_a": "Multimedia Polska S.a.",
        "multimedia_wschod_sp_z_o_o": "Multimedia Wschod Sp. Z O.o.",
        "multinet_pakistan_pvt_ltd": "Multinet Pakistan Pvt. Ltd.",
        "multinet_udaipur_private_limited": "Multinet Udaipur Private Limited",
        "multipla_servicos_inteligentes": "Multipla Servicos Inteligentes",
        "multiplic_comunicacao_e_tecnologia_ltda_me": "Multiplic Comunicacao E Tecnologia Ltda -me",
        "multistream_ltd": "Multistream Ltd.",
        "multitel_comunicacoes_ltda": "Multitel Comunicacoes Ltda",
        "multivelox_servicos_de_provedor_de_acesso_a_intern": "Multivelox Servicos De Provedor De Acesso A Intern",
        "multnomah_education_service_district": "Multnomah Education Service District",
        "mundivox_ltda": "Mundivox Ltda",
        "mup_center_of_teleradio_company_odintsovo": "Mup Center Of Teleradio Company Odintsovo",
        "murphy_cable_tv": "Murphy Cable Tv",
        "muscatine_power_and_water": "Muscatine Power And Water",
        "mutual_telephone_company": "Mutual Telephone Company",
        "mv_telecom": "Mv Telecom",
        "mvtv_wireless": "Mvtv Wireless",
        "mweb": "Mweb",
        "my_server_planet_llc": "My Server Planet Llc",
        "my_tech_bz": "My Tech Bz",
        "myakka_technologies_inc": "Myakka Technologies Inc.",
        "myanmar_post_and_telecommunication": "Myanmar Post And Telecommunication",
        "myhosting": "Myhosting",
        "myloc_managed_it_ag": "Myloc Managed It Ag",
        "mynet_gmbh": "Mynet Gmbh",
        "myrepublic_limited": "Myrepublic Limited",
        "myrepublic_pty_ltd": "Myrepublic Pty Ltd",
        "myserver_org_inc": "Myserver.org Inc.",
        "n_c_sat_mais_veloz_ltda": "N.c Sat Mais Veloz Ltda",
        "n_c_servicos_de_comunicacao_multimidia_ltda_me": "N C Servicos De Comunicacao Multimidia Ltda - Me",
        "n_t_e_limited": "N.t.e. Limited",
        "namecheap_inc": "Namecheap Inc.",
        "nanyang_polytechnic": "Nanyang Polytechnic",
        "nanyang_technological_university": "Nanyang Technological University",
        "napanet": "Napanet",
        "nassau_community_college": "Nassau Community College",
        "nassau_county_boces": "Nassau County Boces",
        "natel_telecom_ltda_me": "Natel Telecom Ltda. - Me",
        "national_academic_network_and_information_center": "National Academic Network And Information Center",
        "national_aeronautics_and_space_administration": "National Aeronautics And Space Administration",
        "national_agency_of_public_registry": "National Agency Of Public Registry",
        "national_archives_and_records_administration": "National Archives And Records Administration",
        "national_cancer_institutefrederick_cancer_research": "National Cancer Institutefrederick Cancer Research",
        "national_center_for_atmospheric_research": "National Center For Atmospheric Research",
        "national_health_service": "National Health Service",
        "national_institutes_of_health": "National Institutes Of Health",
        "national_internet_backbone": "National Internet Backbone",
        "national_knowledge_network": "National Knowledge Network",
        "national_mobile_telecommunications_company": "National Mobile Telecommunications Company",
        "nationstar_mortgage_llc": "Nationstar Mortgage Llc",
        "natural_wireless_llc": "Natural Wireless Llc",
        "naukowa_i_akademicka_siec_komputerowa_instytut_badawczy": "Naukowa I Akademicka Siec Komputerowa Instytut Badawczy",
        "navajo_tribal_utility_authority": "Navajo Tribal Utility Authority",
        "naveganet_comercio_e_servicos_ltda": "Naveganet Comercio E Servicos Ltda",
        "navetech_telecom": "Navetech Telecom",
        "navy_network_information_center": "Navy Network Information Center",
        "nbs": "Nbs",
        "ncell_private_ltd": "Ncell Private Ltd.",
        "nci_data_com_inc": "Nci Data.com Inc.",
        "ncr_corporation": "Ncr Corporation",
        "ncs_technologies": "Ncs Technologies",
        "ndc_telecomunicacoes_ltda": "Ndc Telecomunicacoes Ltda.",
        "ne_colorado_cellular_inc": "Ne Colorado Cellular Inc",
        "nebonet_com": "Nebonet.com",
        "nebraskalink": "Nebraskalink",
        "nec_corporation": "Nec Corporation",
        "neckarcom_telekommunikation_gmbh": "Neckarcom Telekommunikation Gmbh",
        "nedetel_s_a": "Nedetel S.a.",
        "nelsonville_tv_cable_inc": "Nelsonville Tv Cable Inc",
        "nemesis_provedor_de_acesso_as_redes_de_comunicacao": "Nemesis Provedor De Acesso As Redes De Comunicacao",
        "nemont": "Nemont",
        "neorede_telecomunicacao_eireli_epp": "Neorede Telecomunicacao Eireli - Epp",
        "neosky_2002_s_a": "Neo-sky 2002 S.a.",
        "neotelmkd_autonomous_system": "Neotel-mkd Autonomous System",
        "nepal_telecommunications_corporation": "Nepal Telecommunications Corporation",
        "nessus_gmbh": "Nessus Gmbh",
        "net1_ltd": "Net1 Ltd.",
        "net3_inc": "Net3 Inc.",
        "net_360_s_a_r_l": "Net 360 S.a.r.l",
        "net_4_u_services_pvt_ltd": "Net 4 U Services Pvt Ltd",
        "net_access_corporation": "Net Access Corporation",
        "net_alternativa_provedor_de_internet_ltda_me": "Net Alternativa Provedor De Internet Ltda - Me",
        "net_at_once_sweden_ab": "Net At Once Sweden Ab",
        "net_barretos_tecnologia_ltda_me": "Net Barretos Tecnologia Ltda - Me",
        "net_by_net_holding_llc": "Net By Net Holding Llc",
        "net_global_srl": "Net Global Srl",
        "net_onze_provedor_de_acesso_a_internet_ltda": "Net Onze Provedor De Acesso A Internet Ltda",
        "net_rosas_telecomunicacoes_ltda": "Net Rosas Telecomunicacoes Ltda.",
        "net_triangulo_telecom_ltda_me": "Net Triangulo Telecom Ltda - Me",
        "net_work_fiber_comercio_e_servicos_de_comunicacao": "Net Work Fiber Comercio E Servicos De Comunicacao",
        "netactuate_inc": "Netactuate Inc",
        "netart_group_s_r_o": "Netart Group S.r.o.",
        "netblazr": "Netblazr",
        "netcar_internet_telec_info_e_tecnologia_ltda": "Netcar Internet Telec Info E Tecnologia Ltda",
        "netcarrier_inc": "Netcarrier Inc.",
        "netcity_tecnologia_em_internet_ltdame": "Netcity Tecnologia Em Internet Ltda-me",
        "netcologne_gmbh": "Netcologne Gmbh",
        "netcom_enterprises_pvt_ltd": "Netcom Enterprises Pvt Ltd",
        "netcom_kassel_gmbh": "Netcom Kassel Gmbh",
        "netcom_servicos_de_informatica_e_telecomunicacoes": "Netcom Servicos De Informatica E Telecomunicacoes",
        "netcomet_comercio_e_servicos_ltda": "Netcomet Comercio E Servicos Ltda.",
        "netcomr_llc": "Netcom-r Llc",
        "netease": "Netease",
        "neterra_ltd": "Neterra Ltd.",
        "netett_sverige_ab": "Netett Sverige Ab",
        "netfacil_internet_via_radio_e_informatica_ltda_m": "Netfacil Internet Via Radio E Informatica Ltda - M",
        "netfi_servicos_de_comunicacoes_ltda_epp": "Netfi Servicos De Comunicacoes Ltda Epp",
        "netfibra_telecomunicacoes_ltda_me": "Netfibra Telecomunicacoes Ltda - Me",
        "netflesh_telecom_e_com_de_prod_de_informat_ltdame": "Netflesh Telecom E Com De Prod De Informat Ltda-me",
        "netflix_inc": "Netflix Inc",
        "netflix_streaming_services_inc": "Netflix Streaming Services Inc.",
        "netgroup_as": "Netgroup As",
        "netico_s_c": "Netico S.c.",
        "netjacarei_telecon_ltda": "Netjacarei Telecon Ltda",
        "netline_telecom": "Netline Telecom",
        "netllar_sl": "Netllar Sl",
        "netmagic_datacenter_mumbai": "Netmagic Datacenter Mumbai",
        "netnet": "Netnet",
        "netnitco": "Netnitco",
        "netnt_sistemas_e_informatica_ltda": "Netnt Sistemas E Informatica Ltda",
        "netpeu_tecnologia_e_internet_ltda": "Netpeu Tecnologia E Internet Ltda",
        "netplus_broadband_services_private_limited": "Netplus Broadband Services Private Limited",
        "netplus_ch_sa": "Netplus.ch Sa",
        "netrail_inc": "Netrail Inc.",
        "netspeed_data_ltd": "Netspeed Data Ltd",
        "netspeed_internet_communications": "Netspeed Internet Communications",
        "netspeed_ltda": "Netspeed Ltda",
        "netstack_ltd": "Netstack Ltd",
        "netstar_solucoes_ltda": "Netstar Solucoes Ltda",
        "netstream_ag": "Netstream Ag",
        "netstyle_atarim_ltd": "Net-style Atarim Ltd",
        "netsul_internet_banda_larga_ltda": "Netsul Internet Banda Larga Ltda",
        "nettel_telecomunicacoes_ltda": "Nettel Telecomunicacoes Ltda.",
        "netwalk_telecomunicacoes_em_inf_ltda": "Netwalk Telecomunicacoes Em Inf. Ltda",
        "netwave_telecomunicacoes_ltda": "Netwave Telecomunicacoes Ltda.",
        "netwest_online_inc": "Netwest Online Inc.",
        "netwise_informatica_ltda": "Netwise Informatica Ltda",
        "network_and_information_technology_limited": "Network And Information Technology Limited",
        "network_billing_systems_llc": "Network Billing Systems Llc",
        "network_business_systems_inc": "Network Business Systems Inc.",
        "network_communications": "Network Communications",
        "network_for_education_and_research_in_oregon_nero": "Network For Education And Research In Oregon Nero",
        "network_informatica_ltda_me": "Network Informatica Ltda. - Me",
        "network_innovations_inc": "Network Innovations Inc.",
        "network_ip": "Network Ip",
        "network_lubbock_inc": "Network Lubbock Inc.",
        "network_nebraska": "Network Nebraska",
        "network_services": "Network Services",
        "network_technology_aust_pl": "Network Technology Aust Pl",
        "network_telecomunicacoes_ltda": "Network Telecomunicacoes Ltda",
        "network_transit_holdings_llc": "Network Transit Holdings Llc",
        "networks_online": "Networks On-line",
        "networld_provedor_e_servicos_de_internet_ltda": "Networld Provedor E Servicos De Internet Ltda",
        "neutral_data_centers_corp": "Neutral Data Centers Corp.",
        "nevada_system_of_higher_education": "Nevada System Of Higher Education",
        "nevalink_ltd": "Nevalink Ltd",
        "new_century_infocomm_tech_co_ltd": "New Century Infocomm Tech. Co. Ltd.",
        "new_dream_network_llc": "New Dream Network Llc",
        "new_florence_telephone_company": "New Florence Telephone Company",
        "new_frontiers_internet_services": "New Frontiers Internet Services",
        "new_hope_telephone_cooperative": "New Hope Telephone Cooperative",
        "new_jersey_institute_of_technology": "New Jersey Institute Of Technology",
        "new_knoxville_telephone_company": "New Knoxville Telephone Company",
        "new_life_telecom": "New Life Telecom",
        "new_media_co_ltd": "New Media Co. Ltd",
        "new_mexico_lambda_rail_inc": "New Mexico Lambda Rail Inc.",
        "new_skies_satellites_inc": "New Skies Satellites Inc.",
        "new_source_broadband": "New Source Broadband",
        "new_wave_communications": "New Wave Communications",
        "new_york_city_board_of_education": "New York City Board Of Education",
        "new_york_city_health_hospitals_corporation": "New York City Health Hospitals Corporation",
        "new_york_city_transit_authority": "New York City Transit Authority",
        "new_york_daily_news_l_p": "New York Daily News L.p.",
        "new_york_state": "New York State",
        "new_york_state_unified_court_system": "New York State Unified Court System",
        "new_york_university": "New York University",
        "newbreak_management_co_llc": "Newbreak Management Co. Llc",
        "newcore_wireless_llc": "Newcore Wireless Llc",
        "newellrubbermaid_inc": "Newellrubbermaid Inc.",
        "newone_gmbh": "Newone Gmbh",
        "newroz_telecom_ltd": "Newroz Telecom Ltd.",
        "news_and_entertainment_network_corp": "News And Entertainment Network Corp",
        "newyorkpresbyterian_hospital": "Newyork-presbyterian Hospital",
        "nexband_communications_inc": "Nexband Communications. Inc.",
        "nexeon_technologies_inc": "Nexeon Technologies Inc.",
        "nexgenaccess_inc": "Nexgenaccess Inc.",
        "nexicom_inc": "Nexicom Inc.",
        "nexnett_brasil_telecom": "Nexnett Brasil Telecom",
        "nextech_wireless_llc": "Nex-tech Wireless Llc",
        "nextel_telecomunicacoes_ltda": "Nextel Telecomunicacoes Ltda",
        "nextgen_networks": "Nextgen Networks",
        "nextgentel_as": "Nextgentel As",
        "nextlink_broadband": "Nextlink Broadband",
        "nextra_teleservices_pvt_ltd": "Nextra Teleservices Pvt. Ltd.",
        "nextweb_inc": "Nextweb Inc",
        "ngi_spa": "Ngi Spa",
        "niagara_regional_broadband_networks_limited": "Niagara Regional Broadband Networks Limited",
        "niagara_university": "Niagara University",
        "nianet_as": "Nianet As",
        "night_owl_wireless_llc": "Night Owl Wireless Llc",
        "nihon_network_service_co_ltd": "Nihon Network Service Co .ltd.",
        "nike_inc": "Nike Inc.",
        "nile_online": "Nile Online",
        "ningxia_west_cloud_data_technology_co_ltd": "Ningxia West Cloud Data Technology Co.ltd.",
        "nippon_telegraph_and_telephone_east_corporation": "Nippon Telegraph And Telephone East Corporation",
        "niqturbo_pimentel_e_moreira_ltda": "Niqturbo Pimentel E Moreira Ltda",
        "nisatel_sh_p_k": "Nisatel Sh.p.k.",
        "nishiowari_catv_corporation": "Nishiowari Catv Corporation",
        "niss_internet_services_private_limited": "Niss Internet Services Private Limited",
        "nizhnetagilskie_kompyuternye_seti_llc": "Nizhnetagilskie Kompyuternye Seti Llc",
        "njedge_net_inc": "Njedge.net Inc.",
        "nkn_core_network": "Nkn Core Network",
        "nlayer_communications_inc": "Nlayer Communications Inc.",
        "nmax": "Nmax",
        "nmultimidia_telecomunicacoes_ltda_me": "N-multimidia Telecomunicacoes Ltda - Me",
        "nobis_technology_group_llc": "Nobis Technology Group Llc",
        "nobreak_net_ltda_m_e": "Nobreak Net Ltda - M.e.",
        "noc4hosts_inc": "Noc4hosts Inc.",
        "node1_internet": "Node1 Internet",
        "nodex_fiber_optic_network_saintpetersburg_russia": "Nodex Fiber Optic Network Saint-petersburg Russia",
        "nodosud_s_a": "Nodosud S.a",
        "noel_communications_inc": "Noel Communications Inc.",
        "noida_software_technology_park_ltd": "Noida Software Technology Park Ltd",
        "nokia_corporation": "Nokia Corporation",
        "nomura_holding_america_inc": "Nomura Holding America Inc",
        "non_state_educational_institution_educational_scientific_and_experimental_center_of_moscow_institute_of_physics_and_technology": "Non State Educational Institution Educational Scientific And Experimental Center Of Moscow Institute Of Physics And Technology",
        "nordmore_energiverk_as": "Nordmore Energiverk As",
        "nordstrom_inc": "Nordstrom Inc.",
        "nordwest_link_np": "Nord-west Link Np",
        "norilsktelecom_jsc": "Norilsk-telecom Jsc",
        "nornett_as": "Nornett As",
        "norte_com_telecomunicacoes_ltda_me": "Norte.com Telecomunicacoes Ltda Me",
        "norte_line_telecomunicacoes_ltda": "Norte Line Telecomunicacoes Ltda.",
        "norte_telecom_eireli_me": "Norte Telecom Eireli - Me",
        "norte_telecomunicacoes_servicos_de_internet_ltda": "Norte Telecomunicacoes Servicos De Internet Ltda",
        "nortex_communications_company": "Nortex Communications Company",
        "north_alabama_electric_cooperative": "North Alabama Electric Cooperative",
        "north_atlantic_internet_llc": "North Atlantic Internet Llc",
        "north_carolina_research_and_education_network": "North Carolina Research And Education Network",
        "north_carolina_state_university": "North Carolina State University",
        "north_carolina_wireless_llc": "North Carolina Wireless Llc",
        "north_central_college": "North Central College",
        "north_central_communications": "North Central Communications",
        "north_coast_wireless_communications": "North Coast Wireless Communications",
        "north_dakota_telephone_co": "North Dakota Telephone Co.",
        "north_east_independent_school_district": "North East Independent School District",
        "north_georgia_network_cooperative_inc": "North Georgia Network Cooperative Inc.",
        "north_kansas_city_hospital_auxiliary": "North Kansas City Hospital Auxiliary",
        "north_nova_cable_ltd": "North Nova Cable Ltd.",
        "north_star_information_hi_tech_ltd_co": "North Star Information Hi.tech Ltd. Co.",
        "north_state_telephone_co": "North State Telephone Co.",
        "northeast_dataa_network_pvt_ltd": "Northeast Dataa Network Pvt Ltd",
        "northeast_louisiana_telephone_company_inc": "Northeast Louisiana Telephone Company Inc.",
        "northeast_ohio_management_information_network": "Northeast Ohio Management Information Network",
        "northeast_ohio_network_for_education_technology": "Northeast Ohio Network For Education Technology",
        "northeast_regional_data_center": "Northeast Regional Data Center",
        "northeast_service_cooperative_asnnewmn": "Northeast Service Cooperative Asn-newmn",
        "northeast_texas_broadband_llc": "Northeast Texas Broadband Llc",
        "northeastern_university": "Northeastern University",
        "northern_arizona_university": "Northern Arizona University",
        "northern_arkansas_telephone_company": "Northern Arkansas Telephone Company",
        "northern_illinois_university": "Northern Illinois University",
        "northern_kentucky_university": "Northern Kentucky University",
        "northern_nevada_high_speed_llc": "Northern Nevada High Speed Llc",
        "northern_telephone_and_data_corp": "Northern Telephone And Data Corp",
        "northern_valley_communications_llc": "Northern Valley Communications Llc",
        "northerntel_limited_partnership": "Northerntel Limited Partnership",
        "northland_cable_television_inc": "Northland Cable Television Inc.",
        "northland_communications": "Northland Communications",
        "northland_communications_inc": "Northland Communications Inc.",
        "northrop_grumman_corp": "Northrop Grumman Corp.",
        "northshore_university_healthsystem": "Northshore University Healthsystem",
        "northwest_nexus_inc": "Northwest Nexus Inc.",
        "northwest_ohio_area_computer_services_cooperative": "Northwest Ohio Area Computer Services Cooperative",
        "northwest_ohio_computer_association": "Northwest Ohio Computer Association",
        "northwest_open_access_network": "Northwest Open Access Network",
        "northwest_regional_esd": "Northwest Regional Esd",
        "northwestel_inc": "Northwestel Inc.",
        "northwestern_memorial_hospital": "Northwestern Memorial Hospital",
        "northwestern_university": "Northwestern University",
        "norwood_light_broadband": "Norwood Light Broadband",
        "nos_acores_comunicacoes_s_a": "Nos Acores Comunicacoes S.a.",
        "nos_comunicacoes_s_a": "Nos Comunicacoes S.a.",
        "nossarede_telecom_ltda_me": "Nossarede Telecom Ltda Me",
        "nostravant_s_l_l": "Nostravant S.l.l.",
        "nour_communication_co_ltd_nournet": "Nour Communication Co.ltd - Nournet",
        "nova_net_telecomunicacoes_ltda": "Nova Net Telecomunicacoes Ltda",
        "nova_portonet_telecomunicacoes_ltda_me": "Nova Portonet Telecomunicacoes Ltda Me",
        "nova_rede_de_telecomunicacoes_ltda": "Nova Rede De Telecomunicacoes Ltda",
        "nova_scotia_department_of_education": "Nova Scotia Department Of Education",
        "nova_servicos_de_comunicacao_multimidia_ltda_me": "Nova Servicos De Comunicacao Multimidia Ltda Me",
        "nova_university": "Nova University",
        "novanet_telecomunicacao_ltda": "Novanet Telecomunicacao Ltda",
        "novatel_eood": "Novatel Eood",
        "novaya_sibir_plus_ltd": "Novaya Sibir Plus Ltd.",
        "novoe_kabelnoe_television_ltd": "Novoe Kabelnoe Television Ltd.",
        "novokuznetsk_telecom_ltd": "Novokuznetsk Telecom Ltd",
        "novotelecom_ltd": "Novotelecom Ltd",
        "novus_entertainment_inc": "Novus Entertainment Inc.",
        "now": "Now",
        "nowo_communications_s_a": "Nowo Communications S.a.",
        "nr_telecom_eireli_me": "Nr Telecom Eireli - Me",
        "nrg_energy_inc": "Nrg Energy Inc.",
        "nrtc": "Nrtc",
        "nsk_co_ltd": "Nsk Co. Ltd.",
        "nss_s_a": "Nss S.a.",
        "nt_brasil_tecnologia_ltda_me": "Nt Brasil Tecnologia Ltda. Me",
        "nte_bredband_as": "Nte Bredband As",
        "nts_communications": "Nts Communications",
        "nts_connections_llc": "Nts Connections Llc",
        "ntt": "Ntt",
        "ntt_america_inc": "Ntt America Inc.",
        "ntt_communications_corporation": "Ntt Communications Corporation",
        "ntt_data_services_holdings_corporation": "Ntt Data Services Holdings Corporation",
        "ntt_docomo_inc": "Ntt Docomo Inc.",
        "ntt_europe_limited": "Ntt Europe Limited",
        "ntt_pc_communications_inc": "Ntt Pc Communications Inc.",
        "ntt_west_chugoku_corporation": "Ntt West Chugoku Corporation",
        "nttme_corporation": "Ntt-me Corporation",
        "nu_informationssysteme_gmbh": "Nu Informationssysteme Gmbh",
        "nucleo_s_a": "Nucleo S.a.",
        "nuevatel_pcs_de_bolivia_s_a": "Nuevatel Pcs De Bolivia S.a.",
        "nurtelecom_llc": "Nurtelecom Llc.",
        "nuskope_pty_ltd": "Nuskope Pty. Ltd.",
        "nuvera": "Nuvera",
        "nynex_satellite_ohg": "Nynex Satellite Ohg",
        "o2_germany_gmbh_co_ohg": "O2 Germany Gmbh Co.ohg",
        "o3b_limited": "O3b Limited",
        "oac_internet_e_informatica": "Oac Internet E Informatica",
        "oacys_technology": "Oacys Technology",
        "oak_ridge_national_laboratory": "Oak Ridge National Laboratory",
        "oakland_schools": "Oakland Schools",
        "oakland_unified_school_district": "Oakland Unified School District",
        "oao_asvt": "Oao Asvt",
        "oao_chitatehenergo": "Oao Chitatehenergo",
        "oarnet": "Oarnet",
        "oberlin_college": "Oberlin College",
        "obihiro_city_cable_co_ltd": "Obihiro City Cable Co. Ltd.",
        "obti_operadora": "Obti Operadora",
        "obyedinyonniye_lokalniye_seti_ltd": "Obyedinyonniye Lokalniye Seti Ltd.",
        "ocala_electric_utility": "Ocala Electric Utility",
        "occidental_college": "Occidental College",
        "occidental_petroleum_corporation": "Occidental Petroleum Corporation",
        "occino_gmbh": "Occino Gmbh",
        "oceans_network_s_l": "Oceans Network S.l.",
        "ochsner_clinic_foundation": "Ochsner Clinic Foundation",
        "ocm_boces": "Ocm Boces",
        "octopusnet_ltd": "Octopusnet Ltd",
        "ods_orbitnet": "Ods.orbitnet",
        "off_campus_telecommunications": "Off Campus Telecommunications",
        "office_depot": "Office Depot",
        "office_des_postes_et_telecommunications_newcaledonia": "Office Des Postes Et Telecommunications New-caledonia",
        "office_national_des_postes_et_telecommunications": "Office National Des Postes Et Telecommunications",
        "office_national_des_postes_et_telecommunications_onpt": "Office National Des Postes Et Telecommunications Onpt",
        "ogaki_cable_television_co_inc": "Ogaki Cable Television Co. Inc.",
        "ogden_city_corporation": "Ogden City Corporation",
        "oglethorpe_power_corporation": "Oglethorpe Power Corporation",
        "ogs_informatica_ltda_me": "Ogs Informatica Ltda - Me",
        "ohio_mid_eastern_regional_education_service_agency": "Ohio Mid Eastern Regional Education Service Agency",
        "ohio_public_library_information_network_oplin": "Ohio Public Library Information Network Oplin",
        "ohio_state_university": "Ohio State University",
        "ohio_university": "Ohio University",
        "ohiohealth_corporation": "Ohiohealth Corporation",
        "oita_cable_telecom_co_ltd": "Oita Cable Telecom Co. Ltd",
        "ojs_moscow_city_telephone_network": "Ojs Moscow City Telephone Network",
        "ojsc_comcor": "Ojsc Comcor",
        "ojsc_kostroma_municipal_telephone_network": "Ojsc Kostroma Municipal Telephone Network",
        "ojsc_megafon": "Ojsc Megafon",
        "ojsc_oao_tattelecom": "Ojsc Oao Tattelecom",
        "ojsc_ritc": "Ojsc Ritc",
        "ojsc_sheremetyevo_international_airport": "Ojsc Sheremetyevo International Airport",
        "ojsc_telecommunication_networks": "Ojsc Telecommunication Networks",
        "ojsc_ufanet": "Ojsc Ufanet",
        "ojsc_vimpelcom": "Ojsc Vimpelcom",
        "ojsc_yamaltelekom": "Ojsc Yamaltelekom",
        "okayama_network_inc": "Okayama Network Inc.",
        "oklahoma_network_for_education_enrichment_and": "Oklahoma Network For Education Enrichment And",
        "oklahoma_office_of_management_enterprise_services": "Oklahoma Office Of Management Enterprise Services",
        "oklahoma_state_university": "Oklahoma State University",
        "old_dominion_university": "Old Dominion University",
        "old_national_bancorp": "Old National Bancorp",
        "ole_comunicacion_s_l": "Ole Comunicacion S.l.",
        "oliveira_e_andrade_informatica_ltda": "Oliveira E Andrade Informatica Ltda",
        "olleh_rwanda_network_ltd": "Olleh Rwanda Network Ltd",
        "om_sai_entertainment": "Om Sai Entertainment",
        "omani_qatari_telecommunications_company_saoc": "Omani Qatari Telecommunications Company Saoc",
        "omantel": "Omantel",
        "omelveny_myers_llp": "O'melveny Myers Llp",
        "omnicity_inc": "Omnicity Inc.",
        "omnispring_llc": "Omnispring Llc",
        "omnitech_inc": "Omni-tech Inc",
        "omnitel_communications": "Omnitel Communications",
        "omskie_kabelnye_seti_ltd": "Omskie Kabelnye Seti Ltd.",
        "onatel": "Onatel",
        "onavo_mobile_ltd": "Onavo Mobile Ltd",
        "onbahia_telecom": "Onbahia Telecom",
        "ondanet_ltda": "Ondanet Ltda",
        "one_eleven_internet_services": "One Eleven Internet Services",
        "one_ring_networks_inc": "One Ring Networks Inc.",
        "onecleveland": "Onecleveland",
        "onitelecom_infocomunicacoes_s_a": "Onitelecom - Infocomunicacoes S.a.",
        "online_computer_library_center": "Online Computer Library Center",
        "online_northwest": "Online Northwest",
        "online_s_a_s": "Online S.a.s.",
        "online_sas": "Online Sas",
        "online_servicos_de_telecomunicacoes_ltda": "Online Servicos De Telecomunicacoes Ltda",
        "onlycable_comunicaciones_s_l": "Onlycable Comunicaciones S.l.",
        "onondaga_community_college": "Onondaga Community College",
        "ontario_datacenter_llc": "Ontario Datacenter Llc",
        "ontario_hydro": "Ontario Hydro",
        "onvoy": "Onvoy",
        "onwave_uk_ltd": "Onwave Uk Ltd",
        "onwifi_tarragona_sl": "Onwifi Tarragona Sl",
        "ooo_adopt_plus": "Ooo Adopt Plus",
        "ooo_alfacom": "Ooo Alfacom",
        "ooo_avtotransport_trejding_jekspedicija_logistika": "Ooo Avtotransport Trejding Jekspedicija Logistika",
        "ooo_bryansk_svyaztv": "Ooo Bryansk Svyaz-tv",
        "ooo_convextagilcenter": "Ooo Convex-tagil-center",
        "ooo_creative_direct_marketing_solutions": "Ooo Creative Direct Marketing Solutions",
        "ooo_direct_telecom": "Ooo Direct Telecom",
        "ooo_elektron": "Ooo Elektron",
        "ooo_firma_varianttv": "Ooo Firma Variant-tv",
        "ooo_fishnet_communications": "Ooo Fishnet Communications",
        "ooo_garant": "Ooo Garant",
        "ooo_hastelekom": "Ooo Has-telekom",
        "ooo_istranet": "Ooo Istranet",
        "ooo_kolpinskie_internetseti": "Ooo Kolpinskie Internet-seti",
        "ooo_matritsa": "Ooo Matritsa",
        "ooo_megacom": "Ooo Megacom",
        "ooo_migraph": "Ooo Migraph",
        "ooo_mostelecom": "Ooo Mostelecom",
        "ooo_netnord": "Ooo Net-nord",
        "ooo_netplus": "Ooo Netplus",
        "ooo_new_line_telecom": "Ooo New Line Telecom",
        "ooo_petrosvyaz": "Ooo Petrosvyaz",
        "ooo_post_ltd": "Ooo Post Ltd",
        "ooo_satellitservis": "Ooo Satellit-servis",
        "ooo_set": "Ooo Set",
        "ooo_sokol": "Ooo Sokol",
        "ooo_sp_milleniumapsny": "Ooo Sp Millenium-apsny",
        "ooo_telecom_zvezdny": "Ooo Telecom Zvezdny",
        "ooredoo_myanmar_limited": "Ooredoo Myanmar Limited",
        "ooredoo_q_s_c": "Ooredoo Q.s.c.",
        "ooredoo_tunisia": "Ooredoo Tunisia",
        "opcao_telecom": "Opcao Telecom",
        "open_data_network_japan_telecom_co_ltd": "Open Data Network Japan Telecom Co. Ltd.",
        "open_mobile": "Open Mobile",
        "openband_multimedia_llc": "Openband Multimedia Llc",
        "opencape_corporation": "Opencape Corporation",
        "openface_inc": "Openface Inc.",
        "opengate_informatica_ltda_me": "Opengate Informatica Ltda - Me",
        "openip": "Openip",
        "opera_software_americas_llc": "Opera Software Americas Llc",
        "opera_software_as": "Opera Software As",
        "operadora_de_nuevos_sistemas_de_comunicacion_slu": "Operadora De Nuevos Sistemas De Comunicacion Slu",
        "operadora_gredos_sl": "Operadora Gredos Sl",
        "operbes_s_a_de_c_v": "Operbes S.a. De C.v.",
        "oppobox": "Oppobox",
        "opt": "Opt",
        "optic_telecom_llc": "Optic Telecom Llc",
        "opticaltel": "Opticaltel",
        "optima_italia_s_p_a": "Optima Italia S.p.a.",
        "optimaset_ltd": "Optimaset Ltd.",
        "optimum_online": "Optimum Online",
        "optus_internet": "Optus Internet",
        "oquei_solucoes_em_ti": "Oquei Solucoes Em Ti",
        "oracle_corporation": "Oracle Corporation",
        "oracle_svenska_ab": "Oracle Svenska Ab",
        "orakom_s_r_l": "Orakom S.r.l.",
        "oral_roberts_university": "Oral Roberts University",
        "orange_belgium_sa": "Orange Belgium Sa",
        "orange_cameroun_sa": "Orange Cameroun Sa",
        "orange_centrafrique_local_registry": "Orange Centrafrique Local Registry",
        "orange_county_public_schools": "Orange County Public Schools",
        "orange_espagne_s_a_u": "Orange Espagne S.a.u.",
        "orange_espagne_sa": "Orange Espagne Sa",
        "orange_madagascar": "Orange Madagascar",
        "orange_niger": "Orange Niger",
        "orange_polska_spolka_akcyjna": "Orange Polska Spolka Akcyjna",
        "orange_romania_s_a": "Orange Romania S.a.",
        "orange_s_a": "Orange S.a.",
        "orange_tunisia": "Orange Tunisia",
        "orange_web_services_s_l": "Orange Web Services S.l.",
        "orano": "Orano",
        "orbitalnet_ltd": "Orbitalnet Ltd",
        "orbitel_communications_llc": "Orbitel Communications Llc",
        "oregon_health_science_university": "Oregon Health Science University",
        "oregon_state_university": "Oregon State University",
        "org_closed_joint_stock_company_star_networks_orgcjsc5ripe": "Org - Closed Joint Stock Company Star Networks Org-cjsc5-ripe",
        "orgtechservice_ltd": "Orgtechservice Ltd",
        "oria_eliza_hugo_carrascome": "Oria Eliza Hugo Carrasco-me",
        "origin_broadband_limited": "Origin Broadband Limited",
        "orion_telecom_ltd": "Orion Telecom Ltd.",
        "orlando_venues": "Orlando Venues",
        "osage_municipal_communications_utility": "Osage Municipal Communications Utility",
        "osaka_university": "Osaka University",
        "oscon_itk_gmbh": "Oscon Itk Gmbh",
        "oshean_inc": "Oshean Inc.",
        "osirnet_info_telecom_ltda": "Osirnet Info Telecom Ltda.",
        "oso_grande_technologies_inc": "Oso Grande Technologies Inc.",
        "ostara_telecomunicacoes_ltda": "Ostara Telecomunicacoes Ltda",
        "ot_optima_telekom_d_d": "Ot - Optima Telekom D.d.",
        "ote_sa_hellenic_telecommunications_organisation": "Ote Sa Hellenic Telecommunications Organisation",
        "otradnoenet_ltd": "Otradnoenet Ltd.",
        "ots_ltd": "Ots Ltd.",
        "ottawa_carleton_district_school_board": "Ottawa Carleton District School Board",
        "outback_internet": "Outback Internet",
        "outremer_telecom": "Outremer Telecom",
        "over_the_wire_pty_ltd": "Over The Wire Pty Ltd",
        "ovh_sas": "Ovh Sas",
        "owenscorning": "Owens-corning",
        "oxford_networks": "Oxford Networks",
        "oxford_university": "Oxford University",
        "ozarksgo_llc": "Ozarksgo Llc",
        "ozservers_hosting_provider": "Ozservers Hosting Provider",
        "ozyorsk_telecom_cjsc": "Ozyorsk Telecom Cjsc.",
        "p4_sp_z_o_o": "P4 Sp. Z O.o.",
        "p_4_net_provedores_ltda_me": "P 4 Net Provedores Ltda - Me",
        "p_a_k_t_llc": "P.a.k.t Llc",
        "pace_university": "Pace University",
        "pacific_dental_services": "Pacific Dental Services",
        "pacific_internet_pte_ltd": "Pacific Internet Pte Ltd",
        "pacific_lightwave_inc": "Pacific Lightwave Inc.",
        "pacific_lutheran_university": "Pacific Lutheran University",
        "pacific_university": "Pacific University",
        "pacific_wave_telecom": "Pacific Wave Telecom",
        "pacifico_cable_s_a": "Pacifico Cable S.a.",
        "packerland_broadband": "Packerland Broadband",
        "packetexchange_inc": "Packetexchange Inc",
        "packetworks_inc": "Packetworks Inc.",
        "pacnet_services_japan_corp": "Pacnet Services Japan Corp.",
        "padrao_system_telecom": "Padrao System Telecom",
        "paetec_communications_inc": "Paetec Communications Inc.",
        "pagosa_springs_medical_center": "Pagosa Springs Medical Center",
        "paintweb_internet_ltda": "Paintweb Internet Ltda",
        "pakistan_telecommunication_company_limited": "Pakistan Telecommunication Company Limited",
        "pakistan_telecommunications_mobile_limited": "Pakistan Telecommunications Mobile Limited",
        "pallada_web_service_llc": "Pallada Web Service Llc",
        "palm_beach_atlantic_university": "Palm Beach Atlantic University",
        "palm_beach_county": "Palm Beach County",
        "palomar_community_college_district": "Palomar Community College District",
        "panamsat_corporation": "Panamsat Corporation",
        "panchsheel_broadband_services_private_limited": "Panchsheel Broadband Services Private Limited",
        "pandora": "Pandora",
        "panhandle_telecommunications_systems_inc": "Panhandle Telecommunications Systems Inc.",
        "panservice": "Panservice",
        "paradise_networks_llc": "Paradise Networks Llc",
        "parasat_cable_tv_inc": "Parasat Cable Tv Inc",
        "park_tv_electronics_inc": "Park Tv Electronics Inc",
        "parker_fibernet_llc": "Parker Fibernet Llc",
        "parkland_health_hospital_system": "Parkland Health Hospital System",
        "parsons_corporation": "Parsons Corporation",
        "pasadena_independent_school_district": "Pasadena Independent School District",
        "pascohernando_state_college": "Pasco-hernando State College",
        "pasifik_lintas_buana_pt": "Pasifik Lintas Buana Pt",
        "paul_bunyan_communications": "Paul Bunyan Communications",
        "paulino_perreira_dos_santos_me": "Paulino Perreira Dos Santos Me",
        "paulo_henrique_freitas_silveira_me": "Paulo Henrique Freitas Silveira - Me",
        "pavlov_media_inc": "Pavlov Media Inc",
        "paxio_inc": "Paxio Inc.",
        "paypal_corp_arin_asn": "Paypal Corp Arin Asn",
        "paypal_inc": "Paypal Inc.",
        "pazetto_e_silva_ltda": "Pazetto E Silva Ltda",
        "pbnet_telecom": "Pbnet - Telecom",
        "pc_connect_telecomunicacoes_ltda": "Pc Connect Telecomunicacoes Ltda",
        "pccw_business_internet_access": "Pccw Business Internet Access",
        "pccw_global_inc": "Pccw Global Inc.",
        "pccw_limited": "Pccw Limited",
        "pcm_sales_inc": "Pcm Sales Inc",
        "pdm_net": "Pdm Net",
        "pdr": "Pdr",
        "pe_ahmedov_alil_aliomarovich": "Pe Ahmedov Alil Aliomarovich",
        "pe_aliev_murad_ahmedovich": "Pe Aliev Murad Ahmedovich",
        "peak_10": "Peak 10",
        "peak_10_inc": "Peak 10 Inc.",
        "peak_internet": "Peak Internet",
        "peak_internet_llc": "Peak Internet Llc",
        "pecquet_adair_freeland_ober_llc": "Pecquet Adair Freeland Ober Llc",
        "pedro_adriano_souto_maior_veloso_me": "Pedro Adriano Souto Maior Veloso - Me",
        "peel_district_school_board": "Peel District School Board",
        "peer_1_dedicated_hosting": "Peer 1 Dedicated Hosting",
        "peg_tech_inc": "Peg Tech Inc",
        "pelephone_communications_ltd": "Pelephone Communications Ltd.",
        "pembroke_telephone_company_inc": "Pembroke Telephone Company Inc.",
        "pen_bay_healthcare": "Pen Bay Healthcare",
        "penasco_valley_telecommunications": "Penasco Valley Telecommunications",
        "peninsula_fiber_network_llc": "Peninsula Fiber Network Llc",
        "penki_kontinentai_ltd": "Penki Kontinentai Ltd.",
        "penn_medicine": "Penn Medicine",
        "pennsylvania_college_of_technology": "Pennsylvania College Of Technology",
        "pennsylvania_state_system_of_higher_education": "Pennsylvania State System Of Higher Education",
        "penteledata_inc": "Penteledata Inc.",
        "pentenet_comercio_de_informatica_eireli_epp": "Pentenet Comercio De Informatica Eireli Epp",
        "peoples_communications_inc": "Peoples Communications Inc.",
        "pepsico_inc": "Pepsico Inc.",
        "pepsicola_international": "Pepsi-cola International",
        "perfect_international_inc": "Perfect International Inc",
        "perfect_internet_pvt_ltd": "Perfect Internet Pvt Ltd",
        "perryspencer_communications_inc": "Perry-spencer Communications Inc.",
        "persona_communications_inc": "Persona Communications Inc.",
        "peter_rauter_gmbh": "Peter Rauter Gmbh",
        "pfalzkom_gesellschaft_fuer_telekommunikation_mbh": "Pfalzkom Gesellschaft Fuer Telekommunikation Mbh",
        "pfizer_inc": "Pfizer Inc.",
        "phenix_cable": "Phenix Cable",
        "philcom_corporation_internet_service": "Philcom Corporation Internet Service",
        "philippine_long_distance_telephone_company": "Philippine Long Distance Telephone Company",
        "philippine_telegraph_and_telephone_corporation": "Philippine Telegraph And Telephone Corporation",
        "phillips_academy": "Phillips Academy",
        "phillips_county_telephone_company": "Phillips County Telephone Company",
        "phoenix_internet": "Phoenix Internet",
        "photonium_netsolutions_gmbh": "Photonium Netsolutions Gmbh",
        "phu_hajdamowicz_miroslaw_hajdamowicz": "Phu Hajdamowicz Miroslaw Hajdamowicz",
        "piedmont_rural_telephone_cooperative_incorporated": "Piedmont Rural Telephone Cooperative Incorporated",
        "piernet_telecom": "Piernet Telecom",
        "pilot_fiber_inc": "Pilot Fiber Inc.",
        "pima_community_college": "Pima Community College",
        "pimox_llc": "Pimox Llc",
        "pine_belt_telephone_co": "Pine Belt Telephone Co",
        "pinnacle_telecom_llc": "Pinnacle Telecom Llc.",
        "pinpoint_communications_inc": "Pinpoint Communications Inc.",
        "pioneer_cellular": "Pioneer Cellular",
        "pioneer_elabs_ltd": "Pioneer Elabs Ltd.",
        "pioneer_hibred_international_inc": "Pioneer Hi-bred International Inc.",
        "pioneer_long_distance_inc": "Pioneer Long Distance Inc.",
        "piranha_systems": "Piranha Systems",
        "pitney_bowes_credit_corporation": "Pitney Bowes Credit Corporation",
        "pittsburgh_supercomputing_center": "Pittsburgh Supercomputing Center",
        "pivit_pty_ltd": "Pivit Pty Ltd",
        "pjsc_bashinformsvyaz": "Pjsc Bashinformsvyaz",
        "pjsc_fars_telecommunication_company": "Pjsc Fars Telecommunication Company",
        "pjsc_rostelecom": "Pjsc Rostelecom",
        "pjsc_ukrtelecom": "Pjsc Ukrtelecom",
        "placido_e_andrade_ltda": "Placido E Andrade Ltda",
        "planet_cable_inc": "Planet Cable Inc.",
        "plant_tiftnet_inc": "Plant Tiftnet Inc",
        "planters_rural_telephone_cooperative": "Planters Rural Telephone Cooperative",
        "plateau_telecommunications_incorporated": "Plateau Telecommunications Incorporated",
        "plexicomm_llc": "Plexicomm Llc",
        "plumas_sierra_telecommunications": "Plumas Sierra Telecommunications",
        "plus_provedor_de_internet_ltda_me": "Plus Provedor De Internet Ltda - Me",
        "plusnet_communication_pvt_ltd": "Plusnet Communication Pvt. Ltd.",
        "pnc_bank": "Pnc Bank",
        "pocketinet_communications_inc": "Pocketinet Communications Inc",
        "poda_a_s": "Poda A.s.",
        "pogozone": "Pogozone",
        "pohang_university_of_science_and_technology": "Pohang University Of Science And Technology",
        "poig_ltd": "Poig Ltd.",
        "point_loma_nazarene_university": "Point Loma Nazarene University",
        "polar_communications": "Polar Communications",
        "polk_county_public_schools": "Polk County Public Schools",
        "polkomtel_sp_z_o_o": "Polkomtel Sp. Z O.o.",
        "polytechnic_institute_of_nyu": "Polytechnic Institute Of Nyu",
        "pomeroy": "Pomeroy",
        "pon_project_services_pty_ltd": "Pon Project Services Pty Ltd",
        "ponte_digital": "Ponte Digital",
        "pontenet_teleinformatica_ltda": "Pontenet Teleinformatica Ltda.",
        "popp_com_inc": "Popp.com Inc.",
        "port_networks_inc": "Port Networks Inc.",
        "port_of_seattle": "Port Of Seattle",
        "portal_conexao_ltda": "Portal Conexao Ltda",
        "portalmail_informatica_ltda": "Portalmail Informatica Ltda",
        "portland_internet_hosting_llc": "Portland Internet Hosting Llc",
        "portland_state_university": "Portland State University",
        "portlane_networks_ab": "Portlane Networks Ab",
        "posta_dhe_telekomi_i_kosoves": "Posta Dhe Telekomi I Kosoves",
        "postecom_s_p_a": "Postecom S.p.a.",
        "postemobile_s_p_a": "Postemobile S.p.a.",
        "poudre_valley_health_care_inc": "Poudre Valley Health Care Inc.",
        "powai": "Powai",
        "power_grid_corporation_of_india_limited": "Power Grid Corporation Of India Limited",
        "power_up_hosting_inc": "Power Up Hosting Inc.",
        "powerhouse_management_inc": "Powerhouse Management Inc.",
        "powernet_solucoes_em_informatica_ltda_me": "Powernet Solucoes Em Informatica Ltda Me",
        "powernet_telecom": "Powernet Telecom",
        "powersat_servicos_de_telecomunicacoes_ltda": "Powersat Servicos De Telecomunicacoes Ltda",
        "powertel_limited": "Powertel Limited",
        "prager_connect_gmbh": "Prager Connect Gmbh",
        "prairie_grove_telephone_co": "Prairie Grove Telephone Co.",
        "praxair_inc": "Praxair Inc",
        "precious_netcom_pvt_ltd": "Precious Netcom Pvt Ltd",
        "predlink_rede_de_telecomuniccoes_ltda": "Predlink Rede De Telecomuniccoes Ltda",
        "premianet_com": "Premianet.com",
        "premier_communications": "Premier Communications",
        "premier_group_companies_telecom_llc": "Premier Group Companies Telecom Llc",
        "pricewaterhousecoopers_llp": "Pricewaterhousecoopers Llp",
        "pride_limited": "Pride Limited",
        "primelink_inc": "Primelink Inc",
        "primesoftex_ltd": "Primesoftex Ltd",
        "primetel_plc": "Primetel Plc",
        "primus_telecommunications_canada_inc": "Primus Telecommunications Canada Inc.",
        "prince_georges_intergovernmental_network_community_coordinating_committee": "Prince Georges Intergovernmental Network Community Coordinating Committee",
        "prince_of_songkla_university_sritrangnet": "Prince Of Songkla University Sritrang'net",
        "princeton_theological_seminary": "Princeton Theological Seminary",
        "princeton_university": "Princeton University",
        "prinetime_internet_solutions_llc": "Prinetime Internet Solutions Llc",
        "prisco_electronica_s_l": "Prisco Electronica S.l",
        "private_joint_stock_company_datagroup": "Private Joint Stock Company Datagroup",
        "privax_ltd": "Privax Ltd",
        "prjsc_mts_ukraine": "Prjsc Mts Ukraine",
        "proceau_s_a_r_l": "Proceau S.a.r.l.",
        "procenter_ltd": "Procenter Ltd.",
        "procono_s_a": "Procono S.a.",
        "procter_gamble_service_gmbh": "Procter Gamble Service Gmbh",
        "produban_servicios_informaticos_generales_s_l": "Produban Servicios Informaticos Generales S.l.",
        "producmedia_s_l_u": "Producmedia S.l.u.",
        "professional_value_internet_services_inc": "Professional Value Internet Services Inc.",
        "proftelecomservice_ltd": "Proftelecom-service Ltd",
        "progetto_8_s_r_l": "Progetto 8 S.r.l.",
        "progressive_casualty_insurance_companies": "Progressive Casualty Insurance Companies",
        "progtechyug_ltd": "Progtech-yug Ltd",
        "proimage_engineering_and_communication_co_ltd": "Proimage Engineering And Communication Co. Ltd.",
        "prometey_llc": "Prometey Llc",
        "pronss": "Pronss",
        "proofpoint_inc": "Proofpoint Inc.",
        "propel_wireless_internet_llc": "Propel Wireless Internet Llc",
        "prored_comunicaciones_autonomous_system": "Prored Comunicaciones Autonomous System",
        "provedor_action": "Provedor Action",
        "provedor_brcentral_net_ltda": "Provedor Brcentral.net Ltda",
        "provedor_crnet_telecom_s_c_multimidia_eireli": "Provedor Crnet Telecom S.c. Multimidia Eireli",
        "provedor_intersousa_ltda": "Provedor Intersousa Ltda",
        "provedor_ultranet": "Provedor Ultranet",
        "provedora_cma_internet_ltda": "Provedora Cma Internet Ltda",
        "provedornet_telecom_e_servicos_de_internet_ltda": "Provedornet Telecom. E Servicos De Internet Ltda",
        "provetech_solucao_em_internet_ltda_me": "Provetech Solucao Em Internet Ltda Me",
        "providence_college": "Providence College",
        "providence_health_services": "Providence Health Services",
        "providence_hospital": "Providence Hospital",
        "provider_solution_internet_telecom_ltda_me": "Provider Solution Internet Telecom Ltda - Me",
        "province_of_british_columbia": "Province Of British Columbia",
        "province_of_new_brunswick": "Province Of New Brunswick",
        "proximus_nv": "Proximus Nv",
        "proxyregistered_autnum_object_for_gtcs_upstream_requirement": "Proxy-registered Aut-num Object For Gtc's Upstream Requirement",
        "prymus_servicos_em_telecomunicacoes_ltda": "Prymus Servicos Em Telecomunicacoes Ltda",
        "ps5_internet": "Ps5 Internet",
        "ps_lightwave": "Ps Lightwave",
        "psinet_europe_via_net_works_international_as": "Psinet Europe Via Net.works International As",
        "psinet_inc": "Psinet Inc.",
        "pskovline_ltd": "Pskovline Ltd.",
        "psychz_networks": "Psychz Networks",
        "pt_alam_insektindo": "Pt Alam Insektindo",
        "pt_artha_telekomindo": "Pt Artha Telekomindo",
        "pt_bit_technology_nusantara": "Pt. Bit Technology Nusantara",
        "pt_borneo_broadband_technology": "Pt. Borneo Broadband Technology",
        "pt_broadband_multimedia_tbk": "Pt. Broadband Multimedia Tbk",
        "pt_cemerlang_multimedia": "Pt. Cemerlang Multimedia",
        "pt_centra_global_investama": "Pt. Centra Global Investama",
        "pt_core_mediatech_dnet": "Pt. Core Mediatech D-net",
        "pt_cyberindo_aditama": "Pt. Cyberindo Aditama",
        "pt_des_teknologi_informasi": "Pt Des Teknologi Informasi",
        "pt_dumai_mandiri_net": "Pt. Dumai Mandiri Net",
        "pt_dunia_informasi_teknologi": "Pt Dunia Informasi Teknologi",
        "pt_duta_karya_perkasa": "Pt Duta Karya Perkasa",
        "pt_dutakom_wibawa_putra": "Pt. Dutakom Wibawa Putra",
        "pt_eka_mas_republik": "Pt. Eka Mas Republik",
        "pt_excelcomindo_pratama_network_access_provider": "Pt Excelcomindo Pratama Network Access Provider",
        "pt_fiber_networks_indonesia": "Pt. Fiber Networks Indonesia",
        "pt_first_media_tbk": "Pt. First Media Tbk",
        "pt_hipernet_indodata": "Pt. Hipernet Indodata",
        "pt_hutchison_cp_telecommunications": "Pt Hutchison Cp Telecommunications",
        "pt_indosat_mega_media": "Pt Indosat Mega Media",
        "pt_inet_global_indo": "Pt Inet Global Indo",
        "pt_infotek_global_network": "Pt Infotek Global Network",
        "pt_internux": "Pt. Internux",
        "pt_jala_lintas_media": "Pt Jala Lintas Media",
        "pt_khasanah_timur_indonesia": "Pt. Khasanah Timur Indonesia",
        "pt_lintas_jaringan_nusantara": "Pt Lintas Jaringan Nusantara",
        "pt_maxindo_mitra_solusi_jl_kelapa_puan_raya_blok_fu_19_jakarta": "Pt Maxindo Mitra Solusi Jl Kelapa Puan Raya Blok Fu 19 Jakarta",
        "pt_mayatama_solusindo": "Pt Mayatama Solusindo",
        "pt_media_andalan_nusa": "Pt Media Andalan Nusa",
        "pt_media_sarana_data": "Pt Media Sarana Data",
        "pt_mora_telematika_indonesia": "Pt.mora Telematika Indonesia",
        "pt_netciti_persada": "Pt Netciti Persada",
        "pt_ntt_indonesia": "Pt. Ntt Indonesia",
        "pt_pasifik_satelit_nusantara": "Pt. Pasifik Satelit Nusantara",
        "pt_pemuda_berkarya_manunggal": "Pt. Pemuda Berkarya Manunggal",
        "pt_permata_abadi_keluarga_perkasa": "Pt Permata Abadi Keluarga Perkasa",
        "pt_powertel": "Pt. Powertel",
        "pt_remala_abadi": "Pt Remala Abadi",
        "pt_sinergi_semesta_telematika": "Pt Sinergi Semesta Telematika",
        "pt_smartfren_telecom_tbk": "Pt. Smartfren Telecom Tbk",
        "pt_solnet_indonesia": "Pt Solnet Indonesia",
        "pt_telekomunikasi_indonesia": "Pt. Telekomunikasi Indonesia",
        "pt_web_data_solusindo": "Pt. Web Data Solusindo",
        "pt_wifiku_indonesia": "Pt Wifiku Indonesia",
        "pt_wireless_indonesia_win": "Pt Wireless Indonesia Win",
        "ptera_inc": "Ptera Inc.",
        "ptk_ltd": "P-t-k Ltd",
        "public_joint_stock_company_vimpelcommunications": "Public Joint Stock Company Vimpel-communications",
        "public_telecommunication_corporation": "Public Telecommunication Corporation",
        "public_utility_district_no_1_of_okanogan_county": "Public Utility District No. 1 Of Okanogan County",
        "puerto_rico_cable_acquisition_company_inc": "Puerto Rico Cable Acquisition Company Inc.",
        "puerto_rico_telephone_company": "Puerto Rico Telephone Company",
        "pulaski_electric_system": "Pulaski Electric System",
        "pulaski_white_rural_telephone_cooperative_inc": "Pulaski White Rural Telephone Cooperative Inc.",
        "pulsant_scotland_ltd": "Pulsant Scotland Ltd",
        "purdue_pharma_lp": "Purdue Pharma Lp",
        "purdue_university": "Purdue University",
        "pure_broadband_limited": "Pure Broadband Limited",
        "pure_pages_inc": "Pure Pages Inc.",
        "pusan_national_university": "Pusan National University",
        "pw_network_informatica_e_telecomunicacoes_ltda": "Pw Network Informatica E Telecomunicacoes Ltda",
        "qcell_limited": "Qcell Limited",
        "qcol_inc": "Qcol Inc",
        "qsc_ag": "Qsc Ag",
        "qtnet_inc": "Qtnet Inc.",
        "quadranet_inc": "Quadranet Inc",
        "quadranet_us": "Quadranet Us",
        "quadrant_televentures_limited": "Quadrant Televentures Limited",
        "qualcomm_inc": "Qualcomm Inc.",
        "quality_service_provider_bv": "Quality Service Provider Bv",
        "quality_technology_services_llc": "Quality Technology Services Llc.",
        "qualitynet_general_trading_contracting_co": "Qualitynet General Trading Contracting Co.",
        "quantic_telecom": "Quantic Telecom",
        "quantil_networks_inc": "Quantil Networks Inc",
        "quantum_cjsc": "Quantum Cjsc",
        "quantum_communications_llc": "Quantum Communications Llc",
        "quantum_internet_services_inc": "Quantum Internet Services Inc.",
        "quasar_ltd": "Quasar Ltd",
        "quattre_internet_sl": "Quattre Internet Sl",
        "qubic_group_plc": "Qubic Group Plc",
        "queen_mary_and_westfield_college_university_of_london": "Queen Mary And Westfield College University Of London",
        "queens_university": "Queen's University",
        "quest_consultancy_pvt_ltd": "Quest Consultancy Pvt Ltd",
        "questzone_net_inc": "Questzone.net Inc.",
        "quicken_loans_inc": "Quicken Loans Inc.",
        "quickest_broadband_private_limited": "Quickest Broadband Private Limited",
        "quickline_ag": "Quickline Ag",
        "quickline_communications_limited": "Quickline Communications Limited",
        "quickpacket_llc": "Quickpacket Llc",
        "quinnipiac_university": "Quinnipiac University",
        "quintex_alliance_consulting": "Quintex Alliance Consulting",
        "qwest_broadband_services_inc": "Qwest Broadband Services Inc.",
        "qwest_communications_company_llc": "Qwest Communications Company Llc",
        "qwest_corporation": "Qwest Corporation",
        "qx_net": "Qx.net",
        "qxc_communications_inc": "Qxc Communications Inc.",
        "r7r_me": "R7r - Me",
        "r_b_servicos_de_informatica_ltdame": "R.b. Servicos De Informatica Ltda-me",
        "r_c_a_sistemas_ltda": "R.c.a. Sistemas Ltda.",
        "r_cable_y_telecomunicaciones_galicia_s_a": "R Cable Y Telecomunicaciones Galicia S.a.",
        "r_k_cable_net_private_limited": "R.k. Cable Net Private Limited",
        "r_k_infratel_limited": "R. K. Infratel Limited",
        "r_l_guimaraes_telecomunicacao_me": "R L Guimaraes Telecomunicacao - Me",
        "r_p_world_telecom_pvt_ltd": "R P World Telecom Pvt Ltd",
        "r_r_donnelley_and_sons_co": "R.r. Donnelley And Sons Co.",
        "r_r_silva_telecom_eireli_me": "R.r.silva Telecom Eireli - Me",
        "race_technologies_inc": "Race Technologies Inc.",
        "rackspace_hosting": "Rackspace Hosting",
        "radar_wisp_ltda": "Radar Wisp Ltda",
        "radford_university": "Radford University",
        "radianz_americas_inc": "Radianz Americas Inc.",
        "radio_link_telecom": "Radio Link Telecom",
        "radiocable_ingenieros_s_l": "Radiocable Ingenieros S.l.",
        "radiomovil_dipsa_s_a_de_c_v": "Radiomovil Dipsa S.a. De C.v.",
        "radiowire_net": "Radiowire.net",
        "radius_telecoms_inc": "Radius Telecoms Inc.",
        "rafael_badra_caloca_me": "Rafael Badra Caloca - Me",
        "ragingwire_data_centers_inc": "Ragingwire Data Centers Inc.",
        "raiffeisen_online_gmbh": "Raiffeisen Online Gmbh",
        "railtel_corporation_of_india_ltd": "Railtel Corporation Of India Ltd.",
        "railtel_corporation_of_india_ltd_internet_service_provider_new_delhi": "Railtel Corporation Of India Ltd. Internet Service Provider New Delhi",
        "rainbow_broadband_inc": "Rainbow Broadband Inc.",
        "rainbow_communications_india_pvt_ltd": "Rainbow Communications India Pvt Ltd",
        "rainbow_district_school_board": "Rainbow District School Board",
        "rainier_connect": "Rainier Connect",
        "rajdhani_telecom_pvt_ltd": "Rajdhani Telecom Pvt.ltd.",
        "rajesh_patel_net_services_pvt_ltd": "Rajesh Patel Net Services Pvt Ltd",
        "rajeshnet": "Rajeshnet",
        "rakuten_communications_corp": "Rakuten Communications Corp.",
        "ralink_telecom_ltda": "Ralink Telecom Ltda",
        "ralls_technologies_llc": "Ralls Technologies Llc",
        "ramapo_college_of_new_jersey": "Ramapo College Of New Jersey",
        "ramnode_llc": "Ramnode Llc",
        "ramsvyazinvest_ltd": "Ramsvyazinvest Ltd.",
        "ranch_wifi_llc": "Ranch Wifi Llc",
        "randolph_telephone_company": "Randolph Telephone Company",
        "rapid_telecommunications_w_l_l": "Rapid Telecommunications W.l.l.",
        "rapiddsl_wireless": "Rapiddsl Wireless",
        "ratt_internet_kapacitet_i_sverige_ab": "Ratt Internet Kapacitet I Sverige Ab",
        "ravand_cybertech_inc": "Ravand Cybertech Inc.",
        "raw_bandwidth_communications_inc": "Raw Bandwidth Communications Inc.",
        "rawifi": "Ra-wifi",
        "raytheon_company": "Raytheon Company",
        "razaoinfo_internet_ltda": "Razaoinfo Internet Ltda",
        "razzo_link_inc": "Razzo Link Inc.",
        "rcn": "Rcn",
        "rcn_corporation": "Rcn Corporation",
        "rcs_rds_sa": "Rcs Rds Sa",
        "reach4_communications": "Reach4 Communications",
        "readylink_internet_services_limited": "Readylink Internet Services Limited",
        "real_time_technologies_alliance_africa": "Real Time Technologies Alliance - Africa",
        "reallink_provedor_de_internet": "Reallink Provedor De Internet",
        "realtel_network_services_pvt_ltd": "Realtel Network Services Pvt Ltd",
        "rebapnet_telecomunicacoes": "Rebapnet Telecomunicacoes",
        "rebeloak_sl": "Rebeloak Sl",
        "rebeltec_communications_llc": "Rebeltec Communications Llc",
        "recom_llc": "Recom Llc",
        "reconn_operator_svyazy_ltd": "Reconn. Operator Svyazy Ltd.",
        "red_digital_de_telecomunicaciones_de_las_islas_baleares_sl": "Red Digital De Telecomunicaciones De Las Islas Baleares Sl",
        "red_intercable_digital_s_a": "Red Intercable Digital S.a.",
        "red_television_srl": "Red Television Srl",
        "redcominternet_inc": "Redcom-internet Inc.",
        "rede_brasil_networks": "Rede Brasil Networks",
        "rede_brasileira_de_comunicacao_ltda": "Rede Brasileira De Comunicacao Ltda",
        "rede_connect_telecom": "Rede Connect Telecom",
        "rede_de_telecomunicacoes_carajas_ltda": "Rede De Telecomunicacoes Carajas Ltda",
        "rede_exitus_ltda": "Rede Exitus Ltda",
        "rede_ideia_telecom": "Rede Ideia Telecom",
        "rede_idl_net": "Rede Idl Net",
        "rede_lago_internet_ltda": "Rede Lago Internet Ltda",
        "rede_telecom": "Rede Telecom",
        "redemetro_telecomunicacoes_ltda": "Redemetro Telecomunicacoes Ltda",
        "redes_del_oeste_s_a": "Redes Del Oeste S.a",
        "redes_y_telecomunicaciones": "Redes Y Telecomunicaciones",
        "redesulnet_telecomunicacoes_ltda_me": "Redesulnet Telecomunicacoes Ltda - Me",
        "rediris": "Rediris",
        "redlink_communications_co_ltd": "Redlink Communications Co. Ltd.",
        "redspectrum_communications": "Red-spectrum Communications",
        "redstation_limited": "Redstation Limited",
        "redzone_wireless_llc": "Redzone Wireless Llc",
        "reedsburg_utility_commission": "Reedsburg Utility Commission",
        "region_14_education_service_center": "Region 14 Education Service Center",
        "region_5_education_service_center": "Region 5 Education Service Center",
        "reinan_cable_network_co_ltd": "Reinan Cable Network Co. Ltd",
        "relaix_networks_gmbh": "Relaix Networks Gmbh",
        "reliablesite_net_llc": "Reliablesite.net Llc",
        "reliance_communications_ltd": "Reliance Communications Ltd",
        "reliance_communications_ltd_dakc_mumbai": "Reliance Communications Ltd.dakc Mumbai",
        "reliance_industries_limited": "Reliance Industries Limited",
        "reliance_jio_infocomm_limited": "Reliance Jio Infocomm Limited",
        "renater": "Renater",
        "renault_sas": "Renault Sas",
        "renet_com_ltd": "Renet Com Ltd.",
        "renovenet_telecomunicacoes_ltda_me": "Renovenet Telecomunicacoes Ltda - Me",
        "renown_health": "Renown Health",
        "rensselaer_hartford_graduate_center": "Rensselaer Hartford Graduate Center",
        "rensselaer_polytechnic_institute": "Rensselaer Polytechnic Institute",
        "republican_unitary_telecommunication_enterprise_beltelecom": "Republican Unitary Telecommunication Enterprise Beltelecom",
        "research_and_production_firm_imtcenter_ooo": "Research And Production Firm Imt-center Ooo",
        "research_machines_plc": "Research Machines Plc",
        "research_organization_of_information_and_systems_national_institute_of_informatics": "Research Organization Of Information And Systems National Institute Of Informatics",
        "reseau_d_informations_scientifiques_du_quebec": "Reseau D Informations Scientifiques Du Quebec",
        "reseau_dinformations_scientifiques_du_quebec_risq": "Reseau D'informations Scientifiques Du Quebec Risq",
        "reseau_national_de_telecommunications_pour_la_technologie": "Reseau National De Telecommunications Pour La Technologie",
        "reseau_noropale": "Reseau Noropale",
        "reseau_picanoc_net_inc": "Reseau Picanoc.net Inc.",
        "reservation_telephone_coop": "Reservation Telephone Coop.",
        "reserve_long_distance_company_inc": "Reserve Long Distance Company Inc",
        "resident_technology_group": "Resident Technology Group",
        "resilans_ab": "Resilans Ab",
        "resortinternet": "Resortinternet",
        "resound_networks_llc": "Resound Networks Llc",
        "restech_services_llc": "Restech Services Llc",
        "reti_telematiche_italiane_s_p_a_retelit_s_p_a": "Reti Telematiche Italiane S.p.a. Retelit S.p.a.",
        "reuben_planet_eireli_me": "Reuben Planet Eireli - Me",
        "reynolds_cable_inc": "Reynolds Cable Inc",
        "rf_now_inc": "Rf Now Inc.",
        "rftkabel_brandenburg_gmbh": "Rftkabel Brandenburg Gmbh",
        "rgc_wireless": "Rgc Wireless",
        "rheinet_gmbh": "Rheinet Gmbh",
        "rhode_island_network_for_educ_technology": "Rhode Island Network For Educ. Technology",
        "rhodes_university": "Rhodes University",
        "ri_networks_pvt_ltd": "Ri Networks Pvt. Ltd.",
        "rial_com_jsc": "Rial Com Jsc",
        "rice_university": "Rice University",
        "ricentr_llc": "Ricentr Llc",
        "right_side_lcc": "Right Side Lcc",
        "rignet_inc": "Rignet Inc",
        "ringnett_as": "Ringnett As",
        "rio_cable_telecom_ltda": "Rio Cable Telecom Ltda",
        "riotelecomm_s_l": "Riotelecomm S.l.",
        "ripe_network_coordination_centre": "Ripe Network Coordination Centre",
        "rise": "Rise",
        "ritter_communications_inc": "Ritter Communications Inc.",
        "river_canyon_wireless": "River Canyon Wireless",
        "riverside_county_office_of_education": "Riverside County Office Of Education",
        "rm_dos_santos_informatica": "Rm Dos Santos Informatica",
        "rms_connect_telecomunicacoes_ltda": "Rms Connect Telecomunicacoes Ltda",
        "roamware_netherlands_b_v": "Roamware Netherlands B.v.",
        "roanoke_college": "Roanoke College",
        "roberto_dos_santos_silva_me": "Roberto Dos Santos Silva - Me",
        "rochester_institute_of_technology": "Rochester Institute Of Technology",
        "rochester_public_schools": "Rochester Public Schools",
        "rock_solid_internet_telephone": "Rock Solid Internet Telephone",
        "rockenstein_ag": "Rockenstein Ag",
        "rocket_fiber": "Rocket Fiber",
        "rockwell_collins_inc": "Rockwell Collins Inc.",
        "rodrigo_da_silva_luz_me": "Rodrigo Da Silva Luz Me",
        "rogers_communications_canada_inc": "Rogers Communications Canada Inc.",
        "roller_network_llc": "Roller Network Llc",
        "romtelecom_s_a": "Romtelecom S.a.",
        "roper_saint_francis_healthcare": "Roper Saint Francis Healthcare",
        "roshan": "Roshan",
        "rosintel_network": "Rosintel Network",
        "rosniiros_russian_institute_for_public_networks": "Rosniiros Russian Institute For Public Networks",
        "rowan_university": "Rowan University",
        "rowe_wireless_networks_llc": "Rowe Wireless Networks Llc",
        "royal_cablevision_corp": "Royal Cablevision Corp.",
        "royal_melbourne_institute_of_technology": "Royal Melbourne Institute Of Technology",
        "royal_net_cable_pvt_ltd": "Royal Net Cable Pvt Ltd",
        "royell_communications_inc": "Royell Communications Inc.",
        "rp_engineering_sas_di_romano_alessandro_c_s_a_s": "Rp Engineering Sas Di Romano Alessandro C.s.a.s.",
        "rr_provedor_de_internet_ltda": "Rr Provedor De Internet Ltda",
        "rr_servicos_ltda": "Rr Servicos Ltda",
        "rr_virtual_informatica": "Rr Virtual Informatica",
        "rta_svyaz_ltd": "Rta Svyaz Ltd",
        "rtc_communications": "Rtc Communications",
        "rtc_communications_corp": "Rtc Communications Corp.",
        "ruhruniversitaet_bochum": "Ruhr-universitaet Bochum",
        "ruko_golden_boulevard_blok_c_26": "Ruko Golden Boulevard Blok C.26",
        "runestone_telecom_association": "Runestone Telecom Association",
        "rural_broadband_network_services_llc": "Rural Broadband Network Services Llc",
        "rural_broadband_pvt_ltd": "Rural Broadband Pvt. Ltd",
        "rural_telephone_service_company_inc": "Rural Telephone Service Company Inc.",
        "ruscable_s_l": "Ruscable S. L.",
        "russellville_electric_plant_board": "Russellville Electric Plant Board",
        "rutgers_university": "Rutgers University",
        "rv_connect_telecomunicacoes_ltda_me": "Rv Connect Telecomunicacoes Ltda - Me.",
        "rvnet_r_v_portela_aguiar_cia_ltda": "Rvnet - R V Portela Aguiar Cia Ltda",
        "rvr_infrastructure_limited_service_provider_hyderabad_india": "Rvr Infrastructure Limited Service Provider Hyderabad India",
        "rz_net_ltda": "Rz Net Ltda.",
        "s_c_servicos_e_redes_ltda_me": "S. C. Servicos E Redes Ltda - Me",
        "s_o_do_brasil_telecomunicacoes_ltda_me": "S. O. Do Brasil Telecomunicacoes Ltda Me",
        "s_o_s_telecom_eireli_me": "S.o.s. Telecom Eireli Me",
        "s_t_communications_llc": "S T Communications Llc",
        "sabius_tecnologia": "Sabius Tecnologia",
        "sabre_glbl_inc": "Sabre Glbl Inc.",
        "sacramento_county_office_of_education": "Sacramento County Office Of Education",
        "saddle_mountain_wireless_inc": "Saddle Mountain Wireless Inc.",
        "saddleback_communications": "Saddleback Communications",
        "safaricom_limited": "Safaricom Limited",
        "safelink_internet": "Safelink Internet",
        "sagashimbun_co_ltd": "Sagashimbun Co. Ltd.",
        "sagenet_llc": "Sagenet Llc",
        "saginaw_valley_state_university": "Saginaw Valley State University",
        "sahara_network": "Sahara Network",
        "sai_ngn_network_services": "Sai Ngn Network Services",
        "sail_internet_inc": "Sail Internet Inc.",
        "saint_anselm_college": "Saint Anselm College",
        "saint_johns_university_college_of_saint_benedict": "Saint John's University - College Of Saint Benedict",
        "saint_josephs_university": "Saint Joseph's University",
        "saint_marys_college_of_california": "Saint Mary's College Of California",
        "saintpetersburg_computer_networks_ltd": "Saint-petersburg Computer Networks Ltd.",
        "sakhalin_cable_telesystems_ltd": "Sakhalin Cable Telesystems Ltd",
        "sakura_internet_inc": "Sakura Internet Inc.",
        "salem_state_university": "Salem State University",
        "salesforce_com_inc": "Salesforce.com Inc.",
        "salina_spavinaw_telephone_company_inc": "Salina Spavinaw Telephone Company Inc",
        "salish_networks_inc": "Salish Networks Inc.",
        "sallisaw_municipal_authority": "Sallisaw Municipal Authority",
        "salsgiver_inc": "Salsgiver Inc.",
        "salt_lake_community_college": "Salt Lake Community College",
        "salt_mobile_sa": "Salt Mobile Sa",
        "salt_river_project_agricultural_improvement_power_district": "Salt River Project Agricultural Improvement Power District",
        "salzburg_ag_fur_energie_verkehr_und_telekommunikation": "Salzburg Ag Fur Energie Verkehr Und Telekommunikation",
        "sam_houston_state_university": "Sam Houston State University",
        "samara_state_aerospace_university_sgau": "Samara State Aerospace University Sgau",
        "samart_corporation_co_ltd": "Samart Corporation Co. Ltd.",
        "sampaio_sampaio_provedores_de_internet_ltda": "Sampaio Sampaio Provedores De Internet Ltda",
        "sampark_estates_pvt_ltd": "Sampark Estates Pvt. Ltd.",
        "samsungsds_inc": "Samsungsds Inc.",
        "samuel_de_lucena_martins_epp": "Samuel De Lucena Martins - Epp",
        "san_bernardino_county_superintendent_of_schools": "San Bernardino County Superintendent Of Schools",
        "san_bruno_cable": "San Bruno Cable",
        "san_diego_county_office_of_education": "San Diego County Office Of Education",
        "san_francisco_department_of_telecommunications_and_information_services": "San Francisco Department Of Telecommunications And Information Services",
        "san_francisco_international_airport": "San Francisco International Airport",
        "san_francisco_unified_school_district": "San Francisco Unified School District",
        "san_jacinto_college_district": "San Jacinto College District",
        "san_jose_state_university": "San Jose State University",
        "san_juan_cable_llc": "San Juan Cable Llc",
        "san_juan_unified_school_district": "San Juan Unified School District",
        "san_marcos_consolidated_independent_school_district": "San Marcos Consolidated Independent School District",
        "sanchar_telenetwork_pvt_ltd": "Sanchar Telenetwork Pvt.ltd.",
        "sanchit_infocomm_pvt_ltd": "Sanchit Infocomm Pvt. Ltd.",
        "sandhill_telephone_cooperative_inc": "Sandhill Telephone Cooperative Inc.",
        "sandwich_isles_communications_inc": "Sandwich Isles Communications Inc",
        "sannikov_kirill_vladimirovich": "Sannikov Kirill Vladimirovich",
        "sanofi_aventis": "Sanofi Aventis",
        "sansara_telecom": "Sansara Telecom",
        "santa_ana_unified_school_district": "Santa Ana Unified School District",
        "santa_clara_county_office_of_education": "Santa Clara County Office Of Education",
        "santa_clara_university": "Santa Clara University",
        "santa_rosa_telephone_cooperative_inc": "Santa Rosa Telephone Cooperative Inc",
        "santo_tomas_eservice_providers": "Santo Tomas E-service Providers",
        "sao_miguel_telecomunicacoes_e_informatica_ltda_m": "Sao Miguel Telecomunicacoes E Informatica Ltda - M",
        "sap_america_inc": "Sap America Inc.",
        "sap_se": "Sap Se",
        "sappi_fine_paper_north_america": "Sappi Fine Paper North America",
        "sara_lee_corporation": "Sara Lee Corporation",
        "sarenet_s_a": "Sarenet S.a.",
        "sarl_icosnet": "Sarl Icosnet",
        "sasag_kabelkommunikation_ag": "Sasag Kabelkommunikation Ag",
        "saskatchewan_telecommunications": "Saskatchewan Telecommunications",
        "sasktel_wide_area_network_engineering_center": "Sasktel Wide Area Network Engineering Center",
        "sat_tv_plus_slu": "Sat Tv Plus Slu",
        "satlan_corporation_limited": "Satlan Corporation Limited",
        "satnet": "Satnet",
        "saudi_telecom_company_jsc": "Saudi Telecom Company Jsc",
        "savage_communications_inc": "Savage Communications Inc.",
        "savannah_college_of_art_and_design": "Savannah College Of Art And Design",
        "savecom_international_inc": "Savecom International Inc.",
        "savvis": "Savvis",
        "savvis_communications": "Savvis Communications",
        "sbr_telecom_pvt_ltd": "Sbr Telecom Pvt. Ltd",
        "scalematrix": "Scalematrix",
        "scan_plus_gmbh": "Scan Plus Gmbh",
        "scan_sat_network_s_l": "Scan Sat Network S.l.",
        "scancom_ltd": "Scancom Ltd.",
        "scansafe_services_llc": "Scansafe Services Llc",
        "scatplus_ltd": "Scatplus Ltd.",
        "scatter_creek_infonet_inc": "Scatter Creek Infonet Inc.",
        "scatui": "Scatui",
        "schat_net": "Schat.net",
        "schlumberger_limited": "Schlumberger Limited",
        "schneider_automation_inc": "Schneider Automation Inc.",
        "schnuck_markets_inc": "Schnuck Markets Inc.",
        "school_district_of_philadelphia": "School District Of Philadelphia",
        "scj_info_com_serv_de_informatica_ltda": "Scj Info Com. Serv. De Informatica Ltda",
        "scl_health_front_range_inc": "Scl Health Front Range Inc.",
        "scon_fibras": "Scon Fibras",
        "scotnet_co_uk": "Scotnet.co.uk",
        "scottsboro_electric_power_board": "Scottsboro Electric Power Board",
        "screen_saver_informatica_ltda": "Screen Saver Informatica Ltda",
        "scripps_health": "Scripps Health",
        "sdn_communications_inc": "Sdn Communications Inc.",
        "sdn_telecom_pvt_ltd": "Sdn Telecom Pvt Ltd",
        "seabra_informatica_ltda": "Seabra Informatica Ltda",
        "seacom_limited": "Seacom Limited",
        "seagate_technology": "Seagate Technology",
        "seanet_telecom_carazinho_eireli": "Seanet Telecom Carazinho Eireli",
        "seanet_telecom_ltda": "Seanet Telecom Ltda",
        "seaside_communications_inc": "Seaside Communications Inc.",
        "sebastian": "Sebastian",
        "sebratel_tecnologia_ltda": "Sebratel Tecnologia Ltda",
        "secom_inc": "Secom Inc",
        "secretaria_de_comunicaciones_y_transportes_coordinacion_de_la_sociedad_de_la_informacion_y_el_conoci": "Secretaria De Comunicaciones Y Transportes Coordinacion De La Sociedad De La Informacion Y El Conoci",
        "secure24_llc": "Secure-24 Llc",
        "secure_internet_llc": "Secure Internet Llc",
        "secured_servers_llc": "Secured Servers Llc",
        "seflow_s_n_c_di_marco_brame_c": "Seflow S.n.c. Di Marco Brame' C.",
        "seg_network_technologies": "Seg Network Technologies",
        "sei_data": "Sei Data",
        "seikei_eizo_network_inc": "Seikei Eizo Network Inc.",
        "selection_services_ltd": "Selection Services Ltd",
        "selectronics_corp": "Selectronics Corp.",
        "sem_limite_comunicacoes_ltda_me": "Sem Limite Comunicacoes Ltda - Me",
        "seminole_gaming": "Seminole Gaming",
        "seminole_state_college_of_florida": "Seminole State College Of Florida",
        "sena_wave_llc": "Sena Wave Llc",
        "senator_telecom_ltd": "Senator Telecom Ltd.",
        "sendai_catv_co_ltd": "Sendai Catv Co. Ltd.",
        "sentara_healthcare": "Sentara Healthcare",
        "sentinel_technologies_inc": "Sentinel Technologies Inc.",
        "sentris_network_llc": "Sentris Network Llc",
        "seokyung_cable_television_co_ltd": "Seokyung Cable Television Co..ltd.",
        "seoul_metropolitan_government": "Seoul Metropolitan Government",
        "seoul_national_university": "Seoul National University",
        "sercomtel_s_a": "Sercomtel S.a.",
        "sergio_cano_lopez": "Sergio Cano Lopez",
        "servcorp_smartoffice": "Servcorp Smartoffice",
        "server_central_network": "Server Central Network",
        "servers_australia_pty_ltd": "Servers Australia Pty Ltd",
        "service_alberta": "Service Alberta",
        "service_elements": "Service Elements",
        "service_provider_corporation": "Service Provider Corporation",
        "servicleop_sl": "Servicleop Sl",
        "servico_federal_de_processamento_de_dados_serpro": "Servico Federal De Processamento De Dados - Serpro",
        "servicos_de_provedores_de_acesso_as_redes_de_telec": "Servicos De Provedores De Acesso As Redes De Telec",
        "servihosting_networks_s_l": "Servihosting Networks S.l.",
        "servtec_telecom": "Servtec Telecom",
        "ses_americom_inc": "Ses Americom Inc.",
        "ses_sistemas_electronicos_s_a": "Ses Sistemas Electronicos S.a.",
        "setarnet": "Setarnet",
        "seti_weba_ltd": "Seti Weba Ltd",
        "seton_hall_university": "Seton Hall University",
        "seven_mobile_ltd": "Seven Mobile Ltd.",
        "seven_star_internet_service_provider": "Seven Star Internet Service Provider",
        "seven_star_ltd": "Seven Star Ltd.",
        "sglinknet_telecomunicacoes_ltdame": "Sglinknet Telecomunicacoes Ltda-me",
        "sh_turbo_internet_tec_e_empreendimentos": "Sh Turbo Internet Tec. E Empreendimentos",
        "shabakah_net": "Shabakah Net",
        "shah_infinite_solutions_pvt_ltd": "Shah Infinite Solutions Pvt. Ltd.",
        "shared_services_canada": "Shared Services Canada",
        "sharedband_london_network": "Sharedband London Network",
        "sharktech": "Sharktech",
        "sharp_healthcare": "Sharp Healthcare",
        "shaw_communications_inc": "Shaw Communications Inc.",
        "shaw_telecom_g_p": "Shaw Telecom G.p.",
        "shawneelink_corporation": "Shawneelink Corporation",
        "shelby_broadband": "Shelby Broadband",
        "shelby_electric_cooperative": "Shelby Electric Cooperative",
        "shell_information_technology_international_inc": "Shell Information Technology International Inc.",
        "shenandoah_university": "Shenandoah University",
        "sheng_li_telecom_india_private_limited": "Sheng Li Telecom India Private Limited",
        "shentel_communications_llc": "Shentel Communications Llc",
        "shenzhen_tencent_computer_systems_company_limited": "Shenzhen Tencent Computer Systems Company Limited",
        "shenzhen_topway_video_communication_co_ltd": "Shenzhen Topway Video Communication Co. Ltd.",
        "sheridan_college": "Sheridan College",
        "shinbiro": "Shinbiro",
        "shivansh_infotech_pvt_ltd": "Shivansh Infotech Pvt Ltd",
        "sho_me_technologies_llc": "Sho Me Technologies Llc",
        "shoreham_telephone_company_inc": "Shoreham Telephone Company Inc.",
        "shree_omkar_infocom_pvt_ltd": "Shree Omkar Infocom Pvt Ltd",
        "shrewsbury_electric_and_cable_operations": "Shrewsbury Electric And Cable Operations",
        "sia_lattelekom": "Sia Lattelekom",
        "sibirskaya_set_ltd": "Sibirskaya Set Ltd",
        "sibirskie_seti_ltd": "Sibirskie Seti Ltd.",
        "sibline_ltd": "Sibline Ltd.",
        "sibtranstelecom": "Sibtranstelecom",
        "sidera_networks_llc": "Sidera Networks Llc",
        "sidinara_dambros_da_silva_e_cia_ltda_epp": "Sidinara Dambros Da Silva E Cia Ltda Epp",
        "siemens_ag": "Siemens Ag",
        "sierra_leone_telecommunications_co_ltd": "Sierra Leone Telecommunications Co Ltd",
        "sierra_tel_internet": "Sierra Tel Internet",
        "sify_limited": "Sify Limited",
        "sigmatelecom_ltd": "Sigmatelecom Ltd",
        "signet_digital_pvt_ltd": "Signet Digital Pvt. Ltd.",
        "sikka_broadband_pvt_ltd": "Sikka Broadband Pvt. Ltd.",
        "sikka_cable": "Sikka Cable",
        "sikka_star_powered_by_sikka_broadband": "Sikka Star Powered By Sikka Broadband",
        "silo_wireless_inc": "Silo Wireless Inc",
        "silver_star_communications": "Silver Star Communications",
        "silverip_communications_inc": "Silverip Communications Inc.",
        "sim_headquarters": "Sim Headquarters",
        "sim_telecom_ltdaepp": "Sim Telecom Ltda-epp",
        "simbanet_t_limited": "Simbanet T Limited",
        "simcoe_county_district_school_board": "Simcoe County District School Board",
        "siminn_hf": "Siminn Hf",
        "simply_bits_llc": "Simply Bits Llc",
        "simply_transit_ltd": "Simply Transit Ltd",
        "simpur_isp": "Simpur Isp",
        "sinam": "Sinam",
        "sinectis_s_a": "Sinectis S.a.",
        "singapore_telecom_mobile_pte_ltd": "Singapore Telecom Mobile Pte Ltd",
        "single_digits_inc": "Single Digits Inc.",
        "singlehop_llc": "Singlehop Llc",
        "singnet_pte_ltd": "Singnet Pte Ltd",
        "singtel_mobile": "Singtel Mobile",
        "sinma_gmbh": "Sinma Gmbh",
        "sipartech_sas": "Sipartech Sas",
        "siportal_srl": "Siportal Srl",
        "sir_sandford_fleming_college_of_applied_arts_and_technology": "Sir Sandford Fleming College Of Applied Arts And Technology",
        "siskiyou_telephone_company": "Siskiyou Telephone Company",
        "sistec_telecom_s_l": "Sistec Telecom S.l.",
        "sistema_oeste_de_servicos_ltda": "Sistema Oeste De Servicos Ltda",
        "sistemy_svyazi_ltd": "Sistemy Svyazi Ltd.",
        "site_solucoes_em_inf_telec_e_eng_ltda_brsite": "Site Solucoes Em Inf. Telec. E Eng. Ltda. - Brsite",
        "siteserver_hosting_inc": "Siteserver Hosting Inc.",
        "siticable_network_limited": "Siticable Network Limited",
        "sits_ltd": "Sits Ltd",
        "six_degrees_managed_data_limited": "Six Degrees Managed Data Limited",
        "sjnet_telecomunicacoes_ltda": "Sjnet Telecomunicacoes Ltda.",
        "sjobergs_inc": "Sjoberg's Inc",
        "sk_broadband_co_ltd": "Sk Broadband Co Ltd",
        "sk_telecom": "Sk Telecom",
        "skatmedia_ltd": "Skat-media Ltd.",
        "skorpion_sistema_de_telecomunicacoes_ltda": "Skorpion Sistema De Telecomunicacoes Ltda",
        "skscom_llc": "Skscom Llc",
        "sky_brasil_servicos_ltda": "Sky Brasil Servicos Ltda.",
        "sky_dot_communication_limited": "Sky Dot Communication Limited",
        "sky_valley_network": "Sky Valley Network",
        "skybroadband_provincial_network": "Skybroadband Provincial Network",
        "skybroadband_skycable_corporation": "Skybroadband Skycable Corporation",
        "skyline_telephone": "Skyline Telephone",
        "skylogic_s_p_a": "Skylogic S.p.a.",
        "skymax_telecomunicacoes_ltda_me": "Skymax Telecomunicacoes Ltda Me",
        "skymesh_pty_ltd": "Skymesh Pty Ltd",
        "skynet": "Skynet",
        "skynet_country_llc": "Skynet Country Llc",
        "skynet_india_internet_services": "Skynet India Internet Services",
        "skynet_ltd": "Skynet Ltd.",
        "skynetlink_informatica_y_redes_sl": "Skynetlink Informatica Y Redes Sl",
        "skyparkcdn_ltd": "Skyparkcdn Ltd.",
        "skyriver_communications_inc": "Skyriver Communications Inc.",
        "skytron_communications_gmbh_co_kg": "Skytron Communications Gmbh Co Kg",
        "skywave_wireless_inc": "Skywave Wireless Inc.",
        "skywerx_industries_llc": "Skywerx Industries Llc",
        "slic_network_solutions_inc": "Slic Network Solutions Inc",
        "sll_computer_communication_system": "Sll Computer Communication System",
        "sm_networks": "Sm Networks",
        "smart_axiata_co_ltd": "Smart Axiata Co. Ltd.",
        "smart_broadband_incorporated": "Smart Broadband Incorporated",
        "smart_city_networks_l_p": "Smart City Networks L.p.",
        "smart_comp_a_s": "Smart Comp. A.s.",
        "smart_solucoes_tecnologicas": "Smart Solucoes Tecnologicas",
        "smart_telecom_networks_serv_de_telecom_ltda_m_e": "Smart Telecom Networks Serv.de Telecom. Ltda M.e",
        "smart_telecomunicacoes_e_servicos_eirelli_epp": "Smart Telecomunicacoes E Servicos Eirelli Epp",
        "smartcom_telephone_llc": "Smartcom Telephone Llc",
        "smarterbroadband": "Smarterbroadband",
        "smartlink_broadband_services_pvt_ltd": "Smartlink Broadband Services Pvt Ltd",
        "smartlink_solutions": "Smartlink Solutions",
        "smartlink_solutions_pvt_ltd": "Smartlink Solutions Pvt. Ltd.",
        "smartlinklink_broadband_services_pvt_ltd": "Smartlinklink Broadband Services Pvt Ltd",
        "smartone_mobile_communications_limited": "Smartone Mobile Communications Limited",
        "smile_communications_nigeria": "Smile Communications Nigeria",
        "smith_bagley_inc": "Smith Bagley Inc",
        "smithville_digital_llc": "Smithville Digital Llc",
        "smithville_telephone_co": "Smithville Telephone Co",
        "smlb_telecom": "Smlb Telecom",
        "smoltelecom_ltd": "Smoltelecom Ltd",
        "smurfit_kappa_the_americas": "Smurfit Kappa The Americas",
        "snap_internet_limited": "Snap Internet Limited",
        "snowball_effect_pyt_ltd": "Snowball Effect Pyt Ltd",
        "soares_peruzzo_ltda": "Soares Peruzzo Ltda",
        "sobralnet_servicos_e_telecomunicacoes_ltda_me": "Sobralnet Servicos E Telecomunicacoes Ltda - Me",
        "sociedad_andaluza_para_el_desarrollo_de_las_telecomunicaciones_s_a": "Sociedad Andaluza Para El Desarrollo De Las Telecomunicaciones S.a.",
        "sociedad_de_telecomunicaciones_netsouth_spa": "Sociedad De Telecomunicaciones Netsouth Spa",
        "societa_cooperativa_jcom": "Societa Cooperativa Jcom",
        "societe_electrique_des_forces_de_laubonne": "Societe Electrique Des Forces De L'aubonne",
        "societe_francaise_du_radiotelephone": "Societe Francaise Du Radiotelephone",
        "societe_internationale_de_telecommunications_aeronautiques_s_c_r_l": "Societe Internationale De Telecommunications Aeronautiques S.c.r.l.",
        "society_with_limited_liability_maglan": "Society With Limited Liability Maglan",
        "socket_internet_services_corporation": "Socket Internet Services Corporation",
        "socrates": "Socrates",
        "soderhamn_nara_ab": "Soderhamn Nara Ab",
        "sodetel_s_a_l": "Sodetel S.a.l.",
        "sofnet_inc": "Sofnet Inc.",
        "soft_call_custocare_private_limited": "Soft Call Cust-o-care Private Limited",
        "softbank_corp": "Softbank Corp.",
        "softcat_ltd": "Softcat Ltd",
        "softcom_internet_communications_inc": "Softcom Internet Communications Inc.",
        "softlayer_technologies_inc": "Softlayer Technologies Inc.",
        "software_technology_parks_of_india": "Software Technology Parks Of India",
        "sogetel_inc": "Sogetel Inc",
        "sohonet_limited": "Sohonet Limited",
        "soka_university": "Soka University",
        "solarus": "Solarus",
        "soltia_consulting_sl": "Soltia Consulting Sl",
        "soluciones_corporativas_ip_sl": "Soluciones Corporativas Ip Sl",
        "solucions_valencianes_i_noves_tecnologies_sl": "Solucions Valencianes I Noves Tecnologies Sl",
        "solway_communications_limited": "Solway Communications Limited",
        "somerville": "Somerville",
        "somtel": "Somtel",
        "sonatel": "Sonatel",
        "sonatel_societe_nationale_des_telecommunications_du_senegal": "Sonatel Societe Nationale Des Telecommunications Du Senegal",
        "sonet_entertainment_corporation": "So-net Entertainment Corporation",
        "sonic_net_inc": "Sonic.net Inc.",
        "sonoma_county_office_of_education": "Sonoma County Office Of Education",
        "sony_network_communications_inc": "Sony Network Communications Inc.",
        "sotrudnik_ltd": "Sotrudnik Ltd",
        "source_cable_ltd": "Source Cable Ltd.",
        "south_arkansas_telephone_company": "South Arkansas Telephone Company",
        "south_canaan_telephone_company": "South Canaan Telephone Company",
        "south_central_communications_inc": "South Central Communications Inc.",
        "south_central_ohio_computer_association": "South Central Ohio Computer Association",
        "south_central_rural_telecommunications_cooperative_inc": "South Central Rural Telecommunications Cooperative Inc.",
        "south_central_telephone_association": "South Central Telephone Association",
        "south_dakota_network": "South Dakota Network",
        "south_dakota_state_government": "South Dakota State Government",
        "south_dakota_state_university": "South Dakota State University",
        "south_east_asia_telecom_cambodia_co_ltd": "South East Asia Telecom Cambodia Co. Ltd",
        "south_slope_cooperative_telephone_company": "South Slope Cooperative Telephone Company",
        "south_texas_college": "South Texas College",
        "south_valley_internet": "South Valley Internet",
        "south_west_grid_for_learning_trust_ltd": "South West Grid For Learning Trust Ltd.",
        "southeastern_louisiana_university": "Southeastern Louisiana University",
        "southern_adventist_university": "Southern Adventist University",
        "southern_alberta_institute_of_technology": "Southern Alberta Institute Of Technology",
        "southern_fibernet_corporation": "Southern Fibernet Corporation",
        "southern_illinois_university": "Southern Illinois University",
        "southern_illinois_university_at_edwardsville": "Southern Illinois University At Edwardsville",
        "southern_kansas_telephone_co_inc": "Southern Kansas Telephone Co. Inc.",
        "southern_light_llc": "Southern Light Llc",
        "southern_methodist_university": "Southern Methodist University",
        "southern_new_england_telephone_company_and_snet_america_inc": "Southern New England Telephone Company And Snet America Inc.",
        "southern_new_hampshire_university": "Southern New Hampshire University",
        "southern_ohio_communication_services": "Southern Ohio Communication Services",
        "southern_online_bio_technologies_ltd": "Southern Online Bio Technologies Ltd",
        "southern_university": "Southern University",
        "southwest_arkansas_telephone_cooperative": "Southwest Arkansas Telephone Cooperative",
        "southwest_minnesota_broadband_services": "Southwest Minnesota Broadband Services",
        "southwest_ohio_computer_association": "Southwest Ohio Computer Association",
        "southwest_texas_telephone_company": "Southwest Texas Telephone Company",
        "southwestern_adventist_university": "Southwestern Adventist University",
        "southwestern_wireless_inc": "Southwestern Wireless Inc.",
        "southwire_company": "Southwire Company",
        "sovernet_inc": "Sovernet Inc.",
        "sp2telecom_ltda_me": "Sp2telecom Ltda Me",
        "space_network_informatica_ltda": "Space Network Informatica Ltda",
        "spacenet_ag": "Spacenet Ag",
        "spacenet_internet_services_pvt_ltd": "Spacenet Internet Services Pvt. Ltd.",
        "spacetel_benin_sa": "Spacetel Benin Sa",
        "spanish_fork_city": "Spanish Fork City",
        "spark_new_zealand_trading_limited": "Spark New Zealand Trading Limited",
        "spartan_stores_inc": "Spartan Stores Inc.",
        "spartanburg_regional_health_services_district_inc": "Spartanburg Regional Health Services District Inc.",
        "spartannet": "Spartan-net",
        "special_engineering_and_design_bureau_orbita_jsc": "Special Engineering And Design Bureau Orbita Jsc",
        "spectranet_limited": "Spectranet Limited",
        "spectrum_health": "Spectrum Health",
        "spectrum_internet_ltd": "Spectrum Internet Ltd",
        "spectrum_online_services_llc": "Spectrum Online Services Llc",
        "spectrums_core_network": "Spectrums Core Network",
        "speed_communication": "Speed Communication",
        "speed_net_servicos_de_telecomunicacoes_ltdame": "Speed Net Servicos De Telecomunicacoes Ltda-me",
        "speedconnect_llc": "Speedconnect Llc",
        "speednet_llc": "Speednet Llc",
        "speednet_provedor_de_acesso_a_internet_ltda": "Speednet Provedor De Acesso A Internet Ltda",
        "speedy_networks_llc": "Speedy Networks Llc",
        "sphier_infotech_private_limited": "Sphier Infotech Private Limited",
        "spiderlink_networks_pvt_ltd": "Spiderlink Networks Pvt Ltd",
        "spintel": "Spintel",
        "spiral_solutions_and_technologies_inc": "Spiral Solutions And Technologies Inc.",
        "spirit_communications": "Spirit Communications",
        "spirit_telecom_australia_pty_ltd": "Spirit Telecom Australia Pty Ltd",
        "spitfire_network_services_limited": "Spitfire Network Services Limited",
        "spolek_bubakov_net": "Spolek Bubakov.net",
        "spot_solution_tecnologia_em_internet_ltda_me": "Spot Solution Tecnologia Em Internet Ltda - Me",
        "spotsylvania_county_school_board": "Spotsylvania County School Board",
        "spring_branch_independent_school_district": "Spring Branch Independent School District",
        "spring_city_cable_tv_inc": "Spring City Cable Tv Inc",
        "springnet": "Springnet",
        "springs_industries_inc": "Springs Industries Inc.",
        "sprint": "Sprint",
        "sprint_ltd": "Sprint Ltd.",
        "sprintlink_global_network": "Sprintlink Global Network",
        "sprocket_communications_llc": "Sprocket Communications Llc",
        "spruce_knob_seneca_rocks_telephone_inc": "Spruce Knob Seneca Rocks Telephone Inc.",
        "squid_net_telecomunicacoes_ltda_me": "Squid Net Telecomunicacoes Ltda Me",
        "sri_lanka_telecom": "Sri Lanka Telecom",
        "srinagar_net_tech_p_ltd": "Srinagar Net Tech P Ltd",
        "srt_communications_inc": "Srt Communications Inc.",
        "ss_netcom_pvt_ltd": "Ss Netcom Pvt. Ltd.",
        "st_catherine_university": "St. Catherine University",
        "st_edwards_university": "St. Edwards University",
        "st_john_fisher_college": "St. John Fisher College",
        "st_johns_university_new_york": "St. John's University New York",
        "st_lawrence_university": "St. Lawrence University",
        "st_louis_university": "St. Louis University",
        "st_lukes_episcopal_hospital": "St. Luke's Episcopal Hospital",
        "st_lukes_medical_center_inc": "St. Luke's Medical Center Inc.",
        "st_lukes_roosevelt_hospital_center": "St. Luke's Roosevelt Hospital Center",
        "st_michaels_college": "St Michael's College",
        "st_olaf_college": "St. Olaf College",
        "stadtgemeindestadtwerke_imst": "Stadtgemeindestadtwerke Imst",
        "stadtnetz_bamberg_gesellschaft_fuer_telekommunikation_mbh": "Stadtnetz Bamberg Gesellschaft Fuer Telekommunikation Mbh",
        "stadtwerke_finsterwalde_gmbh": "Stadtwerke Finsterwalde Gmbh",
        "stadtwerke_hall_in_tirol_gmbh": "Stadtwerke Hall In Tirol Gmbh",
        "stadtwerke_konstanz_gmbh": "Stadtwerke Konstanz Gmbh",
        "stafford_county_public_schools": "Stafford County Public Schools",
        "stanford_hospital_and_clinics": "Stanford Hospital And Clinics",
        "stanford_university": "Stanford University",
        "stanislaus_county_office_of_education": "Stanislaus County Office Of Education",
        "star_broadband_services": "Star Broadband Services",
        "star_net_divino_ltda_epp": "Star Net Divino Ltda - Epp",
        "star_telecom": "Star Telecom",
        "star_telecom_sa": "Star Telecom Sa",
        "starbucks_coffee_company": "Starbucks Coffee Company",
        "starhub_cable_vision_ltd": "Starhub Cable Vision Ltd",
        "starhub_internet_pte_ltd": "Starhub Internet Pte Ltd",
        "stark_portage_area_computer_consortium": "Stark Portage Area Computer Consortium",
        "starry_inc": "Starry Inc.",
        "start_communications": "Start Communications",
        "start_telecomunicacoes_ltda_epp": "Start Telecomunicacoes Ltda - Epp",
        "startouch_inc": "Startouch Inc",
        "starvision_inc": "Starvision Inc.",
        "state_educational_institution_of_higher_professional_education_moscow_state_university_of_railway_engineering": "State Educational Institution Of Higher Professional Education Moscow State University Of Railway Engineering",
        "state_fair_community_college": "State Fair Community College",
        "state_institute_of_information_technologies_and": "State Institute Of Information Technologies And",
        "state_of_arizona": "State Of Arizona",
        "state_of_arkansas": "State Of Arkansas",
        "state_of_arkansas_department_of_informationsystems": "State Of Arkansas Department Of Informationsystems",
        "state_of_connecticut": "State Of Connecticut",
        "state_of_delaware": "State Of Delaware",
        "state_of_maryland": "State Of Maryland",
        "state_of_minnesota": "State Of Minnesota",
        "state_of_montana": "State Of Montana",
        "state_of_nc_state_telecommunications_services": "State Of Nc - State Telecommunications Services",
        "state_of_nebraska_office_of_the_chief_information_officer": "State Of Nebraska - Office Of The Chief Information Officer",
        "state_of_north_dakota": "State Of North Dakota",
        "state_of_north_dakota_itd": "State Of North Dakota Itd",
        "state_of_oregon": "State Of Oregon",
        "state_of_tennessee": "State Of Tennessee",
        "state_of_tennessee_department_of_education": "State Of Tennessee Department Of Education",
        "state_of_washington": "State Of Washington",
        "state_street_bank_and_trust_company": "State Street Bank And Trust Company",
        "state_university_of_new_york_at_buffalo": "State University Of New York At Buffalo",
        "state_university_of_new_york_at_stony_brook": "State University Of New York At Stony Brook",
        "state_university_of_new_york_college_oftechnology_at_alfred": "State University Of New York College Oftechnology At Alfred",
        "state_university_of_new_york_health_science_center_at_syracuse": "State University Of New York Health Science Center At Syracuse",
        "state_university_of_new_york_oswego": "State University Of New York - Oswego",
        "static_ip_services": "Static Ip Services",
        "status_equipamentos_de_telecomunicacoes_ltda_epp": "Status Equipamentos De Telecomunicacoes Ltda - Epp",
        "steadfast_networks": "Steadfast Networks",
        "stealth_broadband_llc": "Stealth Broadband Llc",
        "stealth_communications": "Stealth Communications",
        "steelcase_sa": "Steelcase Sa",
        "stel_s_r_l": "Stel S.r.l.",
        "stellar_association_llc": "Stellar Association Llc",
        "stellford_llc": "Stellford Llc",
        "stentor_national_integrated_communications_network": "Stentor National Integrated Communications Network",
        "stephen_f_austin_state_university": "Stephen F. Austin State University",
        "stevens_institute_of_technology": "Stevens Institute Of Technology",
        "stiadsl_s_r_l": "Stiadsl S.r.l",
        "stiegeler_internet_service_gmbh_co_kg": "Stiegeler Internet Service Gmbh Co. Kg",
        "stnet_incorporated": "Stnet Incorporated",
        "stockholms_lans_landsting": "Stockholms Lans Landsting",
        "stofa_as": "Stofa As",
        "stonehill_college": "Stonehill College",
        "storm_internet_services": "Storm Internet Services",
        "stowe_access_llc": "Stowe Access Llc",
        "stp_nuclear_operating_company": "Stp Nuclear Operating Company",
        "strato_ag": "Strato Ag",
        "stratus_networks": "Stratus Networks",
        "stream_networks_ltd": "Stream Networks Ltd",
        "streamline_network_solutions_pty_ltd": "Streamline Network Solutions Pty Ltd",
        "streetnet_telecom": "Streetnet Telecom",
        "strong_technology_llc": "Strong Technology Llc.",
        "stylecom": "Style-com",
        "subnet_llc": "Subnet Llc",
        "succeed_net": "Succeed.net",
        "success_ltd": "Success Ltd",
        "suddenlink_communications": "Suddenlink Communications",
        "suec_dacor_gmbh": "Suec Dacor Gmbh",
        "suffolk_county_government": "Suffolk County Government",
        "suffolk_university": "Suffolk University",
        "suis_boga_telecom_sl": "Suis Boga Telecom Sl",
        "sulcom_informatica_ltda": "Sulcom Informatica Ltda",
        "sum_sistema_da_informacao_ltda": "Sum Sistema Da Informacao Ltda",
        "summa_health_system": "Summa Health System",
        "summa_telecom_llc": "Summa Telecom Llc",
        "summit_broadband": "Summit Broadband",
        "summit_communications_ltd": "Summit Communications Ltd",
        "summit_communications_system_inc": "Summit Communications System Inc.",
        "sumofiber": "Sumofiber",
        "sun_broadband_and_data_services_pvt_ltd": "Sun Broadband And Data Services Pvt Ltd",
        "sun_dot_communications_llc": "Sun Dot Communications Llc.",
        "sun_network_hong_kong_limited": "Sun Network Hong Kong Limited",
        "sungard_network_solutions_inc": "Sungard Network Solutions Inc.",
        "sungard_vericenter_inc": "Sungard Vericenter Inc",
        "sunlink_telecom_isp_tula_russia": "Sunlink Telecom Isp Tula Russia",
        "sunrise_communications_ag": "Sunrise Communications Ag",
        "sunset_digital_communications_inc": "Sunset Digital Communications Inc.",
        "sunwire_inc": "Sunwire Inc.",
        "suny_college_at_cortland": "Suny College At Cortland",
        "suny_college_at_fredonia": "Suny College At Fredonia",
        "suny_college_at_new_paltz": "Suny College At New Paltz",
        "suny_college_at_plattsburgh": "Suny College At Plattsburgh",
        "suny_geneseo_computer_center": "Suny Geneseo Computer Center",
        "suny_institute_of_technology": "Suny Institute Of Technology",
        "super_cabo_tv_caratinga_ltda": "Super Cabo Tv Caratinga Ltda",
        "super_midia_tv_a_cabo_ltda": "Super Midia Tv A Cabo Ltda",
        "superimagem_tecnologia_em_eletronica_ltda": "Superimagem Tecnologia Em Eletronica Ltda",
        "superinternet_access_pte_ltd": "Superinternet Access Pte Ltd",
        "superloop_australia_pty_ltd": "Superloop Australia Pty Ltd",
        "superonline_iletisim_hizmetleri_a_s": "Superonline Iletisim Hizmetleri A.s.",
        "supranet_communications_inc": "Supranet Communications Inc.",
        "supremebytes_llc": "Supremebytes Llc",
        "suraj_network": "Suraj Network",
        "surewest_kansas_operations_llc": "Surewest Kansas Operations Llc",
        "surf_air_wireless_llc": "Surf Air Wireless Llc",
        "surfnet_bv": "Surfnet Bv",
        "surfnet_communications_inc": "Surfnet Communications Inc.",
        "surfnet_the_netherlands": "Surfnet The Netherlands",
        "surry_telephone_membership_corporation": "Surry Telephone Membership Corporation",
        "susquehanna_university": "Susquehanna University",
        "sutter_health": "Sutter Health",
        "suvoz_global_services_s_l": "Suvoz Global Services S.l.",
        "suzuka_cable_co_ltd": "Suzuka Cable Co. Ltd",
        "sviazatominform_ltd_company": "Sviazatominform Ltd. Company",
        "svstelecom_ltd": "Svs-telecom Ltd.",
        "svvsd": "Svvsd",
        "svyazenergo_ltd": "Svyaz-energo Ltd.",
        "svyazholding_ltd": "Svyaz-holding Ltd.",
        "svyazservis_ltd": "Svyazservis Ltd.",
        "sw_comercio_e_servicos_de_comunicacao_multimidia": "Sw Comercio E Servicos De Comunicacao Multimidia",
        "sw_telecomunicacoes_e_informatica_itu_ltda_me": "Sw Telecomunicacoes E Informatica Itu Ltda. - Me",
        "swagelok_company": "Swagelok Company",
        "swastik_internet_and_cables_pvt_ltd": "Swastik Internet And Cables Pvt. Ltd.",
        "swc_telesolutions_inc": "Swc Telesolutions Inc.",
        "sweet_briar_college": "Sweet Briar College",
        "swift_networks_limited": "Swift Networks Limited",
        "swiftel_communications": "Swiftel Communications",
        "swiftmail_communications_limited": "Swiftmail Communications Limited",
        "swiftnet_broadband_private_limited": "Swiftnet Broadband Private Limited",
        "swiftway_communications_inc": "Swiftway Communications Inc",
        "swiftway_sp_z_o_o": "Swiftway Sp. Z O.o.",
        "swisscom_schweiz_ag": "Swisscom Schweiz Ag",
        "swisscom_switzerland_ltd": "Swisscom Switzerland Ltd",
        "switch": "Switch",
        "switch_ip_ltd": "Switch Ip Ltd",
        "switch_ltd": "Switch Ltd",
        "switch_the_swiss_education_and_research_network": "Switch The Swiss Education And Research Network",
        "swoi": "Swoi",
        "swu_telenet_gmbh": "Swu Telenet Gmbh",
        "swwc_service_cooperatives": "Swwc Service Cooperatives",
        "sychrovnet_s_r_o": "Sychrovnet S.r.o.",
        "symantec_corporation": "Symantec Corporation",
        "symbio_networks": "Symbio Networks",
        "symphonet_sdn_bhd": "Symphonet Sdn Bhd",
        "syn_ltd": "Syn Ltd",
        "synapse_co_ltd": "Synapse Co. Ltd.",
        "syndeo_networks_inc": "Syndeo Networks Inc",
        "synergy_broadband": "Synergy Broadband",
        "synopsys_inc": "Synopsys Inc.",
        "syracuse_university": "Syracuse University",
        "syringa_networks_llc": "Syringa Networks Llc",
        "syscon_infoway_pvt_ltd": "Syscon Infoway Pvt. Ltd.",
        "sysdatacom_s_r_o": "Sys-datacom S.r.o.",
        "syseleven_gmbh": "Syseleven Gmbh",
        "sysmex": "Sysmex",
        "system_ltd": "System Ltd",
        "system_net_ltd": "System Net Ltd",
        "systema_ltd": "Systema Ltd.",
        "systemmetrics_corporation": "Systemmetrics Corporation",
        "systemsfox_prestacao_de_servicos_ltda": "Systemsfox Prestacao De Servicos Ltda",
        "t10_networks": "T10 Networks",
        "t2_d_o_o": "T-2 D.o.o.",
        "t2_mobile_llc": "T2 Mobile Llc",
        "t_a_luiz_eletronico_ltda_me": "T.a.luiz Eletronico Ltda - Me",
        "t_f_da_silva_ramos_telecomunicacoes_e_sistema_epp": "T F Da Silva Ramos Telecomunicacoes E Sistema Epp",
        "tacom": "Tacom",
        "taiwan_academic_network": "Taiwan Academic Network",
        "taiwan_fixed_network_co_ltd": "Taiwan Fixed Network Co. Ltd.",
        "taiwan_fixed_network_telco_and_network_service_provider": "Taiwan Fixed Network Telco And Network Service Provider.",
        "taiwan_infrastructure_network_technologie": "Taiwan Infrastructure Network Technologie",
        "taiwan_mobile_co_ltd": "Taiwan Mobile Co. Ltd.",
        "talk_internet_limited": "Talk Internet Limited",
        "talk_straight_ltd": "Talk Straight Ltd.",
        "talktalk_communications_limited": "Talktalk Communications Limited",
        "tango_s_a": "Tango S.a.",
        "tannan_cable_television_corporation": "Tannan Cable Television Corporation",
        "tanzania_telecommunications_co_ltd": "Tanzania Telecommunications Co. Ltd",
        "taos_net_llc": "Taos Net Llc",
        "targo_communications_inc": "Targo Communications Inc.",
        "tascom_telecomunicacoes_ltda": "Tascom Telecomunicacoes Ltda",
        "tashicell_national_network": "Tashicell National Network",
        "tata_communications": "Tata Communications",
        "tata_communications_america_inc": "Tata Communications America Inc",
        "tata_communications_canada_ltd": "Tata Communications Canada Ltd.",
        "tata_communications_internet_services_ltd": "Tata Communications Internet Services Ltd",
        "tata_teleservices_isp": "Tata Teleservices Isp",
        "tata_teleservices_limited": "Tata Teleservices Limited",
        "tata_teleservices_maharashtra_ltd": "Tata Teleservices Maharashtra Ltd",
        "taunton_municipal_lighting_plant": "Taunton Municipal Lighting Plant",
        "taylor_fresh_foods_inc": "Taylor Fresh Foods Inc.",
        "taylor_telephone_cooperative_inc": "Taylor Telephone Cooperative Inc.",
        "taylor_university": "Taylor University",
        "tbaytel": "Tbaytel",
        "tbilisi_city_hall": "Tbilisi City Hall",
        "tbnet_informatica_ltda": "Tbnet Informatica Ltda",
        "tbroad_suwon_broadcasting_corporation": "Tbroad Suwon Broadcasting Corporation",
        "tc_net": "Tc Net",
        "tcf_telecomunicacoes_campo_florido_ltda": "Tcf Telecomunicacoes Campo Florido Ltda",
        "tct_west_inc": "Tct West Inc.",
        "td_pr_arlu_s_a": "Td Pr Arlu S.a",
        "tdc_as": "Tdc As",
        "tds_telecom": "Tds Telecom",
        "te_data": "Te Data",
        "team_madison_partners_llc": "Team Madison Partners Llc.",
        "tec_of_jackson_inc": "Tec Of Jackson Inc.",
        "tecbuy_telecomunicacoes_ltda_me": "Tecbuy Telecomunicacoes Ltda - Me",
        "tech_valley_communications": "Tech Valley Communications",
        "techavenue_sdn_bhd": "Techavenue Sdn Bhd",
        "techcom_comunicacao_comercio_e_servicos_ltdame": "Techcom Comunicacao Comercio E Servicos Ltda-me",
        "technet_informatica_ltda": "Technet Informatica Ltda",
        "technical_university_of_gdansk_academic_computer_center_task": "Technical University Of Gdansk Academic Computer Center Task",
        "technicaltrade_centre_radiotekhnika_ltd": "Technical-trade Centre Radiotekhnika Ltd.",
        "technology_network_communication_access_llc": "Technology Network Communication Access Llc",
        "technology_networks_co": "Technology Networks Co.",
        "techtel_lmds_comunicaciones_interactivas_s_a": "Techtel Lmds Comunicaciones Interactivas S.a.",
        "tecnologicawifi_as": "Tecnologicawifi As",
        "tecnotel_servizi_tecnologici_srl": "Tecnotel Servizi Tecnologici Srl",
        "tecnowireless_telecom_ltda": "Tecnowireless Telecom Ltda",
        "tefnet_tecnologia": "Tefnet Tecnologia",
        "tegna_inc": "Tegna Inc.",
        "tehno_limited_company": "Tehno Limited Company",
        "tekfinity": "Tekfinity",
        "tekify_broadband": "Tekify Broadband",
        "teklinks_inc": "Teklinks Inc.",
        "teksavvy_solutions_inc": "Teksavvy Solutions Inc.",
        "telcoinabox": "Telcoinabox",
        "telcom_networks_limited": "Telcom Networks Limited",
        "telcom_supply": "Telcom Supply",
        "tele2_nederland_b_v": "Tele2 Nederland B.v.",
        "tele2_sverige_ab": "Tele2 Sverige Ab",
        "tele_2_nederland_b_v": "Tele 2 Nederland B.v.",
        "tele_ag": "Tele Ag",
        "tele_alhama_s_l": "Tele Alhama S.l",
        "tele_columbus_ag": "Tele Columbus Ag",
        "tele_elda_s_a": "Tele Elda S.a",
        "telebec": "Telebec",
        "telebit_corporation": "Telebit Corporation",
        "telebucaramanga_s_a_e_s_p": "Telebucaramanga S.a. E.s.p.",
        "telecable_de_asturias_sa": "Telecable De Asturias Sa",
        "telecable_economico_s_a": "Telecable Economico S.a.",
        "telecel_faso": "Telecel Faso",
        "telecel_s_a": "Telecel S.a.",
        "telecentr_ltd": "Telecentr Ltd.",
        "telecentro_s_a": "Telecentro S.a.",
        "telecitygroup_international_limited": "Telecitygroup International Limited",
        "telecom3_sverige_ab": "Telecom3 Sverige Ab",
        "telecom_algeria": "Telecom Algeria",
        "telecom_argentina_s_a": "Telecom Argentina S.a.",
        "telecom_center_llc": "Telecom Center Llc",
        "telecom_cordeiropolis_ltda_me": "Telecom Cordeiropolis Ltda - Me",
        "telecom_italia_s_p_a": "Telecom Italia S.p.a.",
        "telecom_italia_spa": "Telecom Italia Spa",
        "telecom_italia_sparkle_s_p_a": "Telecom Italia Sparkle S.p.a.",
        "telecom_ltd": "Telecom Ltd",
        "telecom_ltda_me": "Telecom Ltda Me",
        "telecom_malagasy": "Telecom Malagasy",
        "telecom_namibia": "Telecom Namibia",
        "telecom_networks_malawi_ltd": "Telecom Networks Malawi Ltd",
        "telecom_ru_ltd": "Telecom.ru Ltd",
        "telecom_south_america_sa": "Telecom South America Sa",
        "telecommunication_company_varna_ead": "Telecommunication Company Varna Ead",
        "telecommunication_services_of_trinidad_and_tobago": "Telecommunication Services Of Trinidad And Tobago",
        "telecommunicationcompany_suriname_telesur": "Telecommunicationcompany Suriname - Telesur",
        "telecompany_fialka_ltd": "Telecompany Fialka Ltd.",
        "telecompany_viza2_cjsc": "Telecompany Viza-2 Cjsc.",
        "telecomunicacions_garrotxa_sl": "Telecomunicacions Garrotxa Sl",
        "telecomunicacoes_brasileiras_s_a_telebras": "Telecomunicacoes Brasileiras S. A. - Telebras",
        "telecomunicacoes_nordeste_ltda": "Telecomunicacoes Nordeste Ltda.",
        "telecuatro_c_b": "Telecuatro C.b.",
        "teledata_gmbh": "Teledata Gmbh",
        "teledata_ict_ltd": "Teledata Ict Ltd",
        "teledifusora_s_a": "Teledifusora S.a.",
        "telefiber_as": "Telefiber As",
        "telefonaktiebolaget_lm_ericsson": "Telefonaktiebolaget Lm Ericsson",
        "telefonica_brasil_s_a": "Telefonica Brasil S.a.",
        "telefonica_celular_de_bolivia_s_a": "Telefonica Celular De Bolivia S.a.",
        "telefonica_chile_s_a": "Telefonica Chile S.a.",
        "telefonica_data_s_a": "Telefonica Data S.a.",
        "telefonica_de_argentina": "Telefonica De Argentina",
        "telefonica_de_espana": "Telefonica De Espana",
        "telefonica_del_peru_s_a_a": "Telefonica Del Peru S.a.a.",
        "telefonica_del_sur_s_a": "Telefonica Del Sur S.a.",
        "telefonica_empresas": "Telefonica Empresas",
        "telefonica_moviles_argentina_s_a_movistar_argentina": "Telefonica Moviles Argentina S.a. Movistar Argentina",
        "telefonica_moviles_espana_s_a_u": "Telefonica Moviles Espana S.a.u.",
        "telefonica_moviles_guatemala_s_a": "Telefonica Moviles Guatemala S.a.",
        "telefonica_o2_czech_republic_a_s": "Telefonica O2 Czech Republic A.s.",
        "telefonica_o2_germany_gmbh_co_ohg": "Telefonica O2 Germany Gmbh Co.ohg",
        "telefonica_uk_limited": "Telefonica Uk Limited",
        "telefonica_usa_inc": "Telefonica Usa Inc.",
        "telefonos_del_noroeste_s_a_de_c_v": "Telefonos Del Noroeste S.a. De C.v.",
        "teleguam_holdings_llc": "Teleguam Holdings Llc",
        "telekenex_inc": "Telekenex Inc",
        "telekom_malaysia_berhad": "Telekom Malaysia Berhad",
        "telekom_romania_mobile_communications_s_a": "Telekom Romania Mobile Communications S.a.",
        "telekom_srbija_a_d": "Telekom Srbija A.d.",
        "telekommunikation_lindau_gmbh": "Telekommunikation Lindau Gmbh",
        "telemach_d_o_o": "Telemach D.o.o.",
        "telemach_d_o_o_sarajevo": "Telemach D.o.o. Sarajevo",
        "telemar_norte_leste_s_a": "Telemar Norte Leste S.a.",
        "telemidia_sistema_de_telecomunicacao_ltda": "Telemidia Sistema De Telecomunicacao Ltda",
        "telemos_multimidia_ltda": "Telemos Multimidia Ltda",
        "telenet_n_v": "Telenet N.v.",
        "telenor_as": "Telenor As",
        "telenor_hungary_plc": "Telenor Hungary Plc",
        "telenor_india_communications_pvt_ltd": "Telenor India Communications Pvt. Ltd.",
        "telenor_myanmar": "Telenor Myanmar",
        "telenor_norge_as": "Telenor Norge As",
        "telenor_pakistan_ltd": "Telenor Pakistan Ltd",
        "telenor_sverige_ab": "Telenor Sverige Ab",
        "telentia": "Telentia",
        "telepacific_communications": "Telepacific Communications",
        "telepark_ltd": "Telepark Ltd",
        "teleplus_llc": "Tele-plus Llc",
        "teleradiokompaniya_kandalaksha_ltd": "Teleradiokompaniya Kandalaksha Ltd.",
        "teletalk_bangladesh_ltd": "Teletalk Bangladesh Ltd.",
        "teletime_ltd": "Teletime Ltd.",
        "televideo_novelda_s_a_unipersonal": "Televideo Novelda S.a. Unipersonal",
        "television_cable_digital_s_l": "Television Cable Digital S.l.",
        "television_costa_blanca_sl": "Television Costa Blanca Sl",
        "television_internacional_s_a_de_c_v": "Television Internacional S.a. De C.v.",
        "television_por_cable_santa_pola_s_l": "Television Por Cable Santa Pola S.l.",
        "telexair_telecom_pvt_ltd": "Telexair Telecom Pvt Ltd",
        "telia_eesti_as": "Telia Eesti As",
        "teliasonera_ab": "Teliasonera Ab",
        "teliasonera_finland_oyj": "Teliasonera Finland Oyj",
        "teliasonera_norge_as": "Teliasonera Norge As",
        "teliko_gmbh": "Teliko Gmbh",
        "telitec_connections_s_l": "Telitec Connections S.l.",
        "telix_llc": "Telix Llc",
        "telko_jsc": "Telko Jsc",
        "telkom": "Telkom",
        "telkom_sa_ltd": "Telkom Sa Ltd.",
        "telkomsel": "Telkomsel",
        "tellcom_iletisim_hizmetleri_a_s": "Tellcom Iletisim Hizmetleri A.s.",
        "telmex_chile_internet_s_a": "Telmex Chile Internet S.a.",
        "telmex_colombia_s_a": "Telmex Colombia S.a.",
        "telmex_servicios_empresariales_s_a": "Telmex Servicios Empresariales S.a.",
        "telplay_sl": "Telplay Sl",
        "telstar_communications_inc": "Tel-star Communications Inc",
        "telstra_global_internet_services_network_blocks": "Telstra Global Internet Services Network Blocks",
        "telstra_internet": "Telstra Internet",
        "telstra_limited": "Telstra Limited",
        "telstra_pty_ltd": "Telstra Pty Ltd",
        "telus_communications_inc": "Telus Communications Inc.",
        "telx": "Telx",
        "tely_ltda": "Tely Ltda.",
        "temple_university": "Temple University",
        "tencent_building_kejizhongyi_avenue": "Tencent Building Kejizhongyi Avenue",
        "tenet_scientific_production_enterprise_llc": "Tenet Scientific Production Enterprise Llc",
        "tennessee_state_university": "Tennessee State University",
        "terago_networks_inc": "Terago Networks Inc.",
        "terra_networks_brasil_s_a": "Terra Networks Brasil S.a.",
        "terralink_llc": "Terralink Llc",
        "terranet_sal": "Terranet Sal",
        "terranovanet": "Terranovanet",
        "terratransit_ag": "Terratransit Ag",
        "terrecablate_reti_e_servizi_s_r_l": "Terrecablate Reti E Servizi S.r.l.",
        "terremark": "Terremark",
        "terrenap_data_centers_inc": "Terrenap Data Centers Inc.",
        "testengeer_inc": "Testengeer Inc",
        "tetralan_llc": "Tetralan Llc",
        "texaco_group_inc": "Texaco Group Inc.",
        "texas_am_international_university": "Texas Am International University",
        "texas_am_university": "Texas Am University",
        "texas_am_university_corpus_christi": "Texas Am University Corpus Christi",
        "texas_am_university_system_health_science_center": "Texas Am University System Health Science Center",
        "texas_cellnet_inc": "Texas Cellnet Inc.",
        "texas_childrens_hospital": "Texas Children's Hospital",
        "texas_christian_university": "Texas Christian University",
        "texas_department_of_information_resources": "Texas Department Of Information Resources",
        "texas_instruments": "Texas Instruments",
        "texas_state_technical_college_harlingen": "Texas State Technical College Harlingen",
        "texas_state_technical_college_sweetwater": "Texas State Technical College Sweetwater",
        "texas_state_university_san_marcos": "Texas State University - San Marcos",
        "texas_tech_university": "Texas Tech University",
        "texas_womans_university": "Texas Woman's University",
        "tfn_media_co_ltd": "Tfn Media Co. Ltd.",
        "tgm_pinnacle_network_solutions_llc": "Tgm Pinnacle Network Solutions Llc",
        "tgvr_weblink_telecom_ltda_me": "Tgvr Weblink Telecom Ltda - Me",
        "thackergrigsby_communications": "Thacker-grigsby Communications",
        "thames_valley_communications_inc": "Thames Valley Communications Inc.",
        "the_aero_group_inc": "The Aero Group Inc.",
        "the_art_institutes_international": "The Art Institutes International",
        "the_associated_press": "The Associated Press",
        "the_boeing_company": "The Boeing Company",
        "the_catholic_university_of_america": "The Catholic University Of America",
        "the_childrens_hospital_of_philadelphia": "The Children's Hospital Of Philadelphia",
        "the_childrens_mercy_hospital": "The Children's Mercy Hospital",
        "the_city_of_wadsworth": "The City Of Wadsworth",
        "the_cloud_networks_germany_gmbh": "The Cloud Networks Germany Gmbh",
        "the_cloud_networks_ltd": "The Cloud Networks Ltd",
        "the_computer_works_of_arkansas_inc": "The Computer Works Of Arkansas Inc.",
        "the_cosmopolitan_of_las_vegas": "The Cosmopolitan Of Las Vegas",
        "the_dow_chemical_company": "The Dow Chemical Company",
        "the_endurance_international_group_inc": "The Endurance International Group Inc.",
        "the_fusion_network_llc": "The Fusion Network Llc",
        "the_george_brown_college_of_applied_arts_and_technology": "The George Brown College Of Applied Arts And Technology",
        "the_george_washington_university": "The George Washington University",
        "the_goldman_sachs_group_inc": "The Goldman Sachs Group Inc.",
        "the_goodyear_tire_rubber_company": "The Goodyear Tire Rubber Company",
        "the_greater_eastern_oregon_network_llc": "The Greater Eastern Oregon Network Llc",
        "the_health_alliance_of_greater_cincinnati": "The Health Alliance Of Greater Cincinnati",
        "the_hertz_corp": "The Hertz Corp.",
        "the_hockaday_school": "The Hockaday School",
        "the_home_town_network_inc": "The Home Town Network Inc",
        "the_internet_group_ltd": "The Internet Group Ltd.",
        "the_internexus_group_limited": "The Internexus Group Limited",
        "the_junction_internet_llc": "The Junction Internet Llc",
        "the_light_plant_commission_of_the_city_of_paragould": "The Light Plant Commission Of The City Of Paragould",
        "the_mitre_corporation": "The Mitre Corporation",
        "the_municipal_communications_utility_of_the_city_of_cedar_falls_iowa": "The Municipal Communications Utility Of The City Of Cedar Falls Iowa",
        "the_neiman_marcus_group_inc": "The Neiman Marcus Group Inc.",
        "the_networking_people_northwest_limited": "The Networking People Northwest Limited",
        "the_ngl_connection_llc": "The Ngl Connection Llc",
        "the_northeastern_pennsylvania_telephone_company": "The North-eastern Pennsylvania Telephone Company",
        "the_ogilvy_group_inc": "The Ogilvy Group Inc.",
        "the_optimal_link_corporation": "The Optimal Link Corporation",
        "the_park_region_mutual_telephone_co": "The Park Region Mutual Telephone Co",
        "the_pennsylvania_state_university": "The Pennsylvania State University",
        "the_pioneer_telephone_association_inc": "The Pioneer Telephone Association Inc.",
        "the_procter_and_gamble_company": "The Procter And Gamble Company",
        "the_prudential_insurance_company_of_america": "The Prudential Insurance Company Of America",
        "the_ridley_school_district": "The Ridley School District",
        "the_ringgold_telephone_company": "The Ringgold Telephone Company",
        "the_rockefeller_university": "The Rockefeller University",
        "the_rye_telephone_company": "The Rye Telephone Company",
        "the_school_board_of_brevard_county": "The School Board Of Brevard County",
        "the_school_district_of_palm_beach_county": "The School District Of Palm Beach County",
        "the_servicemaster_company": "The Servicemaster Company",
        "the_speednet": "The Speednet",
        "the_st_paul_travelers_companies_inc": "The St. Paul Travelers Companies Inc.",
        "the_stanley_works": "The Stanley Works",
        "the_toledo_hospital": "The Toledo Hospital",
        "the_tricounty_telephone_association_inc": "The Tri-county Telephone Association Inc.",
        "the_university_of_adelaide": "The University Of Adelaide",
        "the_university_of_akron": "The University Of Akron",
        "the_university_of_alabama": "The University Of Alabama",
        "the_university_of_auckland": "The University Of Auckland",
        "the_university_of_louisiana_at_monroe": "The University Of Louisiana At Monroe",
        "the_university_of_melbourne_melbourne_victoria": "The University Of Melbourne Melbourne Victoria",
        "the_university_of_memphis": "The University Of Memphis",
        "the_university_of_mississippi": "The University Of Mississippi",
        "the_university_of_otago": "The University Of Otago",
        "the_university_of_queensland": "The University Of Queensland",
        "the_university_of_tennessee_at_martin": "The University Of Tennessee At Martin",
        "the_university_of_texas_at_brownsville": "The University Of Texas At Brownsville",
        "the_university_of_texas_at_el_paso": "The University Of Texas At El Paso",
        "the_university_of_texas_m_d_anderson_cancer_center": "The University Of Texas M.d. Anderson Cancer Center",
        "the_university_of_texas_pan_american": "The University Of Texas - Pan American",
        "the_wasserstrom_company": "The Wasserstrom Company",
        "the_weblink_network": "The Weblink Network",
        "the_world_bank_group": "The World Bank Group",
        "thiel_college": "Thiel College",
        "thinktech_inc": "Thinktech Inc",
        "thirumala_tripleplay_network": "Thirumala Tripleplay Network",
        "thomas_jefferson_university_hospitals": "Thomas Jefferson University Hospitals",
        "thomson_reuters_inc": "Thomson Reuters Inc.",
        "three_river_telco": "Three River Telco",
        "threeminds_s_r_l": "Threeminds S.r.l.",
        "threesa_infoway_pvt_ltd": "Threesa Infoway Pvt.ltd.",
        "ths_provider_servicos_de_comunicacao_multimidia_lt": "Ths Provider Servicos De Comunicacao Multimidia Lt",
        "thuega_smartservice_gmbh": "Thuega Smartservice Gmbh",
        "thueringer_netkom_gmbh": "Thueringer Netkom Gmbh",
        "thus_plc_ta_demon_internet": "Thus Plc Ta Demon Internet",
        "thyphone_communications_llc": "Thyphone Communications Llc",
        "ti5_tecnologia_e_inovacao": "Ti5 Tecnologia E Inovacao",
        "tiaacref": "Tiaa-cref",
        "tiera_centr_ltd": "Tiera Centr Ltd",
        "tierpoint_llc": "Tierpoint Llc",
        "tierpoint_pennsylvania_two_llc": "Tierpoint Pennsylvania Two Llc",
        "tierzero": "Tierzero",
        "ties": "Ties",
        "tigo_rwanda_sa": "Tigo Rwanda Sa",
        "tikona_digital_networks_pvt_ltd": "Tikona Digital Networks Pvt. Ltd",
        "tim_celular_s_a": "Tim Celular S.a.",
        "time_warner_cable_internet_llc": "Time Warner Cable Internet Llc",
        "time_warner_enterprise_infrastructure_services_llc": "Time Warner Enterprise Infrastructure Services Llc",
        "tinet_spa": "Tinet Spa",
        "tis_dialog_llc": "Tis Dialog Llc",
        "tiscali_italia_s_p_a": "Tiscali Italia S.p.a.",
        "tiscali_uk_limited": "Tiscali Uk Limited",
        "titan_networks_internet_telecommunications_service_providing_gmbh": "Titan Networks Internet Telecommunications Service Providing Gmbh",
        "tj_telecom_ltda_me": "Tj Telecom Ltda Me",
        "tlcommunications_de_hait": "Tlcommunications De Hait",
        "tmobile_austria_gmbh": "T-mobile Austria Gmbh",
        "tmobile_czech_republic_a_s": "T-mobile Czech Republic A.s.",
        "tmobile_deutschland_gmbh": "T-mobile Deutschland Gmbh",
        "tmobile_netherlands_bv": "T-mobile Netherlands Bv.",
        "tmobile_poland": "T-mobile Poland",
        "tmobile_polska_s_a": "T-mobile Polska S.a.",
        "tmobile_usa_inc": "T-mobile Usa Inc.",
        "tmt_gmbh_co_kg": "Tmt Gmbh Co. Kg",
        "tnet_wireless_e_informa#769tica": "T-net Wireless E Informa#769tica",
        "togo_telecom": "Togo Telecom",
        "tohoku_university": "Tohoku University",
        "toile_informatique_grenobloise": "Toile Informatique Grenobloise",
        "tokai_communications_corporation": "Tokai Communications Corporation",
        "tokyo_university_of_agriculture": "Tokyo University Of Agriculture",
        "toledo_telephone_co_inc": "Toledo Telephone Co Inc",
        "tomales_bay_lan_llc": "Tomales Bay Lan Llc",
        "tomato_web_pvt_limited": "Tomato Web Pvt Limited",
        "tombigbee_communications_llc": "Tombigbee Communications Llc",
        "tomkevich_andrey_vladimirovich_individual_businessman": "Tomkevich Andrey Vladimirovich Individual Businessman",
        "tonami_transportation_co_ltd": "Tonami Transportation Co. Ltd.",
        "toolsnet_telecomunicacoes_ltda_me": "Toolsnet Telecomunicacoes Ltda - Me",
        "top_connect_tecnologia_ltda": "Top Connect Tecnologia Ltda",
        "top_net_servicos_ltda": "Top Net Servicos Ltda",
        "toplink_informatica_ltda": "Toplink Informatica Ltda",
        "topnet": "Topnet",
        "tops_net_gmbh_co_kg": "Tops.net Gmbh Co. Kg",
        "topsham_communications_llc": "Topsham Communications Llc",
        "toque_telecom_ltda": "Toque Telecom Ltda",
        "torgovij_dom_asteis_ltd": "Torgovij Dom Asteis Ltd.",
        "tot_mobile_co_ltd": "Tot Mobile Co Ltd",
        "tot_public_company_limited": "Tot Public Company Limited",
        "total_access_communication_plc": "Total Access Communication Plc.",
        "total_play_telecomunicaciones_sa_de_cv": "Total Play Telecomunicaciones Sa De Cv",
        "total_server_solutions_l_l_c": "Total Server Solutions L.l.c.",
        "total_telecom_ltdame": "Total Telecom Ltda-me",
        "totcable": "Totcable",
        "totelcom_communications_llc": "Totelcom Communications Llc",
        "totelcsi": "Totelcsi",
        "towerstream": "Towerstream",
        "towerstream_i_inc": "Towerstream I Inc.",
        "town_of_pineville": "Town Of Pineville",
        "townes_telecommunications_services_corporation": "Townes Telecommunications Services Corporation",
        "towson_state_university": "Towson State University",
        "toyama_university": "Toyama University",
        "toyohashi_cable_network_inc": "Toyohashi Cable Network Inc.",
        "toyota_motor_sales_u_s_a_inc": "Toyota Motor Sales U.s.a. Inc.",
        "toyota_tsusho_america_inc": "Toyota Tsusho America Inc.",
        "tpa_telecomunicacoes_ltda": "Tpa Telecomunicacoes Ltda",
        "tpg_internet_pty_ltd": "Tpg Internet Pty Ltd.",
        "tpg_telecom_limited": "Tpg Telecom Limited",
        "tpx_communications": "Tpx Communications",
        "transfer_ltd": "Transfer Ltd.",
        "transit_telecom_llc": "Transit Telecom Llc",
        "transit_wireless_llc": "Transit Wireless Llc.",
        "transmedia_technologies_ap_pvt_ltd": "Transmedia Technologies Ap Pvt Ltd",
        "transport_for_london": "Transport For London",
        "transtelco_inc": "Transtelco Inc",
        "transvision_reseau_inc": "Transvision Reseau Inc.",
        "transwave_communications_systems_inc": "Transwave Communications Systems Inc.",
        "travekom_telekommunikationsgesellschaft_mbh": "Travekom Telekommunikationsgesellschaft Mbh",
        "trend_sistemas_e_consultoria": "Trend Sistemas E Consultoria",
        "trenton_telephone_company": "Trenton Telephone Company",
        "trenton_tv_cable_company": "Trenton Tv Cable Company",
        "tri_isp_networks_private_limited": "Tri Isp Networks Private Limited",
        "tri_telecom_ltda": "Tri Telecom Ltda",
        "triangle_communications": "Triangle Communications",
        "triangle_services_ltd": "Triangle Services Ltd.",
        "tricounty_communications_inc": "Tricounty Communications Inc.",
        "tricounty_telephone_inc": "Tri-county Telephone Inc.",
        "trinity_college": "Trinity College",
        "trinity_washington_university": "Trinity Washington University",
        "trion_tel_d_o_o": "Trion Tel D.o.o.",
        "triple_play_broadband_private_limited": "Triple Play Broadband Private Limited",
        "triple_t_internet_company_limited": "Triple T Internet Company Limited",
        "tripleplay_telecom_pvt_ltd": "Tripleplay Telecom Pvt Ltd",
        "triplet_internet_internet_service_provider_bangkok": "Triplet Internet Internet Service Provider Bangkok",
        "triunfo_telecomunicaciones_s_l": "Triunfo Telecomunicaciones S.l.",
        "trollfjord_bredband_as": "Trollfjord Bredband As",
        "troy_cablevision_inc": "Troy Cablevision Inc.",
        "true_internet_co_ltd": "True Internet Co. Ltd.",
        "true_internet_corporation_co_ltd": "True Internet Corporation Co. Ltd.",
        "true_move_company_limited": "True Move Company Limited",
        "truespeed_internet_services": "Truespeed Internet Services",
        "truman_state_university": "Truman State University",
        "truphone_limited": "Truphone Limited",
        "trusc": "Trusc",
        "trustpower_ltd": "Trustpower Ltd",
        "truvista_communications": "Truvista Communications",
        "trytech_ltd": "Trytech Ltd.",
        "tsystems_international_gmbh": "T-systems International Gmbh",
        "tsystems_north_america_inc": "T-systems North America Inc.",
        "ttslisp_division": "Ttsl-isp Division",
        "tubaron_telecom": "Tubaron Telecom",
        "tucows_com_co": "Tucows.com Co.",
        "tudo_internet": "Tudo Internet",
        "tufts_university": "Tufts University",
        "tulane_university": "Tulane University",
        "tularosa_communications_inc": "Tularosa Communications Inc.",
        "tulip_telecom_ltd": "Tulip Telecom Ltd.",
        "tullahoma_utilities_authority": "Tullahoma Utilities Authority",
        "tulsa_community_college": "Tulsa Community College",
        "tung_ho_multimedia_co_ltd": "Tung Ho Multimedia Co. Ltd.",
        "tunisiana": "Tunisiana",
        "tunisiana_sa": "Tunisiana Sa",
        "tunisie_telecom": "Tunisie Telecom",
        "turbo_net_telecom_ltdame": "Turbo Net Telecom Ltdame",
        "turbo_sp_internet_provider": "Turbo Sp Internet Provider",
        "turbonet_telecom": "Turbonet Telecom",
        "turbonet_telecomunicaes": "Turbonet Telecomunicaes",
        "turk_telekom": "Turk Telekom",
        "turkcell": "Turkcell",
        "turksat_uydu_haberlesme_ve_kablo_tv_isletme_a_s": "Turksat Uydu Haberlesme Ve Kablo Tv Isletme A.s.",
        "turner_broadcasting_system_inc": "Turner Broadcasting System Inc.",
        "turnkey_internet_inc": "Turnkey Internet Inc.",
        "tusco_net_inc": "Tusco.net Inc.",
        "tussa_ikt_as": "Tussa Ikt As",
        "tv_azteca_sucursal_colombia": "Tv Azteca Sucursal Colombia",
        "tv_loja_comunicaciones_s_l": "Tv Loja Comunicaciones S.l.",
        "tvcabo_mocambique": "Tvcabo Mocambique",
        "tvingo_telecom_ltd": "Tvingo Telecom Ltd.",
        "tw_telecom_holdings_inc": "Tw Telecom Holdings Inc.",
        "twin_lakes_telephone_cooperative_corporation": "Twin Lakes Telephone Cooperative Corporation",
        "twin_valley_communications_inc": "Twin Valley Communications Inc",
        "twlkom_gmbh": "Twl-kom Gmbh",
        "twt_s_p_a": "Twt S.p.a.",
        "tyco_electronics_corporation": "Tyco Electronics Corporation",
        "tyler_junior_college": "Tyler Junior College",
        "tzulo_inc": "Tzulo Inc.",
        "u_mobile_sdn_bhd": "U Mobile Sdn Bhd",
        "u_p_communication_services_pvt_ltd": "U.p. Communication Services Pvt Ltd",
        "u_s_department_of_defense_education_activity_europe": "U.s. Department Of Defense Education Activity Europe",
        "u_s_department_of_labor": "U.s. Department Of Labor",
        "u_s_department_of_state": "U.s. Department Of State",
        "u_s_dept_of_commerce_ita": "U.s. Dept. Of Commerce - Ita",
        "u_s_dept_of_health_and_human_services": "U.s. Dept. Of Health And Human Services",
        "u_s_government_printing_office": "U.s. Government Printing Office",
        "u_s_house_of_representatives": "U.s. House Of Representatives",
        "u_s_metropolitan_telecom_llc": "U.s. Metropolitan Telecom Llc",
        "uab_bite_lietuva": "Uab Bite Lietuva",
        "uab_source_bridge": "Uab Source Bridge",
        "uai_telecom_comunicacao_multimidia_ltda": "Uai Telecom Comunicacao Multimidia Ltda.",
        "ubergroup_limited": "Ubergroup Limited",
        "ubs_ag": "Ubs Ag",
        "ubtanet": "Ubtanet",
        "ucb_inc": "Ucb Inc.",
        "uci_medical_center": "Uci Medical Center",
        "uclix_infra_ltd": "Uclix Infra Ltd",
        "ucn_cable_network_pvt_ltd": "Ucn Cable Network Pvt. Ltd",
        "ucom_llc": "Ucom Llc",
        "ufinet_panama_s_a": "Ufinet Panama S.a.",
        "ugmktelecom_llc": "Ugmk-telecom Llc",
        "ugselhoz_llc": "Ugsel'hoz Llc",
        "uk2_ltd": "Uk2 - Ltd",
        "uk_broadband_ltd": "Uk Broadband Ltd.",
        "uk_dedicated_servers_limited": "Uk Dedicated Servers Limited",
        "uk_ministry_of_defence": "Uk Ministry Of Defence",
        "ukfastnet_ltd": "Ukfastnet Ltd",
        "ulsan_jungang_broadcasting_network": "Ulsan Jung-ang Broadcasting Network",
        "ulster_county_boces": "Ulster County Boces",
        "ultimate_internet_access_inc": "Ultimate Internet Access Inc",
        "ultraceu_ltda": "Ultraceu Ltda",
        "ultranet_services_private_limited": "Ultranet Services Private Limited",
        "ultranet_telecomunicacoes_ltda": "Ultranet Telecomunicacoes Ltda",
        "ultrawave_telecom": "Ultrawave Telecom",
        "umniah_mobile_company_plc": "Umniah Mobile Company Plc",
        "umos_center_llc": "Umos Center Llc",
        "umos_llc": "Umos Llc",
        "unelink_telecom_s_a": "Unelink Telecom S.a.",
        "unet_b_v": "Unet B.v.",
        "unet_communication_llc": "Unet Communication Llc",
        "unet_communication_private_limited": "Unet Communication Private Limited",
        "unica_technology_ltda_me": "Unica Technology Ltda Me",
        "unidasnet_comunicacoes_ltda": "Unidasnet Comunicacoes Ltda",
        "unidata_s_p_a": "Unidata S.p.a.",
        "unified_layer": "Unified Layer",
        "uninet": "Uninet",
        "uninet_s_a_de_c_v": "Uninet S.a. De C.v.",
        "uninett_as": "Uninett As",
        "uninett_the_norwegian_university_research_network": "Uninett The Norwegian University Research Network",
        "uninetth": "Uninet-th",
        "union_county_college": "Union County College",
        "union_pacific_railroad_company": "Union Pacific Railroad Company",
        "union_springs_telephone_company": "Union Springs Telephone Company",
        "union_wireless": "Union Wireless",
        "uniontel_zao": "Uniontel Zao",
        "uniplan_software_s_r_l_internet_provider": "Uniplan Software S.r.l. - Internet Provider",
        "uniserve_on_line": "Uniserve On Line",
        "unitas_global_ltd": "Unitas Global Ltd",
        "unite_private_networks_llc": "Unite Private Networks Llc.",
        "unitec_telecom_sistemas_de_telefonia": "Unitec Telecom Sistemas De Telefonia",
        "united_electric_cooperative": "United Electric Cooperative",
        "united_parcel_service": "United Parcel Service",
        "united_states_antarctic_program": "United States Antarctic Program",
        "united_states_cellular_telephone_company_lp": "United States Cellular Telephone Company Lp",
        "united_states_patent_and_trademark_office": "United States Patent And Trademark Office",
        "united_states_postal_service": "United States Postal Service.",
        "united_technologies_research_center": "United Technologies Research Center",
        "united_telephone_association_inc": "United Telephone Association Inc.",
        "united_telephone_company": "United Telephone Company",
        "united_telephone_mutual_aid_corporation": "United Telephone Mutual Aid Corporation",
        "unitedhealth_group_incorporated": "Unitedhealth Group Incorporated",
        "unitel_inc": "Unitel Inc",
        "unitel_laos": "Unitel Laos",
        "unitel_llc": "Unitel Llc",
        "unitel_sa": "Unitel Sa",
        "uniti_wireless_pty_ltd": "Uniti Wireless Pty Ltd",
        "unitymedia_nrw_gmbh": "Unitymedia Nrw Gmbh",
        "univera_network": "Univera Network",
        "universal_mobile_systems_lcc": "Universal Mobile Systems Lcc",
        "universidad_politecnica_de_madrid": "Universidad Politecnica De Madrid",
        "universidade_estadual_de_campinas": "Universidade Estadual De Campinas",
        "universidade_federal_de_pernambuco": "Universidade Federal De Pernambuco",
        "universitaet_des_saarlandes_saarbruecken": "Universitaet Des Saarlandes Saarbruecken",
        "universitaet_stuttgart": "Universitaet Stuttgart",
        "universitas_padjadjaran": "Universitas Padjadjaran",
        "universite_laval": "Universite Laval",
        "universiti_malaysia_sabah": "Universiti Malaysia Sabah",
        "universiti_putra_malaysia_as": "Universiti Putra Malaysia As",
        "universiti_sains_islam_malaysia": "Universiti Sains Islam Malaysia",
        "universiti_sains_malaysia_usm": "Universiti Sains Malaysia Usm",
        "university_at_albany_state_university_of_new_york": "University At Albany State University Of New York",
        "university_corporation_for_atmospheric_research": "University Corporation For Atmospheric Research",
        "university_health_network": "University Health Network",
        "university_hospitals_health_system": "University Hospitals Health System",
        "university_medical_center": "University Medical Center",
        "university_of_alabama_at_birmingham": "University Of Alabama At Birmingham",
        "university_of_alabama_at_birmingham_the_university_computer_center": "University Of Alabama At Birmingham - The University Computer Center",
        "university_of_alabama_in_huntsville": "University Of Alabama In Huntsville",
        "university_of_alaska": "University Of Alaska",
        "university_of_arizona": "University Of Arizona",
        "university_of_arkansas": "University Of Arkansas",
        "university_of_arkansas_at_monticello": "University Of Arkansas At Monticello",
        "university_of_bridgeport": "University Of Bridgeport",
        "university_of_calgary": "University Of Calgary",
        "university_of_california_at_berkeley": "University Of California At Berkeley",
        "university_of_california_at_davis": "University Of California At Davis",
        "university_of_california_irvine": "University Of California Irvine",
        "university_of_california_los_angeles": "University Of California Los Angeles",
        "university_of_california_office_of_the_president": "University Of California - Office Of The President",
        "university_of_california_riverside": "University Of California Riverside",
        "university_of_california_san_diego": "University Of California San Diego",
        "university_of_california_san_francisco": "University Of California San Francisco",
        "university_of_california_santa_cruz": "University Of California Santa Cruz",
        "university_of_canterbury": "University Of Canterbury",
        "university_of_central_florida": "University Of Central Florida",
        "university_of_chicago": "University Of Chicago",
        "university_of_cincinnati": "University Of Cincinnati",
        "university_of_colorado": "University Of Colorado",
        "university_of_colorado_denver": "University Of Colorado Denver",
        "university_of_colorado_hospital": "University Of Colorado Hospital",
        "university_of_connecticut": "University Of Connecticut",
        "university_of_dayton": "University Of Dayton",
        "university_of_delaware": "University Of Delaware",
        "university_of_georgia": "University Of Georgia",
        "university_of_guelph": "University Of Guelph",
        "university_of_hartford": "University Of Hartford",
        "university_of_hawaii": "University Of Hawaii",
        "university_of_houston": "University Of Houston",
        "university_of_houston_downtown": "University Of Houston - Downtown",
        "university_of_idaho": "University Of Idaho",
        "university_of_illinois": "University Of Illinois",
        "university_of_illinois_at_chicago": "University Of Illinois At Chicago",
        "university_of_indianapolis": "University Of Indianapolis",
        "university_of_iowa": "University Of Iowa",
        "university_of_kansas_medical_center": "University Of Kansas Medical Center",
        "university_of_kentucky": "University Of Kentucky",
        "university_of_la_verne": "University Of La Verne",
        "university_of_louisiana_at_lafayette": "University Of Louisiana At Lafayette",
        "university_of_louisville": "University Of Louisville",
        "university_of_maine_system": "University Of Maine System",
        "university_of_malaya": "University Of Malaya",
        "university_of_manitoba": "University Of Manitoba",
        "university_of_maryland": "University Of Maryland",
        "university_of_maryland_at_baltimore": "University Of Maryland At Baltimore",
        "university_of_maryland_baltimore_county_umbc": "University Of Maryland Baltimore County Umbc",
        "university_of_massachusetts": "University Of Massachusetts",
        "university_of_massachusetts_dartmouth": "University Of Massachusetts Dartmouth",
        "university_of_massachusetts_lowell": "University Of Massachusetts - Lowell",
        "university_of_miami": "University Of Miami",
        "university_of_michigan": "University Of Michigan",
        "university_of_minnesota": "University Of Minnesota",
        "university_of_mississippi_medical_center": "University Of Mississippi Medical Center",
        "university_of_missouri_dba_the_missouri_research_and_education_network": "University Of Missouri - Dba The Missouri Research And Education Network",
        "university_of_missouri_dba_the_missouri_research_and_education_network_morenet": "University Of Missouri - Dba The Missouri Research And Education Network Morenet",
        "university_of_missouri_kansas_city": "University Of Missouri - Kansas City",
        "university_of_missouricolumbia": "University Of Missouri-columbia",
        "university_of_montana": "University Of Montana",
        "university_of_nebraska_at_omaha": "University Of Nebraska At Omaha",
        "university_of_nebraskalincoln": "University Of Nebraska-lincoln",
        "university_of_nevada_reno": "University Of Nevada Reno",
        "university_of_new_brunswick": "University Of New Brunswick",
        "university_of_new_england": "University Of New England",
        "university_of_new_haven": "University Of New Haven",
        "university_of_new_mexico": "University Of New Mexico",
        "university_of_new_orleans": "University Of New Orleans",
        "university_of_new_south_wales": "University Of New South Wales",
        "university_of_newcastle_upon_tyne": "University Of Newcastle Upon Tyne",
        "university_of_north_carolina_at_chapel_hill": "University Of North Carolina At Chapel Hill",
        "university_of_north_carolina_at_greensboro": "University Of North Carolina At Greensboro",
        "university_of_north_texas": "University Of North Texas",
        "university_of_northern_colorado": "University Of Northern Colorado",
        "university_of_northern_iowa": "University Of Northern Iowa",
        "university_of_notre_dame": "University Of Notre Dame",
        "university_of_oklahoma": "University Of Oklahoma",
        "university_of_oklahoma_hsc": "University Of Oklahoma Hsc",
        "university_of_oregon": "University Of Oregon",
        "university_of_pennsylvania": "University Of Pennsylvania",
        "university_of_pittsburgh": "University Of Pittsburgh",
        "university_of_queensland": "University Of Queensland",
        "university_of_regina": "University Of Regina",
        "university_of_rhode_island": "University Of Rhode Island",
        "university_of_richmond": "University Of Richmond",
        "university_of_rochester": "University Of Rochester",
        "university_of_san_diego": "University Of San Diego",
        "university_of_san_francisco": "University Of San Francisco",
        "university_of_scranton": "University Of Scranton",
        "university_of_south_carolina": "University Of South Carolina",
        "university_of_south_dakota": "University Of South Dakota",
        "university_of_south_florida": "University Of South Florida",
        "university_of_southern_california": "University Of Southern California",
        "university_of_southern_indiana": "University Of Southern Indiana",
        "university_of_southern_mississippi": "University Of Southern Mississippi",
        "university_of_st_thomas": "University Of St. Thomas",
        "university_of_sydney": "University Of Sydney",
        "university_of_tennessee": "University Of Tennessee",
        "university_of_tennessee_at_chattanooga": "University Of Tennessee At Chattanooga",
        "university_of_tennessee_computing_center": "University Of Tennessee Computing Center",
        "university_of_texas": "University Of Texas",
        "university_of_texas_at_arlington": "University Of Texas At Arlington",
        "university_of_texas_at_austin": "University Of Texas At Austin",
        "university_of_texas_at_dallas": "University Of Texas At Dallas",
        "university_of_texas_at_san_antonio": "University Of Texas At San Antonio",
        "university_of_texas_medical_branch": "University Of Texas Medical Branch",
        "university_of_texas_system": "University Of Texas System",
        "university_of_the_cumberlands": "University Of The Cumberlands",
        "university_of_the_incarnate_word": "University Of The Incarnate Word",
        "university_of_the_pacific": "University Of The Pacific",
        "university_of_the_south": "University Of The South",
        "university_of_toronto": "University Of Toronto",
        "university_of_tsukuba": "University Of Tsukuba",
        "university_of_tulsa": "University Of Tulsa",
        "university_of_utah": "University Of Utah",
        "university_of_vermont": "University Of Vermont",
        "university_of_virginia": "University Of Virginia",
        "university_of_warwick": "University Of Warwick",
        "university_of_washington": "University Of Washington",
        "university_of_waterloo": "University Of Waterloo",
        "university_of_western_australia": "University Of Western Australia",
        "university_of_wisconsin_eau_claire": "University Of Wisconsin - Eau Claire",
        "university_of_wisconsin_madison": "University Of Wisconsin Madison",
        "university_of_wisconsin_milwaukee": "University Of Wisconsin - Milwaukee",
        "university_of_wisconsin_system": "University Of Wisconsin System",
        "university_of_wisconsin_whitewater": "University Of Wisconsin Whitewater",
        "university_of_wyoming": "University Of Wyoming",
        "university_of_zurich": "University Of Zurich",
        "university_system_of_georgia": "University System Of Georgia",
        "university_system_of_new_hampshire": "University System Of New Hampshire",
        "uno_communications_spa": "Uno Communications Spa",
        "unum_group": "Unum Group",
        "unus_inc": "Unus Inc.",
        "unwired": "Unwired",
        "unwired_broadband_inc": "Unwired Broadband Inc.",
        "unwired_group_fixed_wireless_broadband_access_sydney": "Unwired Group Fixed Wireless Broadband Access Sydney",
        "up_telecom": "Up Telecom",
        "upc_austria_gmbh": "Upc Austria Gmbh",
        "upc_ceska_republica_a_s": "Upc Ceska Republica A.s.",
        "upc_polska_sp_z_o_o": "Upc Polska Sp. Z O.o.",
        "upc_schweiz_gmbh": "Upc Schweiz Gmbh",
        "upcloud_ltd": "Upcloud Ltd",
        "upcloud_usa_inc": "Upcloud Usa Inc",
        "upmc": "Upmc",
        "upnet_provedor_de_acesso_e_telecom": "Upnet Provedor De Acesso E Telecom",
        "urban_networks_inc": "Urban Networks Inc.",
        "us_dedicated": "Us Dedicated",
        "us_internet_corp": "Us Internet Corp",
        "us_senate": "Us Senate",
        "us_signal_company_l_l_c": "Us Signal Company L.l.c.",
        "usa_companies_llc": "Usa Companies Llc",
        "uscuniversity_hospital": "Usc-university Hospital",
        "usda_office_of_operations": "Usda Office Of Operations",
        "used_in_eu": "Used In Eu",
        "usfamily_net": "Usfamily.net",
        "utah_broadband_llc": "Utah Broadband Llc",
        "utah_education_network": "Utah Education Network",
        "utah_state_university": "Utah State University",
        "utextelecom_llc": "Utex-telecom Llc",
        "utility_telephone_inc": "Utility Telephone Inc.",
        "utopianet_informatica_e_telecomunicacoes_ltda": "Utopianet Informatica E Telecomunicacoes Ltda",
        "utsunomiya_cable_tv_corporation": "Utsunomiya Cable Tv Corporation",
        "uzbektelekom_joint_stock_company": "Uzbektelekom Joint Stock Company",
        "v2net_comercio_servicos_e_internet_ltda": "V2net Comercio Servicos E Internet Ltda.",
        "v_i_v_a": "- V I V A",
        "v_p_holdings_inc": "V P Holdings Inc.",
        "va_skywire_llc": "Va Skywire Llc",
        "vads_managed_business_internet_service_provider": "Vads Managed Business Internet Service Provider",
        "vagner_brasileiro_da_silva": "Vagner Brasileiro Da Silva",
        "vainavi_industies_ltd_internet_service_provider_india": "Vainavi Industies Ltd Internet Service Provider India",
        "vaioni_group_as": "Vaioni Group As",
        "vaioni_group_ltd": "Vaioni Group Ltd",
        "vale_verde_tecnologia_integrada": "Vale Verde Tecnologia Integrada",
        "valencia_college": "Valencia College",
        "valley_electric_association": "Valley Electric Association",
        "valley_fiber_ltd": "Valley Fiber Ltd.",
        "valley_telecom_internet": "Valley Telecom Internet",
        "valleynet_inc": "Valleynet Inc.",
        "valliant_telephone_co_inc": "Valliant Telephone Co Inc",
        "valunet_llc": "Valu-net Llc.",
        "vanderbilt_university": "Vanderbilt University",
        "vanilce_paes_de_arruda_cotta_me": "Vanilce Paes De Arruda Cotta Me",
        "vanilla": "Vanilla",
        "vanion_inc": "Vanion Inc.",
        "vanoppen_biz_llc": "Vanoppen.biz Llc",
        "vanuatu_telecom": "Vanuatu Telecom",
        "vasai_cable_pvt_ltd": "Vasai Cable Pvt. Ltd.",
        "vassar_college": "Vassar College",
        "vctech_sas": "Vctech Sas",
        "vcu_health_system_authority": "Vcu Health System Authority",
        "vdata_ltd": "Vdata Ltd",
        "veber_limited": "Veber Limited",
        "vectant_ltd": "Vectant Ltd.",
        "vectone_mobile_limited": "Vectone Mobile Limited",
        "vectra_s_a": "Vectra S.a.",
        "vega_service_llc": "Vega Service Llc",
        "vegasnap_llc": "Vegasnap Llc",
        "velcom": "Velcom",
        "velevi_s_a": "Velevi S.a.",
        "velia_net_internetdienste_gmbh": "Velia.net Internetdienste Gmbh",
        "velociter_wireless_inc": "Velociter Wireless Inc.",
        "velocity1_limited": "Velocity1 Limited",
        "velocity_net_limited": "Velocity Net Limited",
        "velocity_network_inc": "Velocity Network Inc.",
        "velocloud_networks_inc": "Velocloud Networks Inc.",
        "veloo_net_ltda": "Veloo Net Ltda",
        "ventura_county_office_of_education": "Ventura County Office Of Education",
        "venus_business_communications_limited": "Venus Business Communications Limited",
        "veon_armenia_cjsc": "Veon Armenia Cjsc",
        "ver_tv_comunicacoes_sa": "Ver Tv Comunicacoes Sa",
        "ver_tv_s_a": "Ver Tv S.a.",
        "veracity_networks_llc": "Veracity Networks Llc",
        "verein_zur_foerderung_eines_deutschen_forschungsnetzes_e_v": "Verein Zur Foerderung Eines Deutschen Forschungsnetzes E.v.",
        "vergent_llc": "Vergent Llc",
        "verity_health_system": "Verity Health System",
        "verizon_business": "Verizon Business",
        "verizon_business_emea_commercial_ip_service_provider_in_europe": "Verizon Business Emea - Commercial Ip Service Provider In Europe",
        "verizon_data_services_llc": "Verizon Data Services Llc",
        "verizon_deutschland_gmbh": "Verizon Deutschland Gmbh",
        "verizon_france_sas": "Verizon France Sas",
        "verizon_korea_limited": "Verizon Korea Limited",
        "verizon_nederland_b_v": "Verizon Nederland B.v.",
        "verizon_uk_limited": "Verizon Uk Limited",
        "verizon_wireless": "Verizon Wireless",
        "vermont_department_of_information_and_innovation": "Vermont Department Of Information And Innovation",
        "vermont_telephone_company_inc": "Vermont Telephone Company Inc.",
        "vermontit_limited_liability_company": "Vermont-it Limited Liability Company",
        "veroxity_technology_partners_inc": "Veroxity Technology Partners Inc.",
        "versatel_deutschland_gmbh": "Versatel Deutschland Gmbh",
        "versaweb_llc": "Versaweb Llc",
        "vertex_telecom_inc": "Vertex Telecom Inc.",
        "vertical_broadband_llc": "Vertical Broadband Llc",
        "vertical_telecoms_pty_ltd": "Vertical Telecoms Pty Ltd",
        "verus_connect": "Verus Connect",
        "veterans_avenue_kalibo_aklan": "Veterans Avenue Kalibo Aklan",
        "vetorialnet_inf_e_serv_de_internet_eireli_epp": "Vetorialnet Inf E Serv De Internet Eireli - Epp",
        "vexnet_telecon_informatica_ltda": "Vexnet Telecon Informatica Ltda",
        "vf_services_inc_information_technology_services": "Vf Services Inc. Information Technology Services",
        "vi_powernet_llc": "Vi Powernet Llc",
        "via_cast_solucoes_em_telecomunicacoes_ltda": "Via Cast Solucoes Em Telecomunicacoes Ltda",
        "via_ip_pty_ltd": "Via Ip Pty. Ltd.",
        "via_web_telecom": "Via Web Telecom",
        "viacom_inc": "Viacom Inc.",
        "viana_viana_comunicaao_ltdame": "Viana Viana Comunicaao Ltda-me",
        "vianet": "Vianet",
        "vianet_communications": "Vianet Communications",
        "vianet_communications_pvt_ltd": "Vianet Communications Pvt. Ltd.",
        "vianet_telecomunicacoes_e_internet": "Vianet Telecomunicacoes E Internet",
        "vianettv_inc": "Vianettv Inc",
        "vianna_mehl_servicos_de_telecomunicacoes_voip_ltda": "Vianna Mehl Servicos De Telecomunicacoes Voip Ltda",
        "viar_telecom_eireli_me": "Viar Telecom Eireli - Me",
        "viarapida_telecomunicacoes_ltda_me": "Viarapida Telecomunicacoes Ltda - Me",
        "viasat_inc": "Viasat Inc.",
        "viasite_internet_ltda": "Viasite Internet Ltda",
        "viatec_servicos_e_comunicacoes_ltda_me": "Viatec Servicos E Comunicacoes Ltda - Me",
        "viaveloz_redes": "Viaveloz Redes",
        "vibe_communications_ltd": "Vibe Communications Ltd",
        "vibo_telecom_inc": "Vibo Telecom Inc.",
        "vida_optics_tvv_ltd": "Vida Optics Tvv Ltd.",
        "vidant_health": "Vidant Health",
        "video_direct_satellite_entertainment": "Video Direct Satellite Entertainment",
        "videobroadcast_gmbh": "Video-broadcast Gmbh",
        "videoline_llc": "Videoline Llc",
        "videomar_rede_nordeste_sa": "Videomar Rede Nordeste Sa",
        "videotron": "Videotron",
        "vietnam_post_and_telecom_corporation": "Vietnam Post And Telecom Corporation",
        "viettel_burundi_sa": "Viettel Burundi Sa",
        "viettel_corporation": "Viettel Corporation",
        "viettel_peru_s_a_c": "Viettel Peru S.a.c.",
        "viettel_tanzania": "Viettel Tanzania",
        "viewqwest_pte_ltd": "Viewqwest Pte Ltd",
        "villanova_university": "Villanova University",
        "viltel_ltd": "Viltel Ltd",
        "vimpelcom_lao_co_ltd": "Vimpelcom Lao Co. Ltd.",
        "vinakom_communications": "Vinakom Communications",
        "vini_sas": "Vini Sas",
        "vip_br_telecom_ltda_me": "Vip Br Telecom Ltda - Me",
        "vip_mobile_d_o_o": "Vip Mobile D.o.o.",
        "vipnet_d_o_o": "Vipnet D.o.o.",
        "virgin_media_limited": "Virgin Media Limited",
        "virgin_mobile_polska_sp_z_o_o": "Virgin Mobile Polska Sp. Z O. O.",
        "virginia_beach_city_public_schools": "Virginia Beach City Public Schools",
        "virginia_broadband_llc": "Virginia Broadband Llc",
        "virginia_commonwealth_university": "Virginia Commonwealth University",
        "virginia_community_college_system": "Virginia Community College System",
        "virginia_information_technologies_agency": "Virginia Information Technologies Agency",
        "virginia_military_institute": "Virginia Military Institute",
        "virginia_polytechnic_institute_and_state_univ": "Virginia Polytechnic Institute And State Univ.",
        "virginia_state_university": "Virginia State University",
        "virginia_union_university": "Virginia Union University",
        "virtela_communications": "Virtela Communications",
        "virtex_ltda": "Virtex Ltda",
        "virtual1_limited": "Virtual1 Limited",
        "virtual_internet_uk_limited": "Virtual Internet Uk Limited",
        "virtuzo": "Virtuzo",
        "visao_informatica_telecomunicacoes_eireli_me": "Visao Informatica Telecomunicacoes Eireli - Me",
        "visaonet_telecom_ltda": "Visaonet Telecom Ltda.",
        "vishwamani_solutions": "Vishwamani Solutions",
        "vision_net_inc": "Vision Net Inc.",
        "visionary_communications_inc": "Visionary Communications Inc.",
        "visovision_s_l": "Visovision S.l.",
        "vispa_limited": "Vispa Limited",
        "vista_broadband_networks_inc": "Vista Broadband Networks Inc.",
        "vistabeam": "Vistabeam",
        "vistec_internet_service_gmbh": "Vistec Internet Service Gmbh",
        "visual_link_comunicacoes_multimidia_ltda_me": "Visual Link Comunicacoes Multimidia Ltda - Me",
        "vital_net": "Vital Net",
        "vitamix_corporation": "Vita-mix Corporation",
        "vitnett_as": "Vitnett As",
        "vitro_corporation": "Vitro Corporation",
        "vitroconnect_gmbh": "Vitroconnect Gmbh",
        "vivid_hosting": "Vivid Hosting",
        "vivint_wireless_inc": "Vivint Wireless Inc.",
        "vladivostokskaya_set_ltd": "Vladivostokskaya Set Ltd",
        "vltelecom_ltd": "Vl-telecom Ltd",
        "vm_openlink_comunicacao_multimidia_e_informatica_l": "Vm Openlink Comunicacao Multimidia E Informatica L",
        "vmware_inc": "Vmware Inc.",
        "voanet_telecomunicacoes_ltda": "Voanet Telecomunicacoes Ltda.",
        "voce_telecomunicacoes_ltda": "Voce Telecomunicacoes Ltda",
        "vocus_pty_ltd": "Vocus Pty Ltd",
        "vodacom_congo": "Vodacom Congo",
        "vodacom_lesotho_ltd": "Vodacom Lesotho Ltd",
        "vodacom_ltd": "Vodacom Ltd.",
        "vodacom_mozambique": "Vodacom Mozambique",
        "vodacom_tanzania_ltd": "Vodacom Tanzania Ltd",
        "vodafone_albania": "Vodafone Albania",
        "vodafone_americas_inc": "Vodafone Americas Inc.",
        "vodafone_australia": "Vodafone Australia",
        "vodafone_data": "Vodafone Data",
        "vodafone_faroe_islands": "Vodafone Faroe Islands",
        "vodafone_fiji_limited": "Vodafone Fiji Limited",
        "vodafone_germany": "Vodafone Germany",
        "vodafone_ghana": "Vodafone Ghana",
        "vodafone_greece": "Vodafone Greece",
        "vodafone_iceland": "Vodafone Iceland",
        "vodafone_india": "Vodafone India",
        "vodafone_ireland_limited": "Vodafone Ireland Limited",
        "vodafone_italy": "Vodafone Italy",
        "vodafone_kabel_deutschland_gmbh": "Vodafone Kabel Deutschland Gmbh",
        "vodafone_libertel_b_v": "Vodafone Libertel B.v.",
        "vodafone_limited": "Vodafone Limited",
        "vodafone_ltd": "Vodafone Ltd",
        "vodafone_net_iletisim_hizmetleri_anonim_sirketi": "Vodafone Net Iletisim Hizmetleri Anonim Sirketi",
        "vodafone_netherlands": "Vodafone Netherlands",
        "vodafone_new_zealand": "Vodafone New Zealand",
        "vodafone_nz_ltd": "Vodafone Nz Ltd.",
        "vodafone_ono_s_a": "Vodafone Ono S.a.",
        "vodafone_portugal": "Vodafone Portugal",
        "vodafone_qatar": "Vodafone Qatar",
        "vodafone_romania_s_a": "Vodafone Romania S.a.",
        "vodafone_spain": "Vodafone Spain",
        "vodafone_telekomunikasyon_a_s": "Vodafone Telekomunikasyon A.s.",
        "vodafone_us_inc": "Vodafone Us Inc.",
        "vodalink_telecom_inc": "Vodalink Telecom Inc.",
        "vogel_solucoes_em_telecom_e_informatica_sa": "Vogel Solucoes Em Telecom E Informatica Sa",
        "voicehost_ltd": "Voicehost Ltd",
        "voicetec_sys_limited": "Voicetec Sys Limited",
        "voip_communications_pvt_ltd": "Voip Communications Pvt Ltd",
        "voipglobe_servicos_de_com_multimidia_via_internet": "Voipglobe Servicos De Com Multimidia Via Internet",
        "vol_broadband": "Vol Broadband",
        "volcano_internet_provider": "Volcano Internet Provider",
        "volkhov_online_ltd": "Volkhov Online Ltd",
        "volo_broadband": "Volo Broadband",
        "volstate_inc": "Volstate Inc.",
        "volt_broadband": "Volt Broadband",
        "voltec_servico_de_automacao_telecom": "Voltec Servico De Automacao Telecom …",
        "volvo_information_technology": "Volvo Information Technology",
        "volvo_it_north_america": "Volvo It North America",
        "vooservers_ltd": "Vooservers Ltd",
        "vorboss_limited": "Vorboss Limited",
        "vostoktelecom_telephone_company_llc": "Vostoktelecom Telephone Company Llc",
        "vostronet_enterprises_pty_ltd": "Vostronet Enterprises Pty Ltd",
        "voxel_dot_net_inc": "Voxel Dot Net Inc.",
        "voxx_telecom_ltda_me": "Voxx Telecom Ltda - Me",
        "voyager_internet_ltd": "Voyager Internet Ltd.",
        "voyageur_internet_inc": "Voyageur Internet Inc",
        "vozelia_telecom_sl": "Vozelia Telecom Sl",
        "vozplus_telecomunicaciones_s_l": "Vozplus Telecomunicaciones S.l.",
        "voztelecom_sistemas_s_l": "Voztelecom Sistemas S.l.",
        "vsevnet_ltd": "Vsevnet Ltd",
        "vslink_comunicacao_e_multimidia_ltda_me": "Vslink Comunicacao E Multimidia Ltda - Me",
        "vtr_banda_ancha_s_a": "Vtr Banda Ancha S.a.",
        "vtx_communications": "Vtx Communications",
        "vtx_services_sa": "Vtx Services Sa",
        "vulcan_northwest_inc": "Vulcan Northwest Inc.",
        "vxchnge_operating_llc": "Vxchnge Operating Llc",
        "w_de_c_canto_junior": "W De C Canto Junior",
        "w_w_grainger": "W.w.grainger",
        "wabash_independent_networks": "Wabash Independent Networks",
        "wachovia_corp": "Wachovia Corp",
        "walmart_stores_inc": "Wal-mart Stores Inc.",
        "walters_state_community_college": "Walters State Community College",
        "wan_lan_internet_pvt_ltd": "Wan Lan Internet Pvt Ltd",
        "wana_corporate": "Wana Corporate",
        "wananchi_group": "Wananchi Group",
        "wananchi_online_limited": "Wananchi Online Limited",
        "wansecurity_inc": "Wansecurity Inc",
        "wanstor_ltd": "Wanstor Ltd",
        "wantel_tecnologia_ltda_epp": "Wantel Tecnologia Ltda. Epp",
        "warabi_cable_vision_co_ltd": "Warabi Cable Vision Co. Ltd.",
        "warian_sas": "Warian Sas",
        "wartburg_college": "Wartburg College",
        "warwicknet": "Warwicknet",
        "waseda_university": "Waseda University",
        "washington_broadband_inc": "Washington Broadband Inc.",
        "washington_lacerda_de_sousa_me": "Washington Lacerda De Sousa Me",
        "washington_lee_university": "Washington Lee University",
        "washington_school_information_processing_cooperative": "Washington School Information Processing Cooperative",
        "washington_state_board_for_community_technical_colleges": "Washington State Board For Community Technical Colleges",
        "washington_state_k20_telecommunications_network": "Washington State K-20 Telecommunications Network",
        "washington_state_university": "Washington State University",
        "washington_university": "Washington University",
        "waste_management_inc": "Waste Management Inc.",
        "watch_communications": "Watch Communications",
        "watch_tower_bible_and_tract_society_of_britain": "Watch Tower Bible And Tract Society Of Britain",
        "wateen_telecom": "Wateen Telecom",
        "wave_broadband": "Wave Broadband",
        "wave_net_llc": "Wave Net Llc",
        "wave_telecom_ltda": "Wave Telecom Ltda.",
        "waveconn_s_r_l": "Waveconn S.r.l.",
        "wavedirect_telecommunications": "Wavedirect Telecommunications",
        "wavelinc_communications": "Wavelinc Communications",
        "wavemax_internet": "Wavemax Internet",
        "wavemax_s_r_l": "Wave-max S.r.l.",
        "waverly_hall_telephone_llc": "Waverly Hall Telephone Llc.",
        "waverly_utilities": "Waverly Utilities",
        "waveworks_internet_network": "Waveworks Internet Network",
        "wawb_pty_ltd": "Wawb Pty Ltd",
        "way_2_internet_private_limited": "Way 2 Internet Private Limited",
        "wayne_county_regional_educationalservice_agency": "Wayne County Regional Educationalservice Agency",
        "wayne_state_university": "Wayne State University",
        "wayport_inc": "Wayport Inc.",
        "wbi": "Wbi",
        "weatherford_international_llc": "Weatherford International Llc",
        "web2objects_llc": "Web2objects Llc",
        "web_star": "Web Star",
        "webair_internet_development_company_inc": "Webair Internet Development Company Inc.",
        "webdiscount_gmbh_co_kg": "Webdiscount Gmbh Co. Kg",
        "webe_digital_sdn_bhd": "Webe Digital Sdn. Bhd.",
        "webfibra_telecomunicacoes_eireli_me": "Webfibra Telecomunicacoes Eireli Me",
        "webfoco_telecomunicacoes_ltda": "Webfoco Telecomunicacoes Ltda",
        "webformix_company": "Webformix Company",
        "webgenesys_informatica_ltda": "Webgenesys Informatica Ltda",
        "webmax_tecnologia_ltda": "Webmax Tecnologia Ltda",
        "webnx_inc": "Webnx Inc.",
        "webpass_inc": "Webpass Inc.",
        "websense_inc": "Websense Inc.",
        "webster_university": "Webster University",
        "wednet": "Wednet",
        "welcome_italia_s_p_a": "Welcome Italia S.p.a.",
        "weld_county_school_district_six": "Weld County School District Six",
        "wellesley_college": "Wellesley College",
        "wells_fargo_company": "Wells Fargo Company",
        "welltelecom_llc": "Welltelecom Llc",
        "welsh_assembly_government": "Welsh Assembly Government",
        "welt_comercio_e_servicos_de_internet_ltda_me": "Welt Comercio E Servicos De Internet Ltda Me",
        "wesleyan_university": "Wesleyan University",
        "west_alabama_t_v_cable_company_inc": "West Alabama T.v. Cable Company Inc.",
        "west_call_ltd": "West Call Ltd",
        "west_carolina_communications_llc": "West Carolina Communications Llc",
        "west_central_telephone_assn": "West Central Telephone Assn",
        "west_central_wireless": "West Central Wireless",
        "west_chester_university": "West Chester University",
        "west_coast_internet_incorporated": "West Coast Internet Incorporated",
        "west_internet_banda_larga": "West Internet Banda Larga",
        "west_ip_communications": "West Ip Communications",
        "west_ky_networks": "West Ky Networks",
        "west_liberty_foods": "West Liberty Foods",
        "west_river_cooperative_telephone_company": "West River Cooperative Telephone Company",
        "west_river_telecommunications_cooperative": "West River Telecommunications Cooperative",
        "west_virginia_network_for_educational_telecomputing": "West Virginia Network For Educational Telecomputing",
        "west_virginia_university": "West Virginia University",
        "westco_internet": "Westco Internet",
        "westcom_trade_ltd": "West-com Trade Ltd.",
        "westelcom_internet_inc": "Westelcom Internet Inc.",
        "western_illinois_university": "Western Illinois University",
        "western_independent_networks_inc": "Western Independent Networks Inc.",
        "western_iowa_telephone_association_a_cooperative": "Western Iowa Telephone Association A Cooperative",
        "western_kentucky_university": "Western Kentucky University",
        "western_ohio_computer_organization": "Western Ohio Computer Organization",
        "western_wimax": "Western Wimax",
        "westex_telecommunications_ltd": "Wes-tex Telecommunications Ltd",
        "westfield_state_university": "Westfield State University",
        "westgate_resorts_ltd": "Westgate Resorts Ltd",
        "westman_communications_group": "Westman Communications Group",
        "westmoreland_intermediate_unit": "Westmoreland Intermediate Unit",
        "westnet_inc": "Westnet Inc.",
        "westpac_banking_corporation": "Westpac Banking Corporation",
        "westpanet_inc": "Westpanet Inc.",
        "wf_telecom_servicos_de_telecomunicacoes_eirele_me": "Wf -telecom Servicos De Telecomunicacoes Eirele Me",
        "wgo_telecomunicacoes_ltda": "Wgo Telecomunicacoes Ltda",
        "wharf_tt_ltd": "Wharf Tt Ltd.",
        "wheat_state_telephone_inc": "Wheat State Telephone Inc.",
        "wheatland_electric_coop": "Wheatland Electric Coop",
        "wheaton_college": "Wheaton College",
        "whidbey_telephone_company": "Whidbey Telephone Company",
        "whirlpool_corporation": "Whirlpool Corporation",
        "whitesky_communications_llc": "Whitesky Communications Llc.",
        "whitworth_university": "Whitworth University",
        "whiz_to_coho_inc": "Whiz To Coho Inc.",
        "wholesale_airtime_inc": "Wholesale Air-time Inc.",
        "wholesale_internet_inc": "Wholesale Internet Inc.",
        "wi_provedor_de_telecomunicacoes_ltda": "Wi - Provedor De Telecomunicacoes Ltda.",
        "wichita_state_university": "Wichita State University",
        "wide_technology_partners_ag": "Wide Technology Partners Ag",
        "wideband_networks_pty_ltd_broadband_internet_provider": "Wideband Networks Pty Ltd Broadband Internet Provider.",
        "wideband_networks_pty_ltd_transit_as": "Wideband Networks Pty Ltd Transit As",
        "wideopenwest_finance_llc": "Wideopenwest Finance Llc",
        "wien_energie_gmbh": "Wien Energie Gmbh",
        "wifibaleares_s_l": "Wifibaleares S.l.",
        "wifibytes_s_l": "Wifibytes S.l.",
        "wificity": "Wificity",
        "wifinity_ltd": "Wifinity Ltd",
        "wifirst_s_a_s": "Wifirst S.a.s.",
        "wightfibre_limited": "Wightfibre Limited",
        "wightman_telecom": "Wightman Telecom",
        "wiip_telecom_servicos_de_internet_ltda": "Wiip Telecom Servicos De Internet Ltda",
        "wikiker_broadband_s_l": "Wikiker Broadband S.l",
        "wikstrom_telephone_company_incorporated": "Wikstrom Telephone Company Incorporated",
        "wiland_ltd": "Wiland Ltd",
        "wilhelm_tel_gmbh": "Wilhelm.tel Gmbh",
        "wiline_networks_inc": "Wiline Networks Inc.",
        "wilkes_communications_inc": "Wilkes Communications Inc.",
        "wilkes_university": "Wilkes University",
        "willamette_education_service_district": "Willamette Education Service District",
        "willamette_university": "Willamette University",
        "willamette_valley_internet_l_l_c": "Willamette Valley Internet L.l.c.",
        "william_beaumont_hospital": "William Beaumont Hospital",
        "william_paterson_university": "William Paterson University",
        "williams_college": "Williams College",
        "williams_information_technology_llc": "Williams Information Technology Llc",
        "willian_mendes_de_oliveira_me": "Willian Mendes De Oliveira Me",
        "wimax_on_line_s_l": "Wimax On Line S.l.",
        "win_time_informatica_ltda": "Win Time Informatica Ltda.",
        "wind_hellas_telecommunications_sa": "Wind Hellas Telecommunications Sa",
        "wind_italy": "Wind Italy",
        "wind_river_internet": "Wind River Internet",
        "wind_telecomunicacao_do_brasil_ltda_me": "Wind Telecomunicacao Do Brasil Ltda - Me",
        "wind_telecomunicazioni_s_p_a": "Wind Telecomunicazioni S.p.a.",
        "windstream_communications_inc": "Windstream Communications Inc",
        "windstream_communications_llc": "Windstream Communications Llc",
        "windtel_servicos_de_telecomunicacoes_ltda": "Windtel Servicos De Telecomunicacoes Ltda",
        "windtre_s_p_a": "Windtre S.p.a",
        "windwave_communications": "Windwave Communications",
        "winfnet_telecom_wireless_ltda": "Winfnet Telecom Wireless Ltda",
        "winn_telecom": "Winn Telecom",
        "winndixie_stores_inc": "Winn-dixie Stores Inc.",
        "winnebago_cooperative_telecom_association": "Winnebago Cooperative Telecom Association",
        "wins_llc": "Wins Llc",
        "wintek_corporation": "Wintek Corporation",
        "winters_broadband_llc": "Winters Broadband Llc",
        "wiphonet_s_r_l": "Wiphonet S.r.l.",
        "wire_and_wireless_co_ltd": "Wire And Wireless Co. Ltd.",
        "wireless_comm_services_ltda": "Wireless Comm Services Ltda",
        "wireless_group_communications_s_r_l": "Wireless Group Communications S.r.l.",
        "wirelessnet_internet_solutions_ltda_me": "Wirelessnet Internet Solutions Ltda - Me",
        "wiscnet": "Wiscnet",
        "wisconsin_internet_inc": "Wisconsin Internet Inc.",
        "wish_net_private_limited": "Wish Net Private Limited",
        "wisp_iconecta_servicos_de_rede_ltda": "Wisp Iconecta Servicos De Rede Ltda",
        "wisper_isp_inc": "Wisper Isp Inc.",
        "wispwest_net": "Wispwest.net",
        "witribe_pakistan_limited": "Wi-tribe Pakistan Limited",
        "wittenberg_telephone_company": "Wittenberg Telephone Company",
        "wittenbergnet_gmbh": "Wittenberg-net Gmbh",
        "wiva_telecom_s_l": "Wiva Telecom S.l.",
        "wj_internet": "Wj Internet",
        "wlanpartner_com_ag": "Wlan-partner.com Ag",
        "wlenet_telecom": "Wlenet Telecom",
        "wnet_telecom_pvt_ltd": "Wnet Telecom Pvt Ltd",
        "wnm_communications": "Wnm Communications",
        "wobcom_gmbh": "Wobcom Gmbh",
        "wofford_college": "Wofford College",
        "wombat_servers_pty_ltd": "Wombat Servers Pty. Ltd.",
        "woodstock_telephone_company": "Woodstock Telephone Company",
        "worcester_polytechnic_institute": "Worcester Polytechnic Institute",
        "worcester_state_university": "Worcester State University",
        "world_crossing_telecom_guangzhou_ltd": "World Crossing Telecom Guangzhou Ltd.",
        "world_phone_internet_services_pvt_ltd": "World Phone Internet Services Pvt Ltd",
        "world_star_communication": "World Star Communication",
        "worldcall_telecom_ltd": "Worldcall Telecom Ltd",
        "worldlink_communications_pvt_ltd": "Worldlink Communications Pvt Ltd",
        "worldstream": "Worldstream",
        "worthington_city_schools": "Worthington City Schools",
        "wow_solutions_and_systems_pvt_ltd": "Wow Solutions And Systems Pvt Ltd",
        "wowrack_com": "Wowrack.com",
        "ws_retail_services_pvt_ltd": "Ws Retail Services Pvt. Ltd.",
        "wscan_telecom_ltda_me": "Wscan Telecom Ltda Me",
        "wss_telecomunicacao_ltda": "Wss Telecomunicacao Ltda",
        "wt_services_inc": "Wt Services Inc.",
        "wtc_communications": "Wtc Communications",
        "wtc_communications_inc": "Wtc Communications Inc.",
        "wv_fiber": "Wv Fiber",
        "wvva_net_inc": "Wvva.net Inc.",
        "wwprecision_communication": "Wwprecision Communication",
        "wwz_telekom_ag": "Wwz Telekom Ag",
        "wyndham_worldwide_corporation": "Wyndham Worldwide Corporation",
        "wyoming_com": "Wyoming.com",
        "wyoming_community_college_commission": "Wyoming Community College Commission",
        "wz_communications_inc": "Wz Communications Inc.",
        "x_integration_pty_ltd": "X Integration Pty Ltd",
        "xand_corporation": "Xand Corporation",
        "xarxes_de_telecomunicacions_alternatives_sl": "Xarxes De Telecomunicacions Alternatives Sl",
        "xavier_university_of_louisiana": "Xavier University Of Louisiana",
        "xbar7_communications_llc": "Xbar7 Communications Llc",
        "xchange_telecom_corp": "Xchange Telecom Corp.",
        "xenosite_b_v": "Xenosite B.v.",
        "xerox_corporation": "Xerox Corporation",
        "xfera_moviles_s_a": "Xfera Moviles S.a.",
        "xfiber_as": "Xfiber As",
        "xfone_018_ltd": "Xfone 018 Ltd",
        "xglobe_online_ltd": "Xglobe Online Ltd",
        "xifos_limited": "Xifos Limited",
        "xilinx_incorporated": "Xilinx Incorporated",
        "xinwei_intelcom_nic_s_a": "Xinwei Intelcom.nic S.a.",
        "xit_rural_telephone_cooperative_inc": "Xit Rural Telephone Cooperative Inc.",
        "xl_axiata": "Xl Axiata",
        "xl_broadband_inc": "Xl Broadband Inc.",
        "xmission_l_c": "Xmission L.c.",
        "xp_tecnologia_ltda": "Xp Tecnologia Ltda",
        "xplornet_communications_inc": "Xplornet Communications Inc.",
        "xpressbet_inc": "Xpressbet Inc.",
        "xs4all_internet_bv": "Xs4all Internet Bv",
        "xspedius_communications_co": "Xspedius Communications Co.",
        "xt_global_networks_ltd": "Xt Global Networks Ltd.",
        "xtel_communications": "Xtel Communications",
        "xtra_telecom_s_a": "Xtra Telecom S.a.",
        "xtrim_ltd": "X-trim Ltd.",
        "y_k_communications_ltd": "Y K Communications Ltd",
        "yadkin_valley_telephone": "Yadkin Valley Telephone",
        "yahoo": "Yahoo",
        "yahoo_inc": "Yahoo Inc.",
        "yale_university": "Yale University",
        "yalenew_haven_health_services_corporation": "Yale-new Haven Health Services Corporation",
        "yamagata_university": "Yamagata University",
        "yandex_llc": "Yandex Llc",
        "yarnet_ltd": "Yarnet Ltd",
        "yashash_cable_network_pvt_ltd": "Yashash Cable Network Pvt Ltd",
        "yelcot_telephone_company": "Yelcot Telephone Company",
        "yellowknife_wireless_company_llc": "Yellowknife Wireless Company Llc",
        "yesup_ecommerce_solutions_inc": "Yesup Ecommerce Solutions Inc.",
        "yeungnam_university": "Yeungnam University",
        "ygnition_networks": "Ygnition Networks",
        "yhc_international_bv": "Yhc International Bv",
        "yonsei_university": "Yonsei University",
        "york_college_of_pennsylvania": "York College Of Pennsylvania",
        "you_telecom_india_pvt_ltd": "You Telecom India Pvt Ltd",
        "youhost_ltd": "Youhost Ltd",
        "youngstown_state_university": "Youngstown State University",
        "ytl_communications_sdn_bhd": "Ytl Communications Sdn Bhd",
        "yucca_telecom": "Yucca Telecom",
        "yuzhniy_telecom_ltd": "Yuzhniy Telecom Ltd.",
        "zain_bahrain_b_s_c": "Zain Bahrain B.s.c.",
        "zain_iraq": "Zain Iraq",
        "zain_south_sudan": "Zain South Sudan",
        "zajil_international_telecom_company_w_l_l": "Zajil International Telecom Company W.l.l.",
        "zalando_se": "Zalando Se",
        "zamtel": "Zamtel",
        "zanzibar_telecom": "Zanzibar Telecom",
        "zao_aquafongsm": "Zao Aquafon-gsm",
        "zao_electrontelecom": "Zao Electrontelecom",
        "zao_energogarantservice": "Zao Energogarantservice",
        "zao_group_tower_telecom": "Zao Group Tower Telecom",
        "zap_telecomunicacoes": "Zap Telecomunicacoes",
        "zapbytes_technologies_pvt_ltd": "Zapbytes Technologies Pvt. Ltd.",
        "zayo_bandwidth": "Zayo Bandwidth",
        "zayo_group": "Zayo Group",
        "zayo_group_eu_limited": "Zayo Group Eu Limited",
        "zb_national_association": "Zb National Association",
        "zebra_technologies": "Zebra Technologies",
        "zeecon_wireless_internet_l_l_c": "Zeecon Wireless Internet L.l.c.",
        "zeelandnet_bv": "Zeelandnet Bv",
        "zelenaya_tochka_belgorod_llc": "Zelenaya Tochka Belgorod Llc",
        "zelenaya_tochka_lipetsk_llc": "Zelenaya Tochka Lipetsk Llc",
        "zelenaya_tochka_vladivistok_llc": "Zelenaya Tochka Vladivistok Llc",
        "zen_internet_ltd": "Zen Internet Ltd",
        "zenlayer_inc": "Zenlayer Inc",
        "zenon_n_s_p": "Zenon N.s.p.",
        "zeta_broadband": "Zeta Broadband",
        "ziggo_b_v": "Ziggo B.v.",
        "ziggo_services_b_v": "Ziggo Services B.v.",
        "zimbabwe_online": "Zimbabwe Online",
        "zito_media_l_p": "Zito Media L.p.",
        "zone_telecom_pte_ltd": "Zone Telecom Pte Ltd",
        "zrl_provedor_de_internet_ltda_me": "Zrl Provedor De Internet Ltda - Me",
        "zscaler_inc": "Zscaler Inc.",
        "ztelecom_ltd": "Z-telecom Ltd",
        "ztv_co_ltd": "Ztv Co. Ltd",
        "zuknet_networks_ltda_me": "Zuknet Networks Ltda Me",
        "zulu_internet_inc": "Zulu Internet Inc.",
        "zum_telecom_ltda_me": "Zum Telecom Ltda- Me",
        "zvezda_telecom_ltd": "Zvezda Telecom Ltd.",
        "zye_telecom_pvt_ltd": "Zye Telecom Pvt Ltd"
      },
      "messaging": {"skype": "Skype", "telegram": "Telegram", "yahoo_messenger": "Yahoo Messenger"},
      "mobile_carrier": {
        "2degrees_new_zealand": "2degrees New Zealand",
        "9_mobile_nigeria": "9 Mobile Nigeria",
        "a1_austria": "A1 Austria",
        "aircel_india": "Aircel India",
        "airtel_bangladesh": "Airtel Bangladesh",
        "airtel_ghana": "Airtel Ghana",
        "airtel_india": "Airtel India",
        "airtel_kenya": "Airtel Kenya",
        "airtel_nigeria": "Airtel Nigeria",
        "airtel_sri_lanka": "Airtel Sri Lanka",
        "ais_thailand": "AIS Thailand",
        "alcom": "Alcom",
        "algar_telecom_brazil": "Algar Telecom Brazil",
        "altel_kazakhstan": "Altel Kazakhstan",
        "antel_uruguay": "Antel Uruguay",
        "at_t_mexico": "AT\u0026T Mexico",
        "at_t_wireless_united_states": "AT\u0026T Wireless United States",
        "au_japan": "au Japan",
        "avantel_colombia": "Avantel Colombia",
        "axiata_bangladsh": "Axiata Bangladsh",
        "azercell_azerbaijan": "Azercell Azerbaijan",
        "bakcell_azerbaijan": "Bakcell Azerbaijan",
        "banglalink_bangladesh": "Banglalink Bangladesh",
        "base_belgium": "BASE Belgium",
        "becloud_belarus": "BeCloud Belarus",
        "beeline_kazakhstan": "Beeline Kazakhstan",
        "beeline_russia": "Beeline Russia",
        "bell_mobility_canada": "Bell Mobility Canada",
        "bitel_peru": "Bitel Peru",
        "bouygues_telecom_france": "Bouygues Telecom France",
        "bsnl_india": "BSNL India",
        "c_spire_wireless_united_states": "C Spire Wireless United States",
        "cable_wireless_panama": "Cable \u0026 Wireless Panama",
        "cafe_ivory_coast": "Cafe Ivory Coast",
        "celcom_malaysia": "Celcom Malaysia",
        "cell_c_south_africa": "Cell C South Africa",
        "cellcom_israel": "Cellcom Israel",
        "china_mobile_china": "China Mobile China",
        "china_telecom_china": "China Telecom China",
        "china_unicom_china": "China Unicom China",
        "cincinnati_bell_wireless_united_states": "Cincinnati Bell Wireless United States",
        "claro_argentina": "Claro Argentina",
        "claro_brazil": "Claro Brazil",
        "claro_colombia": "Claro Colombia",
        "claro_costa_rica": "Claro Costa Rica",
        "claro_ecuador": "Claro Ecuador",
        "claro_el_salvador": "Claro El Salvador",
        "claro_guatemala": "Claro Guatemala",
        "claro_honduras": "Claro Honduras",
        "claro_nicaragua": "Claro Nicaragua",
        "claro_panama": "Claro Panama",
        "claro_peru": "Claro Peru",
        "claro_puerto_rico": "Claro Puerto Rico",
        "claro_uruguay": "Claro Uruguay",
        "cnt_ecuador": "CNT Ecuador",
        "cora_de_comstar_ivory_coast": "Cora de COMSTAR Ivory Coast",
        "cosmote_greece": "Cosmote Greece",
        "cricket_wireless_united_states": "Cricket Wireless United States",
        "cyta_greece": "CYTA Greece",
        "debitel_germany": "Debitel Germany",
        "dialog_sri_lanka": "Dialog Sri Lanka",
        "digi_malaysia": "Digi Malaysia",
        "digi_mobil_hungary": "Digi Mobil Hungary",
        "digi_mobil_romania": "Digi.Mobil Romania",
        "digicel_el_salvador": "Digicel El Salvador",
        "digicell_panama": "Digicell Panama",
        "dna_finland": "DNA Finland",
        "dna_oyj": "DNA Oyj",
        "dtac_thailand": "dtac Thailand",
        "du_united_arab_emirates": "du United Arab Emirates",
        "ee_united_kingdom": "EE United Kingdom",
        "elisa": "Elisa",
        "elisa_finland": "Elisa Finland",
        "entel_peru": "Entel Peru",
        "etisalat_egypt": "Etisalat Egypt",
        "etisalat_sri_lanka": "Etisalat Sri Lanka",
        "etisalat_united_arab_emirates": "Etisalat United Arab Emirates",
        "expresso_telecom_ghana": "Expresso Telecom Ghana",
        "free_mobile_france": "Free Mobile France",
        "glo_ghana": "Glo Ghana",
        "glo_nigeria": "Glo Nigeria",
        "globe_philippines": "Globe Philippines",
        "gmobile_vietnam": "Gmobile Vietnam",
        "golan_telecom_israel": "Golan Telecom Israel",
        "grameen_bangladesh": "Grameen Bangladesh",
        "greenn_ivory_coast": "GreenN Ivory Coast",
        "h2o_wireless_united_states": "H2O Wireless United States",
        "hot_mobile_israel": "Hot Mobile Israel",
        "hutch_sri_lanka": "Hutch Sri Lanka",
        "ice_net_norway": "Ice.net Norway",
        "idea_cellular_india": "Idea Cellular India",
        "inwi_morocco": "Inwi Morocco",
        "irancell_iran": "Irancell Iran",
        "itisaluna_iraq": "Itisaluna Iraq",
        "jawwal_palestine": "Jawwal Palestine",
        "jio_india": "Jio India",
        "kcell_kazakhstan": "Kcell Kazakhstan",
        "kolbi_costa_rica": "Kolbi Costa Rica",
        "koz_ivory_coast": "Koz Ivory Coast",
        "kpn_netherlands": "KPN Netherlands",
        "kt_south_korea": "KT South Korea",
        "kyivstar_ukraine": "Kyivstar Ukraine",
        "lanka_bell_sri_lanka": "Lanka Bell Sri Lanka",
        "lg_u_south_korea": "LG U+ South Korea",
        "life_belarus": "Life Belarus",
        "lifecell_ukraine": "lifecell Ukraine",
        "maxis_malaysia": "Maxis Malaysia",
        "mci_iran": "MCI Iran",
        "megafon_russia": "MegaFon Russia",
        "meo_portugal": "MEO Portugal",
        "meteor_ireland": "Meteor Ireland",
        "metfone_cambodia": "Metfone Cambodia",
        "metropcs_united_states": "MetroPCS United States",
        "mobifone_vietnam": "MobiFone Vietnam",
        "mobily_saudi_arabia": "Mobily Saudi Arabia",
        "mobitel_sri_lanka": "Mobitel Sri Lanka",
        "moov_ivory_coast": "Moov Ivory Coast",
        "movistar_argentina": "Movistar Argentina",
        "movistar_colombia": "Movistar Colombia",
        "movistar_costa_rica": "Movistar Costa Rica",
        "movistar_ecuador": "Movistar Ecuador",
        "movistar_el_salvador": "Movistar El Salvador",
        "movistar_guatemala": "Movistar Guatemala",
        "movistar_mexico": "Movistar Mexico",
        "movistar_nicaragua": "Movistar Nicaragua",
        "movistar_panama": "Movistar Panama",
        "movistar_peru": "Movistar Peru",
        "movistar_spain": "Movistar Spain",
        "movistar_uruguay": "Movistar Uruguay",
        "mpt_myanmar": "MPT Myanmar",
        "mtn_cameroon": "MTN Cameroon",
        "mtn_cyprus": "MTN Cyprus",
        "mtn_ghana": "MTN Ghana",
        "mtn_ivory_coast": "MTN Ivory Coast",
        "mtn_nigeria": "MTN Nigeria",
        "mtn_south_africa": "MTN South Africa",
        "mtnl_india": "MTNL India",
        "mts_belarus": "MTS Belarus",
        "mts_india": "MTS India",
        "mts_russia": "MTS Russia",
        "mts_serbia": "Mts Serbia",
        "my_thailand": "MY Thailand",
        "nar_mobile_azerbaijan": "Nar mobile Azerbaijan",
        "naxtel_azerbaijan": "Naxtel Azerbaijan",
        "nextel_argentina": "Nextel Argentina",
        "nextel_brazil": "Nextel Brazil",
        "nexttel_cameroon": "Nexttel Cameroon",
        "nos_portugal": "NOS Portugal",
        "ntt_docomo_japan": "NTT DoCoMo Japan",
        "o2_czech_republic": "O2 Czech Republic",
        "o2_germany": "O2 Germany",
        "o2_united_kingdom": "O2 United Kingdom",
        "oi_brazil": "Oi Brazil",
        "ooredoo_indonesia": "Ooredoo Indonesia",
        "ooredoo_iraq": "Ooredoo Iraq",
        "ooredoo_kuwait": "Ooredoo Kuwait",
        "ooredoo_myanmar": "Ooredoo Myanmar",
        "ooredoo_tunisia": "Ooredoo Tunisia",
        "open_puerto_rico": "Open Puerto Rico",
        "optus_australia": "Optus Australia",
        "orange_belgium": "Orange Belgium",
        "orange_cameroon": "Orange Cameroon",
        "orange_egypt": "Orange Egypt",
        "orange_france": "Orange France",
        "orange_ivory_coast": "Orange Ivory Coast",
        "orange_morocco": "Orange Morocco",
        "orange_poland": "Orange Poland",
        "orange_romania": "Orange Romania",
        "orange_spain": "Orange Spain",
        "orange_tunisia": "Orange Tunisia",
        "p1_malaysia": "P1 Malaysia",
        "partner_israel": "Partner Israel",
        "pelephone_israel": "Pelephone Israel",
        "personal_argentina": "Personal Argentina",
        "personal_paraguay": "Personal Paraguay",
        "play_poland": "Play Poland",
        "plus_poland": "Plus Poland",
        "primetel_cyprus": "PrimeTel Cyprus",
        "proximus_belgium": "Proximus Belgium",
        "qb_cambodia": "Qb Cambodia",
        "rcom_india": "RCom India",
        "red_el_salvador": "Red El Salvador",
        "republic_wireless_united_states": "Republic Wireless United States",
        "rightel_iran": "RighTel Iran",
        "rogers_wireless_canada": "Rogers Wireless Canada",
        "safaricom_kenya": "Safaricom Kenya",
        "salt_switzerland": "Salt Switzerland",
        "sasktel_mobility_canada": "Sasktel Mobility Canada",
        "seatel_cambodia": "Seatel Cambodia",
        "sercomtel_brazil": "Sercomtel Brazil",
        "sfone_vietnam": "S-Fone Vietnam",
        "sfr_france": "SFR France",
        "shentel_wireless_united_states": "Shentel Wireless United States",
        "sk_south_korea": "SK South Korea",
        "smart_cambodia": "Smart Cambodia",
        "smart_philippines": "Smart Philippines",
        "smartfren_indonesia": "Smartfren Indonesia",
        "softbank_mobile_japan": "SoftBank Mobile Japan",
        "sonera_finland": "Sonera Finland",
        "spark_new_zealand": "Spark New Zealand",
        "sprint_pcs_united_states": "Sprint PCS United States",
        "sri_lanka_telecom": "Sri Lanka Telecom",
        "stc_saudi_arabia": "STC Saudi Arabia",
        "sunrise_switzerland": "Sunrise Switzerland",
        "swisscom_switzerland": "Swisscom Switzerland",
        "tata_docomo_india": "Tata Docomo India",
        "tdc_denmark": "TDC Denmark",
        "telcel_mexico": "Telcel Mexico",
        "tele2_kazakhstan": "Tele2 Kazakhstan",
        "tele2_netherlands": "Tele2 Netherlands",
        "tele2_russia": "Tele2 Russia",
        "tele2_sweden": "Tele2 Sweden",
        "telecom_morocco": "Telecom Morocco",
        "telecom_tunisia": "Telecom Tunisia",
        "telekom_germany": "Telekom Germany",
        "telekom_hungary": "Telekom Hungary",
        "telekom_romania": "Telekom Romania",
        "telenor_denmark": "Telenor Denmark",
        "telenor_hungary": "Telenor Hungary",
        "telenor_myanmar": "Telenor Myanmar",
        "telenor_norway": "Telenor Norway",
        "telenor_serbia": "Telenor Serbia",
        "telenor_sweden": "Telenor Sweden",
        "teletalk_bangladesh": "TeleTalk Bangladesh",
        "telia_denmark": "Telia Denmark",
        "telia_norway": "Telia Norway",
        "telia_sweden": "Telia Sweden",
        "telkom_kenya": "Telkom Kenya",
        "telkom_south_africa": "Telkom South Africa",
        "telkomsel_indonesia": "Telkomsel Indonesia",
        "telstra_australia": "Telstra Australia",
        "telus_mobility_canada": "Telus Mobility Canada",
        "three_austria": "Three Austria",
        "three_ireland": "Three Ireland",
        "three_italy": "three Italy",
        "three_sweden": "Three Sweden",
        "three_united_kingdom": "Three United Kingdom",
        "tigo_colombia": "Tigo Colombia",
        "tigo_el_salvador": "Tigo El Salvador",
        "tigo_guatemala": "Tigo Guatemala",
        "tigo_honduras": "Tigo Honduras",
        "tim_brazil": "TIM Brazil",
        "tim_italy": "TIM Italy",
        "tmobile_austria": "T-Mobile Austria",
        "tmobile_czech_republic": "T-Mobile Czech Republic",
        "tmobile_netherlands": "T-Mobile Netherlands",
        "tmobile_poland": "T-Mobile Poland",
        "tmobile_united_states": "T-Mobile United States",
        "tot_thailand": "TOT Thailand",
        "tracfone_wireless_united_states": "TracFone Wireless United States",
        "truemove_h_thailand": "truemove H Thailand",
        "turk_telekom_turkey": "Turk Telekom Turkey",
        "turkcell_turkey": "Turkcell Turkey",
        "u_mobile_malaysia": "U Mobile Malaysia",
        "ukko_mobile": "Ukko Mobile",
        "us_cellular_united_states": "US Cellular United States",
        "velcom_belarus": "Velcom Belarus",
        "verizon_wireless_united_states": "Verizon Wireless United States",
        "videotron_cellulaire_canada": "Videotron Cellulaire Canada",
        "vietnamobile_vietnam": "Vietnamobile Vietnam",
        "viettel_mobile_vietnam": "Viettel Mobile Vietnam",
        "vinaphone_vietnam": "Vinaphone Vietnam",
        "vip_serbia": "Vip Serbia",
        "viva_kuwait": "Viva Kuwait",
        "vivo_brazil": "Vivo Brazil",
        "vodacom_south_africa": "Vodacom South Africa",
        "vodafone_australia": "Vodafone Australia",
        "vodafone_czech_republic": "Vodafone Czech Republic",
        "vodafone_egypt": "Vodafone Egypt",
        "vodafone_germany": "Vodafone Germany",
        "vodafone_ghana": "Vodafone Ghana",
        "vodafone_greece": "Vodafone Greece",
        "vodafone_hungary": "Vodafone Hungary",
        "vodafone_india": "Vodafone India",
        "vodafone_ireland": "Vodafone Ireland",
        "vodafone_italy": "Vodafone Italy",
        "vodafone_netherlands": "Vodafone Netherlands",
        "vodafone_new_zealand": "Vodafone New Zealand",
        "vodafone_portugal": "Vodafone Portugal",
        "vodafone_romania": "Vodafone Romania",
        "vodafone_spain": "Vodafone Spain",
        "vodafone_turkey": "Vodafone Turkey",
        "vodafone_ukraine": "Vodafone Ukraine",
        "vodafone_united_kingdom": "Vodafone United Kingdom",
        "wataniya_palestine": "Wataniya Palestine",
        "we_egypt": "We Egypt",
        "wind_greece": "Wind Greece",
        "wind_italy": "Wind Italy",
        "xl_indonesia": "Xl Indonesia",
        "yes_4g_malaysia": "Yes 4G Malaysia",
        "yoigo_spain": "Yoigo Spain",
        "yoomee_ivory_coast": "YooMee Ivory Coast",
        "zain_iraq": "Zain Iraq",
        "zain_kuwait": "Zain Kuwait",
        "zain_saudi_arabia": "Zain Saudi Arabia"
      },
      "os_version": {
        "android_10_0": "Android (10.0)",
        "android_11_0": "Android (11.0)",
        "android_12_0": "Android (12.0)",
        "android_4_1": "Android (4.1)",
        "android_4_2": "Android (4.2)",
        "android_4_3": "Android (4.3)",
        "android_4_4": "Android (4.4)",
        "android_5_0": "Android (5.0)",
        "android_5_1": "Android (5.1)",
        "android_6_0": "Android (6.0)",
        "android_7_0": "Android (7.0)",
        "android_7_1": "Android (7.1)",
        "android_8_0": "Android (8.0)",
        "android_8_1": "Android (8.1)",
        "android_9_0": "Android (9.0)",
        "ios_10_0": "iOS (10.0)",
        "ios_10_1": "iOS (10.1)",
        "ios_10_2": "iOS (10.2)",
        "ios_10_3": "iOS (10.3)",
        "ios_11_0": "iOS (11.0)",
        "ios_11_1": "iOS (11.1)",
        "ios_11_2": "iOS (11.2)",
        "ios_11_3": "iOS (11.3)",
        "ios_11_4": "iOS (11.4)",
        "ios_12_0": "iOS (12.0)",
        "ios_12_1": "iOS (12.1)",
        "ios_12_2": "iOS (12.2)",
        "ios_12_3": "iOS (12.3)",
        "ios_12_4": "iOS (12.4)",
        "ios_13_0": "iOS (13.0)",
        "ios_13_1": "iOS (13.1)",
        "ios_13_2": "iOS (13.2)",
        "ios_13_3": "iOS (13.3)",
        "ios_13_4": "iOS (13.4)",
        "ios_13_5": "iOS (13.5)",
        "ios_13_6": "iOS (13.6)",
        "ios_13_7": "iOS (13.7)",
        "ios_14_0": "iOS (14.0)",
        "ios_14_1": "iOS (14.1)",
        "ios_14_2": "iOS (14.2)",
        "ios_14_3": "iOS (14.3)",
        "ios_14_4": "iOS (14.4)",
        "ios_14_5": "iOS (14.5)",
        "ios_14_6": "iOS (14.6)",
        "ios_14_7": "iOS (14.7)",
        "ios_14_8": "iOS (14.8)",
        "ios_15_0": "iOS (15.0)",
        "ios_15_1": "iOS (15.1)",
        "ios_15_2": "iOS (15.2)",
        "ios_7_0": "iOS (7.0)",
        "ios_7_1": "iOS (7.1)",
        "ios_8_0": "iOS (8.0)",
        "ios_8_1": "iOS (8.1)",
        "ios_8_2": "iOS (8.2)",
        "ios_8_3": "iOS (8.3)",
        "ios_8_4": "iOS (8.4)",
        "ios_9_0": "iOS (9.0)",
        "ios_9_1": "iOS (9.1)",
        "ios_9_2": "iOS (9.2)",
        "ios_9_3": "iOS (9.3)"
      },
      "platform": {
        "android": "Android",
        "blackberry_os": "BlackBerry OS",
        "brew": "Brew",
        "bsd": "BSD",
        "chrome_os": "Chrome OS",
        "firefox_os": "Firefox OS",
        "ios": "iOS",
        "l4": "L4",
        "lg_proprietary": "LG Proprietary",
        "linux": "Linux",
        "mac_os": "Mac OS",
        "nintendo_os": "Nintendo OS",
        "nokia_os": "Nokia OS",
        "playstation_os": "PlayStation OS",
        "samsung_proprietary": "Samsung Proprietary",
        "symbian": "Symbian",
        "tizen": "Tizen",
        "windows": "Windows",
        "windows_mobile": "Windows Mobile",
        "windows_phone": "Windows Phone",
        "windows_rt": "Windows RT"
      },
      "previous_software": {
        "1shoppingcart": "1ShoppingCart",
        "adjust": "Adjust",
        "affise": "Affise",
        "afftrack": "AffTrack",
        "ambassador": "Ambassador",
        "appsflyer": "Appsflyer",
        "awin": "Awin",
        "branch": "Branch",
        "cake": "Cake",
        "cjaffiliate": "CJAffiliate",
        "clickbank": "ClickBank",
        "clickfunnels": "ClickFunnels",
        "everflow": "Everflow",
        "google_analytics": "Google Analytics",
        "hasoffers": "HasOffers",
        "hitpath": "HitPath",
        "hubscale": "Hubscale",
        "hugoffers": "HugOffers",
        "idevaffiliate": "iDevAffiliate",
        "impact": "Impact",
        "internal": "Internal",
        "konnektive": "Konnektive",
        "leaddyno": "LeadDyno",
        "leadspedia": "LeadsPedia",
        "linkshare": "LinkShare",
        "linktrust": "Linktrust",
        "none": "None",
        "offerslook": "Offerslook",
        "ontraport": "Ontraport",
        "partnerstack": "PartnerStack",
        "pepperjam": "PepperJam",
        "post_affiliate_pro": "Post Affiliate Pro",
        "redtrack": "RedTrack",
        "refersion": "Refersion",
        "scaleo": "Scaleo",
        "shareasale": "ShareASale",
        "tapaffiliate": "TapAffiliate",
        "thrive": "Thrive",
        "track_revenue": "Track Revenue",
        "trackaff": "Trackaff",
        "trackia": "Trackia",
        "voluum": "Voluum"
      },
      "region": {
        "aargau_ch": "Aargau (CH)",
        "aberdeen_city_uk": "Aberdeen City (UK)",
        "aberdeenshire_uk": "Aberdeenshire (UK)",
        "abra_ph": "Abra (PH)",
        "abu_dhabi_ae": "Abu Dhabi (AE)",
        "aceh_id": "Aceh (ID)",
        "acre_br": "Acre (BR)",
        "adana_tr": "Adana (TR)",
        "adiyaman_tr": "Adiyaman (TR)",
        "afyonkarahisar_tr": "Afyonkarahisar (TR)",
        "agder_no": "Agder (NO)",
        "agrigento_it": "Agrigento (IT)",
        "aguascalientes_mx": "Aguascalientes (MX)",
        "agusan_del_norte_ph": "Agusan Del Norte (PH)",
        "agusan_del_sur_ph": "Agusan Del Sur (PH)",
        "aichi_jp": "Aichi (JP)",
        "ajman_ae": "Ajman (AE)",
        "akershus_no": "Akershus (NO)",
        "akita_jp": "Akita (JP)",
        "aklan_ph": "Aklan (PH)",
        "aksaray_tr": "Aksaray (TR)",
        "al_bahah_sa": "Al Bahah (SA)",
        "al_fujayrah_ae": "Al Fujayrah (AE)",
        "al_jawf_sa": "Al Jawf (SA)",
        "al_madinah_al_munawwarah_sa": "Al Madinah al Munawwarah (SA)",
        "alabama_us": "Alabama (US)",
        "alagoas_br": "Alagoas (BR)",
        "alajuela_cr": "Alajuela (CR)",
        "alaska_us": "Alaska (US)",
        "alava_es": "Alava (ES)",
        "albacete_es": "Albacete (ES)",
        "albay_ph": "Albay (PH)",
        "alberta_ca": "Alberta (CA)",
        "alessandria_it": "Alessandria (IT)",
        "alicante_es": "Alicante (ES)",
        "almeria_es": "Almeria (ES)",
        "alqassim_sa": "Al-Qassim (SA)",
        "altai_republic_ru": "Altai Republic (RU)",
        "altayskiy_kray_ru": "Altayskiy Kray (RU)",
        "amapa_br": "Amapa (BR)",
        "amasya_tr": "Amasya (TR)",
        "amazonas_br": "Amazonas (BR)",
        "amazonea_co": "Amazonea (CO)",
        "amurskaya_oblast_ru": "Amurskaya Oblast (RU)",
        "ancona_it": "Ancona (IT)",
        "andaman_and_nicobar_islands_in": "Andaman And Nicobar Islands (IN)",
        "andhra_pradesh_in": "Andhra Pradesh (IN)",
        "angus_uk": "Angus (UK)",
        "ankara_tr": "Ankara (TR)",
        "antalya_tr": "Antalya (TR)",
        "antioquia_co": "Antioquia (CO)",
        "antique_ph": "Antique (PH)",
        "antofagasta_cl": "Antofagasta (CL)",
        "antrim_and_newtownabbey_uk": "Antrim And Newtownabbey (UK)",
        "aomori_jp": "Aomori (JP)",
        "aosta_it": "Aosta (IT)",
        "apayao_ph": "Apayao (PH)",
        "appenzell_ausserrhoden_ch": "Appenzell Ausserrhoden (CH)",
        "appenzell_innerrhoden_ch": "Appenzell Innerrhoden (CH)",
        "ar_riyāḑ_sa": "Ar Riyāḑ (SA)",
        "arauca_co": "Arauca (CO)",
        "ardahan_tr": "Ardahan (TR)",
        "ards_and_north_down_uk": "Ards And North Down (UK)",
        "arezzo_it": "Arezzo (IT)",
        "argyll_and_bute_uk": "Argyll And Bute (UK)",
        "arizona_us": "Arizona (US)",
        "arkansas_us": "Arkansas (US)",
        "arkhangelskaya_oblast_ru": "Arkhangel'skaya Oblast (RU)",
        "armagh_banbridge_and_craigavon_uk": "Armagh Banbridge And Craigavon (UK)",
        "armed_forces_aa_us": "Armed Forces - AA (US)",
        "armed_forces_ae_us": "Armed Forces - AE (US)",
        "armed_forces_ap_us": "Armed Forces - AP (US)",
        "artvin_tr": "Artvin (TR)",
        "arunachal_pradesh_in": "Arunachal Pradesh (IN)",
        "ascoli_piceno_it": "Ascoli Piceno (IT)",
        "ash_shariqah_ae": "Ash Shariqah (AE)",
        "asir_sa": "Asir (SA)",
        "assam_in": "Assam (IN)",
        "asti_it": "Asti (IT)",
        "astrakhanskaya_oblast_ru": "Astrakhanskaya Oblast (RU)",
        "asturias_es": "Asturias (ES)",
        "atacama_cl": "Atacama (CL)",
        "atlantico_co": "Atlantico (CO)",
        "aurora_ph": "Aurora (PH)",
        "austagder_no": "Aust-Agder (NO)",
        "australian_capital_territory_au": "Australian Capital Territory (AU)",
        "auvergnerhonealpes_fr": "Auvergne-rhone-alpes (FR)",
        "avellino_it": "Avellino (IT)",
        "avila_es": "Avila (ES)",
        "aydın_tr": "Aydın (TR)",
        "aysen_cl": "Aysen (CL)",
        "azuay_ec": "Azuay (EC)",
        "ağrı_tr": "Ağrı (TR)",
        "badajoz_es": "Badajoz (ES)",
        "badenwurttemberg_de": "Baden-wurttemberg (DE)",
        "bahia_br": "Bahia (BR)",
        "baja_california_mx": "Baja California (MX)",
        "baja_california_sur_mx": "Baja California Sur (MX)",
        "baker_island_um": "Baker Island (UM)",
        "bali_id": "Bali (ID)",
        "balıkesir_tr": "Balıkesir (TR)",
        "banbridge_uk": "Banbridge (UK)",
        "bangka–belitung_islands_id": "Bangka–Belitung Islands (ID)",
        "banten_id": "Banten (ID)",
        "barcelona_es": "Barcelona (ES)",
        "bari_it": "Bari (IT)",
        "barking_and_dagenham_uk": "Barking And Dagenham (UK)",
        "barlettaandriatrani_it": "Barletta-andria-trani (IT)",
        "barnet_uk": "Barnet (UK)",
        "barnsley_uk": "Barnsley (UK)",
        "bartın_tr": "Bartın (TR)",
        "baselcity_ch": "Basel-City (CH)",
        "basellandschaft_ch": "Basel-Landschaft (CH)",
        "bashkortostan_ru": "Bashkortostan (RU)",
        "basilan_ph": "Basilan (PH)",
        "bataan_ph": "Bataan (PH)",
        "batanes_ph": "Batanes (PH)",
        "batangas_ph": "Batangas (PH)",
        "bath_and_north_east_somerset_uk": "Bath And North East Somerset (UK)",
        "batman_tr": "Batman (TR)",
        "bayburt_tr": "Bayburt (TR)",
        "bayern_de": "Bayern (DE)",
        "bedfordshire_uk": "Bedfordshire (UK)",
        "belfast_uk": "Belfast (UK)",
        "belgorodskaya_oblast_ru": "Belgorodskaya Oblast (RU)",
        "belgrade_rs": "Belgrade (RS)",
        "belluno_it": "Belluno (IT)",
        "benevento_it": "Benevento (IT)",
        "bengkulu_id": "Bengkulu (ID)",
        "benguet_ph": "Benguet (PH)",
        "bergamo_it": "Bergamo (IT)",
        "berlin_de": "Berlin (DE)",
        "bern_ch": "Bern (CH)",
        "bexley_uk": "Bexley (UK)",
        "biella_it": "Biella (IT)",
        "bihar_in": "Bihar (IN)",
        "bilecik_tr": "Bilecik (TR)",
        "biliran_ph": "Biliran (PH)",
        "bingöl_tr": "Bingöl (TR)",
        "birmingham_uk": "Birmingham (UK)",
        "bitlis_tr": "Bitlis (TR)",
        "blackburn_with_darwen_uk": "Blackburn With Darwen (UK)",
        "blackpool_uk": "Blackpool (UK)",
        "blaenau_gwent_uk": "Blaenau Gwent (UK)",
        "bocas_del_toro_pa": "Bocas Del Toro (PA)",
        "bohol_ph": "Bohol (PH)",
        "bolivar_co": "Bolivar (CO)",
        "bolivar_ec": "Bolivar (EC)",
        "bologna_it": "Bologna (IT)",
        "bolton_uk": "Bolton (UK)",
        "bolu_tr": "Bolu (TR)",
        "bolzano_it": "Bolzano (IT)",
        "borski_okrug_rs": "Borski Okrug (RS)",
        "bourgognefranchecomte_fr": "Bourgogne-franche-comte (FR)",
        "bournemouth_christchurch_and_poole_uk": "Bournemouth Christchurch and Poole (UK)",
        "bournemouth_uk": "Bournemouth (UK)",
        "boyaca_co": "Boyaca (CO)",
        "bracknell_forest_uk": "Bracknell Forest (UK)",
        "bradford_uk": "Bradford (UK)",
        "brandenburg_de": "Brandenburg (DE)",
        "branicevski_okrug_rs": "Branicevski Okrug (RS)",
        "bremen_de": "Bremen (DE)",
        "brent_uk": "Brent (UK)",
        "brescia_it": "Brescia (IT)",
        "bretagne_fr": "Bretagne (FR)",
        "bridgend_uk": "Bridgend (UK)",
        "brighton_and_hove_uk": "Brighton And Hove (UK)",
        "brindisi_it": "Brindisi (IT)",
        "bristol_uk": "Bristol (UK)",
        "british_columbia_ca": "British Columbia (CA)",
        "bromley_uk": "Bromley (UK)",
        "bryanskaya_oblast_ru": "Bryanskaya Oblast (RU)",
        "buckinghamshire_uk": "Buckinghamshire (UK)",
        "bukidnon_ph": "Bukidnon (PH)",
        "bulacan_ph": "Bulacan (PH)",
        "burdur_tr": "Burdur (TR)",
        "burgenland_at": "Burgenland (AT)",
        "burgos_es": "Burgos (ES)",
        "bursa_tr": "Bursa (TR)",
        "bury_uk": "Bury (UK)",
        "buskerud_no": "Buskerud (NO)",
        "caceres_es": "Caceres (ES)",
        "cadiz_es": "Cadiz (ES)",
        "caerphilly_uk": "Caerphilly (UK)",
        "cagayan_ph": "Cagayan (PH)",
        "cagliari_it": "Cagliari (IT)",
        "caldas_co": "Caldas (CO)",
        "calderdale_uk": "Calderdale (UK)",
        "california_us": "California (US)",
        "caltanissetta_it": "Caltanissetta (IT)",
        "camarines_norte_ph": "Camarines Norte (PH)",
        "camarines_sur_ph": "Camarines Sur (PH)",
        "cambridgeshire_uk": "Cambridgeshire (UK)",
        "camden_uk": "Camden (UK)",
        "camiguin_ph": "Camiguin (PH)",
        "campeche_mx": "Campeche (MX)",
        "campobasso_it": "Campobasso (IT)",
        "canar_ec": "Canar (EC)",
        "cantabria_es": "Cantabria (ES)",
        "capital_region_dk": "Capital Region (DK)",
        "capiz_ph": "Capiz (PH)",
        "caqueta_co": "Caqueta (CO)",
        "carboniaiglesias_it": "Carbonia-iglesias (IT)",
        "carchi_ec": "Carchi (EC)",
        "cardiff_uk": "Cardiff (UK)",
        "carmarthenshire_uk": "Carmarthenshire (UK)",
        "cartago_cr": "Cartago (CR)",
        "casanare_co": "Casanare (CO)",
        "caserta_it": "Caserta (IT)",
        "castellon_es": "Castellon (ES)",
        "catanduanes_ph": "Catanduanes (PH)",
        "catania_it": "Catania (IT)",
        "catanzaro_it": "Catanzaro (IT)",
        "cauca_co": "Cauca (CO)",
        "causeway_coast_and_glens_uk": "Causeway Coast And Glens (UK)",
        "cavite_ph": "Cavite (PH)",
        "ceara_br": "Ceara (BR)",
        "cebu_ph": "Cebu (PH)",
        "central_bedfordshire_uk": "Central Bedfordshire (UK)",
        "central_java_id": "Central Java (ID)",
        "central_jutland_dk": "Central Jutland (DK)",
        "central_kalimantan_id": "Central Kalimantan (ID)",
        "central_sulawesi_id": "Central Sulawesi (ID)",
        "centreval_de_loire_fr": "Centre-val De Loire (FR)",
        "ceredigion_uk": "Ceredigion (UK)",
        "cesar_co": "Cesar (CO)",
        "ceuta_es": "Ceuta (ES)",
        "chandigarh_in": "Chandigarh (IN)",
        "chechnya_ru": "Chechnya (RU)",
        "chelyabinskaya_oblast_ru": "Chelyabinskaya Oblast (RU)",
        "cheshire_east_uk": "Cheshire East (UK)",
        "cheshire_west_and_chester_uk": "Cheshire West And Chester (UK)",
        "chhattisgarh_in": "Chhattisgarh (IN)",
        "chiapas_mx": "Chiapas (MX)",
        "chiba_jp": "Chiba (JP)",
        "chieti_it": "Chieti (IT)",
        "chihuahua_mx": "Chihuahua (MX)",
        "chimborazo_ec": "Chimborazo (EC)",
        "chiriqui_pa": "Chiriqui (PA)",
        "choco_co": "Choco (CO)",
        "chukotskiy_avtonomnyy_okrug_ru": "Chukotskiy Avtonomnyy Okrug (RU)",
        "chuvashia_ru": "Chuvashia (RU)",
        "ciudad_de_mexico_mx": "ciudad de mexico (MX)",
        "ciudad_real_es": "Ciudad Real (ES)",
        "clackmannanshire_uk": "Clackmannanshire (UK)",
        "coahuila_mx": "Coahuila (MX)",
        "cocle_pa": "Cocle (PA)",
        "colima_mx": "Colima (MX)",
        "colon_pa": "Colon (PA)",
        "colorado_us": "Colorado (US)",
        "como_it": "Como (IT)",
        "compostela_valley_ph": "Compostela Valley (PH)",
        "connecticut_us": "Connecticut (US)",
        "conwy_uk": "Conwy (UK)",
        "coquimbo_cl": "Coquimbo (CL)",
        "cordoba_co": "Cordoba (CO)",
        "cordoba_es": "Cordoba (ES)",
        "cornwall_uk": "Cornwall (UK)",
        "corse_fr": "Corse (FR)",
        "cosenza_it": "Cosenza (IT)",
        "cotopaxi_ec": "Cotopaxi (EC)",
        "coventry_uk": "Coventry (UK)",
        "cremona_it": "Cremona (IT)",
        "crotone_it": "Crotone (IT)",
        "croydon_uk": "Croydon (UK)",
        "cuenca_es": "Cuenca (ES)",
        "cumbria_uk": "Cumbria (UK)",
        "cundimarca_co": "Cundimarca (CO)",
        "cuneo_it": "Cuneo (IT)",
        "dadra_and_nagar_haveli_and_daman_and_diu_in": "Dadra And Nagar Haveli And Daman And Diu (IN)",
        "dadra_and_nagar_haveli_in": "Dadra And Nagar Haveli (IN)",
        "dagestan_ru": "Dagestan (RU)",
        "daman_and_diu_in": "Daman And Diu (IN)",
        "darien_pa": "Darien (PA)",
        "darlington_uk": "Darlington (UK)",
        "davao_del_norte_ph": "Davao Del Norte (PH)",
        "davao_del_sur_ph": "Davao Del Sur (PH)",
        "davao_occidental_ph": "Davao Occidental (PH)",
        "davao_oriental_ph": "Davao Oriental (PH)",
        "delaware_us": "Delaware (US)",
        "delhi_in": "Delhi (IN)",
        "denbighshire_uk": "Denbighshire (UK)",
        "denizli_tr": "Denizli (TR)",
        "derby_uk": "Derby (UK)",
        "derbyshire_uk": "Derbyshire (UK)",
        "derry_and_strabane_uk": "Derry And Strabane (UK)",
        "devon_uk": "Devon (UK)",
        "dinagat_islands_ph": "Dinagat Islands (PH)",
        "district_of_columbia_us": "District of Columbia (US)",
        "distrito_capital_co": "Distrito Capital (CO)",
        "diyarbakir_tr": "Diyarbakir (TR)",
        "doncaster_uk": "Doncaster (UK)",
        "dorset_uk": "Dorset (UK)",
        "dubai_ae": "Dubai (AE)",
        "dudley_uk": "Dudley (UK)",
        "duezce_tr": "Duezce (TR)",
        "dumfries_and_galloway_uk": "Dumfries And Galloway (UK)",
        "dundee_city_uk": "Dundee City (UK)",
        "durango_mx": "Durango (MX)",
        "durham_uk": "Durham (UK)",
        "ealing_uk": "Ealing (UK)",
        "east_ayrshire_uk": "East Ayrshire (UK)",
        "east_dunbartonshire_uk": "East Dunbartonshire (UK)",
        "east_java_id": "East Java (ID)",
        "east_lothian_uk": "East Lothian (UK)",
        "east_nusa_tenggara_id": "East Nusa Tenggara (ID)",
        "east_renfrewshire_uk": "East Renfrewshire (UK)",
        "east_riding_of_yorkshire_uk": "East Riding Of Yorkshire (UK)",
        "east_sussex_uk": "East Sussex (UK)",
        "eastern_province_sa": "Eastern Province (SA)",
        "eastern_samar_ph": "Eastern Samar (PH)",
        "edinburgh_uk": "Edinburgh (UK)",
        "edirne_tr": "Edirne (TR)",
        "ehime_jp": "Ehime (JP)",
        "eilean_siar_uk": "Eilean Siar (UK)",
        "el_oro_ec": "El Oro (EC)",
        "elazığ_tr": "Elazığ (TR)",
        "emberawounaan_pa": "Embera-Wounaan (PA)",
        "enfield_uk": "Enfield (UK)",
        "enna_it": "Enna (IT)",
        "erzincan_tr": "Erzincan (TR)",
        "erzurum_tr": "Erzurum (TR)",
        "eskişehir_tr": "Eskişehir (TR)",
        "esmeraldas_ec": "Esmeraldas (EC)",
        "espirito_santo_br": "Espirito Santo (BR)",
        "essex_uk": "Essex (UK)",
        "falkirk_uk": "Falkirk (UK)",
        "federal_district_br": "Federal District (BR)",
        "fermanagh_and_omagh_uk": "Fermanagh And Omagh (UK)",
        "fermo_it": "Fermo (IT)",
        "ferrara_it": "Ferrara (IT)",
        "fife_uk": "Fife (UK)",
        "finnmark_fylke_no": "Finnmark Fylke (NO)",
        "firenze_it": "Firenze (IT)",
        "flintshire_uk": "Flintshire (UK)",
        "florida_us": "Florida (US)",
        "foggia_it": "Foggia (IT)",
        "forlicesena_it": "Forli-cesena (IT)",
        "fribourg_ch": "Fribourg (CH)",
        "frosinone_it": "Frosinone (IT)",
        "fukui_jp": "Fukui (JP)",
        "fukuoka_jp": "Fukuoka (JP)",
        "fukushimaken_jp": "Fukushima-ken (JP)",
        "galapagos_ec": "Galapagos (EC)",
        "gateshead_uk": "Gateshead (UK)",
        "gaziantep_tr": "Gaziantep (TR)",
        "geneva_ch": "Geneva (CH)",
        "genova_it": "Genova (IT)",
        "georgia_us": "Georgia (US)",
        "gifu_jp": "Gifu (JP)",
        "giresun_tr": "Giresun (TR)",
        "girona_es": "Girona (ES)",
        "glarus_ch": "Glarus (CH)",
        "glasgow_city_uk": "Glasgow City (UK)",
        "gloucestershire_uk": "Gloucestershire (UK)",
        "goa_in": "Goa (IN)",
        "goias_br": "Goias (BR)",
        "gorizia_it": "Gorizia (IT)",
        "gorontalo_id": "Gorontalo (ID)",
        "granada_es": "Granada (ES)",
        "grandest_fr": "Grand-est (FR)",
        "greenwich_uk": "Greenwich (UK)",
        "grisons_ch": "Grisons (CH)",
        "grosseto_it": "Grosseto (IT)",
        "guadalajara_es": "Guadalajara (ES)",
        "guainia_co": "Guainia (CO)",
        "guanacaste_cr": "Guanacaste (CR)",
        "guanajuato_mx": "Guanajuato (MX)",
        "guaviare_co": "Guaviare (CO)",
        "guayas_ec": "Guayas (EC)",
        "guemueshane_tr": "Guemueshane (TR)",
        "guerrero_mx": "Guerrero (MX)",
        "guimaras_ph": "Guimaras (PH)",
        "guipuzcoa_es": "Guipuzcoa (ES)",
        "gujarat_in": "Gujarat (IN)",
        "guna_yala_pa": "Guna Yala (PA)",
        "gunma_jp": "Gunma (JP)",
        "gwynedd_uk": "Gwynedd (UK)",
        "hackney_uk": "Hackney (UK)",
        "hail_sa": "Hail (SA)",
        "hakkâri_tr": "Hakkâri (TR)",
        "halton_uk": "Halton (UK)",
        "hamburg_de": "Hamburg (DE)",
        "hammersmith_and_fulham_uk": "Hammersmith And Fulham (UK)",
        "hampshire_uk": "Hampshire (UK)",
        "haringey_uk": "Haringey (UK)",
        "harrow_uk": "Harrow (UK)",
        "hartlepool_uk": "Hartlepool (UK)",
        "haryana_in": "Haryana (IN)",
        "hatay_tr": "Hatay (TR)",
        "hautsdefrance_fr": "Hauts-de-france (FR)",
        "havering_uk": "Havering (UK)",
        "hawaii_us": "Hawaii (US)",
        "hedmark_no": "Hedmark (NO)",
        "heredia_cr": "Heredia (CR)",
        "herefordshire_uk": "Herefordshire (UK)",
        "herrera_pa": "Herrera (PA)",
        "hertfordshire_uk": "Hertfordshire (UK)",
        "hessen_de": "Hessen (DE)",
        "hidalgo_mx": "Hidalgo (MX)",
        "highland_uk": "Highland (UK)",
        "hillingdon_uk": "Hillingdon (UK)",
        "himachal_pradesh_in": "Himachal Pradesh (IN)",
        "hiroshima_jp": "Hiroshima (JP)",
        "hokkaido_jp": "Hokkaido (JP)",
        "hordaland_fylke_no": "Hordaland Fylke (NO)",
        "hounslow_uk": "Hounslow (UK)",
        "howland_island_um": "Howland Island (UM)",
        "huelva_es": "Huelva (ES)",
        "huesca_es": "Huesca (ES)",
        "huila_co": "Huila (CO)",
        "hyōgo_jp": "Hyōgo (JP)",
        "ibaraki_jp": "Ibaraki (JP)",
        "idaho_us": "Idaho (US)",
        "ifugao_ph": "Ifugao (PH)",
        "iledefrance_fr": "Ile-de-france (FR)",
        "illinois_us": "Illinois (US)",
        "ilocos_norte_ph": "Ilocos Norte (PH)",
        "ilocos_sur_ph": "Ilocos Sur (PH)",
        "iloilo_ph": "Iloilo (PH)",
        "imbabura_ec": "Imbabura (EC)",
        "imperia_it": "Imperia (IT)",
        "indiana_us": "Indiana (US)",
        "innlandet_no": "Innlandet (NO)",
        "inverclyde_uk": "Inverclyde (UK)",
        "iowa_us": "Iowa (US)",
        "irkutskaya_oblast_ru": "Irkutskaya Oblast (RU)",
        "isabela_ph": "Isabela (PH)",
        "isernia_it": "Isernia (IT)",
        "ishikawa_jp": "Ishikawa (JP)",
        "islas_baleares_es": "Islas Baleares (ES)",
        "isle_of_anglesey_uk": "Isle Of Anglesey (UK)",
        "isle_of_wight_uk": "Isle Of Wight (UK)",
        "isles_of_scilly_uk": "Isles Of Scilly (UK)",
        "islington_uk": "Islington (UK)",
        "isparta_tr": "Isparta (TR)",
        "istanbul_tr": "Istanbul (TR)",
        "ivanovskaya_oblast_ru": "Ivanovskaya Oblast (RU)",
        "iwate_jp": "Iwate (JP)",
        "izmir_tr": "Izmir (TR)",
        "iğdır_tr": "Iğdır (TR)",
        "jablanicki_okrug_rs": "Jablanicki Okrug (RS)",
        "jaen_es": "Jaen (ES)",
        "jakarta_id": "Jakarta (ID)",
        "jalisco_mx": "Jalisco (MX)",
        "jambi_id": "Jambi (ID)",
        "jammu_and_kashmir_in": "Jammu And Kashmir (IN)",
        "jan_mayen_no": "Jan Mayen (NO)",
        "jarvis_island_um": "Jarvis Island (UM)",
        "jharkhand_in": "Jharkhand (IN)",
        "jizan_sa": "Jizan (SA)",
        "johnston_atoll_um": "Johnston Atoll (UM)",
        "jura_ch": "Jura (CH)",
        "juznobanatcki_okrug_rs": "Juznobanatcki Okrug (RS)",
        "juznobanatski_okrug_rs": "Juznobanatski Okrug (RS)",
        "kabardinobalkarskaya_respublika_ru": "Kabardino-Balkarskaya Respublika (RU)",
        "kagawa_jp": "Kagawa (JP)",
        "kagoshima_jp": "Kagoshima (JP)",
        "kahramanmaraş_tr": "Kahramanmaraş (TR)",
        "kalimantan_utara_id": "Kalimantan Utara (ID)",
        "kalingaapayso_ph": "Kalinga-apayso (PH)",
        "kaliningradskaya_oblast_ru": "Kaliningradskaya Oblast (RU)",
        "kalmykiya_ru": "Kalmykiya (RU)",
        "kaluzhskaya_oblast_ru": "Kaluzhskaya Oblast (RU)",
        "kamchatskiy_kray_ru": "Kamchatskiy Kray (RU)",
        "kanagawa_jp": "Kanagawa (JP)",
        "kansas_us": "Kansas (US)",
        "karabuek_tr": "Karabuek (TR)",
        "karachayevocherkesiya_ru": "Karachayevo-Cherkesiya (RU)",
        "karaman_tr": "Karaman (TR)",
        "karnataka_in": "Karnataka (IN)",
        "karnten_at": "Karnten (AT)",
        "kars_tr": "Kars (TR)",
        "kastamonu_tr": "Kastamonu (TR)",
        "kayseri_tr": "Kayseri (TR)",
        "kemerovskaya_oblast_ru": "Kemerovskaya Oblast (RU)",
        "kensington_and_chelsea_uk": "Kensington And Chelsea (UK)",
        "kent_uk": "Kent (UK)",
        "kentucky_us": "Kentucky (US)",
        "kerala_in": "Kerala (IN)",
        "khabarovskiy_kray_ru": "Khabarovskiy Kray (RU)",
        "khantymansiyskiy_avtonomnyy_okrugyugra_ru": "Khanty-mansiyskiy Avtonomnyy Okrug-yugra (RU)",
        "kilis_tr": "Kilis (TR)",
        "kingman_reef_um": "Kingman Reef (UM)",
        "kingston_upon_hull_uk": "Kingston Upon Hull (UK)",
        "kingston_upon_thames_uk": "Kingston Upon Thames (UK)",
        "kirklees_uk": "Kirklees (UK)",
        "kirovskaya_oblast_ru": "Kirovskaya Oblast (RU)",
        "knowsley_uk": "Knowsley (UK)",
        "kocaeli_tr": "Kocaeli (TR)",
        "kochi_jp": "Kochi (JP)",
        "kolubarski_okrug_rs": "Kolubarski Okrug (RS)",
        "komi_republic_ru": "Komi Republic (RU)",
        "konya_tr": "Konya (TR)",
        "kosovski_okrug_rs": "Kosovski Okrug (RS)",
        "kosovskomitrovacki_okrug_rs": "Kosovsko-mitrovacki Okrug (RS)",
        "kosovskopomoravski_okrug_rs": "Kosovsko-pomoravski Okrug (RS)",
        "kostromskaya_oblast_ru": "Kostromskaya Oblast (RU)",
        "krasnodarskiy_kray_ru": "Krasnodarskiy Kray (RU)",
        "krasnoyarskiy_kray_ru": "Krasnoyarskiy Kray (RU)",
        "kumamoto_jp": "Kumamoto (JP)",
        "kurganskaya_oblast_ru": "Kurganskaya Oblast (RU)",
        "kurskaya_oblast_ru": "Kurskaya Oblast (RU)",
        "kyoto_jp": "Kyoto (JP)",
        "kütahya_tr": "Kütahya (TR)",
        "kırklareli_tr": "Kırklareli (TR)",
        "kırıkkale_tr": "Kırıkkale (TR)",
        "kırşehir_tr": "Kırşehir (TR)",
        "la_coruna_es": "La Coruna (ES)",
        "la_guajira_co": "La Guajira (CO)",
        "la_rioja_es": "La Rioja (ES)",
        "la_spezia_it": "La Spezia (IT)",
        "la_union_ph": "La Union (PH)",
        "laguna_ph": "Laguna (PH)",
        "lakshadweep_in": "Lakshadweep (IN)",
        "lambeth_uk": "Lambeth (UK)",
        "lampung_id": "Lampung (ID)",
        "lanao_del_norte_ph": "Lanao Del Norte (PH)",
        "lanao_del_sur_ph": "Lanao Del Sur (PH)",
        "lancashire_uk": "Lancashire (UK)",
        "laquila_it": "L'aquila (IT)",
        "las_palmas_es": "Las Palmas (ES)",
        "latina_it": "Latina (IT)",
        "lecce_it": "Lecce (IT)",
        "lecco_it": "Lecco (IT)",
        "leeds_uk": "Leeds (UK)",
        "leicester_uk": "Leicester (UK)",
        "leicestershire_uk": "Leicestershire (UK)",
        "leningradskaya_oblast_ru": "Leningradskaya Oblast (RU)",
        "leon_es": "Leon (ES)",
        "lerida_es": "Lerida (ES)",
        "lewisham_uk": "Lewisham (UK)",
        "leyte_ph": "Leyte (PH)",
        "libertador_general_bernardo_ohiggins_cl": "Libertador General Bernardo O'Higgins (CL)",
        "limon_cr": "Limon (CR)",
        "lincolnshire_uk": "Lincolnshire (UK)",
        "lipetskaya_oblast_ru": "Lipetskaya Oblast (RU)",
        "lisburn_and_castlereagh_uk": "Lisburn And Castlereagh (UK)",
        "liverpool_uk": "Liverpool (UK)",
        "livorno_it": "Livorno (IT)",
        "lodi_it": "Lodi (IT)",
        "loja_ec": "Loja (EC)",
        "london_uk": "London (UK)",
        "los_lagos_cl": "Los Lagos (CL)",
        "los_rios_ec": "Los Rios (EC)",
        "los_santos_pa": "Los Santos (PA)",
        "louisiana_us": "Louisiana (US)",
        "lucca_it": "Lucca (IT)",
        "lucerne_ch": "Lucerne (CH)",
        "lugo_es": "Lugo (ES)",
        "luton_uk": "Luton (UK)",
        "macerata_it": "Macerata (IT)",
        "macvanski_okrug_rs": "Macvanski Okrug (RS)",
        "madhya_pradesh_in": "Madhya Pradesh (IN)",
        "madrid_es": "Madrid (ES)",
        "magadanskaya_oblast_ru": "Magadanskaya Oblast (RU)",
        "magdalena_co": "Magdalena (CO)",
        "maguindanao_ph": "Maguindanao (PH)",
        "maharashtra_in": "Maharashtra (IN)",
        "maine_us": "Maine (US)",
        "makkah_province_sa": "Makkah Province (SA)",
        "malaga_es": "Malaga (ES)",
        "malatya_tr": "Malatya (TR)",
        "maluku_id": "Maluku (ID)",
        "manabi_ec": "Manabi (EC)",
        "manchester_uk": "Manchester (UK)",
        "manipur_in": "Manipur (IN)",
        "manisa_tr": "Manisa (TR)",
        "manitoba_ca": "Manitoba (CA)",
        "mantova_it": "Mantova (IT)",
        "maranhao_br": "Maranhao (BR)",
        "mardin_tr": "Mardin (TR)",
        "marinduque_ph": "Marinduque (PH)",
        "maryland_us": "Maryland (US)",
        "masbate_ph": "Masbate (PH)",
        "massa_it": "Massa (IT)",
        "massachusetts_us": "Massachusetts (US)",
        "matera_it": "Matera (IT)",
        "mato_grosso_br": "Mato Grosso (BR)",
        "mato_grosso_do_sul_br": "Mato Grosso do Sul (BR)",
        "maule_cl": "Maule (CL)",
        "mecklenburgvorpommern_de": "Mecklenburg-vorpommern (DE)",
        "medio_campidano_it": "Medio Campidano (IT)",
        "medway_uk": "Medway (UK)",
        "meghalaya_in": "Meghalaya (IN)",
        "melilla_es": "Melilla (ES)",
        "mersin_tr": "Mersin (TR)",
        "merthyr_tydfil_uk": "Merthyr Tydfil (UK)",
        "merton_uk": "Merton (UK)",
        "messina_it": "Messina (IT)",
        "meta_co": "Meta (CO)",
        "mexico_mx": "Mexico (MX)",
        "michigan_us": "Michigan (US)",
        "michoacan_mx": "Michoacan (MX)",
        "mid_and_east_antrim_uk": "Mid And East Antrim (UK)",
        "mid_ulster_uk": "Mid Ulster (UK)",
        "middlesbrough_uk": "Middlesbrough (UK)",
        "midlothian_uk": "Midlothian (UK)",
        "midway_islands_um": "Midway Islands (UM)",
        "mie_jp": "Mie (JP)",
        "milano_it": "Milano (IT)",
        "milton_keynes_uk": "Milton Keynes (UK)",
        "minas_gerais_br": "Minas Gerais (BR)",
        "mindoro_occidental_ph": "Mindoro Occidental (PH)",
        "mindoro_oriental_ph": "Mindoro Oriental (PH)",
        "minnesota_us": "Minnesota (US)",
        "misamis_occidental_ph": "Misamis Occidental (PH)",
        "misamis_oriental_ph": "Misamis Oriental (PH)",
        "mississippi_us": "Mississippi (US)",
        "missouri_us": "Missouri (US)",
        "miyagi_jp": "Miyagi (JP)",
        "miyazaki_jp": "Miyazaki (JP)",
        "mizoram_in": "Mizoram (IN)",
        "modena_it": "Modena (IT)",
        "monmouthshire_uk": "Monmouthshire (UK)",
        "montana_us": "Montana (US)",
        "monza_and_brianza_it": "Monza And Brianza (IT)",
        "moravicki_okrug_rs": "Moravicki Okrug (RS)",
        "moray_uk": "Moray (UK)",
        "more_og_romsdal_fylke_no": "More og Romsdal fylke (NO)",
        "morelos_mx": "Morelos (MX)",
        "moronasantiago_ec": "Morona-Santiago (EC)",
        "moskovskaya_oblast_ru": "Moskovskaya Oblast (RU)",
        "moskva_ru": "Moskva (RU)",
        "mountain_province_ph": "Mountain Province (PH)",
        "murcia_es": "Murcia (ES)",
        "murmanskaya_oblast_ru": "Murmanskaya Oblast (RU)",
        "muğla_tr": "Muğla (TR)",
        "muş_tr": "Muş (TR)",
        "nagaland_in": "Nagaland (IN)",
        "nagano_jp": "Nagano (JP)",
        "nagasaki_jp": "Nagasaki (JP)",
        "najran_sa": "Najran (SA)",
        "napo_ec": "Napo (EC)",
        "napoli_it": "Napoli (IT)",
        "nara_jp": "Nara (JP)",
        "narino_co": "Narino (CO)",
        "national_capital_region_ph": "National Capital Region (PH)",
        "navarra_es": "Navarra (ES)",
        "navassa_island_um": "Navassa Island (UM)",
        "nayarit_mx": "Nayarit (MX)",
        "neath_port_talbot_uk": "Neath Port Talbot (UK)",
        "nebraska_us": "Nebraska (US)",
        "negroe_occidental_ph": "Negroe Occidental (PH)",
        "negros_oriental_ph": "Negros Oriental (PH)",
        "nenetskiy_avtonomnyy_okrug_ru": "Nenetskiy Avtonomnyy Okrug (RU)",
        "neuchâtel_ch": "Neuchâtel (CH)",
        "nevada_us": "Nevada (US)",
        "nevsehir_tr": "Nevsehir (TR)",
        "new_brunswick_ca": "New Brunswick (CA)",
        "new_hampshire_us": "New Hampshire (US)",
        "new_jersey_us": "New Jersey (US)",
        "new_mexico_us": "New Mexico (US)",
        "new_south_wales_au": "New South Wales (AU)",
        "new_york_us": "New York (US)",
        "newcastle_upon_tyne_uk": "Newcastle Upon Tyne (UK)",
        "newfoundland_and_labrador_ca": "Newfoundland and Labrador (CA)",
        "newham_uk": "Newham (UK)",
        "newport_uk": "Newport (UK)",
        "newry_mourne_and_down_uk": "Newry Mourne And Down (UK)",
        "ngoebebugle_pa": "Ngoebe-Bugle (PA)",
        "nidwalden_ch": "Nidwalden (CH)",
        "niederosterreich_at": "Niederosterreich (AT)",
        "niedersachsen_de": "Niedersachsen (DE)",
        "nigde_tr": "Nigde (TR)",
        "niigata_jp": "Niigata (JP)",
        "nisavski_okrug_rs": "Nisavski Okrug (RS)",
        "nizhegorodskaya_oblast_ru": "Nizhegorodskaya Oblast (RU)",
        "no_region_pr": "no region (PR)",
        "nordland_fylke_no": "Nordland Fylke (NO)",
        "nordrheinwestfalen_de": "Nordrhein-westfalen (DE)",
        "norfolk_uk": "Norfolk (UK)",
        "normandie_fr": "Normandie (FR)",
        "norte_de_santander_co": "Norte De Santander (CO)",
        "north_ayrshire_uk": "North Ayrshire (UK)",
        "north_carolina_us": "North Carolina (US)",
        "north_cotabato_ph": "North Cotabato (PH)",
        "north_dakota_us": "North Dakota (US)",
        "north_denmark_dk": "North Denmark (DK)",
        "north_east_lincolnshire_uk": "North East Lincolnshire (UK)",
        "north_kalimantan_id": "North Kalimantan (ID)",
        "north_lanarkshire_uk": "North Lanarkshire (UK)",
        "north_lincolnshire_uk": "North Lincolnshire (UK)",
        "north_maluku_id": "North Maluku (ID)",
        "north_ossetia_ru": "North Ossetia (RU)",
        "north_somerset_uk": "North Somerset (UK)",
        "north_sulawesi_id": "North Sulawesi (ID)",
        "north_sumatra_id": "North Sumatra (ID)",
        "north_tyneside_uk": "North Tyneside (UK)",
        "north_yorkshire_uk": "North Yorkshire (UK)",
        "northamptonshire_uk": "Northamptonshire (UK)",
        "northern_borders_sa": "Northern Borders (SA)",
        "northern_samar_ph": "Northern Samar (PH)",
        "northern_territory_au": "Northern Territory (AU)",
        "northumberland_uk": "Northumberland (UK)",
        "northwest_territories_ca": "Northwest Territories (CA)",
        "nottingham_uk": "Nottingham (UK)",
        "nottinghamshire_uk": "Nottinghamshire (UK)",
        "nouvelleaquitaine_fr": "Nouvelle-aquitaine (FR)",
        "nova_scotia_ca": "Nova Scotia (CA)",
        "novara_it": "Novara (IT)",
        "novgorodskaya_oblast_ru": "Novgorodskaya Oblast (RU)",
        "novosibirskaya_oblast_ru": "Novosibirskaya Oblast (RU)",
        "nuble_cl": "Nuble (CL)",
        "nueva_ecija_ph": "Nueva Ecija (PH)",
        "nueva_vizcaya_ph": "Nueva Vizcaya (PH)",
        "nuevo_leon_mx": "Nuevo Leon (MX)",
        "nunavut_ca": "Nunavut (CA)",
        "nuoro_it": "Nuoro (IT)",
        "oaxaca_mx": "Oaxaca (MX)",
        "oberosterreich_at": "Oberosterreich (AT)",
        "obwalden_ch": "Obwalden (CH)",
        "occitanie_fr": "Occitanie (FR)",
        "odisha_in": "Odisha (IN)",
        "ogliastra_it": "Ogliastra (IT)",
        "ohio_us": "Ohio (US)",
        "oita_jp": "Oita (JP)",
        "okayama_jp": "Okayama (JP)",
        "okinawa_jp": "Okinawa (JP)",
        "oklahoma_us": "Oklahoma (US)",
        "olbiatempio_it": "Olbia-tempio (IT)",
        "oldham_uk": "Oldham (UK)",
        "omskaya_oblast_ru": "Omskaya Oblast (RU)",
        "ontario_ca": "Ontario (CA)",
        "oppland_no": "Oppland (NO)",
        "ordu_tr": "Ordu (TR)",
        "oregon_us": "Oregon (US)",
        "orellana_ec": "Orellana (EC)",
        "orenburgskaya_oblast_ru": "Orenburgskaya Oblast (RU)",
        "orense_es": "Orense (ES)",
        "oristano_it": "Oristano (IT)",
        "orkney_islands_uk": "Orkney Islands (UK)",
        "orlovskaya_oblast_ru": "Orlovskaya Oblast (RU)",
        "oslo_county_no": "Oslo County (NO)",
        "osmaniye_tr": "Osmaniye (TR)",
        "oxfordshire_uk": "Oxfordshire (UK)",
        "padova_it": "Padova (IT)",
        "palawan_ph": "Palawan (PH)",
        "palencia_es": "Palencia (ES)",
        "palermo_it": "Palermo (IT)",
        "palmyra_atoll_um": "Palmyra Atoll (UM)",
        "pampanga_ph": "Pampanga (PH)",
        "panama_oeste_pa": "Panama Oeste (PA)",
        "panama_pa": "Panama (PA)",
        "pangasinan_ph": "Pangasinan (PH)",
        "papua_id": "Papua (ID)",
        "para_br": "Para (BR)",
        "parana_br": "Parana (BR)",
        "paraíba_br": "Paraíba (BR)",
        "parma_it": "Parma (IT)",
        "pastaza_ec": "Pastaza (EC)",
        "pavia_it": "Pavia (IT)",
        "paysdelaloire_fr": "Pays-de-la-loire (FR)",
        "pcinjski_okrug_rs": "Pcinjski Okrug (RS)",
        "pecki_okrug_rs": "Pecki Okrug (RS)",
        "pembrokeshire_uk": "Pembrokeshire (UK)",
        "pennsylvania_us": "Pennsylvania (US)",
        "penzenskaya_oblast_ru": "Penzenskaya Oblast (RU)",
        "permskiy_kray_ru": "Permskiy Kray (RU)",
        "pernambuco_br": "Pernambuco (BR)",
        "perth_and_kinross_uk": "Perth And Kinross (UK)",
        "perugia_it": "Perugia (IT)",
        "pesaro_and_urbino_it": "Pesaro And Urbino (IT)",
        "pescara_it": "Pescara (IT)",
        "peterborough_uk": "Peterborough (UK)",
        "piacenza_it": "Piacenza (IT)",
        "piaui_br": "Piaui (BR)",
        "pichincha_ec": "Pichincha (EC)",
        "pirotski_okrug_rs": "Pirotski Okrug (RS)",
        "pisa_it": "Pisa (IT)",
        "pistoia_it": "Pistoia (IT)",
        "plymouth_uk": "Plymouth (UK)",
        "podunavski_okrug_rs": "Podunavski Okrug (RS)",
        "pomoravski_okrug_rs": "Pomoravski Okrug (RS)",
        "pontevedra_es": "Pontevedra (ES)",
        "poole_uk": "Poole (UK)",
        "pordenone_it": "Pordenone (IT)",
        "portsmouth_uk": "Portsmouth (UK)",
        "potenza_it": "Potenza (IT)",
        "powys_uk": "Powys (UK)",
        "prato_it": "Prato (IT)",
        "primorskiy_kray_ru": "Primorskiy Kray (RU)",
        "prince_edward_island_ca": "Prince Edward Island (CA)",
        "prizrenski_okrug_rs": "Prizrenski Okrug (RS)",
        "provencealpescotedazur_fr": "Provence-alpes-cote-d'azur (FR)",
        "provincia_de_san_jose_cr": "Provincia de San Jose (CR)",
        "provincia_de_santa_elena_ec": "Provincia de Santa Elena (EC)",
        "provincia_de_santo_domingo_de_los_tsachilas_ec": "Provincia de Santo Domingo de los Tsachilas (EC)",
        "pskovskaya_oblast_ru": "Pskovskaya Oblast (RU)",
        "puducherry_in": "Puducherry (IN)",
        "puebla_mx": "Puebla (MX)",
        "punjab_in": "Punjab (IN)",
        "puntarenas_cr": "Puntarenas (CR)",
        "putumayo_co": "Putumayo (CO)",
        "quebec_ca": "Quebec (CA)",
        "queensland_au": "Queensland (AU)",
        "queretaro_mx": "Queretaro (MX)",
        "quezon_ph": "Quezon (PH)",
        "quindio_co": "Quindio (CO)",
        "quintana_roo_mx": "Quintana Roo (MX)",
        "quirino_ph": "Quirino (PH)",
        "ragusa_it": "Ragusa (IT)",
        "rajasthan_in": "Rajasthan (IN)",
        "ras_alkhaimah_ae": "Ras Al-Khaimah (AE)",
        "rasinski_okrug_rs": "Rasinski Okrug (RS)",
        "raska_okrug_rs": "Raska Okrug (RS)",
        "ravenna_it": "Ravenna (IT)",
        "reading_uk": "Reading (UK)",
        "redbridge_uk": "Redbridge (UK)",
        "redcar_and_cleveland_uk": "Redcar And Cleveland (UK)",
        "reggio_calabria_it": "Reggio Calabria (IT)",
        "reggio_emilia_it": "Reggio Emilia (IT)",
        "region_de_arica_y_parinacota_cl": "Region de Arica y Parinacota (CL)",
        "region_de_la_araucania_cl": "Region de la Araucania (CL)",
        "region_de_los_rios_cl": "Region de Los Rios (CL)",
        "region_de_magallanes_y_de_la_antartica_chilena_cl": "Region de Magallanes y de la Antartica Chilena (CL)",
        "region_de_valparaiso_cl": "Region de Valparaiso (CL)",
        "region_del_biobio_cl": "Region del Biobio (CL)",
        "renfrewshire_uk": "Renfrewshire (UK)",
        "republic_of_karelia_ru": "Republic of Karelia (RU)",
        "respublika_adygeya_ru": "Respublika Adygeya (RU)",
        "respublika_buryatiya_ru": "Respublika Buryatiya (RU)",
        "respublika_ingushetiya_ru": "Respublika Ingushetiya (RU)",
        "respublika_khakasiya_ru": "Respublika Khakasiya (RU)",
        "respublika_mariyel_ru": "Respublika Mariy-El (RU)",
        "respublika_mordoviya_ru": "Respublika Mordoviya (RU)",
        "respublika_sakha_yakutiya_ru": "Respublika Sakha (Yakutiya) (RU)",
        "respublika_tyva_ru": "Respublika Tyva (RU)",
        "rheinlandpfalz_de": "Rheinland-pfalz (DE)",
        "rhode_island_us": "Rhode Island (US)",
        "rhondda_uk": "Rhondda (UK)",
        "riau_id": "Riau (ID)",
        "riau_islands_id": "Riau Islands (ID)",
        "richmond_upon_thames_uk": "Richmond Upon Thames (UK)",
        "rieti_it": "Rieti (IT)",
        "rimini_it": "Rimini (IT)",
        "rio_de_janeiro_br": "Rio de Janeiro (BR)",
        "rio_grande_do_norte_br": "Rio Grande do Norte (BR)",
        "rio_grande_do_sul_br": "Rio Grande do Sul (BR)",
        "risaralda_co": "Risaralda (CO)",
        "rizal_ph": "Rizal (PH)",
        "rize_tr": "Rize (TR)",
        "rochdale_uk": "Rochdale (UK)",
        "rogaland_fylke_no": "Rogaland Fylke (NO)",
        "roma_it": "Roma (IT)",
        "romblon_ph": "Romblon (PH)",
        "rondonia_br": "Rondonia (BR)",
        "roraima_br": "Roraima (BR)",
        "rostovskaya_oblast_ru": "Rostovskaya Oblast (RU)",
        "rotherham_uk": "Rotherham (UK)",
        "rovigo_it": "Rovigo (IT)",
        "rutland_uk": "Rutland (UK)",
        "ryazanskaya_oblast_ru": "Ryazanskaya Oblast (RU)",
        "saarland_de": "Saarland (DE)",
        "sachsen_de": "Sachsen (DE)",
        "sachsenanhalt_de": "Sachsen-anhalt (DE)",
        "saga_prefecture_jp": "Saga Prefecture (JP)",
        "saint_gallen_ch": "Saint Gallen (CH)",
        "saitama_jp": "Saitama (JP)",
        "sakarya_tr": "Sakarya (TR)",
        "sakhalinskaya_oblast_ru": "Sakhalinskaya Oblast (RU)",
        "salamanca_es": "Salamanca (ES)",
        "salerno_it": "Salerno (IT)",
        "salford_uk": "Salford (UK)",
        "salzburg_at": "Salzburg (AT)",
        "samarskaya_oblast_ru": "Samarskaya Oblast (RU)",
        "samsun_tr": "Samsun (TR)",
        "san_andres_co": "San Andres (CO)",
        "san_luis_potosi_mx": "San Luis Potosi (MX)",
        "sandwell_uk": "Sandwell (UK)",
        "sanktpeterburg_ru": "Sankt-peterburg (RU)",
        "santa_catarina_br": "Santa Catarina (BR)",
        "santa_cruz_de_tenerife_es": "Santa Cruz De Tenerife (ES)",
        "santander_co": "Santander (CO)",
        "santiago_metropolitan_cl": "Santiago Metropolitan (CL)",
        "sao_paulo_br": "Sao Paulo (BR)",
        "sarangani_ph": "Sarangani (PH)",
        "saratovskaya_oblast_ru": "Saratovskaya Oblast (RU)",
        "saskatchewan_ca": "Saskatchewan (CA)",
        "sassari_it": "Sassari (IT)",
        "savona_it": "Savona (IT)",
        "schaffhausen_ch": "Schaffhausen (CH)",
        "schleswigholstein_de": "Schleswig-holstein (DE)",
        "schwyz_ch": "Schwyz (CH)",
        "scottish_borders_uk": "Scottish Borders (UK)",
        "sefton_uk": "Sefton (UK)",
        "segovia_es": "Segovia (ES)",
        "sergipe_br": "Sergipe (BR)",
        "severnobacki_okrug_rs": "Severnobacki Okrug (RS)",
        "severnobanatski_okrug_rs": "Severnobanatski Okrug (RS)",
        "sevilla_es": "Sevilla (ES)",
        "sheffield_uk": "Sheffield (UK)",
        "shetland_islands_uk": "Shetland Islands (UK)",
        "shiga_prefecture_jp": "Shiga Prefecture (JP)",
        "shimane_jp": "Shimane (JP)",
        "shizuoka_jp": "Shizuoka (JP)",
        "shropshire_uk": "Shropshire (UK)",
        "siena_it": "Siena (IT)",
        "siirt_tr": "Siirt (TR)",
        "sikkim_in": "Sikkim (IN)",
        "sinaloa_mx": "Sinaloa (MX)",
        "sinop_tr": "Sinop (TR)",
        "siquijor_ph": "Siquijor (PH)",
        "siracusa_it": "Siracusa (IT)",
        "sivas_tr": "Sivas (TR)",
        "slough_uk": "Slough (UK)",
        "smolenskaya_oblast_ru": "Smolenskaya Oblast (RU)",
        "sogn_og_fjordane_fylke_no": "Sogn og Fjordane Fylke (NO)",
        "solihull_uk": "Solihull (UK)",
        "solothurn_ch": "Solothurn (CH)",
        "somerset_uk": "Somerset (UK)",
        "sondrio_it": "Sondrio (IT)",
        "sonora_mx": "Sonora (MX)",
        "soria_es": "Soria (ES)",
        "sorsogon_ph": "Sorsogon (PH)",
        "south_australia_au": "South Australia (AU)",
        "south_ayrshire_uk": "South Ayrshire (UK)",
        "south_carolina_us": "South Carolina (US)",
        "south_cotabato_ph": "South Cotabato (PH)",
        "south_dakota_us": "South Dakota (US)",
        "south_denmark_dk": "South Denmark (DK)",
        "south_gloucestershire_uk": "South Gloucestershire (UK)",
        "south_kalimantan_id": "South Kalimantan (ID)",
        "south_lanarkshire_uk": "South Lanarkshire (UK)",
        "south_sulawesi_id": "South Sulawesi (ID)",
        "south_sumatra_id": "South Sumatra (ID)",
        "south_tyneside_uk": "South Tyneside (UK)",
        "southampton_uk": "Southampton (UK)",
        "southeast_sulawesi_id": "Southeast Sulawesi (ID)",
        "southendonsea_uk": "Southend-on-sea (UK)",
        "southern_leyte_ph": "Southern Leyte (PH)",
        "southwark_uk": "Southwark (UK)",
        "srednjebanatski_okrug_rs": "Srednjebanatski Okrug (RS)",
        "sremski_okrug_rs": "Sremski Okrug (RS)",
        "st_helens_uk": "St. Helens (UK)",
        "staffordshire_uk": "Staffordshire (UK)",
        "stavropolskiy_kray_ru": "Stavropol'skiy Kray (RU)",
        "steiermark_at": "Steiermark (AT)",
        "stirling_uk": "Stirling (UK)",
        "stockport_uk": "Stockport (UK)",
        "stocktonontees_uk": "Stockton-on-tees (UK)",
        "stokeontrent_uk": "Stoke-on-trent (UK)",
        "sucre_co": "Sucre (CO)",
        "sucumbios_ec": "Sucumbios (EC)",
        "suffolk_uk": "Suffolk (UK)",
        "sultan_kudarat_ph": "Sultan Kudarat (PH)",
        "sulu_ph": "Sulu (PH)",
        "sumadijski_okrug_rs": "Sumadijski Okrug (RS)",
        "sunderland_uk": "Sunderland (UK)",
        "surigao_del_norte_ph": "Surigao Del Norte (PH)",
        "surigao_del_sur_ph": "Surigao Del Sur (PH)",
        "surrey_uk": "Surrey (UK)",
        "sutton_uk": "Sutton (UK)",
        "svalbard_no": "Svalbard (NO)",
        "sverdlovskaya_oblast_ru": "Sverdlovskaya Oblast (RU)",
        "swansea_uk": "Swansea (UK)",
        "swindon_uk": "Swindon (UK)",
        "tabasco_mx": "Tabasco (MX)",
        "tabuk_sa": "Tabuk (SA)",
        "tamaulipas_mx": "Tamaulipas (MX)",
        "tambovskaya_oblast_ru": "Tambovskaya Oblast (RU)",
        "tameside_uk": "Tameside (UK)",
        "tamil_nadu_in": "Tamil Nadu (IN)",
        "taranto_it": "Taranto (IT)",
        "tarapacá_cl": "Tarapacá (CL)",
        "tarlac_ph": "Tarlac (PH)",
        "tarragona_es": "Tarragona (ES)",
        "tasmania_au": "Tasmania (AU)",
        "tatarstan_ru": "Tatarstan (RU)",
        "tawitawi_ph": "Tawi-tawi (PH)",
        "tekirdağ_tr": "Tekirdağ (TR)",
        "telangana_in": "Telangana (IN)",
        "telemark_no": "Telemark (NO)",
        "telford_and_wrekin_uk": "Telford And Wrekin (UK)",
        "tennessee_us": "Tennessee (US)",
        "teramo_it": "Teramo (IT)",
        "terni_it": "Terni (IT)",
        "teruel_es": "Teruel (ES)",
        "texas_us": "Texas (US)",
        "thurgau_ch": "Thurgau (CH)",
        "thuringen_de": "Thuringen (DE)",
        "thurrock_uk": "Thurrock (UK)",
        "ticino_ch": "Ticino (CH)",
        "tirol_at": "Tirol (AT)",
        "tlaxcala_mx": "Tlaxcala (MX)",
        "tocantins_br": "Tocantins (BR)",
        "tochigi_jp": "Tochigi (JP)",
        "tokat_tr": "Tokat (TR)",
        "tokushima_jp": "Tokushima (JP)",
        "tokyo_jp": "Tokyo (JP)",
        "toledo_es": "Toledo (ES)",
        "tolima_co": "Tolima (CO)",
        "tomskaya_oblast_ru": "Tomskaya Oblast (RU)",
        "toplicki_okrug_rs": "Toplicki Okrug (RS)",
        "torbay_uk": "Torbay (UK)",
        "torfaen_uk": "Torfaen (UK)",
        "torino_it": "Torino (IT)",
        "tottori_jp": "Tottori (JP)",
        "tower_hamlets_uk": "Tower Hamlets (UK)",
        "toyama_jp": "Toyama (JP)",
        "trabzon_tr": "Trabzon (TR)",
        "trafford_uk": "Trafford (UK)",
        "trapani_it": "Trapani (IT)",
        "trento_it": "Trento (IT)",
        "treviso_it": "Treviso (IT)",
        "trieste_it": "Trieste (IT)",
        "tripura_in": "Tripura (IN)",
        "troms_fylke_no": "Troms Fylke (NO)",
        "troms_og_finnmark_no": "Troms Og Finnmark (NO)",
        "trondelag_no": "Trondelag (NO)",
        "tulskaya_oblast_ru": "Tul'skaya Oblast (RU)",
        "tunceli_tr": "Tunceli (TR)",
        "tungurahua_ec": "Tungurahua (EC)",
        "tverskaya_oblast_ru": "Tverskaya Oblast (RU)",
        "tyumenskaya_oblast_ru": "Tyumenskaya Oblast (RU)",
        "udine_it": "Udine (IT)",
        "udmurtskaya_respublika_ru": "Udmurtskaya Respublika (RU)",
        "ulyanovskaya_oblast_ru": "Ul'yanovskaya Oblast (RU)",
        "umm_alquwain_ae": "Umm Al-Quwain (AE)",
        "uri_ch": "Uri (CH)",
        "utah_us": "Utah (US)",
        "uttar_pradesh_in": "Uttar Pradesh (IN)",
        "uttarakhand_in": "Uttarakhand (IN)",
        "uşak_tr": "Uşak (TR)",
        "valais_ch": "Valais (CH)",
        "vale_of_glamorgan_uk": "Vale Of Glamorgan (UK)",
        "valencia_es": "Valencia (ES)",
        "valladolid_es": "Valladolid (ES)",
        "valle_daosta_it": "Valle d'Aosta (IT)",
        "valle_del_cauca_co": "Valle Del Cauca (CO)",
        "van_tr": "Van (TR)",
        "varese_it": "Varese (IT)",
        "vaud_ch": "Vaud (CH)",
        "vaupes_co": "Vaupes (CO)",
        "venezia_it": "Venezia (IT)",
        "veracruz_mx": "Veracruz (MX)",
        "veraguas_pa": "Veraguas (PA)",
        "verbanocusioossola_it": "Verbano-cusio-ossola (IT)",
        "vercelli_it": "Vercelli (IT)",
        "vermont_us": "Vermont (US)",
        "verona_it": "Verona (IT)",
        "vestagder_fylke_no": "Vest-Agder Fylke (NO)",
        "vestfold_no": "Vestfold (NO)",
        "vestfold_og_telemark_no": "Vestfold Og Telemark (NO)",
        "vestland_no": "Vestland (NO)",
        "viba_valentia_it": "Viba Valentia (IT)",
        "vicenza_it": "Vicenza (IT)",
        "vichada_co": "Vichada (CO)",
        "victoria_au": "Victoria (AU)",
        "viken_no": "Viken (NO)",
        "virginia_us": "Virginia (US)",
        "viterbo_it": "Viterbo (IT)",
        "vizcaya_es": "Vizcaya (ES)",
        "vladimirskaya_oblast_ru": "Vladimirskaya Oblast (RU)",
        "volgogradskaya_oblast_ru": "Volgogradskaya Oblast (RU)",
        "vologodskaya_oblast_ru": "Vologodskaya Oblast (RU)",
        "vorarlberg_at": "Vorarlberg (AT)",
        "voronezhskaya_oblast_ru": "Voronezhskaya Oblast (RU)",
        "wakayama_jp": "Wakayama (JP)",
        "wake_island_um": "Wake Island (UM)",
        "wakefield_uk": "Wakefield (UK)",
        "walsall_uk": "Walsall (UK)",
        "waltham_forest_uk": "Waltham Forest (UK)",
        "wandsworth_uk": "Wandsworth (UK)",
        "warrington_uk": "Warrington (UK)",
        "warwickshire_uk": "Warwickshire (UK)",
        "washington_us": "Washington (US)",
        "west_bengal_in": "West Bengal (IN)",
        "west_berkshire_uk": "West Berkshire (UK)",
        "west_dunbartonshire_uk": "West Dunbartonshire (UK)",
        "west_java_id": "West Java (ID)",
        "west_kalimantan_id": "West Kalimantan (ID)",
        "west_lothian_uk": "West Lothian (UK)",
        "west_nusa_tenggara_id": "West Nusa Tenggara (ID)",
        "west_papua_id": "West Papua (ID)",
        "west_sulawesi_id": "West Sulawesi (ID)",
        "west_sumatra_id": "West Sumatra (ID)",
        "west_sussex_uk": "West Sussex (UK)",
        "west_virginia_us": "West Virginia (US)",
        "western_australia_au": "Western Australia (AU)",
        "western_samar_ph": "Western Samar (PH)",
        "westminster_uk": "Westminster (UK)",
        "wien_at": "Wien (AT)",
        "wigan_uk": "Wigan (UK)",
        "wiltshire_uk": "Wiltshire (UK)",
        "windsor_and_maidenhead_uk": "Windsor And Maidenhead (UK)",
        "wirral_uk": "Wirral (UK)",
        "wisconsin_us": "Wisconsin (US)",
        "wokingham_uk": "Wokingham (UK)",
        "wolverhampton_uk": "Wolverhampton (UK)",
        "worcestershire_uk": "Worcestershire (UK)",
        "wrexham_uk": "Wrexham (UK)",
        "wyoming_us": "Wyoming (US)",
        "yalova_tr": "Yalova (TR)",
        "yamagata_jp": "Yamagata (JP)",
        "yamaguchi_jp": "Yamaguchi (JP)",
        "yamalonenetskiy_avtonomnyy_okrug_ru": "Yamalo-nenetskiy Avtonomnyy Okrug (RU)",
        "yamanashi_jp": "Yamanashi (JP)",
        "yaroslavskaya_oblast_ru": "Yaroslavskaya Oblast (RU)",
        "yevreyskaya_avtonomnaya_oblast_ru": "Yevreyskaya Avtonomnaya Oblast (RU)",
        "yogyakarta_id": "Yogyakarta (ID)",
        "york_uk": "York (UK)",
        "yozgat_tr": "Yozgat (TR)",
        "yucatan_mx": "Yucatan (MX)",
        "yukon_ca": "Yukon (CA)",
        "zabaykalskiy_kray_ru": "Zabaykal'skiy Kray (RU)",
        "zacatecas_mx": "Zacatecas (MX)",
        "zajecarski_okrug_rs": "Zajecarski Okrug (RS)",
        "zambales_ph": "Zambales (PH)",
        "zamboanga_del_norte_ph": "Zamboanga Del Norte (PH)",
        "zamboanga_del_sur_ph": "Zamboanga Del Sur (PH)",
        "zamboanga_sibugay_ph": "Zamboanga Sibugay (PH)",
        "zamora_es": "Zamora (ES)",
        "zamorachinchipe_ec": "Zamora-Chinchipe (EC)",
        "zapadnobacki_okrug_rs": "Zapadnobacki Okrug (RS)",
        "zaragoza_es": "Zaragoza (ES)",
        "zealand_dk": "Zealand (DK)",
        "zlatiborski_okrug_rs": "Zlatiborski Okrug (RS)",
        "zonguldak_tr": "Zonguldak (TR)",
        "zug_ch": "Zug (CH)",
        "zurich_ch": "Zurich (CH)",
        "çanakkale_tr": "Çanakkale (TR)",
        "çankırı_tr": "Çankırı (TR)",
        "çorum_tr": "Çorum (TR)",
        "østfold_no": "Østfold (NO)",
        "ōsaka_jp": "Ōsaka (JP)",
        "şanlıurfa_tr": "Şanlıurfa (TR)",
        "şırnak_tr": "Şırnak (TR)"
      },
      "timezone": {
        "africa_algiers": "Africa/Algiers",
        "africa_cairo": "Africa/Cairo",
        "africa_casablanca": "Africa/Casablanca",
        "africa_johannesburg": "Africa/Johannesburg",
        "africa_nairobi": "Africa/Nairobi",
        "america_adak": "America/Adak",
        "america_anchorage": "America/Anchorage",
        "america_argentina_buenos_aires": "America/Argentina/Buenos_Aires",
        "america_bogota": "America/Bogota",
        "america_caracas": "America/Caracas",
        "america_chicago": "America/Chicago",
        "america_denver": "America/Denver",
        "america_el_salvador": "America/El_Salvador",
        "america_halifax": "America/Halifax",
        "america_indiana_indianapolis": "America/Indiana/Indianapolis",
        "america_lima": "America/Lima",
        "america_los_angeles": "America/Los_Angeles",
        "america_mazatlan": "America/Mazatlan",
        "america_mexico_city": "America/Mexico_City",
        "america_new_york": "America/New_York",
        "america_panama": "America/Panama",
        "america_phoenix": "America/Phoenix",
        "america_puerto_rico": "America/Puerto_Rico",
        "america_santiago": "America/Santiago",
        "america_sao_paulo": "America/Sao_Paulo",
        "america_scoresbysund": "America/Scoresbysund",
        "america_st_johns": "America/St_Johns",
        "america_tijuana": "America/Tijuana",
        "asia_baghdad": "Asia/Baghdad",
        "asia_baku": "Asia/Baku",
        "asia_bangkok": "Asia/Bangkok",
        "asia_beirut": "Asia/Beirut",
        "asia_colombo": "Asia/Colombo",
        "asia_dhaka": "Asia/Dhaka",
        "asia_dubai": "Asia/Dubai",
        "asia_ho_chi_minh": "Asia/Ho_Chi_Minh",
        "asia_hong_kong": "Asia/Hong_Kong",
        "asia_jakarta": "Asia/Jakarta",
        "asia_jerusalem": "Asia/Jerusalem",
        "asia_kabul": "Asia/Kabul",
        "asia_kamchatka": "Asia/Kamchatka",
        "asia_karachi": "Asia/Karachi",
        "asia_kathmandu": "Asia/Kathmandu",
        "asia_kolkata": "Asia/Kolkata",
        "asia_kuala_lumpur": "Asia/Kuala_Lumpur",
        "asia_kuwait": "Asia/Kuwait",
        "asia_manila": "Asia/Manila",
        "asia_rangoon": "Asia/Rangoon",
        "asia_riyadh": "Asia/Riyadh",
        "asia_seoul": "Asia/Seoul",
        "asia_shanghai": "Asia/Shanghai",
        "asia_singapore": "Asia/Singapore",
        "asia_taipei": "Asia/Taipei",
        "asia_tashkent": "Asia/Tashkent",
        "asia_tbilisi": "Asia/Tbilisi",
        "asia_tehran": "Asia/Tehran",
        "asia_tokyo": "Asia/Tokyo",
        "asia_yekaterinburg": "Asia/Yekaterinburg",
        "asia_yerevan": "Asia/Yerevan",
        "atlantic_azores": "Atlantic/Azores",
        "atlantic_bermuda": "Atlantic/Bermuda",
        "atlantic_cape_verde": "Atlantic/Cape_Verde",
        "atlantic_south_georgia": "Atlantic/South_Georgia",
        "australia_adelaide": "Australia/Adelaide",
        "australia_brisbane": "Australia/Brisbane",
        "australia_darwin": "Australia/Darwin",
        "australia_lord_howe": "Australia/Lord_Howe",
        "australia_perth": "Australia/Perth",
        "australia_sydney": "Australia/Sydney",
        "europe_amsterdam": "Europe/Amsterdam",
        "europe_athens": "Europe/Athens",
        "europe_berlin": "Europe/Berlin",
        "europe_brussels": "Europe/Brussels",
        "europe_bucharest": "Europe/Bucharest",
        "europe_dublin": "Europe/Dublin",
        "europe_helsinki": "Europe/Helsinki",
        "europe_istanbul": "Europe/Istanbul",
        "europe_lisbon": "Europe/Lisbon",
        "europe_london": "Europe/London",
        "europe_minsk": "Europe/Minsk",
        "europe_moscow": "Europe/Moscow",
        "europe_paris": "Europe/Paris",
        "europe_prague": "Europe/Prague",
        "europe_rome": "Europe/Rome",
        "pacific_auckland": "Pacific/Auckland",
        "pacific_chatham": "Pacific/Chatham",
        "pacific_enderbury": "Pacific/Enderbury",
        "pacific_fiji": "Pacific/Fiji",
        "pacific_gambier": "Pacific/Gambier",
        "pacific_guadalcanal": "Pacific/Guadalcanal",
        "pacific_honolulu": "Pacific/Honolulu",
        "pacific_kiritimati": "Pacific/Kiritimati",
        "pacific_marquesas": "Pacific/Marquesas",
        "pacific_niue": "Pacific/Niue",
        "pacific_norfolk": "Pacific/Norfolk",
        "pacific_pago_pago": "Pacific/Pago_Pago",
        "pacific_pitcairn": "Pacific/Pitcairn",
        "pacific_tongatapu": "Pacific/Tongatapu",
        "utc": "UTC"
      },

      "macro_affiliate_menu_footer": {
        "affiliate_id": "ID of the partner",
        "encoded_value": "Encoded value",
        "user_id": "Partner specific unique user ID associated with a user"
      },
      "macro_destination_url": {
        "advertiser_id": "ID of the advertiser",
        "advertiser_name": "Name of the advertiser",
        "affiliate_encoded_id": "Encoded ID of the partner",
        "affiliate_id": "ID of the partner",
        "affiliate_name": "Name of the partner",
        "affiliate_smartlink_encoded_id": "Encoded ID of the partner for smartlinks",
        "affiliate_source_id": "partner source ID",
        "android_id": "Unique ID for android devices",
        "android_id_md5": "MD5 hashed value of the android_id",
        "android_id_sha1": "SHA1 hashed value of the android_id",
        "app_id": "App Identifier",
        "browser_version": "Device browser version",
        "campaign_id": "ID of the campaign associated to the click",
        "coupon_code": "Coupon code associated to the offer/partner",
        "creative_id": "ID of the creative associated to the click",
        "creative_name": "Name of the creative associated to the click",
        "creative_type": "Creative type (HTML, Image or Thumbnail)",
        "datetime": "Date and time of an event formatted as YYYY-MM-DD HH:MM:SS (UTC)",
        "device_brand": "Mobile device manufacturer name (i.e. Apple)",
        "device_browser": "Device browser name (i.e. Chrome)",
        "device_language": "Device language two letters code (ISO 639-1)",
        "device_model": "Mobile device model name (i.e. iPhone6)",
        "device_os_version": "Device operating system version (i.e. 10.1)",
        "device_platform": "Device operating system platform (i.e. iOS, Android, Blackberry)",
        "device_type": "Device type (Mobiles, Tablets or PCs)",
        "geo_city_name": "City name",
        "geo_connection_type": "Connection type (Wi-Fi or Mobile)",
        "geo_country_code": "Two letters country code (ISO 3166-1 alpha-2)",
        "geo_country_name": "Country name",
        "geo_dma": "DMA",
        "geo_isp_name": "ISP name",
        "geo_mobile_carrier_name": "Mobile carrier name",
        "geo_region_code": "Two characters region code",
        "geo_region_name": "Region name",
        "google_aid": "Google advertiser ID",
        "google_aid_md5": "MD5 hashed value of the Google advertiser ID",
        "google_aid_sha1": "SHA1 hashed value of the Google advertiser ID",
        "idfa": "Apple's Identifier for Advertisers for iOS 6+",
        "idfa_md5": "MD5 hashed value of the IDFA",
        "idfa_sha1": "SHA1 hashed value of the IDFA",
        "impression_transaction_id": "Impression Transaction ID",
        "mac_address": "ID associated with the wifi or ethernet interface adapter of a device",
        "mac_address_md5": "MD5 hashed value of the mac_address",
        "mac_address_sha1": "SHA1 hashed value of the mac_address",
        "offer_currency": "Currency of an offer (i.e. USD, GBP, EUR)",
        "offer_id": "ID of an offer",
        "offer_name": "Name of an offer",
        "offer_project_id": "ID of an offer project",
        "offer_url_id": "ID of an offer URL",
        "offer_url_name": "Name of an offer URL",
        "random": "Random value from 100,000,000 to 999,999,999",
        "referer": "HTTP referer",
        "revenue_amount": "Amount of the base conversion",
        "source_id": "ID of the partner source",
        "sub1": "Sub ID 1 in the partner tracking URL",
        "sub2": "Sub ID 2 in the partner tracking URL",
        "sub3": "Sub ID 3 in the partner tracking URL",
        "sub4": "Sub ID 4 in the partner tracking URL",
        "sub5": "Sub ID 5 in the partner tracking URL",
        "timestamp": "Unix timestamp of an event (UTC)",
        "tracking_domain": "Tracking domain associated to the click",
        "transaction_id": "Unique id associated to the transaction",
        "unique_click": "Identify if click is unique (1) or not (0)",
        "user_agent": "User-agent string of the device",
        "user_id": "Partner specific unique user ID associated with a user",
        "user_ip": "User IP"
      },
      "macro_email_template": {
        "accountManagerEmail": "The email of the account's associated manager",
        "accountManagerFirstName": "The first name of the account's associated manager",
        "accountManagerLastName": "The last name of the account's associated manager",
        "accountManagerMessagingIdentifier": "The identifier of by the account's associated manager in the messaging software",
        "accountManagerMessagingSoftware": "The software used by the account's associated manager",
        "activationId": "The activation ID",
        "activationLink": "Password Activation Link",
        "advertiserId": "The ID of the associated advertiser, if available",
        "advertiserNaming": "The advertiser word, depending on your customization",
        "advertiserNamingCap": "The capitalized advertiser word, depending on your customization",
        "affiliateEncodedValue": "The encoded value of the affiliate ID used in tracking links",
        "affiliateId": "The ID of the associated affiliate, if available",
        "affiliateNaming": "The affiliate word, depending on your customization",
        "affiliateNamingCap": "The capitalized affiliate word, depending on your customization",
        "affiliateSmartlinkEncodedValue": "The encoded value of the affiliate ID used in Smartlink tracking links",
        "alertId": "Alert ID",
        "alertName": "Alert name",
        "downloadLink": "Download Link",
        "employeeFirstName": "Employee first name",
        "employeeName": "Employee name",
        "firstName": "Recipient first name",
        "lastName": "Recipient last name",
        "logoUrl": "Network logo URL",
        "message": "The default message",
        "networkName": "Network name",
        "offerAffiliateCouponCode": "Offer Affiliate Coupon Code",
        "offerCaps": "Offer Caps",
        "offerCreativeDetails": "Offer / Creative Details",
        "offerCreativeLink": "Offer Creative Link",
        "offerDescription": "Offer Description",
        "offerId": "Offer ID",
        "offerName": "Offer name",
        "offerPayout": "Offer Payout",
        "offerPreviewUrl": "Offer Preview URL",
        "offerRequirementKpis": "Offer Requirement KPIs",
        "offerRequirementTrackingParameters": "Offer Requirement Tracking Parameters",
        "offerTargeting": "Offer Targeting",
        "offers": "Concatenation of the offer names and IDs in html format",
        "recipientEmail": "Recipient email address",
        "salutation": "Default salutation generated by the notification system (First name or Hello)",
        "trackingLink": "Offer Tracking link (unique for each affiliate)",
        "tutorialLink": "Tutorial link",
        "websiteUrl": "Website URL"
      },
      "macro_html": {
        "affiliate_id": "ID of the partner",
        "affiliate_name": "Name of the partner",
        "coupon_code": "Coupon code of an offer (If available)",
        "impression_tracking_link": "Contextual impression tracking URL for the partner / offer combination",
        "offer_currency": "Currency of an offer (i.e. USD, GBP, EUR)",
        "offer_id": "ID of an offer",
        "offer_name": "Name of an offer",
        "offer_project_id": "ID of an offer project",
        "random": "A random value (Cache buster)",
        "suppression_list_link": "Suppression List URL associated to the offer",
        "tracking_link": "Contextual tracking URL for the partner / offer combination",
        "tracking_link_encoded": "Contextual tracking URL (encoded) for the partner / offer combination",
        "unsub_link": "Unsub URL associated to the offer or third-party system (Optizmo / Ezepo)"
      },
      "macro_messaging": {
        "account_manager_email": "Account Manager email",
        "account_manager_first_name": "Account Manager first name",
        "account_manager_im_identifier": "Account Manager instant messaging identifier",
        "account_manager_im_software": "Account Manager instant messaging software",
        "account_manager_last_name": "Account Manager last name",
        "activation_id": "The activation ID",
        "advertiser_id": "Advertiser ID",
        "affiliate_id": "Partner ID",
        "affiliate_referral_url": "Partner referral URL",
        "company": "Network/Partner/Advertiser name",
        "email": "Recipient email address",
        "first_name": "Recipient first name",
        "last_name": "Recipient last name",
        "offer_content": "Display offer content",
        "unsub_link": "Unsubscribe link",
        "website_url": "Website URL"
      },
      "macro_offer_messaging": {
        "offer_affiliate_coupon_code": "Offer Affiliate Coupon Code",
        "offer_caps": "Offer Caps",
        "offer_creative_link": "Offer Creative Link",
        "offer_description": "Offer Description",
        "offer_id": "Offer ID",
        "offer_name": "Offer name",
        "offer_payout": "Offer Payout",
        "offer_preview_url": "Offer Preview URL",
        "offer_requirement_kpis": "Offer Requirement KPIs",
        "offer_requirement_tracking_parameters": "Offer Requirement Tracking Parameters",
        "offer_targeting": "Offer Targeting",
        "offer_tracking_link": "Tracking Link"
      },
      "macro_pixel": {
        "adv1": "Adv ID 1 sent by the advertiser in the conversion postback URL",
        "adv2": "Adv ID 2 sent by the advertiser in the conversion postback URL",
        "adv3": "Adv ID 3 sent by the advertiser in the conversion postback URL",
        "adv4": "Adv ID 4 sent by the advertiser in the conversion postback URL",
        "adv5": "Adv ID 5 sent by the advertiser in the conversion postback URL",
        "affiliate_id": "ID of the partner",
        "affiliate_name": "Name of the partner",
        "affiliate_source_id": "ID of the partner source",
        "android_id": "Unique ID for android devices",
        "android_id_md5": "MD5 hashed value of the android_id",
        "android_id_sha1": "SHA1 hashed value of the android_id",
        "app_id": "App Identifier (publisher)",
        "browser_version": "Device browser version",
        "campaign_id": "ID of the campaign associated to the click",
        "conversion_id": "Unique id associated to the conversion",
        "conversion_ip": "IP address of the user (conversion)",
        "coupon_code": "Coupon code associated to the e-commerce sale",
        "creative_name": "Name associated to the creative",
        "creative_type": "Creative type (HTML, Image or Thumbnail)",
        "datetime": "Date and time of an event formatted as YYYY-MM-DD HH:MM:SS (UTC)",
        "device_brand": "Mobile device manufacturer name (i.e. Apple)",
        "device_browser": "Device browser name (i.e. Chrome)",
        "device_language": "Device language two letters code (ISO 639-1)",
        "device_model": "Mobile device model name (i.e. iPhone6)",
        "device_os_version": "Device operating system version (i.e. 10.1)",
        "device_platform": "Device operating system platform (i.e. iOS, Android, Blackberry)",
        "device_type": "Device type (Mobiles, Tablets or PCs)",
        "event_id": "ID associated to the additional event (0 if base event)",
        "event_name": "Name associated to the additional event (empty if base event)",
        "geo_city_name": "City name",
        "geo_connection_type": "Connection type (Wi-Fi or Mobile)",
        "geo_country_code": "Two letters country code (ISO 3166-1 alpha-2)",
        "geo_country_name": "Country name",
        "geo_isp_name": "ISP name",
        "geo_mobile_carrier_name": "Mobile carrier name",
        "geo_region_code": "Two characters region code",
        "geo_region_name": "Region name",
        "google_aid": "Google advertiser ID",
        "google_aid_md5": "MD5 hashed value of the Google advertiser ID",
        "google_aid_sha1": "SHA1 hashed value of the Google advertiser ID",
        "idfa": "Apple's Identifier for Advertisers for iOS 6+",
        "idfa_md5": "MD5 hashed value of the IDFA",
        "idfa_sha1": "SHA1 hashed value of the IDFA",
        "is_view_through": "Identify if a conversion was generated from an impression",
        "mac_address": "ID associated with the wifi or ethernet interface adapter of a device",
        "mac_address_md5": "MD5 hashed value of the mac_address",
        "mac_address_sha1": "SHA1 hashed value of the mac_address",
        "offer_currency": "Currency of the offer (i.e. USD, GBP, EUR)",
        "offer_encoded_value": "Encoded value of an offer",
        "offer_id": "ID of an offer",
        "offer_name": "Name of an offer",
        "offer_url_id": "ID of an offer URL",
        "order_id": "ID of an order",
        "origin": "Origin of the conversion, either click or impression",
        "payout_amount": "Amount paid out to the partner for an event (conversion or post conversion)",
        "random": "Random value from 100,000,000 to 999,999,999",
        "referer": "HTTP referer",
        "sale_amount": "Sale amount associated to the e-commerce order",
        "session_ip": "IP address of the user (click)",
        "source_id": "ID of the partner source",
        "sub1": "Sub ID 1 in the partner tracking URL",
        "sub2": "Sub ID 2 in the partner tracking URL",
        "sub3": "Sub ID 3 in the partner tracking URL",
        "sub4": "Sub ID 4 in the partner tracking URL",
        "sub5": "Sub ID 5 in the partner tracking URL",
        "timestamp": "Unix timestamp of an event (UTC)",
        "tracking_domain": "Tracking domain associated to the click",
        "transaction_id": "Unique id associated to the transaction",
        "user_agent": "User-agent string of the device",
        "user_id": "Partner specific unique user ID associated with a user",
        "user_ip": "IP address of the user (click)"
      }

    },
    timezones: [
      {
        "label_translate_key": "pacific_kiritimati",
        "offset": "UTC+14:00",
        "timezone": "Pacific/Kiritimati",
        "timezone_id": 1
      },
      {
        "label_translate_key": "pacific_enderbury",
        "offset": "UTC+13:00",
        "timezone": "Pacific/Enderbury",
        "timezone_id": 2
      },
      {
        "label_translate_key": "pacific_tongatapu",
        "offset": "UTC+13:00",
        "timezone": "Pacific/Tongatapu",
        "timezone_id": 3
      },
      {
        "label_translate_key": "pacific_chatham",
        "offset": "UTC+12:45",
        "timezone": "Pacific/Chatham",
        "timezone_id": 4
      },
      {
        "label_translate_key": "pacific_auckland",
        "offset": "UTC+12:00",
        "timezone": "Pacific/Auckland",
        "timezone_id": 5
      },
      {
        "label_translate_key": "pacific_fiji",
        "offset": "UTC+12:00",
        "timezone": "Pacific/Fiji",
        "timezone_id": 6
      },
      {
        "label_translate_key": "asia_kamchatka",
        "offset": "UTC+12:00",
        "timezone": "Asia/Kamchatka",
        "timezone_id": 7
      },
      {
        "label_translate_key": "pacific_norfolk",
        "offset": "UTC+11:30",
        "timezone": "Pacific/Norfolk",
        "timezone_id": 8
      },
      {
        "label_translate_key": "australia_lord_howe",
        "offset": "UTC+11:00",
        "timezone": "Australia/Lord_Howe",
        "timezone_id": 9
      },
      {
        "label_translate_key": "pacific_guadalcanal",
        "offset": "UTC+11:00",
        "timezone": "Pacific/Guadalcanal",
        "timezone_id": 10
      },
      {
        "label_translate_key": "australia_adelaide",
        "offset": "UTC+10:30",
        "timezone": "Australia/Adelaide",
        "timezone_id": 11
      },
      {
        "label_translate_key": "australia_sydney",
        "offset": "UTC+10:00",
        "timezone": "Australia/Sydney",
        "timezone_id": 12
      },
      {
        "label_translate_key": "australia_brisbane",
        "offset": "UTC+10:00",
        "timezone": "Australia/Brisbane",
        "timezone_id": 13
      },
      {
        "label_translate_key": "australia_darwin",
        "offset": "UTC+09:30",
        "timezone": "Australia/Darwin",
        "timezone_id": 14
      },
      {
        "label_translate_key": "asia_seoul",
        "offset": "UTC+09:00",
        "timezone": "Asia/Seoul",
        "timezone_id": 15
      },
      {
        "label_translate_key": "asia_tokyo",
        "offset": "UTC+09:00",
        "timezone": "Asia/Tokyo",
        "timezone_id": 16
      },
      {
        "label_translate_key": "asia_hong_kong",
        "offset": "UTC+08:00",
        "timezone": "Asia/Hong_Kong",
        "timezone_id": 17
      },
      {
        "label_translate_key": "asia_kuala_lumpur",
        "offset": "UTC+08:00",
        "timezone": "Asia/Kuala_Lumpur",
        "timezone_id": 18
      },
      {
        "label_translate_key": "asia_manila",
        "offset": "UTC+08:00",
        "timezone": "Asia/Manila",
        "timezone_id": 19
      },
      {
        "label_translate_key": "asia_shanghai",
        "offset": "UTC+08:00",
        "timezone": "Asia/Shanghai",
        "timezone_id": 20
      },
      {
        "label_translate_key": "asia_singapore",
        "offset": "UTC+08:00",
        "timezone": "Asia/Singapore",
        "timezone_id": 21
      },
      {
        "label_translate_key": "asia_taipei",
        "offset": "UTC+08:00",
        "timezone": "Asia/Taipei",
        "timezone_id": 22
      },
      {
        "label_translate_key": "australia_perth",
        "offset": "UTC+08:00",
        "timezone": "Australia/Perth",
        "timezone_id": 23
      },
      {
        "label_translate_key": "asia_bangkok",
        "offset": "UTC+07:00",
        "timezone": "Asia/Bangkok",
        "timezone_id": 24
      },
      {
        "label_translate_key": "asia_ho_chi_minh",
        "offset": "UTC+07:00",
        "timezone": "Asia/Ho_Chi_Minh",
        "timezone_id": 25
      },
      {
        "label_translate_key": "asia_jakarta",
        "offset": "UTC+07:00",
        "timezone": "Asia/Jakarta",
        "timezone_id": 26
      },
      {
        "label_translate_key": "asia_rangoon",
        "offset": "UTC+06:30",
        "timezone": "Asia/Rangoon",
        "timezone_id": 27
      },
      {
        "label_translate_key": "asia_dhaka",
        "offset": "UTC+06:00",
        "timezone": "Asia/Dhaka",
        "timezone_id": 28
      },
      {
        "label_translate_key": "asia_kathmandu",
        "offset": "UTC+05:45",
        "timezone": "Asia/Kathmandu",
        "timezone_id": 29
      },
      {
        "label_translate_key": "asia_colombo",
        "offset": "UTC+05:30",
        "timezone": "Asia/Colombo",
        "timezone_id": 30
      },
      {
        "label_translate_key": "asia_kolkata",
        "offset": "UTC+05:30",
        "timezone": "Asia/Kolkata",
        "timezone_id": 31
      },
      {
        "label_translate_key": "asia_karachi",
        "offset": "UTC+05:00",
        "timezone": "Asia/Karachi",
        "timezone_id": 32
      },
      {
        "label_translate_key": "asia_tashkent",
        "offset": "UTC+05:00",
        "timezone": "Asia/Tashkent",
        "timezone_id": 33
      },
      {
        "label_translate_key": "asia_yekaterinburg",
        "offset": "UTC+05:00",
        "timezone": "Asia/Yekaterinburg",
        "timezone_id": 34
      },
      {
        "label_translate_key": "asia_kabul",
        "offset": "UTC+04:30",
        "timezone": "Asia/Kabul",
        "timezone_id": 35
      },
      {
        "label_translate_key": "asia_baku",
        "offset": "UTC+04:00",
        "timezone": "Asia/Baku",
        "timezone_id": 36
      },
      {
        "label_translate_key": "asia_dubai",
        "offset": "UTC+04:00",
        "timezone": "Asia/Dubai",
        "timezone_id": 37
      },
      {
        "label_translate_key": "asia_tbilisi",
        "offset": "UTC+04:00",
        "timezone": "Asia/Tbilisi",
        "timezone_id": 38
      },
      {
        "label_translate_key": "asia_yerevan",
        "offset": "UTC+04:00",
        "timezone": "Asia/Yerevan",
        "timezone_id": 39
      },
      {
        "label_translate_key": "asia_tehran",
        "offset": "UTC+03:30",
        "timezone": "Asia/Tehran",
        "timezone_id": 40
      },
      {
        "label_translate_key": "africa_nairobi",
        "offset": "UTC+03:00",
        "timezone": "Africa/Nairobi",
        "timezone_id": 41
      },
      {
        "label_translate_key": "asia_baghdad",
        "offset": "UTC+03:00",
        "timezone": "Asia/Baghdad",
        "timezone_id": 42
      },
      {
        "label_translate_key": "asia_kuwait",
        "offset": "UTC+03:00",
        "timezone": "Asia/Kuwait",
        "timezone_id": 43
      },
      {
        "label_translate_key": "asia_riyadh",
        "offset": "UTC+03:00",
        "timezone": "Asia/Riyadh",
        "timezone_id": 44
      },
      {
        "label_translate_key": "europe_minsk",
        "offset": "UTC+03:00",
        "timezone": "Europe/Minsk",
        "timezone_id": 45
      },
      {
        "label_translate_key": "europe_moscow",
        "offset": "UTC+03:00",
        "timezone": "Europe/Moscow",
        "timezone_id": 46
      },
      {
        "label_translate_key": "africa_cairo",
        "offset": "UTC+03:00",
        "timezone": "Africa/Cairo",
        "timezone_id": 47
      },
      {
        "label_translate_key": "asia_beirut",
        "offset": "UTC+03:00",
        "timezone": "Asia/Beirut",
        "timezone_id": 48
      },
      {
        "label_translate_key": "asia_jerusalem",
        "offset": "UTC+03:00",
        "timezone": "Asia/Jerusalem",
        "timezone_id": 49
      },
      {
        "label_translate_key": "europe_athens",
        "offset": "UTC+03:00",
        "timezone": "Europe/Athens",
        "timezone_id": 50
      },
      {
        "label_translate_key": "europe_bucharest",
        "offset": "UTC+03:00",
        "timezone": "Europe/Bucharest",
        "timezone_id": 51
      },
      {
        "label_translate_key": "europe_helsinki",
        "offset": "UTC+03:00",
        "timezone": "Europe/Helsinki",
        "timezone_id": 52
      },
      {
        "label_translate_key": "europe_istanbul",
        "offset": "UTC+03:00",
        "timezone": "Europe/Istanbul",
        "timezone_id": 53
      },
      {
        "label_translate_key": "africa_johannesburg",
        "offset": "UTC+02:00",
        "timezone": "Africa/Johannesburg",
        "timezone_id": 54
      },
      {
        "label_translate_key": "europe_amsterdam",
        "offset": "UTC+02:00",
        "timezone": "Europe/Amsterdam",
        "timezone_id": 55
      },
      {
        "label_translate_key": "europe_berlin",
        "offset": "UTC+02:00",
        "timezone": "Europe/Berlin",
        "timezone_id": 56
      },
      {
        "label_translate_key": "europe_brussels",
        "offset": "UTC+02:00",
        "timezone": "Europe/Brussels",
        "timezone_id": 57
      },
      {
        "label_translate_key": "europe_paris",
        "offset": "UTC+02:00",
        "timezone": "Europe/Paris",
        "timezone_id": 58
      },
      {
        "label_translate_key": "europe_prague",
        "offset": "UTC+02:00",
        "timezone": "Europe/Prague",
        "timezone_id": 59
      },
      {
        "label_translate_key": "europe_rome",
        "offset": "UTC+02:00",
        "timezone": "Europe/Rome",
        "timezone_id": 60
      },
      {
        "label_translate_key": "europe_lisbon",
        "offset": "UTC+01:00",
        "timezone": "Europe/Lisbon",
        "timezone_id": 61
      },
      {
        "label_translate_key": "africa_algiers",
        "offset": "UTC+01:00",
        "timezone": "Africa/Algiers",
        "timezone_id": 62
      },
      {
        "label_translate_key": "europe_london",
        "offset": "UTC+01:00",
        "timezone": "Europe/London",
        "timezone_id": 63
      },
      {
        "label_translate_key": "atlantic_cape_verde",
        "offset": "UTC-01:00",
        "timezone": "Atlantic/Cape_Verde",
        "timezone_id": 64
      },
      {
        "label_translate_key": "africa_casablanca",
        "offset": "UTC+00:00",
        "timezone": "Africa/Casablanca",
        "timezone_id": 65
      },
      {
        "label_translate_key": "europe_dublin",
        "offset": "UTC+00:00",
        "timezone": "Europe/Dublin",
        "timezone_id": 66
      },
      {
        "label_translate_key": "utc",
        "offset": "UTC+00:00",
        "timezone": "UTC",
        "timezone_id": 67
      },
      {
        "label_translate_key": "america_scoresbysund",
        "offset": "UTC-00:00",
        "timezone": "America/Scoresbysund",
        "timezone_id": 68
      },
      {
        "label_translate_key": "atlantic_azores",
        "offset": "UTC-00:00",
        "timezone": "Atlantic/Azores",
        "timezone_id": 69
      },
      {
        "label_translate_key": "atlantic_south_georgia",
        "offset": "UTC-02:00",
        "timezone": "Atlantic/South_Georgia",
        "timezone_id": 70
      },
      {
        "label_translate_key": "america_st_johns",
        "offset": "UTC-02:30",
        "timezone": "America/St_Johns",
        "timezone_id": 71
      },
      {
        "label_translate_key": "america_sao_paulo",
        "offset": "UTC-03:00",
        "timezone": "America/Sao_Paulo",
        "timezone_id": 72
      },
      {
        "label_translate_key": "america_argentina_buenos_aires",
        "offset": "UTC-03:00",
        "timezone": "America/Argentina/Buenos_Aires",
        "timezone_id": 73
      },
      {
        "label_translate_key": "america_santiago",
        "offset": "UTC-03:00",
        "timezone": "America/Santiago",
        "timezone_id": 74
      },
      {
        "label_translate_key": "america_halifax",
        "offset": "UTC-03:00",
        "timezone": "America/Halifax",
        "timezone_id": 75
      },
      {
        "label_translate_key": "america_puerto_rico",
        "offset": "UTC-04:00",
        "timezone": "America/Puerto_Rico",
        "timezone_id": 76
      },
      {
        "label_translate_key": "atlantic_bermuda",
        "offset": "UTC-04:00",
        "timezone": "Atlantic/Bermuda",
        "timezone_id": 77
      },
      {
        "label_translate_key": "america_caracas",
        "offset": "UTC-04:30",
        "timezone": "America/Caracas",
        "timezone_id": 78
      },
      {
        "label_translate_key": "america_indiana_indianapolis",
        "offset": "UTC-04:00",
        "timezone": "America/Indiana/Indianapolis",
        "timezone_id": 79
      },
      {
        "label_translate_key": "america_new_york",
        "offset": "UTC-04:00",
        "timezone": "America/New_York",
        "timezone_id": 80
      },
      {
        "label_translate_key": "america_bogota",
        "offset": "UTC-05:00",
        "timezone": "America/Bogota",
        "timezone_id": 81
      },
      {
        "label_translate_key": "america_lima",
        "offset": "UTC-05:00",
        "timezone": "America/Lima",
        "timezone_id": 82
      },
      {
        "label_translate_key": "america_panama",
        "offset": "UTC-05:00",
        "timezone": "America/Panama",
        "timezone_id": 83
      },
      {
        "label_translate_key": "america_mexico_city",
        "offset": "UTC-05:00",
        "timezone": "America/Mexico_City",
        "timezone_id": 84
      },
      {
        "label_translate_key": "america_chicago",
        "offset": "UTC-05:00",
        "timezone": "America/Chicago",
        "timezone_id": 85
      },
      {
        "label_translate_key": "america_el_salvador",
        "offset": "UTC-06:00",
        "timezone": "America/El_Salvador",
        "timezone_id": 86
      },
      {
        "label_translate_key": "america_denver",
        "offset": "UTC-06:00",
        "timezone": "America/Denver",
        "timezone_id": 87
      },
      {
        "label_translate_key": "america_mazatlan",
        "offset": "UTC-06:00",
        "timezone": "America/Mazatlan",
        "timezone_id": 88
      },
      {
        "label_translate_key": "america_phoenix",
        "offset": "UTC-07:00",
        "timezone": "America/Phoenix",
        "timezone_id": 89
      },
      {
        "label_translate_key": "america_los_angeles",
        "offset": "UTC-07:00",
        "timezone": "America/Los_Angeles",
        "timezone_id": 90
      },
      {
        "label_translate_key": "america_tijuana",
        "offset": "UTC-07:00",
        "timezone": "America/Tijuana",
        "timezone_id": 91
      },
      {
        "label_translate_key": "pacific_pitcairn",
        "offset": "UTC-08:00",
        "timezone": "Pacific/Pitcairn",
        "timezone_id": 92
      },
      {
        "label_translate_key": "america_anchorage",
        "offset": "UTC-08:00",
        "timezone": "America/Anchorage",
        "timezone_id": 93
      },
      {
        "label_translate_key": "pacific_gambier",
        "offset": "UTC-09:00",
        "timezone": "Pacific/Gambier",
        "timezone_id": 94
      },
      {
        "label_translate_key": "america_adak",
        "offset": "UTC-09:00",
        "timezone": "America/Adak",
        "timezone_id": 95
      },
      {
        "label_translate_key": "pacific_marquesas",
        "offset": "UTC-09:30",
        "timezone": "Pacific/Marquesas",
        "timezone_id": 96
      },
      {
        "label_translate_key": "pacific_honolulu",
        "offset": "UTC-10:00",
        "timezone": "Pacific/Honolulu",
        "timezone_id": 97
      },
      {
        "label_translate_key": "pacific_niue",
        "offset": "UTC-11:00",
        "timezone": "Pacific/Niue",
        "timezone_id": 98
      },
      {
        "label_translate_key": "pacific_pago_pago",
        "offset": "UTC-11:00",
        "timezone": "Pacific/Pago_Pago",
        "timezone_id": 99
      }
    ],
    currencies: [
      {
        "currency_id": "AED",
        "label_translate_key": "united_arab_emirates_dirham",
        "symbol": "د.إ"
      },
      {
        "currency_id": "AFN",
        "label_translate_key": "afghan_afghani",
        "symbol": "؋"
      },
      {
        "currency_id": "ALL",
        "label_translate_key": "albanian_lek",
        "symbol": "Lek"
      },
      {
        "currency_id": "ANG",
        "label_translate_key": "netherlands_antillean_guilder",
        "symbol": "ƒ"
      },
      {
        "currency_id": "ARS",
        "label_translate_key": "argentine_peso",
        "symbol": "$"
      },
      {
        "currency_id": "AUD",
        "label_translate_key": "australian_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "AWG",
        "label_translate_key": "aruban_florin",
        "symbol": "ƒ"
      },
      {
        "currency_id": "AZN",
        "label_translate_key": "azerbaijani_manat",
        "symbol": "ман"
      },
      {
        "currency_id": "BAM",
        "label_translate_key": "bosniaherzegovina_convertible_mark",
        "symbol": "KM"
      },
      {
        "currency_id": "BBD",
        "label_translate_key": "barbadian_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "BGN",
        "label_translate_key": "bulgarian_lev",
        "symbol": "лв"
      },
      {
        "currency_id": "BHD",
        "label_translate_key": "bahraini_dinar",
        "symbol": ".د.ب"
      },
      {
        "currency_id": "BMD",
        "label_translate_key": "bermudan_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "BND",
        "label_translate_key": "brunei_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "BOB",
        "label_translate_key": "bolivian_boliviano",
        "symbol": "$b"
      },
      {
        "currency_id": "BRL",
        "label_translate_key": "brazilian_real",
        "symbol": "R$"
      },
      {
        "currency_id": "BSD",
        "label_translate_key": "bahamian_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "BWP",
        "label_translate_key": "botswanan_pula",
        "symbol": "P"
      },
      {
        "currency_id": "BYN",
        "label_translate_key": "belarusian_ruble",
        "symbol": "p."
      },
      {
        "currency_id": "BZD",
        "label_translate_key": "belize_dollar",
        "symbol": "BZ$"
      },
      {
        "currency_id": "CAD",
        "label_translate_key": "canadian_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "CHF",
        "label_translate_key": "swiss_franc",
        "symbol": "CHF"
      },
      {
        "currency_id": "CLP",
        "label_translate_key": "chilean_peso",
        "symbol": "$"
      },
      {
        "currency_id": "CNY",
        "label_translate_key": "chinese_yuan",
        "symbol": "¥"
      },
      {
        "currency_id": "COP",
        "label_translate_key": "colombian_peso",
        "symbol": "$"
      },
      {
        "currency_id": "CRC",
        "label_translate_key": "costa_rican_colon",
        "symbol": "₡"
      },
      {
        "currency_id": "CUP",
        "label_translate_key": "cuban_peso",
        "symbol": "₱"
      },
      {
        "currency_id": "CZK",
        "label_translate_key": "czech_republic_koruna",
        "symbol": "Kč"
      },
      {
        "currency_id": "DKK",
        "label_translate_key": "danish_krone",
        "symbol": "kr"
      },
      {
        "currency_id": "DOP",
        "label_translate_key": "dominican_peso",
        "symbol": "RD$"
      },
      {
        "currency_id": "EGP",
        "label_translate_key": "egyptian_pound",
        "symbol": "£"
      },
      {
        "currency_id": "EUR",
        "label_translate_key": "euro",
        "symbol": "€"
      },
      {
        "currency_id": "FJD",
        "label_translate_key": "fijian_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "FKP",
        "label_translate_key": "falkland_islands_pound",
        "symbol": "£"
      },
      {
        "currency_id": "GBP",
        "label_translate_key": "british_pound_sterling",
        "symbol": "£"
      },
      {
        "currency_id": "GIP",
        "label_translate_key": "gibraltar_pound",
        "symbol": "£"
      },
      {
        "currency_id": "GTQ",
        "label_translate_key": "guatemalan_quetzal",
        "symbol": "Q"
      },
      {
        "currency_id": "GYD",
        "label_translate_key": "guyanaese_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "HKD",
        "label_translate_key": "hong_kong_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "HNL",
        "label_translate_key": "honduran_lempira",
        "symbol": "L"
      },
      {
        "currency_id": "HRK",
        "label_translate_key": "croatian_kuna",
        "symbol": "kn"
      },
      {
        "currency_id": "HUF",
        "label_translate_key": "hungarian_forint",
        "symbol": "Ft"
      },
      {
        "currency_id": "IDR",
        "label_translate_key": "indonesian_rupiah",
        "symbol": "Rp"
      },
      {
        "currency_id": "ILS",
        "label_translate_key": "israeli_new_sheqel",
        "symbol": "₪"
      },
      {
        "currency_id": "INR",
        "label_translate_key": "indian_rupee",
        "symbol": "Rp"
      },
      {
        "currency_id": "IRR",
        "label_translate_key": "iranian_rial",
        "symbol": "﷼"
      },
      {
        "currency_id": "ISK",
        "label_translate_key": "icelandic_krona",
        "symbol": "kr"
      },
      {
        "currency_id": "JMD",
        "label_translate_key": "jamaican_dollar",
        "symbol": "J$"
      },
      {
        "currency_id": "JPY",
        "label_translate_key": "japanese_yen",
        "symbol": "¥"
      },
      {
        "currency_id": "KGS",
        "label_translate_key": "kyrgystani_som",
        "symbol": "лв"
      },
      {
        "currency_id": "KHR",
        "label_translate_key": "cambodian_riel",
        "symbol": "៛"
      },
      {
        "currency_id": "KPW",
        "label_translate_key": "north_korean_won",
        "symbol": "₩"
      },
      {
        "currency_id": "KRW",
        "label_translate_key": "south_korean_won",
        "symbol": "₩"
      },
      {
        "currency_id": "KWD",
        "label_translate_key": "kuwaiti_dinar",
        "symbol": "د.ك"
      },
      {
        "currency_id": "KYD",
        "label_translate_key": "cayman_islands_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "KZT",
        "label_translate_key": "kazakhstani_tenge",
        "symbol": "лв"
      },
      {
        "currency_id": "LAK",
        "label_translate_key": "laotian_kip",
        "symbol": "₭"
      },
      {
        "currency_id": "LBP",
        "label_translate_key": "lebanese_pound",
        "symbol": "£"
      },
      {
        "currency_id": "LKR",
        "label_translate_key": "sri_lankan_rupee",
        "symbol": "₨"
      },
      {
        "currency_id": "LRD",
        "label_translate_key": "liberian_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "MKD",
        "label_translate_key": "macedonian_denar",
        "symbol": "ден"
      },
      {
        "currency_id": "MNT",
        "label_translate_key": "mongolian_tugrik",
        "symbol": "₮"
      },
      {
        "currency_id": "MUR",
        "label_translate_key": "mauritian_rupee",
        "symbol": "₨"
      },
      {
        "currency_id": "MXN",
        "label_translate_key": "mexican_peso",
        "symbol": "$"
      },
      {
        "currency_id": "MYR",
        "label_translate_key": "malaysian_ringgit",
        "symbol": "RM"
      },
      {
        "currency_id": "MZN",
        "label_translate_key": "mozambican_metical",
        "symbol": "MT"
      },
      {
        "currency_id": "NAD",
        "label_translate_key": "namibian_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "NGN",
        "label_translate_key": "nigerian_naira",
        "symbol": "₦"
      },
      {
        "currency_id": "NIO",
        "label_translate_key": "nicaraguan_cordoba",
        "symbol": "C$"
      },
      {
        "currency_id": "NOK",
        "label_translate_key": "norwegian_krone",
        "symbol": "kr"
      },
      {
        "currency_id": "NPR",
        "label_translate_key": "nepalese_rupee",
        "symbol": "₨"
      },
      {
        "currency_id": "NZD",
        "label_translate_key": "new_zealand_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "OMR",
        "label_translate_key": "omani_rial",
        "symbol": "﷼"
      },
      {
        "currency_id": "PAB",
        "label_translate_key": "panamanian_balboa",
        "symbol": "B/."
      },
      {
        "currency_id": "PEN",
        "label_translate_key": "peruvian_nuevo_sol",
        "symbol": "S/."
      },
      {
        "currency_id": "PHP",
        "label_translate_key": "philippine_peso",
        "symbol": "Php"
      },
      {
        "currency_id": "PKR",
        "label_translate_key": "pakistani_rupee",
        "symbol": "₨"
      },
      {
        "currency_id": "PLN",
        "label_translate_key": "polish_zloty",
        "symbol": "zł"
      },
      {
        "currency_id": "PYG",
        "label_translate_key": "paraguayan_guarani",
        "symbol": "Gs"
      },
      {
        "currency_id": "QAR",
        "label_translate_key": "qatari_rial",
        "symbol": "﷼"
      },
      {
        "currency_id": "RON",
        "label_translate_key": "romanian_leu",
        "symbol": "lei"
      },
      {
        "currency_id": "RSD",
        "label_translate_key": "serbian_dinar",
        "symbol": "Дин."
      },
      {
        "currency_id": "RUB",
        "label_translate_key": "russian_ruble",
        "symbol": "руб"
      },
      {
        "currency_id": "SAR",
        "label_translate_key": "saudi_riyal",
        "symbol": "﷼"
      },
      {
        "currency_id": "SBD",
        "label_translate_key": "solomon_islands_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "SCR",
        "label_translate_key": "seychellois_rupee",
        "symbol": "₨"
      },
      {
        "currency_id": "SEK",
        "label_translate_key": "swedish_krona",
        "symbol": "kr"
      },
      {
        "currency_id": "SGD",
        "label_translate_key": "singapore_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "SHP",
        "label_translate_key": "st_helena_pound",
        "symbol": "£"
      },
      {
        "currency_id": "SOS",
        "label_translate_key": "somali_shilling",
        "symbol": "S"
      },
      {
        "currency_id": "SRD",
        "label_translate_key": "surinamese_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "SVC",
        "label_translate_key": "salvadoran_colón",
        "symbol": "$"
      },
      {
        "currency_id": "SYP",
        "label_translate_key": "syrian_pound",
        "symbol": "£"
      },
      {
        "currency_id": "THB",
        "label_translate_key": "thai_baht",
        "symbol": "฿"
      },
      {
        "currency_id": "TRY",
        "label_translate_key": "turkish_lira",
        "symbol": "TL"
      },
      {
        "currency_id": "TTD",
        "label_translate_key": "trinidad_tobago_dollar",
        "symbol": "TT$"
      },
      {
        "currency_id": "TWD",
        "label_translate_key": "new_taiwan_dollar",
        "symbol": "NT$"
      },
      {
        "currency_id": "UAH",
        "label_translate_key": "ukrainian_hryvnia",
        "symbol": "₴"
      },
      {
        "currency_id": "USD",
        "label_translate_key": "us_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "UYU",
        "label_translate_key": "uruguayan_peso",
        "symbol": "$U"
      },
      {
        "currency_id": "UZS",
        "label_translate_key": "uzbekistan_som",
        "symbol": "лв"
      },
      {
        "currency_id": "VEF",
        "label_translate_key": "venezuelan_bolívar",
        "symbol": "Bs"
      },
      {
        "currency_id": "VND",
        "label_translate_key": "vietnamese_dong",
        "symbol": "₫"
      },
      {
        "currency_id": "XCD",
        "label_translate_key": "east_caribbean_dollar",
        "symbol": "$"
      },
      {
        "currency_id": "YER",
        "label_translate_key": "yemeni_rial",
        "symbol": "﷼"
      },
      {
        "currency_id": "ZAR",
        "label_translate_key": "south_african_rand",
        "symbol": "R"
      }
    ],
    countries: [
      {
        "id": 1,
        "label": "Andorra"
      },
      {
        "id": 2,
        "label": "United Arab Emirates"
      },
      {
        "id": 3,
        "label": "Afghanistan"
      },
      {
        "id": 4,
        "label": "Antigua and Barbuda"
      },
      {
        "id": 5,
        "label": "Anguilla"
      },
      {
        "id": 6,
        "label": "Albania"
      },
      {
        "id": 7,
        "label": "Armenia"
      },
      {
        "id": 8,
        "label": "Angola"
      },
      {
        "id": 9,
        "label": "Antarctica"
      },
      {
        "id": 10,
        "label": "Argentina"
      },
      {
        "id": 11,
        "label": "American Samoa"
      },
      {
        "id": 12,
        "label": "Austria"
      },
      {
        "id": 13,
        "label": "Australia"
      },
      {
        "id": 14,
        "label": "Aruba"
      },
      {
        "id": 15,
        "label": "Åland Islands"
      },
      {
        "id": 16,
        "label": "Azerbaijan"
      },
      {
        "id": 17,
        "label": "Bosnia and Herzegovina"
      },
      {
        "id": 18,
        "label": "Barbados"
      },
      {
        "id": 19,
        "label": "Bangladesh"
      },
      {
        "id": 20,
        "label": "Belgium"
      },
      {
        "id": 21,
        "label": "Burkina Faso"
      },
      {
        "id": 22,
        "label": "Bulgaria"
      },
      {
        "id": 23,
        "label": "Bahrain"
      },
      {
        "id": 24,
        "label": "Burundi"
      },
      {
        "id": 25,
        "label": "Benin"
      },
      {
        "id": 26,
        "label": "Saint Barthélemy"
      },
      {
        "id": 27,
        "label": "Bermuda"
      },
      {
        "id": 28,
        "label": "Brunei"
      },
      {
        "id": 29,
        "label": "Bolivia"
      },
      {
        "id": 30,
        "label": "Brazil"
      },
      {
        "id": 31,
        "label": "Bahamas"
      },
      {
        "id": 32,
        "label": "Bhutan"
      },
      {
        "id": 33,
        "label": "Botswana"
      },
      {
        "id": 34,
        "label": "Belarus"
      },
      {
        "id": 35,
        "label": "Belize"
      },
      {
        "id": 36,
        "label": "Canada"
      },
      {
        "id": 37,
        "label": "Cocos [Keeling] Islands"
      },
      {
        "id": 38,
        "label": "Congo"
      },
      {
        "id": 39,
        "label": "Central African Republic"
      },
      {
        "id": 40,
        "label": "Republic of the Congo"
      },
      {
        "id": 41,
        "label": "Switzerland"
      },
      {
        "id": 42,
        "label": "Ivory Coast"
      },
      {
        "id": 43,
        "label": "Cook Islands"
      },
      {
        "id": 44,
        "label": "Chile"
      },
      {
        "id": 45,
        "label": "Cameroon"
      },
      {
        "id": 46,
        "label": "China"
      },
      {
        "id": 47,
        "label": "Colombia"
      },
      {
        "id": 48,
        "label": "Costa Rica"
      },
      {
        "id": 49,
        "label": "Cuba"
      },
      {
        "id": 50,
        "label": "Cape Verde"
      },
      {
        "id": 51,
        "label": "Christmas Island"
      },
      {
        "id": 52,
        "label": "Cyprus"
      },
      {
        "id": 53,
        "label": "Czech Republic"
      },
      {
        "id": 54,
        "label": "Germany"
      },
      {
        "id": 55,
        "label": "Djibouti"
      },
      {
        "id": 56,
        "label": "Denmark"
      },
      {
        "id": 57,
        "label": "Dominica"
      },
      {
        "id": 58,
        "label": "Dominican Republic"
      },
      {
        "id": 59,
        "label": "Algeria"
      },
      {
        "id": 60,
        "label": "Ecuador"
      },
      {
        "id": 61,
        "label": "Estonia"
      },
      {
        "id": 62,
        "label": "Egypt"
      },
      {
        "id": 63,
        "label": "Eritrea"
      },
      {
        "id": 64,
        "label": "Spain"
      },
      {
        "id": 65,
        "label": "Ethiopia"
      },
      {
        "id": 66,
        "label": "Finland"
      },
      {
        "id": 67,
        "label": "Fiji"
      },
      {
        "id": 68,
        "label": "Falkland Islands"
      },
      {
        "id": 69,
        "label": "Federated States of Micronesia"
      },
      {
        "id": 70,
        "label": "Faroe Islands"
      },
      {
        "id": 71,
        "label": "France"
      },
      {
        "id": 72,
        "label": "Gabon"
      },
      {
        "id": 73,
        "label": "United Kingdom"
      },
      {
        "id": 74,
        "label": "Grenada"
      },
      {
        "id": 75,
        "label": "Georgia"
      },
      {
        "id": 76,
        "label": "French Guiana"
      },
      {
        "id": 77,
        "label": "Guernsey"
      },
      {
        "id": 78,
        "label": "Ghana"
      },
      {
        "id": 79,
        "label": "Gibraltar"
      },
      {
        "id": 80,
        "label": "Greenland"
      },
      {
        "id": 81,
        "label": "Gambia"
      },
      {
        "id": 82,
        "label": "Guinea"
      },
      {
        "id": 83,
        "label": "Guadeloupe"
      },
      {
        "id": 84,
        "label": "Equatorial Guinea"
      },
      {
        "id": 85,
        "label": "Greece"
      },
      {
        "id": 86,
        "label": "South Georgia and the South Sandwich Islands"
      },
      {
        "id": 87,
        "label": "Guatemala"
      },
      {
        "id": 88,
        "label": "Guam"
      },
      {
        "id": 89,
        "label": "Guinea-Bissau"
      },
      {
        "id": 90,
        "label": "Guyana"
      },
      {
        "id": 91,
        "label": "Hong Kong"
      },
      {
        "id": 92,
        "label": "Honduras"
      },
      {
        "id": 93,
        "label": "Croatia"
      },
      {
        "id": 94,
        "label": "Haiti"
      },
      {
        "id": 95,
        "label": "Hungary"
      },
      {
        "id": 96,
        "label": "Indonesia"
      },
      {
        "id": 97,
        "label": "Ireland"
      },
      {
        "id": 98,
        "label": "Israel"
      },
      {
        "id": 99,
        "label": "Isle of Man"
      },
      {
        "id": 100,
        "label": "India"
      },
      {
        "id": 101,
        "label": "British Indian Ocean Territory"
      },
      {
        "id": 102,
        "label": "Iraq"
      },
      {
        "id": 103,
        "label": "Iran"
      },
      {
        "id": 104,
        "label": "Iceland"
      },
      {
        "id": 105,
        "label": "Italy"
      },
      {
        "id": 106,
        "label": "Jersey"
      },
      {
        "id": 107,
        "label": "Jamaica"
      },
      {
        "id": 108,
        "label": "Hashemite Kingdom of Jordan"
      },
      {
        "id": 109,
        "label": "Japan"
      },
      {
        "id": 110,
        "label": "Kenya"
      },
      {
        "id": 111,
        "label": "Kyrgyzstan"
      },
      {
        "id": 112,
        "label": "Cambodia"
      },
      {
        "id": 113,
        "label": "Kiribati"
      },
      {
        "id": 114,
        "label": "Comoros"
      },
      {
        "id": 115,
        "label": "Saint Kitts and Nevis"
      },
      {
        "id": 116,
        "label": "North Korea"
      },
      {
        "id": 117,
        "label": "Republic of Korea"
      },
      {
        "id": 118,
        "label": "Kuwait"
      },
      {
        "id": 119,
        "label": "Cayman Islands"
      },
      {
        "id": 120,
        "label": "Kazakhstan"
      },
      {
        "id": 121,
        "label": "Laos"
      },
      {
        "id": 122,
        "label": "Lebanon"
      },
      {
        "id": 123,
        "label": "Saint Lucia"
      },
      {
        "id": 124,
        "label": "Liechtenstein"
      },
      {
        "id": 125,
        "label": "Sri Lanka"
      },
      {
        "id": 126,
        "label": "Liberia"
      },
      {
        "id": 127,
        "label": "Lesotho"
      },
      {
        "id": 128,
        "label": "Republic of Lithuania"
      },
      {
        "id": 129,
        "label": "Luxembourg"
      },
      {
        "id": 130,
        "label": "Latvia"
      },
      {
        "id": 131,
        "label": "Libya"
      },
      {
        "id": 132,
        "label": "Morocco"
      },
      {
        "id": 133,
        "label": "Monaco"
      },
      {
        "id": 134,
        "label": "Republic of Moldova"
      },
      {
        "id": 135,
        "label": "Montenegro"
      },
      {
        "id": 136,
        "label": "Saint Martin"
      },
      {
        "id": 137,
        "label": "Madagascar"
      },
      {
        "id": 138,
        "label": "Marshall Islands"
      },
      {
        "id": 139,
        "label": "Macedonia"
      },
      {
        "id": 140,
        "label": "Mali"
      },
      {
        "id": 141,
        "label": "Myanmar [Burma]"
      },
      {
        "id": 142,
        "label": "Mongolia"
      },
      {
        "id": 143,
        "label": "Macao"
      },
      {
        "id": 144,
        "label": "Northern Mariana Islands"
      },
      {
        "id": 145,
        "label": "Martinique"
      },
      {
        "id": 146,
        "label": "Mauritania"
      },
      {
        "id": 147,
        "label": "Montserrat"
      },
      {
        "id": 148,
        "label": "Malta"
      },
      {
        "id": 149,
        "label": "Mauritius"
      },
      {
        "id": 150,
        "label": "Maldives"
      },
      {
        "id": 151,
        "label": "Malawi"
      },
      {
        "id": 152,
        "label": "Mexico"
      },
      {
        "id": 153,
        "label": "Malaysia"
      },
      {
        "id": 154,
        "label": "Mozambique"
      },
      {
        "id": 155,
        "label": "Namibia"
      },
      {
        "id": 156,
        "label": "New Caledonia"
      },
      {
        "id": 157,
        "label": "Niger"
      },
      {
        "id": 158,
        "label": "Norfolk Island"
      },
      {
        "id": 159,
        "label": "Nigeria"
      },
      {
        "id": 160,
        "label": "Nicaragua"
      },
      {
        "id": 161,
        "label": "Netherlands"
      },
      {
        "id": 162,
        "label": "Norway"
      },
      {
        "id": 163,
        "label": "Nepal"
      },
      {
        "id": 164,
        "label": "Nauru"
      },
      {
        "id": 165,
        "label": "Niue"
      },
      {
        "id": 166,
        "label": "New Zealand"
      },
      {
        "id": 167,
        "label": "Oman"
      },
      {
        "id": 168,
        "label": "Panama"
      },
      {
        "id": 169,
        "label": "Peru"
      },
      {
        "id": 170,
        "label": "French Polynesia"
      },
      {
        "id": 171,
        "label": "Papua New Guinea"
      },
      {
        "id": 172,
        "label": "Philippines"
      },
      {
        "id": 173,
        "label": "Pakistan"
      },
      {
        "id": 174,
        "label": "Poland"
      },
      {
        "id": 175,
        "label": "Saint Pierre and Miquelon"
      },
      {
        "id": 176,
        "label": "Pitcairn Islands"
      },
      {
        "id": 177,
        "label": "Puerto Rico"
      },
      {
        "id": 178,
        "label": "Palestine"
      },
      {
        "id": 179,
        "label": "Portugal"
      },
      {
        "id": 180,
        "label": "Palau"
      },
      {
        "id": 181,
        "label": "Paraguay"
      },
      {
        "id": 182,
        "label": "Qatar"
      },
      {
        "id": 183,
        "label": "Réunion"
      },
      {
        "id": 184,
        "label": "Romania"
      },
      {
        "id": 185,
        "label": "Serbia"
      },
      {
        "id": 186,
        "label": "Russia"
      },
      {
        "id": 187,
        "label": "Rwanda"
      },
      {
        "id": 188,
        "label": "Saudi Arabia"
      },
      {
        "id": 189,
        "label": "Solomon Islands"
      },
      {
        "id": 190,
        "label": "Seychelles"
      },
      {
        "id": 191,
        "label": "Sudan"
      },
      {
        "id": 192,
        "label": "Sweden"
      },
      {
        "id": 193,
        "label": "Singapore"
      },
      {
        "id": 194,
        "label": "Saint Helena"
      },
      {
        "id": 195,
        "label": "Slovenia"
      },
      {
        "id": 196,
        "label": "Svalbard and Jan Mayen"
      },
      {
        "id": 197,
        "label": "Slovak Republic"
      },
      {
        "id": 198,
        "label": "Sierra Leone"
      },
      {
        "id": 199,
        "label": "San Marino"
      },
      {
        "id": 200,
        "label": "Senegal"
      },
      {
        "id": 201,
        "label": "Somalia"
      },
      {
        "id": 202,
        "label": "Suriname"
      },
      {
        "id": 203,
        "label": "South Sudan"
      },
      {
        "id": 204,
        "label": "Sao Tome and Principe"
      },
      {
        "id": 205,
        "label": "El Salvador"
      },
      {
        "id": 206,
        "label": "Syria"
      },
      {
        "id": 207,
        "label": "Swaziland"
      },
      {
        "id": 208,
        "label": "Turks and Caicos Islands"
      },
      {
        "id": 209,
        "label": "Chad"
      },
      {
        "id": 210,
        "label": "French Southern Territories"
      },
      {
        "id": 211,
        "label": "Togo"
      },
      {
        "id": 212,
        "label": "Thailand"
      },
      {
        "id": 213,
        "label": "Tajikistan"
      },
      {
        "id": 214,
        "label": "Tokelau"
      },
      {
        "id": 215,
        "label": "East Timor"
      },
      {
        "id": 216,
        "label": "Turkmenistan"
      },
      {
        "id": 217,
        "label": "Tunisia"
      },
      {
        "id": 218,
        "label": "Tonga"
      },
      {
        "id": 219,
        "label": "Turkey"
      },
      {
        "id": 220,
        "label": "Trinidad and Tobago"
      },
      {
        "id": 221,
        "label": "Tuvalu"
      },
      {
        "id": 222,
        "label": "Taiwan"
      },
      {
        "id": 223,
        "label": "Tanzania"
      },
      {
        "id": 224,
        "label": "Ukraine"
      },
      {
        "id": 225,
        "label": "Uganda"
      },
      {
        "id": 226,
        "label": "U.S. Minor Outlying Islands"
      },
      {
        "id": 227,
        "label": "United States"
      },
      {
        "id": 228,
        "label": "Uruguay"
      },
      {
        "id": 229,
        "label": "Uzbekistan"
      },
      {
        "id": 230,
        "label": "Vatican City"
      },
      {
        "id": 231,
        "label": "Saint Vincent and the Grenadines"
      },
      {
        "id": 232,
        "label": "Venezuela"
      },
      {
        "id": 233,
        "label": "British Virgin Islands"
      },
      {
        "id": 234,
        "label": "U.S. Virgin Islands"
      },
      {
        "id": 235,
        "label": "Vietnam"
      },
      {
        "id": 236,
        "label": "Vanuatu"
      },
      {
        "id": 237,
        "label": "Wallis and Futuna"
      },
      {
        "id": 238,
        "label": "Samoa"
      },
      {
        "id": 239,
        "label": "Yemen"
      },
      {
        "id": 240,
        "label": "Mayotte"
      },
      {
        "id": 241,
        "label": "South Africa"
      },
      {
        "id": 242,
        "label": "Zambia"
      },
      {
        "id": 243,
        "label": "Zimbabwe"
      },
      {
        "id": 244,
        "label": "Palestinian Territories"
      },
      {
        "id": 245,
        "label": "Netherlands Antilles"
      },
      {
        "id": 246,
        "label": "Curacao"
      },
      {
        "id": 247,
        "label": "Western Sahara"
      }
    ],
    postbackParams: [
      {
        "description_translate_key": "sub1",
        "name": "sub1"
      },
      {
        "description_translate_key": "sub2",
        "name": "sub2"
      },
      {
        "description_translate_key": "sub3",
        "name": "sub3"
      },
      {
        "description_translate_key": "sub4",
        "name": "sub4"
      },
      {
        "description_translate_key": "sub5",
        "name": "sub5"
      },
      {
        "description_translate_key": "adv1",
        "name": "adv1"
      },
      {
        "description_translate_key": "adv2",
        "name": "adv2"
      },
      {
        "description_translate_key": "adv3",
        "name": "adv3"
      },
      {
        "description_translate_key": "adv4",
        "name": "adv4"
      },
      {
        "description_translate_key": "adv5",
        "name": "adv5"
      },
      {
        "description_translate_key": "affiliate_id",
        "name": "affiliate_id"
      },
      {
        "description_translate_key": "affiliate_name",
        "name": "affiliate_name"
      },
      {
        "description_translate_key": "affiliate_source_id",
        "name": "affiliate_source_id"
      },
      {
        "description_translate_key": "android_id",
        "name": "android_id"
      },
      {
        "description_translate_key": "android_id_md5",
        "name": "android_id_md5"
      },
      {
        "description_translate_key": "android_id_sha1",
        "name": "android_id_sha1"
      },
      {
        "description_translate_key": "app_id",
        "name": "app_id"
      },
      {
        "description_translate_key": "browser_version",
        "name": "browser_version"
      },
      {
        "description_translate_key": "campaign_id",
        "name": "campaign_id"
      },
      {
        "description_translate_key": "conversion_id",
        "name": "conversion_id"
      },
      {
        "description_translate_key": "conversion_ip",
        "name": "conversion_ip"
      },
      {
        "description_translate_key": "coupon_code",
        "name": "coupon_code"
      },
      {
        "description_translate_key": "creative_name",
        "name": "creative_name"
      },
      {
        "description_translate_key": "creative_type",
        "name": "creative_type"
      },
      {
        "description_translate_key": "datetime",
        "name": "datetime"
      },
      {
        "description_translate_key": "device_brand",
        "name": "device_brand"
      },
      {
        "description_translate_key": "device_browser",
        "name": "device_browser"
      },
      {
        "description_translate_key": "device_language",
        "name": "device_language"
      },
      {
        "description_translate_key": "device_model",
        "name": "device_model"
      },
      {
        "description_translate_key": "device_os_version",
        "name": "device_os_version"
      },
      {
        "description_translate_key": "device_platform",
        "name": "device_platform"
      },
      {
        "description_translate_key": "device_type",
        "name": "device_type"
      },
      {
        "description_translate_key": "event_id",
        "name": "event_id"
      },
      {
        "description_translate_key": "event_name",
        "name": "event_name"
      },
      {
        "description_translate_key": "geo_city_name",
        "name": "geo_city_name"
      },
      {
        "description_translate_key": "geo_connection_type",
        "name": "geo_connection_type"
      },
      {
        "description_translate_key": "geo_country_code",
        "name": "geo_country_code"
      },
      {
        "description_translate_key": "geo_country_name",
        "name": "geo_country_name"
      },
      {
        "description_translate_key": "geo_isp_name",
        "name": "geo_isp_name"
      },
      {
        "description_translate_key": "offer_currency",
        "name": "offer_currency"
      },
      {
        "description_translate_key": "geo_mobile_carrier_name",
        "name": "geo_mobile_carrier_name"
      },
      {
        "description_translate_key": "geo_region_code",
        "name": "geo_region_code"
      },
      {
        "description_translate_key": "geo_region_name",
        "name": "geo_region_name"
      },
      {
        "description_translate_key": "google_aid",
        "name": "google_aid"
      },
      {
        "description_translate_key": "google_aid_md5",
        "name": "google_aid_md5"
      },
      {
        "description_translate_key": "google_aid_sha1",
        "name": "google_aid_sha1"
      },
      {
        "description_translate_key": "idfa",
        "name": "idfa"
      },
      {
        "description_translate_key": "idfa_md5",
        "name": "idfa_md5"
      },
      {
        "description_translate_key": "idfa_sha1",
        "name": "idfa_sha1"
      },
      {
        "description_translate_key": "is_view_through",
        "name": "is_view_through"
      },
      {
        "description_translate_key": "mac_address",
        "name": "mac_address"
      },
      {
        "description_translate_key": "mac_address_md5",
        "name": "mac_address_md5"
      },
      {
        "description_translate_key": "mac_address_sha1",
        "name": "mac_address_sha1"
      },
      {
        "description_translate_key": "offer_encoded_value",
        "name": "offer_encoded_value"
      },
      {
        "description_translate_key": "offer_id",
        "name": "offer_id"
      },
      {
        "description_translate_key": "offer_name",
        "name": "offer_name"
      },
      {
        "description_translate_key": "offer_url_id",
        "name": "offer_url_id"
      },
      {
        "description_translate_key": "order_id",
        "name": "order_id"
      },
      {
        "description_translate_key": "origin",
        "name": "origin"
      },
      {
        "description_translate_key": "payout_amount",
        "name": "payout_amount"
      },
      {
        "description_translate_key": "random",
        "name": "random"
      },
      {
        "description_translate_key": "referer",
        "name": "referer"
      },
      {
        "description_translate_key": "sale_amount",
        "name": "sale_amount"
      },
      {
        "description_translate_key": "session_ip",
        "name": "session_ip"
      },
      {
        "description_translate_key": "source_id",
        "name": "source_id"
      },
      {
        "description_translate_key": "timestamp",
        "name": "timestamp"
      },
      {
        "description_translate_key": "tracking_domain",
        "name": "tracking_domain"
      },
      {
        "description_translate_key": "transaction_id",
        "name": "transaction_id"
      },
      {
        "description_translate_key": "user_agent",
        "name": "user_agent"
      },
      {
        "description_translate_key": "user_id",
        "name": "user_id"
      },
      {
        "description_translate_key": "user_ip",
        "name": "user_ip"
      }
    ],
    messengers: [
      {
        id: 0,
        label: 'None'
      },
      {
        id: 1,
        label: 'Skype'
      },
      {
        id: 3,
        label: 'Telegram'
      },
      {
        id: 2,
        label: 'Yahoo Messenger'
      },
    ]
  }),
  mutations: {
    SET_META: (s, meta) => {
      s.meta = meta
      console.log('meta=', s.meta)
    },
    RESET_META: (s) => {
      s.meta = null
    }
  },
  actions: {
    async setMeta({commit}) {
      const meta = await api.meta.getMeta()
      commit('SET_META', meta)
    },
  },
  getters: {
    timezoneById: (state) => (id) => {
      return state.timezones.find(tz => tz.timezone_id === id)
    },
    timezoneLabelById: (state, getters) => (id) => {
      return getters.timezoneList.find(tz => tz.timezone_id === id).label
    },

    currencyLabelById: (state, getters) => (id) => {
      return getters.translatedCurrencies.find(tz => tz.currency_id === id).label
    },

    translatedCurrencies: s => s.currencies.map(c => ({
      ...c,
      id: c.currency_id,
      label: s.translations.currency[c.label_translate_key]
    })),
    timezoneList: s => s.timezones.map(t => ({...t, id: t.timezone_id, label: `(${t.offset}) ${t.timezone}`})),
    postbackParamsList: s => s.postbackParams.map(p => ({
        value: p.name,
        slug: '{' + p.name + '}',
        label: s.translations.macro_pixel[p.description_translate_key]
    })),

  }
}
