import api from "@/js/api";
import { requestStatus } from '@/js/utils/constants';
import moment from 'moment';

export default {
  namespaced: true,
  state: () => ({
    invoiceId: null,
    details: {},
    fetchingStatus: requestStatus.IDLE,
  }),
  getters: {
    getDetails: (state) => {
      return state.details.details || [];
    },
    getPayments: (state) => {
      return state.details.payments || [];
    },
  },
  mutations: {
    SET_INVOICE_ID: (state, invoiceId) => {
      state.invoiceId = invoiceId;
    },
    SET_DATA: (state, payload) => {
      const { time_created, time_saved, relationship } = payload.everflow;
      const { id, start_date, end_date, status, notes } = payload.local;

      state.details = {
        id,
        start_date: moment(new Date(start_date)).format('yyyy-MM-DD'),
        end_date: moment(new Date(end_date)).format('yyyy-MM-DD'),
        time_created: moment(time_created * 1000).format('yyyy-MM-DD HH:mm:ss') + 'UTC',
        time_saved: moment(time_saved * 1000).format('yyyy-MM-DD HH:mm:ss') + 'UTC',
        status,
        notes,
        details: relationship?.details || [],
        payments: relationship?.payments || [],
      };
    },
  },
  actions: {
    async getInvoiceDetails({ state, commit }) {
      state.fetchingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.payments.getInvoiceDetails(state.invoiceId);
        commit('SET_DATA', data);
        state.fetchingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.fetchingStatus = requestStatus.FAIL;
        throw e;
      }
    },
  },
};
