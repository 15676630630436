const requestStatus = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail',
};

const toasted = {
  text: {
    success: 'Data was successfully updated',
    error: 'Something went wrong!',
    warning: '',
    info: '',
  }
};

const offerAffiliateStatus = {
  all: 'All',
  approved: 'Approved',
  pending: 'Pending',
  public: 'Public',
  rejected: 'Rejected',
  require_approval: 'Require Approval',
};

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const daysOfMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ,11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

export {
  requestStatus,
  toasted,
  offerAffiliateStatus,
  daysOfWeek,
  daysOfMonth,
};