import api from '/src/js/api/index';
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: {
    dashboardSummary: null,
    chartData: null,
    chartDataStatus: requestStatus.IDLE,
    typeFilter: [
      {
        value: 'revenue',
        title: 'Revenue',
      }
    ],
    dateFilter: {
      value: 'today_yesterday',
      title: 'Yesterday vs Today',
    },
    timezone_id: 67,
    chartType: 'line',
  },
  mutations: {
    SET_SUMMARY: (s, payload) => {
      s.dashboardSummary = payload;
    },
    SET_CHART_DATA: (s, payload) => {
      s.chartData = payload;
    },
    SET_TYPE_FILTER: (s, payload) => {
      s.typeFilter = payload;
    },
    SET_DATE_FILTER: (s, payload) => {
      s.dateFilter = payload;
    },
    RESET_CHART_DATA: (s) => {
      s.chartData = null;
      s.chartDataStatus = requestStatus.IDLE;
      s.typeFilter = [{ value: 'revenue', title: 'Revenue' }];
      s.dateFilter = { value: 'today_yesterday', title: 'Yesterday vs Today' };
      s.chartType = 'line';
    },
    RESET_DATA: (s) => {
      s.chartData = null;
    }
  },
  actions: {
    async getSummary({ commit }) {
      try {
        const res = await api.dashboard.getSummary();
        commit('SET_SUMMARY', res.data);
      } catch (e) {
        console.log('err=>', e);
      }
    },
    async getChartData({ state, commit }) {
      const params = {
        range: state.dateFilter.value,
        timezone_id: state.timezone_id
      };

      state.chartDataStatus = requestStatus.PENDING;
      state.chartData = null;

      try {
        const res = await api.dashboard.getChart(params);
        commit('SET_CHART_DATA', res.data);
        state.chartDataStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('err=>', e);
        state.chartDataStatus = requestStatus.FAIL;
      }
    },
    setTypeFilter({ commit }, value) {
      commit('SET_TYPE_FILTER', value);
    },
    setDateFilter({ commit, dispatch }, value) {
      commit('SET_DATE_FILTER', value);
      dispatch('getChartData');
    },
  },
};