const defaultColumnsForOffers = [
  {
    id: 0,
    slug: 'network_offer_id',
    name: 'Offer ID',
    sort: true,
    orderBy: 'def'
  },
  // {id: 1, slug: '', name: 'Thumbnail', sort: false, orderBy: 'def'},
  {
    id: 2,
    slug: 'name',
    name: 'Offer Name',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 3,
    slug: 'category',
    name: 'Category',
    sort: true,
    orderBy: 'def'
  },
  // {id: 4, slug: 'channels', name: 'Channels', sort: false, orderBy: 'def'},
  // {id: 5, slug: 'relationship', name: 'Platforms', sort: false, orderBy: 'def'},
  {
    id: 6,
    slug: 'relationship',
    name: 'Countries',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 7,
    slug: 'payout',
    name: 'Payout',
    sort: false,
    orderBy: 'def'
  },
  // {id: 8, slug: 'payout_type', name: 'Payout Type', sort: false, orderBy: 'def'},
  {
    id: 9,
    slug: 'today_revenue',
    name: 'Today’s revenue',
    sort: false,
    orderBy: 'def'
  },
  // {id: 10, slug: 'weekly_click_cap', name: 'Daily Revenue Cap', sort: false, orderBy: 'def'},
  // {id: 11, slug: 'weekly_conversion_cap', name: 'Daily Click Cap', sort: false, orderBy: 'def'},
  {
    id: 12,
    slug: 'weekly_payout_cap',
    name: 'Daily CV Cap',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 13,
    slug: 'relationship',
    name: 'Visibility',
    sort: false,
    orderBy: 'def'
  },
];

const defaultColumnsForOffer = [
  {
    id: 0,
    slug: 'name',
    name: 'Offer Name',
    sort: true,
    orderBy: 'asc'
  },
  {
    id: 1,
    slug: 'offer_id',
    name: 'Offer ID',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 2,
    slug: 'gross_click',
    name: 'Gross Clicks',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 3,
    slug: 'total_click',
    name: 'Clicks',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 4,
    slug: 'unique_click',
    name: 'Unique Clicks',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 5,
    slug: 'cv',
    name: 'CV',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 6,
    slug: 'cvr',
    name: 'CVR',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 7,
    slug: 'epc',
    name: 'EPC {currencySign}',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 8,
    slug: 'cpa',
    name: 'Cpa {currencySign}',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 9,
    slug: 'payout',
    name: 'Payout {currencySign}',
    sort: true,
    orderBy: 'def'
  },
];

const defaultColumnsForDaily = [
  {
    id: 0,
    slug: 'date',
    name: 'Date',
    sort: true,
    orderBy: 'asc'
  },
  {
    id: 1,
    slug: 'total_clicks',
    name: 'Gross Clicks',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 2,
    slug: 'clicks',
    name: 'Clicks',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 3,
    slug: 'unique_clicks',
    name: 'Unique Clicks',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 4,
    slug: 'cv',
    name: 'CV',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 5,
    slug: 'cvr',
    name: 'CVR',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 6,
    slug: 'epc',
    name: 'EPC {currencySign}',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 7,
    slug: 'cpa',
    name: 'Cpa {currencySign}',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 8,
    slug: 'payout',
    name: 'Payout {currencySign}',
    sort: true,
    orderBy: 'def'
  },
];

const defaultColumnsForConversion = [
  {
    id: 0,
    slug: 'status',
    name: 'Status',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 1,
    slug: 'date',
    name: 'Date',
    sort: true,
    orderBy: 'asc'
  },
  {
    id: 2,
    slug: 'click_date',
    name: 'Click Date',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 3,
    slug: 'offer_name',
    name: 'Offer Name',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 4,
    slug: 'payout',
    name: 'Payout {currencySign}',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 5,
    slug: 'conversion_ip',
    name: 'Conversion IP',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 6,
    slug: 'session_ip',
    name: 'Session IP',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 7,
    slug: 'transaction_id',
    name: 'Transaction ID',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 8,
    slug: 'country',
    name: 'Country',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 9,
    slug: 'http_user_agent',
    name: 'HTTP user agent',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 10,
    slug: 'type',
    name: 'Type',
    sort: false,
    orderBy: 'def'
  },
  // {
  //   id: 11,
  //   slug: 'postback_clickid',
  //   name: 'Postback clickid',
  //   sort: false,
  //   orderBy: 'def'
  // },
  // {
  //   id: 12,
  //   slug: 'cv',
  //   name: 'CV',
  //   sort: false,
  //   orderBy: 'def'
  // },
  {
    id: 13,
    slug: 'source_id',
    name: 'Source ID',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 14,
    slug: 'subid_1',
    name: 'SubID 1',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 15,
    slug: 'subid_2',
    name: 'SubID 2',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 16,
    slug: 'subid_3',
    name: 'SubID 3',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 17,
    slug: 'subid_4',
    name: 'SubID 4',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 18,
    slug: 'subid_5',
    name: 'SubID 5',
    sort: false,
    orderBy: 'def'
  },
];

const defaultColumnsForClick = [
  {
    id: 0,
    slug: 'click_date',
    name: 'Click Date',
    sort: true,
    orderBy: 'asc'
  },
  {
    id: 1,
    slug: 'offer_name',
    name: 'Offer Name',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 2,
    slug: 'payout',
    name: 'Payout {currencySign}',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 3,
    slug: 'transaction_id',
    name: 'Transaction ID',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 4,
    slug: 'country',
    name: 'Country',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 5,
    slug: 'postback_click_id',
    name: 'Postback clickID',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 6,
    slug: 'source_id',
    name: 'Source ID',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 7,
    slug: 'subid_1',
    name: 'SubID 1',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 8,
    slug: 'subid_2',
    name: 'SubID 2',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 9,
    slug: 'subid_3',
    name: 'SubID 3',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 10,
    slug: 'subid_4',
    name: 'SubID 4',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 11,
    slug: 'subid_5',
    name: 'SubID 5',
    sort: true,
    orderBy: 'def'
  },
];

const defaultColumnsForMethods = [
  {
    id: 0,
    slug: 'id',
    name: 'ID',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 1,
    slug: 'name',
    name: 'Method name',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 2,
    slug: 'method_name',
    name: 'Payment method',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 3,
    slug: 'is_default',
    name: 'Default',
    sort: false,
    orderBy: 'def'
  },
];

const defaultMethodsForPayments = [
  {
    id: 0,
    slug: 'id',
    name: 'ID',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 1,
    slug: 'created_at',
    name: 'Creation date',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 2,
    slug: 'start_date',
    name: 'Start date',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 3,
    slug: 'end_date',
    name: 'End date',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 4,
    slug: 'payment_method_name',
    name: 'Payment method',
    sort: false,
    orderBy: 'def'
  },
  {
    id: 5,
    slug: 'status',
    name: 'Status',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 6,
    slug: 'billed',
    name: 'Billed',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 7,
    slug: 'paid',
    name: 'Paid',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 8,
    slug: 'balance',
    name: 'Balance',
    sort: true,
    orderBy: 'def'
  },
  {
    id: 9,
    slug: 'type',
    name: 'Invoice type',
    sort: false,
    orderBy: 'def'
  },
];

export {
  defaultColumnsForOffers,
  defaultColumnsForOffer,
  defaultColumnsForDaily,
  defaultColumnsForConversion,
  defaultColumnsForClick,
  defaultColumnsForMethods,
  defaultMethodsForPayments,
};