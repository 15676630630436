import { accessBackend } from "@/js/api/fetchData";
import routes from "@/js/api/api-routes";

export default {
  getSettings() {
    return accessBackend.get(routes.user.appSessionSettings);
  },
  updateSettings(formData) {
    return accessBackend.put(routes.user.appSessionSettings, formData);
  },
};