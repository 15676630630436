import api from '@/js/api';
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: {
    offers: null,
    categories: null,
    pagination: null,
    fetchingOffersStatus: requestStatus.IDLE,
    updatingApplicationQuestionnaireStatus: requestStatus.IDLE,
    requireApproval: null
  },
  mutations: {
    SET_OFFERS: (s, data) => {
      s.offers = data.offers;
    },
    SET_CATEGORIES: (s, data) => {
      s.categories = data.map(item => ({
        id: item.network_category_id,
        label: item.name,
      }));
    },
    SET_REQUIRE_APPROVAL: (s, data) => {
      s.requireApproval = data;
    },
  },
  actions: {
    async getOffers({ state, commit }, data) {
      state.fetchingOffersStatus = requestStatus.PENDING;

      try {
        const res = await api.offers.getOffers(data);
        commit('SET_OFFERS', res.data);
        state.fetchingOffersStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('err=>', e);
        state.fetchingOffersStatus = requestStatus.FAIL;
      }
    },
    async getCategoryList({ commit }) {
      try {
        const res = await api.offers.getCategoryList();
        commit('SET_CATEGORIES', res.data.categories);
      } catch (e) {
        console.log('err=>', e);
      }
    },
    async getApplicationQuestionnaire({ state, commit }, offerId) {
      state.updatingApplicationQuestionnaireStatus = requestStatus.PENDING;

      try {
        const { data } = await api.offers.getApplicationQuestionnaire(offerId);
        commit('SET_REQUIRE_APPROVAL', data);
        commit('offerDetails/SET_MAIN_DATA', data, { root: true });
        state.updatingApplicationQuestionnaireStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('err=>', e);
        state.updatingApplicationQuestionnaireStatus = requestStatus.FAIL;
      }
    },

  }
};
