import routes from '@/js/api/api-routes'
import {accessBackend} from "@/js/api/fetchData";

export default {

  async getOffers(data) {
    return await accessBackend.post(routes.reports.offer, data.query, {
      params: data.params
    })
  },
  async exportOffers(params) {
    return await accessBackend.post(routes.reports.offer + '/export', params)
  },
  async getDailies(params) {
    return await accessBackend.post(routes.reports.daily, params)
  },
  async exportDailies(params) {
    return await accessBackend.post(routes.reports.daily + '/export', params)
  },
  async getConversions(params) {
    return await accessBackend.post(routes.reports.conversion, params)
  },
  async exportConversions(params) {
    return await accessBackend.post(routes.reports.conversion + '/export', params)
  },
  async getClicks(params) {
    return await accessBackend.post(routes.reports.clicks, params)
  },

  async getFilterOffers() {
    return await accessBackend.post(routes.proxy, {
      method: 'GET',
      url: routes.reports.filterOffers,
    })
    // return await accessAdstellar.get(routes.reports.filterOffers)
  },

}
