import moment from "moment";

export default function (data, type) {
  if (!data || !data.length) {
    return [];
  }

  if (type && type === 'static') {
    const formattedTimeCreated = (date) => {
      return moment(date * 1000).format('DD.MM.yyyy');
    };

    const formattedData = data.reduce((acc, el) => {
      acc[formattedTimeCreated(el.time_created)] = [];
      return acc;
    }, {});

    data.forEach((el, key) => {
      formattedData[formattedTimeCreated(el.time_created)].push({
        id: key,
        text: renderMessage(el.message),
        date: moment(el.time_created * 1000).format('DD.MM.yyyy; HH:mm') // 19.12.2021; 22:45
      });
    });

    return formattedData;
  }

  return data.map((n, i) => ({
    id: i,
    text: renderMessage(n.message),
    date: renderDate(n.time_created)
  }))
}

function renderMessage(input) {
  const objects = {}
  Object.keys(input.network_linked_object_map).forEach(i => {
    const obj = input.network_linked_object_map[i];
    let object_type = obj.object_type;
    if(obj.object_type === 'offer') object_type = 'offers'
    objects[i] = `<router-link to="/${object_type}/${obj.object_id}">${obj.object_name} (${obj.object_type} ${obj.object_id})</router-link>`
  })
  return input.template.replace(/({[\d]*})/g, str => objects[str.replace('{', '').replace('}', '')])
}

function renderDate(timestamp) {
  return moment.duration(moment(timestamp * 1000).diff(moment())).humanize(true)
}