<template>
  <div class="performance">
    <div class="performance__title">
      <h3>Performance</h3>
    </div>
    <div class="performance__row performance__row-top">
      <div class="performance__data">
        <div v-for="(f, i) in typeFilter" :key="i">
          <div class="performance__data-metric">{{ f }}</div>
          <div class="performance__data-vs">{{ leftTotalByFilter(f) }} vs {{ rightTotalByFilter(f) }}</div>
        </div>
      </div>
      <div class="performance__filter">
        <div class="performance__filter-item">
          <XDropdown
            :options-dropdown="optionsMetrics"
            v-model="typeFilter"
          />
        </div>
        <div class="performance__filter-item">
          <VSelect
              :options="optionsVS"
              v-model="dateFilter"
              size="mini"
              withoutIconCheck
          />
        </div>
      </div>
    </div>
    <div class="performance__row performance__chart-container">
      <VLoader v-if="$store.state.dashboard.chartDataStatus === requestStatus.PENDING" />
      <Chart v-if="$store.state.dashboard.chartDataStatus === requestStatus.SUCCESS" />
    </div>
  </div>
</template>

<script>
import Chart from "@/js/components/pages/dashboard/Chart";
import XDropdown from "@/js/components/common/XDropdown";
import VSelect from "@/js/components/common/VSelect";
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "Performance",
  components: {
    VSelect,
    XDropdown,
    Chart
  },
  data() {
    return {
      requestStatus,
      optionsMetrics: ['CV', 'EVR', 'RPC', 'Revenue', 'Unique clicks'],
      optionsVS: [
        {
          id: 0,
          label: 'Yesterday vs Today',
        },
        {
          id: 1,
          label: 'This day vs Today',
        },
        {
          id: 2,
          label: 'Last Week vs This Week',
        },
        {
          id: 3,
          label: 'Last Month vs Current month',
        }
      ],
      dateFilter: 'Yesterday vs Today',
      typeFilter: ['Revenue'],
      visibleCharts: ['Revenue'],
    };
  },
  watch: {
    typeFilter: function (val) {
      this.$store.dispatch('dashboard/setTypeFilter', val.map(item => ({
        value: this.convertTypeFilter(item),
        title: item
      })));
    },
    dateFilter: function (val) {
      this.$store.dispatch('dashboard/setDateFilter', {
        value: this.convertDateFilter(val),
        title: val
      });
    }
  },
  methods: {
    convertTypeFilter(val) {
      const values = {
        'CV': 'cv',
        'CVR': 'cvr',
        'Clicks': 'total_click',
        'EVR': 'evr',
        'Event': 'event',
        'RPC': 'rpc',
        'Redirect Traffic Rev': 'redirect_traffic_revenue',
        'Revenue': 'revenue',
        'Unique clicks': 'unique_click'
      };
      return values[val];
    },
    convertDateFilter(val) {
      const values = {
        'Yesterday vs Today': 'today_yesterday',
        'This day vs Today': 'today_last_week',
        'Last Week vs This Week': 'this_week_last_week',
        'Last Month vs Current month': 'this_month_last_month',
      };
      return values[val];
    },
    leftTotalByFilter(f) {
      const maxValue = this.$store.state.dashboard.chartData?.left_range?.reduce((previousValue, item) => Math.max(previousValue, item.reporting[this.convertTypeFilter(f)]), 0) || 0;
      return this.formatTotals(f, maxValue);
    },
    rightTotalByFilter(f) {
      const maxValue = this.$store.state.dashboard.chartData?.right_range?.reduce((previousValue, item) => Math.max(previousValue, item.reporting[this.convertTypeFilter(f)]), 0) || 0;
      return this.formatTotals(f, maxValue);
    },
    formatTotals(type, value) {
      switch (type) {
        case 'CV':
          return value;
        case 'CVR':
          return value.toFixed(2) + '%';
        case 'Clicks':
          return value;
        case 'EVR':
          return value.toFixed(2) + '%';
        case 'Event':
          return value;
        case 'RPC':
          return '€' + value.toFixed(2);
        case 'Redirect Traffic Rev':
          return '€' + value.toFixed(2);
        case 'Revenue':
          return '€' + value.toFixed(2);
        case 'Unique clicks':
          return value;
      }
    },
  },
  destroyed() {
    this.$store.commit('dashboard/RESET_CHART_DATA');
  }
};
</script>

<style lang="scss" scoped>
.performance {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: var(--main-box-shadow);
  border-radius: 8px;

  @include media("max", "xl") {
    padding: 16px;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 18px;
  }

  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__row-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    width: 100%;
  }

  &__data-metric {
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__data-vs {
    color: var(--blue-main);
    margin-bottom: 8px;
  }

  &__filter {
    display: grid;
    grid-template-columns: repeat(2, 240px);
    grid-column-gap: 24px;
    align-items: center;

    @include media("max", "xl") {
      grid-template-columns: repeat(2, 220px);
      grid-column-gap: 16px;
    }
  }

  &__filter-item {
    position: relative;
  }

  &__chart-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
}
</style>