import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../pages/Dashboard.vue'
import store from "@/js/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: {layout: 'auth'},
    component: () => import(/* webpackChunkName: "signup" */ '../pages/Auth/SignUp.vue'),
  },

  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {layout: 'auth'},
    component: () => import(/* webpackChunkName: "NewPassword" */ '../pages/Auth/ForgotPassword.vue'),
  },

  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    meta: {layout: 'auth'},
    props: true,
    component: () => import(/* webpackChunkName: "NewPassword" */ '../pages/Auth/ResetPassword.vue'),
  },

  {
    path: '/new-password',
    name: 'NewPassword',
    meta: {layout: 'auth'},
    component: () => import(/* webpackChunkName: "NewPassword" */ '../pages/Auth/NewPassword.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {layout: 'auth'},
    component: () => import(/* webpackChunkName: "login" */ '../pages/Auth/Login.vue'),
  },
  {
    path: '/',
    name: 'Dashboard',
    meta: {layout: 'main'},
    component: Dashboard
  },
  {
    path: '/offers',
    name: 'Offers',
    meta: {layout: 'main'},
    component: () => import(/* webpackChunkName: "offers" */ '../pages/offers/Offers.vue')
  },

  {
    path: '/offers/tracking',
    name: 'OffersTracking',
    meta: {layout: 'main', active: 'link'},
    component: () => import(/* webpackChunkName: "OffersTracking" */ '../pages/offers/OffersTracking.vue'),
  },

  {
    path: '/offers/tracking/list',
    name: 'TrackingList',
    meta: {layout: 'main', active: 'link'},
    component: () => import(/* webpackChunkName: "TrackingList" */ '../pages/offers/TrackingList.vue'),
  },

  {
    path: `/offers/:id`,
    name: 'OfferDetails',
    meta: {layout: 'main', active: 'link'},
    component: () => import(/* webpackChunkName: "OfferDetails" */ '../pages/offers/OfferDetails.vue'),
    props: true
  },

  {
    path: '/payments',
    name: 'Payments',
    meta: {layout: 'main'},
    component: () => import(/* webpackChunkName: "payments" */ '../pages/Payments/PaymentMethods.vue'),
  },
  {
    path: '/payments/create',
    name: 'CreatePaymentMethod',
    meta: {layout: 'main'},
    component: () => import(/* webpackChunkName: "createPaymentsDetails" */ '../pages/Payments/CreatePaymentMethod'),
  },
  {
    path: '/payments/edit/:id',
    name: 'EditPaymentMethod',
    meta: {layout: 'main'},
    component: () => import(/* webpackChunkName: "editPaymentsDetails" */ '../pages/Payments/EditPaymentMethod'),
  },
  {
    path: '/payments/invoices/:id',
    name: 'PaymentRequestsDetails',
    meta: {layout: 'main'},
    component: () => import(/* webpackChunkName: "PaymentRequestsDetails" */ '../pages/Payments/PaymentRequestsDetails'),
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: {layout: 'main'},
    component: () => import(/* webpackChunkName: "reports" */ '../pages/Reports.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {layout: 'main'},
    component: () => import(/* webpackChunkName: "settings" */ '../pages/Settings.vue'),

    children: [
      {
        path: 'profile',
        meta: {layout: 'main'},
        component: () => import(/* webpackChunkName: "profile" */ '../components/pages/settings/Profile'),
      },
      {
        path: 'security',
        meta: {layout: 'main'},
        component: () => import(/* webpackChunkName: "security" */ '../components/pages/settings/Security'),
      },
      {
        path: 'postback',
        meta: {layout: 'main'},
        component: () => import(/* webpackChunkName: "postback" */ '../components/pages/settings/Postback'),
      },
      {
        path: 'notifications',
        meta: {layout: 'main'},
        component: () => import(/* webpackChunkName: "notifications" */ '../components/pages/settings/Notifications'),
      },
    ]

  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const userData = JSON.parse(localStorage.getItem('random_string__api_key'))
  if (to.meta.layout === 'auth' || userData !== null) {
    store.dispatch('auth/setSession', userData);
    next()
  } else {
    router.push('/login')
  }
})

export default router