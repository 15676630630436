import api from "@/js/api";
import { accessAdstellar, accessBackend } from "@/js/api/fetchData";
import { userService } from '@/js/services/userService';

export default {
  namespaced: true,
  state: {
    api_key: null,
    first_name: null,
    last_name: null,
    title: null,
    email: '',
    id: null,
    token: null,
    currency_id: null,
    timezone_id: null,
    language_id: null,
    cell_phone: null,
    work_phone: null,
    created_at: null,
    updated_at: null,
    affiliate_id: null,
    account_status: null,
    instant_messaging_id: null,
    instant_messaging_identifier: null,
    statusSignup: "idle", // "idle" | "pending" | "success" ,
  },
  mutations: {
    SET_DATA: (state, data) => {
      state.api_key = data.api_key;
      state.email = data.email;
      state.first_name = data.first_name;
      state.last_name = data.last_name;
      state.title = data.title;
      state.id = data.id;
      state.token = data.token;
      state.currency_id = data.currency_id;
      state.timezone_id = data.timezone_id;
      state.language_id = data.language_id;
      state.cell_phone = data.cell_phone;
      state.work_phone = data.work_phone;
      state.created_at = data.created_at;
      state.updated_at = data.updated_at;
      state.affiliate_id = data.affiliate_id;
      state.account_status = data.account_status;
      state.instant_messaging_id = data.instant_messaging_id;
      state.instant_messaging_identifier = data.instant_messaging_identifier;
    },
    UPDATE_DATA: (state, data) => {
      const updatedData = userService.updateUserData(data);

      Object.entries(updatedData).forEach((item) => {
        state[item[0]] = item[1];
      });
    },
    SET_API_KEY: (s, api_key) => {
      s.api_key = api_key;
    },
    SET_STATUS_SIGNUP: (s, status) => {
      s.statusSignup = status;
    },
    RESET_STATE_INITIAL: (s) => {
      s.name = '';
      s.email = '';
      s.id = null;
      s.api_key = null;
    }
  },
  actions: {
    setSession({ commit }, data) {
      if (data !== null) {
        commit('SET_DATA', data);
        accessAdstellar.defaults.headers['X-Eflow-API-Key'] = data.api_key;
        accessBackend.defaults.headers['Authorization'] = 'Bearer ' + data.token;
        localStorage.setItem('random_string__api_key', JSON.stringify(data));
      }
    },
    async logout({ commit }) {
      try {
        await api.auth.logout();
        commit('RESET_STATE_INITIAL');
        userService.removeUserData();
        commit('appSessionSettings/RESET_SETTINGS', {}, { root: true })
      } catch (e) {
        console.log('=> ERR', e);
      }
    },
    async signup({ commit }, data) {
      commit('SET_STATUS_SIGNUP', 'pending');
      try {
        const res = await api.auth.signup(data);
        if (res.data.result) {
          commit('SET_STATUS_SIGNUP', 'succeeded');
        }
        return res;
      } catch (e) {
        console.log('err=>', e);
      }
      commit('SET_STATUS_SIGNUP', 'idle');
    },
    async forgotPassword(c, data) {
      return await api.auth.forgotPassword(data);
    },
    async setPassword(c, formData) {
      try {
        const res = await api.auth.setPassword(formData);
        console.log('setPassword res=>', res);
        if (res.status === 200 && res?.data?.user) {
          c.commit('SET_DATA', res.data.user);
          return 1;
        }
      } catch (e) {
        console.log('err=>', e);
      }
      return 0;
    },
  },
  getters: {
    currencySign: (state, getters, rootState) => {
      const currency = rootState.meta.currencies.find(c => c.currency_id === state.currency_id);
      if (currency) {
        return currency.symbol;
      }
      return state.currency_id;
    },
    userProfile: (state) => {
      return state;
    }
  },
};
