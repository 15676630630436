import { bodyOverflow, cancelOverflow } from '@/js/utils/helpers';

const onBodyOverflow = (payload) => {
  if (payload) bodyOverflow();
  else cancelOverflow();
};

export default {
  namespaced: true,
  state: {
    toggleTableColumns: false,
    toggleOfferFilters: false,
    toggleSignupSuccess: false,
  },
  mutations: {
    TOGGLE_TABLE_COLUMNS: (s, payload) => {
      s.toggleTableColumns = payload;
      onBodyOverflow(payload);
    },
    TOGGLE_OFFER_FILTERS: (s, payload) => {
      s.toggleOfferFilters = payload;
      onBodyOverflow(payload);
    },
    TOGGLE_SIGNUP_SUCCESS: (s, payload) => {
      s.toggleSignupSuccess = payload;
    },
  },
};