import axios from 'axios'
import store from "@/js/store"


const headers = {
  Accept: 'application/json',


}
const key = store?.state?.auth?.api_key || JSON.parse(localStorage.getItem('random_string__api_key'))?.api_key
// console.log(key)
if (key) {
  headers['X-Eflow-API-Key'] = key
}

export const accessAdstellar = axios.create({
  baseURL: 'https://api.eflow.team/v1/affiliates/',
  timeout: 30000,
  headers: headers

})

axios.defaults.withCredentials = true

const token = store?.state?.auth?.token || JSON.parse(localStorage.getItem('random_string__api_key'))?.token

if (key) {
  headers['Authorization'] = 'Bearer ' + token
}

export const accessBackend = axios.create({
  baseURL: window.location.origin + '/api/',
  timeout: 30000,
  headers: headers

})