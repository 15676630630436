import api from "@/js/api";
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: () => ({
    list: [],
    defaultPaymentMethod: {},
    fetchingStatus: requestStatus.IDLE,
    deletingStatus: requestStatus.IDLE,
    updatingStatus: requestStatus.IDLE,
  }),
  mutations: {
    SET_LIST: (state, payload) => {
      state.list = payload.data;
    },
    SET_DEFAULT_METHOD: (s, list) => {
      if (list.length > 0) {
        s.defaultPaymentMethod = list.find((method) => method.is_default);
      }
    },
    CHANGE_DEFAULT_METHOD: (state, id) => {
      const paymentMethod = state.list.find(method => method.id === id)
      if(paymentMethod) {
        state.defaultPaymentMethod = {
          ...paymentMethod,
          is_default: true
        };

        state.list.forEach(item => {
          if(item.id === id )item.is_default = true
          else item.is_default = false
        })
      }
    },

    REMOVE_METHOD: (state, id) => {
      state.list = state.list.filter(item => item.id !== id)
    }
  },
  actions: {
    async getPaymentMethods({state, commit}) {
      state.fetchingStatus = requestStatus.PENDING;

      try {
        const {data} = await api.payments.getPaymentMethods();
        commit('SET_LIST', data);
        commit('SET_DEFAULT_METHOD', data.data);
        state.fetchingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.fetchingStatus = requestStatus.FAIL;
      }
    },

    async deletePaymentMethod({state, commit}, id) {
      state.deletingStatus = requestStatus.PENDING;

      try {
        await api.payments.deletePaymentMethod(id);
        commit('REMOVE_METHOD', id);
        state.deletingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.deletingStatus = requestStatus.FAIL;
        throw e;
      }
    },

    async setDefaultPaymentMethod({state, commit}, id) {
      state.updatingStatus = requestStatus.PENDING;

      try {
        await api.payments.setDefaultPaymentMethod(id);
        commit('CHANGE_DEFAULT_METHOD', id);
        state.updatingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR', e);
        state.updatingStatus = requestStatus.FAIL;
        throw e;
      }
    },
  },
};
