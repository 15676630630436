<template>
  <div class="auth-footer">
    <div class="auth-footer__container">
      <div class="auth-footer__logo">
        <img src="@/assets/img/auth/logo-login.svg" alt="">
        <div>
          © All the rights reserved.<br />
          Adstellar {{ (new Date()).getFullYear() }}
        </div>
      </div>
      <div class="auth-footer__list">
        <a href="https://adstellar.io/#/privacy-policy">Privacy Policy</a>
        <a href="https://adstellar.io/#/terms">Advertiser Terms & Conditions</a>
        <a href="https://adstellar.io/#/public-terms">Publisher Terms & Conditions</a>
        <a href="https://adstellar.io/#/legal-address">Legal Address</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthFooter"
};
</script>

<style lang="scss" scoped>
.auth-footer {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  color: #ffffff;
  background-color: #1a1a1a;
  padding: 20px 0;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 15px;
    font-family: var(--Montserrat);

    @media (max-width: 1200px) {
      max-width: 900px;
    }
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    img {
      margin-right: 30px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 40px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 250px);
      grid-row-gap: 20px;
    }
    @media (max-width: 900px) {
      margin-top: 40px;
    }
    @media (max-width: 575px) {
      grid-template-columns: 1fr;
    }

    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
</style>