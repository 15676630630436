import {accessBackend} from "@/js/api/fetchData";
import routes from "@/js/api/api-routes";

export default {
  updateProfile(data) {
    return accessBackend.post(routes.settings.updateProfile, data);
  },
  resetPassword(data) {
    return accessBackend.post(routes.settings.resetPassword, data);
  },
  getPostback() {
    return accessBackend.get(routes.settings.postback);
  },
  updatePostback(data) {
    return accessBackend.post(routes.settings.postback, data);
  },
}