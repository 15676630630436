import api from '@/js/api';
import { appSessionSettingsService } from '@/js/services/appSessionSettingsService';
import { requestStatus } from '@/js/utils/constants';
import {
  defaultColumnsForOffers,
  defaultColumnsForOffer,
  defaultColumnsForDaily,
  defaultColumnsForConversion,
  defaultColumnsForClick,
  defaultMethodsForPayments
} from '@/js/utils/table/defaultColumns';

const initialState = () => ({
  settings: {
    offers: {
      selectedColumns: defaultColumnsForOffers.map((col) => col.id),
    },
    offer: {
      selectedColumns: defaultColumnsForOffer.map((col) => col.id),
    },
    daily: {
      selectedColumns: defaultColumnsForDaily.map((col) => col.id),
    },
    conversion: {
      selectedColumns: defaultColumnsForConversion.map((col) => col.id),
    },
    click: {
      selectedColumns: defaultColumnsForClick.map((col) => col.id),
    },
    payments: {
      selectedColumns: defaultMethodsForPayments.map((col) => col.id),
    },
  },
  fetchingStatus: requestStatus.IDLE,
  updatingStatus: requestStatus.IDLE,
});

export default {
  namespaced: true,
  state: () => {
    const { settings, ...initial } = initialState();
    const parseSettings = appSessionSettingsService.get();

    if (parseSettings) {
      return { settings: { ...settings, ...parseSettings }, ...initial };
    }

    return { settings, ...initial };
  },
  getters: {
    getOffersSettings: (s) => {
      return s.settings.offers;
    },
    getOfferSettings: (s) => {
      return s.settings.offer;
    },
    getDailySettings: (s) => {
      return s.settings.daily;
    },
    getConversionSettings: (s) => {
      return s.settings.conversion;
    },
    getClickSettings: (s) => {
      return s.settings.click;
    },
    getPaymentsSettings: (s) => {
      return s.settings.payments;
    },
  },
  mutations: {
    SET_SETTINGS: (s, payload) => {
      s.settings = payload;
      appSessionSettingsService.set(payload);
    },
    UPDATE_SETTINGS: (s, payload) => {
      s.settings = appSessionSettingsService.update(payload);
    },
    SET_SELECTED_COLUMNS: (s, payload) => {
      s.settings[payload.pageKey].selectedColumns = payload.selectedColumns.sort((a, b) => a - b);
    },
    RESET_SETTINGS: (s) => {
      Object.assign(s, initialState());
      appSessionSettingsService.remove();
    },
  },
  actions: {
    async getSettings({ state, commit }) {
      state.fetchingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.appSessionSettings.getSettings();
        if (Object.values(data).length > 0) commit('SET_SETTINGS', data);
        state.fetchingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR:', e);
        state.fetchingStatus = requestStatus.FAIL;
      }
    },
    async updateSettings({ state, commit }) {
      state.updatingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.appSessionSettings.updateSettings({ data: state.settings });
        if (data.status) commit('UPDATE_SETTINGS', state.settings);
        state.updatingStatus = requestStatus.SUCCESS;
      } catch (e) {
        console.log('=> ERR:', e);
        state.updatingStatus = requestStatus.FAIL;
      }
    }
  }
}