const storage = window.localStorage;
const key = 'random_string__api_key';

const userService = {
  getUserData() {
    const userData = storage.getItem(key);
    return userData ? JSON.parse(userData) : null;
  },
  setUserData(data) {
    const stringifyData = JSON.stringify(data);
    storage.setItem(key, stringifyData);
  },
  updateUserData(data) {
    let userData = this.getUserData();

    if (userData) {
      userData = { ...userData, ...data };
      this.setUserData(userData);
      return userData;
    }
  },
  removeUserData() {
    storage.removeItem(key);
  },
};

export { userService };
