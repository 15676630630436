<template>
  <svg class="icon-arrow-right" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_275_27753" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect y="24" width="24" height="24" transform="rotate(-90 0 24)" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_275_27753)">
      <path d="M20 12.1503C19.9932 11.977 19.9219 11.7766 19.803 11.6503L15.9241 7.52893C15.6614 7.2927 15.1573 7.1953 14.8635 7.4684C14.5742 7.73743 14.5828 8.25896 14.871 8.529L17.5982 11.423L4.72731 11.423C4.3257 11.423 4 11.7487 4 12.1503C4 12.5519 4.32569 12.8776 4.72731 12.8776L17.5982 12.8776L14.871 15.7716C14.6227 16.0202 14.5769 16.5603 14.8635 16.8322C15.15 17.1043 15.672 17.0174 15.9241 16.7715L19.803 12.6502C19.9357 12.5092 20.0003 12.3436 20 12.1502L20 12.1503Z" fill="#0D092E"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconArrowRight"
}
</script>

<style lang="scss" scoped>
.icon-arrow-right {
  cursor: pointer;
  display: block;
}
</style>