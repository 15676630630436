<template>
  <div class="x-dropdown" ref="dropdown">
    <div
        class="x-dropdown__container"
        @click="toggleShowOptions = !toggleShowOptions"
        v-click-outside="hide"
    >
      <div class="x-dropdown__dropdown">
        <span class="x-dropdown__dropdown--name">
          {{ _checkedItems }}
        </span>
        <icon-arrow-select v-if="!toggleShowOptions" class="s-arrow s-arrow-down" />
        <icon-arrow-select v-else class="s-arrow s-arrow-up" />
      </div>
    </div>
    <ul class="x-dropdown__dropdown-options" v-if="toggleShowOptions">
      <li class="x-dropdown--option" v-for="item in optionsDropdown" :key="item">
        <label>
          <input
              type="checkbox"
              :disabled="disabled(item)"
              v-model="checkedItems"
              :value="item"
              @click="selectOption(item)"
          />
          {{ item }}
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import IconArrowSelect from "@/js/components/icons/IconArrowSelect";

export default {
  name: "XDropdown",
  components: { IconArrowSelect },
  props: {
    optionsDropdown: Array,
    value: Array,
  },
  data() {
    return {
      checkedItems: this.value,
      max: 2,
      toggleShowOptions: false,
    };
  },
  computed: {
    _checkedItems() {
      return this.checkedItems.join(', ');
    },
  },
  methods: {
    selectOption(option) {
      this.checkedItems.includes(option)
          ? this.checkedItems = this.checkedItems.filter(item => item !== option)
          : this.checkedItems.push(option);
      this.$emit('input', this.checkedItems);
    },
    disabled(value) {
      const {
        checkedItems,
        max
      } = this;
      const isChecked = checkedItems.includes(value);
      const len = checkedItems.length;

      return (len >= max && !isChecked);
    },
    hide(e) {
      if (this.$refs.dropdown.contains(e.target)) return;
      this.toggleShowOptions = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.x-dropdown {
  position: relative;
  max-width: 350px;
  width: 100%;
  display: block;

  .x-dropdown__dropdown {
    height: 30px;
    border: 1px solid var(--grey-light-1);
    border-radius: 8px;
    outline: none;
    max-width: 316px;
    width: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .x-dropdown__dropdown--name {
    cursor: pointer;
  }

  .s-arrow {
    margin-left: auto;
  }

  .s-arrow-down {
    transform: rotate(0deg);
  }

  .s-arrow-up {
    transform: rotate(180deg);
  }

  .x-dropdown__dropdown-options {
    background: #FFFFFF;
    border-radius: 8px 8px 10px 10px;
    overflow: auto;
    position: absolute;
    z-index: 100;
    max-width: 316px;
    width: 100%;
    margin: 0;
    padding: 0;
    box-shadow: 0px 2px 6px 2px rgba(0, 42, 81, 0.1);
  }

  .x-dropdown--option,
  .x-dropdown--option label {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    width: 100%;
  }

  .x-dropdown--option:hover {
    background-color: var(--blue-light);
  }

  .x-dropdown--option:last-child {
    border-bottom: none;
  }

  .x-dropdown--option input {
    margin-right: 8px;
  }
}

</style>