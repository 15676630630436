<template>
  <div class="dashboard">
    <h2 class="dashboard__title title-page">Dashboard</h2>
    <div class="dashboard__main">
      <div class="dashboard__left">
        <div class="dashboard__summary">
          <div
              v-for="item in cardItems"
              :key="item.title"
              class="dashboard__summary-item"
          >
            <DashboardCard :title="item.title" />
          </div>
        </div>
        <div class="dashboard__performance">
          <Performance />
        </div>
      </div>
      <div class="dashboard__right">
        <ResentNotifications />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardCard from "@/js/components/pages/dashboard/DashboardCard";
import ResentNotifications from "@/js/components/pages/dashboard/ResentNotifications";
import Performance from "@/js/components/pages/dashboard/Performance";

export default {
  name: "Dashboard",
  components: {
    Performance,
    ResentNotifications,
    DashboardCard
  },
  data() {
    return {
      cardItems: [
        { title: 'revenue' },
        { title: 'conversion' },
        { title: 'click' },
      ],
    };
  },
  computed: {
    _notifications() {
      return this.$store.state.modalNotifications.notifications;
    },
  },
  mounted() {
    this.$store.dispatch('dashboard/getSummary');
    this.$store.dispatch('dashboard/getChartData');
    if (!this._notifications.length) {
      this.$store.dispatch('modalNotifications/getNotifications');
    }
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__main {
    display: grid;
    grid-template-columns: calc(100% - 320px) 300px;
    grid-column-gap: 20px;

    @include media("max", "xl") {
      grid-template-columns: calc(100% - 240px) 220px;
      grid-column-gap: 16px;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
  }

  &__summary {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    @include media("max", "xl") {
      margin-bottom: 16px;
    }
  }

  &__summary-item {
    width: 100%;
    margin-right: 20px;

    @include media("max", "xl") {
      margin-right: 16px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__performance {
    height: 100%;
  }
}
</style>