import Vue from 'vue'
import App from './App.vue'
import router from './js/router'
import store from './js/store'


// - - - - - PLUGINS - - - - - //
import vClickOutside from 'v-click-outside'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import Toasted from 'vue-toasted';

Vue.use(VueMeta)
Vue.use(vClickOutside)
Vue.use(Vuelidate)
Vue.use(Toasted, {
  position: 'top-center',
  duration: 3000
});


// - - - - - STYLES - - - - - //
import './scss/app.scss';

// - - - - - COMPONENTS- - - - - //
import VButton from '@/js/components/common/VButton';
import VIcon from '@/js/components/common/VIcon';
import VLoader from '@/js/components/common/VLoader';
import VOverlay from '@/js/components/common/VOverlay';

Vue.config.productionTip = false

Vue.component("VButton", VButton);
Vue.component("VIcon", VIcon);
Vue.component("VLoader", VLoader);
Vue.component("VOverlay", VOverlay);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
