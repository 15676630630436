<template>
  <div class="resent-notifications">
    <div class="resent-notifications__header">
      <h3>Reсent notifications</h3>
      <v-icon icon="update" @click.native="$store.dispatch('modalNotifications/getNotifications')" />
    </div>
    <ul class="resent-notifications__list">
      <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
      <template v-else-if="_fetchingStatus === requestStatus.SUCCESS">
        <li
          class="resent-notifications__item-list"
          v-for="item in _notifications"
          :key="item.id"
        >
          <div class="resent-notifications__item-text" v-html="item.text"></div>
          <div class="resent-notifications__item-day">{{ item.date }}</div>
        </li>
      </template>
      <li
        v-if="!_notifications.length && _fetchingStatus === requestStatus.SUCCESS"
        class="modal-notifications__item-list text-center"
      >
        No notifications
      </li>
    </ul>
    <div class="resent-notifications__footer">
      <div class="resent-notifications__footer-text" @click="$router.push('/settings/notifications')">
        <span>Browse Notifications</span>
        <icon-arrow-right />
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowRight from "@/js/components/icons/IconArrowRight";
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "ResentNotifications",
  components: { IconArrowRight },
  data: () => ({
    requestStatus,
  }),
  computed: {
    _fetchingStatus() {
      return this.$store.state.modalNotifications.fetchingStatus;
    },
    _notifications() {
      return this.$store.state.modalNotifications.notifications;
    },
  }
};
</script>

<style lang="scss" scoped>
.resent-notifications {
  --padding-blocks: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  box-shadow: var(--main-box-shadow);
  border-radius: 8px;

  @include media("max", "xl") {
    --padding-blocks: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--padding-blocks);
    border-bottom: 1px dashed #DDE4EB;
  }

  &__list {
    height: 100%;
    overflow-y: auto;
    padding: var(--padding-blocks);
  }

  &__item-list {
    margin-bottom: 32px;
  }

  &__item-text {
    margin-bottom: 12px;
  }

  &__item-day {
    color: var(--grey-main);
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--padding-blocks);
    border-top: 1px dashed #DDE4EB;
  }

  &__footer-text {
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 16px;
    }
  }
}
</style>