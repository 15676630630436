import Vue from 'vue'
import Vuex from 'vuex'

import auth from "./modules/auth/auth"
import dashboard from "@/js/store/modules/dashboard/dashboard"
import modalNotifications from "./modules/modalNotifications/modalNotifications"
import modalTableColumns from "./modules/offers/modalTableColumns"
import offers from "./modules/offers/offers"
import offerDetails from "@/js/store/modules/offers/offerDetails"
import trackingList from "@/js/store/modules/offers/trackingList"
import meta from "./modules/meta/meta"
import reports from "@/js/store/modules/reports/reports";
import paymentMethods from '@/js/store/modules/payments/paymentMethods';
import paymentMethodsDetails from '@/js/store/modules/payments/paymentMethodsDetails';
import paymentRequests from '@/js/store/modules/payments/paymentRequests';
import paymentRequestsDetails from '@/js/store/modules/payments/paymentRequestsDetails';
import profile from "@/js/store/modules/settings/profile";
import postback from "@/js/store/modules/settings/postback";
import passwordAndSecurity from "@/js/store/modules/settings/passwordAndSecurity";
import appSessionSettings from '@/js/store/modules/user/appSessionSettings';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    modalNotifications,
    dashboard,
    modalTableColumns,
    offers,
    meta,
    offerDetails,
    trackingList,
    reports,
    paymentMethods,
    paymentMethodsDetails,
    paymentRequests,
    paymentRequestsDetails,
    profile,
    postback,
    passwordAndSecurity,
    appSessionSettings,
  }
})