<template>
  <VMask>
    <div class="modal-notifications" ref="notifications">
      <div class="modal-notifications__header">
        <h3>Notifications</h3>
        <div @click="$store.commit('modalNotifications/TOGGLE_NOTIFICATION', false)" v-click-outside="hide">
          <icon-close />
        </div>
      </div>
      <ul class="modal-notifications__list">
        <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
        <template v-else-if="_fetchingStatus === requestStatus.SUCCESS">
          <li class="modal-notifications__item-list" v-for="item in _notifications" :key="item.id">
            <div class="modal-notifications__item-main">
              <div v-html="item.text"></div>
            </div>
            <div class="modal-notifications__item-day">{{ item.date }}</div>
          </li>
        </template>
        <li
          v-if="!_notifications.length && _fetchingStatus === requestStatus.SUCCESS"
          class="modal-notifications__item-list text-center"
        >
          No notifications
        </li>
      </ul>
      <div class="modal-notifications__footer">
        <div class="modal-notifications__footer-text" @click="seeAllNotifications">
          <span>Browse notifications</span>
          <icon-arrow-right />
        </div>
      </div>
    </div>
  </VMask>
</template>

<script>
import VMask from '@/js/components/common/VMask';
import IconClose from "@/js/components/icons/IconClose";
import IconArrowRight from "@/js/components/icons/IconArrowRight";
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "ModalNotifications",
  components: {
    VMask,
    IconArrowRight,
    IconClose
  },
  data: () => ({
    requestStatus,
  }),
  computed: {
    _fetchingStatus() {
      return this.$store.state.modalNotifications.fetchingStatus;
    },
    _notifications() {
      return this.$store.state.modalNotifications.notifications;
    },
  },
  mounted() {
    if (!this._notifications.length && this._fetchingStatus === requestStatus.IDLE) {
      this.getNotifications();
    }
  },
  methods: {
    async getNotifications() {
      await this.$store.dispatch('modalNotifications/getNotifications');
    },
    seeAllNotifications() {
      this.$store.commit('modalNotifications/TOGGLE_NOTIFICATION', false);
      this.$router.push('/settings/notifications');
    },
    hide(e) {
      if (this.$refs.notifications.contains(e.target)) return;
      this.$store.commit('modalNotifications/TOGGLE_NOTIFICATION', false);
    },
  }
};
</script>

<style lang="scss" scoped>
.modal-notifications {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 32px;
  top: 70px;
  max-width: 350px;
  height: calc(100vh - 100px);
  width: 100%;
  background: #FFFFFF;
  box-shadow: var(--main-box-shadow);
  border-radius: 8px;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  &__list {
    height: 100%;
    padding: 0 20px;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 15px 20px;
  }

  &__footer-text {
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 16px;
    }
  }

  &__item-list {
    margin-bottom: 16px;
    padding: 15px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
  }

  &__item-day {
    color: var(--grey-main);
  }
}
</style>