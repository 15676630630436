<template>
  <div class="auth-header">
    <div class="auth-header__container">
      <img class="auth-header__logo" src="@/assets/img/auth/logo-header.svg" alt="">

      <div class="auth-header__list" :class="{'auth-header__list--is-visible': isVisible}">
        <div class="auth-header__list-close" @click="isVisible = !isVisible"></div>
        <a href="https://adstellar.io/index.html#rec445424990">About</a>
        <a href="https://adstellar.io/index.html#rec445462507">Performance agency</a>
        <a href="https://adstellar.io/index.html#rec445477839">Affiliates</a>
        <a href="https://adstellar.io/index.html#rec445545271">Contact</a>
      </div>

      <div class="auth-header__btn">
        <router-link v-if="path === '/login'" to="/sign-up" class="auth-header__btn-signup">
          Sign Up
        </router-link>
        <router-link v-if="path === '/sign-up'" to="/login" class="auth-header__btn-signup">
          Log In
        </router-link>
      </div>

      <div class="auth-header__bar" @click="isVisible = !isVisible">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthHeader",
  data: () => ({
    path: '/login',
    isVisible: false
  }),
  created() {
    this.path = this.$route.path;
  },
  watch: {
    $route(route) {
      this.path = route.path;
    }
  }
};
</script>

<style lang="scss" scoped>
.auth-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 80px;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: saturate(180%) blur(20px);
  z-index: 9998;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    font-family: var(--Montserrat);

    @media (max-width: 900px) {
      flex-wrap: wrap;
      padding-top: 20px;
    }
  }

  &__logo {
    @media (max-width: 1200px) {
      width: 170px;
    }
    @media (max-width: 900px) {
      order: 1;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    transition: .25s ease;

    @media (max-width: 900px) {
      width: 100%;
      justify-content: center;
      order: 3;
      padding: 20px 0;
    }
    @media (max-width: 480px) {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: #000000;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 42px 25px 25px;
      transform: translateX(100%);
    }

    &--is-visible {
      @media (max-width: 480px) {
        transform: translateX(0);
      }
    }

    &-close {
      display: none;

      @media (max-width: 480px) {
        display: block;
        position: absolute;
        top: 12px;
        right: 12px;
        width: 24px;
        height: 24px;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 10px;
          left: 0;
          display: block;
          width: 100%;
          height: 2px;
          background-color: #fff;
        }

        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          transform: rotate(45deg);
        }
      }
    }

    a {
      color: #ffffff;
      font-size: 16px;
      line-height: 1.55;
      font-weight: 500;
      margin-right: 30px;
      text-decoration: none;

      @media (max-width: 480px) {
        font-size: 24px;
        margin-bottom: 15px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
      }
    }
  }

  &__btn {
    @media (max-width: 900px) {
      order: 2;
    }
    @media (max-width: 480px) {
      display: flex;
      justify-content: center;
      width: 100%;
      order: 3;
      padding: 20px 0;
    }

    &-signup {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 137px;
      height: 42px;
      color: #6fcf97;
      font-size: 16px;
      line-height: 1.55;
      font-weight: 600;
      border: 2px solid #6fcf97;
      text-decoration: none;
      border-radius: 8px;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: #fff;
      }
    }
  }

  &__bar {
    display: none;

    @media (max-width: 480px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      order: 2;
      width: 48px;
      height: 48px;
      background-color: #000000;
      border-radius: 48px;
      cursor: pointer;

      & > div {
        width: 22px;
        height: 2px;
        background-color: #fff;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>