import routes from '@/js/api/api-routes'
import {accessBackend} from "@/js/api/fetchData";

export default {
  async getSummary(timezone_id = 67) {
    return await accessBackend.post(routes.proxy, {
      method: 'POST',
      url: routes.dashboardSummary,
      data: {timezone_id},
    })

  },
  async getChart(data) {
    return await accessBackend.post(routes.proxy, {
      method: 'POST',
      url: routes.chart,
      data: data
    })
    // return await accessAdstellar.post(routes.chart, data)
  },
  async getNotifications(pageSize = 999) {
    return await accessBackend.get(routes.notifications + '?page_size=' + pageSize)
  },
}

