import api from '@/js/api';
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: () => ({
    data: null,
    fetchingStatus: requestStatus.IDLE,
    updatingStatus: requestStatus.IDLE,
  }),
  mutations: {
    SET_POSTBACK_URL: (s, payload) => {
      s.data = payload;
    }
  },
  actions: {
    async getPostback({ state, commit }) {
      state.fetchingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.settings.getPostback();

        console.log('=> getPostback', data);

        commit('SET_POSTBACK_URL', data.data);

        state.fetchingStatus = requestStatus.SUCCESS;
      } catch (err) {
        console.log('=> ERR', err);
        state.fetchingStatus = requestStatus.FAIL;
        throw err;
      }
    },
    async updatePostback({ state }, postbackData) {
      state.updatingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.settings.updatePostback(postbackData);

        console.log('=> updatePostback', data);

        state.updatingStatus = requestStatus.SUCCESS;
      } catch (err) {
        console.log('=> ERR', err);
        state.updatingStatus = requestStatus.FAIL;
        throw err;
      }
    }
  }
}