import { render, staticRenderFns } from "./VMask.vue?vue&type=template&id=18c8cd96&scoped=true&"
import script from "./VMask.vue?vue&type=script&lang=js&"
export * from "./VMask.vue?vue&type=script&lang=js&"
import style0 from "./VMask.vue?vue&type=style&index=0&id=18c8cd96&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c8cd96",
  null
  
)

export default component.exports