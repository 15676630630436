import api from '@/js/api';
import { requestStatus } from '@/js/utils/constants';

export default {
  namespaced: true,
  state: {
    updatingStatus: requestStatus.IDLE,
  },
  mutations: {},
  actions: {
    async resetPassword({ state }, formData) {
      state.updatingStatus = requestStatus.PENDING;

      try {
        const { data } = await api.settings.resetPassword(formData);
        state.updatingStatus = requestStatus.SUCCESS;
        return data;
      } catch (err) {
        console.log('=> ERR', err);
        state.updatingStatus = requestStatus.FAIL;
        throw err;
      }
    }
  }
};
